import { AfterViewChecked, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DatatableColumn, DatatableSorting, FetchData, RktTableComponent } from '@rocketfinancialcorp/rocket-ui/table';

import { MultiLegTransactionList, MultiLegTransactionListItem, TransactionList, TransactionListItem } from '@shared/models';
import { ScheduledTransactionsService } from '@shared/services';
import { ErrorUtils } from '@shared/utils';

type PreviousTransactionItem = TransactionListItem | MultiLegTransactionListItem;

@Component({
  selector: 'app-scheduled-transaction-previous-transactions',
  templateUrl: './scheduled-transaction-previous-transactions.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [RktTableComponent],
})
export class ScheduledTransactionPreviousTransactionsComponent implements AfterViewChecked, OnDestroy {
  @Input() schedulerId?: string | null;

  @Input() isMLT = false;

  loading = true;

  transactions: PreviousTransactionItem[] = [];

  columns: DatatableColumn[] = [
    { name: 'Created At', prop: 'createdAt', fixedSize: 150, isSortable: true, colType: 'date-time' },
    { name: 'Transaction ID', prop: 'id', colType: 'short-id', flexSize: 160 },
    { name: 'Move From', prop: 'moveFrom', colType: 'two-line-col', flexSize: 315 },
    { name: 'Move To', prop: 'moveTo', colType: 'two-line-col', flexSize: 300 },
    { name: 'Amount', prop: 'amount', flexSize: 151, isSortable: true, colType: 'amount', colAlign: 'right' },
    {
      name: 'Status',
      prop: 'status',
      sortProp: 'latestStatus',
      fixedSize: 140,
      isSortable: true,
      colType: 'status-tag',
      colAlign: 'right',
    },
  ];

  mltColumns: DatatableColumn[] = [
    { name: 'Created At', prop: 'createdAt', fixedSize: 150, isSortable: true, colType: 'date-time' },
    { name: 'MLT ID', prop: 'id', colType: 'short-id', fixedSize: 142 },
    { name: 'Move From', prop: 'moveFrom', colType: 'two-line-col', flexSize: 300 },
    { name: 'Move To', prop: 'moveTo', colType: 'two-line-col', flexSize: 300 },
    { name: 'Amount', prop: 'totalAmount', flexSize: 151, colType: 'amount', colAlign: 'right' },
    {
      name: 'Stage',
      prop: 'stage',
      fixedSize: 118,
      isSortable: true,
      colType: 'status-tag',
      colAlign: 'right',
    },
    {
      name: 'Status',
      prop: 'status',
      sortProp: 'status',
      fixedSize: 140,
      isSortable: true,
      colType: 'status-tag',
      colAlign: 'right',
    },
  ];

  totalElements = 0;

  sortParams: DatatableSorting = { key: 'createdAt', sortProp: 'createdAt', sortDir: 'desc' };

  page = 0;

  emptyListMessage = 'No Transaction Activity found.';

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    public ref: ChangeDetectorRef,
    private scheduledTransactionService: ScheduledTransactionsService,
    private router: Router,
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngAfterViewChecked(): void {
    this.ref.detectChanges();
  }

  getTransactions({ page, size, sortParams }: FetchData): void {
    this.loading = true;

    if (!this.schedulerId) {
      return;
    }

    if (sortParams) {
      this.sortParams = sortParams;
    }
    this.scheduledTransactionService
      .getScheduledTransactionProcessedTransactions({
        page: page ?? 0,
        size,
        sortParams: this.sortParams,
        scheduledTransactionId: this.schedulerId,
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response) => {
          this.emptyListMessage = 'No Transaction Activity found.';
          this.onFetchComplete(response);
        },
        error: (error) => {
          this.emptyListMessage = 'Unable to fetch Transaction Activity.';
          this.onFetchComplete();
          ErrorUtils.catchError('transactionService.getTransactions error', error);
        },
      });
  }

  onFetchComplete(response?: TransactionList | MultiLegTransactionList) {
    this.transactions = response?.items ?? [];
    this.totalElements = response?.totalElements ?? 0;
    this.loading = false;
  }

  onRowClick({ id }: PreviousTransactionItem) {
    this.router.navigateByUrl(`/app/transactions/${this.isMLT ? 'multi-leg-transactions' : 'transactions'}/${id}`);
  }
}
