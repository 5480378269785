<button
  rktButton
  btnType="secondary"
  title="Add Financial Account"
  class="add-entity-btn"
  type="button"
  [disabled]="disabled"
  (click)="addFinancialAccountMethodSelect.toggle($event)"
>
  <rkt-icon name="plus" class="table-header-filter-icon"></rkt-icon>
  Add Financial Account
</button>

<rkt-menu className="add-financial-account-dropdown" #addFinancialAccountMethodSelect [model]="addFinancialAccountMethods"></rkt-menu>
