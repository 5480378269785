import { DecimalPipe, NgIf, TitleCasePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { Store } from '@ngrx/store';
import { NgxMaskPipe } from 'ngx-mask';
import { Subject, switchMap, takeUntil, tap } from 'rxjs';
import { RktIconComponent } from '@rocketfinancialcorp/rocket-ui/icon';
import { DatatableColumn, RktTableComponent } from '@rocketfinancialcorp/rocket-ui/table';

import { BreadcrumbsComponent, DetailsPageHeaderPlaceholderComponent, TextWithPlaceholderComponent } from '@shared/components';
import { Breadcrumb, FinancialAccountStatementDetails } from '@shared/models';
import { DateFormatPipe, PrefixPipe } from '@shared/pipes';
import { FinancialAccountService } from '@shared/services';
import { selectRouteParams } from '@shared/store';
import { ErrorUtils } from '@shared/utils';

@Component({
  selector: 'app-financial-account-statement-details',
  templateUrl: './financial-account-statement-details.component.html',
  styleUrls: ['./financial-account-statement-details.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    RouterLink,
    BreadcrumbsComponent,
    DetailsPageHeaderPlaceholderComponent,
    TextWithPlaceholderComponent,
    DecimalPipe,
    TitleCasePipe,
    PrefixPipe,
    DateFormatPipe,
    RktIconComponent,
    RktTableComponent,
    NgxMaskPipe,
  ],
})
export class FinancialAccountStatementDetailsComponent implements OnInit, OnDestroy {
  statement?: FinancialAccountStatementDetails;

  totalElements = 0;

  columns: DatatableColumn[] = [
    { name: 'Created At', prop: 'createdAt', fixedSize: 120, colType: 'date' },
    { name: 'Description', prop: 'description', colType: 'two-line-col', flexSize: 487 },
    { name: 'Debit/Credit Amount', prop: 'amount', flexSize: 150, colType: 'amount', colAlign: 'right' },
    { name: 'Balance', prop: 'balance', flexSize: 150, colType: 'amount', colAlign: 'right' },
  ];

  pageReturnURL = '';

  breadcrumbList: Breadcrumb[] = [];

  get loading() {
    return !this.statement;
  }

  private destroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private financialAccountService: FinancialAccountService,
    private store: Store,
  ) {}

  ngOnInit() {
    this.getFinancialAccountStatementDetails();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getFinancialAccountStatementDetails(): void {
    this.statement = undefined;
    this.totalElements = 0;

    this.store
      .select(selectRouteParams)
      .pipe(
        tap({
          next: ({ individualCustomerId, businessCustomerId, financialAccountId }) => {
            if (!individualCustomerId && !businessCustomerId) {
              this.pageReturnURL = `/app/settings/financial-accounts/${financialAccountId}/account-statements`;

              this.breadcrumbList = [
                { label: 'Financial Accounts', url: '/app/settings/financial-accounts' },
                { label: 'Account Details', url: this.pageReturnURL },
                { label: 'Statement' },
              ];
            } else if (individualCustomerId) {
              this.pageReturnURL = `/app/customers/individuals/${individualCustomerId}/financial-accounts/${financialAccountId}/account-statements`;

              this.breadcrumbList = [
                { label: 'Individuals', url: '/app/customers/individuals' },
                { label: 'Individual details', url: `/app/customers/individuals/${individualCustomerId}` },
                { label: 'Financial Accounts', url: `/app/customers/individuals/${individualCustomerId}/financial-accounts` },
                { label: 'Account Details', url: this.pageReturnURL },
                { label: 'Statement' },
              ];
            } else if (businessCustomerId) {
              this.pageReturnURL = `/app/customers/businesses/${businessCustomerId}/financial-accounts/${financialAccountId}/account-statements`;

              this.breadcrumbList = [
                { label: 'Businesses', url: '/app/customers/businesses' },
                { label: 'Business details', url: `/app/customers/businesses/${businessCustomerId}` },
                { label: 'Financial Accounts', url: `/app/customers/businesses/${businessCustomerId}/financial-accounts` },
                { label: 'Account Details', url: this.pageReturnURL },
                { label: 'Statement' },
              ];
            }
          },
        }),
        switchMap(({ financialAccountId, yearMonth }) => {
          return this.financialAccountService.getFinancialAccountStatementDetails({
            financialAccountId,
            yearMonth,
          });
        }),
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: (response) => {
          this.statement = response;
          this.totalElements = response.accountActivities?.length || 0;
        },
        error: (error) => {
          ErrorUtils.catchError('financialAccountService.getFinancialAccountStatementDetails error', error);
          this.router.navigate(['..'], { relativeTo: this.activatedRoute });
        },
      });
  }
}
