<div class="list-page-header">
  <div class="list-page-title">Exceptions</div>

  <div class="list-page-controls">
    <div class="list-filters">
      <button type="button" class="table-header-filter-btn" (click)="filterMenu.toggle($event)" #filterBtn>
        <rkt-icon name="filter" class="table-header-filter-icon"></rkt-icon>
        Filter
        <span class="table-header-filter-counter" *ngIf="activeFiltersCount$ | async as activeFiltersCount">{{ activeFiltersCount }}</span>
      </button>
    </div>

    <app-assignee-filter
      (filterOpened)="closeMenu()"
      [selectedAssignee]="selectedAssignee ?? []"
      (changed)="setSelectedAssignee($event)"
      (closed)="getExceptions({ page: 0 })"
    ></app-assignee-filter>
  </div>
</div>

<ng-container>
  <div
    class="exception-list"
    [class.no-items]="(exceptions$ | async)?.length === 0 && (isLoading$ | async) === false && (activeFiltersCount$ | async) !== 0"
  >
    <rkt-table
      [rows]="(exceptions$ | async) || []"
      [columns]="columns"
      (fetchData)="getExceptions($event)"
      [count]="(totalElements$ | async)!"
      [loadingIndicator]="(isLoading$ | async)!"
      [offset]="activePage"
      [messages]="{
        emptyMessage:
          (exceptions$ | async)?.length === 0 && (isLoading$ | async) === false && (activeFiltersCount$ | async) === 0
            ? 'No Exceptions found.'
            : '',
      }"
      emptyValuePlaceholder="--"
      dateTimeFormat="MM/dd/yy HH:mm"
      [isItemsAlignCenter]="true"
      [placeholderItemsCount]="10"
      [sortParam]="sortParams"
      [rowHeight]="64"
      (rowActivated)="onRowClick($event)"
    ></rkt-table>
  </div>

  <div
    *ngIf="(exceptions$ | async)?.length === 0 && (isLoading$ | async) === false && (activeFiltersCount$ | async) !== 0"
    class="empty-exception-list"
  >
    <div class="empty-list-icon"></div>
    <div class="empty-list-title">No results found</div>
    <div class="empty-list-message">No results matched your search. Try double check your words.</div>
  </div>
</ng-container>

<app-filter-menu
  #filterMenu
  [initialValues]="(activeFilters$ | async) || {}"
  [filters]="filters"
  (opened)="filterBtn.classList.add('opened')"
  (closed)="filterBtn.classList.remove('opened')"
  (saved)="applyFilters($event)"
></app-filter-menu>
