<app-collapsible-row
  iconName="profile"
  class="linx-request-row"
  label="Enhanced Profile"
  [isRowExpanded]="isRowExpanded()"
  [isRowHasValue]="isRowHasValue()"
>
  @if (isRowHasValue() && !isRowExpanded()) {
    <app-enhance-profile-preview
      [enhanceAccountDetails]="enhanceAccountDetails()"
      [enhanceProfileDisplayData]="enhanceProfileDisplayData()"
      [isEditDisabled]="isEditDisabled()"
      (expandRow)="expandRow.emit()"
    />
  }
  <app-enhance-profile-edit
    [hidden]="!isRowExpanded()"
    [accountType]="accountType()"
    [entityType]="entityType()"
    [selectedEnhanceProfileDetails]="enhanceAccountDetails()"
    (continue)="onEnhanceProfileSelect($event)"
  />
</app-collapsible-row>
