import { AsyncPipe, NgClass, NgIf, TitleCasePipe } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject, combineLatest, takeUntil } from 'rxjs';
import { RktIconComponent } from '@rocketfinancialcorp/rocket-ui/icon';

import { BreadcrumbsComponent } from '@shared/components';
import { Breadcrumb, FinancialAccountHolderType } from '@shared/models';
import { fromFinancialAccount, selectRouteData, selectRouteParams } from '@shared/store';

import { FinancialAccountActionsComponent } from '../../financial-account/financial-account-actions/financial-account-actions.component';
import { DetailsPageHeaderPlaceholderComponent } from '../../loading-placeholders/details-page-header-placeholder/details-page-header-placeholder.component';

@Component({
  selector: 'app-card-pages-breadcrumbs',
  templateUrl: './card-pages-breadcrumbs.component.html',
  standalone: true,
  imports: [
    NgIf,
    RouterLink,
    NgClass,
    BreadcrumbsComponent,
    FinancialAccountActionsComponent,
    DetailsPageHeaderPlaceholderComponent,
    AsyncPipe,
    TitleCasePipe,
    RktIconComponent,
  ],
})
export class CardPagesBreadcrumbsComponent implements OnInit, OnDestroy {
  @Input() loading = true;

  @Input() cardPage!: 'cardAccountDetails' | 'restrictionDetails' | 'alertDetails' | 'disputeDetails' | 'merchantDetails';

  cardAccount$ = this.store.select(fromFinancialAccount.selectCardAccountDetails);

  breadcrumbList: Breadcrumb[] = [];

  pageReturnURL = '';

  listReturnUrl: Record<string, string> = {
    restrictionDetails: 'restrictions',
    alertDetails: 'alerts',
    disputeDetails: 'disputes',
    merchantDetails: 'merchants',
  };

  activeBreadcrumbLabel: Record<string, string> = {
    cardAccountDetails: 'Card Account Details',
    restrictionDetails: 'Restriction Details',
    alertDetails: 'Alert Details',
    disputeDetails: 'Dispute Details',
    merchantDetails: 'Merchant Details',
  };

  public entityType?: FinancialAccountHolderType;

  private destroy$ = new Subject<void>();

  constructor(private store: Store) {}

  ngOnInit(): void {
    combineLatest([this.store.select(selectRouteParams), this.store.select(selectRouteData)])
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: ([routeParams, routeData]) => {
          const { individualCustomerId, businessCustomerId, cardId } = routeParams;
          const listReturnUrl = this.listReturnUrl[this.cardPage];
          const activeBreadcrumbLabel = this.activeBreadcrumbLabel[this.cardPage];

          this.entityType = routeData.accountHolderType;

          if (this.cardPage === 'cardAccountDetails') {
            this.setCardAccountDetailsBreadcrumbs({ individualCustomerId, businessCustomerId });
            return;
          }

          if (!listReturnUrl || !activeBreadcrumbLabel) {
            return;
          }

          if (individualCustomerId) {
            this.pageReturnURL = `/app/customers/individuals/${individualCustomerId}/cards/${cardId}/${listReturnUrl}`;

            this.breadcrumbList = [
              { label: 'Individuals', url: '/app/customers/individuals' },
              { label: 'Individual Details', url: `/app/customers/individuals/${individualCustomerId}` },
              { label: 'Financial Accounts', url: `/app/customers/individuals/${individualCustomerId}/financial-accounts` },
              { label: 'Card Account Details', url: this.pageReturnURL },
              { label: activeBreadcrumbLabel },
            ];
          } else if (businessCustomerId) {
            this.pageReturnURL = `/app/customers/businesses/${businessCustomerId}/cards/${cardId}/${listReturnUrl}`;

            this.breadcrumbList = [
              { label: 'Businesses', url: '/app/customers/businesses' },
              { label: 'Business details', url: `/app/customers/businesses/${businessCustomerId}` },
              { label: 'Financial Accounts', url: `/app/customers/businesses/${businessCustomerId}/financial-accounts` },
              { label: 'Card Account Details', url: this.pageReturnURL },
              { label: activeBreadcrumbLabel },
            ];
          }
        },
      });
  }

  setCardAccountDetailsBreadcrumbs({
    individualCustomerId,
    businessCustomerId,
  }: {
    individualCustomerId?: string;
    businessCustomerId?: string;
  }) {
    if (individualCustomerId) {
      this.pageReturnURL = `/app/customers/individuals/${individualCustomerId}/financial-accounts`;

      this.breadcrumbList = [
        { label: 'Individuals', url: '/app/customers/individuals' },
        { label: 'Individual details', url: `/app/customers/individuals/${individualCustomerId}` },
        { label: 'Financial Accounts', url: this.pageReturnURL },
        { label: 'Card Account Details' },
      ];
    }
    if (businessCustomerId) {
      this.pageReturnURL = `/app/customers/businesses/${businessCustomerId}/financial-accounts`;

      this.breadcrumbList = [
        { label: 'Businesses', url: '/app/customers/businesses' },
        { label: 'Business details', url: `/app/customers/businesses/${businessCustomerId}` },
        { label: 'Financial Accounts', url: this.pageReturnURL },
        { label: 'Card Account Details' },
      ];
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
