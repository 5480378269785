import { createFeature, createReducer, createSelector, on } from '@ngrx/store';

import { FinancialAccountDetails } from '@shared/models';
import { selectRouteData } from '@shared/store';
import { toTitleCase } from '@shared/utils';

import { FinancialAccountDetailsActions } from './financial-account.actions';

interface FinancialAccountFeatureState {
  financialAccountDetails: FinancialAccountDetails | null;
}

const financialAccountInitialState: FinancialAccountFeatureState = {
  financialAccountDetails: null,
};

export const financialAccountFeature = createFeature({
  name: 'financialAccountFeature',
  reducer: createReducer(
    financialAccountInitialState,
    on(FinancialAccountDetailsActions.loadFinancialAccountDetails, (state): FinancialAccountFeatureState => {
      return { ...state, financialAccountDetails: null };
    }),
    on(
      FinancialAccountDetailsActions.loadFinancialAccountDetailsSuccess,
      (state, { financialAccountDetails }): FinancialAccountFeatureState => {
        return { ...state, financialAccountDetails };
      },
    ),
    on(FinancialAccountDetailsActions.loadFinancialAccountDetailsFailure, (state): FinancialAccountFeatureState => {
      return { ...state, financialAccountDetails: null };
    }),
  ),
  extraSelectors: ({ selectFinancialAccountDetails }) => {
    const selectFinancialAccountDetailsTitle = createSelector(
      selectFinancialAccountDetails,
      selectRouteData,
      (financialAccount, routeData) => {
        if (!financialAccount) {
          return '';
        }

        const { name, category, cardOrBankAccount, displayName } = financialAccount;

        if (routeData.accountHolderType !== 'business-account' && displayName) {
          return displayName;
        }

        if (name) {
          return name;
        }

        return `${toTitleCase(category)} ${cardOrBankAccount === 'card' ? 'Card' : 'Bank'} Account`;
      },
    );

    const selectIsFinancialAccountExternal = createSelector(selectFinancialAccountDetails, (financialAccount) => {
      return financialAccount?.category === 'EXTERNAL';
    });

    const selectIsFinancialAccountIntegratedCard = createSelector(selectFinancialAccountDetails, (financialAccount) => {
      return financialAccount?.type === 'CARD' && financialAccount?.category === 'INTEGRATED';
    });

    return { selectFinancialAccountDetailsTitle, selectIsFinancialAccountExternal, selectIsFinancialAccountIntegratedCard };
  },
});
