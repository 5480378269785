import { HttpParams } from '@angular/common/http';
import { format, sub } from 'date-fns';
import { fromZonedTime } from 'date-fns-tz';
import { compact, isEmpty, toUpper } from 'lodash-es';

import { constants } from '@shared/constants';
import { CustomHttpParamEncoder } from '@shared/encoder';
import { RequestPageParams } from '@shared/models';
import {
  EarnedWageCustomer,
  EarnedWageCustomerListItem,
  EarnedWageCustomerListRaw,
  EarnedWageCustomerPaymentList,
  EarnedWageCustomerPaymentListItem,
  EarnedWageListItem,
  EarnedWageListRaw,
  EarnedWageProgram,
} from '@shared/models/earned-wage';
import { filterCreatedAtDateRange, systemTimeZone, toTitleCase } from '@shared/utils';

export const earnedWageRequestParams = (requestParams: RequestPageParams): HttpParams => {
  const { page, size, sortParams, activeFilters } = requestParams;

  let params = new HttpParams({ encoder: new CustomHttpParamEncoder() });

  params = params.append('size', size ?? constants.TABLE_ROWS);

  if (page) {
    params = params.append('page', page);
  }

  if (sortParams) {
    params = params.append('sort', `${sortParams.key},${sortParams.sortDir}`);
  }

  if (activeFilters) {
    Object.keys(activeFilters).forEach((key) => {
      if (key === 'enrollmentBegin') {
        const { from, to } = filterCreatedAtDateRange(activeFilters[key]) ?? {};

        if (!from || !to) {
          return;
        }

        params = params.append('enrollmentBeginDateFrom', from);
        params = params.append('enrollmentBeginDateTo', to);
        params = params.append('timeZone', systemTimeZone);
      } else {
        params = params.append(key, activeFilters[key]!);
      }
    });
  }

  return params;
};

export const earnedWageCustomerRequestParams = (requestParams: RequestPageParams): HttpParams => {
  const { page, size, activeFilters } = requestParams;

  let params = new HttpParams({ encoder: new CustomHttpParamEncoder() });

  params = params.append('pageSize', size ?? constants.TABLE_ROWS);

  if (page) {
    params = params.append('pageNumber', page);
  }

  if (!isEmpty(activeFilters)) {
    Object.keys(activeFilters).forEach((key) => {
      if (key === 'date') {
        const defaultRange = {
          from: format(sub(new Date(), { days: 30 }), 'yyyy-MM-dd'),
          to: format(new Date(), 'yyyy-MM-dd'),
        };

        const { from, to } = filterCreatedAtDateRange(activeFilters[key], defaultRange) ?? {};

        if (!from || !to) {
          return;
        }

        if (key === 'date') {
          params = params.append('dateFrom', from);
          params = params.append('dateTo', to);
          params = params.append('timeZone', systemTimeZone);
          return;
        }
      } else {
        params = params.append(key, activeFilters[key]!);
      }
    });
  }

  return params;
};

export const mapEarnedWageProgram = (earnedWageList: EarnedWageListRaw): EarnedWageListItem => {
  const { content = [], totalElements = 0, totalPages = 0 } = earnedWageList || {};
  const items = content?.map((earnedWageProgram) => ({
    ...earnedWageProgram,
    fundingAccountShortId: earnedWageProgram?.fundingFinancialAccountId?.split('-')[0]?.toUpperCase(),
    name: toTitleCase(earnedWageProgram.name),
  }));

  return {
    items,
    totalElements,
    totalPages,
  };
};

export const mapEarnedWageProgramDetails = (earnedWageProgram: EarnedWageProgram): EarnedWageProgram => {
  return {
    ...earnedWageProgram,
    name: toTitleCase(earnedWageProgram.name),
    customerDataSource: toTitleCase(earnedWageProgram.customerDataSource),
    fundingAccountShortId: earnedWageProgram?.fundingFinancialAccountId?.split('-')[0]?.toUpperCase(),
    disbursementSolution: toTitleCase(earnedWageProgram.disbursementSolution),
    recoverySolution: toTitleCase(earnedWageProgram.recoverySolution),
    disbursementType: toTitleCase(earnedWageProgram.disbursementType),
    recoveryMaxRetryValue: `${earnedWageProgram.recoveryMaxRetries} ${earnedWageProgram.recoveryMaxRetries > 1 ? 'times' : 'time'}`,
    recoveryDaysAfterPaydayValue: `${earnedWageProgram.recoveryDaysAfterPayday} ${
      earnedWageProgram.recoveryDaysAfterPayday > 1 ? 'days' : 'day'
    }`,
    recoveryRetryDelayMinValue: `${earnedWageProgram.recoveryRetryDelayMin.toString()} minutes`,
    status: toTitleCase(earnedWageProgram?.status),
    registrationFeeTimingValue: toTitleCase(earnedWageProgram?.registrationFeeTiming),
    registrationFeeFinancialAccountShordId: earnedWageProgram?.registrationFeeFinancialAccountId?.split('-')[0]?.toUpperCase(),
  };
};

export const mapEarnedWageCustomer = (earnedWageCustomerList: EarnedWageCustomerListRaw): EarnedWageCustomerListItem => {
  const { content = [], totalElements = 0 } = earnedWageCustomerList || {};
  const items = content?.map((earnedWageProgramCustomer) => ({
    ...earnedWageProgramCustomer,
    name: `${compact([earnedWageProgramCustomer.firstName, earnedWageProgramCustomer.middleName, earnedWageProgramCustomer.lastName]).join(
      ' ',
    )}`,
    externalId: toUpper(
      `${
        earnedWageProgramCustomer.externalId.split('-').length > 1
          ? earnedWageProgramCustomer.externalId.split('-')[0] + '-' + earnedWageProgramCustomer.externalId.split('-')[1]
          : earnedWageProgramCustomer.externalId
      }`,
    ),
    eligibilityStatus: toTitleCase(earnedWageProgramCustomer?.latestEligibilityStatus?.status),
    enrollmentBegin: earnedWageProgramCustomer.enrollmentBegin
      ? fromZonedTime(
          earnedWageProgramCustomer.enrollmentBegin.split(' ')[0],
          earnedWageProgramCustomer.enrollmentBegin.split(' ')[1],
        ).toISOString()
      : '',
  }));

  return {
    items,
    totalElements,
  };
};

export const mapEarnedWageCustomerDetails = (earnedWageCustomer: EarnedWageCustomer): EarnedWageCustomer => {
  return {
    ...earnedWageCustomer,
    name: `${compact([earnedWageCustomer.firstName, earnedWageCustomer.middleName, earnedWageCustomer.lastName]).join(' ')}`,
    shortExtId: formatExternalId(earnedWageCustomer.externalId),
    enrollmentStatus: earnedWageCustomer?.enrollmentStatus?.toLowerCase(),
    enrollmentBegin: earnedWageCustomer?.enrollmentBegin,
    mappedPayments: mapEarnedWagePayments(earnedWageCustomer.payments),
    eligibilityStatus: earnedWageCustomer?.latestEligibilityStatus?.status,
  };
};

const mapEarnedWagePayments = (payments: EarnedWageCustomerPaymentListItem): EarnedWageCustomerPaymentList => {
  const { content = [], totalElements = 0, totalPages = 0 } = payments || {};

  const items = content?.map((ewaCustomerPayment) => ({
    ...ewaCustomerPayment,
    paymentDate: ewaCustomerPayment.createdAt,
  }));

  return {
    items,
    totalElements,
    totalPages,
  };
};

const formatExternalId = (externalId: string): string => {
  if (externalId && externalId.split('-').length > 1) {
    externalId = `${externalId.split('-')[0]}-${externalId.split('-')[1]}`;
  }

  return toUpper(externalId);
};
