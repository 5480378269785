<div class="linx-request-move-money">
  <div class="collapsible-row">
    <div class="default">1</div>
    <app-linx-move-how-selection
      (selected)="onMoveHowSelect($event)"
      (cleared)="onMoveHowReset()"
      [customerFinancialAccountType]="financialAccountType"
    />
  </div>

  <div class="collapsible-row-column" [hidden]="!isRowExpanded('move-how-wire-details')">
    <div class="collapsible-row-header">
      <div class="default">2</div>
      <div class="collapsible-row-label">Wire Details</div>
    </div>

    <div class="registry-form">
      <app-move-how-wire-details
        [isSameDayWindowDisabled]="isSameDayWireWindowDisabled"
        [isNextDayWireWindowDisabled]="isNextDayWireWindowDisabled"
        (selected)="onMoveHowWireDetailsSelect($event)"
      />
    </div>
  </div>

  <div class="collapsible-row-column" [hidden]="!isRowExpanded('move-how-ach-details')">
    <div class="collapsible-row-header">
      <div class="default">2</div>
      <div class="collapsible-row-label">ACH Details</div>
    </div>

    <div class="registry-form">
      <app-move-how-ach-details
        [isLinXMoveMoney]="true"
        [isSameDayWindowDisabled]="isSameDayAchWindowDisabled"
        (selected)="onMoveHowAchDetailsSelect($event)"
        (blurred)="onMoveHowAchDetailsBlur()"
      />
    </div>
  </div>

  <div class="collapsible-row">
    <div class="default">{{ isWireOrAch ? 3 : 2 }}</div>
    @if (!isRowExpanded('move-reason-amount')) {
      <div class="collapsible-row-label">Reason & Amount</div>
    }

    @if (isRowExpanded('move-reason-amount')) {
      <div class="move-reason-amount-wrapper">
        <div class="payment-reasons-wrapper">
          <app-move-reason-selection
            [initialValue]="selectedMoveMoneyDetails?.paymentReasonId"
            [directionType]="moveMoneyService.moveHow.moveDirection"
            [moveHowType]="moveMoneyService.moveHow.moveHowType"
            [isMoveFieldsDisabled]="!moveMoneyService.isRowHasValue('move-how')"
            (selected)="onPaymentReasonSelect($event)"
          />
        </div>

        <div class="move-amount-wrapper">
          @if (!isTemplate) {
            <app-move-amount-control
              [transactionType]="moveMoneyService.moveHow.moveHowType"
              [transactionWindowType]="moveMoneyService.moveHowAchDetails.moveAchType"
              [achEntryType]="moveMoneyService.moveHowAchDetails.moveAchEntryType"
              [isMoveFieldsDisabled]="!moveMoneyService.isRowHasValue('move-how')"
              [moveAmountError]="moveMoneyService.moveAmountError"
              [enabledSolutionConfigs]="enabledSolutionConfigs$ | async"
              (changed)="moveMoneyService.onAmountChange($event)"
              (blurred)="onReasonAmountElBlur()"
              (errored)="moveMoneyService.onAmountError($event)"
            />
          }

          @if (moveMoneyService.moveHow.moveHowType === 'wire') {
            <app-move-wire-selection (changed)="onWireDetailsChange($event)" (blurred)="onReasonAmountElBlur()" />
          }
        </div>
      </div>
    }
  </div>

  @if (!isTemplate) {
    <div class="collapsible-row-column">
      <div class="collapsible-row-header">
        <div class="default">{{ isWireOrAch ? 4 : 3 }}</div>
        <div class="collapsible-row-label">{{ businessAccountSelectionLabel }}</div>
      </div>

      @if (isRowExpanded('move-from')) {
        <div class="registry-form-preview" [hidden]="showBusinessFinancialAccountSelection">
          <div class="preview-content">
            @if (!moveMoneyService.selectedDebitFinancialAccount?.accountHolderType) {
              <div class="preview-content-item">
                {{ moveMoneyService.selectedDebitFinancialAccount?.accountHolderTypeDisplayName }}
              </div>
            }

            @if (moveMoneyService.selectedDebitFinancialAccount?.customer) {
              <div class="preview-content-item">
                <span class="preview-label">
                  {{ moveMoneyService.selectedDebitFinancialAccount?.customer?.type | titlecase }}
                  {{ moveMoneyService.selectedDebitFinancialAccount?.accountHolderTypeDisplayName }}:&nbsp;
                </span>
                {{ moveMoneyService.selectedDebitFinancialAccount?.customer?.displayName }}
              </div>
            }

            @if (moveMoneyService.selectedDebitFinancialAccount?.recipient) {
              <div class="preview-content-item">
                <span class="preview-label">
                  {{ moveMoneyService.selectedDebitFinancialAccount?.recipient?.recipientType | titlecase }}
                  {{ moveMoneyService.selectedDebitFinancialAccount?.accountHolderTypeDisplayName }}:
                </span>
                {{ moveMoneyService.selectedDebitFinancialAccount?.recipient?.displayName }}
              </div>
            }

            <div class="preview-content-item">
              <rkt-icon [name]="moveMoneyService.selectedDebitFinancialAccount?.type === 'BANK' ? 'bank' : 'card'"></rkt-icon>
              {{ moveMoneyService.selectedDebitFinancialAccount?.name }}
              *
              {{
                moveMoneyService.selectedDebitFinancialAccount?.bankAccount?.accountNumberTail ||
                  moveMoneyService.selectedDebitFinancialAccount?.card?.cardNumberTail ||
                  moveMoneyService.selectedDebitFinancialAccount?.lastFourNumbers?.replace('*', '')
              }}
              {{ moveMoneyService.selectedDebitFinancialAccount?.subtype | titlecase }}
              {{ moveMoneyService.selectedDebitFinancialAccount?.type === 'BANK' ? 'Acc' : 'Card' }}
            </div>

            @if (moveMoneyService.selectedDebitFinancialAccount?.category === 'INTERNAL') {
              <div class="preview-content-item">
                <span class="preview-label">Available Balance: </span>
                {{
                  (moveMoneyService.selectedDebitFinancialAccount?.availableBalance | number: '1.2-2') || ''
                    | mask: 'separator' : { thousandSeparator: ',' }
                    | prefix: '$ '
                }}
              </div>
            }

            @if (moveMoneyService.selectedDebitFinancialAccount?.customer) {
              <div class="preview-content preview-content-account-info">
                <div class="preview-content-item">{{ moveMoneyService.selectedDebitFinancialAccount?.customer?.primaryEmail?.value }}</div>

                <div class="preview-content-item">
                  {{ moveMoneyService.selectedDebitFinancialAccount?.customer?.primaryPhoneNumber?.number }}
                </div>
              </div>
            }

            @if (moveMoneyService.selectedDebitFinancialAccount?.recipient) {
              <div class="preview-content preview-content-account-info">
                <div class="preview-content-item">{{ moveMoneyService.selectedDebitFinancialAccount?.recipient?.email }}</div>

                <div class="preview-content-item">{{ moveMoneyService.selectedDebitFinancialAccount?.recipient?.phoneNumber }}</div>
              </div>
            }
          </div>

          <button class="preview-edit" [disabled]="submitLoader" (click)="editBusinessFinancialAccount()">
            <rkt-icon name="edit"></rkt-icon>
          </button>
        </div>

        <div class="registry-form" [hidden]="!showBusinessFinancialAccountSelection">
          <app-linx-business-financial-account-selection
            [initialSelectedFinancialAccountId]="selectedMoveMoneyDetails?.selectedMoveFromId"
            (selectedFinancialAccount)="onDebitFinancialAccountSelect($event)"
            [debitAllowedTypes]="debitAllowedAccountTypes$ | async"
            [creditAllowedTypes]="creditAllowedAccountTypes$ | async"
            [selectedMoveHowType]="moveMoneyService.moveHow.moveHowType"
            [selectedMoveDirection]="moveMoneyService.moveHow.moveDirection"
            [customerId]="customerId"
          />
        </div>
      }
    </div>
  }
  <div class="linx-request-move-money-note">
    <app-move-note-control (changed)="moveMoneyService.onNoteChange($event)" [isMoveFieldsDisabled]="submitLoader" />
  </div>

  <div class="move-money-buttons">
    @if (!isEditTemplateMode) {
      <button
        rktButton
        btnType="tertiary"
        class="move-money-btn btn-clear"
        title="Clear"
        (click)="onClearBtnClick()"
        [disabled]="isDataUnchanged || submitLoader"
      >
        Clear
      </button>
    }

    @if (isEditTemplateMode) {
      <button
        rktButton
        btnType="tertiary"
        class="move-money-btn btn-clear"
        title="Cancel"
        (click)="cancel.emit()"
        [disabled]="submitLoader"
      >
        Cancel
      </button>
    }

    @if (moveMoneyService.expandedRow) {
      <button
        rktButton
        title="Continue"
        (click)="expandNotFilledRow()"
        [disabled]="!moveMoneyService.isRowHasValue(moveMoneyService.expandedRow)"
      >
        Continue
      </button>
    }

    @if (!isSubmitDisabled && !moveMoneyService.expandedRow) {
      <button rktButton title="Continue" (click)="moveMoneyReview()" [disabled]="submitLoader">Continue</button>
    }
  </div>
</div>
