import { Injectable } from '@angular/core';

import {
  IdentityVerificationProfile,
  LinxEnhanceAccountDetails,
  LinxRequestBenificiaryDetails,
  LinxRequestDocumentDetails,
  LinxRequestFinancialAccount,
  LinxRequestMoveMoneyDetails,
  LinxRequestOptionType,
  LinxRequestRowType,
} from '@shared/models';

export abstract class AbstractLinxTemplateBase {
  abstract expandedRow?: LinxRequestRowType;
  abstract selectedOptions: LinxRequestOptionType[];
  abstract enhanceAccountDetails?: LinxEnhanceAccountDetails;
  abstract enhanceBeneficiaryDetails?: LinxRequestBenificiaryDetails;
  abstract financialAccountDetails?: LinxRequestFinancialAccount;
  abstract documentAcceptanceDetails?: LinxRequestDocumentDetails[];
  abstract moveMoneyDetails?: LinxRequestMoveMoneyDetails;
  abstract identityVerificationDetails?: IdentityVerificationProfile;
  abstract resetRequestRowDetails(type: LinxRequestRowType): void;
  abstract handleRowExpand(type?: LinxRequestRowType): void;
  abstract isRequestOptionsAvailable(): boolean;
  abstract isRowVisible(type: LinxRequestRowType): boolean;
  abstract isRowExpanded(type: LinxRequestRowType): boolean;
  abstract isRowHasValue(type: LinxRequestRowType): boolean;
  abstract isEditDisabled(): boolean;
  abstract onReset(): void;
}

@Injectable({
  providedIn: 'root',
})
export class LinxTemplateWrapperService {
  public expandedRow?: LinxRequestRowType;

  enhanceAccountDetails?: LinxEnhanceAccountDetails;

  enhanceBeneficiaryDetails?: LinxRequestBenificiaryDetails;

  financialAccountDetails?: LinxRequestFinancialAccount;

  documentAcceptanceDetails?: LinxRequestDocumentDetails[];

  moveMoneyDetails?: LinxRequestMoveMoneyDetails;

  identityVerificationDetails?: IdentityVerificationProfile;

  selectedOptions: LinxRequestOptionType[] = [];

  isRequestOptionsAvailable(): boolean {
    return this.selectedOptions.length > 0;
  }

  isRowVisible(type: LinxRequestRowType): boolean {
    return this.selectedOptions.find((option) => option.type === type)?.isChecked ?? false;
  }

  isRowExpanded(type: LinxRequestRowType): boolean {
    return this.expandedRow === type;
  }

  isRowHasValue(type: string): boolean {
    switch (type) {
      case 'enhance-profile':
        return !!this.enhanceAccountDetails;
      case 'financial-account':
        return !!this.financialAccountDetails;
      case 'document-acceptance':
        return this.documentAcceptanceDetails?.length ? true : false;
      case 'move-money':
        return !!this.moveMoneyDetails;
      case 'enhance-beneficiary':
        return !!this.enhanceBeneficiaryDetails;
      case 'identity-verification':
        return !!this.identityVerificationDetails;
      default:
        return false;
    }
  }

  isEditDisabled(): boolean {
    return this.expandedRow ? true : false;
  }

  resetRequestRowDetails(type: LinxRequestRowType): void {
    switch (type) {
      case 'enhance-profile':
        this.enhanceAccountDetails = undefined;
        break;
      case 'financial-account':
        this.financialAccountDetails = undefined;
        break;
      case 'document-acceptance':
        this.documentAcceptanceDetails = undefined;
        break;
      case 'move-money':
        this.moveMoneyDetails = undefined;
        break;
      case 'enhance-beneficiary':
        this.enhanceBeneficiaryDetails = undefined;
        break;
      case 'identity-verification':
        this.identityVerificationDetails = undefined;
        break;
      default:
        break;
    }
  }

  onReset(): void {
    this.financialAccountDetails = undefined;
    this.enhanceAccountDetails = undefined;
    this.documentAcceptanceDetails = undefined;
    this.moveMoneyDetails = undefined;
    this.expandedRow = undefined;
    this.selectedOptions = [];
    this.enhanceBeneficiaryDetails = undefined;
    this.identityVerificationDetails = undefined;
  }

  handleRowExpand(type?: LinxRequestRowType): void {
    if (type) {
      this.expandedRow = type;
      return;
    }
    this.expandedRow = this.selectedOptions.find((option) => option.isChecked && !option.hasValue)?.type;
  }
}
