import { NgFor } from '@angular/common';
import { AfterViewChecked, ChangeDetectorRef, Component } from '@angular/core';
import { RktButtonDirective } from '@rocketfinancialcorp/rocket-ui/button';
import { RktIconComponent } from '@rocketfinancialcorp/rocket-ui/icon';
import { ActiveModal } from '@rocketfinancialcorp/rocket-ui/modal';

import { DocumentCreateModel } from '@shared/models';

import { DocumentType, businessCustomerDocumentTypes, individualCustomerDocumentTypes } from './document-types';

@Component({
  selector: 'app-document-type-selection',
  templateUrl: 'document-type-selection.component.html',
  styleUrls: ['document-type-selection.component.scss'],
  standalone: true,
  imports: [RktButtonDirective, NgFor, RktIconComponent],
})
export class DocumentTypeSelectionComponent implements AfterViewChecked {
  documentType?: string;

  accountHolderType?: DocumentCreateModel['accountHolderType'];

  get documentTypes(): DocumentType[] {
    switch (this.accountHolderType) {
      case 'Individual':
      case 'BusinessBeneficiary':
        return individualCustomerDocumentTypes;

      case 'Business':
        return businessCustomerDocumentTypes;

      default:
        return [];
    }
  }

  constructor(
    public ref: ChangeDetectorRef,
    public activeModal: ActiveModal,
  ) {}

  public ngAfterViewChecked(): void {
    this.ref.detectChanges();
  }

  public modalInitData(data: DocumentCreateModel) {
    const { accountHolderType, documentType } = data;
    this.accountHolderType = accountHolderType;
    this.documentType = documentType;
  }

  public typesTrackBy(_i: number, documentType: DocumentType) {
    return documentType.value;
  }

  public selectDocumentType(type: string) {
    this.documentType = type;
  }

  public onSubmit() {
    this.activeModal.close({ documentType: this.documentType, accountHolderType: this.accountHolderType });
  }
}
