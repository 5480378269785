import { NgClass, NgFor, NgIf, TitleCasePipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RktIconComponent } from '@rocketfinancialcorp/rocket-ui/icon';
import { ModalService } from '@rocketfinancialcorp/rocket-ui/modal';

import { UploadDocumentLinkComponent } from '@shared/components';
import { IdentityVerificationProcessingSummary } from '@shared/models';
import { getIdvOutcomeTagColor } from '@shared/utils';

import { DateFormatPipe } from '../../../pipes/date-format.pipe';
import { DetailsItemComponent } from '../../details-item/details-item.component';

@Component({
  selector: 'app-identity-verification-summary',
  templateUrl: './identity-verification-summary.component.html',
  styleUrls: ['./identity-verification-summary.component.scss'],
  standalone: true,
  imports: [NgFor, NgClass, NgIf, DetailsItemComponent, TitleCasePipe, DateFormatPipe, RktIconComponent],
})
export class IdentityVerificationSummaryComponent {
  @Input() idvApplicationSummaryItems?: IdentityVerificationProcessingSummary[];
  @Input() loading = false;

  pageClassPrefix = 'identity-application-details-';

  getTagColor = getIdvOutcomeTagColor;

  constructor(private modalService: ModalService) {}

  applicationHasHostedDocumentLink(identityEntityItem: IdentityVerificationProcessingSummary): boolean {
    return identityEntityItem.entityVerificationStatus === 'pending_documents';
  }

  isBusinessCustomer(idvApplicationSummaryItem: IdentityVerificationProcessingSummary): boolean {
    return idvApplicationSummaryItem.accountTypeInfo === 'BUSINESS_CUSTOMER';
  }

  toggleCollapsableItem(idvApplicationSummaryItem: IdentityVerificationProcessingSummary) {
    idvApplicationSummaryItem.collapseSectionExpanded = !idvApplicationSummaryItem.collapseSectionExpanded;
  }

  getProfileIcon(item: string | undefined): string {
    switch (item) {
      case 'BUSINESS_CUSTOMER':
        return 'briefcase';

      case 'BENEFICIARY':
        return 'beneficiary';

      case 'PASSPORT':
        return 'passport';

      case 'DRIVING LICENSE':
        return 'driver-license';

      case 'INDIVIDUAL_CUSTOMER':
      default:
        return 'businessman';
    }
  }

  uploadDocumentModal(idvApplicationSummaryItem: IdentityVerificationProcessingSummary) {
    const uploadDocumentLinkModalRef = this.modalService.open(UploadDocumentLinkComponent, {
      className: 'entity-form-modal',
    });

    uploadDocumentLinkModalRef.componentInstance.applicationDetails = idvApplicationSummaryItem;

    uploadDocumentLinkModalRef.result.finally(() => false);
  }
}
