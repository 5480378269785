import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';

import { RktIconComponent } from '@rocketfinancialcorp/rocket-ui/icon';
import { NgSelectComponent } from '@ng-select/ng-select';
import { RktButtonDirective } from '@rocketfinancialcorp/rocket-ui/button';
import { RktFormSelectComponent } from '@rocketfinancialcorp/rocket-ui/form';
import { MenuComponent, MenuItem } from '@rocketfinancialcorp/rocket-ui/menu';

@Component({
  selector: 'app-add-financial-account-method-select',
  standalone: true,
  imports: [RktIconComponent, NgSelectComponent, RktButtonDirective, RktFormSelectComponent, MenuComponent],
  templateUrl: './add-financial-account-method-select.component.html',
})
export class AddFinancialAccountMethodSelectComponent {
  @ViewChild('addFinancialAccountMethodSelect') addFinancialAccountMethodSelect!: NgSelectComponent;
  @Input() disabled!: boolean;
  @Output() onboardAccountMethod = new EventEmitter<string>();

  addFinancialAccountMethods: MenuItem[] = [
    {
      label: 'Add Account Manually',
      command: () => this.addFinancialAccountMethod('MANUAL'),
      type: 'link',
    },
    {
      label: 'Connect External Account',
      command: () => this.addFinancialAccountMethod('EXTERNAL'),
      type: 'link',
    },
  ];

  addFinancialAccountMethod(onboardMethod: string) {
    this.onboardAccountMethod.emit(onboardMethod);
  }
}
