import { Pipe, PipeTransform } from '@angular/core';
import { CountryCode } from 'libphonenumber-js';

import { toPhone } from '@shared/utils';

@Pipe({
  name: 'phone',
  standalone: true,
})
export class PhonePipe implements PipeTransform {
  transform(val?: string, region: CountryCode = 'US'): string {
    if (!val) {
      return '';
    }

    return toPhone(val, region);
  }
}
