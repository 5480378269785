import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { format, sub } from 'date-fns';
import { compact, isEmpty } from 'lodash-es';
import { Observable, map, tap } from 'rxjs';

import { environment } from '@env';
import { constants } from '@shared/constants';
import { CustomHttpParamEncoder } from '@shared/encoder';
import { ExceptionHistoryListResponse, ExceptionItem, ExceptionItemRaw, ExceptionListResponse, RequestPageParams } from '@shared/models';
import { BackendService } from '@shared/services/backend.service';
import { AuditActions } from '@shared/store';
import { exceptionCategory, exceptionType, filterCreatedAtDateRange, systemTimeZone } from '@shared/utils';

@Injectable({
  providedIn: 'root',
})
export class ExceptionManagementService {
  store = inject(Store);

  backendService = inject(BackendService);

  getExeptions({
    businessAccountId,
    requestParams,
    assignedTo,
  }: {
    businessAccountId: string;
    requestParams: RequestPageParams;
    assignedTo: string[];
  }): Observable<ExceptionItem[]> {
    const { page, size, activeFilters, sortParams } = requestParams;

    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set('page', `${page ?? 0}`)
      .set('size', `${size ?? constants.TABLE_ROWS}`)
      .set('businessAccountId', businessAccountId);

    if (sortParams) {
      params = params.append('sort', `${sortParams.key},${sortParams.sortDir}`);
    }

    if (assignedTo.length) {
      params = params.append('assignedTo', assignedTo.join(','));
    }

    if (!isEmpty(activeFilters)) {
      Object.keys(activeFilters).forEach((key) => {
        if (key === 'date') {
          const defaultRange = {
            from: format(sub(new Date(), { days: 30 }), 'yyyy-MM-dd'),
            to: format(new Date(), 'yyyy-MM-dd'),
          };

          const { from, to } = filterCreatedAtDateRange(activeFilters[key], defaultRange) ?? {};

          if (!from || !to) {
            return;
          }

          if (key === 'date') {
            params = params.append('dateFrom', from);
            params = params.append('dateTo', to);
            params = params.append('timeZone', systemTimeZone);
            return;
          }
        } else {
          params = params.append(key, activeFilters[key]!);
        }
      });
    }

    return this.backendService
      .get<ExceptionListResponse>(`${environment.exceptionManagementEndpoint}/exceptions`, {
        params,
      })
      .pipe(
        map((response) => {
          const { content = [] } = response;
          return content.map((item) => this.mapExceptionItem(item));
        }),
      );
  }

  getExceptionDetails({ exceptionId }: { exceptionId: string }): Observable<ExceptionItem> {
    return this.backendService.get<ExceptionItemRaw>(`${environment.exceptionManagementEndpoint}/exceptions/${exceptionId}`).pipe(
      tap({
        next: (response) => {
          const { createdBy, updatedBy } = response;
          this.store.dispatch(AuditActions.loadTeamMembers({ ids: compact([createdBy, updatedBy]) }));
        },
      }),
      map((response) => this.mapExceptionItem(response)),
    );
  }

  updateExceptionById({
    exceptionId,
    data,
  }: {
    exceptionId: string;
    data: { assignedTo?: string | null; status?: string };
  }): Observable<ExceptionItem> {
    const headers = new HttpHeaders().set('Content-Type', 'application/merge-patch+json');

    return this.backendService
      .patch<ExceptionItemRaw>(`${environment.exceptionManagementEndpoint}/exceptions/${exceptionId}`, data, { headers })
      .pipe(
        tap({
          next: (response) => {
            const { createdBy, updatedBy } = response;
            this.store.dispatch(AuditActions.loadTeamMembers({ ids: compact([createdBy, updatedBy]) }));
          },
        }),
        map((response) => this.mapExceptionItem(response)),
      );
  }

  getExceptionHistory({
    exceptionId,
    requestParams,
  }: {
    exceptionId: string;
    requestParams: RequestPageParams;
  }): Observable<ExceptionHistoryListResponse> {
    const { page, size, sortParams } = requestParams;

    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() }).set('page', `${page ?? 0}`).set('size', `${size ?? 100}`);

    if (sortParams) {
      params = params.append('sort', `${sortParams.key},${sortParams.sortDir}`);
    }

    return this.backendService.get<ExceptionHistoryListResponse>(
      `${environment.exceptionManagementEndpoint}/exceptions/${exceptionId}/history`,
      {
        params,
      },
    );
  }

  mapExceptionItem(item: ExceptionItemRaw): ExceptionItem {
    const assignedToName = item.assignedTo ? compact([item.assignedTo.firstName, item.assignedTo.lastName]).join(' ') : '';
    const assignedToEmail = item.assignedTo?.email ?? ' ';
    return {
      ...item,
      typeLabel: exceptionType[item.type] ?? item.type,
      categoryLabel: exceptionCategory[item.category] ?? item.category,
      assignee: `${assignedToName}::${assignedToEmail}`,
      entityLink:
        item.category === 'BANK_ACCOUNT_VERIFICATION'
          ? '/app/settings/business-profile'
          : `/app/exception-management/exceptions/${item.id}/transactions/${item.entityId}`,
    };
  }
}
