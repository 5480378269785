import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, map, switchMap, throwError } from 'rxjs';

import { environment } from '@env';
import { constants } from '@shared/constants';
import { CustomHttpParamEncoder } from '@shared/encoder';
import {
  FilterValues,
  LinxStpRequestListItem,
  LinxV1Request,
  LinxV1RequestCreateResponse,
  LinxV1RequestFormInstanceRaw,
  LinxV1RequestList,
  LinxV1RequestListItem,
  LinxV1RequestListRaw,
  RequestPageParams,
} from '@shared/models';
import { StorageService } from '@shared/services';
import { BackendService } from '@shared/services/backend.service';
import { filterCreatedAtDateRange, filterCustomDate } from '@shared/utils';

import { mapLinxRequest, mapLinxRequestList } from './linx-mapping-utils';

@Injectable({
  providedIn: 'root',
})
export class LinxV1Service {
  linxV1ListFilter = new BehaviorSubject<FilterValues>({});
  linxStpListFilter = new BehaviorSubject<FilterValues>({});

  constructor(
    private backendService: BackendService,
    private storageService: StorageService,
  ) {}

  public setLinxFilterParams(filters: FilterValues): void {
    this.storageService.setItem(constants.LINX_V1_REQUESTS_FILTER, filters);
    this.updateLinxFilterParams(filters);
  }

  public getLinxFilterParams(): void {
    const filters = this.storageService.getItem<FilterValues>(constants.LINX_V1_REQUESTS_FILTER);

    if (filters && Object.keys(filters).length) {
      this.updateLinxFilterParams(filters);
    }
  }

  private updateLinxFilterParams(filters: FilterValues): void {
    this.linxV1ListFilter.next(filters);
  }

  public setLinxStpFilterParams(filters: FilterValues): void {
    this.storageService.setItem(constants.LINX_STP_REQUESTS_FILTER, filters);
    this.updateLinxStpFilterParams(filters);
  }

  public getLinxStpFilterParams(): void {
    const filters = this.storageService.getItem<FilterValues>(constants.LINX_STP_REQUESTS_FILTER);

    if (filters && Object.keys(filters).length) {
      this.updateLinxStpFilterParams(filters);
    }
  }

  private updateLinxStpFilterParams(filters: FilterValues): void {
    this.linxStpListFilter.next(filters);
  }

  getFormInstances(requestParams: RequestPageParams, isStp = false): Observable<LinxV1RequestList> {
    const params = this.linxRequestParams(requestParams, isStp);

    return this.backendService.get<LinxV1RequestListRaw>(`${environment.linxV1Endpoint}`, { params }).pipe(
      map((response) => mapLinxRequestList(response)),
      catchError((errorRes) => throwError(() => errorRes)),
    );
  }

  getFormInstance(formInstanceId: LinxV1RequestListItem['id']): Observable<LinxV1RequestListItem> {
    return this.backendService.get<LinxV1RequestFormInstanceRaw>(`${environment.linxV1Endpoint}/${formInstanceId}`).pipe(
      map((response) => mapLinxRequest(response)),
      catchError((errorRes) => throwError(() => errorRes)),
    );
  }

  getStpFormInstance(formInstanceId: LinxStpRequestListItem['id']): Observable<LinxStpRequestListItem> {
    return this.backendService.get<LinxV1RequestFormInstanceRaw>(`${environment.linxV1Endpoint}/${formInstanceId}`).pipe(
      map((response) => mapLinxRequest(response)),
      catchError((errorRes) => throwError(() => errorRes)),
    );
  }

  updateFormInstance(
    formInstanceId: LinxV1RequestListItem['id'],
    action: string,
    formData?: LinxV1RequestListItem,
  ): Observable<LinxV1RequestListItem> {
    return this.backendService
      .update<LinxV1RequestFormInstanceRaw>(`${environment.linxV1Endpoint}/${formInstanceId}?action=${action}`, formData)
      .pipe(
        switchMap(({ instance }) => this.getFormInstance(instance?.instanceId)),
        catchError((errorRes) => throwError(() => errorRes)),
      );
  }

  triggerLinxRequest(formData: LinxV1Request): Observable<LinxV1RequestCreateResponse> {
    return this.backendService.post<LinxV1RequestCreateResponse>(`${environment.linxV1Endpoint}`, formData).pipe(
      switchMap(({ instance }) => this.activateLinxRequest(instance?.instanceId)),
      catchError((errorRes) => throwError(() => errorRes)),
    );
  }

  activateLinxRequest(instanceId?: string): Observable<LinxV1RequestCreateResponse> {
    return this.backendService
      .update<LinxV1RequestCreateResponse>(`${environment.linxV1Endpoint}/${instanceId}?action=activate`, {})
      .pipe(catchError((errorRes) => throwError(() => errorRes)));
  }

  linxRequestParams(requestParams: RequestPageParams, isStp: boolean): HttpParams {
    const { page, size, searchString, sortParams, activeFilters } = requestParams;
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() });

    if (isStp) {
      params = params.append('collectionName', 'stp-transaction');
    } else {
      params = params.append('collectionName', 'send-money-capture');
    }
    params = params.append('collectionVersion', 1);

    params = params.append('size', size || constants.TABLE_ROWS);

    if (page) {
      params = params.append('page', page);
    }

    if (sortParams) {
      params = params.append('sort', `${sortParams.key},${sortParams.sortDir}`);
    }

    if (searchString) {
      params = params.append('search', searchString);
    }

    if (activeFilters) {
      const filterByAttributes: string[] = [];
      Object.keys(activeFilters).forEach((key) => {
        if (key === 'createdAt') {
          const { from, to } = filterCreatedAtDateRange(activeFilters[key]) ?? {};

          if (!from || !to) {
            return;
          }
          filterByAttributes.push(`createdAtFrom=${from}`);
          filterByAttributes.push(`createdAtTo=${to}`);
        } else if (key === 'expiry') {
          const customDate = filterCustomDate(activeFilters[key]) ?? undefined;
          if (!customDate) {
            return;
          }
          filterByAttributes.push(`expiry=${customDate}`);
        } else {
          filterByAttributes.push(`${key}=${activeFilters[key]}`);
        }
      });

      if (filterByAttributes.length > 0) {
        params = params.append('filterByAttributes', filterByAttributes.join('&'));
      }
    }
    return params;
  }
}
