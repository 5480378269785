import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl } from '@angular/forms';

import { emptySpacesValidator } from '@shared/validators';

import { FieldDisableDirective } from '../../../directives/field-disable.directive';
import { NoteFormItemComponent } from '../../notes/note-form-item/note-form-item.component';

@Component({
  selector: 'app-move-note-control',
  templateUrl: 'move-note-control.component.html',
  styleUrls: ['move-note-control.component.scss'],
  standalone: true,
  imports: [NoteFormItemComponent, FormsModule, ReactiveFormsModule, FieldDisableDirective],
})
export class MoveNoteControlComponent {
  @Input() isMoveFieldsDisabled = false;

  @Output() changed = new EventEmitter<string>();

  moveNotes = new UntypedFormControl('', [emptySpacesValidator]);

  onNoteFieldBlur(): void {
    this.moveNotes.setValue(this.moveNotes.value?.trim());
    this.changed.emit(this.moveNotes.value);
  }

  onChange(value: string): void {
    this.changed.emit(value);
  }

  resetValue(): void {
    this.moveNotes.reset();
  }
}
