<div
  class="account-balances-container"
  [class.account-balances-container-wide]="isFullWidth"
  [class.no-bottom-offset]="noBottomOffset"
  [class.account-balances-container-vertical]="isVerticalOrientation"
>
  <div class="account-balances-header">
    <div class="account-balances-title">{{ label }}</div>
  </div>

  <div class="account-balances">
    <div class="balance-items">
      <ng-container *ngFor="let item of accountBalances">
        <ng-container
          *ngTemplateOutlet="balanceItem; context: { $implicit: item, value: accountBalanceValues?.[item.type], isLoading }"
        ></ng-container>
      </ng-container>
    </div>

    <div class="balance-items balance-pending-items">
      <ng-container *ngFor="let item of pendingBalances">
        <ng-container
          *ngTemplateOutlet="
            balanceItem;
            context: { $implicit: item, value: accountBalanceValues?.[item.type], isLoading, isPendingBalance: true }
          "
        ></ng-container>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #balanceItem let-item let-value="value" let-isLoading="isLoading" let-isPendingBalance="isPendingBalance">
  <div class="account-balances-item" [class.primary]="item.isPrimary" [class.loading]="isLoading">
    <ng-container *ngIf="!isLoading; else loadingPlaceholder">
      <div class="label">
        {{ item.label }}

        <div class="label-tooltip"><rkt-icon name="info" [title]="item.tooltip"></rkt-icon></div>
      </div>

      <div class="value" *ngIf="available">
        <span class="value-icon" *ngIf="item.icon" [ngClass]="'value-icon-' + item.icon"></span>
        <div class="account-balance">
          {{ (value ? (isPendingBalance ? (value | abs) : value) : '0.00') | currency: selectedCurrency : 'symbol-narrow' }}
        </div>
      </div>

      <div class="value not-available" *ngIf="!available">N/A</div>
    </ng-container>

    <ng-template #loadingPlaceholder>
      <div class="label">
        <div class="loading-placeholder" [class.dark-bg]="isPendingBalance"></div>
      </div>

      <div class="value">
        <div class="loading-placeholder" [class.dark-bg]="isPendingBalance"></div>
      </div>
    </ng-template>
  </div>
</ng-template>
