import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { TransactionActivityByFaIdsComponent } from '../../transactions/transaction-activity-by-fa-ids/transaction-activity-by-fa-ids.component';

@Component({
  selector: 'app-external-financial-account-activity-table',
  templateUrl: 'external-financial-account-activity-table.component.html',
  standalone: true,
  imports: [TransactionActivityByFaIdsComponent],
})
export class ExternalFinancialAccountActivityTableComponent {
  public financialAccountId?: string | null;

  get participatingFinancialAccountId() {
    return this.financialAccountId ? [this.financialAccountId] : [];
  }

  constructor(private activatedRoute: ActivatedRoute) {
    this.financialAccountId = this.activatedRoute.snapshot?.parent?.paramMap.get('id');
  }
}
