import { Component, input, output } from '@angular/core';
import { RktIconComponent } from '@rocketfinancialcorp/rocket-ui/icon';

import { CollapsibleRowComponent } from '@shared/components/collapsible-row/collapsible-row.component';
import { LinxAccountType, LinxEntityType } from '@shared/constants';
import { FinancialAccountType, LinxRequestMoveMoneyDetails } from '@shared/models';
import { SolutionNamePipe } from '@shared/pipes';

import { LinxMoveMoneyEditComponent } from './linx-move-money-edit/linx-move-money-edit.component';

@Component({
  selector: 'app-linx-move-money-step',
  standalone: true,
  imports: [CollapsibleRowComponent, SolutionNamePipe, LinxMoveMoneyEditComponent, RktIconComponent],
  templateUrl: './linx-move-money-step.component.html',
})
export class LinxMoveMoneyStepComponent {
  isRowExpanded = input<boolean>(false);

  isRowHasValue = input<boolean>(true);

  isEditDisabled = input<boolean>(false);

  accountType = input<LinxAccountType>();

  entityType = input<LinxEntityType>();

  isTemplate = input<boolean>(false);

  financialAccountType = input<FinancialAccountType>();

  customerId = input<string>();

  moveMoneyDetails = input<LinxRequestMoveMoneyDetails>();

  expandRow = output();

  cleared = output();

  continue = output<LinxRequestMoveMoneyDetails | undefined>();
}
