import { AsyncPipe, NgIf, NgTemplateOutlet } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewChecked, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { isEmpty, isUndefined } from 'lodash-es';
import { Subject, takeUntil } from 'rxjs';
import { validate as uuidValidate } from 'uuid';
import { RktButtonDirective } from '@rocketfinancialcorp/rocket-ui/button';
import { RktIconComponent } from '@rocketfinancialcorp/rocket-ui/icon';
import { ModalService } from '@rocketfinancialcorp/rocket-ui/modal';
import { DatatableColumn, DatatableSorting, FetchData, RktTableComponent } from '@rocketfinancialcorp/rocket-ui/table';

import {
  FilterMenuComponent,
  IdentityVerificationErrorModalComponent,
  SubmitIdentityVerificationComponent,
  SuccessModalComponent,
  getIdentityFilterFieldOptions,
} from '@shared/components';
import { IDV_ERROR_MESSAGES_BY_CODES } from '@shared/constants';
import {
  FilterField,
  FilterValues,
  IdentityVerificationApplication,
  IdentityVerificationApplicationList,
  RequestPageParams,
  SubmitIdentityVerificationApplicationParams,
} from '@shared/models';
import { IdentityVerificationService } from '@shared/services';
import { ErrorUtils, activeFilters, safeJSONparse } from '@shared/utils';

import { AccessControlPipe } from '../../../pipes/access-control.pipe';

@Component({
  selector: 'app-identity-verification-list',
  templateUrl: './identity-verification-list.component.html',
  styleUrls: ['./identity-verification-list.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    NgTemplateOutlet,
    AsyncPipe,
    RktButtonDirective,
    AccessControlPipe,
    RktIconComponent,
    RktTableComponent,
    FilterMenuComponent,
  ],
})
export class IdentityVerificationListComponent implements OnInit, AfterViewChecked, OnDestroy {
  @Input() accountHolder!: string;

  @Output() toggleWatchlistEvents = new EventEmitter();

  customerIDVApplications: IdentityVerificationApplicationList['items'] = [];

  totalElements = 0;

  loading = true;

  customerType!: string;

  isFilterActive = false;

  activeFilters: FilterValues = {};

  filters!: FilterField[];

  columns: DatatableColumn[] = [
    { name: 'Created At', prop: 'createdAt', fixedSize: 200, isSortable: true, sortProp: 'createdAt', colType: 'date-time' },
    { name: 'IDV Profile', prop: 'identityProfileIdName', flexSize: 300 },
    { name: 'IDV Application ID', prop: 'shortId', fixedSize: 200, colType: 'short-id' },
    { name: 'Customer ID', prop: 'customerAccountShortId', fixedSize: 200, colType: 'short-id' },
    { name: 'Name', prop: 'displayName', flexSize: 300, isSortable: false },
    { name: 'Outcome', prop: 'outcome', flexSize: 200, colType: 'status-tag' },
    {
      name: 'Status',
      prop: 'latestStatus',
      sortProp: 'latestStatus',
      fixedSize: 200,
      isSortable: false,
      colType: 'status-tag',
    },
    { name: 'Completed At', prop: 'completedAt', flexSize: 200, isSortable: true, sortProp: 'completedAt', colType: 'date-time' },
  ];

  sortParams: DatatableSorting = { key: 'createdAt', sortProp: 'createdAt', sortDir: 'desc' };

  emptyListMessage = 'No IDV Applications found.';

  get activeFiltersCount(): number {
    return Object.keys(this.activeFilters).length;
  }

  get activePage(): number {
    const routePage = this.activatedRoute?.snapshot?.queryParams?.page;
    return routePage ? parseInt(routePage, 10) : 0;
  }

  get isIndividualEntity() {
    return uuidValidate(this.accountHolder);
  }

  private destroy$ = new Subject<void>();

  constructor(
    private identityVerificationService: IdentityVerificationService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private modalService: ModalService,
    public ref: ChangeDetectorRef,
  ) {
    this.customerType = this.activatedRoute.snapshot.data?.customerType === 'individual' ? 'individuals' : 'businesses';
    this.filters = getIdentityFilterFieldOptions(this.customerType);
    this.identityVerificationService.setWatchlistEventsFilterParams({});
  }

  ngOnInit() {
    this.initFilterList();
  }

  ngAfterViewChecked(): void {
    this.ref.detectChanges();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.modalService.dismissAll();
  }

  initFilterList(): void {
    this.identityVerificationService.idvListFilter.pipe(takeUntil(this.destroy$)).subscribe({
      next: (filters: FilterValues) => {
        if (isEmpty(filters)) {
          this.identityVerificationService.getIdvFilterParams();
        } else {
          this.activeFilters = filters;
          this.isFilterActive = this.activeFiltersCount > 0;
        }
      },
    });
  }

  onSubmitIdentityVerification() {
    const customerModalRef = this.modalService.open(SubmitIdentityVerificationComponent, {
      className: 'entity-form-modal',
    });

    const customerType = this.customerType === 'individuals' ? 'INDIVIDUAL_CUSTOMER' : 'BUSINESS_CUSTOMER';
    customerModalRef.componentInstance.modalInitData({ holderId: this.accountHolder, accountHolderType: customerType });

    customerModalRef.result.then(
      (result) => {
        if (result) {
          this.submitIdentityVerification(result);
        }
      },
      () => false,
    );
  }

  submitIdentityVerification(submitIdentityData: SubmitIdentityVerificationApplicationParams) {
    this.identityVerificationService
      .submitIdentityVerification(submitIdentityData)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          const identityVerificationSuccessModal = this.modalService.open(SuccessModalComponent, {
            className: 'success-modal',
          });

          identityVerificationSuccessModal.componentInstance.actionName = 'submitted';
          identityVerificationSuccessModal.componentInstance.type = 'Identity Verification';
          identityVerificationSuccessModal.result.finally(() => {
            this.getCustomerIDVApplications({ page: 0 });
          });
        },
        error: (error) => {
          this.handleError(error);
        },
      });
  }

  updateQueryParams(page?: RequestPageParams['page']): void {
    if (!isUndefined(page) && this.activePage !== page) {
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: { page: page > 0 ? page : undefined },
        queryParamsHandling: 'merge',
      });
    }
  }

  getCustomerIDVApplications({ page, size, sortParams }: FetchData) {
    if (!this.accountHolder) {
      return;
    }

    this.loading = true;

    this.updateQueryParams(page);

    const fetchParamData = this.isIndividualEntity ? `accountId=${this.accountHolder}` : `accountTypes=${this.accountHolder}`;

    if (sortParams) {
      this.sortParams = sortParams;
    }

    this.identityVerificationService
      .getCustomerIDVApplications(fetchParamData, {
        page: page ?? 0,
        size,
        sortParams: sortParams ?? this.sortParams,
        activeFilters: this.activeFilters,
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (idvApplicationListItems) => {
          this.emptyListMessage = 'No IDV Applications found.';
          this.onFetchComplete(idvApplicationListItems);
        },
        error: (error) => {
          this.emptyListMessage = 'Unable to fetch Customer IDV applications.';
          this.onFetchComplete();
          ErrorUtils.catchError('identityVerificationService.getCustomerIDVApplications', error);
        },
      });
  }

  onFetchComplete(idvApplicationListItems?: IdentityVerificationApplicationList): void {
    const { items = [], totalElements = 0 } = idvApplicationListItems ?? {};
    this.customerIDVApplications = items;
    this.totalElements = totalElements;
    this.loading = false;
  }

  handleError(error: string | number | HttpErrorResponse): void {
    const errorModalRef = this.modalService.open(IdentityVerificationErrorModalComponent, { className: 'identity-verification-modal' });

    const errorObj = safeJSONparse(error as string);
    if (errorObj?.errors) {
      const errorBlocks = this.identityVerificationService.parseIDVSubmitError(errorObj.errors);
      errorModalRef.componentInstance.errorBlocks = errorBlocks;
      errorModalRef.componentInstance.modalSubTitle = 'Missing information. Please fill out the following customer data to try again:';
    } else if (errorObj.errorCode) {
      errorModalRef.componentInstance.messageText = IDV_ERROR_MESSAGES_BY_CODES[errorObj.errorCode];
    } else if (typeof error === 'string') {
      errorModalRef.componentInstance.messageText = error.match(/'.*?'/g)![2] || error;
    }

    errorModalRef.result.finally(() => {
      this.getCustomerIDVApplications({ page: 0 });
    });

    ErrorUtils.catchError('identityVerificationService.submitIdentityVerification error', error);
  }

  applyFilters(filterValues: FilterValues): void {
    this.activeFilters = activeFilters(filterValues);
    this.identityVerificationService.setIdvFilterParams(this.activeFilters);
    this.getCustomerIDVApplications({ page: 0 });
  }

  onRowClick({ id, accountId }: IdentityVerificationApplication) {
    this.router.navigateByUrl(`/app/customers/${this.customerType}/${accountId}/identity-verification/${id}`);
  }
}
