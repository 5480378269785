<button class="close-button" (click)="activeModal.close()"><rkt-icon name="times"></rkt-icon></button>

<h1 class="title">Terms & Conditions</h1>

<div class="content">
  <h3>Welcome to RocketKOR</h3>

  <p>Thank you for using our products and services.</p>

  <p>
    Please carefully review the following terms that govern your use of our Site and Application/s, and also read our Privacy Policy
    regarding the information that you provide to us.
  </p>
  <p>
    These Terms and Conditions of Use (“Terms of Use”) apply to the world wide web site owned, operated, licensed, and controlled by Rocket
    Financial Inc. (“RocketKOR” or “we” or “us”), including its related, affiliated, or subsidiary companies (collectively, the “Site”) and
    any mobile phone application we may provide (“App”). This Site and the App are the property of RocketKOR. By using this Site or the App,
    you agree to these Terms of Use; if you do not agree, do not use this Site or the App.
  </p>
  <p>
    These Terms of Use hereby incorporate by reference specific terms, descriptions, conditions, limitations and exclusions relating to
    RocketKOR services that may be provided under each of its programs. Please note: once programs or services become generally available,
    specific terms may be set, and will be detailed in updates to this document and under Support and Frequently Asked Questions (“FAQ”).
    RocketKOR reserves the right, at its sole discretion, to change, modify, add or remove portions of these Terms of Use, at any time. It
    is your responsibility to check these Terms of Use periodically for changes. Your continued use of the Site or the App following the
    posting of changes will mean that you accept and agree to the changes.
  </p>
  <p>
    As long as you comply with these Terms of Use, RocketKOR grants you a non-exclusive, non-transferable, limited license (the “License”)
    to enter and use the Site and any App and access our services (the “Services”). RocketKOR reserves the right, at its sole discretion, to
    change, modify, add or remove Services, or portions of Services, at any time. It is your responsibility to check these Terms of Use
    periodically for changes. Your continued use of the Site or the App following the posting of changes will mean that you accept and agree
    to the changes.
  </p>
  <p>
    Subject to applicable law, we reserve the right, in our sole discretion, to terminate the License, your use of the Site and the App,
    your Account (defined below) or any Service provided to you (subject to the terms of each Service) and to assert our legal rights with
    respect to content or use of the Site, the App or Services that we reasonably believe is, or might be, in violation of these Terms or
    the terms of the App or any Service.
  </p>

  <h3 class="my-4">Privacy</h3>

  <p>
    Please review our <a class="underline" [routerLink]="'/privacy-policy'">Privacy Policy</a> (which also governs your visit to our Site
    and use of our App) to understand our practices. Additionally, by using the Site or the App, you acknowledge and agree that Internet
    transmissions are never completely private or secure and that any message or information you send to the Site or the App may be read or
    intercepted by others, notwithstanding our efforts to protect such transmissions.
  </p>

  <h4>Intellectual Property</h4>

  <p>
    This Site, the App, the content, any materials downloaded, and all intellectual property pertaining to or contained on the Site or the
    App (including but not limited to copyrights, patents, database rights, graphics, designs, text, logos, trade dress, trademarks and
    service marks) are owned by RocketKOR or third parties, and all right, title and interest therein shall remain the property of RocketKOR
    and/or such third parties (collectively, the “Content”).
  </p>

  <h4>Electronic Communications:</h4>

  <p>
    When you visit the Site, use an App, send emails to us, or communicate with us through an App, you are communicating with us
    electronically, and you consent to receive communications from us electronically to the extent permissible by law. We will communicate
    with you by email or by posting notices on this Site or through an App. You agree that all agreements, notices, disclosures and other
    communications that we provide to you electronically satisfy any legal requirement that such communications be in writing, to the extent
    permissible by law.
  </p>
  <p>
    You agree that we may send emails or App notifications to you for the purpose of advising you of any changes or additions to the Site or
    the App, changes to any of our products or services, or for such other purposes as we deem appropriate and as permissible by law. By
    accessing the Site or utilizing the App or our Services you agree to our ESign Consent and agree to receive disclosures and other
    information in electronic form. Please make sure to review the Consent to ESIGN and Electronic Communications below for further
    information about e-sign of Service documents
  </p>

  <h3 class="my-4">Consent to ESIGN and Electronic Communications</h3>

  <p style="font-weight: 600">
    This Electronic Signature Consent (“E-Sign Consent”) constitutes your consent to receive disclosures and other information
    (“Communications”) in electronic form in compliance with the federal E-Sign Act (15 U.S.C §7001, et. seq.) and applicable state laws.
    RocketKOR is required by law to provide you with certain disclosures. RocketKOR is not permitted to provide those disclosures to you
    electronically without your consent. We will provide Communications to you by posting them on our Site or App, accessible after you
    log-in, and/or by emailing them to you at your registered email address. It is your responsibility to keep your registered email address
    and App up to date so that we can communicate with you electronically.
  </p>

  <p>
    By consenting to E-Sign Consent, you also agree that RocketKOR may provide electronically the Privacy Policy, any other disclosures in
    connection with your Services and any and all communications concerning our decisions on your Services (the “Disclosures”). The
    Disclosures may include, in addition to other things, disclosures pursuant to any applicable federal, state or local law or regulation.
  </p>

  <p>Consumer E-Sign Consent:</p>
  <p>Your E-Sign Consent constitutes your agreement to the terms above and the terms below:</p>

  <ul>
    <li>
      RocketKOR may provide Disclosures required by law and any other information concerning your legal rights and duties and your Account
      to you electronically.
    </li>
    <li>
      RocketKOR can send any billing statements, Communications, or Disclosures to you electronically via our Site or App, or to the email
      address or phone number that you have provided.
    </li>
    <li>
      RocketKOR will notify you via e-mail or text or App notifications when Disclosures are available. The email or text or App
      notification will contain instructions on how to view the Disclosures. The Disclosures will be available for at least 30 days and will
      be provided to you in a format that can either be printed or downloaded for your records.
    </li>
  </ul>

  <p>Your electronic signature on agreements and documents has the same effect as if you physically signed the document with a pen.</p>

  <p>
    This consent applies to any transaction undertaken through RocketKOR in the past, to all future Disclosures and Communications on your
    Account, to all future transactions, at any time, and to any transaction with us.
  </p>

  <ul>
    <li>
      You agree that any email address or mobile phone number you provide may be used to provide you with Disclosures and Communications.
    </li>
    <li>
      You agree to notify RocketKOR of any changes in your email address or mobile phone number by notifying RocketKOR either in writing or
      via email at least five (5) days before the change.
    </li>
  </ul>

  <p>
    Withdrawing Consent: You are free to withdraw your E-Sign Consent at any time if you choose to. If at any time you wish to withdraw your
    E-Sign Consent, you can do so by submitting a request through the Site or the App, contacting customer support, or writing to us at the
    address below. If you decide to withdraw your E-Sign Consent, the legal effectiveness, validity and/or enforceability of prior
    electronic Disclosures or Communication will not be affected, and your Account will be closed.
  </p>

  <div>
    <p class="m-0">Rocket Financial Inc.</p>
    <p class="m-0">21550 Biscayne Blvd., Ste, 400</p>
    <p>Aventura FL 33180</p>
  </div>

  <p>Minimum Requirements:</p>
  <p>To electronically receive, view and save or print the Communications and Disclosures, you must have a device equipped with:</p>
  <p>
    An internet browser - supported internet browsers include
    <strong>Microsoft Internet Explorer 10.0, Google Chrome 10, Firefox 3.6, Safari 10.8.4</strong>
    or any later version of these programs;
  </p>
  <p>
    A portable document file (‘pdf’) reader - supported pdf readers include Adobe Reader 6 or later version; and either a printer, a hard
    drive or other storage device.
  </p>
  <p>
    In the event that there is a change in the hardware or software requirements, and you cannot access the Communications and Disclosures
    as a result of this change, you have the right to withdraw your consent to this policy without the imposition of any condition,
    consequence, or fee for such withdrawal; and we would then request a new affirmative consent, either by mail or email or through the
    App.
  </p>

  <p>Legal Effect:</p>
  <p>
    Your E-Sign Consent means that Disclosures and Communications provided to you electronically shall have the same effect as if provided
    in paper form. A text message or email or App notification alerting you that Disclosures or Communications are available electronically,
    provided that the Disclosures or Communications are made available online, shall have the same meaning as if provided them to you in
    paper form, regardless of whether you actually view them, unless you have withdrawn your E-Sign Consent, as provided above, prior to
    receiving such Disclosures or Communications.
  </p>

  <p>Miscellaneous:</p>

  <p>
    You agree that RocketKOR may modify or change the methods of disclosure described herein, and that RocketKOR may send you Disclosures or
    Communications in paper form at its option. You may obtain a paper copy of an electronic Disclosure or Communication, provided that such
    request is made within a reasonable period of time after you first received the electronic Disclosure or Communication, by contacting
    RocketKOR at 21550 Biscayne Blvd., Ste. 400, Aventura FL 33180. There may be a fee for you to receive a paper copy of an electronic
    Disclosure or Communication, subject to applicable law.
  </p>

  <p>
    You may also withdraw your E-Sign Consent by contacting RocketKOR in the same manner. If you withdraw your ESign Consent, RocketKOR may
    elect to terminate its relationship with you. Such termination does not exterminate any obligations you may have to RocketKOR or one of
    its affiliates. You also agree that RocketKOR is not responsible for any delay or failure in your receipt of any text message or email
    notice or App notification that is not caused by RocketKOR’s failure to send such a notice to the mobile phone number or email address
    you have provided for that purpose or through the App.
  </p>

  <p>Accounts:</p>

  <p>
    In order to access or use the Services, the App, or many of the features offered by RocketKOR or its affiliates in connection with the
    Services, you will be required to create an account (your “Account”). Our practices governing any collection and use of your personal
    information are disclosed in RocketKOR’s privacy notice, located at https://rocketbnk.com/privacy-policy/ (the “Privacy Policy”). In
    creating and using your Account you agree that:
  </p>

  <ul>
    <li>You have the legal right and authority to enter into agreements, and hold a valid U.S. Tax Identification Number;</li>
    <li>
      You will not use a username, e-mail address or mobile phone number that is already being used by someone else, that infringes the
      intellectual property or other right of any person or entity, or is offensive;
    </li>
    <li>You will provide true, accurate, current, and complete information about yourself in connection with the registration process;</li>
    <li>You are solely responsible for all activities that occur in connection with your Account, password, and username;</li>
    <li>
      You are solely responsible for maintaining the confidentiality of your password and for restricting access to your device so that
      others may not access any password-protected portion of the Services using your name, username, or password;
    </li>
    <li>
      You will immediately notify RocketKOR of any unauthorized use of your Account, password, or username, or any other breach of security;
      and
    </li>
    <li>You will not sell, transfer, or assign your Account or any rights associated therewith.</li>
  </ul>

  <p>
    If any information that you provide, or if we have reasonable grounds to suspect that any information that you provide, is false,
    inaccurate, outdated, incomplete, or violative of these Terms of Use, any additional terms applicable to the Services, or any applicable
    law, then RocketKOR may suspend or terminate your Account with or without providing advance notice. You acknowledge and agree that
    RocketKOR also reserves the broad, general right to terminate or suspend your Account or to otherwise deny you access to it or its
    benefits, in RocketKOR’s sole discretion, for any reason or no reason, with or without advance notice. These Terms of Use will continue
    to apply until your formal relationship with RocketKOR is terminated, either by you or by RocketKOR. If you choose to terminate your use
    of the Services, you may do so by calling +1 (XXX) XXX-XXXX (NOTE (as of 3/18/20): phone based Support will not be available until
    service goes live) or by emailing RocketKOR at support&#64;rocketKOR.net, or by Cancelling your Account in the App. Once your use of the
    Services is terminated, your ability to log in may be deactivated. If you terminate your use of the Services, you will remain obligated
    to pay all outstanding fees and other amounts owed. If you have any questions or trouble terminating the use of the Services, please see
    Help in the App, FAQs on the RocketKOR.net website, or you can contact customer support for assistance. RocketKOR may continue to
    maintain information about you after your use of the Services is terminated only to the extent necessary to comply with applicable laws,
    and only to further legitimate business needs as outlined in the Privacy Policy.
  </p>

  <p>
    All agreements and disclosures that you enter into in connection with the Services will also govern your usage of the Site and the
    App.ROCKETKOR
  </p>

  <p>Links to Other Web Sites and Services:</p>

  <p>
    This Site and/or the App contains links to outside services and resources (“Third-Party Content”), the availability and content of which
    RocketKOR does not control. Some Third-Party Content may be custom matched based on information provided by you. We are not responsible
    for examining or evaluating, and we do not warrant the offering of, these businesses or individuals or the content of their web sites or
    mobile applications. RocketKOR does not assume any responsibility or liability for the actions, products, or content of these or any
    other thirdparties. RocketKOR does not guarantee that any service offered by Third-Party Content will be offered to you. RocketKOR may
    receive compensation from third-parties for referring you to the third-party or to their website or mobile application. Any services
    that you obtain through Third-Party Content will be subject to the terms and conditions and privacy policies of the Third-Party Content.
    Any concerns regarding any such service or resource, or any link thereto, should be directed to the particular outside service or
    resource.
  </p>

  <p>Information and Data Usage:</p>

  <p>
    RocketKOR may use all or any portion of information and data provided by you or obtained with your consent (“User Content”) for: (i)
    improvement of services; (ii) the use, distribution or disclosure of Aggregated Data, to the extent permitted by law; and (iii) fraud
    screening, identity verification, and account information verification. RocketKOR will also disclose User Content to third parties as
    outlined in these Terms and Conditions.
  </p>

  <h3 class="my-4">DISCLAIMER OF WARRANTIES AND LIMITATION OF LIABILITY</h3>

  <p>
    TO THE FULLEST EXTENT PERMISSIBLE PURSUANT TO APPLICABLE LAW, ROCKTKOR AND ITS DIRECTORS, OFFICERS, SHAREHOLDERS, SUBSIDIARIES,
    EMPLOYEES, SUCCESSORS IN INTEREST, AGENTS, CONTRACTORS, AFFILIATES, LICENSORS OR THIRD-PARTY PARTNERS AND SUPPLIERS (INCLUDING PAYMENT
    CARD NETWORKS AND PAYMENT PROCESSORS) AND THE SUCCESSORS OR ASSIGNS OF EACH (COLLECTIVELY “AFFILIATES”) EXPRESSLY DISCLAIM ANY
    WARRANTIES, EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING WITHOUT LIMITATION WARRANTIES OF MERCHANTABILITY, FITNESS FOR ANY
    PARTICULAR PURPOSES, AND NON-INFRINGEMENT. ROCKTKOR AND ITS AFFILIATES DO NOT WARRANT THE DATA, CONTENT, ANALYTICS FEATURES, OR
    INFORMATION PROVIDED THROUGH THE SERVICES, INCLUDING WITHOUT LIMITATION TRANSACTION DATA OR USER SUBMISSIONS OR OTHER DATA PROVIDED BY
    OTHER USERS, TO BE UNINTERRUPTED, ACCURATE, USEFUL, OR FREE OF ERRORS, VIRUSES, OR OTHER HARMFUL COMPONENTS.
  </p>

  <p>
    UNDER NO CIRCUMSTANCES WILL ROCKTKOR OR ITS AFFILIATES BE LIABLE FOR ANY SPECIAL, INDIRECT, INCIDENTAL, CONSEQUENTIAL, PUNITIVE, OR
    EXEMPLARY DAMAGES ARISING OR RELATED TO THE SERVICES, EVEN IF ROCKTKOR OR ITS AFFILIATES HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH
    DAMAGES.
  </p>

  <p>
    IN NO EVENT WILL ROCKTKOR’S OR ITS AFFILIATES’ TOTAL LIABILITY TO YOU FOR ALL DAMAGES, LOSSES, AND CAUSES OF ACTION ARISING OUT OF OR
    RELATING TO THESE TERMS OR YOUR USE OF THE SITE, THE APP OR THE SERVICES (WHETHER IN CONTRACT, TORT INCLUDING NEGLIGENCE, WARRANTY, OR
    OTHERWISE) EXCEED THE AMOUNT PAID BY YOU, IF ANY, FOR ACCESSING THE SITE, THE APP OR THE SERVICES DURING THE THREE (3) CALENDAR MONTHS
    PRECEDING YOUR CLAIM.
  </p>

  <p>User Generated Content</p>

  <p>
    You hereby grant RocketKOR a non-exclusive, transferable, unlimited, worldwide, irrevocable, sub-licensable and royalty-free license to
    publish, host, copy, distribute, modify, create derivative works from, reproduce, display, perform, transmit, process or otherwise use
    in any manner whatsoever and in all formats or distribution methods now known or developed hereafter, all or any portion of your User
    Content for the purposes of providing, optimizing, improving and promoting our Services. You agree this license includes the right to
    use your name, persona and likeness included in any User Content in connection with delivering the Services, without any obligation or
    compensation to you.
  </p>

  <p>
    You agree this license includes the right for RocketKOR to publish your User Content in a searchable format that may be accessed by
    other users of the Services.
  </p>

  <p>
    You further agree not to post, upload to, transmit, distribute, store, create or otherwise publish through the Services any of the
    following:
  </p>

  <ul>
    <li>
      User Content that is libelous, defamatory, profane, obscene, pornographic, sexually explicit, indecent, lewd, vulgar, suggestive,
      violent, harassing, hateful, threatening, offensive, discriminatory, bigoted, abusive, inflammatory, invasive of privacy or publicity
      rights, fraudulent, deceptive or otherwise objectionable;
    </li>

    <li>
      User Content that is illegal or unlawful, that would constitute, encourage or provide instructions for a criminal offense, violate the
      rights of any party, or otherwise create liability or violate any local, state, national or international law;
    </li>

    <li>
      User Content that may infringe or violate any patent, trademark, trade secret, copyright, or other intellectual or other right of any
      party;
    </li>

    <li>User Content that contains or depicts any statements, remarks or claims that do not reflect your honest views and experiences;</li>

    <li>
      Private information of any third-party including, but not limited to, addresses, phone numbers, email addresses, Social Security
      numbers or credit card numbers;
    </li>

    <li>Unsolicited promotions, political campaigning, or commercial messages (SPAM) or any chain messages;</li>

    <li>User Content designed to deceive or trick the user of the Services;</li>

    <li>
      Viruses, corrupted data or other harmful, disruptive or destructive files or code, script or other software designed to automate any
      functionality on the Services; or
    </li>

    <li>User Content that, in the sole judgment of RocketKOR, is objectionable.</li>
  </ul>

  <p>Identity Verification:</p>
  <p>
    To help the government fight the funding of terrorism and money laundering activities, Federal law requires all financial institutions
    to obtain, verify, and record information that identifies each person or entity that opens an account. As a result, under our customer
    identification program, we must ask for your name, street address (no P.O. boxes), date of birth / formation, tax identification number
    or other identification number, and other information that will allow us to identify you. We may also ask to see other identifying
    documents.
  </p>

  <p>Customer Communications:</p>
  <p>
    By accepting these Terms of Use, you expressly consent to be contacted by us, our agents, representatives, affiliates, or anyone calling
    on our behalf for any and all purposes, at any telephone number, or physical or electronic address you provide or at which you may be
    reached, or numbers or addresses we can reasonably associate with your Account (through skip trace, caller ID capture or other means),
    with information or questions about your application for any Services and/or Account. You agree we may contact you in any way, including
    on a recorded line, SMS messages (including text messages), calls using prerecorded messages or artificial voice, and calls and messages
    delivered using automated telephone dialing system or an automatic texting system. Automated messages may be played when the telephone
    is answered, whether by you or someone else. In the event that an agent or representative calls, he or she may also leave a message on
    your answering machine, voice mail, or send one via text. You understand that anyone with access to your telephone or email account may
    listen to or read the messages, notwithstanding our efforts to communicate only with you.
  </p>

  <p>
    You certify, warrant and represent that the telephone numbers that you have provided to us are your numbers and not someone else's. You
    represent that you are permitted to receive calls at each of the telephone numbers you have provided to us. If a telephone number you
    have provided to us changes, or if you cease to be the owner, subscriber or primary user of such telephone number, you agree to
    immediately give us notice of such facts so that we may update our records. Your cellular or mobile telephone provider will charge you
    according to the type of plan you carry. You agree that we will not be liable to you for any fees, inconvenience, annoyance or loss of
    privacy in connection with such calls or texts.
  </p>

  <p>Site Security:</p>

  <p>
    As a condition of your use of this Site and the App, you agree that you will not, and you will not take any action intended to: (i)
    access data that is not intended for you or attempt to reverse engineer the Site or the App; (ii) invade the privacy of, obtain the
    identity of, or obtain any personal information about any customer or user of this Site or the App; (iii) probe, scan or test the
    vulnerability of this Site or the App or the RocketKOR network or breach security or authentication measures without proper
    authorization; (iv) attempt to interfere with service to any user, host or network or otherwise attempt to disrupt our business,
    including, without limitation, via means of submitting a virus, worm, Trojan Horse, or other destructive feature to this Site or the
    App, overloading, “flooding,” “spamming,” “mail bombing” or “crashing;” (v) send unsolicited mail, email, texts or notifications,
    including promotions and/or advertising of products and services; or (vi) attempt to use robots, spiders, intelligent agents, software,
    tools, engines, or other means to navigate or search the Site or the App other than those generally available through third-party web
    browsers such as Microsoft Explorer or Google Chrome. Violations of system or network security may result in termination of Account and
    civil or criminal liability.
  </p>

  <p>Notice of Agreements Resolving Disputes and Arbitration:</p>

  <p>
    By using the Site or the App or any of the Services, you agree to be bound by these Agreements Resolving Disputes which includes the
    Arbitration Agreement (the “Dispute Agreement”), the full text of which are provided to you when you apply for or accept Services, which
    are hereby incorporated into these Terms and Conditions by reference as if fully set forth at this point. This Agreement provides that
    all claims arising from or relating to use of the Site, the App or the Services must be resolved by binding arbitration if the person or
    entity against whom a claim is made elects to arbitrate the claim. Thus, if the person or entity against whom the claim is made elects
    to arbitrate the claim, then you will not have the following important rights:
  </p>

  <ul>
    <li>You may not file or maintain a lawsuit in any court except a small claims court.</li>
    <li>You give up your right to have a jury decide your claim.</li>
    <li>
      You may not be afforded the procedural, pre-trial discovery, and appellate rights in an arbitration proceeding that you would enjoy in
      a court or judicial proceeding.
    </li>
    <li>
      You may not join or participate in a class action, act as a class representative or a private attorney general or consolidate your
      claim with the claims of others.
    </li>
    <li>You will have to pay certain fees in order to commence an arbitration proceeding, unless we agree to pay those fees for you.</li>
    <li>
      If you do not want to arbitrate all claims as provided in the Agreement, then you have the right to reject the Arbitration Provision
      by delivering a written notice to us at the Notice Address within thirty (30) days following the date of this Agreement.
    </li>
  </ul>

  <p>Indemnity:</p>

  <p>
    By using the Site or the App, you agree to indemnify and hold RocketKOR and its Affiliates harmless from and against any and all losses,
    claims, damages, costs and expenses (including reasonable legal and accounting fees) arising out of or in any way connected with (i)
    your access to and use of the Site, the App or the Services; (ii) your violation of any of the Terms and Conditions referenced herein,
    or any applicable law or regulation; (iii) your violation of any third-party right, including without limitation any intellectual
    property right, publicity, confidentiality, property or privacy right; or (iv) any disputes or issues between you and any third-party.
    RocketKOR reserves the right to assume or participate, at your expense, in the investigation, settlement and defense of any such action
    or claim.
  </p>

  <p>Minors</p>

  <p>
    This Site is not directed at individuals under the age of eighteen (18), or individuals under the age of nineteen (19) in the states of
    Alabama or Nebraska, and RocketKOR does not knowingly collect personal information from any individual under the age of eighteen (18),
    or under the age of nineteen (19) in the states of Alabama or Nebraska, at this Site or the App.
  </p>

  <p>Severability</p>

  <p>
    Any failure by us to exercise any rights or enforce any of these Terms of Use shall not constitute a waiver of such rights or terms. If
    any provision of these Terms of Use or their application in a particular circumstance shall be held by a court of competent jurisdiction
    to be contrary to law, such provision shall be changed and interpreted so as to best accomplish the objectives of the original provision
    to the fullest extent allowed by law. The remainder of these Terms of Use, or the application of such provision in other circumstances,
    shall not be affected thereby, and each provision hereof shall be valid and enforced to the fullest extent permitted by law.
  </p>

  <p>Entire Agreement</p>

  <p>
    These Terms of Use constitute the entire agreement between you and us with regard to your use of the Site, the App and the Services, and
    may be supplemented by additional terms of use or conditions applicable to the App or the Services. Any and all other written or oral
    agreements or understandings previously existing between you and RocketKOR with respect to such use are hereby superseded and cancelled.
  </p>

  <p>Assignment</p>

  <p>
    You may not assign or delegate this Agreement or any of the terms or conditions hereunder, directly, indirectly, or otherwise without
    the written consent of RocketKOR, and any such purported assignment or delegation shall be void.
  </p>

  <p>Law and Venue</p>

  <p>
    This Site is, and shall be deemed to be, located in the State of Florida. These Terms of Use and the relationship between you and
    RocketKOR shall be governed exclusively by the laws of the State of Florida without regard to its conflicts of law provisions. You agree
    to the personal jurisdiction by and venue in the state and federal courts located in Miami-Dade County, Florida and agree and consent
    that such courts are the exclusive forum for litigation of any claim or cause of action arising out of or relating to your use of this
    Site, the App or the Services. You agree that regardless of any statute or law to the contrary, any claim or cause of action arising out
    of or relating to your use of this Site, the App or the Services must be filed within one year after such claim or cause of action arose
    or be forever barred.
  </p>

  <p>Cookies</p>

  <p>
    “Cookies” are small data files transferred by a website to your computer's hard drive. RocketKOR or its service providers send cookies
    when you surf our site, or sites where RocketKOR ads appear, request or personalize information, or register yourself for certain
    Services. Accepting the cookies used on our Site, sites that are “powered by” another company on RocketKOR’s behalf, or sites where our
    ads appear may give us access to information about your browsing preferences, which we may use to personalize and enhance your
    experience. Cookies are typically classified as either “session” cookies or “persistent” cookies.
  </p>

  <p>Session cookies do not stay on your computer after you close your browser.</p>
  <p>Persistent cookies remain on your computer until you delete them, or they expire.</p>

  <p>
    Generally speaking, web browsers automatically accept cookies by default. That being said, you can typically prevent cookies or
    selectively accept cookies by adjusting the preferences in your browser. If cookies are not accepted, there may be some features of our
    Site that will not be available, and some websites/pages/content may not display properly.
  </p>

  <p>
    RocketKOR does not store passwords or any other information about a visitor in a cookie that would identify them, locate them, or
    determine their preferences or their financial activity. Aggregated customer information may help RocketKOR assess the performance of
    its Site and develop strategies to maximize utility. This information may be provided to other companies, including but not limited to
    third-party advertisers. This information does NOT include any personal details that can be used to identify individuals.
  </p>

  <p>How long does RocketKOR keep User Information?</p>

  <p>
    RocketKOR will maintain the information that is necessary to enable RocketKOR to provide the requested service only for as long as it
    takes RocketKOR to provide any such requested service. RocketKOR may still need to keep your user details to ensure that our systems
    reflect your preferences. RocketKOR may keep records of any transactions you enter into on the Site or the App for a minimum of six
    years from the end of RocketKOR’s relationship with you. RocketKOR may keep other information about you to the extent necessary to
    comply with applicable laws and to further legitimate business needs.
  </p>

  <p>Disclaimers</p>

  <p>
    ROCKTKOR DOES NOT WARRANT THAT ANY CONTENT ON THE SITE OR THE APP OR THE SERVICES, OR ANY DOCUMENT OR FEATURE OF THE SITE OR THE APP OR
    THE SERVICES WILL BE ERROR-FREE OR UNINTERRUPTED, OR THAT ANY DEFECTS WILL BE CORRECTED OR THAT YOUR USE OF THE SITE OR THE APP OR THE
    SERVICES WILL PROVIDE SPECIFIC RESULTS. THE CONTENT IN THE SITE OR THE APP OR THE SERVICES COULD INCLUDE TECHNICAL INACCURACIES OR
    TYPOGRAPHICAL ERRORS. THE SITE AND ITS CONTENT OR THE APP AND ITS CONTENT OR THE SERVICES AND ITS CONTENT ARE DELIVERED ON AN “AS-IS”
    AND “AS-AVAILABLE” BASIS. ALL INFORMATION PROVIDED ON OR THROUGH THE SITE OR THE APP OR THE SERVICES IS SUBJECT TO CHANGE WITHOUT
    NOTICE. ROCKTKOR CANNOT ENSURE THAT ANY FILES, DOCUMENTS OR OTHER DATA YOU DOWNLOAD FROM THE SITE OR THE APP WILL BE FREE OF VIRUSES OR
    CONTAMINATION OR DESTRUCTIVE FEATURES. ROCKTKOR DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING ANY WARRANTIES OF ACCURACY,
    NON-INFRINGEMENT, MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE. ROCKTKOR DISCLAIMS ANY AND ALL LIABILITY FOR THE ACTS, OMISSIONS
    AND CONDUCT OF ANY THIRD PARTIES IN CONNECTION WITH OR RELATED TO YOUR USE OF THE SITE OR THE APP OR THE SERVICES. ROCKTKOR DOES NOT
    VERIFY FOR ACCURACY ANY FINANCIAL INFORMATION PROVIDED TO US FROM THIRD-PARTIES. YOU ASSUME TOTAL RESPONSIBILITY FOR YOUR USE OF THE
    SITE OR THE APP OR THE SERVICES AND ANY LINKED SITES. YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR PROPERTY (INCLUDING YOUR
    DEVICES USED IN CONNECTION WITH THE SITE, THE APP OR THE SERVICES) OR LOSS OF DATA THAT RESULTS FROM THE USE OF THE SITE, THE APP OR THE
    SERVICES OR THE DOWNLOAD OR USE OF SUCH MATERIALS OR CONTENT. SOME JURISDICTIONS MAY PROHIBIT A DISCLAIMER OF WARRANTIES AND THEREFORE
    THIS DISCLAIMER WOULD NOT BE APPLICABLE IN THOSE JURISDICTIONS. YOU MAY ALSO HAVE OTHER RIGHTS THAT VARY FROM JURISDICTION TO
    JURISDICTION. YOUR SOLE REMEDY AGAINST ROCKTKOR FOR DISSATISFACTION IS TO STOP USING THE SITE, THE APP, THE SERVICES OR ANY SUCH
    CONTENT.
  </p>

  <p>Advertiser Disclosure</p>

  <p>
    Some of the offers that appear on this Site are from companies from which RocketKOR may receive compensation. This compensation may
    impact how and where products appear on this Site, including, for example, the order in which they may appear within listing categories.
    RocketKOR does not include all products or offers available in the marketplace. It is always your choice whether or not to apply for an
    offered product or service and we will never submit an application for a financial product or service on your behalf without your
    express consent. For further information, please refer to our Privacy Policy and Terms and Conditions.
  </p>
</div>

<div class="buttons">
  <button rktButton btnType="tertiary" (click)="downloadTermsConditions()">Download PDF</button>
  <button rktButton (click)="activeModal.close()">Close</button>
</div>
