import { Component, ElementRef } from '@angular/core';
import { ActiveModal } from '@rocketfinancialcorp/rocket-ui/modal';
import { SafePipe } from '@shared/pipes';
import { RktIconComponent } from '@rocketfinancialcorp/rocket-ui/icon';

@Component({
  selector: 'app-financial-account-verification-iframe',
  standalone: true,
  imports: [SafePipe, RktIconComponent],
  templateUrl: './financial-account-verification-iframe.component.html',
})
export class FinancialAccountVerificationIframeComponent {
  connectionURL!: string;

  constructor(
    public activeModal: ActiveModal,
    private elementRef: ElementRef,
  ) {}

  modelInitData(connectionURL: string): void {
    if (connectionURL) {
      this.connectionURL = connectionURL;
      const iframeElement = this.elementRef.nativeElement.querySelector('#iframe');
      const iframe = iframeElement.querySelector('iframe');
      if (iframe) {
        iframe.style.width = '100%';
        iframe.style.height = 'calc(100vh - 14.625rem)';
      }
    }
  }
}
