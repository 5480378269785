import { createAction, createActionGroup, emptyProps, props } from '@ngrx/store';

import {
  CardAlert,
  CardDispute,
  CardMerchant,
  CardProduct,
  CardProductRestrictions,
  CardProgram,
  CardRestriction,
  ErrorMessage,
  FinancialAccountDetails,
  InsightDocument,
  MccItem,
  RequestPageParams,
} from '@shared/models';

export namespace CardAccountActions {
  export const loadRestrictions = createAction('[Cards API] Load Card Restrictions');

  export const loadRestrictionsSuccess = createAction(
    '[Cards API] Load Card Restrictions Success',
    props<{ restrictionList: CardRestriction[] }>(),
  );

  export const loadRestrictionsError = createAction('[Cards API] Load Card Restrictions Error', props<{ error: ErrorMessage }>());

  export const loadRestrictionDetails = createAction('[Cards API] Load Card Restriction Details');

  export const loadRestrictionDetailsSuccess = createAction(
    '[Cards API] Load Card Restriction Details Success',
    props<{ restrictionDetails: CardRestriction }>(),
  );

  export const loadRestrictionDetailsError = createAction(
    '[Cards API] Load Card Restriction Details Error',
    props<{ error: ErrorMessage }>(),
  );

  export const loadAlerts = createAction('[Cards API] Load Card Alerts');
  export const loadAlertsSuccess = createAction('[Cards API] Load Card Alerts Success', props<{ alertList: CardAlert[] }>());
  export const loadAlertsError = createAction('[Cards API] Load Card Alerts Error', props<{ error: ErrorMessage }>());

  export const loadAlertDetails = createAction('[Cards API] Load Card Alert Details');
  export const loadAlertDetailsSuccess = createAction('[Cards API] Load Card Alert Details Success', props<{ alertDetails: CardAlert }>());
  export const loadAlertDetailsError = createAction('[Cards API] Load Card Alert Details Error', props<{ error: ErrorMessage }>());

  export const loadDisputes = createAction('[Cards API] Load Card Disputes');
  export const loadDisputesSuccess = createAction('[Cards API] Load Card Disputes Success', props<{ disputeList: CardDispute[] }>());
  export const loadDisputesError = createAction('[Cards API] Load Card Disputes Error', props<{ error: ErrorMessage }>());

  export const loadDisputeDetails = createAction('[Cards API] Load Card Dispute Details');
  export const loadDisputeDetailsSuccess = createAction(
    '[Cards API] Load Card Dispute Details Success',
    props<{ disputeDetails: CardDispute }>(),
  );
  export const loadDisputeDetailsError = createAction('[Cards API] Load Card Dispute Details Error', props<{ error: ErrorMessage }>());

  export const loadMerchants = createAction('[Cards API] Load Card Program Merchants');
  export const loadMerchantsSuccess = createAction(
    '[Cards API] Load Card Program Merchants Success',
    props<{ merchantList: CardMerchant[] }>(),
  );
  export const loadMerchantsError = createAction('[Cards API] Load Card Merchants Error', props<{ error: ErrorMessage }>());

  export const loadMerchantDetails = createAction('[Cards API] Load Card Program Merchant Details');
  export const loadMerchantDetailsSuccess = createAction(
    '[Cards API] Load Card Program Merchant Details Success',
    props<{ merchantDetails: CardMerchant }>(),
  );
  export const loadMerchantDetailsError = createAction('[Cards API] Load Card Merchant Details Error', props<{ error: ErrorMessage }>());

  export const loadStatements = createAction('[Cards API] Load Card Statements', props<{ requestParams?: RequestPageParams }>());

  export const loadStatementsSuccess = createAction(
    '[Cards API] Load Card Statements Success',
    props<{ statementList: InsightDocument[]; totalElements: number }>(),
  );

  export const loadStatementsError = createAction('[Cards API] Load Card Statements Error', props<{ error: ErrorMessage }>());

  export const loadCardAccountDetails = createAction(
    '[Cards API] Load Card Account Details',
    props<{ requestParams?: RequestPageParams }>(),
  );

  export const loadCardAccountDetailsSuccess = createAction(
    '[Cards API] Load Card Account Details Success',
    props<{ financialAccount: FinancialAccountDetails }>(),
  );

  export const loadCardAccountDetailsError = createAction('[Cards API] Load Card Account Details Error', props<{ error: ErrorMessage }>());

  export const loadCardPrograms = createAction('[Cards API] Load Card Programs');
  export const loadCardProgramsSuccess = createAction(
    '[Cards API] Load Card Programs Success',
    props<{ cardProgramList: CardProgram[] }>(),
  );
  export const loadCardProgramsError = createAction('[Cards API] Load Card Programs Error', props<{ error: ErrorMessage }>());

  export const cardIssueFormInit = createAction('[Cards API] Card Issue Form Init', props<{ requestParams?: RequestPageParams }>());
}

export const MccActions = createActionGroup({
  source: 'MCC API',
  events: {
    Load: emptyProps(),
    'Load Success': props<{ mccItems: MccItem[] }>(),
    'Load Failure': props<{ error: ErrorMessage }>(),
  },
});

export const CardProductsActions = createActionGroup({
  source: 'Card Products API',
  events: {
    Load: props<{ requestParams: RequestPageParams }>(),
    'Load Success': props<{ cardProducts: CardProduct[]; cardProductsTotalElements: number }>(),
    'Load Failure': props<{ error: ErrorMessage }>(),
    'Set Sort Params': props<{ sortParams: { key: string; sortProp: string; sortDir: string } }>(),
  },
});

export const CardProductDetailsActions = createActionGroup({
  source: 'Card Product Details API',
  events: {
    Load: emptyProps(),
    'Load Success': props<{ cardProduct: CardProduct }>(),
    'Load Failure': props<{ error: ErrorMessage }>(),
    'Get Restrictions': emptyProps(),
    'Get Restrictions Success': props<{ productRestrictions: CardProductRestrictions }>(),
    'Get Restrictions Failure': props<{ error: ErrorMessage }>(),
  },
});
