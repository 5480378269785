import { NgIf, TitleCasePipe } from '@angular/common';
import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Store } from '@ngrx/store';
import { format, parse } from 'date-fns';
import { isEmpty, omitBy, pick, some } from 'lodash-es';
import { Subject } from 'rxjs';
import { RktButtonDirective } from '@rocketfinancialcorp/rocket-ui/button';
import { RktFormComponent, RktFormFieldConfig } from '@rocketfinancialcorp/rocket-ui/form';
import { RktIconComponent } from '@rocketfinancialcorp/rocket-ui/icon';
import { ActiveModal } from '@rocketfinancialcorp/rocket-ui/modal';

import { AddCustomerParams, AddressesByType, FormModel } from '@shared/models';
import { IndustriesActions, fromIndustries } from '@shared/store';
import { emptySpacesValidator } from '@shared/validators';

import { businessCustomerNameField, businessIDVFields, customerCreateFields, individualIDVFields } from '../entity-form-fields';
import { customerAddressFormFields } from './customer-form-utils';
import { AddressAddComponent } from '../../addresses/address-add/address-add.component';
import { NoteFormItemComponent } from '../../notes/note-form-item/note-form-item.component';

@Component({
  selector: 'app-add-customer-modal',
  templateUrl: './add-customer-modal.component.html',
  styleUrls: ['./add-customer-modal.component.scss'],
  standalone: true,
  imports: [
    RktButtonDirective,
    FormsModule,
    ReactiveFormsModule,
    NgIf,
    RktFormComponent,
    NoteFormItemComponent,
    AddressAddComponent,
    TitleCasePipe,
    RktIconComponent,
  ],
})
export class AddCustomerModalComponent implements AfterViewChecked, OnDestroy {
  customerType!: 'INDIVIDUAL' | 'BUSINESS';

  formModel: FormModel<AddCustomerParams> = {
    physicalAddresses: [{ addressLine1: '', city: '', state: '', country: '', postalCode: '' }],
    socialIdCountryCode: 'USA',
    taxIdCountryCode: 'USA',
  };

  formError = '';

  note = new FormControl('', [emptySpacesValidator]);

  isAdditionalInfoCollapsed = true;

  industries$ = this.store.select(fromIndustries.selectIndustryList);

  customerIDVFields: RktFormFieldConfig[] = [];

  customerCreateFields = customerCreateFields;

  businessCustomerNameField = businessCustomerNameField;

  addressFields: RktFormFieldConfig[] = [];

  entityForm = new FormGroup({});

  entityIDVForm = new FormGroup({});

  isAddressFormInvalid = false;

  get addresses() {
    return pick(this.formModel, 'physicalAddresses', 'mailingAddresses', 'shippingAddresses');
  }

  private destroy$ = new Subject<void>();

  constructor(
    private ref: ChangeDetectorRef,
    public activeModal: ActiveModal,
    private store: Store,
  ) {}

  modalInitData({
    customerData,
    customerType,
    skipTypeSelection,
    accountHolderType,
    formError = '',
  }: {
    customerData: AddCustomerParams;
    customerType: 'INDIVIDUAL' | 'BUSINESS';
    skipTypeSelection: boolean;
    accountHolderType: 'CUSTOMER';
    formError?: string;
  }) {
    this.customerType = customerType;

    this.formModel = {
      ...this.formModel,
      customerType,
      skipTypeSelection,
      accountHolderType,
    };

    this.formError = formError;
    if (customerType === 'BUSINESS') {
      this.store.dispatch(IndustriesActions.loadIndustries({ params: { size: 150 } }));
    }

    this.customerIDVFields = customerType === 'INDIVIDUAL' ? individualIDVFields : businessIDVFields({ industries$: this.industries$ });

    this.addressFields = customerAddressFormFields({
      isEdit: false,
      customerType,
    });

    if (customerData) {
      const {
        noteContent,
        dateOfBirth,
        sex,
        socialId,
        citizenshipCountryCode,
        cityOfBirth,
        countryOfBirth,
        physicalAddresses,
        mailingAddresses,
        shippingAddresses,
        doingBusinessAsName,
        taxId,
        legalName,
        businessPrimaryPhoneNumber,
        website,
        dateOfFormation,
        legalEntityType,
        externalId,
        industryCode,
      } = customerData;

      this.note.setValue(noteContent ?? null);

      this.formModel = {
        ...this.formModel,
        ...customerData,
      };

      if (
        some([
          dateOfBirth,
          sex,
          socialId,
          citizenshipCountryCode,
          cityOfBirth,
          countryOfBirth,
          physicalAddresses,
          mailingAddresses,
          shippingAddresses,
          doingBusinessAsName,
          taxId,
          legalName,
          businessPrimaryPhoneNumber,
          website,
          dateOfFormation,
          legalEntityType,
          externalId,
          industryCode,
        ])
      ) {
        this.isAdditionalInfoCollapsed = false;
      }
    }
  }

  public ngAfterViewChecked(): void {
    this.ref.detectChanges();
  }

  public ngOnDestroy(): void {
    this.entityForm.markAsUntouched();
    this.destroy$.next();
    this.destroy$.complete();
  }

  toggleCollapsable(): void {
    this.isAdditionalInfoCollapsed = !this.isAdditionalInfoCollapsed;
  }

  public onNoteFieldBlur(): void {
    this.note.setValue(this.note.value?.trim() ?? '');
  }

  public onSubmit(): void {
    this.entityForm.markAllAsTouched();

    if (this.entityForm.invalid || this.isAddressFormInvalid || this.entityForm.invalid) {
      return;
    }

    this.formError = '';
    const customerData = {
      ...this.formModel,
      dateOfBirth: this.formModel.dateOfBirth
        ? format(parse(this.formModel.dateOfBirth, 'MM/dd/yyyy', new Date()), 'yyyy-MM-dd')
        : undefined,
      dateOfFormation: this.formModel.dateOfFormation
        ? format(parse(this.formModel.dateOfFormation, 'MM/dd/yyyy', new Date()), 'yyyy-MM-dd')
        : undefined,
      noteContent: this.note.value,
    };
    this.activeModal.close(omitBy(customerData, isEmpty));
    this.entityForm.reset();
  }

  setAddresses({ physicalAddresses, mailingAddresses, shippingAddresses }: AddressesByType): void {
    this.formModel = {
      ...this.formModel,
      physicalAddresses,
      mailingAddresses,
      shippingAddresses,
    };
  }

  setIsAddressFormInvalid(invalid: boolean) {
    this.isAddressFormInvalid = invalid;
  }
}
