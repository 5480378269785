import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, input, output } from '@angular/core';
import { RktIconComponent } from '@rocketfinancialcorp/rocket-ui/icon';

import { LinxAccountType, LinxEntityType, LinxProfileType, LinxSteps } from '@shared/constants';
import { LinXTemplateStep, LinxRequestOptionType, LinxRequestRowType } from '@shared/models';

@Component({
  selector: 'app-linx-steps',
  templateUrl: './linx-steps.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgClass, RktIconComponent],
})
export class LinxStepsComponent implements OnInit {
  entityType = input<LinxEntityType>();

  profileType = input<LinxProfileType>();

  accountType = input<LinxAccountType>();

  templateSteps = input<LinXTemplateStep[]>();

  optionClicked = output<LinxRequestOptionType[]>();

  selectedOption = output<LinxRequestOptionType>();

  requestOptions: LinxRequestOptionType[] = [
    { type: 'enhance-profile', label: 'Enhanced Profile', iconName: 'user' },
    { type: 'enhance-beneficiary', label: 'Onboard Beneficiary', iconName: 'beneficiary' },
    { type: 'identity-verification', label: 'Identity verification', iconName: 'verified' },
    { type: 'financial-account', label: 'Financial Account', iconName: 'bank' },
    { type: 'document-acceptance', label: 'Document Acceptance', iconName: 'document' },
    { type: 'move-money', label: 'Move Money', iconName: 'move-money' },
  ];

  selectedOptions: LinxRequestOptionType[] = [];

  ngOnInit(): void {
    this.onRequestOptionClick();
    if (this.templateSteps()) {
      this.initTemplateSteps();
    }
  }

  isDisabledType(type: string): boolean {
    if (
      this.entityType() === LinxEntityType.RECIPIENT &&
      ['enhance-beneficiary', 'identity-verification', 'document-acceptance'].includes(type)
    ) {
      return true;
    }

    if (type === 'enhance-beneficiary' && this.accountType() === LinxAccountType.INDIVIDUAL) {
      return true;
    }

    if (['document-acceptance', 'move-money'].includes(type)) {
      return !this.selectedOptions.find((option) => option.type === 'financial-account')?.isChecked;
    }

    return false;
  }

  initTemplateSteps(): void {
    this.templateSteps()?.forEach((step) => {
      this.requestOptions.forEach((option) => {
        if (
          option.type === step.name ||
          this.isIdentityVerificationStep(option.type, step.name) ||
          this.isEnhanceProfileStep(option.type, step.name) ||
          (option.type === 'financial-account' && step.name === LinxSteps.LookupIndividualFinancialAccount)
        ) {
          option.isChecked = true;
          option.hasValue = true;
        }
      });
    });
    this.selectedOptions = this.requestOptions.filter((option) => option.isChecked);
    this.optionClicked.emit(this.selectedOptions);
  }

  isIdentityVerificationStep(optionType: LinxRequestRowType, step: LinxSteps): boolean {
    return (
      optionType === 'identity-verification' &&
      [LinxSteps.SubmitBusinessVerification, LinxSteps.SubmitIndividualVerification].includes(step)
    );
  }

  isEnhanceProfileStep(optionType: LinxRequestRowType, step: LinxSteps): boolean {
    return optionType === 'enhance-profile' && [LinxSteps.EnhanceIndividualProfile, LinxSteps.EnhanceBusinessProfile].includes(step);
  }

  onRequestOptionClick(selectedOption?: LinxRequestOptionType): void {
    this.requestOptions.forEach((option) => {
      if (selectedOption && option.type === selectedOption.type) {
        option.isChecked = !option.isChecked;
        if (!option.isChecked) {
          option.hasValue = false;
          this.selectedOption.emit(option);
        }
      }
      if (
        this.entityType() === LinxEntityType.RECIPIENT &&
        option.type === 'financial-account' &&
        this.profileType() === LinxProfileType.New
      ) {
        option.isChecked = true;
        option.disabled = true;
      }
      if (this.isDisabledType(option.type)) {
        option.isChecked = false;
      }
    });
    this.selectedOptions = this.requestOptions.filter((option) => option.isChecked);
    this.optionClicked.emit(this.selectedOptions);
  }
}
