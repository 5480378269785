import { Action, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromRoot from '@shared/store';

import * as fromReports from './reports.reducer';

export const reportFeatureKey = 'report';

export interface ReportState {
  [fromReports.reportListFeatureKey]: fromReports.ReportsState;
}

export interface State extends fromRoot.RootState {
  [reportFeatureKey]: ReportState;
}

export const reducers = (state: ReportState | undefined, action: Action) => {
  return combineReducers({
    [fromReports.reportListFeatureKey]: fromReports.reducer,
  })(state, action);
};

export const selectReportState = createFeatureSelector<ReportState>(reportFeatureKey);

export const selectReportListState = createSelector(selectReportState, (state) => state.list);

export const selectReportList = createSelector(selectReportListState, fromReports.getReports);

export const selectReportsTotalElements = createSelector(selectReportListState, fromReports.getTotalElements);

export const selectReportsLoading = createSelector(selectReportListState, fromReports.getLoading);
