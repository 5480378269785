import { RktFormFieldConfig, formInput, formRadioGroup, formTextarea } from '@rocketfinancialcorp/rocket-ui/form';

interface AuthorizationFieldsParams {
  authorizationFieldsInitHook: () => void;
}

export const linxRequestDebitAuthorizationFields = ({ authorizationFieldsInitHook }: AuthorizationFieldsParams) => {
  return [
    formInput({
      key: 'businessName',
      label: 'Business Name',
      props: { required: true, minLength: 1 },
      className: 'linx-debit-authorization',
    }),
    formRadioGroup({
      key: 'authorization',
      label: '',
      className: 'linx-debit-authorization',
      props: {
        options: [
          { label: 'Manual Authorization', value: 'MANUAL' },
          { label: 'Open Authorization', value: 'OPEN' },
        ],
        required: true,
        change: () => authorizationFieldsInitHook(),
      },
    }),
  ];
};

export const notesField: RktFormFieldConfig[] = [
  formTextarea({
    key: 'note',
    label: 'Notes',
    className: 'linx-debit-authorization-field',
    props: {
      maxLength: 255,
      withCounter: true,
    },
  }),
];
