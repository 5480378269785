import { createAction, props } from '@ngrx/store';

import { ErrorMessage, Industry, IndustryList, RequestPageParams } from '@shared/models';

export namespace IndustriesActions {
  export const loadIndustries = createAction('[Industries API] Load Industries', props<{ params: RequestPageParams }>());

  export const loadIndustriesSuccess = createAction(
    '[Industries API] Load Industries Success',
    props<{ industries: IndustryList['items']; totalElements: number }>(),
  );

  export const loadIndustriesError = createAction('[Industries API] Load Industries Error', props<{ error: ErrorMessage }>());

  export const loadIndustryDetails = createAction('[Industries API] Load Industry Details by id', props<{ industryCode: string }>());

  export const loadIndustryDetailsSuccess = createAction('[Industries API] Load Industry Details Success', props<{ industry: Industry }>());

  export const loadIndustryDetailsError = createAction('[Industries API] Load Industry Details Error', props<{ error: ErrorMessage }>());
}
