import { Action, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromRoot from '@shared/store';

import * as fromRecipients from './recipients.reducer';

export const recipientFeatureKey = 'recipient';

export interface RecipientState {
  [fromRecipients.recipientListFeatureKey]: fromRecipients.RecipientsState;
}

export interface State extends fromRoot.RootState {
  [recipientFeatureKey]: RecipientState;
}

export const reducers = (state: RecipientState | undefined, action: Action) => {
  return combineReducers({
    [fromRecipients.recipientListFeatureKey]: fromRecipients.reducer,
  })(state, action);
};

export const selectRecipientState = createFeatureSelector<RecipientState>(recipientFeatureKey);

export const selectRecipientListState = createSelector(selectRecipientState, (state) => state.list);

export const selectRecipientList = createSelector(selectRecipientListState, fromRecipients.getRecipients);

export const selectRecipientsTotalElements = createSelector(selectRecipientListState, fromRecipients.getTotalElements);

export const selectRecipientsLoading = createSelector(selectRecipientListState, fromRecipients.getLoading);
