import { AsyncPipe, NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import { NavigationService } from '@shared/services';
import { FinancialAccountsActions, fromFinancialAccount } from '@shared/store';

import { TransactionActivityByFaIdsComponent } from '../transaction-activity-by-fa-ids/transaction-activity-by-fa-ids.component';

@Component({
  selector: 'app-account-holder-transaction-activity',
  templateUrl: 'account-holder-transaction-activity.component.html',
  standalone: true,
  imports: [NgIf, TransactionActivityByFaIdsComponent, AsyncPipe],
})
export class AccountHolderTransactionActivityComponent implements OnInit, OnDestroy {
  public participatingFinancialAccountIds$ = this.store.select(fromFinancialAccount.selectCustomerFinancialAccountIds);

  constructor(
    private navigationService: NavigationService,
    private store: Store,
  ) {
    this.navigationService.dispatchOnNavigationEnd(FinancialAccountsActions.loadFinancialAccountsIds());
  }

  ngOnInit() {
    this.store.dispatch(FinancialAccountsActions.initialize());
  }

  public ngOnDestroy(): void {
    this.navigationService.ngOnDestroy();
  }
}
