import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';
import { RktButtonDirective } from '@rocketfinancialcorp/rocket-ui/button';
import { RktFormComponent } from '@rocketfinancialcorp/rocket-ui/form';
import { RktIconComponent } from '@rocketfinancialcorp/rocket-ui/icon';
import { ActiveModal } from '@rocketfinancialcorp/rocket-ui/modal';

import { BusinessAccountAddress, FinancialAccountReviewData, FormModel, IntegratedFinancialAccountCreateModel } from '@shared/models';
import { fromAuth } from '@shared/store';
import { formAddressDeserializer } from '@shared/utils';

import { addressFields, integratedBankAccountFields } from '../entity-form-fields';

@Component({
  selector: 'app-add-integrated-financial-account',
  templateUrl: './add-integrated-financial-account.component.html',
  standalone: true,
  imports: [RktButtonDirective, FormsModule, ReactiveFormsModule, RktFormComponent, RktIconComponent],
})
export class AddIntegratedFinancialAccountComponent implements AfterViewChecked, OnDestroy {
  formModel: FormModel<IntegratedFinancialAccountCreateModel> = {
    category: 'INTEGRATED',
    subtype: 'CHECKING',
    cardOrBankAccount: 'bankAccount',
    name: undefined,
  };

  bankForm = new FormGroup({});

  bankFields = integratedBankAccountFields;

  addressForm = new FormGroup({});

  addressFields = addressFields;

  businessAccountId?: string;

  get isInternal(): boolean {
    return this.formModel.category === 'INTERNAL';
  }

  private destroy$ = new Subject<void>();

  constructor(
    public ref: ChangeDetectorRef,
    public activeModal: ActiveModal,
    private store: Store,
  ) {
    this.store
      .select(fromAuth.selectBusinessAccountDetails)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (businessAccount) => {
          const billingAddress = formAddressDeserializer(
            businessAccount?.addresses?.find(({ type, primary }) => type === 'PHYSICAL' && primary) || ({} as BusinessAccountAddress),
          );
          this.businessAccountId = businessAccount?.id;
          this.formModel = {
            ...this.formModel,
            ...billingAddress,
            legalName: businessAccount?.legalName,
          };
        },
      });
  }

  modalInitData(createData: FinancialAccountReviewData) {
    if (createData) {
      this.formModel = {
        ...this.formModel,
        name: createData.name,
      };
    }
  }

  public ngAfterViewChecked(): void {
    this.ref.detectChanges();
  }

  public ngOnDestroy(): void {
    this.bankForm.markAsUntouched();

    this.destroy$.next();
    this.destroy$.complete();
  }

  onSubmit() {
    if (this.bankForm.invalid) {
      return;
    }

    const { subtype, name, category, legalName } = this.formModel;

    if (!subtype || !name || !legalName || !this.businessAccountId) {
      return;
    }

    this.activeModal.close({ subtype, name, category, accountHolderName: legalName, businessAccountId: this.businessAccountId });
  }
}
