import { AsyncPipe, NgFor, NgIf, NgTemplateOutlet } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { Store } from '@ngrx/store';
import { RktIconComponent } from '@rocketfinancialcorp/rocket-ui/icon';

import { Breadcrumb, CardMerchant, TemplateContext } from '@shared/models';
import { CardAccountActions, fromFinancialAccount } from '@shared/store';

import { TemplateContextDirective } from '../../../directives/template-context.directive';
import { PhonePipe } from '../../../pipes/phone.pipe';
import { BreadcrumbsComponent } from '../../breadcrumbs/breadcrumbs.component';
import { DetailsItemComponent } from '../../details-item/details-item.component';
import { DetailsPageHeaderPlaceholderComponent } from '../../loading-placeholders';
import { TextWithPlaceholderComponent } from '../../text-with-placeholder/text-with-placeholder.component';

@Component({
  selector: 'app-merchant-details',
  templateUrl: './merchant-details.component.html',
  standalone: true,
  imports: [
    NgIf,
    NgTemplateOutlet,
    TemplateContextDirective,
    TextWithPlaceholderComponent,
    DetailsItemComponent,
    NgFor,
    AsyncPipe,
    PhonePipe,
    RktIconComponent,
    BreadcrumbsComponent,
    RouterLink,
    DetailsPageHeaderPlaceholderComponent,
  ],
})
export class MerchantDetailsComponent implements OnInit {
  store = inject(Store);

  activatedRoute = inject(ActivatedRoute);

  pageClassPrefix = 'merchant-details-';

  merchantDetailsContext?: TemplateContext<CardMerchant>;

  merchantDetails$ = this.store.select(fromFinancialAccount.selectCardAccountMerchantDetails);

  cardProductId?: string | null;

  cardProductListUrl = '/app/cards/card-products';

  pageReturnURL = '';

  breadcrumbs: Breadcrumb[] = [];

  constructor() {
    this.cardProductId = this.activatedRoute.snapshot?.paramMap.get('productId');
    this.pageReturnURL = `${this.cardProductListUrl}/${this.cardProductId}/merchants`;

    this.breadcrumbs = [
      { label: 'Card Products', url: this.cardProductListUrl },
      {
        label: 'Card Product Details',
        url: `${this.cardProductListUrl}/${this.cardProductId}/details`,
      },
      {
        label: 'Merchants',
        url: `${this.cardProductListUrl}/${this.cardProductId}/merchants`,
      },

      {
        label: 'Merchant Details',
      },
    ];
  }

  ngOnInit() {
    this.store.dispatch(CardAccountActions.loadMerchantDetails());
  }
}
