import {
  LinxAccountType,
  LinxEnhanceAccountType,
  LinxEntityType,
  LinxNotificationChannel,
  LinxProfileType,
  LinxSteps,
} from '@shared/constants';
import {
  LinXTemplateStep,
  LinxRequestDocumentDetails,
  LinxRequestMoveMoneyDetails,
  LinxReviewModalData,
  LinxTemplateCreationPayload,
  LinxTemplatePreDefinedFields,
} from '@shared/models';

export const getTemplateCreationPayload = (templateData: LinxReviewModalData, templateName?: string): LinxTemplateCreationPayload => {
  const {
    entityModalData,
    expiryDays,
    documentAcceptanceDetails,
    enhanceAccountDetails,
    moveMoneyDetails,
    customerMemo,
    enhanceBeneficiaryDetails,
    identityVerificationDetails,
    financialAccountDetails,
  } = templateData;

  const isBusiness = entityModalData?.accountType === LinxAccountType.BUSINESS;

  const selectedSteps: LinXTemplateStep[] = [
    {
      name: LinxSteps.Notification,
      input: {
        preDefined: { channel: LinxNotificationChannel.Sms },
        admin: { parameters: ['phoneNumber'] },
      },
    },
  ];

  /** Adding Customer account Step */
  if (entityModalData?.entityType === LinxEntityType.CUSTOMER) {
    if (entityModalData.profileType === LinxProfileType.Existing) {
      selectedSteps.push({
        name: isBusiness ? LinxSteps.LookupBusinessProfile : LinxSteps.LookupIndividualProfile,
        input: {
          admin: {
            parameters: ['customerId'],
          },
        },
      });
    } else {
      selectedSteps.push({ name: isBusiness ? LinxSteps.CreateBusinessAccount : LinxSteps.CreateIndividualAccount });
    }
  }

  /** Adding Recipient account Step */
  if (entityModalData?.entityType === LinxEntityType.RECIPIENT) {
    const isNewRecipient = entityModalData.profileType === LinxProfileType.New;

    selectedSteps.push({
      name: isNewRecipient ? LinxSteps.CreateRecipient : LinxSteps.LookupRecipient,
      input: {
        user: {
          recipientType: entityModalData?.accountType,
        },
      },
    });
  }

  /** Adding Enhance Profile Step */
  if (enhanceAccountDetails) {
    if (entityModalData?.entityType === LinxEntityType.CUSTOMER) {
      selectedSteps.push({
        name: isBusiness ? LinxSteps.EnhanceBusinessProfile : LinxSteps.EnhanceIndividualProfile,
        input: { user: enhanceAccountDetails?.enhanceProfileDetails },
      });
    } else {
      selectedSteps.push({
        name: LinxSteps.EnhanceRecipient,
        input: {
          user: {
            ...enhanceAccountDetails?.enhanceProfileDetails,
            recipientType: entityModalData?.accountType,
          },
        },
      });
    }
  }

  /** Adding Collect ID Documents Step */
  if (enhanceAccountDetails?.documentTypes?.length) {
    selectedSteps.push({
      name: LinxSteps.CollectDocuments,
      input: {
        user: {
          documentTypes: enhanceAccountDetails.documentTypes,
          accountType: enhanceAccountDetails.accountType,
        },
      },
    });
  }

  /** Adding Financial Account Step */
  if (financialAccountDetails) {
    const { financialAccountType, financialAccountSubType } = financialAccountDetails;
    const isNewFa = financialAccountType !== 'EXISTING';
    selectedSteps.push({
      name: isNewFa ? LinxSteps.CreateIndividualFinancialAccount : LinxSteps.LookupIndividualFinancialAccount,
      input: {
        preDefined: {
          financialAccountSubType: financialAccountSubType === 'CARD' ? 'DEBIT' : financialAccountSubType,
        },
        admin: isNewFa ? {} : { parameters: ['counterPartyFinancialAccountId'] },
      },
    });
  }

  /** Adding Document Acceptance Step */
  if (documentAcceptanceDetails?.length) {
    const acceptanceDocuments: LinxRequestDocumentDetails[] = [];
    documentAcceptanceDetails.forEach((document) => {
      acceptanceDocuments.push({
        documentGroup: document.documentGroup,
        documentId: document.documentId,
      });
    });
    selectedSteps.push({
      name: LinxSteps.DocumentAcceptance,
      input: { user: { documents: acceptanceDocuments } },
    });
  }

  /** Adding Move Money Step */
  if (moveMoneyDetails) {
    addMoveMoneyStep(moveMoneyDetails, selectedSteps);
  }

  /** Adding Beneficiary Details Step */
  if (enhanceBeneficiaryDetails && isBusiness) {
    selectedSteps.push({
      name: enhanceBeneficiaryDetails.beneficiaryType === 'NEW' ? LinxSteps.CreateBeneficiary : LinxSteps.LookupBeneficiary,
    });

    if (enhanceBeneficiaryDetails.enhanceProfileDetails) {
      selectedSteps.push({
        name: LinxSteps.EnhanceBeneficiary,
        input: {
          user: enhanceBeneficiaryDetails.enhanceProfileDetails,
        },
      });
    }
  }

  /** Adding Beneficiary Collect ID Documents Step */
  if (enhanceBeneficiaryDetails?.documentTypes?.length) {
    selectedSteps.push({
      name: LinxSteps.CollectDocuments,
      input: {
        user: {
          documentTypes: enhanceBeneficiaryDetails.documentTypes,
          accountType: LinxEnhanceAccountType.Benificiary,
        },
      },
    });
  }

  /** Adding Identity Verification Step */
  if (identityVerificationDetails) {
    selectedSteps.push({
      name: isBusiness ? LinxSteps.SubmitBusinessVerification : LinxSteps.SubmitIndividualVerification,
      input: {
        preDefined: {
          idvProfile: identityVerificationDetails.id,
        },
      },
    });
  }

  return {
    defaultExpiryDays: expiryDays,
    displayName: entityModalData?.templateName ?? templateName,
    definition: {
      steps: selectedSteps,
    },
    customerMemo: customerMemo,
  };
};

const addMoveMoneyStep = (moveMoneyDetails: LinxRequestMoveMoneyDetails, selectedSteps: LinXTemplateStep[]) => {
  const isWire = moveMoneyDetails?.selectedMoveHowType === 'wire';
  const isACH = moveMoneyDetails?.selectedMoveHowType === 'ach';
  const wireSettlementPriorityOrUndefined = isWire ? moveMoneyDetails?.moveHowWireDetails?.moveWireType : undefined;

  const transactionData: LinxTemplatePreDefinedFields = {
    transactionType: moveMoneyDetails.selectedMoveItem === 'push' ? 'SEND' : 'REQUEST',
    solution: moveMoneyDetails.selectedMoveHowType,
    priority: isACH ? moveMoneyDetails?.moveHowAchDetails?.moveAchType : wireSettlementPriorityOrUndefined,
    currency: 'USD',
    paymentReasonId: moveMoneyDetails.paymentReasonId,
  };

  if (isWire) {
    transactionData.description = moveMoneyDetails?.wireDetails?.narrative;
    transactionData.memo = moveMoneyDetails?.wireDetails?.memo;
  }

  if (isACH) {
    transactionData.rkorACHIndividualId = moveMoneyDetails?.achDetails?.rkorACHIndividualId;
  }

  selectedSteps.push({
    name: LinxSteps.MoveMoney,
    input: {
      preDefined: { ...transactionData },
      admin: {
        parameters: ['amount', 'originatorFinancialAccountId'],
      },
    },
  });
};
