import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { compact } from 'lodash-es';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { environment } from '@env';
import { constants } from '@shared/constants';
import {
  BusinessAccount,
  FilterValues,
  RequestPageParams,
  TransactionBatch,
  TransactionBatchList,
  TransactionBatchListRaw,
  TransactionBatchRaw,
} from '@shared/models';
import { BusinessAccountService, StorageService } from '@shared/services';
import { BackendService } from '@shared/services/backend.service';
import { AuditActions } from '@shared/store';

import { mapTransactionBatch, mapTransactionBatches, transactionBathesRequestParams } from './transaction-mapping-utils';

@Injectable({
  providedIn: 'root',
})
export class TransactionBatchesService {
  apiBasePath = `${environment.transactionFlowEndpoint}/business-accounts`;

  activeFilters = new BehaviorSubject<FilterValues>({});

  constructor(
    private businessAccountService: BusinessAccountService,
    private readonly store: Store,
    private backendService: BackendService,
    private storageService: StorageService,
  ) {}

  /**
   * Returns Transaction Batch List
   *
   * @param page - page number
   * @param size  - page size
   * @param sortParams - sorting by
   * @param filteredParams - filter query
   * @returns TransactionBatchList
   */
  public getTransactionBatches(requestParams: RequestPageParams): Observable<TransactionBatchList> {
    const params = transactionBathesRequestParams(requestParams);

    return this.getSelectedBusinessAccountId().pipe(
      switchMap((businessAccountId) =>
        this.backendService.get<TransactionBatchListRaw>(`${this.apiBasePath}/${businessAccountId}/transaction-batches`, { params }).pipe(
          map((transactionsData) => mapTransactionBatches(transactionsData)),
          catchError((errorRes) => throwError(() => errorRes)),
        ),
      ),
    );
  }

  /**
   * Returns single Transaction Batch by ID
   * @param transactionBatchId - requested Transaction Batch ID
   * @returns TransactionBatch
   */
  public getTransactionBatchById(transactionBatchId: string): Observable<TransactionBatch> {
    return this.businessAccountService.getSelectedBusinessAccount().pipe(
      switchMap((businessAccount) =>
        this.backendService
          .get<TransactionBatchRaw>(`${this.apiBasePath}/${businessAccount.id}/transaction-batches/${transactionBatchId}/details`)
          .pipe(
            tap({
              next: (transactionBatch) => {
                const { createdBy, updatedBy } = transactionBatch;
                this.store.dispatch(AuditActions.loadTeamMembers({ ids: compact([createdBy, updatedBy]) }));
              },
            }),
            map((transactionBatch) => mapTransactionBatch(transactionBatch)),
            catchError((errorRes) => throwError(() => errorRes)),
          ),
      ),
    );
  }

  /**
   * Cancel PENDING Transaction Batch By ID
   * @param transactionBatchId - Transaction Batch ID
   * @returns result of deletion
   */
  public cancelTransactionBatch(transactionBatchId: string): Observable<boolean> {
    return this.getSelectedBusinessAccountId().pipe(
      switchMap((businessAccountId) =>
        this.backendService.delete<void>(`${this.apiBasePath}/${businessAccountId}/transaction-batches/${transactionBatchId}`).pipe(
          map(() => true),
          catchError((errorRes) => throwError(() => errorRes)),
        ),
      ),
    );
  }

  public setFilterParams(filters: FilterValues): void {
    this.storageService.setItem(constants.KOR_TRANSACTION_BATCH_FILTER, filters);
    this.updateFilterParams(filters);
  }

  public getFilterParams(): void {
    const filters = this.storageService.getItem<FilterValues>(constants.KOR_TRANSACTION_BATCH_FILTER);

    if (filters && Object.keys(filters).length) {
      this.updateFilterParams(filters);
    }
  }

  private updateFilterParams(filters: FilterValues): void {
    this.activeFilters.next(filters);
  }

  private getSelectedBusinessAccountId(): Observable<BusinessAccount['id']> {
    return this.businessAccountService.getSelectedBusinessAccountId();
  }
}
