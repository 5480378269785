import { AsyncPipe, NgIf, NgTemplateOutlet } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { RktButtonDirective } from '@rocketfinancialcorp/rocket-ui/button';
import { ModalService } from '@rocketfinancialcorp/rocket-ui/modal';
import { ShortIdPipe } from '@rocketfinancialcorp/rocket-ui/table';

import { AlertCreateModalComponent } from '@shared/components';
import { CardAlert, TemplateContext } from '@shared/models';
import { NavigationService } from '@shared/services';
import { CardAccountActions, fromFinancialAccount } from '@shared/store';

import { TemplateContextDirective } from '../../../directives/template-context.directive';
import { DateFormatPipe } from '../../../pipes/date-format.pipe';
import { PhonePipe } from '../../../pipes/phone.pipe';
import { DetailsItemComponent } from '../../details-item/details-item.component';
import { TextWithPlaceholderComponent } from '../../text-with-placeholder/text-with-placeholder.component';
import { CardPagesBreadcrumbsComponent } from '../card-pages-breadcrumbs/card-pages-breadcrumbs.component';

@Component({
  templateUrl: './alert-details.component.html',
  standalone: true,
  imports: [
    RktButtonDirective,
    NgIf,
    NgTemplateOutlet,
    TemplateContextDirective,
    CardPagesBreadcrumbsComponent,
    TextWithPlaceholderComponent,
    DetailsItemComponent,
    AsyncPipe,
    PhonePipe,
    DateFormatPipe,
    ShortIdPipe,
  ],
})
export class AlertDetailsComponent implements OnInit, OnDestroy {
  pageClassPrefix = 'alert-details-';

  alertDetailsContext?: TemplateContext<CardAlert>;

  alertDetails$ = this.store.select(fromFinancialAccount.selectCardAccountAlertDetails);

  constructor(
    private navigationService: NavigationService,
    private store: Store,
    private modalService: ModalService,
  ) {
    this.navigationService.dispatchOnNavigationEnd(CardAccountActions.loadAlertDetails());
  }

  ngOnInit(): void {
    this.store.dispatch(CardAccountActions.loadCardAccountDetails({}));
  }

  ngOnDestroy(): void {
    this.modalService.dismissAll();
    this.navigationService.ngOnDestroy();
  }

  onEditBtnClick(alert?: CardAlert) {
    if (!alert) {
      return;
    }

    const alertModalRef = this.modalService.open(AlertCreateModalComponent, { className: 'entity-form-modal' });

    alertModalRef.componentInstance.modalInitData(alert);

    alertModalRef.result.finally(() => {
      this.store.dispatch(CardAccountActions.loadAlertDetails());
    });
  }
}
