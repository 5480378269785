<div class="linx-request-move-how">
  <form [formGroup]="moveHowDirectionForm">
    <rkt-form [form]="moveHowDirectionForm" [fields]="moveHowDirectionFields()" [model]="formModel"></rkt-form>
  </form>

  <div class="move-how-divider"></div>

  <form [formGroup]="moveHowTypeForm">
    <rkt-form [form]="moveHowTypeForm" [fields]="moveHowTypeFields()" [model]="formModel"></rkt-form>
  </form>
</div>
