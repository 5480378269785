<div class="modal-title">Edit Transaction</div>

<ng-container *ngIf="formAction$ | async as formAction">
  <div class="create-transaction-form top-segment" *ngIf="(isFormLoading$ | async) === false">
    <div class="create-transaction-form-header">
      <app-create-transaction-amount class="amount-row"></app-create-transaction-amount>

      <app-create-transaction-amount-options class="amount-options-row"></app-create-transaction-amount-options>
    </div>

    <div class="create-transaction-form-body">
      <div class="financial-account-selection-wrapper">
        <app-create-transaction-financial-account class="financial-account-selection"></app-create-transaction-financial-account>

        <div class="financial-account-selection-icon"></div>

        <app-create-transaction-financial-account class="financial-account-selection" type="to"></app-create-transaction-financial-account>
      </div>
    </div>
  </div>

  <div class="create-transaction-form mid-segment" *ngIf="(deliverySpeedOptions$ | async)?.length">
    <div class="create-transaction-form-body">
      <app-create-transaction-delivery-speed></app-create-transaction-delivery-speed>
    </div>
  </div>

  <div class="create-transaction-form bottom-segment">
    <div class="create-transaction-form-body">
      <app-edit-transaction-scheduler
        *ngIf="['EDIT_SCHEDULED_SLT', 'EDIT_SCHEDULED_MLT'].includes(formAction) && (schedulerEditData$ | async) as editData"
        [editData]="editData"
      ></app-edit-transaction-scheduler>

      <div class="create-transaction-form-footer">
        <app-create-transaction-note class="create-transaction-note"></app-create-transaction-note>

        <div class="buttons">
          <button
            rktButton
            btnType="tertiary"
            class="create-transaction-btn create-transaction-btn-clear"
            title="Cancel"
            (click)="activeModal.close()"
          >
            Cancel
          </button>

          <button
            rktButton
            title="Continue"
            class="create-transaction-btn create-transaction-btn-continue"
            (click)="onContinueBtnClick({ formAction })"
            [disabled]="(isUpdateMultiLegTransactionDisabled$ | async) === true"
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-container>
