import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-note-form-item',
  templateUrl: 'note-form-item.component.html',
  styleUrls: ['note-form-item.component.scss'],
  standalone: true,
  imports: [NgIf],
})
export class NoteFormItemComponent {
  @Input() errors?: Record<string, Record<string, string | boolean>> | null;

  @Input() value?: string | null;

  @Input() title?: string;

  @Input() isShowTitle = true;

  @Input() disabled?: boolean;
}
