import { createFeature, createReducer, on } from '@ngrx/store';

import { TransactionBatch } from '@shared/models';

import { TransactionBatchDetailsActions } from './transcation-batch.actions';

export interface TransactionBatchFeatureState {
  transactionBatchDetails: TransactionBatch | null;
}

export const transactionBatchInitialState: TransactionBatchFeatureState = {
  transactionBatchDetails: null,
};

export const transactionBatchFeature = createFeature({
  name: 'transactionBatchFeature',
  reducer: createReducer(
    transactionBatchInitialState,
    on(TransactionBatchDetailsActions.loadTransactionBatchDetails, (state): TransactionBatchFeatureState => {
      return { ...state, transactionBatchDetails: null };
    }),
    on(
      TransactionBatchDetailsActions.loadTransactionBatchDetailsSuccess,
      (state, { transactionBatchDetails }): TransactionBatchFeatureState => {
        return { ...state, transactionBatchDetails };
      },
    ),
    on(TransactionBatchDetailsActions.loadTransactionBatchDetailsFailure, (state): TransactionBatchFeatureState => {
      return { ...state, transactionBatchDetails: null };
    }),
  ),
});
