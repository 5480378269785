import { AsyncPipe, NgIf, NgTemplateOutlet } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject, combineLatest, takeUntil } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { RktButtonDirective } from '@rocketfinancialcorp/rocket-ui/button';
import { RktIconComponent } from '@rocketfinancialcorp/rocket-ui/icon';
import { ModalService } from '@rocketfinancialcorp/rocket-ui/modal';
import { ShortIdPipe } from '@rocketfinancialcorp/rocket-ui/table';

import {
  BeneficiaryDetailsAction,
  BreadcrumbsComponent,
  ConfirmModalComponent,
  DetailsItemComponent,
  DetailsPageHeaderPlaceholderComponent,
  DocumentCreateFormComponent,
  DocumentFilePreviewModalComponent,
  SuccessModalComponent,
  TextWithPlaceholderComponent,
} from '@shared/components';
import { TemplateContextDirective } from '@shared/directives';
import { Breadcrumb, DocumentCreateModel, DocumentDetails, TemplateContext } from '@shared/models';
import { AccessControlPipe, DateFormatPipe, TeamMemberNamePipe } from '@shared/pipes';
import { DocumentService } from '@shared/services';
import { DocumentActions, fromDocument, selectRouteData, selectRouteParams } from '@shared/store';
import { ErrorUtils } from '@shared/utils';

import { DocumentDetailsHeaderComponent } from '../document-details-header/document-details-header.component';

@Component({
  selector: 'app-document-details-page',
  templateUrl: './document-details-page.component.html',
  styleUrls: ['./document-details-page.component.scss'],
  standalone: true,
  imports: [
    RktButtonDirective,
    NgIf,
    NgTemplateOutlet,
    TemplateContextDirective,
    RouterLink,
    BreadcrumbsComponent,
    DocumentDetailsHeaderComponent,
    DetailsPageHeaderPlaceholderComponent,
    TextWithPlaceholderComponent,
    DetailsItemComponent,
    AsyncPipe,
    DateFormatPipe,
    TeamMemberNamePipe,
    AccessControlPipe,
    RktIconComponent,
    ShortIdPipe,
  ],
})
export class DocumentDetailsPageComponent implements OnInit, OnDestroy {
  pageClassPrefix = 'document-details-';

  documentDetailsContext?: TemplateContext<DocumentDetails>;

  document$ = this.store.select(fromDocument.selectDocumentDetails);

  breadcrumbList: Breadcrumb[] = [];

  pageReturnURL = '';

  loading = false;

  accountHolderType?: DocumentCreateModel['accountHolderType'];

  private destroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private store: Store,
    private modalService: ModalService,
    private documentService: DocumentService,
  ) {}

  ngOnInit(): void {
    this.store.dispatch(DocumentActions.loadDocument());

    combineLatest([this.store.select(selectRouteParams), this.store.select(selectRouteData)])
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: ([routeParams, routeData]) => {
          if (routeParams.beneficiaryId) {
            this.pageReturnURL = `/app/customers/businesses/${routeParams.entityId}/beneficiaries/${routeParams.beneficiaryId}/documents`;

            this.breadcrumbList = [
              { label: 'Businesses', url: '/app/customers/businesses' },
              { label: 'Business Details', url: `/app/customers/businesses/${routeParams.entityId}/beneficiaries` },
              { label: 'Beneficial Owner Details', url: this.pageReturnURL },
              { label: 'Document Details' },
            ];

            this.accountHolderType = 'BusinessBeneficiary';
          }
          if (routeParams.individualCustomerId) {
            this.pageReturnURL = `/app/customers/individuals/${routeParams.individualCustomerId}/documents`;

            this.breadcrumbList = [
              { label: 'Individuals', url: '/app/customers/individuals' },
              { label: 'Individual Details', url: `/app/customers/individuals/${routeParams.individualCustomerId}` },
              { label: 'Document Details' },
            ];

            this.accountHolderType = 'Individual';
          }
          if (routeParams.businessCustomerId) {
            this.pageReturnURL = `/app/customers/businesses/${routeParams.businessCustomerId}/documents`;

            this.breadcrumbList = [
              { label: 'Businesses', url: '/app/customers/businesses' },
              { label: 'Business Details', url: `/app/customers/businesses/${routeParams.businessCustomerId}` },
              { label: 'Document Details' },
            ];

            this.accountHolderType = 'Business';
          }
          if (routeData.accountHolderType === 'business-account') {
            this.pageReturnURL = `/app/settings/business-profile/documents`;

            this.breadcrumbList = [{ label: 'Account Info', url: '/app/settings/business-profile' }, { label: 'Document details' }];

            this.accountHolderType = 'Business';
          }
          if (routeParams.businessAccountBeneficiaryId) {
            this.pageReturnURL = `/app/settings/business-profile/beneficiaries/${routeParams.businessAccountBeneficiaryId}/documents`;

            this.breadcrumbList = [
              { label: 'Account Info', url: '/app/settings/business-profile' },
              {
                label: 'Beneficial Owner Details',
                url: `/app/settings/business-profile/beneficiaries/${routeParams.businessAccountBeneficiaryId}`,
              },
              { label: 'Document Details' },
            ];

            this.accountHolderType = 'BusinessBeneficiary';
          }
        },
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onEditBtnClick(document: DocumentDetails): void {
    const documentEditFormModalRef = this.modalService.open(DocumentCreateFormComponent, {
      className: 'entity-form-modal',
    });

    documentEditFormModalRef.componentInstance.modalInitData({ editData: { ...document }, accountHolderType: this.accountHolderType });

    documentEditFormModalRef.result.then((result) => {
      if (result) this.store.dispatch(DocumentActions.loadDocument());
    });
  }

  onFilePreviewClick(document: DocumentDetails): void {
    if (document.status !== 'SCANNED' || !document?.url) return;

    const url = document.url.value!;
    const type = document.documentTypeIcon!;
    const name = document.documentTypeDisplayName!;
    const fileFormat = document.extension!;

    const filePreviewModal = this.modalService.open(DocumentFilePreviewModalComponent, {
      className: 'entity-form-modal file-preview-modal',
    });

    filePreviewModal.componentInstance.modalInitData({ url, type, name, fileFormat });
  }

  getDocumentScanLabel(document: DocumentDetails): string {
    switch (document.status) {
      case 'NEW':
        return 'File not Available.';

      case 'UPLOADED':
        return 'Scanning for Viruses..';

      case 'MALICIOUS':
      case 'INCOMPLETE':
        return 'File cannot be Displayed.';

      case 'SCANNED':
      default:
        return '';
    }
  }

  onHeaderBtnClick(btnType: BeneficiaryDetailsAction, document: DocumentDetails) {
    this.onUpdateDocument(btnType, document);
  }

  public onUpdateDocument(action: string, document: DocumentDetails): void {
    const suspendConfirmModalRef = this.modalService.open(ConfirmModalComponent, {
      className: 'confirm-modal',
    });

    suspendConfirmModalRef.componentInstance.actionName = action;
    suspendConfirmModalRef.componentInstance.type = 'Document';
    suspendConfirmModalRef.componentInstance.displayName = document.name;

    suspendConfirmModalRef.result.then(
      (result) => {
        if (result) {
          switch (action) {
            case 'DELETE':
              this.deleteDocument(document);
              break;

            default:
              break;
          }
        }
      },
      () => false,
    );
  }

  deleteDocument(document: DocumentDetails): void {
    this.loading = true;

    this.documentService
      .deleteDocument({ documentId: document.id })
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => {
          this.loading = false;
        }),
      )
      .subscribe({
        next: () => {
          this.showChangeSuccessModal('DELETED');
        },
        error: (error) => this.handleError(error),
      });
  }

  public showChangeSuccessModal(status: string): void {
    const changeSuccessModalRef = this.modalService.open(SuccessModalComponent, {
      className: 'success-modal',
    });

    changeSuccessModalRef.componentInstance.actionName = status;
    changeSuccessModalRef.componentInstance.type = 'Document';
    changeSuccessModalRef.result.finally(() => {
      if (status === 'DELETED') {
        this.router.navigateByUrl(this.pageReturnURL);
      }
    });
  }

  isUnverifiedDocument(document: DocumentDetails): boolean {
    return document?.verificationStatus === 'UNVERIFIED';
  }

  private handleError(error?: string | number): void {
    ErrorUtils.catchError('documentService.deleteDocument error', error);
  }

  isVerifiedDocument(document: DocumentDetails): boolean {
    return document?.verificationStatus === 'VERIFIED';
  }
}
