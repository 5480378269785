import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { ErrorMessage, ExceptionDetails, ExceptionHistoryItem, ExceptionItem, FilterValues, RequestPageParams } from '@shared/models';

export const ExceptionManagementActions = createActionGroup({
  source: 'Exception Management',
  events: {
    'Load Exceptions': props<{ params: RequestPageParams }>(),
    'Load Exceptions Success': props<{ exceptionListItems: ExceptionItem[]; totalElements: number }>(),
    'Load Exceptions Failure': props<{ error: ErrorMessage }>(),
    'Set Exceptions Active Filters': props<{ filters: FilterValues }>(),
    'Set Exceptions Assignee Filter': props<{ assigneeFilter: { label: string; value: string }[] }>(),
    'Load Exception Details': emptyProps,
    'Load Exception Details Success': props<{ exceptionDetails: ExceptionDetails }>(),
    'Load Exception Details Failure': props<{ error: ErrorMessage }>(),
    'Update Exception': props<{ data: { assignedTo?: string; status?: string } }>(),
    'Update Exception Success': props<{ exceptionDetails: ExceptionDetails }>(),
    'Update Exception Failure': props<{ error: ErrorMessage }>(),
    'Load Exception History': props<{ params: RequestPageParams }>(),
    'Load Exception History Success': props<{ exceptionHistoryItems: ExceptionHistoryItem[]; totalElements: number }>(),
    'Load Exception History Failure': props<{ error: ErrorMessage }>(),
  },
});
