import { formInput, formRadioGroup, formRow } from '@rocketfinancialcorp/rocket-ui/form';

import { Patterns } from '../patterns';

export const integratedBankAccountFields = [
  formRow([
    formRadioGroup({
      key: 'category',
      label: 'Account Category',
      props: {
        options: [
          { value: 'EXTERNAL', label: 'EXTERNAL', disabled: true },
          { value: 'INTERNAL', label: 'INTERNAL', disabled: true },
          { value: 'INTEGRATED', label: 'INTEGRATED' },
        ],
      },
    }),
  ]),
  formRow(
    [
      formRadioGroup({
        key: 'cardOrBankAccount',
        label: 'Account Type',
        props: {
          options: [
            { value: 'bankAccount', label: 'Bank Account' },
            { value: 'card', label: 'Debit Card', disabled: true },
          ],
        },
      }),
    ],
    { className: 'account-type-row' },
  ),
  formRow([
    formInput({
      key: 'name',
      label: 'Financial Account Nam\u0435',
      props: {
        pattern: Patterns.GENERIC_NAME,
        required: true,
      },
      validation: { messages: { pattern: 'Enter a valid Financial Account Nam\u0435' } },
    }),
  ]),
  formRow([formInput({ key: 'legalName', label: 'Account Holder Name', props: { required: true, disabled: true } })]),
  formRow([formInput({ key: 'subtype', label: 'Account Subtype', props: { required: true, disabled: true } })]),
];
