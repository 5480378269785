import { createFeature, createReducer, on } from '@ngrx/store';

import { LinxEntityModalData } from '@shared/models';

import { LinxActions } from './linx-feature.actions';

export interface LinxFeatureState {
  entityModalData: LinxEntityModalData | null;
}

export const linxFeatureInitialState: LinxFeatureState = {
  entityModalData: null,
};

export const linxFeature = createFeature({
  name: 'linxFeature',
  reducer: createReducer(
    linxFeatureInitialState,
    on(LinxActions.setEntityModalData, (state, { entityModalData }) => ({
      ...state,
      entityModalData,
    })),
  ),
});
