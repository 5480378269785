import { AsyncPipe, NgClass, NgFor, NgIf, NgTemplateOutlet, TitleCasePipe } from '@angular/common';
import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { Store } from '@ngrx/store';
import { RktIconComponent } from '@rocketfinancialcorp/rocket-ui/icon';
import { ModalService } from '@rocketfinancialcorp/rocket-ui/modal';
import { ShortIdPipe, SplitPipe } from '@rocketfinancialcorp/rocket-ui/table';

import {
  AttachmentsListComponent,
  BreadcrumbsComponent,
  DetailsItemComponent,
  DetailsNotesComponent,
  DetailsPageHeaderPlaceholderComponent,
  TextWithPlaceholderComponent,
} from '@shared/components';
import { DragDropAttachmentDirective, TemplateContextDirective } from '@shared/directives';
import { SingleLegTransactionFinancialAccount, TemplateContext, TransactionDetails, TransactionHistory } from '@shared/models';
import { AmountPipe, DateFormatPipe, NotAvailablePipe, TeamMemberNamePipe } from '@shared/pipes';
import { SingleLegTransactionActions, TransactionFormActions, selectSingleLegTransactionDetailsWithHistory } from '@shared/store';

import { TransactionDetailActionsComponent } from '../transaction-detail-actions/transaction-detail-actions.component';
import { TransactionHistoryComponent } from '../transaction-history/transaction-history.component';
@Component({
  selector: 'app-view-transaction',
  templateUrl: './view-transaction.component.html',
  styleUrls: ['./view-transaction.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    RouterLink,
    RktIconComponent,
    NgClass,
    BreadcrumbsComponent,
    TransactionDetailActionsComponent,
    DragDropAttachmentDirective,
    NgTemplateOutlet,
    AttachmentsListComponent,
    DetailsNotesComponent,
    DetailsPageHeaderPlaceholderComponent,
    TemplateContextDirective,
    TransactionHistoryComponent,
    DetailsItemComponent,
    TextWithPlaceholderComponent,
    NgFor,
    AsyncPipe,
    TitleCasePipe,
    NotAvailablePipe,
    DateFormatPipe,
    AmountPipe,
    TeamMemberNamePipe,
    ShortIdPipe,
    SplitPipe,
  ],
})
export class ViewTransactionComponent implements OnInit, AfterViewChecked, OnDestroy {
  @ViewChild('attachmentsList') attachmentsList!: AttachmentsListComponent;

  pageClassPrefix = 'transaction-details-';

  transactionData$ = this.store.select(selectSingleLegTransactionDetailsWithHistory);

  transactionDetailsContext?: TemplateContext<TransactionDetails>;

  transactionHistoryContext?: TemplateContext<TransactionHistory[]>;

  hasNotes = true;

  isFromFA = this.router.url.split('/').includes('financial-accounts');

  isFromExceptions = this.router.url.split('/').includes('exceptions');

  pageReturnURL = this.router.url.split('/').slice(0, -1).join('/');

  pageReturnURLTwoLevels = this.router.url.split('/').slice(0, -2).join('/');

  breadcrumbList = this.isFromFA
    ? [
        { label: 'Financial Accounts', url: '/app/settings/financial-accounts' },
        { label: 'Account Activity', url: this.pageReturnURL },
        { label: 'Transaction Details' },
      ]
    : this.isFromExceptions
      ? [
          { label: 'Exceptions', url: '/app/exception-management/exceptions' },
          { label: 'Exception Details', url: this.pageReturnURLTwoLevels },
          { label: 'Transaction Details' },
        ]
      : [{ label: 'Transaction Activity', url: this.pageReturnURL }, { label: 'Transaction Details' }];

  isShowMetadata = false;

  constructor(
    private ref: ChangeDetectorRef,
    private router: Router,
    private modalService: ModalService,
    private store: Store,
  ) {}

  ngOnInit() {
    this.store.dispatch(SingleLegTransactionActions.loadDetails());
  }

  ngAfterViewChecked(): void {
    this.ref.detectChanges();
  }

  ngOnDestroy(): void {
    this.store.dispatch(TransactionFormActions.createTransactionPageReset());
    this.modalService.dismissAll();
  }

  onAccountHolderClick(financialAccount: SingleLegTransactionFinancialAccount, transactionId: string): void {
    const { id, accountHolderId, accountHolderType, customer } = financialAccount;

    if (!accountHolderId) {
      this.router.navigateByUrl(`/app/transactions/transactions/${transactionId}/financial-accounts/${id}/account-details`);
    } else if (accountHolderType === 'CUSTOMER' && customer) {
      const customerType = customer.type === 'INDIVIDUAL' ? 'individuals' : 'businesses';
      this.router.navigateByUrl(
        `/app/transactions/transactions/${transactionId}/${customerType}/${accountHolderId}/financial-accounts/${id}/account-details`,
      );
    } else if (accountHolderType === 'RECIPIENT') {
      this.router.navigateByUrl(`/app/transactions/transactions/${transactionId}/recipients/${accountHolderId}`);
    }
  }
}
