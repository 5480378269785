import { createFeature, createReducer, on } from '@ngrx/store';

import { EarnedWageProgram } from '@shared/models';

import { EwaProgramActions } from './ewa.actions';

export interface EwaFeatureState {
  ewaProgram: EarnedWageProgram | null;
}

export const ewaFeatureInitialState: EwaFeatureState = {
  ewaProgram: null,
};

export const ewaFeature = createFeature({
  name: 'ewaFeature',
  reducer: createReducer(
    ewaFeatureInitialState,
    on(EwaProgramActions.loadEwaProgram, (state): EwaFeatureState => {
      return { ...state, ewaProgram: null };
    }),
    on(EwaProgramActions.loadEwaProgramSuccess, (state, { ewaProgram }): EwaFeatureState => {
      return { ...state, ewaProgram };
    }),
    on(EwaProgramActions.loadEwaProgramFailure, (state): EwaFeatureState => {
      return { ...state, ewaProgram: null };
    }),
  ),
});
