<app-collapsible-row
  iconName="beneficiary"
  class="linx-request-row"
  label="Beneficial Owners"
  [isRowExpanded]="isRowExpanded()"
  [isRowHasValue]="isRowHasValue()"
>
  @if (isRowHasValue() && !isRowExpanded()) {
    <app-enhance-benificiary-preview
      [enhanceBenificiaryDetails]="enhanceBeneficiaryDetails()"
      [enhanceBenificiaryDisplayData]="enhanceBenificiaryDisplayData()"
      (expandRow)="expandRow.emit()"
    />
  }
  <div [hidden]="!isRowExpanded()">
    <app-enhance-beneficiary-edit
      [profileType]="profileType()"
      [selectedEnhanceBeneficiaryDetails]="enhanceBeneficiaryDetails()"
      [entityId]="entityId()"
      [isTemplate]="isTemplate()"
      (continue)="onEnhanceBeneficiaryProfile($event)"
    ></app-enhance-beneficiary-edit>
  </div>
</app-collapsible-row>
