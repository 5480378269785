import { AfterViewChecked, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { RktFormComponent, formSelect } from '@rocketfinancialcorp/rocket-ui/form';

import { FormModel } from '@shared/models';
import { TransactionFormActions, selectPaymentReasonByType, selectPaymentReasonOptions, transactionFormFeature } from '@shared/store';

interface PaymentReasonFormModel {
  paymentReason: string;
  isDisabled: boolean;
}

@Component({
  selector: 'app-payment-reason-selection',
  templateUrl: './payment-reason-selection.component.html',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, RktFormComponent],
})
export class PaymentReasonSelectionComponent implements OnInit, AfterViewChecked, OnDestroy {
  @Input() type!: 'from' | 'to';

  paymentReasonFormModel: FormModel<PaymentReasonFormModel> = {
    paymentReason: undefined,
    isDisabled: true,
  };

  paymentReasonForm = new FormGroup({});

  paymentReasonOptions$ = new BehaviorSubject<{ label: string; value: string }[]>([]);

  paymentReasonFormFields = [
    formSelect({
      key: 'paymentReason',
      label: '',
      props: {
        options: this.paymentReasonOptions$.asObservable(),
      },
      expressions: {
        'props.disabled': 'model?.isDisabled',
      },
    }),
  ];

  private destroy$ = new Subject<void>();

  constructor(
    private ref: ChangeDetectorRef,
    private store: Store,
  ) {}

  ngOnInit() {
    this.getPaymentReasons();
  }

  ngAfterViewChecked(): void {
    this.ref.detectChanges();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getPaymentReasons() {
    this.store
      .select(selectPaymentReasonOptions(this.type))
      .pipe(
        concatLatestFrom(() => [
          this.store.select(transactionFormFeature.selectTransactionType),
          this.store.select(selectPaymentReasonByType(this.type)),
        ]),
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: ([paymentReasons, transactionType, selectedReason]) => {
          let isDisabled = false;
          let paymentReason = selectedReason ?? this.paymentReasonFormModel.paymentReason;

          if (paymentReasons.length === 0) {
            isDisabled = true;
          }

          if (transactionType === 'MLT' && this.type === 'from') {
            isDisabled = true;
            paymentReason = 'funds_transfer';
          }

          if (!paymentReasons.find((reason) => reason.value === paymentReason)) {
            paymentReason = undefined;
          }

          this.paymentReasonOptions$.next(paymentReasons);

          this.paymentReasonFormModel = {
            ...this.paymentReasonFormModel,
            paymentReason,
            isDisabled,
          };

          this.onPaymentReasonSelect();
        },
      });
  }

  onPaymentReasonSelect() {
    const { paymentReason = null } = this.paymentReasonFormModel ?? {};

    if (this.type === 'from') {
      this.store.dispatch(TransactionFormActions.setMoveFromPaymentReason({ paymentReason }));
    } else if (this.type === 'to') {
      this.store.dispatch(TransactionFormActions.setMoveToPaymentReason({ paymentReason }));
    }
  }
}
