import { AsyncPipe, CurrencyPipe, NgIf, TitleCasePipe } from '@angular/common';
import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { compact } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RktButtonDirective } from '@rocketfinancialcorp/rocket-ui/button';
import { RktIconComponent } from '@rocketfinancialcorp/rocket-ui/icon';
import { ActiveModal } from '@rocketfinancialcorp/rocket-ui/modal';

import {
  AbstractMoveMoneyBase,
  CollapsibleRowComponent,
  MoveAmountControlComponent,
  MoveMoneyFinancialAccountSelectionComponent,
  MoveMoneyWrapperService,
  MoveNoteControlComponent,
  MoveReasonSelectionComponent,
} from '@shared/components';
import {
  Customer,
  FinancialAccountDetails,
  FinancialAccountListItem,
  Recipient,
  ReverseTransactionModalParams,
  TransactionDetails,
} from '@shared/models';
import {
  CustomerService,
  RecipientService,
  achEntryTypeCodes,
  mapCustomerFinancialAccountWithEntity,
  mapRecipientFinancialAccountWithEntity,
} from '@shared/services';
import { fromTransaction } from '@shared/store';
import { ErrorUtils, getFormattedAmount } from '@shared/utils';

@Component({
  selector: 'app-reverse-transaction-modal',
  templateUrl: 'reverse-transaction-modal.component.html',
  styleUrls: ['reverse-transaction-modal.component.scss'],
  providers: [
    {
      provide: AbstractMoveMoneyBase,
      useClass: MoveMoneyWrapperService,
    },
  ],
  standalone: true,
  imports: [
    RktButtonDirective,
    RktIconComponent,
    CollapsibleRowComponent,
    NgIf,
    MoveReasonSelectionComponent,
    MoveAmountControlComponent,
    MoveMoneyFinancialAccountSelectionComponent,
    MoveNoteControlComponent,
    AsyncPipe,
    TitleCasePipe,
    CurrencyPipe,
  ],
})
export class ReverseTransactionModalComponent implements AfterViewChecked, OnDestroy {
  @ViewChild(MoveAmountControlComponent) amountField!: MoveAmountControlComponent;

  @ViewChild('moveFromSelection') moveFromSelection!: MoveMoneyFinancialAccountSelectionComponent;

  @ViewChild('moveToSelection') moveToSelection!: MoveMoneyFinancialAccountSelectionComponent;

  transaction?: TransactionDetails;

  debitFinancialAccount?: FinancialAccountDetails;

  creditFinancialAccount?: FinancialAccountDetails;

  initialCreditFinancialAccount?: FinancialAccountDetails;

  initialDebitFinancialAccount?: FinancialAccountDetails;

  selectedReversalReason!: string;

  debitAllowedAccountTypes$ = this.store.select(fromTransaction.selectTransactionTypes('debit'));

  creditAllowedAccountTypes$ = this.store.select(fromTransaction.selectTransactionTypes('credit'));

  millikartReversalReasons = ['UNDUE_DISBURSEMENT', 'UNDUE_RECOVERY', 'FEE_REVERSAL', 'INCORRECT_AMOUNT', 'DUPLICATE_TRANSACTION'];

  private destroy$ = new Subject<void>();

  get achEntryTypeDisplayName(): string {
    const { moveAchEntryType } = this.moveMoneyService.moveHowAchDetails ?? {};

    return achEntryTypeCodes.find((item) => item.value === moveAchEntryType)?.label ?? '';
  }

  get achDetailsFieldsCount(): number {
    const { rkorACHIndividualId, rkorACHCheckSerialNumber, rkorACHTerminalCity, rkorACHTerminalState } =
      this.moveMoneyService.moveHowAchDetails || {};

    return compact([rkorACHIndividualId, rkorACHCheckSerialNumber, rkorACHTerminalCity, rkorACHTerminalState]).length;
  }

  get moveAchEntryType(): string {
    return this.moveMoneyService.moveHowAchDetails?.moveAchEntryType ?? '';
  }

  constructor(
    public ref: ChangeDetectorRef,
    public activeModal: ActiveModal,
    public moveMoneyService: AbstractMoveMoneyBase,
    private recipientService: RecipientService,
    private customerService: CustomerService,
    private store: Store,
  ) {}

  ngAfterViewChecked(): void {
    this.ref.detectChanges();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  modalInitData(data: ReverseTransactionModalParams): void {
    const { transaction, creditFinancialAccount, debitFinancialAccount, reverseReason } = data;

    this.transaction = transaction;
    this.creditFinancialAccount = creditFinancialAccount;
    this.debitFinancialAccount = debitFinancialAccount;
    this.selectedReversalReason = reverseReason;
    if (reverseReason === 'INCORRECT_RECIPIENT') {
      this.moveMoneyService.expandedRow = this.transaction?.transactionType === 'SEND' ? 'move-to' : 'move-from';
    } else if (reverseReason === 'INCORRECT_AMOUNT') {
      this.moveMoneyService.expandedRow = 'move-reason-amount';
    }

    this.moveMoneyService.setTransactionData(this.transaction);
    this.setFinancialAccounts();
  }

  setFinancialAccounts(): void {
    if (!this.creditFinancialAccount || !this.debitFinancialAccount) {
      return;
    }

    this.getDebitFinancialAccountData();
    this.getCreditFinancialAccountData();
  }

  getDebitFinancialAccountData() {
    const { accountHolderId, accountHolderType } = this.debitFinancialAccount!;

    if (accountHolderId && accountHolderType === 'RECIPIENT') {
      this.recipientService
        .getRecipientById(accountHolderId)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (recipient) => {
            this.mapDebitFinancialAccountData({ recipient });
          },
          error: (error) => ErrorUtils.catchError('recipientService.getRecipientById', error),
        });
    } else if (accountHolderId && accountHolderType === 'CUSTOMER') {
      this.customerService
        .getCustomerById(accountHolderId)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (customer) => {
            this.mapDebitFinancialAccountData({ customer });
          },
          error: (error) => ErrorUtils.catchError('customerService.getCustomerById', error),
        });
    } else {
      this.mapDebitFinancialAccountData({});
    }
  }

  getCreditFinancialAccountData() {
    const { accountHolderId, accountHolderType } = this.creditFinancialAccount!;

    if (accountHolderId && accountHolderType === 'RECIPIENT') {
      this.recipientService
        .getRecipientById(accountHolderId)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (recipient) => {
            this.mapCreditFinancialAccountData({ recipient });
          },
          error: (error) => ErrorUtils.catchError('recipientService.getRecipientById', error),
        });
    } else if (accountHolderId && accountHolderType === 'CUSTOMER') {
      this.customerService
        .getCustomerById(accountHolderId)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (customer) => {
            this.mapCreditFinancialAccountData({ customer });
          },
          error: (error) => ErrorUtils.catchError('customerService.getCustomerById', error),
        });
    } else {
      this.mapCreditFinancialAccountData({});
    }
  }

  mapDebitFinancialAccountData({ recipient, customer }: { recipient?: Recipient; customer?: Customer }) {
    const isDebitCustomerOrRecipient =
      this.debitFinancialAccount!.accountHolderType && ['RECIPIENT', 'CUSTOMER'].includes(this.debitFinancialAccount!.accountHolderType);

    let debitFinancialAccount;

    if (isDebitCustomerOrRecipient && this.debitFinancialAccount && this.debitFinancialAccount.accountHolderType === 'RECIPIENT') {
      debitFinancialAccount = mapRecipientFinancialAccountWithEntity({
        ...this.debitFinancialAccount!,
        accountHolderType: this.debitFinancialAccount.accountHolderType,
        recipient,
      });
    } else if (isDebitCustomerOrRecipient && this.debitFinancialAccount && this.debitFinancialAccount.accountHolderType === 'CUSTOMER') {
      debitFinancialAccount = mapCustomerFinancialAccountWithEntity({
        ...this.debitFinancialAccount!,
        accountHolderType: this.debitFinancialAccount.accountHolderType,
        customer,
      });
    }

    if (!debitFinancialAccount) {
      debitFinancialAccount = this.moveMoneyService.mapFinancialAccountData(this.debitFinancialAccount!);
    }

    this.initialDebitFinancialAccount = debitFinancialAccount as FinancialAccountDetails;
    this.moveMoneyService.selectedDebitFinancialAccount = debitFinancialAccount;
  }

  mapCreditFinancialAccountData({ recipient, customer }: { recipient?: Recipient; customer?: Customer }) {
    const isCreditCustomerOrRecipient =
      this.creditFinancialAccount!.accountHolderType && ['RECIPIENT', 'CUSTOMER'].includes(this.creditFinancialAccount!.accountHolderType);

    let creditFinancialAccount;

    if (isCreditCustomerOrRecipient && this.creditFinancialAccount && this.creditFinancialAccount.accountHolderType === 'RECIPIENT') {
      creditFinancialAccount = mapRecipientFinancialAccountWithEntity({
        ...this.creditFinancialAccount!,
        accountHolderType: this.creditFinancialAccount.accountHolderType,
        recipient,
      });
    } else if (isCreditCustomerOrRecipient && this.creditFinancialAccount && this.creditFinancialAccount.accountHolderType === 'CUSTOMER') {
      creditFinancialAccount = mapCustomerFinancialAccountWithEntity({
        ...this.creditFinancialAccount!,
        accountHolderType: this.creditFinancialAccount.accountHolderType,
        customer,
      });
    }

    if (!creditFinancialAccount) {
      creditFinancialAccount = this.moveMoneyService.mapFinancialAccountData(this.creditFinancialAccount!);
    }

    this.initialCreditFinancialAccount = creditFinancialAccount as FinancialAccountDetails;
    this.moveMoneyService.selectedCreditFinancialAccount = creditFinancialAccount;
  }

  isMoveFromToDisabled(): boolean {
    if (
      !this.moveMoneyService.selectedCreditFinancialAccount ||
      !this.moveMoneyService.selectedDebitFinancialAccount ||
      !this.initialCreditFinancialAccount ||
      !this.initialDebitFinancialAccount
    ) {
      return true;
    }

    return (
      this.moveMoneyService.selectedCreditFinancialAccount.id === this.initialCreditFinancialAccount.id &&
      this.moveMoneyService.selectedDebitFinancialAccount.id === this.initialDebitFinancialAccount.id
    );
  }

  isAmountSubmitDisabled(): boolean {
    const initialAmount = getFormattedAmount(parseFloat(`${this.transaction?.amount}`)).formattedValue;
    const moveAmount = getFormattedAmount(parseFloat(`${this.moveMoneyService.moveAmount}`)).formattedValue;
    return (
      !!this.moveMoneyService.moveAmountError ||
      !this.moveMoneyService.moveAmount ||
      !this.moveMoneyService.paymentReason?.id ||
      this.moveMoneyService.moveAmount === initialAmount ||
      moveAmount === initialAmount
    );
  }

  onDebitFinancialAccountSelect(financialAccount: FinancialAccountListItem): void {
    this.moveMoneyService.selectedDebitFinancialAccount = financialAccount;
    this.moveMoneyService.expandedRow = undefined;
  }

  onCreditFinancialAccountSelect(financialAccount: FinancialAccountListItem): void {
    this.moveMoneyService.selectedCreditFinancialAccount = financialAccount;
    this.moveMoneyService.expandedRow = undefined;
  }

  isSubmitDisabled(): boolean {
    const reason = this.selectedReversalReason;
    if (reason === 'INCORRECT_RECIPIENT') {
      return this.isMoveFromToDisabled();
    } else if (reason === 'INCORRECT_AMOUNT' && this.moveMoneyService.moveHowSolutionDisplayValue !== 'Millikart Transaction') {
      return this.isAmountSubmitDisabled();
    } else if (this.millikartReversalReasons.includes(reason)) {
      return false;
    } else {
      return true;
    }
  }

  getMoveMoneyData() {
    const { moveDirection, moveHowType } = this.moveMoneyService.moveHow;
    const { reason } = this.moveMoneyService.paymentReason ?? {};
    return {
      selectedMoveItem: moveDirection,
      selectedMoveHowType: moveHowType,
      paymentReasonName: reason,
      moveAmountValue: this.moveMoneyService.moveAmount,
      selectedMoveTo: this.moveMoneyService.selectedCreditFinancialAccount,
      selectedMoveFrom: this.moveMoneyService.selectedDebitFinancialAccount,
      note: this.moveMoneyService.note,
      achDetails: this.moveMoneyService.moveHowAchDetails,
      moveHowDirectionDisplayValue: this.moveMoneyService.moveHowDirectionDisplayValue,
      moveHowSolutionDisplayValue: this.moveMoneyService.moveHowSolutionDisplayValue,
      moveHowSolutionIcon: this.moveMoneyService.moveHowSolutionIcon,
      achEntryTypeDisplayName: this.achEntryTypeDisplayName,
      moveAchEntryType: this.moveAchEntryType,
      moveHowAchDetails: this.moveMoneyService.moveHowAchDetails,
    };
  }

  onReverseSubmit(): void {
    if (!this.selectedReversalReason) {
      return;
    }

    const reason = this.selectedReversalReason;
    const reverseData: Record<string, string> = {};

    switch (reason) {
      case 'INCORRECT_AMOUNT':
        reverseData['correctionAmount'] = this.moveMoneyService.moveAmount!;
        break;
      case 'INCORRECT_RECIPIENT':
        reverseData['correctionFinancialAccountId'] =
          this.transaction?.transactionType === 'SEND'
            ? this.moveMoneyService.selectedCreditFinancialAccount!.id!
            : this.moveMoneyService.selectedDebitFinancialAccount!.id!;
        break;
      default:
        break;
    }

    this.activeModal.close({
      reviewData: this.getMoveMoneyData(),
      reversalReason: reason,
      note: this.moveMoneyService.note,
      ...reverseData,
    });
  }

  onAmountBlur(): void {
    if (this.moveMoneyService.moveAmount && this.moveMoneyService.paymentReason) {
      this.moveMoneyService.expandedRow = undefined;
    }
  }
}
