import { AsyncPipe } from '@angular/common';
import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  Subject,
  catchError,
  defaultIfEmpty,
  finalize,
  forkJoin,
  from,
  map,
  mergeMap,
  of,
  switchMap,
  take,
  takeUntil,
  tap,
  toArray,
} from 'rxjs';
import { DatatableColumn, FetchData, RktTableComponent } from '@rocketfinancialcorp/rocket-ui/table';

import { TransactionBatchTransactionItemRaw } from '@shared/models';
import { FinancialAccountService } from '@shared/services';
import { fromAuth, selectTransactionBatchDetails } from '@shared/store';
import { getMoveFromToFinancialAccount } from '@shared/utils';

@Component({
  selector: 'app-transaction-batch-rejected-list',
  templateUrl: './transaction-batch-rejected-list.component.html',
  standalone: true,
  imports: [RktTableComponent, AsyncPipe],
})
export class TransactionBatchRejectedListComponent implements AfterViewChecked, OnDestroy {
  store = inject(Store);

  rejectedTransactions: TransactionBatchTransactionItemRaw[] = [];

  transactions: TransactionBatchTransactionItemRaw[] = [];

  columns: DatatableColumn[] = [
    { name: 'Move From', prop: 'debitFinancialAccountName', colType: 'two-line-col', flexSize: 250 },
    { name: 'Move To', prop: 'creditFinancialAccountName', colType: 'two-line-col', flexSize: 250 },
    { name: 'Rejection Reason', prop: 'errorMessage', flexSize: 500, isMultiline: true },
    { name: 'Amount', prop: 'amount', flexSize: 151, colAlign: 'right', colType: 'amount' },
    { name: 'Status', prop: 'latestStatus', fixedSize: 140, colAlign: 'right', colType: 'status-tag' },
  ];

  totalElements = 0;

  loading = true;

  transactionBatch$ = this.store.select(selectTransactionBatchDetails).pipe(
    tap({
      next: (transactionBatch) => {
        this.rejectedTransactions = transactionBatch?.transactions.filter((item) => !!item.errorMessage && !item.id) ?? [];
      },
    }),
  );

  private destroy$ = new Subject<void>();

  constructor(
    private financialAccountService: FinancialAccountService,
    private ref: ChangeDetectorRef,
  ) {}

  ngAfterViewChecked(): void {
    this.ref.detectChanges();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getTransactions({ page }: FetchData): void {
    const curPage = page ?? 0;
    this.loading = true;

    const transactions = this.rejectedTransactions.slice(curPage * 25, (curPage + 1) * 25);

    this.store
      .select(fromAuth.selectBusinessAccountId)
      .pipe(
        take(1),
        switchMap((businessAccountId) => {
          if (!transactions.length) return of([]);

          return from(transactions).pipe(
            mergeMap(
              (item) =>
                forkJoin({
                  debit: this.financialAccountService
                    .getFinancialAccountRawDetails({
                      businessAccountId,
                      financialAccountId: item.debitFinancialAccountId,
                      isSuppressError: true,
                    })
                    .pipe(catchError(() => of(null))),

                  credit: this.financialAccountService
                    .getFinancialAccountRawDetails({
                      businessAccountId,
                      financialAccountId: item.creditFinancialAccountId,
                      isSuppressError: true,
                    })
                    .pipe(catchError(() => of(null))),
                }).pipe(
                  map(({ debit, credit }) => ({
                    ...item,
                    debitFinancialAccountName: debit ? getMoveFromToFinancialAccount(debit) : item.debitFinancialAccountId,

                    creditFinancialAccountName: credit ? getMoveFromToFinancialAccount(credit) : item.creditFinancialAccountId,
                  })),
                ),
              5,
            ),
            toArray(),
          );
        }),
        defaultIfEmpty([]),
        takeUntil(this.destroy$),
        finalize(() => (this.loading = false)),
      )
      .subscribe({
        next: (processedTransactions) => {
          this.totalElements = this.rejectedTransactions.length;
          this.transactions = processedTransactions;
        },
      });
  }
}
