import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';

import { AuthLayoutComponent } from '../auth-layout/auth-layout.component';

@Component({
  selector: 'app-auth-page',
  templateUrl: './auth-page.component.html',
  standalone: true,
  imports: [AuthLayoutComponent, RouterOutlet],
})
export class AuthPageComponent {}
