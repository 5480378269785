import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { ReportService } from '@shared/services';
import { MessagesActions } from '@shared/store';
import { ErrorUtils } from '@shared/utils';

import { AskMeAnythingActions } from './ask-me-anything.actions';

@Injectable()
export class AskMeAnythingEffects {
  constructor(
    private actions$: Actions,
    private reportService: ReportService,
  ) {}

  loadAskMeAnythingQuestions$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AskMeAnythingActions.loadAskMeAnythingQuestions),
      switchMap(() => {
        return this.reportService.getAskMeAnythingQuestions().pipe(
          map((askMeAnythingQuestions) =>
            AskMeAnythingActions.loadAskMeAnythingQuestionSuccess({
              askMeAnythingQuestions,
            }),
          ),
          catchError((error) => {
            return of(AskMeAnythingActions.loadAskMeAnythingQuestionFailure({ error }));
          }),
        );
      }),
    );
  });

  loadAskMeAnythingQuestionFailure$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AskMeAnythingActions.loadAskMeAnythingQuestionFailure),
      tap({
        next: (loadHistoryError) => {
          ErrorUtils.catchError('reportService.loadAskMeAnythingQuestionFailure error', loadHistoryError.error);
        },
      }),
      switchMap(() => of(MessagesActions.displayError({ message: 'Unable to fetch ask me anything questions.' }))),
    );
  });
}
