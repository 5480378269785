import { NgFor } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { RktButtonDirective } from '@rocketfinancialcorp/rocket-ui/button';
import { ActiveModal } from '@rocketfinancialcorp/rocket-ui/modal';

import { MESSAGE } from '@shared/constants';

import { AttentionModalComponent } from '../../attention-modal/attention-modal.component';

export interface ErrorBlock {
  title: string;
  fields: string[];
}

@Component({
  selector: 'app-identity-verification-error-modal',
  templateUrl: 'identity-verification-error-modal.component.html',
  styleUrls: ['identity-verification-error-modal.component.scss'],
  standalone: true,
  imports: [RktButtonDirective, AttentionModalComponent, NgFor],
})
export class IdentityVerificationErrorModalComponent implements OnInit {
  @Input() messageText?: string;

  @Input() errorBlocks?: ErrorBlock[];

  errorType?: string | number;

  modalTitle = 'Identity Verification Cannot Be Submitted';

  modalSubTitle!: string;

  constructor(public activeModal: ActiveModal) {}

  ngOnInit() {
    this.modalTitle = this.errorType === 403 ? MESSAGE.PERMISSION_DENIED_TITLE : this.modalTitle || 'Error';
  }
}
