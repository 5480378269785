import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { formDatepicker, formInput, formRow, formSelectCountry, formSelectState, formTextarea } from '@rocketfinancialcorp/rocket-ui/form';

import { Patterns } from '@shared/components';

const stateFieldOptions = {
  expressions: {
    hide: 'model.documentType === "passport" || (["CAN", "USA"].includes(model.issuingCountryCode))',
    'props.disabled': '(!["CAN", "USA"].includes(model.issuingCountryCode))',
  },
};

const stateFieldDropdownOptions = {
  expressions: {
    'props.required': 'model.documentType === "driving-license" && (["CAN", "USA"].includes(model.issuingCountryCode))',
    'props.countryCode3': 'model.issuingCountryCode',
    hide: 'model.documentType === "passport" || (!["CAN", "USA"].includes(model.issuingCountryCode))',
  },
};

export const documentFields = [
  formRow([
    formInput({
      key: 'name',
      label: 'Document Nam\u0435',
      props: {
        pattern: Patterns.GENERIC_NAME,
        maxLength: 40,
        required: true,
      },
      validation: { messages: { pattern: 'Enter a valid Document Nam\u0435' } },
    }),
  ]),
  formRow([
    formInput({
      key: 'number',
      label: 'Document Numbe\u0072',
      props: { required: true },
      expressions: {
        'props.required': 'model.documentType === "passport" || model.documentType === "driving-license"',
      },
      validation: { messages: { pattern: 'Enter a valid Document Numbe\u0072' } },
    }),
    formDatepicker({ key: 'issuingDate', label: 'Issuing Date', props: { required: false, placeholder: 'MM/DD/YY' } }),
    formDatepicker({
      key: 'expiryDate',
      label: 'Expiry Date',
      props: {
        required: true,
        placeholder: 'MM/DD/YY',
        maxDate: NgbDate.from({ day: 1, month: 1, year: new Date().getFullYear() + 120 }),
      },
      expressions: {
        'props.required': '!(model.documentType === "other" || model.accountHolderType === "Business")',
      },
    }),
  ]),
  formRow([
    formSelectCountry({
      key: 'issuingCountryCode',
      label: 'Issuing Country',
      props: { required: true, placeholder: 'Select' },
      expressions: {
        'props.required': '!(model.documentType === "other" || model.accountHolderType === "Business")',
        onInit: (field: FormlyFieldConfig) => {
          field.formControl?.valueChanges?.subscribe({
            next: () => {
              field?.form?.get('issuingStateCode')?.setValue(undefined);
            },
          });
        },
      },
    }),
    formSelectState({
      key: 'issuingStateCode',
      label: 'State',
      className: 'state-field',
      ...stateFieldDropdownOptions,
    }),
    formInput({
      key: 'issuingStateCode',
      label: 'State',
      className: 'state-field',
      props: { required: false, minLength: 2, maxLength: 3 },
      ...stateFieldOptions,
    }),
  ]),
  formRow([
    formTextarea({
      key: 'description',
      label: 'Document Descriptio\u006e',
      props: {
        required: false,
        maxLength: 100,
      },
      expressions: {
        'props.required': 'model.documentType === "other" || model.accountHolderType === "Business"',
      },
    }),
  ]),
];
