import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { Observable, map } from 'rxjs';
import { formCheckboxGroup, formDatepicker, formInput, formRow, formSelect } from '@rocketfinancialcorp/rocket-ui/form';

import { QRAILS_CARD_SHIPPING_METHODS, QRAILS_CARD_SUPPORTED_LANGUAGES } from '@shared/enums';
import { CardProduct } from '@shared/models';

const expressions = {
  'props.disabled': 'model.category === "EXTERNAL" || model.category === "INTERNAL" || model.isEdit',
};

export const integratedCardFields = ({ products$ }: { products$: Observable<CardProduct[] | null> }) => [
  formRow([
    formSelect({
      key: 'card.productId',
      label: 'Card Product',
      props: {
        options: products$.pipe(
          map((products) => {
            if (!products) return [];
            return products.map((product) => ({ label: product.name, value: product.id }));
          }),
        ),
        required: true,
        loading: products$.pipe(map((products) => products === null)),
      },
      expressions: {
        'props.disabled': 'model.category === "EXTERNAL" || model.category === "INTERNAL" || model.isEdit',
      },
    }),
  ]),
  formRow([formInput({ key: 'card.nameOnCard', label: 'Nam\u0435 on Car\u0064', expressions })]),
  formRow([
    formSelect({
      key: 'card.shippingMethod',
      label: 'Shipping Method',
      props: {
        options: Object.keys(QRAILS_CARD_SHIPPING_METHODS).map((key) => ({ label: QRAILS_CARD_SHIPPING_METHODS[key], value: key })),
        required: true,
      },
      expressions,
    }),
  ]),
  formRow([
    formSelect({
      key: 'card.issuedLanguageCode',
      label: 'Issue Language',
      props: {
        options: Object.keys(QRAILS_CARD_SUPPORTED_LANGUAGES).map((key) => ({ label: QRAILS_CARD_SUPPORTED_LANGUAGES[key], value: key })),
        required: false,
      },
      expressions,
    }),
  ]),
  formRow(
    [
      formDatepicker({
        key: 'card.acceptedTermsDate',
        label: 'Terms and Conditions Acceptance Date',
        className: 'half-row-field',
        props: {
          placeholder: 'DD/MM/YYYY',
          required: true,
          maxDate: NgbDate.from({ day: 1, month: 1, year: new Date().getFullYear() + 120 }),
        },
        expressions,
      }),
    ],
    { className: 'datepicker-placement-top' },
  ),
  formRow([
    formCheckboxGroup({
      key: 'card.flags',
      label: '',
      props: {
        options: [{ value: 'defaultFlag', label: 'Set as default account' }],
        required: false,
      },
      expressions,
    }),
  ]),
];
