import { NgFor, NgIf } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';
import { cloneDeep } from 'lodash-es';
import { RktButtonDirective } from '@rocketfinancialcorp/rocket-ui/button';
import { RktIconComponent } from '@rocketfinancialcorp/rocket-ui/icon';
import { ModalService } from '@rocketfinancialcorp/rocket-ui/modal';

import { ALLOWED_ATTACHMENT_FILE_FORMATS } from '@shared/constants';
import { AttachmentCreateModel } from '@shared/models';

import { DragDropAttachmentDirective } from '../../../directives/drag-drop-attachment.directive';
import { MultipleFileUploadComponent } from '../../multiple-file-upload/multiple-file-upload.component';
import { AttachmentCreateFormComponent } from '../attachment-create-form/attachment-create-form.component';

@Component({
  selector: 'app-create-entity-attachment',
  templateUrl: './create-entity-attachment.component.html',
  styleUrls: ['./create-entity-attachment.component.scss'],
  standalone: true,
  imports: [RktButtonDirective, NgIf, DragDropAttachmentDirective, NgFor, MultipleFileUploadComponent, RktIconComponent],
})
export class CreateEntityAttachmentComponent {
  @Output() filesToUpload = new EventEmitter<Partial<AttachmentCreateModel>[] | null>();

  attachmentsData: Partial<AttachmentCreateModel>[] = [];

  allowedFileFormats = ALLOWED_ATTACHMENT_FILE_FORMATS;

  constructor(private modalService: ModalService) {}

  onFileUploaded(files: File[]): void {
    files.forEach((file) => {
      const extension = file?.name.match(/^.*\.(?<extension>[a-z]+)$/i);
      this.attachmentsData.push({
        file: file,
        extension: extension?.groups?.extension.toUpperCase(),
      });
    });
    this.filesEmit();
  }

  onFilesDropped(files: Partial<AttachmentCreateModel>[]): void {
    this.attachmentsData = [...this.attachmentsData, ...files];
    this.filesEmit();
  }

  onDeleteFileBtnClick(i: number): void {
    this.attachmentsData = cloneDeep(this.attachmentsData);
    this.attachmentsData.splice(i, 1);
    this.filesEmit();
  }

  filesEmit(): void {
    if (this.attachmentsData.length) {
      this.filesToUpload.emit(this.attachmentsData);
    } else {
      this.filesToUpload.emit(null);
    }
  }

  onAddAttachment(): void {
    const documentCreateFormModalRef = this.modalService.open(AttachmentCreateFormComponent, {
      className: 'entity-form-modal',
    });

    documentCreateFormModalRef.result.then(
      (result) => {
        if (result) {
          this.attachmentsData = cloneDeep(this.attachmentsData);
          this.attachmentsData.push(result);
          this.filesEmit();
        }
      },
      () => false,
    );
  }

  onEditFileBtnClick(i: number): void {
    const attachmentEditFormModalRef = this.modalService.open(AttachmentCreateFormComponent, {
      className: 'entity-form-modal',
    });
    attachmentEditFormModalRef.componentInstance.isEditMode = true;
    attachmentEditFormModalRef.componentInstance.formModel = {
      name: this.attachmentsData[i].name,
      description: this.attachmentsData[i].description,
    };

    attachmentEditFormModalRef.result.then(
      (result) => {
        if (result) {
          this.attachmentsData = cloneDeep(this.attachmentsData);
          this.attachmentsData[i].description = result.description;
          this.attachmentsData[i].name = result.name;
          this.filesEmit();
        }
      },
      () => false,
    );
  }
}
