<div class="flex flex-wrap gap-3">
  @for (option of requestOptions; track option.type) {
    <button
      class="flex items-center gap-2 py-2 px-3 rounded-lg border border-gray-300 font-semibold cursor-pointer hover:text-primary hover:border-primary"
      (click)="onRequestOptionClick(option)"
      [ngClass]="{
        'text-primary border-primary': option.isChecked,
        'pointer-events-none opacity-50': isDisabledType(option.type) || option.disabled,
      }"
    >
      <rkt-icon class="text-xl" [name]="option.iconName"></rkt-icon>
      <span class="text-black">{{ option.label }}</span>
      <rkt-icon class="text-xl" [name]="option.isChecked ? 'checkbox-on' : 'checkbox-off'"></rkt-icon>
    </button>
  }
</div>
