import { createAction, props } from '@ngrx/store';

import { AskMeAnythingQuestion, ErrorMessage } from '@shared/models';

export namespace AskMeAnythingActions {
  export const loadAskMeAnythingQuestions = createAction('[Insight API] GET User Favorite Questions');

  export const loadAskMeAnythingQuestionSuccess = createAction(
    '[Insight API] Load User Favorite Questions Success',
    props<{ askMeAnythingQuestions: AskMeAnythingQuestion[] }>(),
  );

  export const loadAskMeAnythingQuestionFailure = createAction(
    '[Insight API] Load User Favorite Questions Error',
    props<{ error: ErrorMessage }>(),
  );
}
