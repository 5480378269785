import { Component, Input, OnInit } from '@angular/core';
import { RktButtonDirective } from '@rocketfinancialcorp/rocket-ui/button';
import { ActiveModal } from '@rocketfinancialcorp/rocket-ui/modal';

import { MESSAGE } from '@shared/constants';

import { AttentionModalComponent } from '../attention-modal/attention-modal.component';

@Component({
  selector: 'app-error-modal',
  templateUrl: 'error-modal.component.html',
  standalone: true,
  imports: [RktButtonDirective, AttentionModalComponent],
})
export class ErrorModalComponent implements OnInit {
  @Input() messageText = 'We are unable to complete this request. Please contact your administrator.';

  errorType?: string | number;

  modalTitle = 'Error';

  modalSubTitle!: string;

  btnText = 'Continue';

  constructor(public activeModal: ActiveModal) {}

  ngOnInit() {
    this.modalTitle = this.errorType === 403 ? MESSAGE.PERMISSION_DENIED_TITLE : this.modalTitle || 'Error';
  }

  modalInitData(data: { modalTitle: string; messageText: string; btnText: string }) {
    const { modalTitle, messageText, btnText } = data;
    this.modalTitle = modalTitle;
    this.messageText = messageText;
    this.btnText = btnText;
  }
}
