import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { fromAudit } from '@shared/store';

@Pipe({
  name: 'teamMemberName',
  standalone: true,
})
export class TeamMemberNamePipe implements PipeTransform {
  constructor(private store: Store) {}

  transform(value = ''): Observable<string | undefined> {
    return this.store.select(fromAudit.selectTeamMemberName(value));
  }
}
