import { ChangeDetectionStrategy, Component, input, output, signal } from '@angular/core';

import { CollapsibleRowComponent } from '@shared/components/collapsible-row/collapsible-row.component';
import { EnhanceProfileDetails, LinxProfileType } from '@shared/constants';
import { LinxRequestBenificiaryDetails } from '@shared/models';

import { EnhanceBeneficiaryEditComponent } from './enhance-beneficiary-edit/enhance-beneficiary-edit.component';
import { EnhanceBenificiaryPreviewComponent } from './enhance-benificiary-preview/enhance-benificiary-preview.component';

@Component({
  selector: 'app-enhance-beneficiary-step',
  standalone: true,
  imports: [CollapsibleRowComponent, EnhanceBenificiaryPreviewComponent, EnhanceBeneficiaryEditComponent],
  templateUrl: './enhance-beneficiary-step.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnhanceBeneficiaryStepComponent {
  isRowExpanded = input<boolean>(false);

  isRowHasValue = input<boolean>(true);

  isEditDisabled = input<boolean>(false);

  profileType = input<LinxProfileType | undefined>(LinxProfileType.New);

  isTemplate = input<boolean>(false);

  entityId = input<string>();

  expandRow = output();

  profileChange = output<{
    profileData: LinxRequestBenificiaryDetails | undefined;
    displayData: string[];
  }>();

  enhanceBeneficiaryDetails = signal<LinxRequestBenificiaryDetails | undefined>(undefined);

  enhanceBenificiaryDisplayData = signal<string[]>([]);

  onEnhanceBeneficiaryProfile(event: LinxRequestBenificiaryDetails): void {
    const displayData = [];

    if (event?.enhanceProfileDetails) {
      const { personalInfo = [], addresses = [], phoneNumber = [], email = [] } = event.enhanceProfileDetails ?? {};

      [...personalInfo, ...addresses].forEach((item) => {
        displayData.push(EnhanceProfileDetails[item.replace('-', '').toUpperCase()]);
      });

      if (phoneNumber.length) {
        displayData.push(EnhanceProfileDetails['PHONENUMBER']);
      }

      if (email.length) {
        displayData.push(EnhanceProfileDetails['EMAIL']);
      }
    }

    this.enhanceBenificiaryDisplayData.set(displayData);
    this.enhanceBeneficiaryDetails.set(event);
    this.profileChange.emit({ profileData: event, displayData });
  }
}
