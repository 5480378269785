<h1 class="title">Review</h1>

<button class="close-button" (click)="activeModal.close()"><rkt-icon name="times"></rkt-icon></button>

<div class="details-page-edit-form" *ngIf="customer">
  <div class="form-step">
    <div class="step-name">{{ customer.customerType | titlecase }} Information</div>
  </div>

  <ng-container *ngIf="customer?.customerType === 'BUSINESS'; else viewIndividualCustomer">
    <div class="business-name-item">
      <app-details-item [showPlaceholder]="false" label="Business Name on Account" [value]="customer.businessName"></app-details-item>
    </div>

    <div class="details-row">
      <app-details-item [showPlaceholder]="false" label="First Name" [value]="customer.contactFirstName"></app-details-item>

      <app-details-item [showPlaceholder]="false" label="Middle Name" [value]="customer.contactMiddleName"></app-details-item>

      <app-details-item [showPlaceholder]="false" label="Last Name" [value]="customer.contactLastName"></app-details-item>

      <app-details-item [showPlaceholder]="false" label="Email address" [value]="customer.contactPrimaryEmail"></app-details-item>

      <app-details-item [showPlaceholder]="false" label="Mobile number" [value]="customer.contactPrimaryPhoneNumber"></app-details-item>
    </div>
  </ng-container>

  <ng-template #viewIndividualCustomer>
    <div class="details-row">
      <app-details-item [showPlaceholder]="false" label="First name" [value]="customer.firstName"></app-details-item>

      <app-details-item [showPlaceholder]="false" label="Middle name" [value]="customer.middleName"></app-details-item>

      <app-details-item [showPlaceholder]="false" label="Last name" [value]="customer.lastName"></app-details-item>

      <app-details-item [showPlaceholder]="false" label="Email address" [value]="customer.customerEmailAddress"></app-details-item>

      <app-details-item [showPlaceholder]="false" label="Mobile number" [value]="customer.customerPhoneNumber"></app-details-item>
    </div>
  </ng-template>

  <div class="note-item" *ngIf="customer?.noteContent">
    <app-details-item [showPlaceholder]="false" label="Notes" [value]="customer.noteContent"></app-details-item>
  </div>

  <div *ngIf="businessCustomerHasAdditionalInformation || individualCustomerHasAdditionalInformation || customerAddress?.length">
    <div class="details-separator"></div>

    <div class="form-step">
      <div class="step-name">Additional Information</div>
    </div>
  </div>

  <ng-container *ngIf="customer?.customerType === 'BUSINESS'; else individualCustomerAdditionalInformation">
    <div class="details-row additional-information" *ngIf="businessCustomerHasAdditionalInformation">
      <app-details-item
        *ngIf="customer?.dateOfFormation"
        [showPlaceholder]="false"
        label="Date of Formation"
        [value]="customer.dateOfFormation | dateFormat: 'MM/dd/yy' : 'ISO'"
      ></app-details-item>

      <app-details-item
        *ngIf="customer.taxId"
        [showPlaceholder]="false"
        label="Business Tax ID"
        [value]="customer.taxId"
      ></app-details-item>

      <app-details-item
        *ngIf="customer.doingBusinessAsName"
        [showPlaceholder]="false"
        label="Business DBA Name"
        [value]="customer.doingBusinessAsName"
      ></app-details-item>

      <app-details-item
        *ngIf="customer.legalName"
        [showPlaceholder]="false"
        label="Legal Name of Business"
        [value]="customer.legalName"
      ></app-details-item>

      <app-details-item
        *ngIf="customer.businessPrimaryPhoneNumber"
        [showPlaceholder]="false"
        label="Business Primary Phone"
        [value]="customer.businessPrimaryPhoneNumber"
      ></app-details-item>

      <app-details-item
        *ngIf="customer.website"
        [showPlaceholder]="false"
        label="Company website"
        [value]="customer.website"
      ></app-details-item>

      <app-details-item
        *ngIf="customer.legalEntityType"
        [showPlaceholder]="false"
        label="Type of Business"
        [value]="customer.legalEntityType"
      ></app-details-item>

      <app-details-item
        *ngIf="customer?.externalId"
        [showPlaceholder]="false"
        label="External ID"
        [value]="customer.externalId"
      ></app-details-item>

      <div *ngIf="businessCustomerIndustry$ | async as businessCustomerIndustry">
        <app-details-item [showPlaceholder]="false" label="Industry" [value]="businessCustomerIndustry.name"></app-details-item>
      </div>
    </div>
  </ng-container>

  <ng-template #individualCustomerAdditionalInformation>
    <div class="details-row additional-information" *ngIf="individualCustomerHasAdditionalInformation">
      <app-details-item
        *ngIf="customer?.dateOfBirth"
        [showPlaceholder]="false"
        label="Date of Birth"
        [value]="customer.dateOfBirth | dateFormat: 'MM/dd/yy' : 'ISO'"
      ></app-details-item>

      <app-details-item
        *ngIf="customerPlaceOfBirth"
        [showPlaceholder]="false"
        label="Place of Birth"
        [value]="customerPlaceOfBirth"
      ></app-details-item>

      <app-details-item *ngIf="customer?.sex" [showPlaceholder]="false" label="Sex" [value]="customerGender"></app-details-item>

      <app-details-item
        *ngIf="customer?.socialId"
        [showPlaceholder]="false"
        label="Social ID Number"
        [value]="customer.socialId"
      ></app-details-item>

      <app-details-item
        *ngIf="customer?.citizenshipCountryCode"
        [showPlaceholder]="false"
        label="Citizenship Country"
        [value]="customer.citizenshipCountryCode"
      ></app-details-item>

      <app-details-item
        *ngIf="customer?.externalId"
        [showPlaceholder]="false"
        label="External ID"
        [value]="customer.externalId"
      ></app-details-item>
    </div>
  </ng-template>

  <div class="additional-information address-list" *ngIf="customerAddress?.length">
    <ng-container *ngFor="let address of customerAddress">
      <div class="address-type">{{ getAddressTitle(address?.type, customer.customerType) | titlecase }}</div>

      <div class="details-row">
        <app-details-item [showPlaceholder]="false" label="Address 1" [value]="address?.streetLine1" [noBreak]="true"></app-details-item>

        <app-details-item [showPlaceholder]="false" label="City" [value]="address?.cityName" [noBreak]="true"></app-details-item>

        <app-details-item [showPlaceholder]="false" label="State" [value]="address?.stateCode"></app-details-item>

        <app-details-item [showPlaceholder]="false" label="Zip code" [value]="address?.zipCode" [noBreak]="true"></app-details-item>

        <app-details-item [showPlaceholder]="false" label="Country" [value]="address?.countryCode"></app-details-item>
      </div>
    </ng-container>
  </div>
</div>

<div class="review-buttons">
  <button rktButton btnType="secondary" title="Back to Edit" (click)="activeModal.close({ action: 'EDIT' })">Back to Edit</button>

  <div class="buttons">
    <button rktButton type="button" btnType="tertiary" (click)="activeModal.close()">Discard</button>
    <button rktButton type="submit" (click)="activeModal.close({ action: 'SAVE' })" title="Create">Create</button>
  </div>
</div>
