<div class="view-attachment-container">
  <div class="attachment-header-content" [class.bg-blur]="isDescriptionExpanded">
    <div class="details-page-header-wrapper">
      <div class="attachment-name">
        <rkt-icon name="document"></rkt-icon>
        <div>{{ name }}</div>
      </div>

      <div class="action-buttons">
        @if (canUpdate) {
          <button
            class="action-button trash-button"
            *ngIf="!readOnly"
            [disabled]="false | accessControl | async"
            (click)="activeModal.close('DELETE')"
          >
            <rkt-icon name="trash"></rkt-icon>
          </button>
          <button class="action-button" *ngIf="!readOnly" [disabled]="false | accessControl | async" (click)="activeModal.close('EDIT')">
            <rkt-icon name="edit"></rkt-icon>
          </button>
          <button class="action-button" (click)="onDownloadClick()"><rkt-icon name="download"></rkt-icon></button>
        }
        <button class="action-button" (click)="activeModal.close()"><rkt-icon name="times"></rkt-icon></button>
      </div>
    </div>
    <div class="attachment-description" *ngIf="description">
      <div [class.collapse]="!isDescriptionExpanded">{{ description }}</div>
      <button class="expand-collapse-button" (click)="onExpandCollapse()">
        <rkt-icon [name]="isDescriptionExpanded ? 'chevron-up' : 'chevron-down'"></rkt-icon>
      </button>
    </div>
  </div>

  <div class="attachment-preview">
    <ngx-extended-pdf-viewer
      *ngIf="url && fileFormat === 'PDF'; else imagePreview"
      [src]="url"
      [showBorders]="false"
      [showToolbar]="false"
      [textLayer]="false"
      [sidebarVisible]="false"
      backgroundColor="#F7F7F7"
      [useBrowserLocale]="true"
      [authorization]="true"
    >
    </ngx-extended-pdf-viewer>

    <ng-template #imagePreview>
      <div *ngIf="url && isImageFormat; else noPreview" class="image-preview">
        <img [src]="url" [alt]="name" />
      </div>
    </ng-template>

    <ng-template #noPreview>
      <div class="empty-container">
        <div class="empty-container-icon">
          <div class="empty-list-placeholder"></div>
        </div>
        <div class="empty-container-description">
          <div>No preview available</div>
          <div>Try downloading the file to view it</div>
        </div>
        <button rktButton class="download-button" (click)="onDownloadClick()"><rkt-icon name="download"></rkt-icon>Download</button>
      </div>
    </ng-template>
  </div>
</div>
