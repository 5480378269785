import { Action, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';

import { constants } from '@shared/constants';
import { fromRoot } from '@shared/store';

import * as fromAuth from './auth.reducer';
import * as fromLoginPage from './login-page.reducer';

export const authFeatureKey = 'auth';

export interface AuthRootState {
  [fromAuth.authFeatureKey]: fromAuth.AuthState;
  [fromLoginPage.loginPageFeatureKey]: fromLoginPage.LoginPageState;
}

export interface State extends fromRoot.RootState {
  [authFeatureKey]: AuthRootState;
}

export const reducers = (state: AuthRootState | undefined, action: Action) => {
  return combineReducers({
    [fromAuth.authFeatureKey]: fromAuth.reducer,
    [fromLoginPage.loginPageFeatureKey]: fromLoginPage.reducer,
  })(state, action);
};

export const selectAuthState = createFeatureSelector<AuthRootState>(authFeatureKey);

// Auth State selectors
export const selectAuthStatusState = createSelector(selectAuthState, (state) => state.status);

export const selectBusinessAccount = createSelector(selectAuthStatusState, fromAuth.getBusinessAccount);

export const selectBusinessAccountDetails = createSelector(selectAuthStatusState, fromAuth.getBusinessAccountDetails);

export const selectTokenInfo = createSelector(selectAuthStatusState, fromAuth.getToken);

// Auth Tenant selectors
export const selectTenant = createSelector(selectAuthStatusState, fromAuth.getTenant);

export const selectSponsorTenant = createSelector(selectAuthStatusState, fromAuth.getSponsorTenant);

// Auth User selectors
export const selectUser = createSelector(selectAuthStatusState, fromAuth.getUser);

export const selectActiveUserId = createSelector(selectUser, (user) => user?.id ?? null);

export const selectUserFirstName = createSelector(selectUser, (user) => user?.firstName ?? null);

export const selectLoggedIn = createSelector(selectUser, (user) => !!user);

export const selectUserRole = createSelector(selectUser, (user) => user?.role ?? 'View Only');

// Auth Token selectors
export const selectTokenExpireInMilliseconds = createSelector(
  selectTokenInfo,
  (tokenInfo) => (tokenInfo?.expires_in ? parseInt(tokenInfo.expires_in, 10) : constants.DEFAULT_TOKEN_EXPIRE_IN_SECONDS) * 1000,
);

// Auth Business Account selectors
export const selectActiveBusinessAccountDetails = createSelector(selectBusinessAccountDetails, (businessAccount) => businessAccount!);

export const selectBusinessAccountId = createSelector(selectActiveBusinessAccountDetails, (businessAccount) => businessAccount?.id);

export const selectBusinessAccountName = createSelector(selectBusinessAccountDetails, (businessAccount) => businessAccount?.operatingName);

export const selectBusinessAccountCountryCode = createSelector(
  selectActiveBusinessAccountDetails,
  (businessAccount) => businessAccount?.legalIdCountryCode,
);

// Login Page selectors
export const selectLoginPageState = createSelector(selectAuthState, (state) => state.loginPage);

export const selectLoginPageError = createSelector(selectLoginPageState, fromLoginPage.getError);

export const selectLoginPagePending = createSelector(selectLoginPageState, fromLoginPage.getPending);

export const selectShowSplashScreen = createSelector(selectAuthStatusState, fromAuth.getShowSplashScreen);
