<app-attention-modal [type]="confirmType" [title]="title" [messageTemplate]="message" [buttonsTemplate]="buttons"></app-attention-modal>

<ng-template #message
  ><ng-container *ngIf="customText">{{ customText }}</ng-container
  ><ng-container *ngIf="!customText">{{ actionName | titlecase }} {{ typeText }}<br />“{{ displayName }}”</ng-container>
</ng-template>

<ng-template #buttons>
  @if (showCancelButton) {
    <button rktButton btnType="tertiary" title="Cancel" (click)="activeModal.close()">Cancel</button>
  }
  <button rktButton [class.error-btn]="confirmType === 'error'" title="Confirm" (click)="activeModal.close(true)">
    {{ confirmBtnText || 'Confirm' }}
  </button>
</ng-template>
