export const constants = {
  KOR_TRANSACTION_FILTER: 'rt_filter',
  DEFAULT_TOKEN_EXPIRE_IN_SECONDS: 300,
  KOR_TRANSACTION_BATCH_FILTER: 'rt_batch_filter',
  KOR_TRANSACTION_BATCH_ACTIVITY_FILTER: 'rt_batch_activity_filter',
  KOR_ACTIVITY_FILTER: 'rt_activity_filter',
  KOR_FINANCIAL_ACCOUNT_LIST_FILTER: 'rt_financial_account_list_filter',
  KOR_CUSTOMER_INDIVIDUAL_FILTER: 'rt_customer_individual_filter',
  KOR_BUSINESS_ACCOUNT_FILTER: 'rt_business_account_filter',
  KOR_CUSTOMER_BUSINESS_FILTER: 'rt_customer_business_filter',
  KOR_EWA_CUSTOMER_FILTER: 'rt_ewa_customer_filter',
  LINX_V1_REQUESTS_FILTER: 'rt_linx_v1_request_filter',
  LINX_REQUESTS_FILTER: 'rt_linx_request_filter',
  LINX_BATCH_REQUESTS_FILTER: 'rt_linx_batch_request_filter',
  LINX_STP_REQUESTS_FILTER: 'rt_linx_stp_request_filter',
  KOR_IDV_FILTER: 'rt_kor_idv_filter',
  KOR_WATCHLIST_EVENTS_FILTER: 'rt_kor_watchlist_events_filter',
  KOR_ADA_REGISTRATION_FILTER: 'rt_kor_ada_registration_filter',
  DEFAULT_TOKEN_EXPIRE_IN: 10,
  ENCRYPTION_KEY: 'Zr4u7x!A%D*G-KaNdRgUkXp2s5v8y/B?',
  SENTRY_DSN: 'https://c59b1c2d7ddd42189df288d209a9840d@o923818.ingest.sentry.io/5934234',
  TABLE_ROWS: 25,
  FINANCIAL_ACCOUNT_ROWS: 10,
  HOME_ROUTE: '/app/dashboard',
  AUTH_ROUTE: '/',
  ERROR_NOT_FOUND: '404',
  DEFAULT_PAGE_SIZE: 7,
  UUID_NAMESPACE: 'd2dbfd89-d1b5-4882-9ac0-565b70b995bf',
};

export const READ_ONLY_ROLES = ['View Only'];
