import { AsyncPipe, NgIf, NgTemplateOutlet } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RktButtonDirective } from '@rocketfinancialcorp/rocket-ui/button';
import { RktIconComponent } from '@rocketfinancialcorp/rocket-ui/icon';
import { ModalService } from '@rocketfinancialcorp/rocket-ui/modal';
import { ShortIdPipe } from '@rocketfinancialcorp/rocket-ui/table';

import { BeneficiaryEditFormComponent } from '@shared/components';
import { Beneficiary, TemplateContext } from '@shared/models';
import { NotificationService } from '@shared/services';
import { toTitleCase } from '@shared/utils';

import { TemplateContextDirective } from '../../../directives/template-context.directive';
import { AccessControlPipe } from '../../../pipes/access-control.pipe';
import { DateFormatPipe } from '../../../pipes/date-format.pipe';
import { PhonePipe } from '../../../pipes/phone.pipe';
import { TeamMemberNamePipe } from '../../../pipes/team-member-name.pipe';
import { AddressViewComponent } from '../../addresses/address-view/address-view.component';
import { DetailsAccountVerificationComponent } from '../../details-account-verification/details-account-verification.component';
import { DetailsItemComponent } from '../../details-item/details-item.component';
import { TextWithPlaceholderComponent } from '../../text-with-placeholder/text-with-placeholder.component';

@Component({
  selector: 'app-beneficiary-details',
  templateUrl: 'beneficiary-details.component.html',
  styleUrls: ['beneficiary-details.component.scss'],
  standalone: true,
  imports: [
    RktButtonDirective,
    NgIf,
    NgTemplateOutlet,
    TemplateContextDirective,
    DetailsAccountVerificationComponent,
    TextWithPlaceholderComponent,
    DetailsItemComponent,
    AddressViewComponent,
    AsyncPipe,
    PhonePipe,
    DateFormatPipe,
    TeamMemberNamePipe,
    AccessControlPipe,
    RktIconComponent,
    ShortIdPipe,
  ],
})
export class BeneficiaryDetailsComponent {
  @Input() beneficiary!: Beneficiary | null;

  @Input() beneficiaryType!: 'customer' | 'business';

  @Output() beneficiaryUpdateDone = new EventEmitter();

  pageClassPrefix = 'beneficiary-details-';

  beneficiaryDetailsContext?: TemplateContext<Beneficiary>;

  get beneficiaryGender() {
    return this.beneficiary?.sex === 'NONBINARY' ? 'Non-Binary' : toTitleCase(this.beneficiary?.sex);
  }

  get addresses() {
    if (!this.beneficiary) return [];
    return this.beneficiary.addresses;
  }

  constructor(
    private modalService: ModalService,
    private notificationService: NotificationService,
  ) {}

  onEditBeneficiaryBtnClick(beneficiary: Beneficiary): void {
    const addBeneficiaryModalRef = this.modalService.open(BeneficiaryEditFormComponent, {
      className: 'entity-form-modal',
    });

    addBeneficiaryModalRef.componentInstance.modalInitData({
      actionType: 'edit',
      beneficiaryType: this.beneficiaryType,
      customerId: beneficiary?.parentId,
      beneficiaryData: beneficiary,
    });

    addBeneficiaryModalRef.result.then(
      (result) => {
        if (result) {
          this.notificationService.displaySuccess('Beneficial Owner updated successfully.', 'Success');
          this.beneficiaryUpdateDone.emit();
        }
      },
      () => false,
    );
  }
}
