import { TitleCasePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';
import { RktIconComponent } from '@rocketfinancialcorp/rocket-ui/icon';

import { LinxEnhanceAccountDetails } from '@shared/models';

@Component({
  selector: 'app-enhance-profile-preview',
  standalone: true,
  imports: [RktIconComponent, TitleCasePipe],
  templateUrl: './enhance-profile-preview.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnhanceProfilePreviewComponent {
  enhanceAccountDetails = input<LinxEnhanceAccountDetails>();

  enhanceProfileDisplayData = input<string[]>([]);

  isEditDisabled = input<boolean>(false);

  expandRow = output();
}
