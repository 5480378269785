<h1 class="title">Verification Method</h1>

<button class="close-button" (click)="activeModal.close()"><rkt-icon name="times"></rkt-icon></button>

<div class="account-selection-text">Please choose a method to request for bank account verification</div>

<div class="details-page-edit-form">
  <form [formGroup]="verificationForm">
    <rkt-form [form]="verificationForm" [fields]="formFields" [model]="formModel"></rkt-form>
  </form>

  <div *ngIf="submitError" class="details-page-edit-form-error">{{ submitError }}</div>

  <div class="buttons">
    <button rktButton type="button" btnType="tertiary" (click)="activeModal.close()">Cancel</button>
    <button rktButton type="submit" (click)="onSubmit()" title="Save" [disabled]="!verificationForm.valid">Verify</button>
  </div>
</div>
