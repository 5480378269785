import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { RequestPageParams } from '@shared/models';
import { ReportService } from '@shared/services';
import { fromAuth } from '@shared/store';

import { ReportsActions } from './reports.actions';

@Injectable()
export class ReportEffects {
  constructor(
    private actions$: Actions,
    private reportService: ReportService,
    private store: Store,
  ) {}

  getRecipients$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ReportsActions.loadReports),
      concatLatestFrom(() => this.store.select(fromAuth.selectBusinessAccountId)),
      mergeMap(([payload, activeBusinessAccountId]: [payload: { params: RequestPageParams }, activeBusinessAccountId: string | null]) => {
        return this.reportService.getReports(activeBusinessAccountId!, payload.params).pipe(
          map((response) => ReportsActions.loadReportsSuccess({ reports: response.items, totalElements: response.totalElements })),
          catchError((error) => {
            return of(ReportsActions.loadReportsError({ error }));
          }),
        );
      }),
    );
  });
}
