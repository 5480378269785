import { AsyncPipe, DecimalPipe, NgIf, TitleCasePipe } from '@angular/common';
import { Component, Input, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { NgxMaskPipe } from 'ngx-mask';
import { RktIconComponent } from '@rocketfinancialcorp/rocket-ui/icon';
import { ModalService } from '@rocketfinancialcorp/rocket-ui/modal';
import { SplitPipe } from '@rocketfinancialcorp/rocket-ui/table';

import { FinancialAccountSelectionModalComponent } from '@shared/components';
import { FinancialAccountSelectionListItem } from '@shared/models';
import { TransactionFormAction, transactionFormFeature } from '@shared/store';

import { FocusRemoveDirective } from '../../../directives/focus-remove.directive';
import { CurrencySignPipe } from '../../../pipes/currency.pipe';
import { NotAvailablePipe } from '../../../pipes/not-available.pipe';

@Component({
  selector: 'app-create-transaction-financial-account',
  templateUrl: './create-transaction-financial-account.component.html',
  standalone: true,
  imports: [
    NgIf,
    FocusRemoveDirective,
    AsyncPipe,
    DecimalPipe,
    TitleCasePipe,
    NotAvailablePipe,
    CurrencySignPipe,
    RktIconComponent,
    NgxMaskPipe,
    SplitPipe,
  ],
})
export class CreateTransactionFinancialAccountComponent {
  @Input() type: 'from' | 'to' = 'from';

  private readonly modalService = inject(ModalService);

  private readonly store = inject(Store);

  formAction$ = this.store.select(transactionFormFeature.selectFormAction);

  fromAccount$ = this.store.select(transactionFormFeature.selectFromAccount);

  toAccount$ = this.store.select(transactionFormFeature.selectToAccount);

  isDebitLegsDisabled$ = this.store.select(transactionFormFeature.selectIsDebitLegsDisabled);

  onSelectFinancialAccountClick(params?: {
    selectedFinancialAccount?: FinancialAccountSelectionListItem;
    formAction?: TransactionFormAction;
    fromAccount?: FinancialAccountSelectionListItem;
  }) {
    const financialAccountSelectionModalRef = this.modalService.open(FinancialAccountSelectionModalComponent, {
      className: 'create-transaction-financial-account-modal',
    });

    financialAccountSelectionModalRef.componentInstance.modalInitData({
      type: this.type,
      selectedFinancialAccount: params?.selectedFinancialAccount,
      fromAccount: params?.fromAccount,
      isEditMLT: params?.formAction === 'EDIT_MLT',
      isEditSLT: params?.formAction === 'EDIT_SLT',
      isEditScheduledSLT: params?.formAction === 'EDIT_SCHEDULED_SLT',
    });
  }
}
