<div class="amount-section-title">Amount<span class="asterisk">*</span></div>

<div class="move-amount-section">
  <div class="rkt-form">
    <div class="rkt-form-control">
      <div class="rkt-form-field">
        <div class="rkt-form-input-field">
          <input
            type="text"
            class="rkt-form-input"
            [class.is-invalid]="moveAmountError || isMaximumAmount || isMinimumAmount"
            [formControl]="moveAmount"
            id="moveAmount"
            [prefix]="(amountCurrency | currencySign) ?? ''"
            mask="separator.2"
            maxlength="20"
            thousandSeparator=","
            [placeholder]="isMoveFieldsDisabled ? '' : '$ 0.00'"
            (ngModelChange)="onChange($event)"
            (blur)="onBlur()"
            [appFieldDisable]="isMoveFieldsDisabled"
          />
        </div>
      </div>

      <div *ngIf="isMaximumAmount" class="rkt-form-field-invalid-feedback">
        Amount must be less or equal to
        {{ (maxAmount | number: '1.2-2') || '' | mask: 'separator' : { thousandSeparator: ',' } | prefix: '$' }}
      </div>

      <div *ngIf="isMinimumAmount" class="rkt-form-field-invalid-feedback">
        Amount must be greater or equal to
        {{ (solutionMinLimit | number: '1.2-2') || '' | mask: 'separator' : { thousandSeparator: ',' } | prefix: '$' }}
      </div>
    </div>
  </div>
</div>
