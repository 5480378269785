import { NgFor, NgIf } from '@angular/common';
import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { uniqueId } from 'lodash-es';
import { RktButtonDirective } from '@rocketfinancialcorp/rocket-ui/button';
import { RktIconComponent } from '@rocketfinancialcorp/rocket-ui/icon';

import { FilterField, FilterOption, FilterValue } from '@shared/models';

@Component({
  selector: 'app-filter-checklist',
  templateUrl: './filter-checklist.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [RktButtonDirective, NgIf, NgFor, RktIconComponent],
})
export class FilterChecklistComponent implements OnInit, AfterViewInit, AfterViewChecked {
  @HostBinding('class.rkt-filter-item-container') commonClass = true;

  @Input() options: FilterOption[] = [];

  @Input() props?: FilterField['props'];

  @Input() filterName?: string;

  @Input() selectedValue?: FilterValue;

  @Input() defaultValue?: string;

  @Input() isCollapsable = true;

  @Output() filterToggled = new EventEmitter<void>();

  @Output() changed = new EventEmitter<string>();

  id = uniqueId();

  checkedValues = new Set<string>();

  isCollapsed = true;

  constructor(private ref: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this.isCollapsed = this.isCollapsable && (!this.selectedValue || this.selectedValue === this.defaultValue);
  }

  ngAfterViewChecked(): void {
    this.ref.detectChanges();
  }
  ngOnInit() {
    this.checkedValues.clear();

    this.checkedValues = new Set(this.selectedValue ? `${this.selectedValue}`?.split(',') : []);

    if (this.defaultValue === 'all' && (!this.selectedValue || this.selectedValue === 'all') && this.options) {
      this.options.map((option) => this.checkedValues.add(option.value!));
    }

    if (this.areAllOptionsChecked()) {
      this.checkedValues.add('all');
    }
  }

  isChecked(option: FilterOption) {
    if (!option?.value) {
      return;
    }
    return this.checkedValues.has(option.value);
  }

  onCheckboxChange(value: string, event: Event) {
    const checked = (event.target as HTMLInputElement).checked;
    if (value === 'all') {
      if (checked) {
        this.options.forEach((option) => {
          if (!option.disabled) {
            this.checkedValues.add(option.value!);
          }
        });
      } else {
        this.options.forEach((option) => {
          this.checkedValues.delete(option.value!);
        });
      }
    } else {
      if (checked) {
        this.checkedValues.add(value);
        if (this.areAllOptionsChecked()) {
          this.checkedValues.add('all');
        } else {
          this.checkedValues.delete('all');
        }
      } else {
        this.checkedValues.delete(value);
        this.checkedValues.delete('all');
      }
    }

    if (this.areAllOptionsUnchecked()) {
      setTimeout(() => {
        this.options.map((option) => this.checkedValues.add(option.value!));
        this.emitCheckedValues();
      }, 0);
    } else {
      this.emitCheckedValues();
    }
  }

  emitCheckedValues() {
    if (this.areAllOptionsChecked() && this.defaultValue === 'all') {
      this.changed.emit(undefined);
      return;
    }

    this.changed.emit(
      Array.from(this.checkedValues)
        .filter((value) => value !== 'all')
        .join(','),
    );
  }

  isDefaultValuesSelected() {
    if (this.defaultValue === 'all') {
      return this.isAllSelected();
    } else if (this.defaultValue) {
      const defaultOptions = this.defaultValue.split(',');
      return defaultOptions.every((option) => this.checkedValues.has(option)) && defaultOptions.length === this.checkedValues.size;
    } else {
      return true;
    }
  }

  reset() {
    this.checkedValues.clear();

    if (this.defaultValue === 'all') {
      this.options.map((option) => this.checkedValues.add(option.value!));
    } else if (this.defaultValue) {
      this.defaultValue.split(',').map((option) => this.checkedValues.add(option));
    }

    setTimeout(() => {
      this.emitCheckedValues();
    }, 0);
  }

  isAllSelected(): boolean {
    return this.options
      .filter((option) => option.value !== 'all' && !option.disabled)
      .every((option) => this.checkedValues.has(option.value!));
  }

  areAllOptionsChecked(): boolean {
    return this.options
      .filter((option) => option.value !== 'all' && !option.disabled)
      .every((option) => this.checkedValues.has(option.value!));
  }

  areAllOptionsUnchecked(): boolean {
    return this.options
      .filter((option) => option.value !== 'all' && !option.disabled)
      .every((option) => !this.checkedValues.has(option.value!));
  }

  toggleFilter() {
    if (!this.isCollapsable) {
      return;
    }
    this.isCollapsed = !this.isCollapsed;
    this.filterToggled.emit();
  }
}
