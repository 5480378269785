import { Pipe, PipeTransform } from '@angular/core';
import { format, parseISO } from 'date-fns';
import { isString } from 'lodash-es';

type DateFnsInputDate = string | number | Date | null | undefined;

const isValidDate = (date: DateFnsInputDate): date is Date => !(date === undefined || date === null || !date);

@Pipe({
  name: 'dateFormat',
  standalone: true,
})
export class DateFormatPipe implements PipeTransform {
  transform(date: DateFnsInputDate, dateFormat = 'MM/dd/yy HH:mm', inputDateFormat?: 'ISO' | 'string'): string {
    if (date && isValidDate(new Date(date))) {
      const dateTime = isString(date) && !date.includes('T') ? `${date}T00:00` : date;
      const dateObj = inputDateFormat === 'ISO' && isString(date) ? parseISO(date) : new Date(dateTime);
      return format(dateObj, dateFormat);
    }
    return '';
  }
}
