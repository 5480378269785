import { createAction, props } from '@ngrx/store';

import { ErrorMessage, RecipientList, RequestPageParams } from '@shared/models';

export namespace RecipientActions {
  export const loadRecipients = createAction('[Recipients API] Load Recipients', props<{ params: RequestPageParams }>());

  export const loadRecipientsSuccess = createAction(
    '[Recipients API] Load Recipients Success',
    props<{ recipients: RecipientList['items']; totalElements: number }>(),
  );

  export const loadRecipientsError = createAction('[Recipients API] Load Recipients Error', props<{ error: ErrorMessage }>());
}
