import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { compact } from 'lodash-es';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';

import { CardAccountService, ReportService } from '@shared/services';
import {
  AuditActions,
  CardAccountActions,
  CardProductDetailsActions,
  CardProductsActions,
  MccActions,
  MessagesActions,
  fromAuth,
  selectRouteParams,
  selectUrl,
} from '@shared/store';
import { ErrorUtils } from '@shared/utils';

@Injectable()
export class CardAccountEffects {
  constructor(
    private actions$: Actions,
    private reportService: ReportService,
    private cardAccountService: CardAccountService,
    private router: Router,
    private store: Store,
  ) {}

  getCardAccountById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CardAccountActions.loadCardAccountDetails),
      concatLatestFrom(() => [this.store.select(selectRouteParams), this.store.select(fromAuth.selectBusinessAccountId)]),
      mergeMap(([, routeParams, activeBusinessAccountId]) => {
        return this.cardAccountService
          .getCardAccountDetails({ businessAccountId: activeBusinessAccountId, cardAccountId: routeParams.cardId })
          .pipe(
            map((response) => CardAccountActions.loadCardAccountDetailsSuccess({ financialAccount: response })),
            catchError((error) => {
              return of(CardAccountActions.loadCardAccountDetailsError({ error }));
            }),
          );
      }),
    );
  });

  getCardAccountCreatedUpdatedBy$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CardAccountActions.loadCardAccountDetailsSuccess),
      switchMap(({ financialAccount }) => {
        return of(AuditActions.loadTeamMembers({ ids: compact([financialAccount.createdBy, financialAccount.updatedBy]) }));
      }),
    );
  });

  loadCardAccountDetailsError$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CardAccountActions.loadCardAccountDetailsError),
      tap({
        next: (loadFinancialAccountError) => {
          ErrorUtils.catchError('cardAccountService.getCardAccountDetails error', loadFinancialAccountError.error);
        },
      }),
      switchMap(() => of(MessagesActions.displayError({ message: 'Unable to fetch Card Account Details.' }))),
    );
  });

  loadCardAccountDetailsErrorRedirect$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(CardAccountActions.loadCardAccountDetailsError),
        concatLatestFrom(() => this.store.select(selectUrl)),
        tap({
          next: ([, currentUrl]) => {
            const prevUrl = currentUrl.split('/').slice(0, -2).join('/');
            this.router.navigateByUrl(prevUrl);
          },
        }),
      );
    },
    { dispatch: false },
  );

  loadRestrictions$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CardAccountActions.loadRestrictions),
      concatLatestFrom(() => this.store.select(selectRouteParams)),
      mergeMap(([, routeParams]) => {
        return this.cardAccountService.getCardRestrictions({ financialAccountId: routeParams.cardId }).pipe(
          map((response) => CardAccountActions.loadRestrictionsSuccess({ restrictionList: response })),
          catchError((error) => {
            return of(CardAccountActions.loadRestrictionsError({ error }));
          }),
        );
      }),
    );
  });

  loadRestrictionsError$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CardAccountActions.loadRestrictionsError),
      tap({
        next: (loadRestrictionsError) => {
          ErrorUtils.catchError('cardAccountService.getRestrictions error', loadRestrictionsError.error);
        },
      }),
      switchMap(() => of(MessagesActions.displayError({ message: 'Unable to fetch Restrictions.', title: 'Error' }))),
    );
  });

  loadRestrictionDetails$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CardAccountActions.loadRestrictionDetails),
      concatLatestFrom(() => this.store.select(selectRouteParams)),
      mergeMap(([, routeParams]) => {
        return this.cardAccountService
          .getCardRestrictionById({ financialAccountId: routeParams.cardId, restrictionId: routeParams.id })
          .pipe(
            map((response) => CardAccountActions.loadRestrictionDetailsSuccess({ restrictionDetails: response })),
            catchError((error) => {
              return of(CardAccountActions.loadRestrictionDetailsError({ error }));
            }),
          );
      }),
    );
  });

  loadRestrictionDetailsError$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CardAccountActions.loadRestrictionDetailsError),
      tap({
        next: (loadRestrictionDetailsError) => {
          ErrorUtils.catchError('cardAccountService.getCardRestrictionById error', loadRestrictionDetailsError.error);
        },
      }),
      switchMap(() => of(MessagesActions.displayError({ message: 'Unable to fetch Restriction Details.', title: 'Error' }))),
    );
  });

  loadAlerts$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CardAccountActions.loadAlerts),
      concatLatestFrom(() => this.store.select(selectRouteParams)),
      mergeMap(([, routeParams]) => {
        return this.cardAccountService.getCardAlerts({ financialAccountId: routeParams.cardId }).pipe(
          map((response) => CardAccountActions.loadAlertsSuccess({ alertList: response })),
          catchError((error) => {
            return of(CardAccountActions.loadAlertsError({ error }));
          }),
        );
      }),
    );
  });

  loadAlertsError$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CardAccountActions.loadAlertsError),
      tap({
        next: (loadAlertsError) => {
          ErrorUtils.catchError('cardAccountService.getCardAlerts error', loadAlertsError.error);
        },
      }),
      switchMap(() => of(MessagesActions.displayError({ message: 'Unable to fetch Alerts.', title: 'Error' }))),
    );
  });

  loadAlertDetails$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CardAccountActions.loadAlertDetails),
      concatLatestFrom(() => this.store.select(selectRouteParams)),
      mergeMap(([, routeParams]) => {
        return this.cardAccountService.getCardAlertById({ financialAccountId: routeParams.cardId, alertId: routeParams.id }).pipe(
          map((response) => CardAccountActions.loadAlertDetailsSuccess({ alertDetails: response })),
          catchError((error) => {
            return of(CardAccountActions.loadAlertDetailsError({ error }));
          }),
        );
      }),
    );
  });

  loadAlertDetailsError$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CardAccountActions.loadAlertDetailsError),
      tap({
        next: (loadAlertDetailsError) => {
          ErrorUtils.catchError('cardAccountService.getCardAlertById error', loadAlertDetailsError.error);
        },
      }),
      switchMap(() => of(MessagesActions.displayError({ message: 'Unable to fetch Alert Details.', title: 'Error' }))),
    );
  });

  loadDisputes$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CardAccountActions.loadDisputes),
      concatLatestFrom(() => this.store.select(selectRouteParams)),
      mergeMap(([, routeParams]) => {
        return this.cardAccountService.getCardDisputes({ financialAccountId: routeParams.cardId }).pipe(
          map((response) => CardAccountActions.loadDisputesSuccess({ disputeList: response })),
          catchError((error) => {
            return of(CardAccountActions.loadDisputesError({ error }));
          }),
        );
      }),
    );
  });

  loadDisputesError$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CardAccountActions.loadDisputesError),
      tap({
        next: (loadDisputesError) => {
          ErrorUtils.catchError('cardAccountService.getCardDisputes error', loadDisputesError.error);
        },
      }),
      switchMap(() => of(MessagesActions.displayError({ message: 'Unable to fetch Disputes.', title: 'Error' }))),
    );
  });

  loadDisputeDetails$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CardAccountActions.loadDisputeDetails),
      concatLatestFrom(() => this.store.select(selectRouteParams)),
      mergeMap(([, routeParams]) => {
        return this.cardAccountService.getCardDisputeById({ financialAccountId: routeParams.cardId, disputeId: routeParams.id }).pipe(
          map((response) => CardAccountActions.loadDisputeDetailsSuccess({ disputeDetails: response })),
          catchError((error) => {
            return of(CardAccountActions.loadDisputeDetailsError({ error }));
          }),
        );
      }),
    );
  });

  loadDisputeDetailsError$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CardAccountActions.loadDisputeDetailsError),
      tap({
        next: (loadDisputeDetailsError) => {
          ErrorUtils.catchError('cardAccountService.getCardDisputeById error', loadDisputeDetailsError.error);
        },
      }),
      switchMap(() => of(MessagesActions.displayError({ message: 'Unable to fetch Dispute Details.', title: 'Error' }))),
    );
  });

  loadStatements$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CardAccountActions.loadStatements),
      concatLatestFrom(() => this.store.select(selectRouteParams)),
      mergeMap(([{ requestParams }, routeParams]) => {
        return this.reportService
          .getInsightStatements({
            ...requestParams,
            financialAccountId: routeParams.cardId,
          })
          .pipe(
            map((response) =>
              CardAccountActions.loadStatementsSuccess({
                statementList: response?.items ?? [],
                totalElements: response?.totalElements ?? 0,
              }),
            ),
            catchError((error) => {
              return of(CardAccountActions.loadStatementsError({ error }));
            }),
          );
      }),
    );
  });

  loadStatementsError$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CardAccountActions.loadStatementsError),
      tap({
        next: (loadStatementsError) => {
          ErrorUtils.catchError('reportService.getInsightStatements error', loadStatementsError.error);
        },
      }),
      switchMap(() => of(MessagesActions.displayError({ message: 'Unable to fetch Statements.', title: 'Error' }))),
    );
  });

  loadCardAccountDetailsPageErrorRedirect$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          CardAccountActions.loadRestrictionDetailsError,
          CardAccountActions.loadDisputeDetailsError,
          CardAccountActions.loadAlertDetailsError,
          CardAccountActions.loadCardProgramsError,
        ),
        concatLatestFrom(() => this.store.select(selectUrl)),
        tap({
          next: ([, currentUrl]) => {
            const prevUrl = currentUrl.split('/').slice(0, -1).join('/');
            this.router.navigateByUrl(prevUrl);
          },
        }),
      );
    },
    { dispatch: false },
  );

  loadCardProgramsError$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CardAccountActions.loadCardProgramsError),
      tap({
        next: (loadCardProgramsError) => {
          ErrorUtils.catchError('cardAccountService.getCardPrograms error', loadCardProgramsError.error);
        },
      }),
      switchMap(() => of(MessagesActions.displayError({ message: 'Unable to fetch Card Programs.' }))),
    );
  });

  mccItemsLoad$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MccActions.load),
      switchMap(() => {
        return this.cardAccountService.getMccItems().pipe(
          map((mccItems) => {
            return MccActions.loadSuccess({ mccItems });
          }),
          catchError((error) => {
            return of(MccActions.loadFailure({ error }));
          }),
        );
      }),
    );
  });

  mccItemsLoadFailure$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MccActions.loadFailure),
      tap({
        next: (errorResponse) => {
          ErrorUtils.catchError('cardAccountService.getMccItems error', errorResponse.error);
        },
      }),
      switchMap(() => of(MessagesActions.displayError({ message: 'Unable to fetch Merchant Category Codes.' }))),
    );
  });

  loadCardProducts$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CardProductsActions.load, CardAccountActions.cardIssueFormInit, CardAccountActions.loadCardAccountDetails),
      switchMap(({ requestParams }) =>
        this.cardAccountService.getCardProducts({ requestParams }).pipe(
          map((response) =>
            CardProductsActions.loadSuccess({
              cardProducts: response?.content ?? [],
              cardProductsTotalElements: response?.totalElements ?? 0,
            }),
          ),
          catchError((error) => {
            return of(CardProductsActions.loadFailure({ error }));
          }),
        ),
      ),
    );
  });

  loadCardProductsError$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CardProductsActions.loadFailure),
      tap({
        next: (loadCardProductsError) => {
          ErrorUtils.catchError('cardAccountService.getCardProducts error', loadCardProductsError.error);
        },
      }),
      switchMap(() => of(MessagesActions.displayError({ message: 'Unable to fetch Card Products.' }))),
    );
  });

  loadCardProductDetails$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CardProductDetailsActions.load),
      concatLatestFrom(() => [this.store.select(selectRouteParams)]),
      mergeMap(([, routeParams]) => {
        return this.cardAccountService.getCardProductDetails({ productId: routeParams.productId }).pipe(
          map((response) => CardProductDetailsActions.loadSuccess({ cardProduct: response })),
          catchError((error) => {
            return of(CardProductDetailsActions.loadFailure({ error }));
          }),
        );
      }),
    );
  });

  loadCardProductDetailsError$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CardProductDetailsActions.loadFailure),
      tap({
        next: (loadCardProductDetailsError) => {
          ErrorUtils.catchError('cardAccountService.getCardProductDetails error', loadCardProductDetailsError.error);
        },
      }),
      switchMap(() => of(MessagesActions.displayError({ message: 'Unable to fetch Card Product Details.' }))),
    );
  });

  loadCardProductDetailsErrorRedirect$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(CardProductDetailsActions.loadFailure),
        tap({
          next: () => {
            this.router.navigateByUrl('/app/cards/card-products');
          },
        }),
      );
    },
    { dispatch: false },
  );

  getCardProductRestrictions$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CardProductDetailsActions.getRestrictions),
      concatLatestFrom(() => [this.store.select(selectRouteParams)]),
      mergeMap(([, routeParams]) => {
        return this.cardAccountService.getCardProductRestrictions({ productId: routeParams.productId }).pipe(
          map((response) => CardProductDetailsActions.getRestrictionsSuccess({ productRestrictions: response })),
          catchError((error) => {
            return of(CardProductDetailsActions.getRestrictionsFailure({ error }));
          }),
        );
      }),
    );
  });

  loadMerchants$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CardAccountActions.loadMerchants),
      concatLatestFrom(() => this.store.select(selectRouteParams)),
      mergeMap(([, routeParams]) => {
        return this.cardAccountService.getMerchants({ productId: routeParams.productId }).pipe(
          map((response) => CardAccountActions.loadMerchantsSuccess({ merchantList: response })),
          catchError((error) => {
            return of(CardAccountActions.loadMerchantsError({ error }));
          }),
        );
      }),
    );
  });

  loadMerchantsError$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CardAccountActions.loadMerchantsError),
      tap({
        next: (loadMerchantsError) => {
          ErrorUtils.catchError('cardAccountService.getMerchants error', loadMerchantsError.error);
        },
      }),
      switchMap(() => of(MessagesActions.displayError({ message: 'Unable to fetch Merchants.', title: 'Error' }))),
    );
  });

  loadMerchantDetails$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CardAccountActions.loadMerchantDetails),
      concatLatestFrom(() => this.store.select(selectRouteParams)),
      mergeMap(([, routeParams]) => {
        return this.cardAccountService.getMerchantById({ productId: routeParams.productId, merchantId: routeParams.merchantId }).pipe(
          map((response) => CardAccountActions.loadMerchantDetailsSuccess({ merchantDetails: response })),
          catchError((error) => {
            return of(CardAccountActions.loadMerchantDetailsError({ error }));
          }),
        );
      }),
    );
  });

  loadMerchantDetailsError$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CardAccountActions.loadMerchantDetailsError),
      tap({
        next: (loadMerchantDetailsError) => {
          ErrorUtils.catchError('cardAccountService.getCardDisputeById error', loadMerchantDetailsError.error);
        },
      }),
      switchMap(() => of(MessagesActions.displayError({ message: 'Unable to fetch Merchant Details.', title: 'Error' }))),
    );
  });
}
