import { AfterViewChecked, ChangeDetectorRef, Component } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RktButtonDirective } from '@rocketfinancialcorp/rocket-ui/button';
import { RktFormComponent, RktFormFieldConfig, formRow, formSelect } from '@rocketfinancialcorp/rocket-ui/form';
import { RktIconComponent } from '@rocketfinancialcorp/rocket-ui/icon';
import { ActiveModal } from '@rocketfinancialcorp/rocket-ui/modal';

import { FormModel } from '@shared/models';

export interface ReversalOption {
  label: string;
  value: string;
}

@Component({
  templateUrl: './transaction-reversal-reason-modal.component.html',
  standalone: true,
  imports: [RktButtonDirective, RktIconComponent, FormsModule, ReactiveFormsModule, RktFormComponent],
})
export class TransactionReversalReasonModalComponent implements AfterViewChecked {
  reversalReasons: Record<string, ReversalOption[]> = {
    ach: [
      { value: 'DUPLICATE_PAYMENT', label: 'Duplicate payment' },
      { value: 'INCORRECT_RECIPIENT', label: 'Incorrect Sender/Recipient' },
      { value: 'INCORRECT_AMOUNT', label: 'Incorrect Amount' },
      { value: 'EARLY_DEBIT', label: 'Early Debit' },
      { value: 'LATE_CREDIT', label: 'Late Credit' },
    ],

    'millikart-transaction': [
      { value: 'UNDUE_DISBURSEMENT', label: 'Undue Disbursement' },
      { value: 'UNDUE_RECOVERY', label: 'Undue Recovery' },
      { value: 'FEE_REVERSAL', label: 'Fee Reversal' },
      { value: 'INCORRECT_AMOUNT', label: 'Incorrect Amount' },
      { value: 'DUPLICATE_TRANSACTION', label: 'Duplicate Transaction' },
    ],
  };

  formFields!: RktFormFieldConfig[];

  formModel: FormModel<{ reason?: string }> = { reason: undefined };

  reasonForm = new FormGroup({});

  constructor(
    public ref: ChangeDetectorRef,
    public activeModal: ActiveModal,
  ) {}

  ngAfterViewChecked(): void {
    this.ref.detectChanges();
  }

  modalInitData(solutionType: string): void {
    this.formFields = [
      formRow([
        formSelect({
          key: 'reason',
          label: 'Reversal Reason',
          props: {
            options: this.reversalReasons[solutionType],
            required: true,
            placeholder: 'Select a reason',
          },
        }),
      ]),
    ];
  }
}
