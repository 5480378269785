import { NgFor, NgIf, NgTemplateOutlet } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { RktIconComponent } from '@rocketfinancialcorp/rocket-ui/icon';

import { Breadcrumb } from '@shared/models';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: 'breadcrumbs.component.html',
  styleUrls: ['breadcrumbs.component.scss'],
  standalone: true,
  imports: [NgFor, NgIf, NgTemplateOutlet, RouterLink, RktIconComponent],
})
export class BreadcrumbsComponent {
  @Input() itemList: Breadcrumb[] = [];
}
