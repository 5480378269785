import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { RktButtonDirective } from '@rocketfinancialcorp/rocket-ui/button';
import { RktFormComponent, formRadioGroup } from '@rocketfinancialcorp/rocket-ui/form';

import { LinxProfileType } from '@shared/constants';
import { FormModel, LinxRequestFinancialAccount } from '@shared/models';

@Component({
  selector: 'app-linx-template-financial-account',
  templateUrl: './linx-template-financial-account.component.html',
  standalone: true,
  imports: [RktButtonDirective, FormsModule, ReactiveFormsModule, RktFormComponent],
})
export class LinxTemplateFinancialAccountComponent implements OnInit {
  @Input() selectedFinancialAccountDetails?: LinxRequestFinancialAccount;

  @Input() customerProfileType?: LinxProfileType;

  @Output() continue = new EventEmitter<LinxRequestFinancialAccount>();

  financialAccountForm = new UntypedFormGroup({});

  formModel: FormModel<LinxRequestFinancialAccount> = {};

  financialAccountFields: FormlyFieldConfig[] = [
    formRadioGroup({
      key: 'financialAccountType',
      label: '',
      props: {
        options: [
          { label: 'Use Existing Financial Account', value: 'EXISTING' },
          { label: 'Create New Financial Account', value: 'NEW' },
        ],
        required: true,
      },
      expressions: {
        hide: () => this.customerProfileType === 'NEW',
      },
    }),
    formRadioGroup({
      key: 'financialAccountSubType',
      label: 'Account Type',
      props: {
        options: [
          { label: 'Checking Account', value: 'CHECKING' },
          { label: 'Saving Account', value: 'SAVING' },
          { label: 'Card Account', value: 'CARD' },
        ],
        required: true,
      },
      expressions: {
        hide: (field: FormlyFieldConfig) => this.customerProfileType !== LinxProfileType.New && !field.model.financialAccountType,
      },
    }),
  ];

  ngOnInit(): void {
    if (this.selectedFinancialAccountDetails) {
      this.formModel = {
        financialAccountType: this.selectedFinancialAccountDetails.financialAccountType,
        financialAccountSubType: this.selectedFinancialAccountDetails.financialAccountSubType,
      };
    }
  }

  onClickContinue(): void {
    this.continue.emit(this.formModel);
  }

  onClearBtnClick(): void {
    this.financialAccountForm.reset();
  }
}
