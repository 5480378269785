import { NgFor, NgIf } from '@angular/common';
import { AfterViewChecked, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { RktButtonDirective } from '@rocketfinancialcorp/rocket-ui/button';
import { RktFormComponent, formRow, formTextarea } from '@rocketfinancialcorp/rocket-ui/form';
import { RktIconComponent } from '@rocketfinancialcorp/rocket-ui/icon';
import { ModalService } from '@rocketfinancialcorp/rocket-ui/modal';

import { AttachmentCreateFormComponent } from '@shared/components/attachments';
import { AddNoteParams, AttachmentCreateModel, FormModel } from '@shared/models';
import { emptySpacesValidator } from '@shared/validators';

import { DragDropAttachmentDirective } from '../../../directives/drag-drop-attachment.directive';

@Component({
  selector: 'app-notes-feed-add-note',
  templateUrl: './notes-feed-add-note.component.html',
  standalone: true,
  imports: [RktButtonDirective, DragDropAttachmentDirective, RktFormComponent, NgIf, NgFor, RktIconComponent],
})
export class NotesFeedAddNoteComponent implements AfterViewChecked {
  @Input() loading!: boolean;

  @Input() error?: string;

  @Output() addBtnClick = new EventEmitter<Partial<AddNoteParams>>();

  @Output() discardClick = new EventEmitter<boolean>();

  noteField = new FormControl('', [emptySpacesValidator]);

  noteForm = new FormGroup({});

  noteFormFields = [
    formRow([
      formTextarea({
        key: 'note',
        label: 'Note',
        props: { maxLength: 1000, withCounter: true, blur: () => this.onNoteFieldBlur() },
        validators: { validation: [emptySpacesValidator] },
      }),
    ]),
  ];

  noteFormModel: FormModel<{ note: string }> = { note: '' };

  attachmentsData: Partial<AttachmentCreateModel>[] = [];

  constructor(
    public ref: ChangeDetectorRef,
    private modalService: ModalService,
  ) {}

  ngAfterViewChecked(): void {
    this.ref.detectChanges();
  }

  onNoteFieldBlur() {
    this.noteFormModel = { ...this.noteFormModel, note: this.noteFormModel.note?.trim() ?? '' };
  }

  onAddNoteCancel(): void {
    const isUnsavedData = this.attachmentsData.length || this.noteFormModel.note?.length;
    this.discardClick.emit(!!isUnsavedData);
  }

  onAddNoteSave(): void {
    this.addBtnClick.emit({
      contentText: this.noteFormModel.note,
      attachments: this.attachmentsData.length ? this.attachmentsData : undefined,
    });
  }

  onAddAttachments(): void {
    const attachmentCreateFormModalRef = this.modalService.open(AttachmentCreateFormComponent, {
      className: 'entity-form-modal',
    });

    attachmentCreateFormModalRef.result.then(
      (result) => {
        if (result) {
          this.attachmentsData.push(result);
        }
      },
      () => false,
    );
  }

  onDeleteFileBtnClick(i: number): void {
    this.attachmentsData.splice(i, 1);
  }

  onEditFileBtnClick(i: number): void {
    const attachmentEditFormModalRef = this.modalService.open(AttachmentCreateFormComponent, {
      className: 'entity-form-modal',
    });

    attachmentEditFormModalRef.componentInstance.isEditMode = true;
    attachmentEditFormModalRef.componentInstance.formModel = {
      name: this.attachmentsData[i].name ?? this.attachmentsData[i].file?.name,
      description: this.attachmentsData[i].description,
    };

    attachmentEditFormModalRef.result.then(
      (result) => {
        if (result) {
          this.attachmentsData[i].description = result.description;
          this.attachmentsData[i].name = result.name ? result.name : this.attachmentsData[i].file?.name;
        }
      },
      () => false,
    );
  }

  onAttachmentsDropped(attachments: Partial<AttachmentCreateModel>[]): void {
    this.attachmentsData.push(...attachments);
  }
}
