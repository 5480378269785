import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { RktButtonDirective } from '@rocketfinancialcorp/rocket-ui/button';
import { RktIconComponent } from '@rocketfinancialcorp/rocket-ui/icon';
import { ActiveModal } from '@rocketfinancialcorp/rocket-ui/modal';

import { LegalService } from '@shared/services';

@Component({
  selector: 'app-terms-conditions',
  templateUrl: './terms-conditions.component.html',
  styleUrls: ['./terms-conditions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [RktButtonDirective, RouterLink, RktIconComponent],
})
export class TermsConditionsComponent {
  constructor(
    public activeModal: ActiveModal,
    private legalService: LegalService,
  ) {}

  downloadTermsConditions() {
    this.legalService.downloadTermsConditions();
  }
}
