import { NgClass, TitleCasePipe } from '@angular/common';
import { Component, EventEmitter, Input, Output, ViewChild, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgLabelTemplateDirective, NgNotFoundTemplateDirective, NgSelectComponent } from '@ng-select/ng-select';
import { Store } from '@ngrx/store';
import { uniqueId } from 'lodash-es';

import { DropdownItem } from '@shared/models';

@Component({
  selector: 'app-status-select',
  templateUrl: 'status-select.component.html',
  standalone: true,
  imports: [NgSelectComponent, FormsModule, NgLabelTemplateDirective, NgClass, NgNotFoundTemplateDirective, TitleCasePipe],
})
export class StatusSelectComponent {
  @ViewChild('select') selectRef!: NgSelectComponent;

  store = inject(Store);

  @Input() label!: string;

  @Input() required = false;

  @Input() selectedItem?: string;

  @Input() items: DropdownItem[] = [];

  @Input() loading = false;

  @Input() disabled = false;

  @Input() placeholder = '';

  @Output() changed = new EventEmitter<DropdownItem | undefined>();

  @Output() filterOpened = new EventEmitter();

  @Output() closed = new EventEmitter();

  selectId = uniqueId();

  selectedItemOnOpen?: DropdownItem;

  onChange(item: DropdownItem): void {
    this.changed.emit(item);
  }

  onOpen() {
    this.filterOpened.emit();
  }

  onClose() {
    if (this.selectedItemOnOpen?.value !== this.selectedItem) {
      this.closed.emit();
    }

    this.selectRef?.blur();
  }
}
