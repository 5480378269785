import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { catchError, map, mergeMap, of, switchMap } from 'rxjs';

import { RecipientService } from '@shared/services';

import { RecipientDetailsActions } from './recipients.actions';
import { selectRouteParams } from '../core';
import { MessagesActions } from '../core/messages';

export const loadRecipientDetails$ = createEffect(
  (actions$ = inject(Actions), recipientService = inject(RecipientService), store = inject(Store)) => {
    return actions$.pipe(
      ofType(RecipientDetailsActions.loadRecipientDetails),
      concatLatestFrom(() => [store.select(selectRouteParams)]),
      mergeMap(([, routeParams]) => {
        return recipientService.getRecipientById(routeParams.id).pipe(
          map((recipientDetails) => {
            return RecipientDetailsActions.loadRecipientDetailsSuccess({ recipientDetails });
          }),
          catchError((error) => {
            return of(RecipientDetailsActions.loadRecipientDetailsFailure({ error }));
          }),
        );
      }),
    );
  },
  { functional: true },
);

export const loadRecipientDetailsFailure$ = createEffect(
  (actions$ = inject(Actions)) => {
    return actions$.pipe(
      ofType(RecipientDetailsActions.loadRecipientDetailsFailure),
      switchMap(() => of(MessagesActions.displayError({ message: 'Unable to fetch Recipient Details.' }))),
    );
  },
  { functional: true },
);
