<h1 class="title">Review</h1>

<button class="close-button" (click)="activeModal.close()"><rkt-icon name="times"></rkt-icon></button>

<div class="sub-title">Beneficial Owner <span class="accent">Information</span></div>

<div class="details-row">
  <app-details-item
    [class]="pageClassPrefix + 'first-name'"
    [showPlaceholder]="false"
    label="First Name"
    [value]="beneficiary?.firstName"
  ></app-details-item>

  <app-details-item
    [class]="pageClassPrefix + 'middle-name'"
    [showPlaceholder]="false"
    label="Middle Name"
    [value]="beneficiary?.middleName"
  ></app-details-item>

  <app-details-item
    [class]="pageClassPrefix + 'last-name'"
    [showPlaceholder]="false"
    label="Last Name"
    [value]="beneficiary?.lastName"
  ></app-details-item>

  <app-details-item
    [class]="pageClassPrefix + 'email'"
    [showPlaceholder]="false"
    label="Email Address"
    [noBreak]="true"
    [value]="beneficiary?.email"
  ></app-details-item>

  <app-details-item
    [class]="pageClassPrefix + 'phone-number'"
    [showPlaceholder]="false"
    label="Phone Number"
    [noBreak]="true"
    [value]="beneficiary?.phoneNumber | phone"
  ></app-details-item>

  <app-details-item [class]="pageClassPrefix + 'sex'" [showPlaceholder]="false" label="Sex" [value]="beneficiaryGender"></app-details-item>

  <app-details-item
    [class]="pageClassPrefix + 'date-of-birth'"
    [showPlaceholder]="false"
    label="Date of Birth"
    [value]="beneficiary?.dateOfBirth | dateFormat: 'MM/dd/yy' : 'ISO'"
  ></app-details-item>

  <app-details-item
    [class]="pageClassPrefix + 'place-of-birth'"
    [showPlaceholder]="false"
    label="Place of Birth"
    [value]="beneficiary?.placeOfBirthDisplayValue"
  ></app-details-item>

  <app-details-item
    [class]="pageClassPrefix + 'job-title'"
    [showPlaceholder]="false"
    label="Job Title"
    [value]="beneficiary?.title"
  ></app-details-item>

  <app-details-item [class]="pageClassPrefix + 'controller'" [showPlaceholder]="false" label="Controller" [contentValue]="true">
    <div class="controller-value">
      <rkt-icon [class.checked]="beneficiary?.controller" [name]="beneficiary?.controller ? 'check' : 'close'"></rkt-icon>
      {{ beneficiary?.controller ? 'Yes' : 'No' }}
    </div>
  </app-details-item>
</div>

<div class="details-separator"></div>

<div class="details-row">
  <app-details-item
    [class]="pageClassPrefix + 'social-id'"
    [showPlaceholder]="false"
    label="Social ID (SSN)"
    [value]="beneficiary?.socialId"
  ></app-details-item>

  <app-details-item
    [class]="pageClassPrefix + 'social-id-country'"
    [showPlaceholder]="false"
    label="Social ID Issuing Country"
    [value]="beneficiary?.socialIdCountryCode"
  ></app-details-item>

  <app-details-item
    [class]="pageClassPrefix + 'citizenship-country'"
    [showPlaceholder]="false"
    label="Citizenship Country"
    [value]="beneficiary?.citizenshipCountryCode"
  ></app-details-item>

  <app-details-item
    [class]="pageClassPrefix + 'percentage-owned'"
    [showPlaceholder]="false"
    label="Percentage of Business owned"
    [value]="beneficiary?.ownershipPercentage ? beneficiary?.ownershipPercentage + '%' : undefined"
  ></app-details-item>
</div>

<div class="addresses-info">
  <ng-container *ngFor="let address of beneficiary?.addresses">
    <div class="address-type">{{ address.type | titlecase }} Address</div>
    <div class="details-row">
      <app-details-item [showPlaceholder]="false" label="Address 1" [value]="address?.streetLine1" [noBreak]="true"></app-details-item>

      <app-details-item [showPlaceholder]="false" label="City" [value]="address?.cityName" [noBreak]="true"></app-details-item>

      <app-details-item [showPlaceholder]="false" label="State" [value]="address?.stateCode"></app-details-item>

      <app-details-item [showPlaceholder]="false" label="Zip code" [value]="address?.zipCode" [noBreak]="true"></app-details-item>

      <app-details-item [showPlaceholder]="false" label="Country" [value]="address?.countryCode"></app-details-item>
    </div>
  </ng-container>
</div>

<div class="review-buttons">
  <button rktButton btnType="secondary" title="Back to Edit" (click)="activeModal.close({ action: 'EDIT' })">Back to Edit</button>

  <div class="buttons">
    <button rktButton type="button" btnType="tertiary" (click)="activeModal.close()">Discard</button>
    <button rktButton type="submit" (click)="activeModal.close({ action: 'SAVE' })" title="Confirm">Confirm</button>
  </div>
</div>
