import { NgIf } from '@angular/common';
import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { SelectionType } from '@swimlane/ngx-datatable';
import { isEmpty, isUndefined, startCase } from 'lodash-es';
import { Subject } from 'rxjs';
import { finalize, take, takeUntil } from 'rxjs/operators';
import { RktButtonDirective } from '@rocketfinancialcorp/rocket-ui/button';
import { RktIconComponent } from '@rocketfinancialcorp/rocket-ui/icon';
import { ActiveModal } from '@rocketfinancialcorp/rocket-ui/modal';
import { DatatableColumn, FetchData, RktTableComponent, RktTableSearchComponent } from '@rocketfinancialcorp/rocket-ui/table';

import { FilterMenuComponent } from '@shared/components';
import { BusinessAccountList, BusinessAccountsResponse, FilterField, FilterValues, RequestPageParams } from '@shared/models';
import { BusinessAccountService } from '@shared/services';
import { AuthActions, fromAuth } from '@shared/store';
import { ErrorUtils, activeFilters } from '@shared/utils';

@Component({
  selector: 'app-business-account-list',
  templateUrl: './business-account-list.component.html',
  styleUrls: ['./business-account-list.component.scss'],
  standalone: true,
  imports: [RktButtonDirective, NgIf, RktIconComponent, RktTableSearchComponent, RktTableComponent, FilterMenuComponent],
})
export class BusinessAccountListComponent implements AfterViewChecked, OnDestroy {
  loading = false;

  activeUser$ = this.store.select(fromAuth.selectUser).pipe(take(1));

  totalElements = 0;

  searchString?: string;

  filters: FilterField[] = [
    {
      name: 'tenantSlug',
      displayName: 'Tenant Name',
      type: 'INPUT',
    },
    {
      name: 'accountType',
      displayName: 'Account Type',
      type: 'RADIO',
      options: [
        { label: 'Parent Business Account', value: 'listParentsOnly=true' },
        { label: 'Child Business Account', value: 'listChildOnly=true' },
      ],
    },
  ];

  activeFilters: FilterValues = {};

  businessAccountList!: BusinessAccountList[];

  selectedBusinessAccount: BusinessAccountList[] = [];

  selectionType = SelectionType.single;

  columns: DatatableColumn[] = [
    { name: 'ID', prop: 'id', colType: 'short-id', flexSize: 150 },
    { name: 'Operating Name', prop: 'name', flexSize: 350 },
    { name: 'Parent', prop: 'parent', flexSize: 100 },
    { name: 'Tenant', prop: 'tenantSlug', flexSize: 300 },
  ];

  page = 0;

  emptyListMessage = 'No Business Accounts found.';

  get activePage(): number {
    const routePage = this.activatedRoute?.snapshot?.queryParams?.page;
    return routePage ? parseInt(routePage, 10) : 0;
  }

  get activeFiltersCount(): number {
    return Object.keys(this.activeFilters).length;
  }

  private destroy$ = new Subject<void>();

  constructor(
    public ref: ChangeDetectorRef,
    private store: Store,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private businessAccountService: BusinessAccountService,
    public activeModal: ActiveModal,
  ) {
    this.businessAccountService.businessAccountListFilter.subscribe({
      next: (filters: FilterValues) => {
        if (isEmpty(filters)) {
          this.businessAccountService.getBusinessAccountFilterParams();
        } else {
          this.activeFilters = filters;
        }
      },
    });
  }

  ngAfterViewChecked(): void {
    this.ref.detectChanges();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  updateQueryParams(page?: RequestPageParams['page']): void {
    if (!isUndefined(page) && this.activePage !== page) {
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: { page: page > 0 ? page : undefined },
        queryParamsHandling: 'merge',
      });
    }
  }

  getBusinessAccountList({ page, size }: FetchData): void {
    this.loading = true;
    this.updateQueryParams(page);

    this.businessAccountService
      .getBusinessAccountListForSponsor({
        page: page || 0,
        size,
        searchString: this.searchString,
        activeFilters: { ...this.activeFilters },
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (businessAccountListItems) => {
          this.emptyListMessage = 'No Business Accounts found.';
          this.onFetchComplete(businessAccountListItems);
        },
        error: (error) => {
          this.emptyListMessage = 'Unable to fetch Business Accounts.';
          this.onFetchComplete();
          ErrorUtils.catchError('customerService.getCustomers error', error);
        },
      });
  }

  onFetchComplete(businessAccountListItems?: BusinessAccountsResponse): void {
    const { content = [], totalElements = 0 } = businessAccountListItems || {};
    this.businessAccountList = content;
    this.totalElements = totalElements;
    this.loading = false;
  }

  onSelectBtnClick(): void {
    const selectedAccount = this.selectedBusinessAccount[0];

    this.activeUser$
      .pipe(
        finalize(() => this.activeModal.close()),
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: (user) => {
          if (user) {
            this.store.dispatch(
              AuthActions.loginSuccess({
                user,
                businessAccount: {
                  ...selectedAccount,
                  operatingName: selectedAccount.name,
                  logoInitials: startCase(selectedAccount.name.charAt(0)),
                },
                tenant: selectedAccount.tenantSlug,
              }),
            );

            setTimeout(() => {
              location.reload();
            }, 500);
          }
        },
      });
  }

  onSearch(event: string): void {
    if (!event) {
      this.onSearchReset();
      return;
    }
    this.searchString = event;
    this.getBusinessAccountList({ page: 0 });
  }

  onSearchReset(): void {
    if (!this.searchString) {
      return;
    }

    this.searchString = undefined;
    this.getBusinessAccountList({ page: 0 });
  }

  applyFilters(filterValues: FilterValues): void {
    this.activeFilters = activeFilters(filterValues);
    this.businessAccountService.setBusinessAccountFilterParams(this.activeFilters);
    this.getBusinessAccountList({ page: 0 });
  }
}
