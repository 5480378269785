import { AsyncPipe, NgIf, NgTemplateOutlet } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { ShortIdPipe } from '@rocketfinancialcorp/rocket-ui/table';

import { CardDispute, TemplateContext } from '@shared/models';
import { NavigationService } from '@shared/services';
import { CardAccountActions, fromFinancialAccount } from '@shared/store';

import { TemplateContextDirective } from '../../../directives/template-context.directive';
import { DateFormatPipe } from '../../../pipes/date-format.pipe';
import { DetailsItemComponent } from '../../details-item/details-item.component';
import { TextWithPlaceholderComponent } from '../../text-with-placeholder/text-with-placeholder.component';
import { CardPagesBreadcrumbsComponent } from '../card-pages-breadcrumbs/card-pages-breadcrumbs.component';

@Component({
  templateUrl: './dispute-details.component.html',
  standalone: true,
  imports: [
    NgIf,
    NgTemplateOutlet,
    TemplateContextDirective,
    CardPagesBreadcrumbsComponent,
    TextWithPlaceholderComponent,
    DetailsItemComponent,
    AsyncPipe,
    DateFormatPipe,
    ShortIdPipe,
  ],
})
export class DisputeDetailsComponent implements OnInit, OnDestroy {
  pageClassPrefix = 'dispute-details-';

  disputeDetailsContext?: TemplateContext<CardDispute>;

  disputeDetails$ = this.store.select(fromFinancialAccount.selectCardAccountDisputeDetails);

  private destroy$ = new Subject<void>();

  constructor(
    private navigationService: NavigationService,
    private store: Store,
  ) {
    this.navigationService.dispatchOnNavigationEnd(CardAccountActions.loadDisputeDetails());
  }

  ngOnInit(): void {
    this.store.dispatch(CardAccountActions.loadCardAccountDetails({}));
  }

  ngOnDestroy(): void {
    this.navigationService.ngOnDestroy();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
