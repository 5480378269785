<div class="authorization-filter">
  <div class="rkt-form authorization-select">
    <div class="rkt-form-control">
      <div class="rkt-form-field">
        <div class="rkt-form-select-field">
          <ng-select
            #select
            class="rkt-form-select rkt-form-select-assignee-dropdown"
            [items]="items$ | async"
            placeholder="Select Debit Authorization"
            [tabIndex]="0"
            [(ngModel)]="selectedItem"
            bindLabel="label"
            bindValue="value"
            [loading]="loading"
            [clearable]="true"
            [searchable]="false"
            (change)="onChange($event)"
            [closeOnSelect]="true"
            [minTermLength]="0"
            appendTo="body"
            [maxSelectedItems]="1"
            (open)="onOpen()"
            (close)="onClose()"
            [virtualScroll]="true"
            [notFoundText]="'No Debit Authorizations Found'"
          ></ng-select>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="!(items$ | async)?.length && !loading && !bypassDebitAuthorizationStatus" class="ada-unavailable-message">
    No valid Debit Authorization found, please select Offline Debit Authorization if authorization has been provided.
  </div>

  <div class="row offline-ada-toggle-button-wrapper">
    <button class="offline-ada-toggle-button" [class.checked]="bypassDebitAuthorizationStatus" (click)="bypassDebitAuthorizationToggle()">
      <rkt-icon [name]="bypassDebitAuthorizationStatus ? 'checkbox-on' : 'checkbox-off'"></rkt-icon>
      Offline Debit Authorization
    </button>
  </div>
</div>
