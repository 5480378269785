import { AsyncPipe, NgIf } from '@angular/common';
import { AfterViewChecked, ChangeDetectorRef, Component, ViewChild, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { identity, isUndefined, pickBy } from 'lodash-es';
import { RktIconComponent } from '@rocketfinancialcorp/rocket-ui/icon';
import { DatatableColumn, DatatableSorting, RktTableComponent } from '@rocketfinancialcorp/rocket-ui/table';

import { FilterMenuComponent } from '@shared/components';
import { DropdownItem, ExceptionItem, FilterField, FilterValues, RequestPageParams } from '@shared/models';
import { ExceptionManagementActions, exceptionManagementFeature, selectExceptionsActiveFiltersCount } from '@shared/store';

import { AssigneeFilterComponent } from '../../dropdowns/assignee-filter/assignee-filter.component';

@Component({
  templateUrl: './exception-management-list.component.html',
  standalone: true,
  imports: [NgIf, AssigneeFilterComponent, AsyncPipe, RktIconComponent, RktTableComponent, FilterMenuComponent],
})
export class ExceptionManagementListComponent implements AfterViewChecked {
  @ViewChild('filterMenu') filterMenu!: FilterMenuComponent;

  ref = inject(ChangeDetectorRef);

  router = inject(Router);

  activatedRoute = inject(ActivatedRoute);

  store = inject(Store);

  exceptions$ = this.store.select(exceptionManagementFeature.selectExceptions);

  totalElements$ = this.store.select(exceptionManagementFeature.selectExceptionsTotalElements);

  isLoading$ = this.store.select(exceptionManagementFeature.selectIsLoading);

  activeFilters$ = this.store.select(exceptionManagementFeature.selectExceptionsActiveFilters);

  activeFiltersCount$ = this.store.select(selectExceptionsActiveFiltersCount);

  selectedAssignee?: DropdownItem[];

  filters: FilterField[] = [
    {
      name: 'date',
      displayName: 'Date',
      type: 'DATE',
      options: [
        { label: 'Default: 30 days', value: 'DEFAULT' },
        { label: 'Today', value: 'TODAY' },
        { label: 'Yesterday', value: 'YESTERDAY' },
        { label: 'This week', value: 'THIS_WEEK' },
        { label: 'This month', value: 'THIS_MONTH' },
        { label: 'Custom range', value: 'CUSTOM' },
      ],
      defaultValue: 'DEFAULT',
      props: {
        maxMonths: '15',
      },
    },
    {
      name: 'category',
      displayName: 'Exception Category',
      type: 'CHECKLIST',
      options: [
        { label: 'All', value: 'all' },
        { label: 'Transaction', value: 'TRANSACTION' },
        { label: 'Bank Account Verification', value: 'BANK_ACCOUNT_VERIFICATION' },
      ],
      defaultValue: 'all',
    },
    {
      name: 'type',
      displayName: 'Exception Type',
      type: 'CHECKLIST',
      options: [
        { label: 'All', value: 'all' },
        { label: 'ACH Return', value: 'ACH_RETURN' },
        { label: 'ACH NOC', value: 'ACH_NOC' },
        { label: 'Bank Account Verification Declined', value: 'BANK_ACCOUNT_VERIFICATION_DECLINED' },
        { label: 'Bank Account Verification NOC', value: 'BANK_ACCOUNT_VERIFICATION_NOC' },
      ],
      defaultValue: 'all',
    },
    {
      name: 'status',
      displayName: 'Status',
      type: 'CHECKLIST',
      options: [
        { label: 'All', value: 'all' },
        { label: 'New', value: 'NEW' },
        { label: 'In Progress', value: 'IN_PROGRESS' },
        { label: 'Reopened', value: 'REOPENED' },
        { label: 'Resolved', value: 'RESOLVED' },
      ],
      defaultValue: 'NEW,IN_PROGRESS,REOPENED',
    },
  ];

  columns: DatatableColumn[] = [
    { name: 'Created At', prop: 'createdAt', fixedSize: 150, isSortable: true, colType: 'date-time' },
    { name: 'Exception ID', prop: 'id', colType: 'short-id', fixedSize: 180, isSortable: true },
    { name: 'Exception Category', prop: 'categoryLabel', sortProp: 'category', flexSize: 150, isSortable: true },
    { name: 'Exception Type', prop: 'typeLabel', sortProp: 'type', flexSize: 150, isSortable: true },
    { name: 'Assigned To', prop: 'assignee', sortProp: 'assignedTo', colType: 'two-line-col', flexSize: 150, isSortable: true },
    {
      name: 'Status',
      prop: 'status',
      fixedSize: 140,
      colType: 'status-tag',
      colAlign: 'right',
      isSortable: true,
    },
  ];

  sortParams: DatatableSorting = { key: 'createdAt', sortProp: 'createdAt', sortDir: 'desc' };

  get activePage(): number {
    const routePage = this.activatedRoute?.snapshot?.queryParams?.page;
    return routePage ? parseInt(routePage, 10) : 0;
  }

  ngAfterViewChecked(): void {
    this.ref.detectChanges();
  }

  updateQueryParams(page: RequestPageParams['page']): void {
    if (!isUndefined(page) && this.activePage !== page) {
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: { page: page > 0 ? page : undefined },
        queryParamsHandling: 'merge',
      });
    }
  }

  getExceptions({ page, sortParams }: RequestPageParams): void {
    this.updateQueryParams(page);

    if (sortParams) {
      this.sortParams = sortParams;
    }

    this.store.dispatch(
      ExceptionManagementActions.loadExceptions({
        params: {
          page: page ?? 0,
          sortParams: sortParams ?? this.sortParams,
        },
      }),
    );
  }

  onRowClick({ id }: ExceptionItem): void {
    this.router.navigateByUrl(`/app/exception-management/exceptions/${id}`);
  }

  applyFilters(filterValues: FilterValues): void {
    this.store.dispatch(ExceptionManagementActions.setExceptionsActiveFilters({ filters: { ...pickBy(filterValues, identity) } }));
    this.getExceptions({ page: 0 });
  }

  closeMenu() {
    if (this.filterMenu?.isVisible) {
      this.filterMenu.toggle({} as MouseEvent);
    }
  }

  setSelectedAssignee(items?: DropdownItem[]) {
    this.selectedAssignee = items;
    this.store.dispatch(ExceptionManagementActions.setExceptionsAssigneeFilter({ assigneeFilter: items ?? [] }));
  }
}
