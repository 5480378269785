<h1 class="title">
  @if (type) {
    <rkt-icon [name]="type"></rkt-icon>
  }
  {{ name }}
</h1>

<button class="close-button" (click)="activeModal.close()"><rkt-icon name="times"></rkt-icon></button>

<div *ngIf="fileFormat !== 'PDF'" class="image-preview">
  <img [src]="url" *ngIf="url" [alt]="name" />
</div>

<ngx-extended-pdf-viewer
  *ngIf="fileFormat === 'PDF'"
  [src]="url"
  [showBorders]="false"
  [showToolbar]="false"
  [textLayer]="false"
  [sidebarVisible]="false"
  backgroundColor="#F7F7F7"
  [useBrowserLocale]="true"
  [authorization]="true"
></ngx-extended-pdf-viewer>
