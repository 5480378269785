import { Action, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';

import { SolutionAllowedTypes, TransactionSolution } from '@shared/models';
import * as fromRoot from '@shared/store';

import * as fromTransactionSolution from './transaction-solution.reducer';

export const transactionFeatureKey = 'transaction';

export interface TransactionState {
  [fromTransactionSolution.transactionFeatureKey]: fromTransactionSolution.TransactionSolutionState;
}

export interface State extends fromRoot.RootState {
  [transactionFeatureKey]: TransactionState;
}

export const reducers = (state: TransactionState | undefined, action: Action) => {
  return combineReducers({
    [fromTransactionSolution.transactionFeatureKey]: fromTransactionSolution.reducer,
  })(state, action);
};

export const selectTransactionState = createFeatureSelector<TransactionState>(transactionFeatureKey);

export const selectSolutionState = createSelector(selectTransactionState, (state) => state.transactionSolution);

export const selectEnabledSolutions = createSelector(selectSolutionState, fromTransactionSolution.getEnabledSolutions);

export const selectEnabledSolutionConfigs = createSelector(selectSolutionState, fromTransactionSolution.getSolutionsConfigs);

export const selectSolutionByName = (solutionName: TransactionSolution) =>
  createSelector(selectEnabledSolutionConfigs, (configs) => configs?.find((item) => item.solutionName === solutionName));

export const selectSolutionCurrency = (solutionName: TransactionSolution) =>
  createSelector(selectSolutionByName(solutionName), (solution) => {
    return solution?.solutionConfig?.allowedCurrencies?.[0] || 'USD';
  });

export const selectTransactionTypes = (type: 'debit' | 'credit') =>
  createSelector(
    selectEnabledSolutionConfigs,
    (configs) =>
      configs?.reduce((obj: SolutionAllowedTypes, { solutionName, solutionConfig: { transactionTypes } }) => {
        const { SEND, REQUEST, RETURN, REVERSAL, ADJUSTMENT } = transactionTypes;
        return {
          ...obj,
          [solutionName]: {
            ...(SEND && { SEND: SEND.allowedAccountTypes?.[type] }),
            ...(REQUEST && { REQUEST: REQUEST.allowedAccountTypes?.[type] }),
            ...(RETURN && { RETURN: RETURN.allowedAccountTypes?.[type] }),
            ...(REVERSAL && { REVERSAL: REVERSAL.allowedAccountTypes?.[type] }),
            ...(ADJUSTMENT && { ADJUSTMENT: ADJUSTMENT.allowedAccountTypes?.[type] }),
          },
        };
      }, {}) ?? {},
  );
