import { formatDate } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { format, parse, sub } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import { compact, get, isArray } from 'lodash-es';

import { constants } from '@shared/constants';
import { CustomHttpParamEncoder } from '@shared/encoder';
import {
  CardProviderDetails,
  FinancialAccountActivities,
  FinancialAccountActivitiesRaw,
  FinancialAccountActivitiesRequest,
  FinancialAccountActivityItem,
  FinancialAccountDetails,
  FinancialAccountDetailsWithBalances,
  FinancialAccountDetailsWithBalancesRaw,
  FinancialAccountList,
  FinancialAccountListItemRaw,
  FinancialAccountListRaw,
  FinancialAccountStatements,
  FinancialAccountStatementsRaw,
  FinancialAccountWithAccountHolderList,
  FinancialAccountWithAccountHolderListItem,
  FinancialAccountWithAccountHolderListItemRaw,
  FinancialAccountWithAccountHolderListRaw,
  FinancialAccountsRequest,
  TransactionLimitItem,
} from '@shared/models';
import { filterCreatedAtDateRange, getSubtypeDisplayName, systemTimeZone, toPhone, toTitleCase } from '@shared/utils';

export const mapFinancialAccountListWithEntities = (
  response: FinancialAccountWithAccountHolderListRaw,
): FinancialAccountWithAccountHolderList => {
  const { content = [], totalElements } = response;

  const items = content.map((item) => {
    if (item.accountHolderType === 'RECIPIENT') {
      return mapRecipientFinancialAccountWithEntity(item);
    } else if (item.accountHolderType === 'CUSTOMER') {
      return mapCustomerFinancialAccountWithEntity(item);
    } else {
      return mapFinancialAccountWithUnknownEntity(item);
    }
  });

  return {
    items,
    totalElements,
  };
};

export const mapFinancialAccountList = (response: FinancialAccountListRaw): FinancialAccountList => {
  const { content = [], totalElements = 0, hasNext = false } = response;

  const items = content.map((item) => {
    const accountNumberTailOrNull = item.type === 'BANK' && item.bankAccount ? `*${item.bankAccount.accountNumberTail}` : undefined;
    const bankAccountOrNull = item.bankAccount ? 'bankAccount' : undefined;
    const accountHolderTypeDisplayName = toTitleCase(item.accountHolderType) || 'Business Account';
    const displayName = item.bankAccount
      ? `**** ${item.bankAccount.accountNumberTail ?? ''} | ${toTitleCase(item.subtype)} Acc`
      : `**** ${item.card?.cardNumberTail ?? ''} | ${toTitleCase(item.subtype)} Card`;
    const verificationStatus = item.verificationStatus ?? 'UNVERIFIED';

    return {
      id: item.id,
      currency: item.currency,
      financialAccountShortId: item.id?.split('-')[0].toUpperCase(),
      defaultFlag: item.defaultFlag,
      businessAccountId: item.businessAccountId,
      createdAt: item.createdAt,
      state: item.state,
      verification: toTitleCase(verificationStatus),
      rowClassName: `verification-${verificationStatus.toLowerCase()}`,
      category: item.category,
      categoryDisplayName: item.category,
      cardOrBankAccount: (item.card ? 'card' : bankAccountOrNull) as 'card' | 'bankAccount' | undefined,
      lastFourNumbers: item.card ? `*${item.card.cardNumberTail}` : accountNumberTailOrNull,
      displayName,
      name: item.name,
      accountBalance: item.accountBalance ?? item._embedded?.balances?.[item.currency]?.accountBalance,
      availableBalance: item.availableBalance ?? item._embedded?.balances?.[item.currency]?.availableBalance,
      single: item.single ?? 0,
      daily: item.daily ?? 0,
      weekly: item.weekly ?? 0,
      monthly: item.monthly ?? 0,
      parentId: item.parentId,
      accountHolderType: item.accountHolderType,
      accountHolderTypeDisplayName,
      customer: item.customer,
      subtype: item.subtype,
      type: item.type,
      accountInfo: getFinancialAccountInfo(item),
    };
  });

  return {
    items,
    totalElements,
    hasNext,
  };
};

export const mapFinancialAccountDetails = (financialAccount: FinancialAccountDetailsWithBalancesRaw): FinancialAccountDetails => {
  const accountNumberTailOrNull = financialAccount.bankAccount ? `*******${financialAccount.bankAccount.accountNumberTail}` : undefined;
  const bankAccountOrNull = financialAccount.bankAccount ? 'bankAccount' : undefined;
  const embeddedBalances = financialAccount._embedded?.balances ?? undefined;
  const accountBalances = financialAccount._embedded?.balances
    ? financialAccount._embedded?.balances[financialAccount.currency.toUpperCase()]
    : undefined;

  return {
    ...financialAccount,
    financialAccountShortId: financialAccount.id?.split('-')[0].toUpperCase(),
    displayName: financialAccount.bankAccount
      ? `**** ${financialAccount.bankAccount.accountNumberTail} | ${toTitleCase(financialAccount.subtype)} Acc`
      : `**** ${financialAccount.card?.cardNumberTail} | ${toTitleCase(financialAccount.subtype)} Card`,
    lastFourNumbers: financialAccount.card ? `************${financialAccount.card.cardNumberTail}` : accountNumberTailOrNull,
    card: financialAccount.card
      ? {
          ...financialAccount.card,
          expiry: format(parse(financialAccount.card.expiry, 'yyyy-MM', new Date()), 'MM/yyyy'),
          fullNameOnCard: compact([financialAccount.card.firstName, financialAccount.card.middleName, financialAccount.card.lastName]).join(
            ' ',
          ),
        }
      : undefined,
    cardOrBankAccount: financialAccount.card ? 'card' : bankAccountOrNull,
    accountBalances: accountBalances,
    embeddedAccountBalance: embeddedBalances,
    cardProviderDetails: { ...get(financialAccount, '_embedded.cardProviderDetails', {} as CardProviderDetails) },
  };
};

export const getTransactionLimit = (type: string, limits: TransactionLimitItem[]): number => {
  return limits.find((limit) => limit.type === type)?.amount ?? 0;
};

export const mapRecipientFinancialAccountWithEntity = (
  item: FinancialAccountWithAccountHolderListItemRaw,
): FinancialAccountWithAccountHolderListItem => {
  const account = getFinancialAccountDisplayName(item);

  if (!item.recipient) {
    return mapFinancialAccountWithUnknownEntity(item);
  }

  const recipientName = `${item.recipient.firstName} ${item.recipient.middleName ? item.recipient.middleName : ''} ${
    item.recipient.lastName
  }`;
  const accountHolderTypeDisplayName = toTitleCase(item.accountHolderType);
  const recipientEmail = item.recipient.email;
  const recipientPhone = toPhone(item.recipient.phoneNumber);

  let displayName = `${accountHolderTypeDisplayName} ${recipientName}; ${
    item.recipient.email ? ' ' + item.recipient.email.toLowerCase() + ';' : ''
  }`;
  displayName = displayName + `${item.recipient.phoneNumber ? ' ' + recipientPhone + ';' : ''}`;
  const displayNameByType = displayName + ` ${account}`;

  return {
    ...item,
    accountHolderName: recipientName,
    accountHolderEmail: recipientEmail,
    accountHolderPhone: recipientPhone,
    accountHolderTypeDisplayName,
    account,
    displayName: displayNameByType,
    isDisabled: item.state !== 'ACTIVE' || item.recipient.state !== 'ACTIVE',
    state: item.recipient.state !== 'ACTIVE' ? item.recipient.state : item.state,
    accountInfo: getFinancialAccountInfo(item),
  };
};

export const mapCustomerFinancialAccountWithEntity = (
  item: FinancialAccountWithAccountHolderListItemRaw,
): FinancialAccountWithAccountHolderListItem => {
  const account = getFinancialAccountDisplayName(item);

  if (!item.customer) {
    return mapFinancialAccountWithUnknownEntity(item);
  }

  const customerName = item.customer.displayName;
  const accountHolderTypeDisplayName = toTitleCase(item.accountHolderType);
  let displayName = `${accountHolderTypeDisplayName} ${customerName}; ${
    item.customer.displayEmail ? ' ' + item.customer.displayEmail.toLowerCase() + ';' : ''
  }`;
  displayName = displayName + `${item.customer.primaryPhoneNumber ? ' ' + item.customer.primaryPhoneNumber.number + ';' : ''}`;
  const displayNameByType = displayName + ` ${account}`;

  return {
    ...item,
    accountHolderName: customerName,
    accountHolderEmail: item.customer.displayEmail,
    accountHolderPhone: item.customer.displayPhoneNumber,
    accountHolderTypeDisplayName,
    account,
    displayName: displayNameByType,
    isDisabled: item.state !== 'ACTIVE' || item.customer.status !== 'ACTIVE',
    state: item.customer.status !== 'ACTIVE' ? item.customer.status : item.state,
    accountInfo: getFinancialAccountInfo(item),
  };
};

export const mapFinancialAccountWithUnknownEntity = (
  item: FinancialAccountWithAccountHolderListItemRaw,
): FinancialAccountWithAccountHolderListItem => {
  return {
    ...item,
    accountHolderName: undefined,
    accountHolderEmail: undefined,
    accountHolderPhone: undefined,
    accountHolderTypeDisplayName: toTitleCase(item.accountHolderType),
    account: getFinancialAccountDisplayName(item),
    isDisabled: true,
    state: 'DELETED',
    accountInfo: getFinancialAccountInfo(item),
  };
};

export const getFinancialAccountDisplayName = (
  item: FinancialAccountWithAccountHolderListItemRaw,
): FinancialAccountWithAccountHolderListItem['account'] => {
  return item.bankAccount
    ? `**** ${item.bankAccount.accountNumberTail} | ${toTitleCase(item.subtype)} Acc`
    : `**** ${item.card?.cardNumberTail} | ${toTitleCase(item.subtype)} Card`;
};

export const mapFinancialAccountBalances = (response: FinancialAccountDetailsWithBalancesRaw): FinancialAccountDetailsWithBalances => {
  const { _embedded, bankAccount, card, currency, id } = response ?? {};

  return {
    accountBalances: _embedded?.balances,
    bankAccount,
    currency,
    card,
    id,
  };
};

export const mapFinancialAccountActivities = (response: FinancialAccountActivitiesRaw): FinancialAccountActivities => {
  const { content = [], totalElements = 0 } = response ?? {};
  const items: FinancialAccountActivityItem[] = content.map((item) => ({
    ...item,
    description: `${item.description ?? ' '}::${item.statementDescription ?? ' '}`,
    status: item.transactionStatus,
    isDisabled: !item.transactionId,
    idWithCreatedAt: `${item.transactionId ?? 'N/A'}::${item.createdAt}`,
  }));

  return {
    items,
    totalElements,
  };
};

/**
 * Returns the HttpParams object for making getFinancialAccountsWithBalances API request with provided request parameters.
 * @param {FinancialAccountsRequest} requestParams - The request parameters object containing pagination, sorting, filtering and search options.
 * @returns {HttpParams} - The HttpParams object containing the query parameters to be sent in the request.
 */
export const financialAccountsWithBalancesRequestParams = (requestParams: FinancialAccountsRequest): HttpParams => {
  const { page, size, type, state, accountTypes, parentId, accountHolderId, accountHolderTypes = [], activeFilters } = requestParams;
  const pageSize = size ?? constants.FINANCIAL_ACCOUNT_ROWS;

  let params = new HttpParams({ encoder: new CustomHttpParamEncoder() }).set('page', `${page ?? 0}`).set('size', `${pageSize}`);

  if (type) {
    params = params.append('type', type);
  }

  if (state) {
    params = params.append('state', isArray(state) ? state.join(',') : state);
  }

  if (accountTypes) {
    params = params.append('categories', accountTypes.join(','));
  }

  if (accountHolderTypes.length) {
    params = params.append('accountHolderTypes', accountHolderTypes.join(','));
  }

  if (parentId) {
    params = params.append('parentId', parentId);
  }

  if (accountHolderId) {
    params = params.append('accountHolderId', accountHolderId);
  }

  if (activeFilters) {
    Object.keys(activeFilters).forEach((key) => {
      params = params.append(key, activeFilters[key]!);
    });
  }

  return params;
};

/**
 * Returns the HttpParams object for making getFinancialAccountActivities API request with provided request parameters.
 * @param {Partial<FinancialAccountActivitiesRequest>} requestParams - An object containing the request parameters.
 * @returns {HttpParams} - An instance of HttpParams.
 */
export const financialAccountActivitiesRequestParams = (requestParams: Partial<FinancialAccountActivitiesRequest>): HttpParams => {
  const { page, size, activeFilters } = requestParams;

  const pageSize = size ?? constants.TABLE_ROWS;

  let params = new HttpParams({ encoder: new CustomHttpParamEncoder() });

  params = params.append('size', pageSize);

  if (page) {
    params = params.append('page', page);
  }

  if (activeFilters) {
    Object.keys(activeFilters).forEach((key) => {
      if (key === 'date') {
        const defaultRange = {
          from: format(sub(new Date(), { days: 90 }), 'yyyy-MM-dd'),
          to: format(new Date(), 'yyyy-MM-dd'),
        };
        const { from, to } = filterCreatedAtDateRange(activeFilters[key], defaultRange) ?? {};

        if (!from || !to) {
          return;
        }

        params = params.append('startDateTime', formatDate(from, 'yyyy-MM-ddTHH:mm:ssZZZZZ', 'en'));
        params = params.append('endDateTime', formatDate(`${to} 23:59:59`, 'yyyy-MM-ddTHH:mm:ssZZZZZ', 'en'));
        params = params.append('timeZone', systemTimeZone);
      } else if (key === 'amount') {
        const [min, max, type] = activeFilters[key]?.split('::') ?? [];

        if (!min || !max || !type) {
          return;
        }

        params = params.append('amountFrom', type === 'DEBIT' ? `-${max}` : min);
        params = params.append('amountTo', type === 'DEBIT' ? `-${min}` : max);
      } else {
        params = params.append(key, activeFilters[key]!);
      }
    });
  }

  return params;
};

export const getFinancialAccountInfo = (item: Partial<FinancialAccountListItemRaw>): string | undefined => {
  let accountInfo: string | undefined = undefined;

  switch (item?.type) {
    case 'BANK':
      if (item.bankAccount) {
        accountInfo = compact([
          item.bankAccount?.bankName,
          `*${item.bankAccount?.accountNumberTail}`,
          '|',
          getSubtypeDisplayName(item.subtype),
        ]).join(' ');
      }
      break;

    case 'CARD':
      if (item?.card) {
        accountInfo = compact([`*${item.card?.cardNumberTail}`, '|', getSubtypeDisplayName(item.subtype)]).join(' ');
      }
      break;

    default:
      break;
  }

  return accountInfo;
};

export const mapFinancialAccountStatements = (response: FinancialAccountStatementsRaw): FinancialAccountStatements => {
  const { content = [], totalElements = 0 } = response || {};
  const items = content.map((item) => ({
    ...item,
    statementPeriod: `${format(toZonedTime(new Date(item.statementStartDate), systemTimeZone), 'MMM dd, yyyy')} - ${format(
      toZonedTime(new Date(item.statementEndDate), systemTimeZone),
      'MMM dd, yyyy',
    )}`,
  }));

  return {
    items,
    totalElements,
  };
};
