import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-text-with-placeholder',
  templateUrl: './text-with-placeholder.component.html',
  styleUrls: ['./text-with-placeholder.component.scss'],
  standalone: true,
  imports: [NgIf],
})
export class TextWithPlaceholderComponent {
  @Input() showPlaceholder = true;

  @Input() darkBg?: boolean;
}
