import { format, parse } from 'date-fns';
import { parsePhoneNumber } from 'libphonenumber-js';
import { compact } from 'lodash-es';

import { BankAccountCreateModel, CardAccountCreateModel } from '@shared/models';

import { BankAccountCreateData, CardCreateData } from './models';

export const bankAccountCreateData = ({ bankAccount, address }: BankAccountCreateData): BankAccountCreateModel => {
  const { addressLine1, addressLine2, postalCode, country, state, city } = address ?? {};

  const isAddressFilled = compact([addressLine1, addressLine2, postalCode, country, city, state]).length !== 0;

  return {
    accountNumber: bankAccount.accountNumber,
    bankName: bankAccount.bankName,
    nameOnAccount: bankAccount.nameOnAccount,
    routingNo: bankAccount.routingNo,
    billingAddress: isAddressFilled
      ? {
          addressLine1: addressLine1!,
          addressLine2: addressLine2 ?? undefined,
          postalCode: postalCode!,
          country: country!,
          state: state!,
          city: city!,
        }
      : undefined,
  };
};

export const cardCreateData = ({ card, address }: CardCreateData): CardAccountCreateModel => {
  const { addressLine1, addressLine2, postalCode, country, state, city } = address;
  const isAddressFilled = compact([addressLine1, addressLine2, postalCode, country, city, state]).length !== 0;
  return {
    firstName: card.firstName,
    lastName: card.lastName,
    cardNumber: card.cardNumber,
    middleName: card.middleName ?? undefined,
    expiry: format(parse(card.expiry.replace('/', ''), 'MMyyyy', new Date()), 'yyyy-MM'),
    billingAddress: isAddressFilled
      ? {
          addressLine1: addressLine1,
          addressLine2: addressLine2 ?? undefined,
          postalCode: postalCode,
          country: country,
          state: state,
          city: city,
        }
      : undefined,
  };
};

export const phoneNumberFormat = (phoneNumber?: string): string | undefined => {
  if (!phoneNumber) {
    return undefined;
  }

  return parsePhoneNumber(phoneNumber).number.toString();
};
