import { TitleCasePipe } from '@angular/common';
import { Component, input, output } from '@angular/core';
import { RktIconComponent } from '@rocketfinancialcorp/rocket-ui/icon';

import { CreateIndividualEntityFields } from '@shared/constants';
import { LinxRequestBenificiaryDetails } from '@shared/models';

@Component({
  selector: 'app-enhance-benificiary-preview',
  standalone: true,
  imports: [RktIconComponent, TitleCasePipe],
  templateUrl: './enhance-benificiary-preview.component.html',
})
export class EnhanceBenificiaryPreviewComponent {
  enhanceBenificiaryDetails = input<LinxRequestBenificiaryDetails>();

  enhanceBenificiaryDisplayData = input<string[]>([]);

  isEditDisabled = input<boolean>(false);

  expandRow = output();

  createBeneficiaryFields = CreateIndividualEntityFields;
}
