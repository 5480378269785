import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { RktIconComponent } from '@rocketfinancialcorp/rocket-ui/icon';

import { Recipient } from '@shared/models';

export type RecipientDetailsAction = 'SUSPEND' | 'UNSUSPEND' | 'REMOVE';

@Component({
  selector: 'app-recipient-details-header',
  templateUrl: './recipient-details-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, RktIconComponent],
})
export class RecipientDetailsHeaderComponent {
  @Input() recipient!: Recipient;

  @Input() isControlsDisabled = true;

  @Output() btnClicked = new EventEmitter<RecipientDetailsAction>();
}
