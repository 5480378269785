import { Component, Input, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { compact } from 'lodash-es';
import { MenuComponent, MenuItem } from '@rocketfinancialcorp/rocket-ui/menu';
import { ModalService } from '@rocketfinancialcorp/rocket-ui/modal';

import { FeatureManagementModalComponent } from '@shared/components';
import { User } from '@shared/models';
import { APP_ENV_CONFIG } from '@shared/services';
import { AuthActions } from '@shared/store';

@Component({
  selector: 'app-user-header-info',
  templateUrl: './user-header-info.component.html',
  styleUrls: ['./user-header-info.component.scss'],
  standalone: true,
  imports: [MenuComponent],
})
export class UserHeaderInfoComponent implements OnInit {
  envConfig = inject(APP_ENV_CONFIG);

  @Input() currentUser!: User | null;

  @Input() businessAccountId!: string | null;

  dropdownMenuItems: MenuItem[] = [];

  get featureManagementAccessGranted(): boolean {
    if (['develop', 'qa', 'staging'].includes(this.envConfig.name)) {
      console.info(`%cEnvironment ${this.envConfig.name.toUpperCase()} - Feature Management Access Granted!`, 'color: #2b9fad');
      return true;
    } else {
      return false;
    }
  }

  constructor(
    private router: Router,
    private store: Store,
    private modalService: ModalService,
  ) {}

  ngOnInit(): void {
    this.dropdownMenuItems = compact([
      {
        label: 'My Profile',
        command: () => this.viewProfile(),
        type: 'link',
      },
      this.featureManagementAccessGranted
        ? {
            label: 'Feature Management',
            command: () => this.openFeatureFlagManagement(),
            type: 'link',
          }
        : undefined,
      {
        label: 'Logout',
        command: () => this.store.dispatch(AuthActions.logout({})),
        type: 'link',
      },
    ]);
  }

  viewProfile(): void {
    const userId = this.currentUser?.id;
    if (userId) {
      this.router.navigate(['app', 'settings', 'settings', 'team-members', userId, 'team-member-profile']);
    }
  }

  openFeatureFlagManagement() {
    this.modalService.open(FeatureManagementModalComponent, { className: 'entity-form-modal feature-management-modal' });
  }
}
