@let financialAccountDetails = financialAccountDetails$ | async;
@let financialAccountTitle = selectFinancialAccountTitle$ | async;
@let isExternalFinancialAccount = selectIsFinancialAccountExternal$ | async;
@let isIntegratedCardFinancialAccount = selectIsFinancialAccountIntegratedCard$ | async;

@if (financialAccountDetails) {
  <div class="details-page-header-wrapper">
    <div class="page-header">
      <div class="details-page-back-title">
        <a [routerLink]="[pageReturnURL]" class="details-page-back-btn">
          <rkt-icon name="arrow-left" class="details-page-back-btn-icon" />
        </a>
        <h1 class="details-page-title">
          {{ financialAccountTitle }}
        </h1>

        <div
          class="status-tag"
          [class.no-value]="!financialAccountDetails.state"
          [ngClass]="'status-tag-' + financialAccountDetails.state.toLowerCase()"
        >
          {{ financialAccountDetails.state | titlecase }}
        </div>
      </div>
      <app-breadcrumbs [itemList]="breadcrumbList" />
    </div>

    @if (!isEscrowAccount) {
      <div class="page-header-controls" [class.no-actions]="!financialAccountDetails || !isExternalFinancialAccount">
        <app-financial-account-actions [financialAccount]="financialAccountDetails" [financialAccountHolderType]="entityType" />
      </div>
    }
  </div>
} @else {
  <app-details-page-header-placeholder [pageReturnURL]="pageReturnURL"></app-details-page-header-placeholder>
}

<div class="financial-account-balances-limits-row">
  <div class="account-balances">
    @if (financialAccountDetails && !isExternalFinancialAccount && !isEscrowAccount) {
      <app-financial-account-balances
        class="account-balances-container"
        label="Account Balances"
        [accountBalanceValues]="accountBalanceValues"
        [selectedCurrency]="selectedCurrency"
        [currencyList]="financialAccountCurrencyList"
        [isLoading]="isFinancialAccountLoading"
        (setSelectedCurrency)="setAccountCurrency($event)"
      />
    }
  </div>

  <div class="account-limits">
    @if (financialAccountDetails && !isExternalFinancialAccount && !isIntegratedCardFinancialAccount && !isEscrowAccount) {
      <app-transaction-limits
        [isEditAllowed]="financialAccountDetails.accountHolderType !== 'RECIPIENT' && financialAccountDetails.state !== 'DELETED'"
        [scopeId]="financialAccountDetails.id"
        scopeType="FA_KFA"
      />
    }
  </div>
</div>

<app-tabs [tabItems]="tabItems"></app-tabs>

<router-outlet></router-outlet>
