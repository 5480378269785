import { AfterViewChecked, ChangeDetectorRef, Component, Input, OnDestroy } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { ModalService } from '@rocketfinancialcorp/rocket-ui/modal';
import { DatatableColumn, RktTableComponent } from '@rocketfinancialcorp/rocket-ui/table';

import { AcceptedDocumentFilePreviewModalComponent } from '@shared/components';
import { AcceptedDocumentList, AcceptedDocumentListItem } from '@shared/models';
import { FinancialAccountDocumentService } from '@shared/services';
import { ErrorUtils } from '@shared/utils';

@Component({
  selector: 'app-financial-account-accepted-documents',
  templateUrl: './financial-account-accepted-documents.component.html',
  styleUrls: ['./financial-account-accepted-documents.component.scss'],
  standalone: true,
  imports: [RktTableComponent],
})
export class FinancialAccountAcceptedDocumentsComponent implements AfterViewChecked, OnDestroy {
  @Input() financialAccountId!: string;

  documents: AcceptedDocumentListItem[] = [];

  loading = true;

  totalElements = 0;

  columns: DatatableColumn[] = [
    { name: 'Document Name', prop: 'documentName', flexSize: 180 },
    { name: 'Document ID', prop: 'documentId', flexSize: 180 },
    { name: 'Document Version', prop: 'documentVersion', flexSize: 180 },
    { name: 'Accepted', prop: 'isAccepted', flexSize: 140 },
    { name: 'Accepted At', prop: 'acceptedAt', fixedSize: 150, colType: 'date-time' },
  ];

  emptyListMessage = 'No Documents found.';

  private destroy$ = new Subject<void>();

  constructor(
    public ref: ChangeDetectorRef,
    private financialAccountDocumentService: FinancialAccountDocumentService,
    private modalService: ModalService,
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.modalService.dismissAll();
  }

  ngAfterViewChecked(): void {
    this.ref.detectChanges();
  }

  getDocuments(): void {
    this.loading = true;
    this.financialAccountDocumentService
      .getAcceptedDocuments({ financialAccountId: this.financialAccountId })
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (customerListItems) => {
          this.emptyListMessage = 'No Documents found.';
          this.onFetchComplete(customerListItems);
        },
        error: (error) => {
          this.emptyListMessage = 'Unable to retrieve Accepted Documents of the Financial Account';
          this.onFetchComplete();
          ErrorUtils.catchError('financialAccountDocumentService.getAcceptedDocuments error', error);
        },
      });
  }

  onFetchComplete(documentListItems?: AcceptedDocumentList): void {
    const { items = [], totalElements = 0 } = documentListItems ?? {};
    this.documents = items;
    this.totalElements = totalElements;
    this.loading = false;
  }

  onRowClick({ downloadUrl, documentName, documentVersion }: AcceptedDocumentListItem): void {
    const filePreviewModal = this.modalService.open(AcceptedDocumentFilePreviewModalComponent, {
      className: 'entity-form-modal documents-modal',
    });

    filePreviewModal.componentInstance.modalInitData({ downloadUrl, name: documentName, version: documentVersion });
  }
}
