import { NgFor, NgIf } from '@angular/common';
import { Component, ElementRef, Input, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import { TransactionDetails, TransactionHistory } from '@shared/models';

import { HistoryItemComponent } from '../history-item/history-item.component';

@Component({
  selector: 'app-transaction-history',
  templateUrl: 'transaction-history.component.html',
  styleUrls: ['transaction-history.component.scss'],
  standalone: true,
  imports: [NgIf, NgFor, HistoryItemComponent],
})
export class TransactionHistoryComponent implements OnDestroy {
  @ViewChild('contentElementRef') contentElementRef!: ElementRef<HTMLElement>;

  @Input() transactionHistoryItems!: TransactionHistory[];

  @Input() loading = true;

  transactionId!: TransactionDetails['id'];

  private destroy$ = new Subject<void>();

  get firstItemWithSubItems(): string | undefined {
    return this.transactionHistoryItems?.find((item) => item.parentTransactionId)?.id;
  }

  constructor(private activatedRoute: ActivatedRoute) {
    this.activatedRoute.params
      .pipe(tap({ next: (params) => (this.transactionId = params.transactionId) }), takeUntil(this.destroy$))
      .subscribe();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
