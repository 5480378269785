import { NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { RktButtonDirective } from '@rocketfinancialcorp/rocket-ui/button';
import { ActiveModal } from '@rocketfinancialcorp/rocket-ui/modal';

import { AttentionModalComponent } from '../attention-modal/attention-modal.component';

@Component({
  selector: 'app-success-modal',
  templateUrl: './success-modal.component.html',
  standalone: true,
  imports: [RktButtonDirective, AttentionModalComponent, NgIf],
})
export class SuccessModalComponent {
  actionName!: string;

  type!: 'Recipient' | 'TeamMember' | 'FinancialAccount' | 'Transaction' | 'Customer';

  customTitle = '';

  get typeText() {
    switch (this.type) {
      case 'TeamMember':
        return 'Team Member';
      case 'Recipient':
        return 'Recipient Account';
      case 'FinancialAccount':
        return 'Financial Account';
      case 'Transaction':
        return 'Transaction';
      case 'Customer':
        return 'Customer Account';
      default:
        return this.type;
    }
  }

  constructor(public activeModal: ActiveModal) {}
}
