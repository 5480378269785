import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { catchError, map, mergeMap, of, switchMap, tap } from 'rxjs';

import { EarnedWageService } from '@shared/services';

import { EwaProgramActions } from './ewa.actions';
import { selectRouteParams } from '../core';
import { MessagesActions } from '../core/messages';

export const loadEwaProgram$ = createEffect(
  (actions$ = inject(Actions), earnedWageService = inject(EarnedWageService), store = inject(Store)) => {
    return actions$.pipe(
      ofType(EwaProgramActions.loadEwaProgram),
      concatLatestFrom(() => [store.select(selectRouteParams)]),
      mergeMap(([, routeParams]) => {
        return earnedWageService.getEarnedWageProgram(routeParams.id).pipe(
          map((ewaProgram) => {
            return EwaProgramActions.loadEwaProgramSuccess({ ewaProgram });
          }),
          catchError((error) => {
            return of(EwaProgramActions.loadEwaProgramFailure({ error }));
          }),
        );
      }),
    );
  },
  { functional: true },
);

export const loadEwaProgramFailure$ = createEffect(
  (actions$ = inject(Actions)) => {
    return actions$.pipe(
      ofType(EwaProgramActions.loadEwaProgramFailure),
      switchMap(() => of(MessagesActions.displayError({ message: 'Unable to fetch EWA Program.' }))),
    );
  },
  { functional: true },
);

export const loadEwaProgramFailureRedirect$ = createEffect(
  (actions$ = inject(Actions), router = inject(Router)) => {
    return actions$.pipe(
      ofType(EwaProgramActions.loadEwaProgramFailure),
      tap({
        next: () => router.navigateByUrl('/app/earned-wage'),
      }),
    );
  },
  { functional: true, dispatch: false },
);
