import { Observable, map } from 'rxjs';
import {
  formDatepicker,
  formInput,
  formInputPhone,
  formRadioGroup,
  formRow,
  formSelect,
  formSelectCountry,
  formSelectGroup,
} from '@rocketfinancialcorp/rocket-ui/form';

import { Patterns } from '@shared/components/entity/patterns';
import { Industry } from '@shared/models';
import { businessTaxIDValidator } from '@shared/validators';

const createIndividualCustomerOptions = {
  expressions: {
    hide: "model.customerType === 'BUSINESS' || model.accountHolderType !== 'CUSTOMER'",
  },
};

const createBusinessCustomerOptions = {
  expressions: {
    hide: "model.customerType === 'INDIVIDUAL' || model.accountHolderType !== 'CUSTOMER'",
  },
};

export const businessCustomerNameField = [
  formRow([
    formInput({
      key: 'businessName',
      label: 'Business Name on Account',
      props: { required: true, maxLength: 40 },
      ...createBusinessCustomerOptions,
    }),
  ]),
];

export const customerCreateFields = [
  formRow([
    formRadioGroup({
      key: 'customerType',
      label: 'Customer Type',
      props: {
        options: [
          { value: 'INDIVIDUAL', label: 'Individual' },
          { value: 'BUSINESS', label: 'Business' },
        ],
      },
      expressions: {
        hide: 'model.skipTypeSelection',
      },
    }),
  ]),
  formRow([
    formInput({
      key: 'firstName',
      label: 'First name',
      props: { required: true, minLength: 1, maxLength: 40 },
      ...createIndividualCustomerOptions,
    }),
    formInput({ key: 'middleName', label: 'Middle name', props: { minLength: 1, maxLength: 40 }, ...createIndividualCustomerOptions }),
    formInput({
      key: 'lastName',
      label: 'Last name',
      props: { required: true, minLength: 1, maxLength: 40 },
      ...createIndividualCustomerOptions,
    }),
  ]),
  formRow([
    formInput({
      key: 'customerEmailAddress',
      label: 'Email address',
      props: { pattern: Patterns.EMAIL, type: 'email', required: true, minLength: 1, maxLength: 100 },
      validation: {
        messages: {
          pattern: 'Enter a valid email',
        },
      },
      ...createIndividualCustomerOptions,
    }),
    formInputPhone({
      key: 'customerPhoneNumber',
      label: 'Mobile Number',
      props: { required: true, minLength: 10, maxLength: 40, countryRestrictions: [], shouldKeepPhoneNumber: true },
      validators: { validation: [{ name: 'isValidPhoneNumber' }] },
      ...createIndividualCustomerOptions,
    }),
  ]),
  formRow([
    formInput({
      key: 'contactFirstName',
      label: 'First name',
      props: { required: true, minLength: 1, maxLength: 40 },
      ...createBusinessCustomerOptions,
    }),
    formInput({ key: 'contactMiddleName', label: 'Middle name', props: { minLength: 1, maxLength: 40 }, ...createBusinessCustomerOptions }),
    formInput({
      key: 'contactLastName',
      label: 'Last name',
      props: { required: true, minLength: 1, maxLength: 40 },
      ...createBusinessCustomerOptions,
    }),
  ]),
  formRow([
    formInput({
      key: 'contactPrimaryEmail',
      label: 'Email address',
      props: { pattern: Patterns.EMAIL, type: 'email', required: true, minLength: 1, maxLength: 100 },
      validation: {
        messages: {
          pattern: 'Enter a valid email',
        },
      },
      ...createBusinessCustomerOptions,
    }),
    formInputPhone({
      key: 'contactPrimaryPhoneNumber',
      label: 'Mobile Number',
      props: { required: true, minLength: 10, maxLength: 40, countryRestrictions: [], shouldKeepPhoneNumber: true },
      validators: { validation: [{ name: 'isValidPhoneNumber' }] },
      ...createBusinessCustomerOptions,
    }),
  ]),
];

export const individualIDVFields = [
  formRow([
    formDatepicker({ key: 'dateOfBirth', label: 'Date of Birth', props: { placeholder: 'MM/DD/YY' } }),
    formInput({ key: 'cityOfBirth', label: 'City of Birth', props: { maxLength: 60 } }),
    formSelectCountry({ key: 'countryOfBirth', label: 'Country of Birth', props: { placeholder: 'Select' } }),
  ]),
  formRow([
    formRadioGroup({
      key: 'sex',
      label: 'Sex',
      className: 'customer-details-separator',
      props: {
        required: false,
        options: [
          {
            label: 'Male',
            value: 'MALE',
          },
          {
            label: 'Female',
            value: 'FEMALE',
          },
          {
            label: 'Non-Binary',
            value: 'NONBINARY',
          },
        ],
      },
    }),
  ]),
  formRow([
    formInput({
      key: 'socialId',
      label: 'Social ID Number (SSN)',
      props: {
        required: false,
        pattern: Patterns.SOCIAL_SECURITY_NUMBER,
        maxLength: 11,
        minLength: 9,
        maskString: 'XXX-XX-XXXX',
        placeholder: 'XXX-XX-XXXX',
      },
      validation: {
        messages: {
          pattern: 'Please enter a valid SSN',
        },
      },
    }),
    formSelectCountry({
      key: 'socialIdCountryCode',
      label: 'Social ID Issuing Country',
      props: { placeholder: 'Select', countryRestrictions: ['US'], disabled: true },
    }),
    formSelectCountry({
      key: 'citizenshipCountryCode',
      label: 'Citizenship Country',
      props: { placeholder: 'Select' },
    }),
  ]),
  formRow([formInput({ key: 'externalId', label: 'External ID', props: { required: false, minLength: 1, maxLength: 100 } })]),
];

export const businessIDVFields = ({ industries$ }: { industries$: Observable<Industry[] | null> }) => [
  formRow([
    formInput({
      key: 'doingBusinessAsName',
      label: 'Business DBA Name',
      props: { required: false, minLength: 1, maxLength: 100 },
    }),
    formInput({
      key: 'legalName',
      label: 'Legal Name of Business',
      props: { required: false, minLength: 1, maxLength: 100 },
    }),
  ]),
  formRow([
    formInput({
      key: 'taxId',
      label: 'Business Tax ID',
      props: { required: false, minLength: 9, maxLength: 10, maskString: 'XX-XXXXXXX', placeholder: 'XX-XXXXXXX' },
      validators: {
        validation: [businessTaxIDValidator],
      },
    }),
    formSelectCountry({
      key: 'taxIdCountryCode',
      label: 'Business Tax ID Country',
      props: { placeholder: 'Select', countryRestrictions: ['US'], disabled: true },
    }),
  ]),
  formRow([
    formInputPhone({
      key: 'businessPrimaryPhoneNumber',
      label: 'Primary Phone Number of Business',
      props: { required: false, minLength: 10, maxLength: 40, countryRestrictions: [], shouldKeepPhoneNumber: true },
      validators: { validation: [{ name: 'isValidPhoneNumber' }] },
    }),
    formInput({
      key: 'website',
      label: 'Company Website',
      props: { pattern: Patterns.WEBSITE },
      validation: {
        messages: {
          pattern: 'Please enter a valid website URL. Must begin with http:// or https://',
        },
      },
    }),
  ]),
  formRow([
    formDatepicker({ key: 'dateOfFormation', label: 'Date Of Formation', props: { placeholder: 'MM/DD/YY' } }),
    formSelect({
      key: 'legalEntityType',
      label: 'Type of Business',
      props: {
        options: [
          { value: 'SP', label: 'Sole proprietorship' },
          { value: 'GP', label: 'General Partnership' },
          { value: 'LLP', label: 'Limited Liability Partnership (LLP)' },
          { value: 'LLLP', label: 'Limited Liability Limited Partnership (LLLP)' },
          { value: 'LP', label: 'Limited partnership (LP)' },
          { value: 'LLC', label: 'Limited Liability Company (LLC)' },
          { value: 'CORP', label: 'Corporation (Corp.)' },
          { value: 'NONPROFIT', label: 'Nonprofit' },
          { value: 'GOVERNMENT', label: 'Government' },
        ],
      },
    }),
  ]),
  formRow([
    formInput({ key: 'externalId', label: 'External ID', props: { required: false, minLength: 1, maxLength: 100 } }),
    formSelectGroup({
      key: 'industryCode',
      label: 'Industry',
      props: {
        groupBy: 'categoryCode',
        options: industries$.pipe(
          map(
            (industries) =>
              industries?.map((industry) => ({
                value: industry.industryCode,
                label: industry.name,
                categoryCode: industry.categoryCode,
                groupName: industry.categoryName,
              })) ?? [],
          ),
        ),
        required: false,
      },
    }),
  ]),
];
