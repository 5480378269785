import { createReducer, on } from '@ngrx/store';

import { ErrorMessage, InsightReportHistoryStatus } from '@shared/models';
import { InsightDocumentActions } from '@shared/store/insight-document-store/insight-document.actions';

export const insightDocumentHistoryListFeatureKey = 'insightDocumentHistoryList';

export interface InsightDocumentHistoryState {
  insightUnreadDocuments: InsightReportHistoryStatus[];
  isLoading: boolean;
  error: ErrorMessage;
}

export const initialDocumentHistoryState: InsightDocumentHistoryState = {
  insightUnreadDocuments: [],
  isLoading: false,
  error: null,
};

export const reducer = createReducer(
  initialDocumentHistoryState,
  on(InsightDocumentActions.loadInsightDocumentHistory, (state): InsightDocumentHistoryState => {
    return {
      ...state,
      insightUnreadDocuments: [],
      isLoading: true,
      error: null,
    };
  }),
  on(InsightDocumentActions.getInsightDocumentHistorySuccess, (state, { insightUnreadDocuments }): InsightDocumentHistoryState => {
    return {
      ...state,
      insightUnreadDocuments: insightUnreadDocuments,
      isLoading: false,
      error: null,
    };
  }),
  on(InsightDocumentActions.getInsightDocumentHistoryError, (state, action): InsightDocumentHistoryState => {
    return {
      ...state,
      isLoading: false,
      error: action.error,
    };
  }),
);

export const getInsightUnreadDocuments = (state: InsightDocumentHistoryState) => state.insightUnreadDocuments;
export const getLoading = (state: InsightDocumentHistoryState) => state.isLoading;
