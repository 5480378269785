import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, OnInitEffects, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { find, head, isEmpty } from 'lodash-es';
import { of, throwError, timer } from 'rxjs';
import { catchError, concatMap, debounceTime, delayWhen, exhaustMap, filter, map, switchMap, tap } from 'rxjs/operators';

import { MESSAGE, constants } from '@shared/constants';
import {
  AuthService,
  BusinessAccountService,
  CustomerService,
  EarnedWageService,
  FinancialAccountService,
  IdentityVerificationService,
  TransactionBatchesService,
  TransactionService,
  UserService,
} from '@shared/services';
import { AuthActions, LoginPageActions, MessagesActions, ModalsActions, fromAuth, fromRoot } from '@shared/store';

@Injectable()
export class AuthEffects implements OnInitEffects {
  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private userService: UserService,
    private financialAccountService: FinancialAccountService,
    private transactionBatchesService: TransactionBatchesService,
    private earnedWageService: EarnedWageService,
    private transactionService: TransactionService,
    private businessAccountService: BusinessAccountService,
    private identityVerificationService: IdentityVerificationService,
    private customerService: CustomerService,
    private router: Router,
    private store: Store,
  ) {}

  ngrxOnInitEffects() {
    return AuthActions.rehydrateState();
  }

  pageReload() {
    location.reload();
  }

  login$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LoginPageActions.login),
      map((action) => action.userCredentials),
      exhaustMap(({ email, password, tenant }) =>
        this.authService
          .getAuthTenantInfo(tenant)
          .pipe(catchError(() => throwError(() => new Error(MESSAGE.AUTH_PROVIDER_VERIFICATION_ERROR))))
          .pipe(
            exhaustMap(({ providerTenantId, authProvider, special }) => {
              this.store.dispatch(AuthActions.setSponsorAuthTenant({ isSponsorTenant: special ?? false }));

              if (authProvider == 'FIREBASE' || authProvider == 'GCP_IDENTITY_PLATFORM') {
                return this.authService
                  .googleCloudLogin(email, password, providerTenantId)
                  .pipe(catchError((error) => throwError(() => new Error(error.message))));
              } else {
                return throwError(() => new Error(`Authentication provider ${authProvider} is not supported`));
              }
            }),
            exhaustMap((accessToken) =>
              this.authService
                .exchangeToken(accessToken, tenant)
                .pipe(catchError(() => throwError(() => new Error(MESSAGE.UNKNOWN_ERROR)))),
            ),
            exhaustMap(() => this.userService.getUserDetails().pipe(catchError(() => throwError(() => new Error(MESSAGE.UNKNOWN_ERROR))))),
            switchMap((user) => {
              return this.businessAccountService.getLinkedBusinessAccounts({ page: 0, size: constants.DEFAULT_PAGE_SIZE }).pipe(
                map((linkedBusinessAccounts) => {
                  const businessAccount = find(linkedBusinessAccounts, ['parent', true]) ?? head(linkedBusinessAccounts);

                  return { user, businessAccount: businessAccount ?? null };
                }),
                catchError(() => {
                  return of({ user, businessAccount: null });
                }),
              );
            }),
            switchMap(({ user, businessAccount }) => {
              if (!businessAccount) {
                return of({ user, businessAccount });
              }

              return this.userService.getUserFullDetails({ businessAccountId: businessAccount.id, userId: user.id }).pipe(
                map((userDetailedResponse) => {
                  return { user: { ...user, role: userDetailedResponse.roles[0].name }, businessAccount };
                }),
              );
            }),
            map(({ user, businessAccount }) => {
              return AuthActions.loginSuccess({ user, businessAccount, tenant });
            }),
            catchError((error) => {
              return of(AuthActions.loginFailure({ error: error.message }));
            }),
          ),
      ),
    );
  });

  businessAccountDetails$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.refreshTokenSuccess),
      concatLatestFrom(() => [
        this.store.select(fromAuth.selectBusinessAccount),
        this.store.select(fromAuth.selectBusinessAccountDetails),
        this.store.select(fromAuth.selectSponsorTenant),
      ]),
      filter(([, , businessAccountDetails, isSpecialTenant]) => !(businessAccountDetails && !isSpecialTenant)),
      exhaustMap(([, businessAccount]) => {
        if (!businessAccount) {
          return of(AuthActions.businessAccountDetails({ businessAccountDetails: null }));
        }

        return this.businessAccountService.getBusinessAccount(businessAccount.id).pipe(
          map((response) => AuthActions.businessAccountDetails({ businessAccountDetails: response })),
          catchError(() => of(AuthActions.businessAccountDetails({ businessAccountDetails: null }))),
        );
      }),
    );
  });

  loginSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.businessAccountDetails),
      concatLatestFrom(() => [this.store.select(fromAuth.selectSponsorTenant)]),
      map(([, isSponsorTenant]) => {
        if (isSponsorTenant) {
          return AuthActions.showSplashScreen();
        }

        return LoginPageActions.loggedInRedirect();
      }),
    );
  });

  loggedInRedirect$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(LoginPageActions.loggedInRedirect),
        concatLatestFrom(() => [this.store.select(fromRoot.selectQueryParams), this.store.select(fromAuth.selectTenant)]),
        tap({
          next: ([, queryParams, tenant]) => {
            if (isEmpty(this.authService.realmName)) {
              this.router.navigateByUrl(`${tenant}/app`, { skipLocationChange: false }).then(() => {
                this.pageReload();
              });
            } else {
              this.router.navigateByUrl(queryParams?.returnUrl || constants.HOME_ROUTE);
            }
          },
        }),
      );
    },
    { dispatch: false },
  );

  businessAccountCheck$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.loginSuccess),
      filter(({ businessAccount }) => !businessAccount),
      map(() => {
        return MessagesActions.displayWarning({ message: MESSAGE.NO_BUSINESS_ACCOUNT, title: '' });
      }),
    );
  });

  logout$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.logout),
      exhaustMap(({ params }) => {
        return this.authService.signOut().pipe(map(() => AuthActions.logoutCompleted({ params })));
      }),
    );
  });

  logoutCompleted$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.logoutCompleted),
      tap({
        next: ({ params }) => {
          const { skipNavigation, isReturnUrl } = params ?? {};
          const returnUrl = this.router.url;

          if (!skipNavigation) {
            const routerQueryParams =
              isReturnUrl && ![constants.HOME_ROUTE, '404', '/'].includes(returnUrl) ? { queryParams: { returnUrl } } : undefined;
            this.router.navigateByUrl(constants.AUTH_ROUTE, { replaceUrl: true, ...routerQueryParams });
          }

          this.financialAccountService.setFilterParams({});
          this.financialAccountService.setListFilterParams({});
          this.transactionBatchesService.setFilterParams({});
          this.transactionService.setFilterParams({});
          this.earnedWageService.setFilterParams({});
          this.transactionService.setFilterParams({}, true);
          this.customerService.setBusinessFilterParams({});
          this.customerService.setIndividualFilterParams({});
          this.identityVerificationService.setIdvFilterParams({});
          this.businessAccountService.setBusinessAccountFilterParams({});
        },
      }),
      map(() => ModalsActions.dismissAll()),
    );
  });

  refreshToken$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.refreshToken, AuthActions.loginSuccess, AuthActions.rehydrateState),
      concatLatestFrom(() => [
        this.store.select(fromAuth.selectLoggedIn),
        this.store.select(fromAuth.selectTenant),
        this.store.select(fromAuth.selectBusinessAccount),
      ]),
      concatMap(([, isLoggedIn, tenant, businessAccount]) => {
        if (isLoggedIn && businessAccount?.id) {
          return this.authService.refreshToken(businessAccount.id, tenant!).pipe(
            map((tokenInfo) => {
              return AuthActions.refreshTokenSuccess({ tokenInfo });
            }),
            catchError(() => of(AuthActions.logout({ params: { isReturnUrl: true } }))),
          );
        } else {
          return of(AuthActions.noActiveSession());
        }
      }),
    );
  });

  refreshTokenSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.refreshTokenSuccess),
      concatLatestFrom(() => this.store.select(fromAuth.selectTokenExpireInMilliseconds)),
      delayWhen(([, expiresIn]) => timer(expiresIn * 0.9)),
      map(() => AuthActions.refreshToken()),
    );
  });

  splashScreenAnimationEnd$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LoginPageActions.splashScreenAnimationEnd),
      debounceTime(500),
      map(() => LoginPageActions.loggedInRedirect()),
    );
  });
}
