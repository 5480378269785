import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit, inject } from '@angular/core';
import { isObject } from 'lodash-es';
import { Observable, Subject, catchError, finalize, of, takeUntil } from 'rxjs';
import { RktButtonDirective } from '@rocketfinancialcorp/rocket-ui/button';
import { RktIconComponent } from '@rocketfinancialcorp/rocket-ui/icon';
import { ModalService } from '@rocketfinancialcorp/rocket-ui/modal';

import { PageLeaveConfirmationModalComponent } from '@shared/components';
import { MESSAGE } from '@shared/constants';
import { AddNoteParams, Note } from '@shared/models';
import { NoteService } from '@shared/services';
import { ErrorUtils } from '@shared/utils';

import { AccessControlPipe } from '../../../pipes/access-control.pipe';
import { TextWithPlaceholderComponent } from '../../text-with-placeholder/text-with-placeholder.component';
import { NotesFeedAddNoteComponent } from '../notes-feed-add-note/notes-feed-add-note.component';
import { NotesFeedItemComponent } from '../notes-feed-item/notes-feed-item.component';

@Component({
  selector: 'app-notes-feed',
  templateUrl: './notes-feed.component.html',
  standalone: true,
  imports: [
    RktButtonDirective,
    TextWithPlaceholderComponent,
    NgIf,
    NotesFeedAddNoteComponent,
    NgFor,
    NotesFeedItemComponent,
    AsyncPipe,
    AccessControlPipe,
    RktIconComponent,
  ],
})
export class NotesFeedComponent implements OnInit, OnDestroy {
  noteService = inject(NoteService);

  modalService = inject(ModalService);

  @Input() loading = false;

  @Input() entityId!: Note['entityId'];

  @Input() entityType!: Note['entityType'];

  addNote = false;

  notes$: Observable<Note[]> = of([]);

  viewType: 'tiles' | 'list' = 'tiles';

  newNoteLoading = false;

  noteAddError = '';

  private destroy$ = new Subject<void>();

  ngOnInit() {
    this.fetchNotes();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  fetchNotes() {
    this.noteService
      .getNotes({ entityType: this.entityType, entityId: this.entityId })
      .pipe(
        finalize(() => {
          this.loading = false;
          this.newNoteLoading = false;
        }),
        catchError((errorRes) => {
          ErrorUtils.catchError('noteService.getNotes', errorRes);
          return [];
        }),
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: (notes) => {
          this.notes$ = of(notes);
        },
      });
  }

  onViewTypeClick(): void {
    this.viewType = this.viewType === 'tiles' ? 'list' : 'tiles';
  }

  onNoteAdd({ contentText = '', attachments }: Partial<AddNoteParams>): void {
    this.noteAddError = '';
    this.newNoteLoading = true;

    this.noteService
      .addNote({
        entityId: this.entityId,
        entityType: this.entityType,
        contentText,
        attachments,
      })
      .pipe(
        finalize(() => {
          this.loading = false;
          this.newNoteLoading = false;
        }),
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: () => {
          this.fetchNotes();
          this.addNote = false;
        },
        error: (error?: string | number | HttpErrorResponse) => {
          this.noteAddError =
            (isObject(error) && error.status === 403) || error === 403 ? MESSAGE.PERMISSION_DENIED : MESSAGE.GENERIC_ERROR;
          ErrorUtils.catchError('noteService.addNote', this.noteAddError);
        },
      });
  }

  onNoteAddDiscard(isUnsavedData: boolean) {
    if (!isUnsavedData) {
      this.addNote = false;
      return;
    }

    const modalRef = this.modalService.open(PageLeaveConfirmationModalComponent, {
      className: 'confirm-modal',
    });

    return modalRef.result.then(
      (result) => {
        if (result) {
          this.addNote = false;
        }
      },
      () => false,
    );
  }
}
