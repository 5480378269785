import { Injectable } from '@angular/core';
import CryptoJS from 'crypto-es';

import { constants } from '@shared/constants';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  public setItem(key: string, value: unknown): void {
    const encryptedValue = CryptoJS.AES.encrypt(JSON.stringify(value), constants.ENCRYPTION_KEY).toString();
    localStorage.setItem(key, encryptedValue);
  }

  public getItem<T>(key: string): T | undefined {
    const storageValue = localStorage.getItem(key);

    if (!storageValue) {
      return undefined;
    }

    const decryptedValue = CryptoJS.AES.decrypt(storageValue, constants.ENCRYPTION_KEY);
    const value = decryptedValue.toString(CryptoJS.enc.Utf8);

    if (value) {
      return JSON.parse(decryptedValue.toString(CryptoJS.enc.Utf8));
    } else {
      return undefined;
    }
  }

  public removeItem(key: string): void {
    localStorage.removeItem(key);
  }

  public isKeyExist(key: string): boolean {
    return localStorage.getItem(key) !== null;
  }
}
