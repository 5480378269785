import { NgIf } from '@angular/common';
import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Store } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { format, parse } from 'date-fns';
import { compact, upperFirst } from 'lodash-es';
import { Subject, catchError, map, of, switchMap, take, takeUntil } from 'rxjs';
import { RktButtonDirective } from '@rocketfinancialcorp/rocket-ui/button';
import { RktFormComponent, RktFormFieldConfig, formInput, formRow } from '@rocketfinancialcorp/rocket-ui/form';
import { RktIconComponent } from '@rocketfinancialcorp/rocket-ui/icon';
import { ActiveModal } from '@rocketfinancialcorp/rocket-ui/modal';

import {
  Patterns,
  accountCategoryFields,
  addressFields,
  bankAccountFields,
  cardFields,
  financialAccountTypeFields,
  integratedCardFields,
} from '@shared/components';
import {
  AccountHolderType,
  EntityFinancialAccountDetails,
  FinancialAccountCategory,
  FinancialAccountCreateData,
  FinancialAccountCreateModel,
  FinancialAccountDetails,
  FinancialAccountListItem,
  FinancialAccountUpdateData,
  FormModel,
} from '@shared/models';
import { CardAccountService, FinancialAccountService, NoteService, NotificationService } from '@shared/services';
import { CardAccountActions, fromFinancialAccount } from '@shared/store';
import { ErrorUtils, safeJSONparse } from '@shared/utils';
import { cardExpiryCheck, emptySpacesValidator } from '@shared/validators';

import { FormSelectFieldComponent } from '../../form-select-field/form-select-field.component';
import { NoteFormItemComponent } from '../../notes/note-form-item/note-form-item.component';
import { bankAccountCreateData, cardCreateData } from '../add-entity-with-financial-account-modal/helpers';

interface AddFinancialAccountModalParams {
  accountHolderType: AccountHolderType;
  accountTypes?: FinancialAccountCategory[];
  id: string;
  editData?: FinancialAccountDetails;
  isCardsFeatureEnabled?: boolean;
}

@Component({
  selector: 'app-add-financial-account',
  templateUrl: './add-financial-account.component.html',
  styleUrls: ['./add-financial-account.component.scss'],
  standalone: true,
  imports: [
    RktButtonDirective,
    FormsModule,
    ReactiveFormsModule,
    RktFormComponent,
    NgIf,
    FormSelectFieldComponent,
    NoteFormItemComponent,
    RktIconComponent,
  ],
})
export class AddFinancialAccountComponent implements AfterViewChecked, OnDestroy {
  entityId!: string;

  entityType!: AccountHolderType;

  accountTypes: FinancialAccountCategory[] = [];

  formModel: FormModel<FinancialAccountCreateModel> = {
    defaultFlag: false,
    category: 'EXTERNAL',
    cardOrBankAccount: 'bankAccount',
    name: undefined,
  };

  accountCategoryFields: FormlyFieldConfig[] = [];

  financialAccountFields = [
    formRow([
      formInput({
        key: 'name',
        label: 'Financial Account Nam\u0435',
        props: {
          pattern: Patterns.GENERIC_NAME,
          required: true,
        },
        validation: { messages: { pattern: 'Enter a valid Financial Account Nam\u0435' } },
      }),
    ]),
  ];

  cardFields = cardFields;

  // Integrated Cards
  integratedCardForm = new FormGroup({});

  integratedCardFields: RktFormFieldConfig[] = [];

  bankFields = bankAccountFields;

  addressFields = addressFields;

  financialAccountForm = new FormGroup({});

  cardForm = new FormGroup({});

  cardInfoForm = new FormGroup({});

  bankForm = new FormGroup({});

  addressForm = new FormGroup({});

  financialAccountTypeFields: FormlyFieldConfig[] = [];

  parentFinancialAccount?: string;

  parentFinancialAccountListPageNumber$ = new Subject<number>();

  parentFinancialAccountListLoading = false;

  parentFinancialAccountListItems: { label: string; value: string }[] = [];

  parentFinancialAccountListHasNext = true;

  parentFinancialAccountListPageNumber = 0;

  formError = '';

  formLoading = false;

  note = new FormControl('', [emptySpacesValidator]);

  isEdit = false;

  financialAccountId?: string;

  cardProducts$ = this.store.select(fromFinancialAccount.selectCardProductList);

  private destroy$ = new Subject<void>();

  get isInternalAvailable(): boolean {
    return this.accountTypes.includes('INTERNAL');
  }

  get isIntegratedAvailable(): boolean {
    return this.accountTypes.includes('INTEGRATED');
  }

  get isCard(): boolean {
    return this.formModel.cardOrBankAccount === 'card';
  }

  get isBank(): boolean {
    return this.formModel.cardOrBankAccount === 'bankAccount';
  }

  get isInternal(): boolean {
    return this.formModel.category === 'INTERNAL';
  }

  get isIntegratedCard(): boolean {
    return this.formModel.category === 'INTEGRATED' && this.formModel.cardOrBankAccount === 'card';
  }

  get isDisabled() {
    return (
      this.formLoading ||
      !this.financialAccountForm.valid ||
      (this.isBank && !this.isInternal && this.bankForm.invalid) ||
      (this.isCard && !this.isIntegratedCard && this.cardForm.invalid) ||
      (this.isCard && !this.isIntegratedCard && this.cardInfoForm.invalid) ||
      (this.isIntegratedCard && this.integratedCardForm.invalid) ||
      (this.isBank && this.addressForm.invalid) ||
      (this.isInternal && !this.parentFinancialAccount)
    );
  }

  constructor(
    public ref: ChangeDetectorRef,
    public activeModal: ActiveModal,
    private financialAccountService: FinancialAccountService,
    private cardAccountService: CardAccountService,
    private noteService: NoteService,
    private notificationService: NotificationService,
    private store: Store,
  ) {
    this.parentFinancialAccountListPageNumber$
      .pipe(
        switchMap((pageNumber) => {
          this.parentFinancialAccountListLoading = true;

          return this.financialAccountService.getFinancialAccounts({
            page: pageNumber,
            size: 10,
            state: ['ACTIVE'],
            accountTypes: ['INTERNAL'],
            accountHolderTypes: ['BUSINESS_ACCOUNT'],
            accountRoles: ['DEFAULT'],
          });
        }),
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: (financialAccounts) => {
          this.parentFinancialAccountListItems = this.mapParentFinancialAccountListItems(
            financialAccounts.items,
            financialAccounts.hasNext!,
          );
          this.parentFinancialAccountListLoading = false;
        },
        error: () => {
          this.parentFinancialAccountListLoading = false;
        },
      });
  }

  public ngAfterViewChecked(): void {
    this.ref.detectChanges();
  }

  public ngOnDestroy(): void {
    this.cardForm.markAsUntouched();
    this.cardInfoForm.markAsUntouched();
    this.bankForm.markAsUntouched();
    this.integratedCardForm.markAsUntouched();

    this.destroy$.next();
    this.destroy$.complete();
  }

  public modalInitData(data: AddFinancialAccountModalParams): void {
    const { accountHolderType, accountTypes, id, editData, isCardsFeatureEnabled } = data;
    this.entityId = id;
    this.entityType = accountHolderType;

    if (isCardsFeatureEnabled) {
      this.store.dispatch(CardAccountActions.cardIssueFormInit({ requestParams: { size: 100 } }));
      this.accountTypes = compact(['EXTERNAL', 'INTERNAL', isCardsFeatureEnabled ? 'INTEGRATED' : undefined]);
      this.accountCategoryFields = accountCategoryFields({ isIntegratedAvailable: this.isIntegratedAvailable ?? false });
      this.integratedCardFields = integratedCardFields({ products$: this.cardProducts$ });
    } else {
      this.accountTypes = compact(['EXTERNAL', 'INTERNAL']);
      this.accountCategoryFields = accountCategoryFields({});
    }

    if (editData) {
      this.isEdit = true;
      this.financialAccountId = editData.id;
      this.note.disable();

      const address = editData.bankAccount ? editData.bankAccount.billingAddress : editData.card?.billingAddress;

      this.formModel = {
        ...this.formModel,
        isEdit: true,
        category: editData.category,
        cardOrBankAccount: editData.bankAccount ? 'bankAccount' : 'card',
        name: editData.name,
        card: editData.card
          ? {
              firstName: editData.card.firstName,
              middleName: editData.card.middleName,
              lastName: editData.card.lastName,
              nameOnCard: editData.card.fullNameOnCard,
              cardNumber: `**** ${editData.card.cardNumberTail}`,
              expiry: editData.card.expiry,
              productId: editData.accountData?.cardProductId,
              solution: editData.cardProviderDetails?.solution,
              shippingMethod: editData.cardProviderDetails?.shippingMethod,
              issuedLanguageCode: editData.cardProviderDetails?.issuedLanguageCode,
              flags: {
                defaultFlag: editData.defaultFlag,
              },
              acceptedTermsDate: editData.cardProviderDetails?.acceptedTermsDate,
              acceptedAgreement: !!editData.cardProviderDetails?.acceptedTermsDate,
            }
          : undefined,
        bankAccount: editData.bankAccount
          ? {
              bankName: editData.bankAccount.bankName!,
              routingNo: editData.bankAccount.routingNo!,
              nameOnAccount: editData.bankAccount.nameOnAccount!,
              accountNumber: `**** ${editData.bankAccount.accountNumberTail!}`,
              accountNumberConfirm: `**** ${editData.bankAccount.accountNumberTail!}`,
            }
          : undefined,
        subtype: editData.subtype,
        defaultFlag: editData.defaultFlag,

        ...address,
      };
      if (editData.category === 'INTERNAL') {
        this.parentFinancialAccount = editData.parentId;
      }
    }

    if (accountTypes?.length) {
      this.accountTypes = accountTypes;
    }

    if (!editData) {
      this.onAccountHolderChange({ accountHolderType });
    }
  }

  public onFinancialAccountTypeChanged(): void {
    const { cardOrBankAccount } = this.formModel;
    if (cardOrBankAccount === 'card') {
      this.updatedFormData({
        bankAccount: undefined,
        addressLine1: undefined,
        addressLine2: undefined,
        postalCode: undefined,
        country: undefined,
        state: undefined,
        city: undefined,
      });
      this.bankForm.markAsUntouched();
    } else {
      this.updatedFormData({
        card: undefined,
        addressLine1: undefined,
        addressLine2: undefined,
        postalCode: undefined,
        country: undefined,
        state: undefined,
        city: undefined,
      });
      this.cardForm.markAsUntouched();
      this.cardInfoForm.markAsUntouched();
    }
  }

  public onAccountHolderChange(data: unknown): void {
    const { accountHolderType } = data as { accountHolderType: AccountHolderType };

    if (!accountHolderType) {
      return;
    }

    this.updatedFormData({
      cardOrBankAccount: 'bankAccount',
    });

    this.financialAccountTypeFields = financialAccountTypeFields({});
  }

  public onAccountCategoryChange(data: unknown): void {
    const { category } = data as { category: FinancialAccountCategory };

    if (!category) {
      return;
    }

    if (category === 'INTERNAL') {
      this.parentFinancialAccountListPageNumber$.next(0);
      this.updatedFormData({
        cardOrBankAccount: 'bankAccount',
      });

      this.financialAccountTypeFields = financialAccountTypeFields({
        isBankAccountOnly: true,
      });
    } else if (category === 'INTEGRATED') {
      this.updatedFormData({
        cardOrBankAccount: 'card',
      });

      this.financialAccountTypeFields = financialAccountTypeFields({
        isCardOnly: true,
      });
    } else {
      this.updatedFormData({
        cardOrBankAccount: 'bankAccount',
      });

      this.financialAccountTypeFields = financialAccountTypeFields({});
    }

    this.parentFinancialAccountListItems = [];
  }

  public onCardChange(): void {
    const validationMessage = cardExpiryCheck(this.formModel.card?.expiry);
    const formEl = this.cardInfoForm.get('card.expiry')!;
    if (validationMessage) {
      formEl.setErrors({
        isValidExpiryDate: {
          message: validationMessage,
        },
      });
    }
  }

  public onAccountNumberChange(): void {
    const { accountNumber, accountNumberConfirm } = this.formModel.bankAccount ?? {};
    const formEl = this.bankForm.get('bankAccount.accountNumberConfirm')!;
    formEl.setErrors(null);
    if (accountNumber !== accountNumberConfirm) {
      formEl.setErrors({ matchError: { message: 'Oops, it looks like the account numbers do not match. Please re-enter.' } });
    }
  }

  onParentFinancialAccountSelectScrollToEnd(): void {
    if (this.parentFinancialAccountListLoading || !this.parentFinancialAccountListHasNext) {
      return;
    }
    const pageNumber = this.parentFinancialAccountListPageNumber + 1;
    this.parentFinancialAccountListPageNumber = pageNumber;
    this.parentFinancialAccountListPageNumber$.next(pageNumber);
  }

  onParentFinancialAccountSelect(id: unknown) {
    this.parentFinancialAccount = id as string;
  }

  onSubmit() {
    const entityId = this.entityId;
    const entityType = this.entityType;
    const note = this.note.value ?? '';
    this.formError = '';

    if (
      (this.isBank && !this.isInternal && this.bankForm.invalid) ||
      (this.isCard && !this.isIntegratedCard && this.cardInfoForm.invalid) ||
      (this.isCard && this.isIntegratedCard && this.integratedCardForm.invalid) ||
      (this.isBank && this.addressForm.invalid) ||
      (this.isInternal && !this.parentFinancialAccount)
    ) {
      return;
    }

    this.formLoading = true;

    if (this.isEdit) {
      this.financialAccountService
        .updateFinancialAccount({
          financialAccountId: this.financialAccountId!,
          financialAccountData: this.financialAccountUpdateData(),
        })
        .subscribe({
          next: (financialAccount) => {
            this.notificationService.displaySuccess(`Financial account updated successfully.`, 'Success');
            this.formLoading = false;
            this.activeModal.close(financialAccount);
          },
          error: (error) => {
            this.handleError(error, 'financialAccountService.updateFinancialAccount');
          },
        });
    } else {
      if (this.isIntegratedCard) {
        this.addIntegratedCardAccount();
        return;
      }

      this.financialAccountService
        .addEntityFinancialAccount(entityId, entityType, this.financialAccountCreateData())
        .pipe(
          switchMap((financialAccountResponse) => this.addNoteToFinancialAccount(financialAccountResponse, note)),
          take(1),
          takeUntil(this.destroy$),
        )
        .subscribe({
          next: ({ financialAccount, noteResponse }) => this.onSubmitSuccess(financialAccount, noteResponse),
          error: (error) => {
            this.handleError(error, 'financialAccountService.addEntityFinancialAccount');
          },
        });
    }
  }

  addNoteToFinancialAccount(financialAccount: EntityFinancialAccountDetails, note?: string) {
    if (!note) {
      return of({ financialAccount, noteResponse: true });
    }

    return this.noteService
      .addNote(
        {
          entityId: financialAccount.id,
          entityType: 'FINANCIAL_ACCOUNT',
          contentText: note,
        },
        'Financial account created successfully. Unable to create note for Financial account.',
      )
      .pipe(
        catchError((error) => {
          ErrorUtils.catchError('noteService.addNote error', error);
          return of(false);
        }),
        map((noteResponse) => ({ financialAccount, noteResponse })),
      );
  }

  addIntegratedCardAccount(): void {
    const { card, name } = this.formModel;
    const { productId, shippingMethod, issuedLanguageCode, nameOnCard, flags, acceptedTermsDate } = card ?? {};

    this.formLoading = true;

    this.cardAccountService
      .issueCardAccount({
        name: name!,
        subtype: 'DEBIT',
        defaultFlag: flags?.['defaultFlag'] ?? false,
        accountHolderId: this.entityId,
        card: {
          startingNumbers: '9100101',
          productId: productId!,
          shippingMethod: shippingMethod!,
          extraEmbossingLine: 'Rocket',
          referralSource: 'rocketfncl.com',
          acceptedTermsDate: format(parse(acceptedTermsDate!, 'MM/dd/yyyy', new Date()), 'yyyy-MM-dd'),
          alertTypes: 'NEITHER',
          issuedLanguageCode,
          nameOnCard,
        },
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (financialAccount) => this.onSubmitSuccess(financialAccount, true),
        error: (error) => {
          this.handleError(error, 'cardAccountService.issueCardAccount');
        },
      });
  }

  private financialAccountCreateData(): FinancialAccountCreateData {
    const { cardOrBankAccount, bankAccount, card, addressLine1, addressLine2, postalCode, country, state, city, category, name, subtype } =
      this.formModel;

    return {
      cardOrBankAccount,
      category,
      name,
      subtype: cardOrBankAccount === 'bankAccount' ? subtype : 'DEBIT',
      parentId: category === 'INTERNAL' ? this.parentFinancialAccount : undefined,
      bankAccount:
        cardOrBankAccount === 'bankAccount' && category !== 'INTERNAL'
          ? bankAccountCreateData({
              bankAccount: bankAccount!,
              address: {
                addressLine1: addressLine1!,
                addressLine2,
                postalCode: postalCode!,
                country: country!,
                state: state!,
                city: city!,
              },
            })
          : undefined,
      card:
        cardOrBankAccount === 'card' && category && !['INTERNAL', 'INTEGRATED'].includes(category)
          ? cardCreateData({
              card: card!,
              address: {
                addressLine1: addressLine1!,
                addressLine2,
                postalCode: postalCode!,
                country: country!,
                state: state!,
                city: city!,
              },
            })
          : undefined,
    };
  }

  private financialAccountUpdateData(): FinancialAccountUpdateData {
    const { name, bankAccount, card, category, addressLine1, addressLine2, city, state, postalCode, country } = this.formModel;

    if (category === 'INTERNAL' || this.isIntegratedCard) return { name };

    const data = {
      name,
      firstName: card?.firstName,
      middleName: card?.middleName,
      lastName: card?.lastName,
      nameOnAccount: bankAccount?.nameOnAccount,
      cardNumber: card?.cardNumber,
      expiry: card?.expiry,
    };

    if (category === 'EXTERNAL' && addressLine1 && city && state && country && postalCode) {
      return {
        ...data,
        billingAddress: {
          addressLine1: addressLine1,
          addressLine2,
          city: city,
          state: state,
          country: country,
          postalCode: postalCode,
        },
      };
    }

    return data;
  }

  private updatedFormData(data: Partial<FinancialAccountCreateModel>): void {
    this.formModel = {
      ...this.formModel,
      ...data,
    };
  }

  public onNoteFieldBlur(): void {
    this.note.setValue(this.note.value?.trim() ?? '');
  }

  mapParentFinancialAccountListItems(financialAccounts: FinancialAccountListItem[], hasNext: boolean): { label: string; value: string }[] {
    const financialAccountItems = financialAccounts.map((financialAccount) => ({
      label: `${financialAccount.name} ${financialAccount.displayName}` || '',
      value: financialAccount.id,
    }));
    this.parentFinancialAccountListHasNext = hasNext;

    return [...this.parentFinancialAccountListItems, ...financialAccountItems];
  }

  onSubmitSuccess(financialAccount: EntityFinancialAccountDetails, noteResponse: boolean) {
    if (noteResponse) {
      this.notificationService.displaySuccess(`Financial account created successfully.`, 'Success');
    }
    this.formLoading = false;
    this.activeModal.close(financialAccount);
  }

  private handleError(error: string | number, serviceInfo: string): void {
    const isErrorMessage = typeof error === 'string' && !safeJSONparse(error);

    this.formError = this.parseError(isErrorMessage ? error : ErrorUtils.parseExtendedError(error));

    this.formLoading = false;
    ErrorUtils.catchError(`${serviceInfo} error`, error);
  }

  private parseError(error: string) {
    const matches = error.matchAll(/message='(?<msg>[^']+)'/gi);

    const messages = Array.from(matches, (match) => {
      const { msg } = match?.groups ?? {};
      const errorMsg = upperFirst(msg);
      return errorMsg.slice(-1) === '.' ? errorMsg : `${errorMsg}.`;
    });

    if (!messages.length) {
      const errorMessage = upperFirst(error);
      return errorMessage.slice(-1) === '.' ? errorMessage : `${errorMessage}.`;
    }

    return messages.join('\n');
  }
}
