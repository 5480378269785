import { AsyncPipe, LowerCasePipe, NgFor, NgIf, TitleCasePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { RktButtonDirective } from '@rocketfinancialcorp/rocket-ui/button';
import { ActiveModal } from '@rocketfinancialcorp/rocket-ui/modal';

import { VerificationStatus } from '@shared/models';

import { DateFormatPipe } from '../../pipes/date-format.pipe';
import { NotAvailablePipe } from '../../pipes/not-available.pipe';
import { TeamMemberNamePipe } from '../../pipes/team-member-name.pipe';

@Component({
  selector: 'app-account-verification-history',
  templateUrl: './account-verification-history.component.html',
  styleUrls: ['./account-verification-history.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [RktButtonDirective, NgFor, NgIf, AsyncPipe, LowerCasePipe, TitleCasePipe, NotAvailablePipe, DateFormatPipe, TeamMemberNamePipe],
})
export class AccountVerificationHistoryComponent {
  activeModal = inject(ActiveModal);

  historyItems?: VerificationStatus[];
}
