import { NgIf, NgTemplateOutlet } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { get, uniq } from 'lodash-es';

import { FinancialAccountListItem, SolutionAllowedTypes, TransactionDetails, TransactionSolution } from '@shared/models';

import { BusinessFinancialAccountSelectionComponent } from '../business-financial-account-selection/business-financial-account-selection.component';
import { EntityFinancialAccountSelectionComponent } from '../entity-financial-account-selection/entity-financial-account-selection.component';

@Component({
  selector: 'app-move-money-financial-account-selection',
  templateUrl: 'move-money-financial-account-selection.component.html',
  standalone: true,
  imports: [NgIf, NgTemplateOutlet, EntityFinancialAccountSelectionComponent, BusinessFinancialAccountSelectionComponent],
})
export class MoveMoneyFinancialAccountSelectionComponent implements OnChanges {
  @ViewChild(EntityFinancialAccountSelectionComponent) entityFinancialAccountSelection!: EntityFinancialAccountSelectionComponent;

  @ViewChild(BusinessFinancialAccountSelectionComponent) businessFinancialAccountSelection!: BusinessFinancialAccountSelectionComponent;

  @Input() transaction?: TransactionDetails;

  @Input() type!: 'not-business' | 'business';

  @Input() selectedMoveHowType?: TransactionSolution;

  @Input() selectedMoveDirection?: string;

  @Input() allowedTypes?: SolutionAllowedTypes | null;

  @Input() isMoveFieldsDisabled?: boolean;

  @Input() stickyHeader = true;

  @Input() selectedDebitFinancialAccount?: FinancialAccountListItem;

  @Input() isCardAccount = false;

  @Input() isRowExpanded?: boolean;

  @Output() selectedFinancialAccount = new EventEmitter<FinancialAccountListItem>();

  @Output() listUpdated = new EventEmitter();

  get accountTypes(): string[] | undefined {
    if (!this.allowedTypes || !this.selectedMoveHowType || !this.selectedMoveDirection) {
      return;
    }

    const transactionType = this.selectedMoveDirection === 'push' ? 'SEND' : 'REQUEST';
    const solutionAllowedTypes = get(this.allowedTypes, `${this.selectedMoveHowType}.${transactionType}`);
    return solutionAllowedTypes ? uniq(solutionAllowedTypes.map((typeName) => typeName.split(':')[0])) : undefined;
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { currentValue, previousValue } = changes.isRowExpanded || {};
    if (currentValue !== previousValue) {
      this.entityFinancialAccountSelection?.closeMenu();
      this.businessFinancialAccountSelection?.closeMenu();
    }
  }

  resetValue(): void {
    this.entityFinancialAccountSelection?.resetValue();
    this.businessFinancialAccountSelection?.resetValue();
  }
}
