import { ChangeDetectionStrategy, Component, input, output, signal } from '@angular/core';

import { CollapsibleRowComponent } from '@shared/components';
import { EnhanceProfileDetails, LinxAccountType, LinxEntityType } from '@shared/constants';
import { LinxEnhanceAccountDetails } from '@shared/models';

import { EnhanceProfileEditComponent } from './enhance-profile-edit/enhance-profile-edit.component';
import { EnhanceProfilePreviewComponent } from './enhance-profile-preview/enhance-profile-preview.component';

@Component({
  selector: 'app-enhance-profile-step',
  standalone: true,
  imports: [EnhanceProfilePreviewComponent, CollapsibleRowComponent, EnhanceProfileEditComponent],
  templateUrl: './enhance-profile-step.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnhanceProfileStepComponent {
  isRowExpanded = input<boolean>(false);

  isRowHasValue = input<boolean>(true);

  isEditDisabled = input<boolean>(false);

  accountType = input<LinxAccountType>();

  entityType = input<LinxEntityType>();

  expandRow = output();

  profileChange = output<{
    profileData: LinxEnhanceAccountDetails | undefined;
    displayData: string[];
  }>();

  enhanceAccountDetails = signal<LinxEnhanceAccountDetails | undefined>(undefined);

  enhanceProfileDisplayData = signal<string[]>([]);

  onEnhanceProfileSelect(event: LinxEnhanceAccountDetails): void {
    const displayData = [];

    if (event?.enhanceProfileDetails) {
      const {
        personalInfo = [],
        businessInfo = [],
        addresses = [],
        phoneNumber = [],
        contacts = [],
        email = [],
      } = event?.enhanceProfileDetails ?? {};
      const details = [...personalInfo, ...businessInfo, ...addresses];
      details.forEach((item) => {
        displayData.push(EnhanceProfileDetails[item.replace('-', '').toUpperCase()]);
      });

      if (phoneNumber.length) {
        const phoneKey = this.accountType() === 'BUSINESS' ? 'PRIMARYBUSINESSPHONE' : phoneNumber[0];
        displayData.push(EnhanceProfileDetails[phoneKey]);
      }

      if (email.length) {
        displayData.push(EnhanceProfileDetails['EMAIL']);
      }

      if (contacts.length) {
        displayData.push(EnhanceProfileDetails['CONTACTS']);
      }
    }
    this.enhanceProfileDisplayData.set(displayData);
    this.enhanceAccountDetails.set(event);
    this.profileChange.emit({ profileData: this.enhanceAccountDetails(), displayData });
  }
}
