import { AsyncPipe, DecimalPipe, NgClass, NgIf, NgTemplateOutlet } from '@angular/common';
import { Component, OnDestroy, inject } from '@angular/core';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { pick } from 'lodash-es';
import { NgxMaskPipe } from 'ngx-mask';
import { Subject, catchError, exhaustMap, from, map, mergeMap, of, takeUntil } from 'rxjs';
import { RktButtonDirective } from '@rocketfinancialcorp/rocket-ui/button';
import { ActiveModal } from '@rocketfinancialcorp/rocket-ui/modal';
import { ShortIdPipe, SplitPipe } from '@rocketfinancialcorp/rocket-ui/table';

import { CreateTransactionSltData } from '@shared/models';
import { AmountPipe, CurrencySignPipe, NotAvailablePipe } from '@shared/pipes';
import { AttachmentService, MultiLegTransactionsService, NoteService } from '@shared/services';
import {
  fromAuth,
  selectCreateTransactionReviewData,
  selectMltCreateData,
  selectSchedulerData,
  selectSltCreateData,
  transactionFeature,
  transactionFormFeature,
} from '@shared/store';
import { ErrorUtils } from '@shared/utils';

@Component({
  selector: 'app-create-transaction-review-modal',
  templateUrl: './create-transaction-review-modal.component.html',
  standalone: true,
  imports: [
    RktButtonDirective,
    NgIf,
    NgClass,
    NgTemplateOutlet,
    AsyncPipe,
    DecimalPipe,
    NotAvailablePipe,
    CurrencySignPipe,
    AmountPipe,
    NgxMaskPipe,
    SplitPipe,
    ShortIdPipe,
  ],
})
export class CreateTransactionReviewModalComponent implements OnDestroy {
  private readonly store = inject(Store);

  private readonly multiLegTransactionsService = inject(MultiLegTransactionsService);

  private readonly noteService = inject(NoteService);

  private readonly attachmentService = inject(AttachmentService);

  public readonly activeModal = inject(ActiveModal);

  createTransactionReviewData$ = this.store.select(selectCreateTransactionReviewData);

  createdTransactionId?: string;

  submitLoading = false;

  public isUpdate = false;

  private destroy$ = new Subject<void>();

  modalInitData({ isUpdate }: { isUpdate: boolean }) {
    this.isUpdate = isUpdate;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onSubmit() {
    this.submitLoading = true;

    if (this.isUpdate) {
      this.updateTransactionRequest();
      return;
    }

    this.store
      .select(transactionFormFeature.selectTransactionType)
      .pipe(
        concatLatestFrom(() => [
          this.store.select(selectMltCreateData),
          this.store.select(selectSltCreateData),
          this.store.select(selectSchedulerData),
          this.store.select(fromAuth.selectBusinessAccountId),
          this.store.select(transactionFormFeature.selectIdempotencyTimeConstraint),
        ]),
        exhaustMap(
          ([
            transactionType,
            createMltTransactionData,
            createSltTransactionData,
            schedulerData,
            activeBusinessAccountId,
            idempotencyTimeConstraint,
          ]) => {
            if (
              !transactionType ||
              (transactionType === 'MLT' && !createMltTransactionData) ||
              (transactionType === 'SLT' && !createSltTransactionData)
            ) {
              return of();
            }

            if (schedulerData && transactionType === 'MLT') {
              return this.multiLegTransactionsService.createScheduledMltTransaction(
                schedulerData,
                createMltTransactionData!,
                idempotencyTimeConstraint,
              );
            } else if (schedulerData && transactionType === 'SLT') {
              return this.multiLegTransactionsService.createScheduledSltTransaction(
                schedulerData,
                createSltTransactionData!,
                idempotencyTimeConstraint,
              );
            } else if (transactionType === 'MLT') {
              return this.multiLegTransactionsService.createMltTransaction(createMltTransactionData!, idempotencyTimeConstraint);
            } else {
              return this.multiLegTransactionsService.createSltTransaction(
                activeBusinessAccountId,
                createSltTransactionData!,
                idempotencyTimeConstraint,
              );
            }
          },
        ),
        concatLatestFrom(() => [this.store.select(transactionFormFeature.selectNote), this.store.select(selectSchedulerData)]),
        exhaustMap(([transactionResponse, note, schedulerData]) => {
          if (!note) {
            return of(transactionResponse);
          }

          return this.noteService
            .addNote({
              entityId: transactionResponse,
              entityType: schedulerData ? 'SCHEDULED_TRANSACTION' : 'TRANSACTION',
              contentText: note,
            })
            .pipe(
              catchError((error) => {
                ErrorUtils.catchError('noteService.addNote', error);
                return of(transactionResponse);
              }),
              map(() => transactionResponse),
            );
        }),
        concatLatestFrom(() => [
          this.store.select(transactionFormFeature.selectAttachments),
          this.store.select(selectSchedulerData),
          this.store.select(transactionFormFeature.selectTransactionType),
        ]),
        exhaustMap(([transactionResponse, attachments, schedulerData, transactionType]) => {
          if (!attachments) {
            return of(transactionResponse);
          }
          return from(attachments).pipe(
            mergeMap((attachment) =>
              from(
                this.attachmentService.createAttachment({
                  entityId: transactionResponse,
                  entityType: schedulerData ? 'SCHEDULED_TRANSACTION' : transactionType === 'MLT' ? 'MULTI_LEG_TRANSACTION' : 'TRANSACTION',
                  file: attachment.file!,
                  extension: attachment.extension!,
                  name: attachment.name,
                  description: attachment.description,
                }),
              ),
            ),
            catchError((error) => {
              ErrorUtils.catchError('attachmentService.createAttachment', error);
              return of(transactionResponse);
            }),
            map(() => transactionResponse),
          );
        }),
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: (transactionId) => {
          this.createdTransactionId = transactionId;
          this.submitLoading = false;
        },
        error: (error) => {
          this.submitLoading = false;
          this.activeModal.close({ error });
        },
      });
  }

  updateTransactionRequest() {
    this.store
      .select(transactionFormFeature.selectTransactionType)
      .pipe(
        concatLatestFrom(() => [
          this.store.select(transactionFeature.selectMultiLegTransactionDetails),
          this.store.select(transactionFeature.selectSingleLegTransactionDetails),
          this.store.select(selectMltCreateData),
          this.store.select(selectSchedulerData),
          this.store.select(selectSltCreateData),
          this.store.select(fromAuth.selectBusinessAccountId),
        ]),
        exhaustMap(
          ([
            transactionType,
            mltTransaction,
            sltTransaction,
            createMltTransactionData,
            schedulerData,
            createSltTransactionData,
            activeBusinessAccountId,
          ]) => {
            if (
              !transactionType ||
              (transactionType === 'MLT' && (!mltTransaction || !createMltTransactionData)) ||
              (transactionType === 'SLT' && (!sltTransaction || !createSltTransactionData))
            ) {
              return of();
            }

            if (transactionType === 'MLT' && !schedulerData) {
              return this.multiLegTransactionsService.updateMltTransaction(
                mltTransaction!.id,
                mltTransaction!.transactionETag!,
                createMltTransactionData!,
              );
            } else if (transactionType === 'SLT') {
              return this.multiLegTransactionsService.updateSltTransaction(
                sltTransaction!.id,
                activeBusinessAccountId,
                pick(createSltTransactionData!, [
                  'amount',
                  'debitFinancialAccountId',
                  'creditFinancialAccountId',
                  'paymentReasonId',
                ]) as CreateTransactionSltData,
              );
            } else {
              return of(true);
            }
          },
        ),
        concatLatestFrom(() => [this.store.select(transactionFormFeature.selectNote)]),
        exhaustMap(([transactionResponse, note]) => {
          if (!note) {
            return of(transactionResponse);
          }

          return this.noteService
            .addNote({
              entityId: (transactionResponse as { id: string }).id,
              entityType: 'TRANSACTION',
              contentText: note,
            })
            .pipe(
              catchError((error) => {
                ErrorUtils.catchError('noteService.addNote', error);
                return of(transactionResponse);
              }),
              map(() => transactionResponse),
            );
        }),
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: (transaction) => {
          this.createdTransactionId = (transaction as unknown as { id: string })?.id;
          this.submitLoading = false;
        },
        error: (error) => {
          this.submitLoading = false;
          this.activeModal.close({ error });
        },
      });
  }

  onDoneBtnClick(isMlt: boolean, isScheduled: boolean) {
    const mltOrSltPath = isMlt ? 'multi-leg-transactions' : 'transactions';
    const transactionPath = ['app', 'transactions', isScheduled ? 'scheduled-transactions' : mltOrSltPath, this.createdTransactionId].join(
      '/',
    );
    this.activeModal.close({ isTransactionCreated: true, transactionPath });
  }

  onCreateNewTransactionBtnClick() {
    this.activeModal.close({ isTransactionCreated: true, isSkipRedirect: true });
  }
}
