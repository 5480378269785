import { Action, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromRoot from '@shared/store';

import * as fromIndustries from './industry.reducer';

export const industryFeatureKey = 'industry';

export interface IndustriesState {
  [fromIndustries.industryListFeatureKey]: fromIndustries.IndustriesState;
}

export interface State extends fromRoot.RootState {
  [industryFeatureKey]: IndustriesState;
}

export const reducers = (state: IndustriesState | undefined, action: Action) => {
  return combineReducers({
    [fromIndustries.industryListFeatureKey]: fromIndustries.reducer,
  })(state, action);
};

export const selectIndustryState = createFeatureSelector<IndustriesState>(industryFeatureKey);

export const selectIndustryListState = createSelector(selectIndustryState, (state) => state.industries);

export const selectIndustryList = createSelector(selectIndustryListState, fromIndustries.getIndustries);

export const selectIndustry = createSelector(selectIndustryListState, fromIndustries.getIndustry);
