import { AfterViewChecked, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { SelectionType } from '@swimlane/ngx-datatable';
import { get, head, uniq } from 'lodash-es';
import { Subject, takeUntil } from 'rxjs';
import { DatatableColumn, RktTableComponent } from '@rocketfinancialcorp/rocket-ui/table';

import {
  FinancialAccountList,
  FinancialAccountListItem,
  RequestPageParams,
  SolutionAllowedTypes,
  TransactionSolution,
} from '@shared/models';
import { FinancialAccountService } from '@shared/services';
import { ErrorUtils } from '@shared/utils';

@Component({
  selector: 'app-linx-business-financial-account-selection',
  templateUrl: './linx-business-financial-account-selection.component.html',
  standalone: true,
  imports: [RktTableComponent],
})
export class LinxBusinessFinancialAccountSelectionComponent implements OnInit, AfterViewChecked, OnDestroy {
  @Input() debitAllowedTypes?: SolutionAllowedTypes | null;

  @Input() creditAllowedTypes?: SolutionAllowedTypes | null;

  @Input() selectedMoveHowType?: TransactionSolution;

  @Input() selectedMoveDirection?: string;

  @Input() initialSelectedFinancialAccountId?: string;

  @Input() customerId?: string;

  @Output() selectedFinancialAccount = new EventEmitter<FinancialAccountListItem>();

  stickyHeader = true;

  financialAccountsLoading = false;

  financialAccountsPage = 0;

  totalFinancialAccountsElements = 0;

  SelectionType = SelectionType;

  columns: DatatableColumn[] = [
    { name: 'Account Type', prop: 'accountHolderTypeDisplayName', flexSize: 250 },
    { name: 'Account Name', prop: 'name', flexSize: 300 },
    { name: 'Account Info', prop: 'accountInfo', flexSize: 333 },
    { name: 'Account Category', prop: 'category', flexSize: 200 },
    { name: 'Status', prop: 'state', fixedSize: 140, colType: 'status-tag', colAlign: 'right' },
    { name: 'Account Balance', prop: 'accountBalance', flexSize: 250, colType: 'amount', colAlign: 'right' },
    { name: 'Available Balance', prop: 'availableBalance', flexSize: 250, colType: 'amount', colAlign: 'right' },
  ];

  businessFinancialAccounts: FinancialAccountListItem[] = [];

  emptyListMessage = 'No Financial Accounts found.';

  private destroy$ = new Subject<void>();

  get accountTypes(): string[] | undefined {
    const allowedTypes = this.selectedMoveDirection === 'push' ? this.debitAllowedTypes : this.creditAllowedTypes;

    if (!allowedTypes || !this.selectedMoveHowType || !this.selectedMoveDirection) {
      return;
    }

    const transactionType = this.selectedMoveDirection === 'push' ? 'SEND' : 'REQUEST';
    const solutionAllowedTypes = get(allowedTypes, `${this.selectedMoveHowType}.${transactionType}`);
    return solutionAllowedTypes ? uniq(solutionAllowedTypes.map((typeName) => typeName.split(':')[0])) : undefined;
  }

  get activeFinancialAccountsPage() {
    return this.financialAccountsPage;
  }

  constructor(
    public ref: ChangeDetectorRef,
    private financialAccountService: FinancialAccountService,
  ) {}

  ngOnInit(): void {
    if (this.initialSelectedFinancialAccountId) {
      this.getFinancialAccountById();
    }
  }

  ngAfterViewChecked(): void {
    this.ref.detectChanges();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getBusinessFinancialAccounts({ page }: RequestPageParams): void {
    this.financialAccountsLoading = true;

    this.financialAccountService
      .getFinancialAccountsWithBalances({
        page,
        size: 5,
        type: 'BANK',
        state: ['ACTIVE', 'SUSPENDED'],
        accountTypes: this.accountTypes,
        accountHolderTypes: this.customerId ? undefined : ['BUSINESS_ACCOUNT'],
        accountHolderId: this.customerId,
        skipAccountHolderDetails: true,
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response) => {
          this.emptyListMessage = 'No Financial Accounts found.';
          this.onFetchComplete(response);
        },
        error: (error) => {
          this.emptyListMessage = 'Unable to fetch Financial Accounts.';
          this.onFetchComplete();
          ErrorUtils.catchError('financialAccountService.getFinancialAccountsWithBalances error', error);
        },
      });
  }

  onFetchComplete(response?: FinancialAccountList): void {
    const { items = [], totalElements = 0 } = response || {};
    this.businessFinancialAccounts = this.mapFinancialAccounts(items);
    this.financialAccountsLoading = false;
    this.totalFinancialAccountsElements = totalElements;
  }

  mapFinancialAccounts(financialAccounts: FinancialAccountList['items']): FinancialAccountListItem[] {
    if (!financialAccounts.length) {
      return [];
    }

    const updatedFinancialAccounts = financialAccounts.map((item) => {
      const isNotActive = item.state !== 'ACTIVE';
      const isNotAllowedType = !this.accountTypes?.includes(item.category);

      return {
        ...item,
        isDisabled: isNotActive || isNotAllowedType,
        displayName: `${item.displayName}`,
      };
    });

    let accounts = [...updatedFinancialAccounts];
    const defaultAccount = head(accounts.filter((account) => account.defaultFlag));

    if (defaultAccount) {
      accounts = accounts.filter((acc) => acc !== defaultAccount);
      accounts.unshift(defaultAccount);
    }

    return accounts;
  }

  public getFinancialAccountById(): void {
    this.financialAccountService
      .getFinancialAccountDetailsWithBalances(this.initialSelectedFinancialAccountId ?? '')
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (financialAccount) => {
          if (financialAccount) {
            this.selectedFinancialAccount.emit({
              ...financialAccount,
              availableBalance: financialAccount.accountBalances?.availableBalance,
            });
          }
        },
        error: (error) => {
          ErrorUtils.catchError('financialAccountService.getFinancialAccountDetailsWithBalances error', error);
        },
      });
  }

  onBusinessFinancialAccountsSelect(data: unknown = {}): void {
    const { selected } = data as { selected?: FinancialAccountListItem[] };
    if (selected?.length) {
      this.selectedFinancialAccount.emit(selected[0]);
    }
  }
}
