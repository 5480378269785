<ng-container *ngIf="merchantDetails$ | async as merchant; else merchantDetailsLoading">
  <ng-container *ngTemplateOutlet="merchantDetails; context: { $implicit: merchant, loading: false }"></ng-container>
</ng-container>
<ng-template #merchantDetailsLoading>
  <ng-container *ngTemplateOutlet="merchantDetails; context: { $implicit: null, loading: true }"></ng-container>
</ng-template>

<ng-template #merchantDetails [appTemplateContext]="merchantDetailsContext" let-merchant let-loading="loading">
  <div class="details-page-header-wrapper" *ngIf="!loading; else loadingPlaceholder">
    <div class="page-header">
      <div class="details-page-back-title">
        <a [routerLink]="[pageReturnURL]" class="details-page-back-btn">
          <rkt-icon name="arrow-left" class="details-page-back-btn-icon"></rkt-icon>
        </a>

        <h1 class="details-page-title">
          {{ merchant?.merchantId }}
        </h1>
      </div>
      <app-breadcrumbs [itemList]="breadcrumbs"></app-breadcrumbs>
    </div>
  </div>

  <div class="entity-details-container card-account-details-page">
    <div class="details-section-title">
      <div class="title">
        <app-text-with-placeholder [showPlaceholder]="loading">Merchant Details</app-text-with-placeholder>
      </div>
    </div>

    <div class="details-row details-row-with-offset">
      <app-details-item
        [class]="pageClassPrefix + 'id'"
        [showPlaceholder]="loading"
        label="Merchant ID"
        [value]="merchant?.merchantId"
      ></app-details-item>

      <app-details-item
        [class]="pageClassPrefix + 'cardAcceptorIdCode'"
        [showPlaceholder]="loading"
        label="Card Acceptor ID Code"
        [value]="merchant?.cardAcceptorIdCode"
      ></app-details-item>

      <app-details-item
        [class]="pageClassPrefix + 'merchantCategoryCode'"
        [showPlaceholder]="loading"
        label="Merchant Category Code"
        [value]="merchant?.merchantCategoryCode"
      ></app-details-item>

      <app-details-item
        [class]="pageClassPrefix + 'merchantCategoryDescription'"
        [showPlaceholder]="loading"
        label="Merchant Category Description"
        [value]="merchant?.merchantCategoryDescription"
      ></app-details-item>

      <app-details-item
        [class]="pageClassPrefix + 'brandName'"
        [showPlaceholder]="loading"
        label="Brand Name"
        [value]="merchant?.brandName"
      ></app-details-item>

      <app-details-item
        [class]="pageClassPrefix + 'dbaName'"
        [showPlaceholder]="loading"
        label="Doing Business As"
        [value]="merchant?.dbaName"
      ></app-details-item>

      <app-details-item
        [class]="pageClassPrefix + 'legalName'"
        [showPlaceholder]="loading"
        label="Legal Name"
        [value]="merchant?.legalName"
      ></app-details-item>

      <app-details-item
        [class]="pageClassPrefix + 'phone-number'"
        [showPlaceholder]="loading"
        label="Phone Number"
        [value]="merchant?.phoneNumber | phone"
      ></app-details-item>
    </div>

    <div class="details-separator"></div>

    <div class="details-section-title">
      <div class="title"><app-text-with-placeholder [showPlaceholder]="loading">Address</app-text-with-placeholder></div>
    </div>

    <div class="details-row">
      <ng-container *ngIf="merchant?.merchantAddresses?.length; else emptyAddress">
        <app-details-item
          *ngFor="let address of merchant?.merchantAddresses; let i = index"
          [class]="pageClassPrefix + 'address-' + (i + 1)"
          [showPlaceholder]="loading"
          [label]="'Address ' + (i + 1)"
          [value]="address.address1"
        >
          <ng-container>
            <div>{{ address.address2 }}</div>
            <div>{{ address.city }}, {{ address.state }}</div>
            <div>{{ address.zipCode }} {{ address.countryCode }}</div>
          </ng-container>
        </app-details-item>
      </ng-container>

      <ng-template #emptyAddress>
        <app-details-item [value]="" [showPlaceholder]="loading"></app-details-item>
      </ng-template>
    </div>
  </div>
</ng-template>

<ng-template #loadingPlaceholder>
  <app-details-page-header-placeholder [pageReturnURL]="pageReturnURL" [withStatus]="false"></app-details-page-header-placeholder>
</ng-template>
