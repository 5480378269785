import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RktIconComponent } from '@rocketfinancialcorp/rocket-ui/icon';

@Component({
  selector: 'app-collapsible-row',
  templateUrl: 'collapsible-row.component.html',
  styleUrls: ['collapsible-row.component.scss'],
  standalone: true,
  imports: [NgIf, RktIconComponent],
})
export class CollapsibleRowComponent {
  @Input() number = '';

  @Input() label!: string;

  @Input() iconName!: string;

  @Input() isRowHasValue = false;

  @Input() isLastRow!: boolean;

  @Input() isRowExpanded = false;
}
