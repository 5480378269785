import { AsyncPipe, NgIf } from '@angular/common';
import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { RktButtonDirective } from '@rocketfinancialcorp/rocket-ui/button';
import { RktIconComponent } from '@rocketfinancialcorp/rocket-ui/icon';
import { ModalService } from '@rocketfinancialcorp/rocket-ui/modal';
import { DatatableColumn, RktTableComponent } from '@rocketfinancialcorp/rocket-ui/table';

import { RestrictionCreateModalComponent } from '@shared/components';
import { CardRestriction } from '@shared/models';
import { NavigationService } from '@shared/services';
import { CardAccountActions, fromFinancialAccount } from '@shared/store';

import { FocusRemoveDirective } from '../../../directives/focus-remove.directive';
import { AccessControlPipe } from '../../../pipes/access-control.pipe';

@Component({
  templateUrl: './restriction-list.component.html',
  standalone: true,
  imports: [RktButtonDirective, NgIf, FocusRemoveDirective, AsyncPipe, AccessControlPipe, RktIconComponent, RktTableComponent],
})
export class RestrictionListComponent implements OnDestroy {
  cardAccountId$ = this.store.select(fromFinancialAccount.selectCardAccountDetailsId);

  restrictions$ = this.store.select(fromFinancialAccount.selectCardAccountRestrictionList);

  totalElements$ = this.store.select(fromFinancialAccount.selectRestrictionListTotalElements);

  loading$ = this.store.select(fromFinancialAccount.selectRestrictionListLoading);

  columns: DatatableColumn[] = [
    { name: 'Created At', prop: 'createdAt', fixedSize: 150, isSortable: true, colType: 'date-time' },
    { name: 'Restriction ID', prop: 'id', colType: 'short-id', fixedSize: 130 },
    { name: 'Type', prop: 'typeLabel', flexSize: 200 },
    { name: 'Mode', prop: 'modeLabel', flexSize: 200 },
    { name: 'Value', prop: 'value', flexSize: 200 },
    { name: 'Start At', prop: 'startDateTime', fixedSize: 150, colType: 'date-time' },
    { name: 'End At', prop: 'endDateTime', fixedSize: 150, colType: 'date-time' },
    { name: 'Enabled', prop: 'enabledIcon', fixedSize: 100, colType: 'icon', colAlign: 'center' },
  ];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private store: Store,
    private navigationService: NavigationService,
    private modalService: ModalService,
  ) {
    this.navigationService.dispatchOnNavigationEnd(CardAccountActions.loadRestrictions());
  }

  ngOnDestroy(): void {
    this.navigationService.ngOnDestroy();
    this.modalService.dismissAll();
  }

  onRowClick({ id }: CardRestriction): void {
    this.router.navigate([id], { relativeTo: this.activatedRoute });
  }

  onCreateRestrictionBtnClick({ financialAccountId }: { financialAccountId?: string }) {
    if (!financialAccountId) {
      return;
    }

    const restrictionModalRef = this.modalService.open(RestrictionCreateModalComponent, {
      className: 'entity-form-modal restriction-form-modal',
    });

    restrictionModalRef.componentInstance.modalInitData({ financialAccountId });

    restrictionModalRef.result.finally(() => {
      this.store.dispatch(CardAccountActions.loadRestrictions());
    });
  }
}
