import { AsyncPipe, NgIf } from '@angular/common';
import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { RktButtonDirective } from '@rocketfinancialcorp/rocket-ui/button';
import { RktIconComponent } from '@rocketfinancialcorp/rocket-ui/icon';
import { ModalService } from '@rocketfinancialcorp/rocket-ui/modal';
import { DatatableColumn, RktTableComponent } from '@rocketfinancialcorp/rocket-ui/table';

import { AlertCreateModalComponent } from '@shared/components';
import { CardAlert } from '@shared/models';
import { NavigationService } from '@shared/services';
import { CardAccountActions, fromFinancialAccount } from '@shared/store';

import { FocusRemoveDirective } from '../../../directives/focus-remove.directive';
import { AccessControlPipe } from '../../../pipes/access-control.pipe';

@Component({
  templateUrl: './alert-list.component.html',
  standalone: true,
  imports: [RktButtonDirective, NgIf, FocusRemoveDirective, AsyncPipe, AccessControlPipe, RktIconComponent, RktTableComponent],
})
export class AlertListComponent implements OnDestroy {
  cardAccountId$ = this.store.select(fromFinancialAccount.selectCardAccountDetailsId);

  alerts$ = this.store.select(fromFinancialAccount.selectCardAccountAlertList);

  totalElements$ = this.store.select(fromFinancialAccount.selectAlertListTotalElements);

  loading$ = this.store.select(fromFinancialAccount.selectAlertListLoading);

  columns: DatatableColumn[] = [
    { name: 'Created At', prop: 'createdAt', fixedSize: 150, isSortable: true, colType: 'date-time' },
    { name: 'Alert ID', prop: 'id', colType: 'short-id', flexSize: 100 },
    { name: 'Alert Type', prop: 'typeLabel', flexSize: 160 },
    { name: 'Threshold Type', prop: 'thresholdTypeLabel', flexSize: 200 },
    { name: 'Threshold Amount', prop: 'thresholdAmount', flexSize: 200 },
    { name: 'Enabled', prop: 'enabledIcon', fixedSize: 160, colType: 'icon', colAlign: 'center' },
  ];

  constructor(
    private router: Router,
    private navigationService: NavigationService,
    private activatedRoute: ActivatedRoute,
    private store: Store,
    private modalService: ModalService,
  ) {
    this.navigationService.dispatchOnNavigationEnd(CardAccountActions.loadAlerts());
  }

  ngOnDestroy() {
    this.navigationService.ngOnDestroy();
    this.modalService.dismissAll();
  }

  onRowClick({ id }: CardAlert): void {
    this.router.navigate([id], { relativeTo: this.activatedRoute });
  }

  onCreateAlertBtnClick({ financialAccountId }: { financialAccountId?: string }) {
    if (!financialAccountId) {
      return;
    }

    const alertModalRef = this.modalService.open(AlertCreateModalComponent, { className: 'entity-form-modal' });

    alertModalRef.componentInstance.modalInitData({ financialAccountId });

    alertModalRef.result.finally(() => {
      this.store.dispatch(CardAccountActions.loadAlerts());
    });
  }
}
