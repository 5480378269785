import { AsyncPipe, NgIf, NgTemplateOutlet } from '@angular/common';
import { AfterViewChecked, ChangeDetectorRef, Component, Input, OnDestroy, computed, input } from '@angular/core';
import { isNil, isUndefined } from 'lodash-es';
import { Subject, takeUntil } from 'rxjs';
import { RktButtonDirective } from '@rocketfinancialcorp/rocket-ui/button';
import { RktCarouselComponent, RktCarouselItemDirective } from '@rocketfinancialcorp/rocket-ui/carousel';
import { RktIconComponent } from '@rocketfinancialcorp/rocket-ui/icon';
import { ModalService } from '@rocketfinancialcorp/rocket-ui/modal';
import {
  DatatableColumn,
  DatatableSorting,
  FetchData,
  RktTableComponent,
  RktTableSearchComponent,
} from '@rocketfinancialcorp/rocket-ui/table';

import { AttachmentCreateFormComponent, LinxConfirmModalComponent, ViewAttachmentModalComponent } from '@shared/components';
import {
  AttachmentCreateModel,
  AttachmentDetails,
  AttachmentEntityType,
  AttachmentList,
  AttachmentListItem,
  AttchmentUpdateModel,
} from '@shared/models';
import { AccessControlPipe } from '@shared/pipes';
import { AttachmentService, NotificationService } from '@shared/services';
import { ErrorUtils } from '@shared/utils';

interface ResponsiveBreakpoint {
  breakpoint: number;
  itemsVisible: number;
  itemsScroll: number;
}

@Component({
  selector: 'app-attachments-list',
  templateUrl: './attachments-list.component.html',
  styleUrls: ['./attachments-list.component.scss'],
  standalone: true,
  imports: [
    RktCarouselComponent,
    RktCarouselItemDirective,
    RktButtonDirective,
    NgIf,
    AsyncPipe,
    AccessControlPipe,
    RktIconComponent,
    RktTableSearchComponent,
    RktTableComponent,
    NgTemplateOutlet,
  ],
})
export class AttachmentsListComponent implements AfterViewChecked, OnDestroy {
  @Input() entityId?: string | null;
  @Input() entityType!: AttachmentEntityType;

  canUpdateAttachment = input<boolean>(true);

  showSearch = input<boolean>(true);

  attachments: AttachmentList['items'] = [];

  loading = true;

  searchString?: string;
  attachmentsColumns = computed((): DatatableColumn[] => {
    const defaultColumns: DatatableColumn[] = [
      { name: 'Name', prop: 'name', flexSize: 200 },
      { name: 'ID', prop: 'id', flexSize: 160, colType: 'short-id' },
      { name: 'Description', prop: 'description', flexSize: 300 },
      { name: 'Created At', prop: 'createdAt', flexSize: 150, colType: 'date-time' },
    ];
    if (!this.canUpdateAttachment()) {
      return defaultColumns;
    }
    return [
      ...defaultColumns,
      {
        name: '',
        prop: 'deleteBtn',
        fixedSize: 50,
        colType: 'icon-button',
        iconName: 'trash',
        colAlign: 'center',
        colTitle: 'Delete attachment',
      },
      {
        name: '',
        prop: 'editBtn',
        fixedSize: 50,
        colType: 'icon-button',
        iconName: 'edit',
        colAlign: 'center',
        colTitle: 'Edit attachment',
      },
      {
        name: '',
        prop: 'downloadBtn',
        fixedSize: 50,
        colType: 'icon-button',
        iconName: 'download',
        colAlign: 'center',
        colTitle: 'Download attachment',
      },
    ];
  });

  totalElements = 0;

  page = 0;

  sortParams: DatatableSorting = { key: 'createdAt', sortProp: 'createdAt', sortDir: 'asc' };

  get activePage() {
    return this.page;
  }

  responsiveBreakpoints: ResponsiveBreakpoint[] = [
    { itemsScroll: 3, itemsVisible: 4, breakpoint: 2700 },
    { itemsScroll: 2, itemsVisible: 3, breakpoint: 1700 },
    { itemsScroll: 1, itemsVisible: 2, breakpoint: 1490 },
  ];

  attachmentsView = 'list';

  private destroy$ = new Subject<void>();

  constructor(
    public ref: ChangeDetectorRef,
    private attachmentService: AttachmentService,
    private notificationService: NotificationService,
    private modalService: ModalService,
  ) {}

  ngAfterViewChecked(): void {
    this.ref.detectChanges();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getAttachments({ page, sortParams }: FetchData): void {
    if (!this.entityId || !this.entityType) {
      return;
    }
    if (!isUndefined(page) && this.activePage !== page) {
      this.page = page;
    }
    this.loading = true;
    if (!isNil(page) && this.activePage !== page) {
      this.page = page;
    }

    if (sortParams) {
      this.sortParams = sortParams;
    }

    this.attachmentService
      .getAttachments({
        entityType: this.entityType,
        entityId: this.entityId,
        page,
        size: 10,
        searchString: this.searchString,
        sortParams: sortParams ?? this.sortParams,
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response) => {
          this.onFetchComplete(response);
        },
        error: (error) => {
          this.onFetchComplete();
          this.notificationService.displayError('Unable to fetch Attachments.', 'Error');
          ErrorUtils.catchError('attachmentService.getAttachments', error);
        },
      });
  }

  onFetchComplete(response?: AttachmentList): void {
    const { items = [], totalElements = 0 } = response ?? {};
    this.attachments = items;
    this.totalElements = totalElements;
    this.loading = false;
  }

  onAddAttachment(): void {
    const documentCreateFormModalRef = this.modalService.open(AttachmentCreateFormComponent, {
      className: 'entity-form-modal',
    });

    documentCreateFormModalRef.result.then(
      (result) => {
        if (result) this.createAttachment(result);
      },
      () => false,
    );
  }

  createAttachment(attachmentData: AttachmentCreateModel): void {
    if (!this.entityId || !this.entityType) {
      return;
    }

    this.loading = true;

    this.attachmentService
      .createAttachment({
        ...attachmentData,
        entityType: this.entityType,
        entityId: this.entityId,
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.showConfirmationModal('Attachment Uploaded Successfully', 'Upload Successful');
          this.getAttachments({ page: 0 });
        },
        error: (error) => {
          this.showConfirmationModal('Unable to create Attachment. Try Again', 'Upload Failed', 'error');
          ErrorUtils.catchError('attachmentService.createAttachment', error);
        },
      });
  }

  onRowClick({ id }: AttachmentListItem): void {
    this.attachmentService
      .getAttachmentById(id)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response) => {
          this.viewAttachment(response);
        },
        error: (error) => {
          this.notificationService.displayError('Unable to fetch Attachment.', 'Error');
          ErrorUtils.catchError('attachmentService.getAttachmentById', error);
        },
      });
  }

  viewAttachment(attachment: AttachmentDetails): void {
    const viewAttachmentModalRef = this.modalService.open(ViewAttachmentModalComponent, {
      className: 'view-attachment-modal',
      size: 'lg',
    });
    viewAttachmentModalRef.componentInstance.modalInitData(attachment, this.canUpdateAttachment());

    viewAttachmentModalRef.result.then(
      (result) => {
        if (result) {
          this.onActionButtonClick(result, attachment.id);
        }
      },
      () => false,
    );
  }

  onColButtonClick({ colButton, row }: { colButton: string; row: AttachmentListItem }) {
    this.onActionButtonClick(colButton, row.id);
  }

  onActionButtonClick(action: string, attachmentId: AttachmentDetails['id'], event?: Event): void {
    event?.stopPropagation();
    switch (action) {
      case 'DELETE':
        this.showConfirmationModal(
          'Are you sure you want to delete this Attachment? This cannot be undone.',
          'Delete Attachment',
          'error',
          action,
          attachmentId,
        );
        break;
      case 'DOWNLOAD':
        this.attachmentService.downloadAttachment(attachmentId);
        break;
      case 'EDIT':
        this.onUpdateAttachment(attachmentId);
        break;
    }
  }

  deleteAttachment(attachmentId: AttachmentDetails['id']): void {
    this.attachmentService
      .deleteAttachment(attachmentId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.showConfirmationModal('Attachment Deleted Successfully', 'Delete Successful');
          this.getAttachments({ page: 0 });
        },
        error: (error) => {
          this.showConfirmationModal('Unable to Delete Attachment. Try Again', 'Delete Failed', 'error');
          ErrorUtils.catchError('attachmentService.deleteAttachment', error);
        },
      });
  }

  onUpdateAttachment(attachmentId: AttachmentDetails['id']): void {
    const selectedAttachment = this.attachments.find((attachment) => attachment.id === attachmentId);
    const documentCreateFormModalRef = this.modalService.open(AttachmentCreateFormComponent, {
      className: 'entity-form-modal',
    });
    documentCreateFormModalRef.componentInstance.isEditMode = true;
    documentCreateFormModalRef.componentInstance.formModel = {
      name: selectedAttachment?.name,
      description: selectedAttachment?.description,
    };

    documentCreateFormModalRef.result.then(
      (result) => {
        if (result) this.updateAttachment(attachmentId, result);
      },
      () => false,
    );
  }

  updateAttachment(attachmentId: AttachmentDetails['id'], formData: AttchmentUpdateModel): void {
    this.attachmentService
      .updateAttachment(attachmentId, formData)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.showConfirmationModal('Attachment Updated Successfully', 'Update Successful');
          this.getAttachments({ page: 0 });
        },
        error: (error) => {
          this.showConfirmationModal('Unable to Update Attachment. Try Again', 'Update Failed', 'error');
          ErrorUtils.catchError('attachmentService.updateAttachment', error);
        },
      });
  }

  showConfirmationModal(message: string, title: string, confirmType = 'success', action?: string, attachmentId?: string) {
    const errorModalRef = this.modalService.open(LinxConfirmModalComponent, {
      className: 'confirm-modal',
    });
    errorModalRef.componentInstance.title = title;
    errorModalRef.componentInstance.customText = message;
    errorModalRef.componentInstance.confirmType = confirmType;
    errorModalRef.componentInstance.confirmBtnText = action ? 'Confirm' : 'Close';
    errorModalRef.componentInstance.showCancelButton = !!action;

    errorModalRef.result.then(
      (result) => {
        if (result && action === 'DELETE' && attachmentId) {
          this.deleteAttachment(attachmentId);
        }
      },
      () => false,
    );
  }

  onSearch(event?: string): void {
    if (!event) {
      this.onSearchReset();
      return;
    }
    this.searchString = event.toLowerCase();
    this.getAttachments({ page: 0 });
  }

  onSearchReset(): void {
    if (!this.searchString) {
      return;
    }
    this.searchString = undefined;
    this.getAttachments({ page: 0 });
  }

  onToggleAttachmentsView(view: string): void {
    this.attachmentsView = view;
  }

  getAttachmentScanLabel(attachment: AttachmentDetails): string {
    switch (attachment.status) {
      case 'NEW':
        return 'No File Available';

      case 'UPLOADED':
        return 'Scanning for Viruses';

      case 'MALICIOUS':
      case 'INCOMPLETE':
        return 'File cannot be Displayed.';

      case 'SCANNED':
      default:
        return '';
    }
  }
}
