import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { RktIconComponent } from '@rocketfinancialcorp/rocket-ui/icon';

@Component({
  selector: 'app-details-page-header-placeholder',
  templateUrl: './details-page-header-placeholder.component.html',
  styleUrls: ['./details-page-header-placeholder.component.scss'],
  standalone: true,
  imports: [RouterLink, NgIf, RktIconComponent],
})
export class DetailsPageHeaderPlaceholderComponent {
  @Input() pageReturnURL!: string;

  @Input() withStatus = true;

  @Input() isDarkBg = false;
}
