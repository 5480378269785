import { AsyncPipe, NgClass, NgIf, TitleCasePipe } from '@angular/common';
import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { ActivatedRoute, RouterLink, RouterOutlet } from '@angular/router';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Subject, switchMap, takeUntil } from 'rxjs';
import { RktButtonDirective } from '@rocketfinancialcorp/rocket-ui/button';
import { RktIconComponent } from '@rocketfinancialcorp/rocket-ui/icon';
import { ModalService } from '@rocketfinancialcorp/rocket-ui/modal';
import { ShortIdPipe } from '@rocketfinancialcorp/rocket-ui/table';

import {
  BreadcrumbsComponent,
  ConfirmModalComponent,
  DetailsPageHeaderPlaceholderComponent,
  ErrorModalComponent,
  TabsComponent,
} from '@shared/components';
import { Breadcrumb } from '@shared/models';
import { NotificationService, TransactionBatchesService } from '@shared/services';
import { TransactionBatchDetailsActions, selectTransactionBatchDetails } from '@shared/store';

@Component({
  selector: 'app-transaction-batch-page',
  templateUrl: './transaction-batch-page.component.html',
  styleUrls: ['./transaction-batch-page.component.scss'],
  standalone: true,
  imports: [
    RktButtonDirective,
    NgIf,
    RouterLink,
    RktIconComponent,
    NgClass,
    BreadcrumbsComponent,
    DetailsPageHeaderPlaceholderComponent,
    TabsComponent,
    RouterOutlet,
    AsyncPipe,
    TitleCasePipe,
    ShortIdPipe,
  ],
})
export class TransactionBatchPageComponent implements OnInit, OnDestroy {
  store = inject(Store);

  batchId$ = new BehaviorSubject<string | null>(null);

  pageReturnURL = '/app/transactions/transaction-batches';

  transactionBatch$ = this.store.select(selectTransactionBatchDetails);

  tabItems = [
    { label: 'Batch Details', href: 'batch-details' },
    { label: 'Transaction Activity', href: 'transaction-activity' },
    { label: 'Rejected Transactions', href: 'rejected-transactions' },
  ];

  breadcrumbList: Breadcrumb[] = [{ label: 'Transaction Batch', url: this.pageReturnURL }, { label: 'Batch Details' }];

  private destroy$ = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private transactionBatchesService: TransactionBatchesService,
    private notificationService: NotificationService,
    private modalService: ModalService,
  ) {}

  ngOnInit() {
    this.store.dispatch(TransactionBatchDetailsActions.loadTransactionBatchDetails());
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onBatchCancelClick(): void {
    const confirmModalRef = this.modalService.open(ConfirmModalComponent, {
      className: 'confirm-modal',
    });

    confirmModalRef.componentInstance.customText = 'Are you sure? Once this batch is cancelled, you will not be able to recover it.';

    confirmModalRef.result.then(
      (result) => {
        if (result) {
          this.cancelBatchTransaction();
        }
      },
      () => false,
    );
  }

  cancelBatchTransaction(): void {
    this.batchId$
      .pipe(
        switchMap((batchId) => this.transactionBatchesService.cancelTransactionBatch(batchId!)),
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: () => {
          this.notificationService.displayWarning('Transaction Batch cancelled');
          this.batchId$.next(this.route.snapshot.paramMap.get('id'));
        },
        error: (error) => {
          const cancelErrorModalRef = this.modalService.open(ErrorModalComponent, { className: 'confirm-modal' });
          cancelErrorModalRef.componentInstance.errorType = error;
        },
      });
  }
}
