<h1 class="title">Change password</h1>

<form class="rocket-ui-form" [formGroup]="changePasswordForm" [class.form-server-error]="error">
  <rkt-form
    [form]="changePasswordForm"
    [fields]="changePasswordFields"
    [model]="changePasswordModel"
    (modelChange)="onPasswordChange()"
  ></rkt-form>

  <app-password-strength
    [password]="changePasswordModel.password || ''"
    (strengthChanged)="updatePasswordStrength($event)"
  ></app-password-strength>

  <div *ngIf="error" class="form-error">{{ error }}</div>

  <div class="buttons">
    <button type="button" class="rkt-button rkt-button-tertiary" (click)="activeModal.close()">Cancel</button>
    <button
      type="button"
      class="rkt-button rkt-button-primary"
      (click)="onSubmit()"
      [disabled]="loading || !isAllChecksPass || this.changePasswordForm.invalid"
      [class.disabled]="loading || !isAllChecksPass || this.changePasswordForm.invalid"
    >
      Update Password
    </button>
  </div>
</form>
