import { AsyncPipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnDestroy, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { isNil } from 'lodash-es';
import { ModalService } from '@rocketfinancialcorp/rocket-ui/modal';
import { DatatableColumn, DatatableSorting, RktTableComponent } from '@rocketfinancialcorp/rocket-ui/table';

import { ViewPdfDocumentComponent } from '@shared/components';
import { InsightDocument, RequestPageParams } from '@shared/models';
import { NavigationService, NotificationService, ReportService } from '@shared/services';
import { CardAccountActions, fromFinancialAccount } from '@shared/store';
import { ErrorUtils } from '@shared/utils';

@Component({
  selector: 'app-statement-list',
  templateUrl: './statement-list.component.html',
  standalone: true,
  imports: [AsyncPipe, RktTableComponent],
})
export class StatementListComponent implements OnDestroy {
  reportService = inject(ReportService);

  activatedRoute = inject(ActivatedRoute);

  statements$ = this.store.select(fromFinancialAccount.selectCardAccountStatementList);

  totalElements$ = this.store.select(fromFinancialAccount.selectStatementListTotalElements);

  loading$ = this.store.select(fromFinancialAccount.selectStatementListLoading);

  columns: DatatableColumn[] = [
    { name: 'Created At', prop: 'createdAt', colType: 'date-time', fixedSize: 150 },
    { name: 'Statement ID', prop: 'id', fixedSize: 150, colType: 'short-id' },
    { name: 'Name', prop: 'name', flexSize: 1 },
    {
      name: '',
      prop: 'downloadAvailable',
      fixedSize: 150,
      colType: 'button',
      iconName: 'download',
      colAlign: 'center',
      colTitle: '',
    },
  ];

  page = 0;

  sortParams: DatatableSorting = { key: 'createdAt', sortProp: 'createdAt', sortDir: 'desc' };

  emptyListMessage = 'No Financial Account Statements found.';

  constructor(
    public ref: ChangeDetectorRef,
    private modalService: ModalService,
    private navigationService: NavigationService,
    private store: Store,
    private notificationService: NotificationService,
  ) {}

  ngOnDestroy() {
    this.navigationService.ngOnDestroy();
  }

  onColButtonClick({ colButton, row }: { colButton: string; row: InsightDocument }) {
    if (!colButton) {
      return;
    }

    this.onReportStatementAction(row, true);
  }

  onReportStatementAction(row: InsightDocument, downloadDocument: boolean): void {
    const { id, pdfStatementPath, name } = row;

    if (id && pdfStatementPath) {
      this.reportService.getInsightReportPreSignedURL(id, 'PDF').subscribe({
        next: (response) => {
          const { signedJsonUrl } = response;
          if (downloadDocument) {
            window.open(signedJsonUrl, '_blank');
          } else {
            this.previewDocument(name, signedJsonUrl);
          }
        },
        error: (error) => {
          this.notificationService.displayError('Unable to preview document.');
          ErrorUtils.catchError('reportService.getPreSignedURL error', error);
        },
      });
    } else {
      this.notificationService.displayError('Unable to preview document.');
    }
  }

  getFinancialAccountStatements({ page, size }: RequestPageParams): void {
    if (!isNil(page) && this.page !== page) {
      this.page = page;
    }

    this.store.dispatch(CardAccountActions.loadStatements({ requestParams: { page, size } }));
  }

  previewDocument(documentName: string | undefined, documentURL: string) {
    const previewDocumentRef = this.modalService.open(ViewPdfDocumentComponent, {
      className: 'entity-form-modal preview-document-modal',
      size: 'lg',
    });

    previewDocumentRef.componentInstance.modalInitData({
      documentName,
      documentURL,
      containerHeight: '40rem',
    });
  }
}
