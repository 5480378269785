import { HttpStatusCode } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { format, getTime } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import { of } from 'rxjs';
import { catchError, exhaustMap, filter, map, mergeMap, switchMap, takeUntil, tap } from 'rxjs/operators';

import {
  MultiLegSolutionConfigSolutionName,
  MultiLegTransactionProcessingPriority,
  bankAccountSubtypes,
  cardAccountSubtypes,
} from '@shared/models';
import { MultiLegTransactionsService } from '@shared/services';
import { MessagesActions, TransactionFormActions, fromAuth, transactionFeature, transactionFormFeature } from '@shared/store';
import { ErrorUtils, getConfigEndTimeForSolution, isBankingHoliday, isTimeExpiredAtTimeZone, systemTimeZone } from '@shared/utils';

@Injectable()
export class TransactionFormEffects {
  multiLegTransactionsService = inject(MultiLegTransactionsService);

  constructor(
    private actions$: Actions,
    private store: Store,
  ) {}

  loadFinancialAccounts$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TransactionFormActions.loadFinancialAccounts),
      concatLatestFrom(() => [
        this.store.select(fromAuth.selectBusinessAccountId),
        this.store.select(transactionFormFeature.selectFinancialAccountsActiveFilters),
      ]),
      mergeMap(([payload, activeBusinessAccountId, activeFilters]) => {
        return this.multiLegTransactionsService
          .getFinancialAccounts(activeBusinessAccountId, {
            ...payload.params,
            activeFilters: activeFilters ?? undefined,
          })
          .pipe(
            map(({ items, totalElements }) => {
              return TransactionFormActions.loadFinancialAccountsSuccess({ financialAccountItems: items, totalElements: totalElements });
            }),
            catchError((error) => {
              return of(TransactionFormActions.loadFinancialAccountsFailure({ error }));
            }),
            takeUntil(
              this.actions$.pipe(
                ofType(TransactionFormActions.loadFinancialAccounts, TransactionFormActions.resetFinancialAccountSelection),
              ),
            ),
          );
      }),
    );
  });

  loadFinancialAccountsFailure$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TransactionFormActions.loadFinancialAccountsFailure),
      tap({
        next: (errorResponse) => {
          ErrorUtils.catchError('multiLegTransactionsService.getFinancialAccounts - Error', errorResponse.error);
        },
      }),
      switchMap((errorResponse) => {
        if (errorResponse.error === HttpStatusCode.InternalServerError) {
          return of();
        }
        return of(MessagesActions.displayError({ message: 'Unable to fetch Financial Accounts.' }));
      }),
    );
  });

  loadPaymentReasons$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TransactionFormActions.formInitialize),
      switchMap(() => {
        return this.multiLegTransactionsService.getPaymentReasons().pipe(
          map((paymentReasons) => {
            return TransactionFormActions.setPaymentReasons({ paymentReasons });
          }),
          takeUntil(this.actions$.pipe(ofType(TransactionFormActions.setMoveFromMethod))),
        );
      }),
    );
  });

  loadEnabledSolutions$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        TransactionFormActions.formInitialize,
        TransactionFormActions.formUpdateMltInitialize,
        TransactionFormActions.formUpdateSltInitialize,
        TransactionFormActions.formUpdateScheduledSltInitialize,
      ),
      switchMap(() =>
        this.multiLegTransactionsService.getEnabledSolutions().pipe(
          map((enabledSolutions) => {
            return TransactionFormActions.loadEnabledSolutionsSuccess({ enabledSolutions });
          }),
          catchError((error) => {
            return of(TransactionFormActions.loadEnabledSolutionsFailure({ error: error.message }));
          }),
        ),
      ),
    );
  });

  loadSolutionsConfigs$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TransactionFormActions.loadEnabledSolutionsSuccess),
      exhaustMap(({ enabledSolutions }) => {
        return this.multiLegTransactionsService.getSolutionConfigs(Object.keys(enabledSolutions)).pipe(
          map((enabledSolutionConfigs) => {
            return TransactionFormActions.loadEnabledSolutionConfigsSuccess({ enabledSolutionConfigs });
          }),
          catchError((error) => {
            return of(TransactionFormActions.loadEnabledSolutionConfigsFailure({ error: error.message }));
          }),
        );
      }),
    );
  });

  determineDisabledProcessingPriorities$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TransactionFormActions.loadEnabledSolutionConfigsSuccess),
      exhaustMap(({ enabledSolutionConfigs }) => {
        const transactionTimeZone = enabledSolutionConfigs[0]?.solutionConfig?.transactionWindows[0]?.timeZone;
        const sameDayAchSubmitDeadline = getConfigEndTimeForSolution(enabledSolutionConfigs, 'ach');
        const sameDayWireSubmitDeadline = getConfigEndTimeForSolution(enabledSolutionConfigs, 'wire');

        const isAchSameDayDisabled = sameDayAchSubmitDeadline
          ? isBankingHoliday() || isTimeExpiredAtTimeZone(sameDayAchSubmitDeadline, transactionTimeZone)
          : true;
        const isWireSameDayDisabled = sameDayWireSubmitDeadline
          ? isBankingHoliday() || isTimeExpiredAtTimeZone(sameDayWireSubmitDeadline, transactionTimeZone)
          : true;
        const isWireNextDayDisabled = !isWireSameDayDisabled;
        return of(
          TransactionFormActions.setUnavailableProcessingPriorities({
            achSameDay: isAchSameDayDisabled,
            wireSameDay: isWireSameDayDisabled,
            wireNextDay: isWireNextDayDisabled,
          }),
        );
      }),
    );
  });

  determineTransactionLegType = createEffect(() => {
    return this.actions$.pipe(
      ofType(TransactionFormActions.setMoveTo),
      concatLatestFrom(() => [
        this.store.select(transactionFormFeature.selectFromAccount),
        this.store.select(transactionFormFeature.selectToAccount),
      ]),
      mergeMap(([financialAccount, fromAccount, toAccount]) => {
        if (!financialAccount || !fromAccount || !toAccount) {
          return of(TransactionFormActions.setTransactionType({ transactionType: null }));
        }

        /* MLT check allows only [EXTERNAL:CHK,EXTERNAL:SAV] -> [EXTERNAL:CHK, EXTERNAL:SAV, EXTERNAL:DBT]; */
        const isFromExternal = fromAccount.category === 'EXTERNAL' && toAccount.category === 'EXTERNAL';
        const isFromBankAccountToCardOrBankAccount =
          bankAccountSubtypes.includes(fromAccount.subtype) && [...cardAccountSubtypes, ...bankAccountSubtypes].includes(toAccount.subtype);
        /* MLT check end */

        return of(
          TransactionFormActions.setTransactionType({
            transactionType: isFromExternal && isFromBankAccountToCardOrBankAccount ? 'MLT' : 'SLT',
          }),
        );
      }),
    );
  });

  setFormUpdateMltData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TransactionFormActions.formUpdateMltInitialize),
      concatLatestFrom(() => [this.store.select(transactionFeature.selectMultiLegTransactionDetails)]),
      switchMap(([, multiLegTransactionDetails]) => {
        const { totalAmount, debits, credits, paymentReasons, stage, deliverySpeedOptions } = multiLegTransactionDetails ?? {};
        const debitLeg = debits?.[0];
        const creditLeg = credits?.[0];
        const fromAccount = debitLeg?.financialAccount;
        const toAccount = creditLeg?.financialAccount;
        return of(
          TransactionFormActions.setMultiLegTransactionDetailsFormData({
            formAction: 'EDIT_MLT',
            isDebitLegsDisabled: stage === 'FUNDING',
            paymentReasons: paymentReasons ?? [],
            amount: `${totalAmount}`,
            fromAccount: fromAccount
              ? {
                  ...fromAccount,
                  accountCategory: fromAccount.category,
                }
              : null,
            toAccount: toAccount
              ? {
                  ...toAccount,
                  accountCategory: toAccount.category,
                }
              : null,
            fromAccountMethod: debitLeg?.solution ?? null,
            toAccountMethod: creditLeg?.solution ?? null,
            transactionType: 'MLT',
            fromAccountPaymentReason: debitLeg?.paymentReasonId ?? null,
            toAccountPaymentReason: creditLeg?.paymentReasonId ?? null,
            fromAccountProcessingPriority:
              debitLeg?.settlementPriority && ['NEXT_DAY', 'SAME_DAY'].includes(debitLeg.settlementPriority)
                ? (debitLeg.settlementPriority as MultiLegTransactionProcessingPriority)
                : null,
            toAccountProcessingPriority:
              creditLeg?.settlementPriority && ['NEXT_DAY', 'SAME_DAY'].includes(creditLeg.settlementPriority)
                ? (creditLeg.settlementPriority as MultiLegTransactionProcessingPriority)
                : null,
            isWireMltTransaction: creditLeg?.solution === 'wire',
            isMltDisbursementStage: stage === 'DISBURSING',
            deliverySpeedOptions: deliverySpeedOptions ?? [],
          }),
        );
      }),
    );
  });

  setFormCancelMltData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TransactionFormActions.formCancelMltInitialize),
      concatLatestFrom(() => [this.store.select(transactionFeature.selectMultiLegTransactionDetails)]),
      switchMap(([, multiLegTransactionDetails]) => {
        const { totalAmount, currency } = multiLegTransactionDetails ?? {};
        return of(
          TransactionFormActions.setCancelMultiLegTransactionFormData({
            formAction: 'CANCEL_MLT',
            amount: `${totalAmount}`,
            currency: currency ?? null,
          }),
        );
      }),
    );
  });

  setFilterCancelMltData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TransactionFormActions.formCancelMltInitialize),
      switchMap(() => of(TransactionFormActions.setFinancialAccountsFilters({ filters: { accountHolderTypes: 'BUSINESS_ACCOUNT' } }))),
    );
  });

  setFormUpdateSltData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TransactionFormActions.formUpdateSltInitialize),
      concatLatestFrom(() => [this.store.select(transactionFeature.selectSingleLegTransactionDetails)]),
      switchMap(([, singleLegTransactionDetails]) => {
        const {
          amount,
          paymentReasons,
          debitFinancialAccount,
          creditFinancialAccount,
          metadata,
          solution,
          paymentReasonId,
          settlementPriority,
          deliverySpeedOptions,
        } = singleLegTransactionDetails ?? {};

        const fromAccount = debitFinancialAccount;
        const toAccount = creditFinancialAccount;
        return of(
          TransactionFormActions.setSingleLegTransactionDetailsFormData({
            formAction: 'EDIT_SLT',
            isDebitLegsDisabled: false,
            paymentReasons: paymentReasons ?? [],
            amount: `${amount}`,
            fromAccount: fromAccount
              ? {
                  ...fromAccount,
                  accountCategory: fromAccount.category,
                }
              : null,
            toAccount: toAccount
              ? {
                  ...toAccount,
                  accountCategory: toAccount.category,
                }
              : null,
            fromAccountMethod: (solution as MultiLegSolutionConfigSolutionName) ?? null,
            toAccountMethod: null,
            transactionType: 'SLT',
            fromAccountPaymentReason: paymentReasonId ?? null,
            toAccountPaymentReason: null,
            fromAccountProcessingPriority:
              settlementPriority && ['NEXT_DAY', 'SAME_DAY'].includes(settlementPriority)
                ? (settlementPriority as MultiLegTransactionProcessingPriority)
                : null,
            toAccountProcessingPriority: null,
            fromAccountAchDetails: { ...metadata },
            deliverySpeedOptions: deliverySpeedOptions ?? [],
          }),
        );
      }),
    );
  });

  setFormUpdateScheduledSltData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TransactionFormActions.formUpdateScheduledSltInitialize),
      concatLatestFrom(() => [this.store.select(transactionFeature.selectScheduledTransactionDetails)]),
      switchMap(([, scheduledTransactionDetails]) => {
        const {
          amount,
          paymentReasons,
          debitFinancialAccounts,
          creditFinancialAccounts,
          metadata,
          startDateTime,
          recurrenceRule,
          name,
          debits,
        } = scheduledTransactionDetails ?? {};
        const fromAccount = debitFinancialAccounts?.[0];
        const toAccount = creditFinancialAccounts?.[0];
        const debitLeg = debits?.[0];
        return of(
          TransactionFormActions.setScheduledSingleLegTransactionDetailsFormData({
            formAction: 'EDIT_SCHEDULED_SLT',
            isDebitLegsDisabled: false,
            paymentReasons: paymentReasons ?? [],
            amount: `${amount}`,
            fromAccount: fromAccount
              ? {
                  ...fromAccount,
                  accountCategory: fromAccount.category,
                }
              : null,
            toAccount: toAccount
              ? {
                  ...toAccount,
                  accountCategory: toAccount.category,
                }
              : null,
            fromAccountMethod: (debitLeg?.solution as MultiLegSolutionConfigSolutionName) ?? null,
            toAccountMethod: null,
            transactionType: 'SLT',
            fromAccountPaymentReason: debitLeg?.paymentReasonId ?? null,
            toAccountPaymentReason: null,
            fromAccountProcessingPriority:
              debitLeg?.settlementPriority && ['NEXT_DAY', 'SAME_DAY'].includes(debitLeg?.settlementPriority)
                ? (debitLeg?.settlementPriority as MultiLegTransactionProcessingPriority)
                : null,
            toAccountProcessingPriority: null,
            fromAccountAchDetails: { ...metadata },
            scheduler: {
              scheduleDateTime: startDateTime,
              recurrenceRule,
              timeZone: 'UTC',
              name,
            },
          }),
        );
      }),
    );
  });

  setFormUpdateScheduledMltData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TransactionFormActions.formUpdateScheduledMltInitialize),
      concatLatestFrom(() => [this.store.select(transactionFeature.selectScheduledTransactionDetails)]),
      switchMap(([, scheduledTransactionDetails]) => {
        const {
          amount,
          paymentReasons,
          debitFinancialAccounts,
          creditFinancialAccounts,
          metadata,
          startDateTime,
          recurrenceRule,
          name,
          debits,
          credits,
        } = scheduledTransactionDetails ?? {};
        const fromAccount = debitFinancialAccounts?.[0];
        const toAccount = creditFinancialAccounts?.[0];
        const debitLeg = debits?.[0];
        const creditLeg = credits?.[0];
        return of(
          TransactionFormActions.setScheduledMultiLegTransactionDetailsFormData({
            formAction: 'EDIT_SCHEDULED_MLT',
            isDebitLegsDisabled: false,
            paymentReasons: paymentReasons ?? [],
            amount: `${amount}`,
            fromAccount: fromAccount
              ? {
                  ...fromAccount,
                  accountCategory: fromAccount.category,
                }
              : null,
            toAccount: toAccount
              ? {
                  ...toAccount,
                  accountCategory: toAccount.category,
                }
              : null,
            fromAccountMethod: (debitLeg?.solution as MultiLegSolutionConfigSolutionName) ?? null,
            toAccountMethod: (creditLeg?.solution as MultiLegSolutionConfigSolutionName) ?? null,
            transactionType: 'MLT',
            fromAccountPaymentReason: debitLeg?.paymentReasonId ?? null,
            toAccountPaymentReason: creditLeg?.paymentReasonId ?? null,
            fromAccountProcessingPriority:
              debitLeg?.settlementPriority && ['NEXT_DAY', 'SAME_DAY'].includes(debitLeg?.settlementPriority)
                ? (debitLeg?.settlementPriority as MultiLegTransactionProcessingPriority)
                : null,
            toAccountProcessingPriority:
              creditLeg?.settlementPriority && ['NEXT_DAY', 'SAME_DAY'].includes(creditLeg.settlementPriority)
                ? (creditLeg.settlementPriority as MultiLegTransactionProcessingPriority)
                : null,
            fromAccountAchDetails: { ...metadata },
            scheduler: {
              scheduleDateTime: startDateTime,
              recurrenceRule,
              timeZone: 'UTC',
              name,
            },
          }),
        );
      }),
    );
  });

  setIdempotencyTimeConstraint$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        TransactionFormActions.formInitialize,
        TransactionFormActions.createTransactionPageReset,
        TransactionFormActions.createTransactionPageClear,
      ),
      switchMap(() => of(TransactionFormActions.setIdempotencyTimeConstraint({ timestamp: getTime(new Date()).toString() }))),
    );
  });

  loadDeliverySpeedOptions$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        TransactionFormActions.setMoveFrom,
        TransactionFormActions.setMoveTo,
        TransactionFormActions.updateMoveFrom,
        TransactionFormActions.updateMoveTo,
        TransactionFormActions.setScheduler,
      ),
      concatLatestFrom(() => [
        this.store.select(transactionFormFeature.selectFromAccount),
        this.store.select(transactionFormFeature.selectToAccount),
        this.store.select(transactionFormFeature.selectScheduler),
      ]),
      filter(([, fromAccount, toAccount]) => !!fromAccount?.id && !!toAccount?.id),
      switchMap(([, fromAccount, toAccount, scheduler]) => {
        const zonedDate = scheduler?.scheduleDateTime ? toZonedTime(scheduler.scheduleDateTime, systemTimeZone) : new Date();
        const formattedZonedDate = zonedDate ? format(zonedDate, "yyyy-MM-dd'T'HH:mm:ss.SSSXXX") : undefined;

        return this.multiLegTransactionsService
          .getDeliverySpeedOptions({
            debitFinancialAccountId: fromAccount!.id,
            creditFinancialAccountId: toAccount!.id,
            dateTime: formattedZonedDate,
          })
          .pipe(
            map((deliverySpeedOptions) => {
              return TransactionFormActions.setDeliverySpeedOptions({ deliverySpeedOptions });
            }),
            catchError(() => {
              return of(TransactionFormActions.setDeliverySpeedOptions({ deliverySpeedOptions: [] }));
            }),
          );
      }),
    );
  });
}
