<div class="details-page-header-wrapper" *ngIf="scheduledTransaction$ | async as scheduledTransaction; else loadingPlaceholder">
  <div class="page-header">
    <div class="details-page-back-title">
      <a [routerLink]="[pageReturnURL]" class="details-page-back-btn">
        <rkt-icon name="arrow-left" class="details-page-back-btn-icon"></rkt-icon>
      </a>

      <h1 class="details-page-title">
        {{
          scheduledTransaction.transactionDetails.name ??
            'Scheduled Transaction for ' + scheduledTransaction.transactionDetails.startDateTime
        }}
      </h1>

      <div
        class="status-tag"
        [class.no-value]="!scheduledTransaction.transactionDetails.status"
        [ngClass]="'status-tag-' + scheduledTransaction.transactionDetails.status.toLowerCase()"
      >
        {{ scheduledTransaction.transactionDetails.status | titlecase }}
      </div>
    </div>
    <app-breadcrumbs [itemList]="breadcrumbs"></app-breadcrumbs>
  </div>

  <div class="page-header-controls" *ngIf="businessAccountId$ | async as businessAccountId">
    <div class="entity-actions">
      <button
        appRemoveFocus
        class="action-button action-button-edit"
        [disabled]="isCancelled || isFinished || isPaused | accessControl | async"
        title="Edit Scheduled Transaction"
        (click)="onEdit()"
      >
        <rkt-icon name="edit"></rkt-icon>
      </button>

      <button
        appRemoveFocus
        *ngIf="!isPaused"
        class="action-button action-button-pause"
        [disabled]="isCancelled || isFinished | accessControl | async"
        title="Pause Scheduled Transaction"
        (click)="onPause(scheduledTransaction.transactionDetails.id)"
      >
        <rkt-icon name="suspend"></rkt-icon>
      </button>

      <button
        appRemoveFocus
        *ngIf="isPaused"
        class="action-button action-button-unpause"
        [disabled]="isCancelled || isFinished | accessControl | async"
        title="Resume Scheduled Transaction"
        (click)="onUnpause(scheduledTransaction.transactionDetails.id)"
      >
        <rkt-icon name="resume"></rkt-icon>
      </button>

      <button
        appRemoveFocus
        class="action-button action-button-cancel"
        [disabled]="isCancelled || isFinished | accessControl | async"
        title="Cancel Scheduled Transaction"
        (click)="onCancel(scheduledTransaction.transactionDetails.id)"
      >
        <rkt-icon name="times"></rkt-icon>
      </button>
    </div>
  </div>
</div>

<ng-template #loadingPlaceholder>
  <app-details-page-header-placeholder [pageReturnURL]="pageReturnURL"></app-details-page-header-placeholder>
</ng-template>

<div class="transaction-details-page scheduled-transaction-details-page">
  <div class="transaction-details">
    <div
      class="entity-details-container"
      appDragDropAttachment
      [entityId]="scheduledTransactionId"
      [entityType]="'SCHEDULED_TRANSACTION'"
      [attachmentsList]="attachmentsList"
    >
      <div class="attachments-hover-overlay"></div>
      <ng-container *ngIf="scheduledTransaction$ | async as scheduledTransaction; else transactionLoading">
        <ng-container
          *ngTemplateOutlet="transactionDetails; context: { $implicit: scheduledTransaction.transactionDetails, loading: false }"
        ></ng-container>
      </ng-container>
      <ng-template #transactionLoading>
        <ng-container *ngTemplateOutlet="transactionDetails; context: { $implicit: null, loading: true }"></ng-container>
      </ng-template>

      <ng-container *ngIf="scheduledTransaction$ | async as transaction">
        <div class="separator-row"></div>
        <app-attachments-list
          #attachmentsList
          [entityId]="transaction.transactionDetails.id"
          [entityType]="'SCHEDULED_TRANSACTION'"
        ></app-attachments-list>

        <app-details-notes entityType="SCHEDULED_TRANSACTION" [entityId]="transaction.transactionDetails.id"></app-details-notes>
      </ng-container>
    </div>
  </div>

  <div class="history-column">
    <app-transaction-history
      [transactionHistoryItems]="(scheduledTransaction$ | async)?.transactionHistory || []"
      [loading]="(scheduledTransaction$ | async)?.transactionHistory === null"
    ></app-transaction-history>
  </div>
</div>

<ng-template #transactionDetails [appTemplateContext]="scheduledTransactionDetailsContext" let-transaction let-loading="loading">
  <div class="schedule-recurring-details">
    <div class="title-wrapper">
      <div class="title">Scheduled Transaction Details</div>
      <div class="recurring-tag" [class.scheduled]="!transaction?.isRecurring" *ngIf="!loading">
        {{ transaction?.isRecurring ? 'Recurring' : 'Once-off' }}
      </div>
    </div>

    <div class="details-row">
      <ng-container *ngIf="transaction?.isRecurring">
        <app-details-item
          [showPlaceholder]="loading"
          label="Transactions Processed"
          [value]="transaction?.processedTransactions ?? '0'"
        ></app-details-item>

        <app-details-item
          [noBreak]="false"
          [showPlaceholder]="loading"
          label="Recurrence Rule"
          [value]="transaction?.recurrenceRuleParsed"
        ></app-details-item>

        <app-details-item
          [showPlaceholder]="loading"
          label="Next Transaction Scheduled for"
          [value]="transaction?.nextTransactionAt"
        ></app-details-item>
      </ng-container>

      <ng-container *ngIf="!transaction?.isRecurring">
        <app-details-item
          [isDarkBg]="true"
          [showPlaceholder]="loading"
          label="Transaction Scheduled for"
          [value]="transaction?.startDateTime"
        ></app-details-item>
      </ng-container>
    </div>
  </div>

  <div class="details-row">
    <app-details-item
      [class]="pageClassPrefix + 'id'"
      [showPlaceholder]="loading"
      label="Scheduler ID"
      [value]="transaction?.id || '' | rktShortId"
    ></app-details-item>

    <app-details-item
      class="transaction-type"
      [showPlaceholder]="loading"
      label="Transaction Type"
      [value]="transaction?.transactionType"
    ></app-details-item>
  </div>

  <div class="separator-row"></div>

  <div class="amount-row">
    <div class="amount-label">
      <app-text-with-placeholder [showPlaceholder]="loading">Amount</app-text-with-placeholder>
    </div>
    <div class="amount-value">
      <app-text-with-placeholder [showPlaceholder]="loading">
        {{ transaction?.amount | amount: transaction?.currency }} {{ transaction?.currency }}
      </app-text-with-placeholder>
    </div>
  </div>

  <div class="from-to-row">
    <div class="from-to-item">
      <div class="title">
        <app-text-with-placeholder [showPlaceholder]="loading">From Account</app-text-with-placeholder>
      </div>
      <ng-container
        *ngTemplateOutlet="fromToAccount; context: { $implicit: transaction?.debitFinancialAccounts?.[0], transaction, loading: loading }"
      ></ng-container>
    </div>

    <div class="from-to-item">
      <div class="title">
        <app-text-with-placeholder [showPlaceholder]="loading">To Account</app-text-with-placeholder>
      </div>
      <ng-container
        *ngTemplateOutlet="fromToAccount; context: { $implicit: transaction?.creditFinancialAccounts?.[0], transaction, loading: loading }"
      ></ng-container>
    </div>
  </div>

  <!-- MLT -->
  <div class="estimate-details" *ngIf="transaction?.transactionType === 'MLT' && !!transaction?.estLabel">
    <div class="estimate-details-title">
      {{ transaction?.estLabel }}
    </div>
    <div class="estimate-details-sections">
      <div class="estimate-details-inner">
        <div class="details-item">
          <div class="details-label">Payment Reason:</div>
          <div class="details-value">{{ transaction?.credits?.[0]?.paymentReason | notAvailable }}</div>
        </div>

        <ng-container *ngIf="transaction?.credits?.[0]?.solution === 'wire'">
          <div class="details-item">
            <div class="details-label">Narrative:</div>
            <div class="details-value">{{ transaction?.description }}</div>
          </div>

          <div class="details-item">
            <div class="details-label">Memo:</div>
            <div class="details-value" [class.empty-value]="!transaction?.memo">
              {{ transaction?.memo | notAvailable }}
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="transaction?.credits?.[0]?.solution === 'ach'">
          <div class="details-item" *ngIf="transaction?.credits?.[0]?.solution === 'ach' && transaction?.transactionType !== 'RETURN'">
            <div class="details-label">SEC Code:</div>
            <div class="details-value">
              {{ transaction.metadata?.rkorACHEntryType || 'CCD' }}
            </div>
          </div>

          <div class="details-item" *ngIf="transaction.metadata?.rkorACHIndividualId">
            <div class="details-label">Individual ID:</div>
            <div class="details-value">{{ transaction.metadata?.rkorACHIndividualId }}</div>
          </div>

          <div class="details-item" *ngIf="transaction.metadata?.rkorACHCheckSerialNumber">
            <div class="details-label">Check Serial Number:</div>
            <div class="details-value">{{ transaction.metadata?.rkorACHCheckSerialNumber }}</div>
          </div>

          <div class="details-item" *ngIf="transaction.metadata?.rkorACHTerminalCity">
            <div class="details-label">Terminal City:</div>
            <div class="details-value">{{ transaction.metadata?.rkorACHTerminalCity }}</div>
          </div>

          <div class="details-item" *ngIf="transaction.metadata?.rkorACHTerminalState">
            <div class="details-label">Terminal State:</div>
            <div class="details-value">{{ transaction.metadata?.rkorACHTerminalState }}</div>
          </div>
        </ng-container>
      </div>
      <div class="estimate-details-inner">
        <div class="details-item">
          <div class="details-label">Funds Delivery Estimate:</div>
          <div class="details-value">
            {{ transaction?.estLabelDay }} – {{ transaction?.estLabelDate }}
            <div class="details-value-estimation">
              We estimate the funds will settle at the receiving institution in the
              <span class="strong">{{ transaction?.estLabelTime }}</span> of
              <span class="strong">{{ transaction?.estLabelDate }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--SLT -->
  <div class="estimate-details" *ngIf="transaction?.transactionType !== 'MLT' && !!transaction?.estLabel">
    <div class="estimate-details-title">
      {{ transaction?.estLabel }}
    </div>
    <div class="estimate-details-sections">
      <div class="estimate-details-inner">
        <div class="details-item">
          <div class="details-label">Payment Reason:</div>
          <div class="details-value">{{ transaction?.debits?.[0]?.paymentReason | notAvailable }}</div>
        </div>

        <ng-container *ngIf="transaction?.debits?.[0]?.solution === 'wire'">
          <div class="details-item">
            <div class="details-label">Narrative:</div>
            <div class="details-value">{{ transaction?.description }}</div>
          </div>

          <div class="details-item">
            <div class="details-label">Memo:</div>
            <div class="details-value" [class.empty-value]="!transaction?.memo">
              {{ transaction?.memo | notAvailable }}
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="transaction?.debits?.[0]?.solution === 'ach'">
          <div class="details-item" *ngIf="transaction?.debits?.[0]?.solution === 'ach' && transaction?.transactionType !== 'RETURN'">
            <div class="details-label">SEC Code:</div>
            <div class="details-value">
              {{ transaction.metadata?.rkorACHEntryType || 'CCD' }}
            </div>
          </div>

          <div class="details-item" *ngIf="transaction.metadata?.rkorACHIndividualId">
            <div class="details-label">Individual ID:</div>
            <div class="details-value">{{ transaction.metadata?.rkorACHIndividualId }}</div>
          </div>

          <div class="details-item" *ngIf="transaction.metadata?.rkorACHCheckSerialNumber">
            <div class="details-label">Check Serial Number:</div>
            <div class="details-value">{{ transaction.metadata?.rkorACHCheckSerialNumber }}</div>
          </div>

          <div class="details-item" *ngIf="transaction.metadata?.rkorACHTerminalCity">
            <div class="details-label">Terminal City:</div>
            <div class="details-value">{{ transaction.metadata?.rkorACHTerminalCity }}</div>
          </div>

          <div class="details-item" *ngIf="transaction.metadata?.rkorACHTerminalState">
            <div class="details-label">Terminal State:</div>
            <div class="details-value">{{ transaction.metadata?.rkorACHTerminalState }}</div>
          </div>
        </ng-container>
      </div>
      <div class="estimate-details-inner">
        <div class="details-item">
          <div class="details-label">Funds Delivery Estimate:</div>
          <div class="details-value">
            {{ transaction?.estLabelDay }} – {{ transaction?.estLabelDate }}
            <div class="details-value-estimation">
              We estimate the funds will settle at the receiving institution in the
              <span class="strong">{{ transaction?.estLabelTime }}</span> of
              <span class="strong">{{ transaction?.estLabelDate }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-container *ngIf="transaction?.processedTransactions">
    <div class="upcoming-transactions previous-transactions">
      <div class="upcoming-transactions-title" (click)="isShowPrevious = !isShowPrevious">
        <div class="title">Previous {{ transaction.processedTransactions }} transactions</div>
        <rkt-icon [name]="isShowPrevious ? 'chevron-down' : 'chevron-right'"></rkt-icon>
      </div>

      <div class="previous-transactions" *ngIf="isShowPrevious">
        <app-scheduled-transaction-previous-transactions
          [schedulerId]="transaction.id"
          [isMLT]="transaction.transactionType === 'MLT'"
        ></app-scheduled-transaction-previous-transactions>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="isRecurring && !isCancelled && !isPaused && transaction.nextTransactions?.length">
    <div class="upcoming-transactions">
      <div class="upcoming-transactions-title" (click)="isShowNext = !isShowNext">
        <div class="title">Next {{ transaction.nextTransactions?.length }} transactions</div>
        <rkt-icon [name]="isShowNext ? 'chevron-down' : 'chevron-right'"></rkt-icon>
      </div>

      <div class="upcoming-transaction-items" *ngIf="isShowNext">
        <div class="upcoming-transaction-item" *ngFor="let nextTransactionDate of transaction.nextTransactions">
          <div class="date">{{ nextTransactionDate }}</div>

          <div class="amount">
            $ {{ (transaction?.amount | number: '1.2-2') || '' | mask: 'separator' : { thousandSeparator: ',' } }}
            {{ transaction?.currency }}
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="!loading">
    <div class="transaction-metadata">
      <div class="transaction-metadata-title" (click)="isShowMetadata = !isShowMetadata">
        <div class="title">Metadata</div>
        <rkt-icon [name]="isShowMetadata ? 'chevron-down' : 'chevron-right'"></rkt-icon>
      </div>

      <div class="transaction-metadata-items" *ngIf="isShowMetadata">
        <div class="transaction-metadata-item transaction-metadata-header-item">
          <div class="key">Key</div>

          <div class="value">Value</div>
        </div>
        <div class="transaction-metadata-item" *ngFor="let metadataItem of transaction.metadataItems">
          <div class="key">{{ metadataItem.key }}</div>

          <div class="value">
            {{ metadataItem.value }}
          </div>
        </div>
        <div class="transaction-metadata-empty-item" *ngIf="!transaction.metadataItems.length">No Transaction Metadata</div>
      </div>
    </div>
  </ng-container>

  <div class="create-update-details">
    <app-details-item [showPlaceholder]="loading" label="Created At" [value]="transaction?.createdAt | dateFormat"></app-details-item>

    <app-details-item
      [showPlaceholder]="loading"
      label="Created By"
      [value]="transaction?.createdBy?.id | teamMemberName | async"
      [noBreak]="true"
    ></app-details-item>

    <app-details-item [showPlaceholder]="loading" label="Updated At" [value]="transaction?.updatedAt | dateFormat"></app-details-item>

    <app-details-item
      [showPlaceholder]="loading"
      label="Updated By"
      [value]="transaction?.updatedBy?.id | teamMemberName | async"
      [noBreak]="true"
    ></app-details-item>
  </div>
</ng-template>

<ng-template #fromToAccount let-financialAccount let-transaction="transaction" let-loading="loading">
  <div class="item-body">
    <div class="item-body-row" [class.empty-value]="!financialAccount?.accountHolderInfo">
      <div class="label">
        <app-text-with-placeholder class="placeholder" [showPlaceholder]="loading" [darkBg]="true"
          >Account Holder</app-text-with-placeholder
        >
      </div>
      <div class="value">
        <div [class.empty-value]="!(financialAccount?.accountHolderInfo || '' | rktSplit: '::' : 0)">
          <app-text-with-placeholder [showPlaceholder]="loading" [darkBg]="true">
            {{ financialAccount?.accountHolderInfo || '' | rktSplit: '::' : 0 | notAvailable }}
          </app-text-with-placeholder>
        </div>
        <div [class.empty-value]="!(financialAccount?.accountHolderInfo || '' | rktSplit: '::' : 1)">
          <app-text-with-placeholder [showPlaceholder]="loading" [darkBg]="true">
            {{ financialAccount?.accountHolderInfo || '' | rktSplit: '::' : 1 | notAvailable }}
          </app-text-with-placeholder>
        </div>
      </div>
    </div>

    <ng-container
      *ngTemplateOutlet="
        itemBodyRows;
        context: {
          $implicit: [
            { value: financialAccount?.accountInfo, label: 'Account Info', splitable: true },
            { value: financialAccount?.type, label: 'Account Type' },
            { value: financialAccount?.category, label: 'Account Category' },
            {
              value: financialAccount?.address,
              label: 'Address',
              className: (!!financialAccount?.bankAccount ? 'item-body-row-with-separator ' : '') + 'item-body-row-tall',
              type: 'address',
            },
          ],
          loading,
        }
      "
    ></ng-container>

    <div class="item-body-row" *ngIf="financialAccount?.bankAccount">
      <div class="label">
        <app-text-with-placeholder class="placeholder" [showPlaceholder]="loading" [darkBg]="true">Routing No.</app-text-with-placeholder>
      </div>
      <div class="value">
        <app-text-with-placeholder class="placeholder" [showPlaceholder]="loading" [darkBg]="true">
          {{ financialAccount?.bankAccount?.routingNo | notAvailable }}
        </app-text-with-placeholder>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #itemBodyRows let-loading="loading" let-rows>
  <div class="item-body-row" [class.empty-value]="!row.value" [ngClass]="row.className" *ngFor="let row of rows">
    <div class="label" *ngIf="row.label">
      <app-text-with-placeholder class="placeholder" [showPlaceholder]="loading" [darkBg]="true">{{ row.label }}</app-text-with-placeholder>
    </div>
    <div class="value" *ngIf="row.type !== 'address' || (row.type === 'address' && !row.value)">
      <ng-container *ngIf="row.splitable">
        <div [class.empty-value]="!(row.value || '' | rktSplit: '::' : 0)">
          <app-text-with-placeholder [showPlaceholder]="loading" [darkBg]="true">
            {{ row.value || '' | rktSplit: '::' : 0 | notAvailable }}
          </app-text-with-placeholder>
        </div>
        <div [class.empty-value]="!(row.value || '' | rktSplit: '::' : 1)">
          <app-text-with-placeholder [showPlaceholder]="loading" [darkBg]="true">
            {{ row.value || '' | rktSplit: '::' : 1 | notAvailable }}
          </app-text-with-placeholder>
        </div>
      </ng-container>

      <app-text-with-placeholder *ngIf="!row.splitable" class="placeholder" [showPlaceholder]="loading" [darkBg]="true">
        {{ row.value | notAvailable }}
      </app-text-with-placeholder>
    </div>

    <div class="value" *ngIf="row.type === 'address' && row.value">
      <app-text-with-placeholder class="placeholder" [showPlaceholder]="loading" [darkBg]="true">
        {{ row.value?.addressLine1 }},<br />
        <ng-container *ngIf="row.value?.addressLine2">{{ row.value?.addressLine2 }}<br /></ng-container>
        {{ row.value?.city }}, {{ row.value?.state }}<br />
        {{ row.value?.postalCode }}
        {{ row.value?.country }}
      </app-text-with-placeholder>
    </div>
  </div>
</ng-template>
