import { createReducer, on } from '@ngrx/store';

import { ErrorMessage, ReportList } from '@shared/models';

import { ReportsActions } from './reports.actions';

export const reportListFeatureKey = 'list';

export interface ReportsState {
  reports: ReportList['items'] | null;
  totalElements: number;
  isLoading: boolean;
  error: ErrorMessage;
}

export const initialReportsState: ReportsState = {
  reports: null,
  totalElements: 0,
  isLoading: false,
  error: null,
};

export const reducer = createReducer<ReportsState>(
  initialReportsState,
  on(ReportsActions.loadReports, (state): ReportsState => {
    return {
      ...state,
      isLoading: true,
      error: null,
    };
  }),
  on(ReportsActions.loadReportsSuccess, (state, action): ReportsState => {
    return {
      ...state,
      reports: action.reports,
      totalElements: action.totalElements,
      isLoading: false,
    };
  }),
  on(ReportsActions.loadReportsError, (state, action): ReportsState => {
    return {
      ...state,
      isLoading: false,
      error: action.error,
    };
  }),
);

export const getReports = (state: ReportsState) => state.reports;
export const getTotalElements = (state: ReportsState) => state.totalElements;
export const getLoading = (state: ReportsState) => state.isLoading;
