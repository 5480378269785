import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { compact, omit } from 'lodash-es';
import { Observable, of, tap, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { environment } from '@env';
import { constants } from '@shared/constants';
import { CustomHttpParamEncoder } from '@shared/encoder';
import {
  DocumentCreateModel,
  DocumentCreateResponse,
  DocumentDetails,
  DocumentDetailsRaw,
  DocumentList,
  DocumentListRaw,
  DocumentListRequest,
  DocumentUpdateModel,
} from '@shared/models';
import { AuditActions } from '@shared/store';
import { documentTypeDisplayName } from '@shared/utils';

import { BackendService } from '../backend.service';
import { mapDocumentDetails } from './document-mapping-utils';

@Injectable({
  providedIn: 'root',
})
export class DocumentService {
  constructor(
    private backendService: BackendService,
    private store: Store,
  ) {}

  getDocuments({ holderId, page, size, sortParams }: DocumentListRequest): Observable<DocumentList> {
    const pageSize = size ?? constants.TABLE_ROWS;
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() }).set('page', `${page ?? 0}`).set('size', `${pageSize}`);

    if (sortParams) {
      params = params.append('sort', `${sortParams.key},${sortParams.sortDir}`);
    }

    return this.backendService.get<DocumentListRaw>(`${environment.documentService}/documents?holderId=${holderId}`, { params }).pipe(
      map((response) => this.mapDocumentList(response)),
      catchError((errorRes) => throwError(() => errorRes)),
    );
  }

  createDocument(documentData: DocumentCreateModel): Observable<boolean> {
    return this.backendService.post<DocumentCreateResponse>(`${environment.documentService}/documents`, omit(documentData, 'file')).pipe(
      switchMap((document) => {
        return this.uploadDocumentFile({ url: document?.url.value, file: documentData.file, extension: document.extension });
      }),
      catchError((errorRes) => throwError(() => errorRes)),
    );
  }

  updateDocument(documentId: string, documentData: Partial<DocumentUpdateModel>): Observable<boolean> {
    return this.backendService
      .update<DocumentCreateResponse>(`${environment.documentService}/documents/${documentId}`, omit(documentData, 'file'))
      .pipe(
        switchMap((document) => {
          if (!documentData.file) {
            return of(null);
          } else {
            return this.getUploadDocumentLink(document, {
              contentLength: documentData.contentLength!,
              fileExtension: documentData.extension!,
            });
          }
        }),
        switchMap((url) => {
          if (url?.value) {
            return this.uploadDocumentFile({
              url: url.value,
              file: documentData.file as string,
              extension: documentData.extension!,
            });
          } else return of(true);
        }),
        map(() => true),
        catchError((errorRes) => throwError(() => errorRes)),
      );
  }

  getUploadDocumentLink(
    { id, businessAccountId }: DocumentCreateResponse,
    fileData: { contentLength: number; fileExtension: string },
  ): Observable<{
    allow: string;
    expiresIn: number;
    value: string;
  }> {
    const params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set('contentUrlType', 'UPLOAD')
      .set('businessAccountId', `${businessAccountId}`)
      .set('contentLength', fileData.contentLength)
      .set('fileExtension', fileData.fileExtension);

    return this.backendService.get(`${environment.documentService}/documents/${id}/content-url`, { params });
  }

  uploadDocumentFile({ url, file, extension }: { url: string; file: string; extension: string }): Observable<boolean> {
    const contentTypes: Record<string, string> = {
      JPG: 'image/jpeg',
      JPEG: 'image/jpeg',
      PNG: 'image/png',
      PDF: 'application/pdf',
    };

    const requestHeaders = {
      headers: new HttpHeaders({
        'Content-Type': contentTypes[extension.toUpperCase()],
      }),
    };

    return this.backendService.upload(url, file, requestHeaders).pipe(
      map(() => true),
      catchError((errorRes) => throwError(() => errorRes)),
    );
  }

  mapDocumentList(response: DocumentListRaw): DocumentList {
    const { content = [], totalElements = 0, hasNext = false } = response;
    const items = content.map((item) => ({ ...item, documentTypeDisplayName: documentTypeDisplayName(item.documentType) }));

    return {
      items,
      totalElements,
      hasNext,
    };
  }

  getDocumentById({ documentId }: { documentId: string }): Observable<DocumentDetails> {
    return this.backendService.get<DocumentDetailsRaw>(`${environment.documentService}/documents/${documentId}`).pipe(
      tap({
        next: (response) => {
          const { createdBy, updatedBy } = response;
          this.store.dispatch(AuditActions.loadTeamMembers({ ids: compact([createdBy, updatedBy]) }));
        },
      }),
      map((response) => mapDocumentDetails(response)),
      catchError((errorRes) => throwError(() => errorRes)),
    );
  }

  deleteDocument({ documentId }: { documentId: string }) {
    return this.backendService
      .delete<void>(`${environment.documentService}/documents/${documentId}`)
      .pipe(catchError((errorRes) => throwError(() => errorRes)));
  }
}
