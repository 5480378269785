import { createAction, props } from '@ngrx/store';

import { AdaListRequestParams, AdaRegistrationList, ErrorMessage } from '@shared/models';

export namespace AdaRegistrationListActions {
  export const loadAdaRegistrations = createAction(
    '[Debit Authorizations API] Load Debit Authorizations',
    props<{ params: AdaListRequestParams }>(),
  );

  export const loadAdaRegistrationsSuccess = createAction(
    '[Debit Authorizations API] Load Debit Authorizations Success',
    props<{ adaRegistrations: AdaRegistrationList['items']; totalElements: number }>(),
  );

  export const loadAdaRegistrationsError = createAction(
    '[Debit Authorizations API] Load Debit Authorizations Error',
    props<{ error: ErrorMessage }>(),
  );
}
