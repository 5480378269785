import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RktFormComponent, RktFormFieldConfig } from '@rocketfinancialcorp/rocket-ui/form';

import { AddressesByType, FormModel } from '@shared/models';

import { addressFormFields } from './address-add-form';

@Component({
  selector: 'app-address-add',
  templateUrl: 'address-add.component.html',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, RktFormComponent],
})
export class AddressAddComponent implements OnInit {
  @Input() isPhysicalAddressRequired?: boolean = false;
  @Input() initialAddresses?: AddressesByType;

  @Output() changed = new EventEmitter<AddressesByType>();
  @Output() isFormInvalid = new EventEmitter<boolean>();

  addressForm = new FormGroup({});

  formModel: FormModel<AddressesByType> = {
    physicalAddresses: [{ addressLine1: '', city: '', state: '', country: '', postalCode: '' }],
    mailingAddresses: [{ addressLine1: '', city: '', state: '', country: '', postalCode: '' }],
    shippingAddresses: [{ addressLine1: '', city: '', state: '', country: '', postalCode: '' }],
  };

  addressFields: RktFormFieldConfig[] = [];

  formError = '';

  constructor(private ref: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.addressFields = addressFormFields(this.isPhysicalAddressRequired ?? false);
    if (this.initialAddresses) this.formModel = { ...this.formModel, ...this.initialAddresses };
  }

  onFormUpdate(): void {
    this.changed.emit({
      physicalAddresses: this.formModel.physicalAddresses?.map((address, i) => ({ ...address, addressType: 'PHYSICAL', primary: i === 0 })),
      mailingAddresses: this.formModel.mailingAddresses?.map((address, i) => ({ ...address, addressType: 'MAILING', primary: i === 0 })),
      shippingAddresses: this.formModel.shippingAddresses?.map((address, i) => ({ ...address, addressType: 'SHIPPING', primary: i === 0 })),
    });

    this.isFormInvalid.emit(this.addressForm.invalid);

    if (this.formError) {
      this.formError = '';
    }

    this.ref.markForCheck();
  }
}
