import { createReducer, on } from '@ngrx/store';

import { AuthActions } from './auth.actions';
import { LoginPageActions } from './login-page.actions';

export const loginPageFeatureKey = 'loginPage';

export interface LoginPageState {
  error: string | null;
  pending: boolean;
}

export const initialState: LoginPageState = {
  error: null,
  pending: false,
};

export const reducer = createReducer(
  initialState,
  on(LoginPageActions.initialize, (state): LoginPageState => {
    return {
      ...state,
      error: null,
      pending: false,
    };
  }),
  on(LoginPageActions.login, (state): LoginPageState => {
    return {
      ...state,
      error: null,
      pending: true,
    };
  }),
  on(LoginPageActions.resetLoginError, (state): LoginPageState => {
    return { ...state, error: null };
  }),
  on(AuthActions.loginSuccess, (state): LoginPageState => {
    return {
      ...state,
      error: null,
      pending: false,
    };
  }),
  on(AuthActions.loginFailure, (state, { error }): LoginPageState => {
    return {
      ...state,
      error,
      pending: false,
    };
  }),
);

export const getError = (state: LoginPageState) => state.error;

export const getPending = (state: LoginPageState) => state.pending;
