import { Component } from '@angular/core';
import { RktButtonDirective } from '@rocketfinancialcorp/rocket-ui/button';
import { ActiveModal } from '@rocketfinancialcorp/rocket-ui/modal';

import { AttentionModalComponent } from '../attention-modal/attention-modal.component';

@Component({
  selector: 'app-page-leave-confirmation-modal',
  templateUrl: './page-leave-confirmation-modal.component.html',
  standalone: true,
  imports: [RktButtonDirective, AttentionModalComponent],
})
export class PageLeaveConfirmationModalComponent {
  constructor(public activeModal: ActiveModal) {}
}
