import { Injectable } from '@angular/core';
import { IndividualConfig } from 'ngx-toastr';

import { NgxToastrService } from './ngx-toastr.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private ngxToastrService: NgxToastrService) {}

  displaySuccess(message: string, title?: string, messageConfig?: Partial<IndividualConfig>): void {
    this.ngxToastrService.show({ message, title }, 'toast-success', messageConfig);
  }

  displayError(message: string, title?: string, messageConfig?: Partial<IndividualConfig>): void {
    this.ngxToastrService.show({ message, title }, 'toast-error', messageConfig);
  }

  displayWarning(message: string, title?: string, messageConfig?: Partial<IndividualConfig>): void {
    this.ngxToastrService.show({ message, title }, 'toast-warning', messageConfig);
  }

  displayInfo(message: string, title?: string, messageConfig?: Partial<IndividualConfig>): void {
    this.ngxToastrService.show({ message, title }, 'toast-info', messageConfig);
  }

  clearAll(): void {
    this.ngxToastrService.clear();
  }
}
