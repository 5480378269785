import { NgIf } from '@angular/common';
import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { isEmpty, isUndefined } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RktIconComponent } from '@rocketfinancialcorp/rocket-ui/icon';
import {
  DatatableColumn,
  DatatableSorting,
  FetchData,
  RktTableComponent,
  RktTableSearchComponent,
} from '@rocketfinancialcorp/rocket-ui/table';

import { FilterField, FilterValues, RequestPageParams, TransactionList, TransactionListItem } from '@shared/models';
import { TransactionService } from '@shared/services';
import { ErrorUtils, activeFilters } from '@shared/utils';

import { FilterMenuComponent } from '../../../../shared';

@Component({
  selector: 'app-transaction-list',
  templateUrl: './transaction-list.component.html',
  standalone: true,
  imports: [RktTableSearchComponent, RktIconComponent, NgIf, RktTableComponent, FilterMenuComponent],
})
export class TransactionListComponent implements OnInit, OnDestroy, AfterViewChecked {
  loading = false;

  searchString?: string;

  transactions: TransactionListItem[] = [];

  transactionsListItems: TransactionListItem[] = [];

  filters: FilterField[] = [
    {
      name: 'date',
      displayName: 'Date',
      type: 'DATE',
      options: [
        { label: 'Default: 30 days', value: 'DEFAULT' },
        { label: 'Today', value: 'TODAY' },
        { label: 'Yesterday', value: 'YESTERDAY' },
        { label: 'This week', value: 'THIS_WEEK' },
        { label: 'This month', value: 'THIS_MONTH' },
        { label: 'Custom range', value: 'CUSTOM' },
      ],
      defaultValue: 'DEFAULT',
      props: {
        maxMonths: '15',
      },
    },
    {
      name: 'maskedDebitAccountNumber',
      displayName: 'From Account',
      type: 'INPUT',
      props: {
        label: 'Account No.',
        maxLength: '20',
        validationPattern: '[0-9*]*',
        validationPatternMessage: 'Account Number only allow numbers (digits) and asterisk',
      },
    },
    {
      name: 'maskedCreditAccountNumber',
      displayName: 'To Account',
      type: 'INPUT',
      props: {
        label: 'Account No.',
        maxLength: '20',
        validationPattern: '[0-9*]*',
        validationPatternMessage: 'Account Number only allow numbers (digits) and asterisk',
      },
    },
    {
      name: 'amount',
      displayName: 'Amount',
      type: 'AMOUNT',
    },
    {
      name: 'latestStatus',
      displayName: 'Status',
      type: 'RADIO',
      options: [
        { label: 'All', value: undefined },
        { label: 'Approved', value: 'APPROVED' },
        { label: 'Cancelled', value: 'CANCELLED' },
        { label: 'Cleared', value: 'CLEARED' },
        { label: 'Declined', value: 'DECLINED' },
        { label: 'Error', value: 'ERROR' },
        { label: 'New', value: 'NEW' },
        { label: 'Pending', value: 'PENDING' },
        { label: 'Processing', value: 'PROCESSING' },
        { label: 'Settled', value: 'SETTLED' },
        { label: 'Reversed', value: 'REVERSED' },
      ],
    },
    {
      name: 'origin',
      displayName: 'Transaction origin',
      type: 'RADIO',
      options: [
        { label: 'All', value: undefined },
        { label: 'Single', value: 'SINGLE' },
        { label: 'Batch', value: 'BATCH' },
      ],
    },
  ];

  activeFilters: FilterValues = { date: 'DEFAULT' };

  columns: DatatableColumn[] = [
    { name: 'Created At', prop: 'createdAt', fixedSize: 150, isSortable: true, colType: 'date-time' },
    { name: 'Transaction ID', prop: 'id', colType: 'short-id', flexSize: 160 },
    { name: 'Move From', prop: 'moveFrom', colType: 'two-line-col', flexSize: 315 },
    { name: 'Move To', prop: 'moveTo', colType: 'two-line-col', flexSize: 315 },
    { name: 'Solution', prop: 'solutionName', flexSize: 160 },
    { name: 'Type', prop: 'transactionType', flexSize: 120 },
    { name: 'Amount', prop: 'amount', flexSize: 151, isSortable: true, colType: 'amount', colAlign: 'right' },
    {
      name: 'Status',
      prop: 'status',
      sortProp: 'latestStatus',
      fixedSize: 140,
      isSortable: true,
      colType: 'status-tag',
      colAlign: 'right',
    },
  ];

  totalElements = 0;

  sortParams: DatatableSorting = { key: 'createdAt', sortProp: 'createdAt', sortDir: 'desc' };

  emptyListMessage = 'No matching Transactions found.';

  get activePage(): number {
    const routePage = this.activatedRoute.snapshot.queryParamMap.get('page');
    return routePage ? parseInt(routePage, 10) : 0;
  }

  get activeFiltersCount(): number {
    return Object.values(this.activeFilters).length;
  }

  private destroy$ = new Subject<void>();

  constructor(
    public ref: ChangeDetectorRef,
    private transactionService: TransactionService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    this.transactionService.activeTransactionFilters.subscribe({
      next: (filters: FilterValues) => {
        if (isEmpty(filters)) {
          this.transactionService.getFilterParams();
        } else {
          this.activeFilters = filters;
        }
      },
    });
  }

  ngOnInit(): void {
    this.transactionService.setFilterParams(this.activeFilters);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngAfterViewChecked(): void {
    this.ref.detectChanges();
  }

  onSearch(event?: string): void {
    if (!event) {
      this.onSearchReset();
      return;
    }
    this.searchString = event;
    this.getTransactions({ page: 0 });
  }

  onSearchReset(): void {
    if (!this.searchString) {
      return;
    }
    this.searchString = undefined;
    this.transactions = this.transactionsListItems;
    this.totalElements = this.transactionsListItems.length;
    this.getTransactions({ page: this.activePage });
  }

  updateQueryParams(page?: RequestPageParams['page']): void {
    if (!isUndefined(page) && this.activePage !== page) {
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: { page: page > 0 ? page : undefined },
        queryParamsHandling: 'merge',
      });
    }
  }

  getTransactions({ page, size, sortParams }: FetchData): void {
    this.loading = true;
    this.updateQueryParams(page);

    if (sortParams) {
      this.sortParams = sortParams;
    }

    this.transactionService
      .getTransactions({
        page: page || 0,
        size,
        sortParams: sortParams || this.sortParams,
        searchString: this.searchString,
        activeFilters: { ...this.activeFilters },
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response) => {
          this.emptyListMessage = 'No matching Transactions found.';
          this.onFetchComplete(response);
        },
        error: (error) => {
          this.emptyListMessage = 'Unable to fetch Transaction Activity.';
          this.onFetchComplete();
          ErrorUtils.catchError('transactionService.getTransactions', error);
        },
      });
  }

  onFetchComplete(response?: TransactionList) {
    this.transactions = response?.items || [];
    this.transactionsListItems = response?.items || [];
    this.totalElements = response?.totalElements || 0;
    this.loading = false;
  }

  onRowClick({ id }: TransactionListItem) {
    this.router.navigateByUrl(`/app/transactions/transactions/${id}`);
  }

  applyFilters(filterValues: FilterValues): void {
    this.activeFilters = activeFilters(filterValues);
    this.transactionService.setFilterParams(this.activeFilters);
    this.getTransactions({ page: 0 });
  }
}
