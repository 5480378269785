import { NgIf, TitleCasePipe } from '@angular/common';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { AfterViewChecked, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { parsePhoneNumber } from 'libphonenumber-js';
import { pick } from 'lodash-es';
import { Subject, of } from 'rxjs';
import { catchError, finalize, map, switchMap, takeUntil } from 'rxjs/operators';
import { RktButtonDirective } from '@rocketfinancialcorp/rocket-ui/button';
import { RktFormComponent } from '@rocketfinancialcorp/rocket-ui/form';
import { RktIconComponent } from '@rocketfinancialcorp/rocket-ui/icon';
import { ActiveModal } from '@rocketfinancialcorp/rocket-ui/modal';

import { MESSAGE } from '@shared/constants';
import {
  AddTeamMemberParams,
  FormModel,
  PermissionRoles,
  RoleOption,
  TeamMember,
  TeamMemberFormModalParams,
  TeamMemberProfile,
  TeamMemberRaw,
} from '@shared/models';
import { NoteService, NotificationService, RoleService, TeamMemberService } from '@shared/services';
import { ErrorUtils } from '@shared/utils';
import { emptySpacesValidator } from '@shared/validators';

import { formFields } from './form-fields';
import { NoteFormItemComponent } from '../../notes/note-form-item/note-form-item.component';

@Component({
  selector: 'app-team-member-edit-form',
  templateUrl: './team-member-edit-form.component.html',
  standalone: true,
  imports: [
    RktButtonDirective,
    FormsModule,
    ReactiveFormsModule,
    RktFormComponent,
    NgIf,
    NoteFormItemComponent,
    TitleCasePipe,
    RktIconComponent,
  ],
})
export class TeamMemberEditFormComponent implements OnInit, AfterViewChecked, OnDestroy {
  @Input() teamMember!: TeamMember;

  actionType?: 'add' | 'edit';

  submitError = '';

  isLoading = false;

  public editForm = new FormGroup({});

  public profileModel: FormModel<TeamMemberProfile> = { firstName: undefined, middleName: undefined, lastName: undefined };

  public formFields = formFields();

  roleOptions: RoleOption[] = [];

  note = new FormControl('', [emptySpacesValidator]);

  private destroy$ = new Subject<void>();

  @Output() saved = new EventEmitter<Partial<AddTeamMemberParams>>();

  @Output() changed = new EventEmitter<boolean>();

  @Output() saveAllowed = new EventEmitter<boolean>();

  constructor(
    public ref: ChangeDetectorRef,
    private roleService: RoleService,
    private noteService: NoteService,
    private teamMemberService: TeamMemberService,
    private notificationService: NotificationService,
    public activeModal: ActiveModal,
  ) {}

  public ngOnInit(): void {
    this.initEditForm();
  }

  ngAfterViewChecked() {
    this.ref.detectChanges();
  }

  public ngOnDestroy(): void {
    this.editForm.markAsUntouched();
    this.destroy$.next();
    this.destroy$.complete();
  }

  modalInitData(data: TeamMemberFormModalParams): void {
    const { teamMember, actionType = 'add' } = data;

    this.actionType = actionType;

    if (actionType === 'edit' && teamMember) {
      const { firstName, middleName, lastName, title, roles, primaryEmail, workPhoneNumberExtension, workPhone, primaryPhone } = teamMember;

      this.teamMember = teamMember;

      this.profileModel = {
        firstName,
        middleName,
        lastName,
        title,
        permissionRoles: (roles[0].name as PermissionRoles) || undefined,
        email: primaryEmail,
        workPhoneNumber: workPhone?.number || undefined,
        workPhoneNumberExtension,
        mobileNumber: primaryPhone?.number || undefined,
      };

      if (this.formFields[2]?.fieldGroup[0]?.props) {
        this.formFields[2].fieldGroup[0].props.disabled = true;
      }
    }
  }

  initEditForm() {
    this.roleService
      .getRoleOptions()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (roleOptions) => {
          if (this.formFields[1]?.fieldGroup[1]?.props) {
            this.formFields[1].fieldGroup[1].props.options = roleOptions;
          }

          this.roleOptions = roleOptions;
        },
        error: (error) => {
          ErrorUtils.catchError('roleService.getRoleOptions error', error);
        },
      });
  }

  public onNoteFieldBlur(): void {
    if (this.note.value) {
      this.note.patchValue(this.note.value.trim());
    }
  }

  public onSave(): void {
    this.editForm.markAllAsTouched();

    if (this.editForm.invalid) {
      return;
    }

    const {
      firstName,
      lastName,
      middleName = '',
      title,
      permissionRoles,
      workPhoneNumber,
      workPhoneNumberExtension,
      mobileNumber,
      email,
    } = this.profileModel;

    const workPhoneParsed = workPhoneNumber ? parsePhoneNumber(workPhoneNumber).number.toString() : undefined;
    const mobilePhoneParsed = mobileNumber ? parsePhoneNumber(mobileNumber).number.toString() : undefined;

    const teamMember = pick(this.teamMember, [
      'firstName',
      'lastName',
      'middleName',
      'prefix',
      'suffix',
      'title',
      'department',
      'sex',
      'gender',
      'pronoun',
      'employeeNumber',
    ]);

    const teamMemberData: Partial<AddTeamMemberParams> = {
      ...teamMember,
      firstName: firstName!,
      lastName: lastName!,
      middleName: middleName || undefined,
      title: title || undefined,
      workPhoneNumber: workPhoneParsed,
      workPhoneNumberExtension: workPhoneNumberExtension || undefined,
      mobileNumber: mobilePhoneParsed,
    };

    const selectedRole = this.roleOptions.find((role) => role.value === permissionRoles);
    const roleObj = selectedRole
      ? {
          id: selectedRole.id,
          name: selectedRole.value,
        }
      : undefined;

    teamMemberData.roles = roleObj ? [roleObj] : undefined;
    if (this.actionType === 'edit') {
      this.updateTeamMember(teamMemberData);
    } else {
      this.addTeamMember({ ...teamMemberData, email });
    }
  }

  public updateTeamMember(teamMemberData: Partial<AddTeamMemberParams>) {
    this.isLoading = true;
    this.submitError = '';

    this.teamMemberService
      .updateEmployee(this.teamMember.id, teamMemberData)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: () => {
          this.notificationService.displaySuccess('Team Member updated successfully.', 'Success');
          this.activeModal.close(true);
        },
        error: (error) => this.handleError(error, 'updateEmployee'),
      });
  }

  public addTeamMember(teamMemberData: Partial<AddTeamMemberParams>) {
    this.isLoading = true;
    this.submitError = '';

    this.teamMemberService
      .addTeamMember(teamMemberData as AddTeamMemberParams)
      .pipe(
        switchMap((teamMemberResponse) => this.addNoteToTeamMember(teamMemberResponse, this.note.value)),
        takeUntil(this.destroy$),
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: ({ noteResponse }) => this.onTeamMemberCreateSuccess(!noteResponse),
        error: (error) => this.handleError(error, 'addTeamMember'),
      });
  }

  addNoteToTeamMember(teamMemberResponse: TeamMemberRaw, note?: string | null) {
    if (!note) {
      return of({ noteResponse: true });
    }

    return this.noteService
      .addNote({
        entityId: teamMemberResponse.id,
        entityType: 'TEAM_MEMBER',
        contentText: note,
      })
      .pipe(
        catchError((error) => {
          ErrorUtils.catchError('noteService.addNote', error);
          return of(false);
        }),
        map((noteResponse) => ({ noteResponse })),
      );
  }

  onTeamMemberCreateSuccess(noteError: boolean) {
    if (noteError) {
      this.notificationService.displayError('Team Member created successfully. Unable to create Note for Team Member');
    } else {
      this.notificationService.displaySuccess('Team Member created successfully.', 'Success');
    }
    this.activeModal.close(true);
  }

  private handleError(error: string | number | HttpErrorResponse, serviceFunction: string): void {
    let errorMessage = '';

    if (typeof error === 'number') {
      if (error === HttpStatusCode.Conflict) {
        errorMessage = MESSAGE.TEAM_MEMBER_ALREADY_EXIST;
      } else if (error === HttpStatusCode.Forbidden || error === HttpStatusCode.Unauthorized) {
        errorMessage = MESSAGE.PERMISSION_DENIED;
      }
    } else if (typeof error === 'string') {
      errorMessage = error;
    } else {
      errorMessage = MESSAGE.GENERIC_ERROR;
    }

    this.submitError = errorMessage;

    ErrorUtils.catchError(`teamMemberService.${serviceFunction}`, this.submitError);
  }
}
