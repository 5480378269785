import { AsyncPipe, NgFor, NgIf, TitleCasePipe } from '@angular/common';
import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { compact, flatten } from 'lodash-es';
import { RktButtonDirective } from '@rocketfinancialcorp/rocket-ui/button';
import { RktIconComponent } from '@rocketfinancialcorp/rocket-ui/icon';
import { ActiveModal } from '@rocketfinancialcorp/rocket-ui/modal';

import { AddCustomerParams, CustomerAddress } from '@shared/models';
import { IndustriesActions, fromIndustries } from '@shared/store';
import { toTitleCase } from '@shared/utils';
import { formAddressSerializer } from '@shared/utils/address';

import { DateFormatPipe } from '../../../pipes/date-format.pipe';
import { DetailsItemComponent } from '../../details-item/details-item.component';

@Component({
  selector: 'app-add-customer-review',
  templateUrl: './add-customer-review.component.html',
  styleUrls: ['./add-customer-review.component.scss'],
  standalone: true,
  imports: [RktButtonDirective, NgIf, DetailsItemComponent, NgFor, AsyncPipe, TitleCasePipe, DateFormatPipe, RktIconComponent],
})
export class AddCustomerReviewComponent {
  customer!: AddCustomerParams;

  addresses!: CustomerAddress[];

  businessCustomerIndustry$ = this.store.select(fromIndustries.selectIndustry);

  get customerGender() {
    return this.customer.sex === 'NONBINARY' ? 'Non-Binary' : toTitleCase(this.customer.sex);
  }

  constructor(
    public activeModal: ActiveModal,
    private store: Store,
  ) {}

  modalInitData(customerDetails: AddCustomerParams) {
    this.customer = customerDetails;
    if (customerDetails.customerType === 'BUSINESS' && customerDetails.industryCode) {
      this.store.dispatch(IndustriesActions.loadIndustryDetails({ industryCode: customerDetails.industryCode }));
    }
  }

  get customerPlaceOfBirth(): string | undefined {
    return `${compact([this.customer?.cityOfBirth, this.customer?.countryOfBirth]).join(', ')}` || undefined;
  }

  get customerAddress(): (CustomerAddress | undefined)[] {
    const { physicalAddresses, shippingAddresses, mailingAddresses } = this.customer || [];

    const mappedAddresses = flatten(
      compact([
        physicalAddresses?.map((physicalAddress) => formAddressSerializer({ ...physicalAddress!, addressType: 'PHYSICAL' })),
        mailingAddresses?.map((mailingAddress) => formAddressSerializer({ ...mailingAddress!, addressType: 'MAILING' })),
        shippingAddresses?.map((shippingAddress) => formAddressSerializer({ ...shippingAddress!, addressType: 'SHIPPING' })),
      ]),
    );

    return compact(mappedAddresses);
  }

  get individualCustomerHasAdditionalInformation(): boolean {
    return (
      compact([
        this.customerPlaceOfBirth,
        this.customer?.dateOfBirth,
        this.customer?.sex,
        this.customer?.socialId,
        this.customer?.citizenshipCountryCode,
        this.customer?.externalId,
      ]).length > 0
    );
  }

  get businessCustomerHasAdditionalInformation(): boolean {
    return (
      compact([
        this.customer?.dateOfFormation,
        this.customer?.taxId,
        this.customer?.legalName,
        this.customer?.website,
        this.customer?.legalEntityType,
        this.customer?.businessPrimaryPhoneNumber,
        this.customer?.externalId,
        this.customer?.industryCode,
      ]).length > 0
    );
  }

  getAddressTitle(type?: string, customerType?: 'BUSINESS' | 'INDIVIDUAL'): string {
    if (!type) {
      return '';
    }

    if (type === 'PHYSICAL') {
      return customerType === 'BUSINESS' ? 'Registered Business Address' : 'Residential Address';
    } else {
      return `${type} Address`;
    }
  }
}
