import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { compact } from 'lodash-es';
import { BehaviorSubject, Observable, catchError, map, tap, throwError } from 'rxjs';

import { environment } from '@env';
import { constants } from '@shared/constants';
import { CustomHttpParamEncoder } from '@shared/encoder';
import { BatchRejectedLinxRequests, FilterValues, LinxBatchList, LinxBatchListItem, RequestPageParams } from '@shared/models';
import { AuditActions } from '@shared/store';

import { BackendService } from '../backend.service';
import { StorageService } from '../storage/storage.service';

@Injectable({
  providedIn: 'root',
})
export class LinxBatchesService {
  batchListFilter = new BehaviorSubject<FilterValues>({});
  constructor(
    private backendService: BackendService,
    private storageService: StorageService,
    private store: Store,
  ) {}

  public setBatchFilterParams(filters: FilterValues): void {
    this.storageService.setItem(constants.LINX_BATCH_REQUESTS_FILTER, filters);
    this.updateBatchFilterParams(filters);
  }

  public getBatchFilterParams(): void {
    const filters = this.storageService.getItem<FilterValues>(constants.LINX_BATCH_REQUESTS_FILTER);

    if (filters && Object.keys(filters).length) {
      this.updateBatchFilterParams(filters);
    }
  }

  private updateBatchFilterParams(filters: FilterValues): void {
    this.batchListFilter.next(filters);
  }

  getLinxBatches(requestParams: RequestPageParams): Observable<LinxBatchList> {
    const { page, size, searchString, activeFilters } = requestParams;
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() });

    params = params.append('page', page ?? 0);

    params = params.append('size', size ?? constants.TABLE_ROWS);

    if (searchString) {
      params = params.append('search', searchString);
    }

    if (activeFilters) {
      Object.keys(activeFilters).forEach((key) => {
        params = params.append(key, activeFilters[key]!);
      });
    }

    return this.backendService
      .get<LinxBatchList>(`${environment.linxBatchesEndpoint}`, { params })
      .pipe(catchError((errorRes) => throwError(() => errorRes)));
  }

  getLinxBatchDetails(batchId: string): Observable<LinxBatchListItem> {
    return this.backendService.get<LinxBatchListItem>(`${environment.linxBatchesEndpoint}/${batchId}`).pipe(
      tap({
        next: (linxBatchDetails) => {
          const { createdBy, updatedBy } = linxBatchDetails;
          this.store.dispatch(AuditActions.loadTeamMembers({ ids: compact([createdBy, updatedBy]) }));
        },
      }),
      map((batchDetails) => this.mapLinxBatchDetails(batchDetails)),
      catchError((errorRes) => throwError(() => errorRes)),
    );
  }

  private mapLinxBatchDetails(batchDetails: LinxBatchListItem): LinxBatchListItem {
    const {
      cancelledRequestsCount,
      declinedRequestsCount,
      expiredRequestsCount,
      failedRequestsCount,
      id,
      templateId,
      templateVersion,
      templateDisplayName,
    } = batchDetails;
    return {
      ...batchDetails,
      batchShortId: id.split('-')[0].toUpperCase(),
      templateNameWithVersion: `${templateDisplayName} (${templateVersion})`,
      templateShortId: templateId.split('-')[0].toUpperCase(),
      unsuccessfulRequestsCount: cancelledRequestsCount + declinedRequestsCount + expiredRequestsCount + failedRequestsCount,
    };
  }

  cancelBatchActiveLinxRequests(batchId: string): Observable<LinxBatchListItem> {
    return this.backendService
      .post<LinxBatchListItem>(`${environment.linxBatchesEndpoint}/${batchId}/cancel`)
      .pipe(catchError((errorRes) => throwError(() => errorRes)));
  }

  getBatchRejectedLinxRequests(status: string, batchId: string, requestParams: RequestPageParams): Observable<BatchRejectedLinxRequests> {
    const { page, size, searchString } = requestParams;
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() });

    params = params.append('page', page ?? 0);

    params = params.append('size', size ?? constants.TABLE_ROWS);

    params = params.append('status', status);

    if (searchString) {
      params = params.append('search', searchString);
    }

    return this.backendService
      .get<BatchRejectedLinxRequests>(`${environment.linxBatchesEndpoint}/${batchId}/entries`, { params })
      .pipe(catchError((errorRes) => throwError(() => errorRes)));
  }
}
