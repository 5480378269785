import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RktButtonDirective } from '@rocketfinancialcorp/rocket-ui/button';
import { RktFormComponent } from '@rocketfinancialcorp/rocket-ui/form';
import { RktIconComponent } from '@rocketfinancialcorp/rocket-ui/icon';
import { ActiveModal } from '@rocketfinancialcorp/rocket-ui/modal';

import { identityProfileOptions } from '@shared/components';
import { CustomerFormModalParams, FormModel, SubmitIdentityVerificationApplicationParams } from '@shared/models';
import { IdentityVerificationService } from '@shared/services';
import { ErrorUtils } from '@shared/utils';

@Component({
  selector: 'app-submit-identity-verification',
  templateUrl: './submit-identity-verification.component.html',
  standalone: true,
  imports: [RktButtonDirective, FormsModule, ReactiveFormsModule, RktFormComponent, RktIconComponent],
})
export class SubmitIdentityVerificationComponent implements AfterViewChecked, OnDestroy {
  formFields = identityProfileOptions;

  formModel: FormModel<SubmitIdentityVerificationApplicationParams> = {
    identityVerificationProfileId: undefined,
  };

  identityVerificationForm = new FormGroup({});

  private destroy$ = new Subject<void>();

  constructor(
    public ref: ChangeDetectorRef,
    public activeModal: ActiveModal,
    private identityVerificationService: IdentityVerificationService,
  ) {}

  public ngAfterViewChecked(): void {
    this.ref.detectChanges();
  }

  public ngOnDestroy(): void {
    this.identityVerificationForm.markAsUntouched();
    this.destroy$.next();
    this.destroy$.complete();
  }

  private initFormData(customerType: string | undefined) {
    this.identityVerificationService
      .getIdentityVerificationProfiles()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (profileOptions) => {
          const idvProfileOptions = customerType
            ? profileOptions.filter((profile) => profile.allowedAccountTypes.includes(customerType))
            : profileOptions;

          if (this.formFields[0]?.fieldGroup[0]?.props) {
            this.formFields[0].fieldGroup[0].props.options = idvProfileOptions;
          }
        },
        error: (error) => {
          this.activeModal.close();
          ErrorUtils.catchError('identityVerificationService.getIdentityVerificationProfiles error', error);
        },
      });
  }

  modalInitData(data: CustomerFormModalParams): void {
    const { holderId, accountHolderType } = data;

    this.formModel = {
      ...this.formModel,
      accountId: holderId,
      customerType: accountHolderType,
    };

    this.initFormData(accountHolderType);
  }

  onSubmit() {
    const { accountId, identityVerificationProfileId } = this.formModel;
    this.activeModal.close({ accountId, identityVerificationProfileId });
  }
}
