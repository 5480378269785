import { AsyncPipe, CurrencyPipe, TitleCasePipe } from '@angular/common';
import { AfterViewChecked, ChangeDetectionStrategy, ChangeDetectorRef, Component, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { ShortIdPipe } from '@rocketfinancialcorp/rocket-ui/table';

import { DetailsItemComponent, TextWithPlaceholderComponent } from '@shared/components';
import { DateFormatPipe, TeamMemberNamePipe } from '@shared/pipes';
import { selectTransactionBatchDetails } from '@shared/store';

@Component({
  templateUrl: './transaction-batch-details.component.html',
  styleUrls: ['./transaction-batch-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TextWithPlaceholderComponent,
    DetailsItemComponent,
    AsyncPipe,
    TitleCasePipe,
    CurrencyPipe,
    DateFormatPipe,
    TeamMemberNamePipe,
    ShortIdPipe,
  ],
})
export class TransactionBatchDetailsComponent implements AfterViewChecked {
  pageClassPrefix = 'transaction-batch-details-';

  store = inject(Store);

  ref = inject(ChangeDetectorRef);

  transactionBatch$ = this.store.select(selectTransactionBatchDetails);

  ngAfterViewChecked(): void {
    this.ref.detectChanges();
  }
}
