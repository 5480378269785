import { NgFor } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RktIconComponent } from '@rocketfinancialcorp/rocket-ui/icon';
import { FetchData } from '@rocketfinancialcorp/rocket-ui/table';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
  standalone: true,
  imports: [NgFor, RktIconComponent],
})
export class PaginationComponent {
  @Input() currentPage!: number;
  @Input() totalPages!: number;
  @Input() activePage!: number;
  @Output() updatePageParam = new EventEmitter<FetchData>();

  get isFirstPage(): boolean {
    return this.activePage === 0;
  }

  get isLastPage(): boolean {
    return this.activePage === this.totalPages - 1;
  }

  onPageChange(pageNumber: number): void {
    this.currentPage = pageNumber;
    this.updatePageParam.emit({ page: pageNumber });
  }
}
