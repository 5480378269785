import { HttpContext, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { compact, startCase, toUpper } from 'lodash-es';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { environment } from '@env';
import { constants } from '@shared/constants';
import { CustomHttpParamEncoder } from '@shared/encoder';
import { CUSTOM_ERROR_MESSAGE } from '@shared/interceptors';
import {
  AddCustomerParams,
  Beneficiaries,
  BeneficiariesRaw,
  Beneficiary,
  BeneficiaryRaw,
  BeneficiaryRequestParams,
  BusinessAccount,
  Customer,
  CustomerBeneficiaryRequestParams,
  CustomerDetailRaw,
  CustomerFullDetails,
  CustomerListItem,
  CustomerListRaw,
  FilterValues,
  IndustryListRaw,
  IndustryRaw,
  RequestPageParams,
  UpdateCustomerParams,
  VerificationStatus,
} from '@shared/models';
import { BusinessAccountService, StorageService } from '@shared/services';
import { BackendService } from '@shared/services/backend.service';
import { AuditActions } from '@shared/store';
import { mapVerifactionStatus, toPhone, toTitleCase } from '@shared/utils';

import { customersRequestParams, mapCustomerBeneficiary, mapCustomerBeneficiaryList, mapIndustries } from './customer-mapping-utils';

@Injectable({
  providedIn: 'root',
})
export class CustomerService {
  individualListFilter = new BehaviorSubject<FilterValues>({});

  businessListFilter = new BehaviorSubject<FilterValues>({});

  constructor(
    private businessAccountService: BusinessAccountService,
    private backendService: BackendService,
    private storageService: StorageService,
    private store: Store,
  ) {}

  /**
   * Retrieves a list of customers for the selected business account based on the provided request parameters.
   * API {{base-url}}/accountflow/api/portal/v1/business-accounts/{{ba-id}}/customers
   *
   * @param {RequestPageParams} requestParams - The request parameters object containing pagination, sorting and filtering.
   * @returns {Observable<CustomerListItem>} - An Observable that emits a TransactionList object.
   */
  public getBusinessAccountCustomers(requestParams: RequestPageParams): Observable<CustomerListItem> {
    const params = customersRequestParams(requestParams);

    return this.getSelectedBusinessAccountId().pipe(
      switchMap((businessAccountId) =>
        this.backendService
          .get<CustomerListRaw>(`${environment.accountFlowService}/business-accounts/${businessAccountId}/customers`, { params })
          .pipe(
            map((response) => {
              const { content = [], totalElements = 0 } = response || {};
              const items = content?.map((customer) => {
                const verificationStatus = customer.verificationStatus?.status ?? 'UNVERIFIED';

                return {
                  ...customer,
                  displayName: toTitleCase(customer.name),
                  customerTypeName: toTitleCase(customer.type),
                  phoneNumber: toPhone(customer.primaryPhoneNumber),
                  externalId:
                    customer?.externalId && customer?.externalId?.split('-')?.length > 1
                      ? toUpper(`${customer.externalId.split('-')[0] + '-' + customer.externalId.split('-')[1]}`)
                      : customer?.externalId,
                  rowClassName: `verification-${verificationStatus.toLowerCase()}`,
                  verification: toTitleCase(verificationStatus),
                };
              });

              return {
                items,
                totalElements,
              };
            }),
            catchError((errorRes) => throwError(() => errorRes)),
          ),
      ),
    );
  }

  public getCustomerById(customerId: Customer['id']): Observable<Customer> {
    return this.backendService.get<CustomerDetailRaw>(`${environment.accountFlowService}/v2/customers/${customerId}`).pipe(
      switchMap((customerInfo) => {
        const { createdBy, updatedBy, verificationStatus, verificationStatusHistory } = customerInfo;
        const verificationHistoryUpdatedBy = verificationStatusHistory?.map((historyItem) => historyItem.updatedBy) ?? [];
        this.store.dispatch(
          AuditActions.loadTeamMembers({
            ids: compact([createdBy, updatedBy, verificationStatus?.updatedBy, ...verificationHistoryUpdatedBy]),
          }),
        );
        if (customerInfo.type === 'BUSINESS' && customerInfo.industryCode) {
          return this.getIndustryById(customerInfo.industryCode).pipe(
            map((industry) => {
              return { ...customerInfo, industryLabel: industry?.name };
            }),
          );
        } else {
          return of(customerInfo);
        }
      }),
      map((customer) => CustomerService.mapCustomer(customer)),
      catchError((errorRes) => throwError(() => errorRes)),
    );
  }

  public setIndividualFilterParams(filters: FilterValues): void {
    this.storageService.setItem(constants.KOR_CUSTOMER_INDIVIDUAL_FILTER, filters);
    this.updateIndividualFilterParams(filters);
  }

  public getIndividualFilterParams(): void {
    const filters = this.storageService.getItem<FilterValues>(constants.KOR_CUSTOMER_INDIVIDUAL_FILTER);

    if (filters && Object.keys(filters).length) {
      this.updateIndividualFilterParams(filters);
    }
  }

  private updateIndividualFilterParams(filters: FilterValues): void {
    this.individualListFilter.next(filters);
  }

  public setBusinessFilterParams(filters: FilterValues): void {
    this.storageService.setItem(constants.KOR_CUSTOMER_BUSINESS_FILTER, filters);
    this.updateBusinessFilterParams(filters);
  }

  public getBusinessFilterParams(): void {
    const filters = this.storageService.getItem<FilterValues>(constants.KOR_CUSTOMER_BUSINESS_FILTER);

    if (filters && Object.keys(filters).length) {
      this.updateBusinessFilterParams(filters);
    }
  }

  private updateBusinessFilterParams(filters: FilterValues): void {
    this.businessListFilter.next(filters);
  }

  public getIndustries({ page, size, sortParams = { key: 'industryCode', sortProp: 'industryCode', sortDir: 'ASC' } }: RequestPageParams) {
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() });

    params = params.append('size', size ?? 30);

    if (page) {
      params = params.append('page', page);
    }

    if (sortParams) {
      params = params.append('sort', `${sortParams.key},${sortParams.sortDir}`);
    }
    return this.backendService
      .get<IndustryListRaw>(`${environment.accountFlowService}/v1/industry-categories/industry-sub-categories`, { params })
      .pipe(map((response) => mapIndustries(response)));
  }

  public getIndustryById(industryCode: string) {
    return this.backendService
      .get<IndustryRaw>(`${environment.accountFlowService}/v1/industry-categories/industry-sub-categories/${industryCode}`)
      .pipe(map((response) => response));
  }

  public addCustomer(customerData: AddCustomerParams): Observable<Customer> {
    return this.backendService
      .post<CustomerDetailRaw>(`${environment.accountFlowService}/v2/customers`, {
        ...customerData,
        // limitsCurrency: 'USD',
        limits: {
          SINGLE: { amount: 100 },
          DAILY: { amount: 100 },
          WEEKLY: { amount: 100 },
          MONTHLY: { amount: 100 },
        },
      })
      .pipe(
        tap({
          next: (customerInfo) => {
            const { createdBy, updatedBy } = customerInfo;
            this.store.dispatch(AuditActions.loadTeamMembers({ ids: compact([createdBy, updatedBy]) }));
          },
        }),
        map((customer) => CustomerService.mapCustomer(customer)),
        catchError((errorRes) => throwError(() => errorRes)),
      );
  }

  public updateCustomer(customerId: Customer['id'], updateCustomerData: Partial<UpdateCustomerParams>) {
    return this.backendService
      .update<CustomerDetailRaw>(`${environment.accountFlowService}/v2/customers/${customerId}`, updateCustomerData)
      .pipe(
        switchMap((customerInfo) => {
          const { createdBy, updatedBy, verificationStatus } = customerInfo;
          this.store.dispatch(AuditActions.loadTeamMembers({ ids: compact([createdBy, updatedBy, verificationStatus?.updatedBy]) }));
          if (customerInfo.type === 'BUSINESS' && customerInfo.industryCode) {
            return this.getIndustryById(customerInfo.industryCode).pipe(
              map((industry) => {
                return { ...customerInfo, industryLabel: industry?.name };
              }),
            );
          } else {
            return of(customerInfo);
          }
        }),
        map((customer) => CustomerService.mapCustomer(customer)),
        catchError((errorRes) => throwError(() => errorRes)),
      );
  }

  public updateCustomerStatus(customerId: Customer['id'], status: string) {
    return this.backendService
      .update<void>(`${environment.accountFlowService}/v2/customers/${customerId}/${status}`)
      .pipe(catchError((errorRes) => throwError(() => errorRes)));
  }

  public deleteCustomer(customerId: Customer['id']) {
    return this.getSelectedBusinessAccountId().pipe(
      switchMap((businessAccountId) =>
        this.backendService
          .delete<void>(`${environment.accountFlowService}/business-accounts/${businessAccountId}/customers/${customerId}`)
          .pipe(catchError((errorRes) => throwError(() => errorRes))),
      ),
    );
  }

  private static mapCustomer(customer: CustomerDetailRaw): Customer {
    const verificationStatus: VerificationStatus = mapVerifactionStatus({
      verificationStatus: customer.verificationStatus,
      updatedAt: customer.createdAt,
    });

    return {
      ...customer,
      customerType: toTitleCase(customer.type),
      displayPhoneNumber: toPhone(customer.primaryPhone ?? customer?.primaryPhoneNumber?.number),
      primaryPhone: toPhone(customer.primaryPhone),
      displayEmail: customer?.primaryEmail?.value,
      displayName: customer.doingBusinessAsName ?? compact([customer.firstName, customer.lastName]).join(' '),
      avatarInitials: startCase(customer.doingBusinessAsName?.charAt(0) ?? customer?.firstName?.charAt(0)),
      shortId: customer.id.split('-')[0],
      firstName: customer.firstName,
      middleName: customer?.middleName ?? '',
      lastName: customer.lastName,
      customerPlaceOfBirth: `${compact([customer.placeOfBirth?.city, customer.placeOfBirth?.countryCode]).join(', ')}` || undefined,
      verificationStatus,
      verificationStatusHistory: customer.verificationStatusHistory ?? [verificationStatus],
    };
  }

  private getSelectedBusinessAccountId(): Observable<BusinessAccount['id']> {
    return this.businessAccountService.getSelectedBusinessAccountId();
  }

  public getBusinessCustomerBeneficiaries(customerId: Customer['id']): Observable<Beneficiaries[]> {
    return this.backendService.get<BeneficiariesRaw[]>(`${environment.accountFlowService}/v1/customers/${customerId}/beneficiaries`).pipe(
      map((response) => mapCustomerBeneficiaryList(response)),
      catchError((errorRes) => throwError(() => errorRes)),
    );
  }

  public getBusinessCustomerBeneficiaryById({ customerId, beneficiaryId }: CustomerBeneficiaryRequestParams): Observable<Beneficiary> {
    return this.backendService
      .get<BeneficiaryRaw>(`${environment.accountFlowService}/v1/customers/${customerId}/beneficiaries/${beneficiaryId}`)
      .pipe(
        tap({
          next: (customerInfo) => {
            const { createdBy, updatedBy, verificationStatus, verificationStatusHistory } = customerInfo;
            const verificationHistoryUpdatedBy = verificationStatusHistory?.map((historyItem) => historyItem.updatedBy) ?? [];

            this.store.dispatch(
              AuditActions.loadTeamMembers({
                ids: compact([createdBy, updatedBy, verificationStatus?.updatedBy, ...verificationHistoryUpdatedBy]),
              }),
            );
          },
        }),
        map((response) => mapCustomerBeneficiary(response)),
        catchError((errorRes) => throwError(() => errorRes)),
      );
  }

  public revealCustomerDetails({
    customerId,
    customerType,
  }: {
    customerId: string;
    customerType: string;
  }): Observable<CustomerFullDetails> {
    const isBusiness = customerType === 'business';
    return this.backendService.get<CustomerFullDetails>(`${environment.accountFlowService}/v2/customers/${customerId}/details`, {
      context: new HttpContext().set(
        CUSTOM_ERROR_MESSAGE,
        `Unable to retrieve the full ${isBusiness ? 'EIN' : 'SSN'} details of the ${isBusiness ? 'Business' : 'Customer'}`,
      ),
    });
  }

  public addBeneficiary({
    customerId,
    beneficiaryData,
  }: {
    customerId: string;
    beneficiaryData: BeneficiaryRequestParams;
  }): Observable<boolean> {
    return this.backendService
      .post<BeneficiaryRaw>(`${environment.accountFlowService}/v1/customers/${customerId}/beneficiaries`, beneficiaryData)
      .pipe(
        map(() => true),
        catchError((errorRes) => throwError(() => errorRes)),
      );
  }

  public updateBeneficiary({
    customerId,
    beneficiaryId,
    beneficiaryData,
  }: {
    customerId: string;
    beneficiaryId: string;
    beneficiaryData: BeneficiaryRequestParams;
  }): Observable<Beneficiary> {
    return this.backendService
      .update<BeneficiaryRaw>(
        `${environment.accountFlowService}/v1/customers/${customerId}/beneficiaries/${beneficiaryId}`,
        beneficiaryData,
      )
      .pipe(
        map((response) => mapCustomerBeneficiary(response)),
        catchError((errorRes) => throwError(() => errorRes)),
      );
  }

  public updateCustomerBeneficiaryStatus(customerId: string, beneficiaryId: Beneficiary['id'], action: string) {
    return this.backendService
      .update<void>(`${environment.accountFlowService}/v1/customers/${customerId}/beneficiaries/${beneficiaryId}/${action}`, {})
      .pipe(catchError((errorRes) => throwError(() => errorRes)));
  }

  public deleteCustomerBeneficiary(customerId: string, beneficiaryId: Beneficiary['id']) {
    return this.backendService
      .delete<void>(`${environment.accountFlowService}/v1/customers/${customerId}/beneficiaries/${beneficiaryId}`)
      .pipe(catchError((errorRes) => throwError(() => errorRes)));
  }
}
