import { createReducer, on } from '@ngrx/store';

import { AccountBalance, ErrorMessage, FinancialAccountBalances } from '@shared/models';

import { AggregatedAccountBalanceActions } from './aggregated-account-balance.actions';

export const aggregatedAccountBalancePageFeatureKey = 'aggregatedAccountBalancePage';

export interface AggregatedAccountBalanceState {
  aggregatedAccountBalances: FinancialAccountBalances | null;
  selectedAggregatedAccountBalance: AccountBalance | undefined;
  selectedCurrency: string | undefined;
  isLoading: boolean;
  error: ErrorMessage;
}

export const initialAggregatedAccountBalanceState: AggregatedAccountBalanceState = {
  aggregatedAccountBalances: null,
  selectedAggregatedAccountBalance: undefined,
  isLoading: false,
  selectedCurrency: undefined,
  error: null,
};

export const reducer = createReducer(
  initialAggregatedAccountBalanceState,
  on(AggregatedAccountBalanceActions.initialize, (state): AggregatedAccountBalanceState => {
    return {
      ...state,
      isLoading: true,
      aggregatedAccountBalances: null,
      selectedAggregatedAccountBalance: undefined,
      selectedCurrency: undefined,
      error: null,
    };
  }),
  on(AggregatedAccountBalanceActions.loadAggregatedAccountBalance, (state): AggregatedAccountBalanceState => {
    return {
      ...state,
      isLoading: true,
      selectedAggregatedAccountBalance: undefined,
      selectedCurrency: undefined,
      error: null,
    };
  }),
  on(AggregatedAccountBalanceActions.loadAggregatedAccountBalanceSuccess, (state, action): AggregatedAccountBalanceState => {
    return {
      ...state,
      aggregatedAccountBalances: action.financialAccountsBalances,
      isLoading: false,
      selectedCurrency: undefined,
      error: null,
    };
  }),
  on(AggregatedAccountBalanceActions.loadAggregatedAccountBalanceFailure, (state, action): AggregatedAccountBalanceState => {
    return {
      ...state,
      isLoading: false,
      error: action.error,
    };
  }),
  on(AggregatedAccountBalanceActions.setCurrency, (state, action): AggregatedAccountBalanceState => {
    return {
      ...state,
      selectedCurrency: action.currency,
      isLoading: false,
      error: null,
    };
  }),
  on(AggregatedAccountBalanceActions.setAggregateAccountBalance, (state, action): AggregatedAccountBalanceState => {
    return {
      ...state,
      selectedAggregatedAccountBalance: action.aggregateAccountBalance,
      isLoading: false,
      error: null,
    };
  }),
);

export const getAggregatedAccountBalance = (state: AggregatedAccountBalanceState) => state?.aggregatedAccountBalances;
export const getAggregatedAccountBalanceCurrency = (state: AggregatedAccountBalanceState) => state.selectedCurrency;
export const getAggregatedAccountBalanceForSelectedCurrency = (state: AggregatedAccountBalanceState) =>
  state.selectedAggregatedAccountBalance;
export const getLoading = (state: AggregatedAccountBalanceState) => state.isLoading;
export const getError = (state: AggregatedAccountBalanceState) => state.error;
