import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { ErrorMessage, TransactionBatch } from '@shared/models';

export const TransactionBatchDetailsActions = createActionGroup({
  source: 'Transaction Batch Details',
  events: {
    'Load Transaction Batch Details': emptyProps,
    'Load Transaction Batch Details Success': props<{ transactionBatchDetails: TransactionBatch }>(),
    'Load Transaction Batch Details Failure': props<{ error: ErrorMessage }>(),
  },
});
