import { AsyncPipe, NgIf, NgTemplateOutlet } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import {
  NgLabelTemplateDirective,
  NgMultiLabelTemplateDirective,
  NgNotFoundTemplateDirective,
  NgOptionTemplateDirective,
  NgSelectComponent,
} from '@ng-select/ng-select';
import { Store } from '@ngrx/store';
import { uniqueId } from 'lodash-es';
import { Observable, Subject, catchError, concat, debounceTime, map, of, switchMap, tap } from 'rxjs';
import { RktIconComponent } from '@rocketfinancialcorp/rocket-ui/icon';

import { User } from '@shared/models';
import { TeamMemberService } from '@shared/services';
import { fromAuth } from '@shared/store';

interface Item {
  label: string;
  value: string;
}

@Component({
  selector: 'app-assignee-filter',
  templateUrl: 'assignee-filter.component.html',
  standalone: true,
  imports: [
    NgIf,
    NgSelectComponent,
    FormsModule,
    NgLabelTemplateDirective,
    NgMultiLabelTemplateDirective,
    NgOptionTemplateDirective,
    NgTemplateOutlet,
    NgNotFoundTemplateDirective,
    AsyncPipe,
    RktIconComponent,
  ],
})
export class AssigneeFilterComponent implements OnInit {
  store = inject(Store);

  @Input() label!: string;

  @Input() required = false;

  @Input() selectedAssignee: Item[] = [];

  @Input() selectedItems?: string[];

  @Input() items$!: Observable<Item[]>;

  @Input() loading = false;

  @Input() disabled = false;

  @Input() placeholder = '';

  @Input() maxSelectedItems?: number;

  @Output() changed = new EventEmitter<Item[] | undefined>();

  @Output() filterOpened = new EventEmitter();

  @Output() closed = new EventEmitter();

  activeUser$ = this.store.select(fromAuth.selectUser);

  assigneeInput$ = new Subject<string>();

  selectId = uniqueId();

  teamMemberService = inject(TeamMemberService);

  selectedItemsCountOnOpen = 0;

  getDefaultItems(user: User | null) {
    return [
      ...this.selectedAssignee.filter((item) => ![user?.id, 'UNASSIGNED'].includes(item.value)),
      { label: `${user?.name ?? ''}::${user?.email ?? ''}`, value: user?.id ?? '' },
      { label: 'Unassigned', value: 'UNASSIGNED' },
    ];
  }

  ngOnInit() {
    this.items$ = this.activeUser$.pipe(
      switchMap((user) => {
        return concat(
          of(this.getDefaultItems(user)),
          this.assigneeInput$.pipe(
            debounceTime(300),
            tap({ next: () => (this.loading = true) }),
            switchMap((term) => {
              if (!term) {
                return of(this.getDefaultItems(user));
              } else {
                return this.teamMemberService.getBusinessAccountTeamMembers({ searchString: term }).pipe(
                  tap({ next: () => (this.loading = false) }),
                  map((items) => {
                    return [
                      ...items.items.map((employee) => {
                        return { label: `${employee.displayName}::${employee.primaryEmail}`, value: employee.id };
                      }),
                      {
                        label: 'Unassigned',
                        value: 'UNASSIGNED',
                      },
                    ];
                  }),
                  catchError(() => of([] as Item[])),
                );
              }
            }),
          ),
        );
      }),
    );
  }

  onChange(items: Item[]): void {
    this.changed.emit(items);
  }

  onOpen() {
    this.selectedItems = this.selectedAssignee?.map((item) => item.value) ?? [];
    this.selectedItemsCountOnOpen = this.selectedItems.length;
    this.filterOpened.emit();
  }

  onClose() {
    this.assigneeInput$.next('');
    if (this.selectedItemsCountOnOpen !== this.selectedItems?.length) {
      this.closed.emit();
    }
  }
}
