import { Patterns } from '@shared/components';
import {
  Address,
  FinancialAccountDetails,
  FinancialAccountDetailsRaw,
  FinancialAccountSubtype,
  MultiLegSolutionConfig,
  TransactionSettlementPriority,
  TransactionSolution,
  TransactionSolutionDisplayName,
} from '@shared/models';

import { toTitleCase } from './titlecase-util';

export const getSolutionDisplayName = (value?: TransactionSolution): TransactionSolutionDisplayName => {
  if (!value) {
    return '';
  }

  const solutionNames: Record<TransactionSolution, TransactionSolutionDisplayName> = {
    ach: 'ACH',
    'push-to-card': 'Push To Card',
    transfer: 'Transfer',
    wire: 'Wire',
    'millikart-transaction': 'Millikart Transaction',
    'i2c-transaction': 'I2C Transaction',
    'cards-transaction': 'Cards Transaction',
    'financial-account-aggregation': 'Financial Account Aggregation',
  };

  return solutionNames[value] || '';
};

export const getSolutionIconName = (value?: TransactionSolution): string | undefined => {
  if (!value) {
    return;
  }

  const solutionNames: Record<TransactionSolution, string> = {
    ach: 'ach',
    'push-to-card': 'card',
    transfer: 'transfer',
    wire: 'bank',
    'millikart-transaction': '',
    'i2c-transaction': '',
    'cards-transaction': '',
    'financial-account-aggregation': '',
  };

  return solutionNames[value] || undefined;
};

export const getSubtypeDisplayName = (value?: FinancialAccountSubtype): string | undefined => {
  if (!value) {
    return;
  }

  const subtypeNames: Record<FinancialAccountSubtype, string> = {
    CHECKING: 'CHK',
    SAVING: 'SAV',
    CREDIT: 'CRD',
    DEBIT: 'DBT',
    GIFT: 'GFT',
    PREPAID: 'PRP',
    TOKENIZED: 'TKN',
  };

  return subtypeNames[value] || undefined;
};

export const getMaskedAccountNumber = (str: string | undefined): string | undefined => {
  if (!str) {
    return;
  }

  return `*${str}`.replace(Patterns.MASKED_NUMBER, '');
};

export const getMoveFromToFinancialAccount = (financialAccount?: Partial<FinancialAccountDetails>): string => {
  const { bankAccount, card, name, maskedAccountNumber, subtype } = financialAccount ?? {};
  const bankNameMoveFromTo = bankAccount?.bankName;
  const nameOnCardMoveFromTo = card ? [card?.firstName, card?.lastName].join(' ') : '';

  return `${name ?? ''}::${[
    bankNameMoveFromTo ?? nameOnCardMoveFromTo,
    getMaskedAccountNumber(maskedAccountNumber),
    '|',
    getSubtypeDisplayName(subtype),
  ].join(' ')}`;
};

export const getFinancialAccountAddressFields = (financialAccount?: FinancialAccountDetailsRaw): Address | undefined => {
  switch (financialAccount?.type) {
    case 'CARD':
      return financialAccount?.card?.billingAddress;

    case 'BANK':
      return financialAccount?.bankAccount?.billingAddress;

    default:
      return;
  }
};

export const getConfigEndTimeForSolution = (configs: MultiLegSolutionConfig[], solution: MultiLegSolutionConfig['solutionName']) => {
  return configs
    .find((item) => item.solutionName === solution)
    ?.solutionConfig?.transactionWindows.filter((window) => window.priority === 'SAME_DAY')[0]?.endTime;
};

export const getTransactionWindowConfigForSolution = (
  configs: MultiLegSolutionConfig[],
  solution: MultiLegSolutionConfig['solutionName'],
  priority: 'SAME_DAY' | 'NEXT_DAY' = 'SAME_DAY',
) => {
  return configs
    .find((item) => item.solutionName === solution)
    ?.solutionConfig?.transactionWindows.filter((window) => window.priority === priority)[0];
};

export const getProcessingPriorityName = (settlementPriority?: TransactionSettlementPriority): string | undefined => {
  switch (settlementPriority) {
    case 'NEXT_DAY':
      return 'Next Day';

    case 'SAME_DAY':
      return 'Same Day';

    default:
      return toTitleCase(settlementPriority);
  }
};

export const getProcessingPriorityIconName = (settlementPriority?: TransactionSettlementPriority): string | undefined => {
  switch (settlementPriority) {
    case 'NEXT_DAY':
      return 'calendar';

    case 'SAME_DAY':
      return 'same-day';

    default:
      return;
  }
};
