import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { ErrorMessage, Recipient } from '@shared/models';

export const RecipientDetailsActions = createActionGroup({
  source: 'Recipient Details',
  events: {
    'Load Recipient Details': emptyProps,
    'Load Recipient Details Success': props<{ recipientDetails: Recipient }>(),
    'Load Recipient Details Failure': props<{ error: ErrorMessage }>(),
  },
});
