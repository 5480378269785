import { formInput, formRadioGroup, formRow } from '@rocketfinancialcorp/rocket-ui/form';

import { Patterns } from '../patterns';

const expressions = {
  'props.disabled': 'model.cardOrBankAccount === "card" || model.category === "INTERNAL" || !!model.isEdit',
  'props.required': 'model.cardOrBankAccount === "bankAccount" && !model.isEdit',
};

export const bankAccountFields = [
  formRow([
    formRadioGroup({
      key: 'subtype',
      label: 'Bank Account Type',
      props: {
        options: [
          { label: 'Checking', value: 'CHECKING' },
          { label: 'Saving', value: 'SAVING' },
        ],
      },
      defaultValue: 'CHECKING',
      expressions,
    }),
  ]),
  formRow([
    formInput({ key: 'bankAccount.bankName', label: 'Bank Name', expressions }),
    formInput({ key: 'bankAccount.routingNo', label: 'Routing No', props: { minLength: 9, maxLength: 9 }, expressions }),
  ]),
  formRow([
    formInput({
      key: 'bankAccount.nameOnAccount',
      label: 'Name on Account',
      props: { pattern: Patterns.GENERIC_NAME, minLength: 1, maxLength: 100 },
      validation: { messages: { pattern: 'Enter a valid Name on Account' } },
      expressions: {
        'props.disabled': 'model.cardOrBankAccount === "card" || model.category === "INTERNAL"',
        'props.required': 'model.cardOrBankAccount === "bankAccount" && !model.isEdit',
      },
    }),
  ]),
  formRow([
    formInput({
      key: 'bankAccount.accountNumber',
      label: 'Account Number',
      props: {
        minLength: 4,
        maxLength: 20,
      },
      expressions,
    }),
  ]),
  formRow([
    formInput({
      key: 'bankAccount.accountNumberConfirm',
      label: 'Confirm Account Number',
      expressions: {
        ...expressions,
        'props.disabled':
          'model.cardOrBankAccount === "card" || model.category === "INTERNAL" || !model.bankAccount?.accountNumber || !!model.isEdit',
      },
    }),
  ]),
];
