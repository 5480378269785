import { NgIf, TitleCasePipe } from '@angular/common';
import { AfterViewChecked, ChangeDetectorRef, Component } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { identity, isEmpty, keysIn, pickBy } from 'lodash-es';
import { RktButtonDirective } from '@rocketfinancialcorp/rocket-ui/button';
import { RktFormComponent, formCheckboxGroup, formRow } from '@rocketfinancialcorp/rocket-ui/form';
import { RktIconComponent } from '@rocketfinancialcorp/rocket-ui/icon';
import { ActiveModal } from '@rocketfinancialcorp/rocket-ui/modal';

import { FormModel, InsightDocument } from '@shared/models';

export interface InsightFileDownloadModel {
  downloadTypes: {
    csv: boolean;
    pdf: boolean;
  };
}

@Component({
  selector: 'app-file-download-select',
  templateUrl: './file-download-select.component.html',
  styleUrls: ['./file-download-select.component.scss'],
  standalone: true,
  imports: [RktButtonDirective, NgIf, FormsModule, ReactiveFormsModule, RktFormComponent, TitleCasePipe, RktIconComponent],
})
export class FileDownloadSelectComponent implements AfterViewChecked {
  reportDocument!: InsightDocument;

  templateNameForm = new FormGroup({});

  formModel: FormModel<InsightFileDownloadModel> = {
    downloadTypes: {
      csv: false,
      pdf: false,
    },
  };

  formFields = [
    formRow([
      formCheckboxGroup({
        key: 'downloadTypes',
        label: '',
        props: {
          options: [],
          required: true,
        },
      }),
    ]),
  ];

  get formFieldValues(): string[] {
    return keysIn(pickBy(this.formModel.downloadTypes, identity()));
  }

  constructor(
    public activeModal: ActiveModal,
    private ref: ChangeDetectorRef,
  ) {}

  ngAfterViewChecked(): void {
    this.ref.detectChanges();
  }

  modalInitData(reportDocument: InsightDocument): void {
    const { csvStatementPath, pdfStatementPath } = reportDocument;

    this.reportDocument = reportDocument;

    this.formModel = {
      ...this.formModel,
      downloadTypes: {
        csv: !isEmpty(csvStatementPath),
        pdf: !isEmpty(pdfStatementPath),
      },
    };

    if (this.formFields[0]?.fieldGroup[0]?.props) {
      this.formFields[0].fieldGroup[0].props.options = [];
      if (pdfStatementPath) {
        this.formFields[0].fieldGroup[0].props.options = [
          ...this.formFields[0].fieldGroup[0].props.options,
          {
            label: 'Download PDF',
            value: 'pdf',
          },
        ];
      }

      if (csvStatementPath) {
        this.formFields[0].fieldGroup[0].props.options = [
          ...this.formFields[0].fieldGroup[0].props.options,
          {
            label: 'Download CSV',
            value: 'csv',
          },
        ];
      }
    }
  }

  onSave() {
    this.templateNameForm.markAllAsTouched();

    if (this.templateNameForm.invalid || !this.reportDocument) return;

    this.activeModal.close(this.formFieldValues);
  }
}
