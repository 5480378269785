import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import { ModalService } from '@rocketfinancialcorp/rocket-ui/modal';

import { ModalsActions } from './modals.actions';

@Injectable()
export class ModalsEffects {
  constructor(
    private actions$: Actions,
    private modalsService: ModalService,
  ) {}

  dismissAll$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ModalsActions.dismissAll),
        map(() => this.modalsService.dismissAll()),
      );
    },
    { dispatch: false },
  );
}
