import { Pipe, PipeTransform, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { READ_ONLY_ROLES } from '@shared/constants';
import { fromAuth } from '@shared/store';

@Pipe({
  name: 'accessControl',
  pure: false,
  standalone: true,
})
export class AccessControlPipe implements PipeTransform {
  store = inject(Store);

  private userRole$ = this.store.select(fromAuth.selectUserRole);

  transform(otherCondition?: Observable<boolean> | boolean | null): Observable<boolean> {
    const isDisabled$ = this.userRole$.pipe(map((userRole) => READ_ONLY_ROLES.includes(userRole)));

    const otherCondition$ = otherCondition instanceof Observable ? otherCondition : of(otherCondition ?? false);

    return combineLatest([isDisabled$, otherCondition$]).pipe(map(([isDisabled, other]) => isDisabled || other));
  }
}
