import { createAction, props } from '@ngrx/store';

import { Beneficiary, ErrorMessage } from '@shared/models';

export namespace BusinessAccountBeneficiaryActions {
  export const loadBusinessAccountBeneficiary = createAction('[Business Account Beneficiaries API] Load Business Account Beneficiary');

  export const loadBusinessAccountBeneficiarySuccess = createAction(
    '[Business Account Beneficiaries API] Load Business Account Beneficiary Success',
    props<{ beneficiary: Beneficiary }>(),
  );

  export const loadBusinessAccountBeneficiaryError = createAction(
    '[Business Account Beneficiaries API] Load Business Account Beneficiary Error',
    props<{ error: ErrorMessage }>(),
  );
}
