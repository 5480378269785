import { NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl } from '@angular/forms';

import { getFormattedAmount } from '@shared/utils';

@Component({
  selector: 'app-limit-edit-item-field',
  templateUrl: './limit-edit-item-field.component.html',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, NgIf],
})
export class LimitEditItemFieldComponent implements OnInit, OnChanges {
  @Input() initialValue?: number;

  @Input() maxAmountValue = 999999999;

  @Input() limitType = '';

  public isMaximumValue = false;

  public isMinimumValue = false;

  public valueField = new UntypedFormControl();

  public maxLimitsType: Record<string, string> = {
    single: '1 day limit',
    daily: '7 days limit',
    weekly: '30 days limit',
    monthly: '999999999',
  };

  @Output() changed = new EventEmitter<string>();

  @Output() errored = new EventEmitter<boolean>();

  ngOnInit(): void {
    this.updateFieldValue(this.initialValue);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.initialValue && changes.hasOwnProperty('maxAmountValue')) {
      this.isMaximumValue = this.initialValue > this.maxAmountValue;
      this.isMinimumValue = !this.initialValue || this.initialValue <= 0;
      this.errored.emit(this.isMaximumValue || this.isMinimumValue);
    }
  }

  updateFieldValue(value?: number): void {
    if (!value) {
      return;
    }

    const formattedValue = getFormattedAmount(parseFloat(`${this.initialValue}`)).formattedValue;
    this.setValue(formattedValue);
    this.changed.emit(formattedValue);
  }

  onBlur(): void {
    if (!this.valueField.value) {
      this.valueField.reset();
    } else {
      const value = parseFloat(this.valueField.value);
      this.valueField.setValue(getFormattedAmount(value).formattedValue);
    }
  }

  onChange(value: string): void {
    if (this.valueField.dirty) {
      this.isMaximumValue = parseFloat(value) > this.maxAmountValue;
      this.isMinimumValue = !value || parseFloat(value) <= 0;
    }

    this.changed.emit(value);
    this.errored.emit(this.isMaximumValue || this.isMinimumValue);
  }

  resetValue(): void {
    this.valueField.reset();
    this.errored.emit(false);
  }

  setValue(value?: string): void {
    this.valueField.setValue(value);
  }

  isDirty(): boolean {
    return this.valueField.dirty;
  }
}
