import { Component, ElementRef, OnInit, inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { embedDashboard } from '@superset-ui/embedded-sdk';
import { RktFormComponent, formRow, formSelect } from '@rocketfinancialcorp/rocket-ui/form';

import { FormModel } from '@shared/models';
import { APP_ENV_CONFIG, ReportService } from '@shared/services';
import { ErrorUtils } from '@shared/utils';

@Component({
  selector: 'app-superset-dashboard',
  templateUrl: './superset-dashboard.component.html',
  standalone: true,
  imports: [RktFormComponent],
})
export class SupersetDashboardComponent implements OnInit {
  envConfig = inject(APP_ENV_CONFIG);

  dashboardSelectForm = new FormGroup({});

  formFields: FormlyFieldConfig[] = [
    formRow([
      formSelect({
        key: 'dashboardName',
        label: '',
        className: 'dashboard-dropdown',
        defaultValue: 'Insights Dashboard',
        props: {
          options: [
            { label: 'EWA Financing', value: 'EWA Financing' },
            { label: 'Insights Dashboard', value: 'Insights Dashboard' },
          ],
          placeholder: 'Select dashboard',
          required: false,
        },
      }),
    ]),
  ];

  formModel: FormModel<{
    dashboardName: string;
  }> = {};

  loading = false;

  constructor(
    private reportService: ReportService,
    private elementRef: ElementRef,
  ) {}

  ngOnInit() {
    this.formModel.dashboardName = 'Insights Dashboard';
    this.initializeSupersetWidget();
  }

  initializeSupersetWidget() {
    const { dashboardName } = this.formModel;

    if (dashboardName) {
      this.reportService.supersetInitConfig(dashboardName).subscribe({
        next: (response) => {
          const dashboardElement = this.elementRef.nativeElement.querySelector('#dashboard');
          if (dashboardElement) {
            embedDashboard({
              id: response.embeddingUuid,
              supersetDomain: this.envConfig.supersetDomain,
              mountPoint: dashboardElement,
              fetchGuestToken: () => Promise.resolve(response.guestToken),
              dashboardUiConfig: {
                hideTitle: true,
                filters: {
                  expanded: false,
                },
              },
            }).then(
              () => {
                const iframe = dashboardElement.querySelector('iframe');
                if (iframe) {
                  iframe.style.width = '100%';
                  iframe.style.height = 'calc(100vh - 14.625rem)';
                }
              },
              (err) => {
                ErrorUtils.catchError('reportService supersetInitConfig', err);
              },
            );
          }
        },
      });
    }
  }
}
