import { NgIf } from '@angular/common';
import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { isEqual } from 'lodash-es';
import { Subject, finalize, takeUntil } from 'rxjs';
import { RktFormModule, formInputPassword, formRow } from '@rocketfinancialcorp/rocket-ui/form';
import { ActiveModal } from '@rocketfinancialcorp/rocket-ui/modal';

import { PasswordStrengthComponent } from '@shared/components';
import { User } from '@shared/models';
import { AuthService } from '@shared/services';

interface ChangePasswordModel {
  oldPassword: string;
  password: string;
  passwordConfirmation: string;
}

@Component({
  selector: 'app-change-password',
  templateUrl: 'change-password.component.html',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, RktFormModule, PasswordStrengthComponent, NgIf],
})
export class ChangePasswordComponent implements AfterViewChecked, OnDestroy {
  user!: User | null;

  changePasswordForm = new FormGroup({});

  changePasswordModel: Partial<ChangePasswordModel> = {};

  error?: string;

  loading!: boolean;

  passwordStrength = '';

  isAllChecksPass = false;

  changePasswordFields: FormlyFieldConfig[] = [
    formRow([
      formInputPassword({
        key: 'oldPassword',
        label: 'Current password',
        props: {
          required: true,
          type: 'password',
        },
      }),
    ]),
    formRow([
      formInputPassword({
        key: 'password',
        label: 'New password',
        props: {
          required: true,
          type: 'password',
        },
      }),
    ]),
    formRow([
      formInputPassword({
        key: 'passwordConfirmation',
        label: 'Confirm new password',
        props: {
          required: true,
          type: 'password',
        },
        expressions: {
          'props.disabled': '!model.password',
        },
      }),
    ]),
  ];

  get isDataUnchanged() {
    return isEqual({ ...this.changePasswordModel }, this.originalData);
  }

  originalData: Partial<ChangePasswordModel> = {
    oldPassword: '',
    password: '',
    passwordConfirmation: '',
  };

  private destroy$ = new Subject<void>();

  constructor(
    private ref: ChangeDetectorRef,
    public activeModal: ActiveModal,
    private authService: AuthService,
  ) {
    this.changePasswordModel = { ...this.changePasswordModel, ...this.originalData };
  }

  canDeactivate(): boolean {
    return this.isDataUnchanged;
  }

  ngAfterViewChecked(): void {
    this.ref.detectChanges();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onSubmit(): void {
    this.changePasswordForm.markAllAsTouched();
    if (this.changePasswordForm.invalid) {
      return;
    }

    const { oldPassword, password, passwordConfirmation } = this.changePasswordModel;

    if (!oldPassword || !password || !passwordConfirmation) {
      return;
    }

    this.loading = true;
    this.authService
      .changePassword({
        password: oldPassword,
        newPassword: password,
        newPasswordConfirmation: passwordConfirmation,
      })
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => (this.loading = false)),
      )
      .subscribe({
        next: () => this.activeModal.close(true),
        error: (error) => {
          this.error = error.message;
        },
      });
  }

  updatePasswordStrength({ strengthString, isAllChecksPass }: { strengthString: string; isAllChecksPass: boolean }): void {
    this.passwordStrength = strengthString;
    this.isAllChecksPass = isAllChecksPass;
  }

  onPasswordChange(): void {
    const { password, passwordConfirmation } = this.changePasswordModel || {};

    const formEl = this.changePasswordForm.get('passwordConfirmation')!;

    formEl.setErrors(null);

    if (password !== passwordConfirmation) {
      formEl.setErrors({ matchError: { message: 'Password does not match' } });
    }
  }
}
