import { createReducer, on } from '@ngrx/store';

import { ErrorMessage, SubjectQuickInfo, TeamMembersAuditInfo } from '@shared/models';

import { AuditActions } from './audit.actions';

export const auditInfoFeatureKey = 'auditInfo';

export interface AuditInfoState {
  teamMembersAuditInfo: TeamMembersAuditInfo;
  isLoading: boolean;
  error: ErrorMessage;
}

export const initialAuditInfoState: AuditInfoState = {
  teamMembersAuditInfo: {},
  isLoading: false,
  error: null,
};

export const reducer = createReducer<AuditInfoState>(
  initialAuditInfoState,
  on(AuditActions.loadTeamMembers, (state): AuditInfoState => {
    return {
      ...state,
      isLoading: true,
      error: null,
    };
  }),
  on(AuditActions.loadTeamMembersSuccess, (state, action): AuditInfoState => {
    return {
      ...state,
      teamMembersAuditInfo: setTeamMemberAuditInfo(action.teamMembers, state.teamMembersAuditInfo),
      isLoading: false,
    };
  }),
  on(AuditActions.loadTeamMembersError, (state, action): AuditInfoState => {
    return {
      ...state,
      isLoading: false,
      error: action.error,
    };
  }),
);

const setTeamMemberAuditInfo = (teamMembers: SubjectQuickInfo[], info: TeamMembersAuditInfo): TeamMembersAuditInfo => {
  const auditInfo = { ...info };
  teamMembers.forEach((teamMember) => {
    auditInfo[teamMember.id] = teamMember.name;
  });
  return auditInfo;
};

export const getTeamMembersAuditInfo = (state: AuditInfoState) => state?.teamMembersAuditInfo;
