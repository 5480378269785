import { NgFor, NgIf } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, HostBinding, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { format } from 'date-fns';
import { isString, uniqueId } from 'lodash-es';
import { RktButtonDirective } from '@rocketfinancialcorp/rocket-ui/button';
import { RktIconComponent } from '@rocketfinancialcorp/rocket-ui/icon';

import { FilterField, FilterOption, FilterValue } from '@shared/models';

@Component({
  selector: 'app-filter-date',
  templateUrl: './filter-date.component.html',
  standalone: true,
  imports: [RktButtonDirective, NgIf, NgFor, FormsModule, RktIconComponent],
})
export class FilterDateComponent implements OnChanges, AfterViewInit {
  @HostBinding('class.rkt-filter-item-container') commonClass = true;

  @Input() options: FilterOption[] = [];

  @Input() filterName?: string;

  @Input() selectedValue?: FilterValue;

  @Input() defaultValue?: string;

  @Input() fieldError?: boolean;

  @Input() props?: FilterField['props'];

  @Input() isCollapsable = true;

  @Input() isCalendarVisible?: boolean;

  @Output() changed = new EventEmitter<string>();

  @Output() edit = new EventEmitter<string>();

  @Output() filterToggled = new EventEmitter<void>();

  fromDate: string | null = null;

  toDate: string | null = null;

  customDate: string | null = null;

  isCollapsed = true;

  id = uniqueId();

  get isEditDateRangeAvailable(): boolean {
    const isCustomRangeSelected = this.selectedValue === 'CUSTOM';
    const isDateRangeSelected = !!this.fromDate && !!this.toDate;

    return !this.isCalendarVisible && isCustomRangeSelected && isDateRangeSelected;
  }

  get isEditCustomDateAvailable(): boolean {
    const isCustomDateSelected = this.selectedValue === 'CUSTOM_DATE';
    const isCustomDateSet = !!this.customDate;

    return !this.isCalendarVisible && isCustomDateSelected && isCustomDateSet;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedValue?.currentValue && isString(changes.selectedValue.currentValue)) {
      const [type, from, to] = changes.selectedValue.currentValue.split('::');
      this.selectedValue = type;

      if (type === 'CUSTOM' && from && to) {
        this.fromDate = format(new Date(from + 'T00:00'), 'MM/dd/yyyy');
        this.toDate = format(new Date(to + 'T00:00'), 'MM/dd/yyyy');
      }

      if (type === 'CUSTOM_DATE' && from) {
        this.customDate = format(new Date(from + 'T00:00'), 'MM/dd/yyyy');
      }
    }
    if (changes.selectedValue && !changes.selectedValue?.currentValue) {
      this.fromDate = null;
      this.toDate = null;
      this.customDate = null;
    }
  }

  ngAfterViewInit(): void {
    this.isCollapsed = this.isCollapsable && (!this.selectedValue || this.selectedValue === 'DEFAULT');
  }

  onChange(value: string | undefined) {
    if (value === 'CUSTOM') {
      this.fromDate = null;
      this.toDate = null;
    }

    if (value === 'CUSTOM_DATE') {
      this.customDate = null;
    }
    this.isCalendarVisible = false;
    this.changed.emit(value);
  }

  onEditClick(value: string): void {
    this.edit.emit(value);
  }

  toggleFilter() {
    if (!this.isCollapsable) {
      return;
    }

    this.isCollapsed = !this.isCollapsed;
    this.filterToggled.emit();
  }
}
