import { createFeature, createReducer, on } from '@ngrx/store';

import { Recipient } from '@shared/models';

import { RecipientDetailsActions } from './recipients.actions';

export interface RecipientsFeatureState {
  recipientDetails: Recipient | null;
}

export const recipientsInitialState: RecipientsFeatureState = {
  recipientDetails: null,
};

export const recipientsFeature = createFeature({
  name: 'recipientsFeature',
  reducer: createReducer(
    recipientsInitialState,
    on(RecipientDetailsActions.loadRecipientDetails, (state): RecipientsFeatureState => {
      return { ...state, recipientDetails: null };
    }),
    on(RecipientDetailsActions.loadRecipientDetailsSuccess, (state, { recipientDetails }): RecipientsFeatureState => {
      return { ...state, recipientDetails };
    }),
    on(RecipientDetailsActions.loadRecipientDetailsFailure, (state): RecipientsFeatureState => {
      return { ...state, recipientDetails: null };
    }),
  ),
});
