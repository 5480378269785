import { AsyncPipe, NgIf, NgTemplateOutlet, TitleCasePipe, UpperCasePipe } from '@angular/common';
import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { RktButtonDirective } from '@rocketfinancialcorp/rocket-ui/button';
import { RktIconComponent } from '@rocketfinancialcorp/rocket-ui/icon';
import { ModalService } from '@rocketfinancialcorp/rocket-ui/modal';
import { ShortIdPipe } from '@rocketfinancialcorp/rocket-ui/table';

import { CardReissueModalComponent } from '@shared/components';
import { FinancialAccountDetails, TemplateContext } from '@shared/models';
import { CardAccountActions, fromFinancialAccount } from '@shared/store';

import { TemplateContextDirective } from '../../../directives/template-context.directive';
import { AccessControlPipe } from '../../../pipes/access-control.pipe';
import { DateFormatPipe } from '../../../pipes/date-format.pipe';
import { TeamMemberNamePipe } from '../../../pipes/team-member-name.pipe';
import { DetailsItemComponent } from '../../details-item/details-item.component';
import { DetailsNotesComponent } from '../../details-notes/details-notes.component';
import { TextWithPlaceholderComponent } from '../../text-with-placeholder/text-with-placeholder.component';

@Component({
  selector: 'app-card-account-details',
  templateUrl: './card-account-details.component.html',
  styleUrls: ['./card-account-details.component.scss'],
  standalone: true,
  imports: [
    RktButtonDirective,
    NgIf,
    NgTemplateOutlet,
    TemplateContextDirective,
    DetailsItemComponent,
    TextWithPlaceholderComponent,
    DetailsNotesComponent,
    AsyncPipe,
    UpperCasePipe,
    TitleCasePipe,
    DateFormatPipe,
    TeamMemberNamePipe,
    AccessControlPipe,
    RktIconComponent,
    ShortIdPipe,
  ],
})
export class CardAccountDetailsComponent {
  pageClassPrefix = 'card-details-';

  cardAccount$ = this.store.select(fromFinancialAccount.selectCardAccountDetails);

  cardAccountPageContext?: TemplateContext<FinancialAccountDetails>;

  constructor(
    private store: Store,
    private modalService: ModalService,
  ) {}

  onCardReissueClick(financialAccountId: string) {
    const cardReissueModalRef = this.modalService.open(CardReissueModalComponent, {
      className: 'entity-form-modal',
    });

    cardReissueModalRef.componentInstance.modalInitData({ financialAccountId });

    cardReissueModalRef.result.then(
      (result) => {
        if (result) {
          this.store.dispatch(CardAccountActions.loadCardAccountDetails({}));
        }
      },
      () => false,
    );
  }
}
