import { AfterViewChecked, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { BehaviorSubject, Subject, map, takeUntil } from 'rxjs';
import { RktButtonDirective } from '@rocketfinancialcorp/rocket-ui/button';
import { RktFormComponent, RktFormFieldConfig, formRepeatSection, formSelect } from '@rocketfinancialcorp/rocket-ui/form';

import { LinxDocuments, LinxRequestDocumentDetails } from '@shared/models';
import { LinxService } from '@shared/services';
import { ErrorUtils } from '@shared/utils';

export interface DocumentNameOption {
  id: string;
  title: string;
  groupId: string;
}

@Component({
  selector: 'app-linx-request-document-acceptance',
  templateUrl: './linx-request-document-acceptance.component.html',
  standalone: true,
  imports: [RktButtonDirective, FormsModule, ReactiveFormsModule, RktFormComponent],
})
export class LinxRequestDocumentAcceptanceComponent implements OnInit, AfterViewChecked, OnDestroy {
  @Input() selectedDocuments?: { documentGroup: string; documentId: string }[];
  @Output() continue = new EventEmitter<LinxRequestDocumentDetails[]>();

  documentAcceptanceForm = new UntypedFormGroup({});

  formModel = {
    documents: [{ documentGroup: '', documentId: '' }],
  };

  documentAcceptanceDetails: LinxDocuments[] = [];

  documentGroups: { value: string; label: string }[] = [];
  documentNameOptions$ = new BehaviorSubject<DocumentNameOption[]>([]);

  formFields: RktFormFieldConfig[] = [
    formRepeatSection({
      key: 'documents',
      addText: 'Add document',
      maxItems: 5,
      fieldGroup: [
        formSelect({
          key: 'documentGroup',
          label: 'Document Group',
          props: {
            options: this.documentGroups,
            required: true,
            change: (field: FormlyFieldConfig) => field.form?.get('documentId')?.reset(),
          },
          className: 'rkt-form-control',
        }),
        formSelect({
          key: 'documentId',
          label: 'Document Name',
          props: {
            required: true,
          },
          className: 'rkt-form-control',
          expressions: {
            'props.disabled': '!model?.documentGroup',
            'props.options': (field: FormlyFieldConfig) =>
              this.documentNameOptions$.asObservable().pipe(
                map((documents) => {
                  return documents
                    .filter((document) => field?.model?.documentGroup === document.groupId)
                    .map((document) => ({
                      label: document.title,
                      value: document.id,
                    }));
                }),
              ),
          },
        }),
      ],
    }),
  ];
  documentAcceptanceFields: RktFormFieldConfig[] = [];

  get isContinueDisabled(): boolean {
    return Object.keys(this.formModel.documents).length === 0;
  }

  private destroy$ = new Subject<void>();

  constructor(
    private linxService: LinxService,
    private ref: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.getDocumentAcceptanceDetails();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngAfterViewChecked(): void {
    this.ref.detectChanges();
  }

  getDocumentAcceptanceDetails(): void {
    this.linxService
      .getLinXDocumentAcceptanceDetails()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (documentsDetails) => {
          this.onFetchComplete(documentsDetails);
          if (this.selectedDocuments) {
            this.formModel.documents = this.selectedDocuments;
            this.onClickContinue();
          }
        },
        error: (error) => {
          this.onFetchComplete();
          ErrorUtils.catchError('linxService.getLinXDocumentAcceptanceDetails', error);
        },
      });
  }

  onFetchComplete(documentsDetails?: LinxDocuments[]): void {
    this.documentAcceptanceDetails = documentsDetails ?? [];
    const documentNames: DocumentNameOption[] = [];
    this.documentAcceptanceDetails.forEach((documentGroup) => {
      this.documentGroups.push({
        value: documentGroup.documentGroup,
        label: documentGroup.documentGroupTitle,
      });
      documentGroup.documents.forEach((document) => {
        documentNames.push({
          id: document.id,
          title: document.title,
          groupId: documentGroup.documentGroup,
        });
      });
    });
    this.documentNameOptions$.next(documentNames);
    this.documentAcceptanceFields = this.formFields;
  }

  onClickContinue(): void {
    this.documentAcceptanceForm.markAllAsTouched();

    if (this.documentAcceptanceForm.invalid) {
      return;
    }

    const documentAcceptanceData: LinxRequestDocumentDetails[] = [];
    this.formModel.documents.forEach((document) => {
      const documentGroup = this.documentAcceptanceDetails.find((group) => group.documentGroup === document.documentGroup);
      const documentName = documentGroup?.documents.find((doc) => doc.id === document.documentId)?.title;
      documentAcceptanceData.push({
        ...document,
        documentGroupTitle: documentGroup?.documentGroupTitle,
        documentTitle: documentName,
      });
    });
    this.continue.emit(documentAcceptanceData);
  }

  resetForm(): void {
    this.documentAcceptanceForm.reset();
  }
}
