<div class="flex space-x-4 my-4">
  <button
    (click)="onItemClick(LinxProfileType.New)"
    class="flex-1 px-6 py-4 border-2 rounded-md font-medium border-gray-300"
    [ngClass]="{
      'border-primary text-primary': profileType() === LinxProfileType.New,
    }"
  >
    <span class="flex items-center space-x-2 text-base">
      <rkt-icon name="plus"></rkt-icon>
      <span>New {{ entityType() | titlecase }}</span>
    </span>
  </button>

  <button
    (click)="onItemClick(LinxProfileType.Existing)"
    class="flex-1 px-6 py-4 border-2 rounded-md font-medium border-gray-300"
    [ngClass]="{
      'border-primary text-primary': profileType() === LinxProfileType.Existing,
    }"
  >
    <span class="flex items-center space-x-2 text-base">
      <rkt-icon name="profile"></rkt-icon>
      <span>Existing {{ entityType() | titlecase }}</span>
    </span>
  </button>
</div>
