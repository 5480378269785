import { NgFor, NgIf } from '@angular/common';
import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { uniqueId } from 'lodash-es';
import { RktButtonDirective } from '@rocketfinancialcorp/rocket-ui/button';
import { RktIconComponent } from '@rocketfinancialcorp/rocket-ui/icon';

import { FilterField, FilterOption, FilterValue } from '@shared/models';

@Component({
  selector: 'app-filter-radio-group',
  templateUrl: './filter-radio-group.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [RktButtonDirective, NgIf, NgFor, FormsModule, RktIconComponent],
})
export class FilterRadioGroupComponent implements AfterViewInit, AfterViewChecked {
  @HostBinding('class.rkt-filter-item-container') commonClass = true;

  @Input() options: FilterOption[] = [];

  @Input() props?: FilterField['props'];

  @Input() filterName?: string;

  @Input() selectedValue?: FilterValue;

  @Input() defaultValue?: string;

  @Input() isCollapsable = true;

  @Output() changed = new EventEmitter<string>();

  @Output() filterToggled = new EventEmitter<void>();

  isCollapsed = true;

  id = uniqueId();

  constructor(public ref: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this.isCollapsed = this.isCollapsable && !this.selectedValue;
  }

  ngAfterViewChecked(): void {
    this.ref.detectChanges();
  }

  toggleFilter() {
    if (!this.isCollapsable) {
      return;
    }
    this.isCollapsed = !this.isCollapsed;
    this.filterToggled.emit();
  }
}
