import { createActionGroup, props } from '@ngrx/store';

import { LinxEntityModalData } from '@shared/models';

export const LinxActions = createActionGroup({
  source: 'Linx',
  events: {
    'Set Entity Modal Data': props<{ entityModalData: LinxEntityModalData }>(),
  },
});
