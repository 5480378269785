import { AsyncPipe } from '@angular/common';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { differenceInDays } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import { compact, omit } from 'lodash-es';
import { Observable, Subject, of } from 'rxjs';
import { catchError, exhaustMap, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { RktIconComponent } from '@rocketfinancialcorp/rocket-ui/icon';
import { ModalService } from '@rocketfinancialcorp/rocket-ui/modal';

import { MoveMoneyModalComponent, MoveMoneyReviewComponent } from '@modules/transaction/components';
import {
  ConfirmModalComponent,
  CreateTransactionReviewModalComponent,
  ErrorModalComponent,
  MultiLegTransactionEditModalComponent,
  ReverseTransactionModalComponent,
  SuccessModalComponent,
  TransactionReversalReasonModalComponent,
} from '@shared/components';
import { FocusRemoveDirective } from '@shared/directives';
import {
  FinancialAccountDetails,
  TransactionDetails,
  TransactionEditRequestData,
  TransactionReverseRequestData,
  TransactionStatus,
} from '@shared/models';
import { AccessControlPipe } from '@shared/pipes';
import { FinancialAccountService, NoteService, TransactionService, achEntryTypeCodes } from '@shared/services';
import { TransactionFormActions, TransactionSolutionActions } from '@shared/store';
import { ErrorUtils, getSolutionDisplayName, toTitleCase } from '@shared/utils';

const MAX_DAYS_REVERSE_AVAILABLE = 5;

@Component({
  selector: 'app-transaction-detail-actions',
  templateUrl: 'transaction-detail-actions.component.html',
  standalone: true,
  imports: [FocusRemoveDirective, RktIconComponent, AsyncPipe, AccessControlPipe],
})
export class TransactionDetailActionsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() transaction!: TransactionDetails;

  @Input() disabled?: boolean;

  @Input() hasNotes?: boolean;

  public creditFinancialAccount?: FinancialAccountDetails;

  public debitFinancialAccount?: FinancialAccountDetails;

  private reversalAllowedMaxDays = MAX_DAYS_REVERSE_AVAILABLE;

  private editingAllowedStatuses: TransactionStatus['status'][] = ['PENDING', 'NEW'];

  private cancelationAllowedStatuses: TransactionStatus['status'][] = ['PENDING', 'NEW'];

  private reversalAllowedStatuses: TransactionStatus['status'][] = ['CLEARED', 'SETTLED'];

  private reversalAllowedSolutions = ['ach', 'millikart-transaction'];

  private destroy$ = new Subject<void>();

  get isAchSolution(): boolean {
    const { solution } = this.transaction || {};

    return solution === 'ach';
  }

  get isAchReturn(): boolean {
    return this.transaction?.transactionType === 'RETURN';
  }

  get isAchReversal(): boolean {
    return this.transaction?.transactionType === 'REVERSAL';
  }

  get isMillikartTransation(): boolean {
    return this.transaction.isMillikartSolution;
  }

  get isEditDisabled(): boolean {
    const { latestStatus } = this.transaction || {};

    if (!this.isAchSolution || !latestStatus?.status || this.hasNotes) {
      return true;
    }

    return !this.editingAllowedStatuses.includes(latestStatus.status);
  }

  get isReverseDisabled(): boolean {
    const { latestStatus, createdAt, solution } = this.transaction || {};

    if (
      !createdAt ||
      !this.reversalAllowedSolutions.includes(solution) ||
      !latestStatus?.status ||
      !this.reversalAllowedStatuses.includes(latestStatus.status)
    ) {
      return true;
    }

    const daysDiff = differenceInDays(toZonedTime(new Date(), 'US/Eastern'), toZonedTime(new Date(createdAt), 'US/Eastern'));

    return daysDiff > this.reversalAllowedMaxDays;
  }

  get isCancelDisabled(): boolean {
    const { latestStatus } = this.transaction || {};

    if (!this.isAchSolution || !latestStatus?.status) {
      return true;
    }

    return !this.cancelationAllowedStatuses.includes(latestStatus.status);
  }

  constructor(
    private router: Router,
    private transactionService: TransactionService,
    private noteService: NoteService,
    private modalService: ModalService,
    private financialAccountService: FinancialAccountService,
    private store: Store,
  ) {}

  ngOnInit(): void {
    this.store.dispatch(TransactionSolutionActions.loadEnabledSolutions());
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.transaction && changes.transaction.currentValue && !changes.transaction.previousValue) {
      this.financialAccountService
        .getFinancialAccountDetailsWithBalances(this.transaction.creditFinancialAccountId)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (response) => {
            this.creditFinancialAccount = this.mapFinancialAccountData(response);
          },
        });

      this.financialAccountService
        .getFinancialAccountDetailsWithBalances(this.transaction.debitFinancialAccountId)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (response) => {
            this.debitFinancialAccount = this.mapFinancialAccountData(response);
          },
        });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  showEditConfirmationModal(editData: TransactionEditRequestData): void {
    const reviewModalRef = this.modalService.open(MoveMoneyReviewComponent, {
      className: 'move-money-review-modal',
    });
    reviewModalRef.componentInstance.modalInitData(editData.reviewData);

    reviewModalRef.result.then(
      (result) => {
        if (result) {
          this.editTransaction(editData);
        } else {
          this.onEditBtnClick();
        }
      },
      () => false,
    );
  }

  public onReverseBtnClick(): void {
    const confirmModalRef = this.modalService.open(ConfirmModalComponent, { className: 'confirm-modal reverse-transaction-modal' });

    confirmModalRef.componentInstance.title = 'Reverse Transaction';
    confirmModalRef.componentInstance.customText = 'Are you sure you want to reverse the transaction?';

    confirmModalRef.result.then(
      (result) => {
        if (result) {
          this.openReverseReasonModal();
        }
      },
      () => false,
    );
  }

  public openReverseReasonModal(): void {
    const reversalReasonModalRef = this.modalService.open(TransactionReversalReasonModalComponent, {
      className: 'transaction-reversal-reason-modal entity-form-modal',
    });

    reversalReasonModalRef.componentInstance.modalInitData(this.transaction.solution);

    reversalReasonModalRef.result.then(
      (reasonId) => {
        if (!reasonId) {
          return;
        }

        if (['DUPLICATE_PAYMENT', 'EARLY_DEBIT', 'LATE_CREDIT'].includes(reasonId) || this.isMillikartTransation) {
          this.reverseTransaction({ reversalReason: reasonId });
        } else {
          this.openReverseTransactionModal(reasonId);
        }
      },
      () => false,
    );
  }

  public openReverseTransactionModal(reasonId: string): void {
    const reverseModalRef = this.modalService.open(ReverseTransactionModalComponent, {
      className: 'edit-transaction-modal',
    });

    reverseModalRef.componentInstance.modalInitData({
      transaction: this.transaction,
      creditFinancialAccount: this.creditFinancialAccount,
      debitFinancialAccount: this.debitFinancialAccount,
      reverseReason: reasonId,
    });

    reverseModalRef.result.then(
      (reverseData) => {
        this.showReverseConfirmationModal(reverseData);
      },
      () => false,
    );
  }

  public onCancelBtnClick(): void {
    const confirmModalRef = this.modalService.open(ConfirmModalComponent, { className: 'confirm-modal cancel-transaction-confirm-modal' });

    confirmModalRef.componentInstance.customText =
      'Canceling a transaction cannot be undone. Are you sure you want to cancel the transaction?';

    confirmModalRef.result.then(
      (result) => {
        if (result) {
          this.cancelTransaction();
        }
      },
      () => false,
    );
  }

  mapFinancialAccountData(financialAccount: FinancialAccountDetails): FinancialAccountDetails {
    const displayName = [financialAccount.name ?? financialAccount.bankAccount?.nameOnAccount ?? ''];

    if (financialAccount.bankAccount || financialAccount.card) {
      displayName.push(
        financialAccount.bankAccount
          ? `**** ${financialAccount.bankAccount.accountNumberTail} | ${toTitleCase(financialAccount.subtype)} Acc`
          : `**** ${financialAccount.card!.cardNumberTail} | ${toTitleCase(financialAccount.subtype)} Card`,
      );
    }

    return {
      ...financialAccount,
      availableBalance: financialAccount.accountBalances?.availableBalance ?? 0,
      displayName: compact(displayName).join('; '),
    };
  }

  cancelTransaction(): void {
    this.transactionService
      .cancelTransaction(this.transaction.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.onCancelTransactionSuccess();
        },
        error: (error) => {
          this.onCancelTransactionError(error);
        },
      });
  }

  onCancelTransactionSuccess(): void {
    const cancelSuccessModalRef = this.modalService.open(SuccessModalComponent, { className: 'success-modal' });

    cancelSuccessModalRef.componentInstance.actionName = 'submitted a request to cancel';
    cancelSuccessModalRef.componentInstance.type = 'transaction';

    cancelSuccessModalRef.result.finally(() => this.reloadPage({}));
  }

  onCancelTransactionError(error?: string | number): void {
    const cancelErrorModalRef = this.modalService.open(ErrorModalComponent, { className: 'confirm-modal' });
    cancelErrorModalRef.componentInstance.errorType = error;
  }

  showReverseConfirmationModal(reverseData?: TransactionReverseRequestData): void {
    if (!reverseData) {
      return;
    }

    const reviewModalRef = this.modalService.open(MoveMoneyReviewComponent, {
      className: 'move-money-review-modal',
    });
    reviewModalRef.componentInstance.modalInitData(reverseData.reviewData);

    reviewModalRef.result.then(
      (result) => {
        if (result) {
          this.reverseTransaction(reverseData);
        } else {
          this.openReverseTransactionModal(reverseData.reversalReason);
        }
      },
      () => false,
    );
  }

  reverseTransaction(reverseData: TransactionReverseRequestData): void {
    this.transactionService
      .reverseTransaction(this.transaction.id, omit(reverseData, 'note', 'reviewData'))
      .pipe(
        switchMap((transactionResponse) => this.addNote(transactionResponse, reverseData.note)),
        take(1),
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: (transaction) => this.onReverseTransactionSuccess(transaction),
        error: (error) => this.onReverseEditTransactionError(error),
      });
  }

  onReverseTransactionSuccess(transaction: TransactionDetails): void {
    const successModalRef = this.modalService.open(MoveMoneyModalComponent, {
      className: 'move-money-success-modal',
    });
    const moveAchEntryType = transaction.metadata?.rkorACHEntryType;

    successModalRef.componentInstance.title = 'Transaction Successfully Reversed!';

    successModalRef.componentInstance.id = transaction.id;
    successModalRef.componentInstance.solution = compact([
      getSolutionDisplayName(transaction.solution),
      achEntryTypeCodes.find((item) => item.value === moveAchEntryType)?.label ?? '',
    ]).join(' | ');
    successModalRef.componentInstance.settlementPriority = toTitleCase(transaction.settlementPriority?.replace('_', ' '));
    successModalRef.componentInstance.fromAccountNumber = compact([
      this.transaction.debitFinancialAccount?.bankAccount?.nameOnAccount,
      this.transaction.debitFinancialAccount?.bankAccount?.accountNumber,
    ]).join(' ');
    successModalRef.componentInstance.toAccountNumber = compact([
      this.transaction.creditFinancialAccount?.bankAccount?.nameOnAccount,
      this.transaction.creditFinancialAccount?.bankAccount?.accountNumber,
    ]).join(' ');
    successModalRef.componentInstance.reason = this.transaction.paymentReason;
    successModalRef.componentInstance.amount = transaction.amount;
    successModalRef.componentInstance.transactionCurrency = transaction.currency;
    successModalRef.result.finally(() => this.reloadPage({}));
  }

  onReverseEditTransactionError(errorMessage?: string): void {
    const errorModalRef = this.modalService.open(ErrorModalComponent, { className: 'confirm-modal' });
    errorModalRef.componentInstance.modalInitData(errorMessage);
    errorModalRef.result.finally(() => this.reloadPage({ id: this.transaction.id }));
  }

  editTransaction({ note, ...editData }: TransactionEditRequestData): void {
    const { debitFinancialAccountId, creditFinancialAccountId, paymentReasonId, amount } = editData;
    this.transactionService
      .editTransaction(this.transaction.id, { debitFinancialAccountId, creditFinancialAccountId, paymentReasonId, amount })
      .pipe(
        exhaustMap((transactionResponse) => this.addNote(transactionResponse, note)),
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: (response) => this.onEditTransactionSuccess(response),
        error: () => this.onReverseEditTransactionError(),
      });
  }

  onEditTransactionSuccess(transaction: TransactionDetails) {
    const successModalRef = this.modalService.open(MoveMoneyModalComponent, {
      className: 'move-money-success-modal',
    });

    successModalRef.componentInstance.title = 'Transaction Successfully Updated!';

    const moveAchEntryType = transaction.metadata?.rkorACHEntryType;

    successModalRef.componentInstance.id = transaction.id;
    successModalRef.componentInstance.solution = compact([
      getSolutionDisplayName(transaction.solution),
      achEntryTypeCodes.find((item) => item.value === moveAchEntryType)?.label ?? '',
    ]).join(' | ');
    successModalRef.componentInstance.settlementPriority = toTitleCase(transaction.settlementPriority?.replace('_', ' '));
    successModalRef.componentInstance.fromAccountNumber = compact([
      this.transaction.debitFinancialAccount?.bankAccount?.nameOnAccount,
      this.transaction.debitFinancialAccount?.bankAccount?.accountNumber,
    ]).join(' ');
    successModalRef.componentInstance.toAccountNumber = compact([
      this.transaction.creditFinancialAccount?.bankAccount?.nameOnAccount,
      this.transaction.creditFinancialAccount?.bankAccount?.accountNumber,
    ]).join(' ');
    successModalRef.componentInstance.reason = this.transaction.paymentReason;
    successModalRef.componentInstance.amount = transaction.amount;
    successModalRef.result.finally(() => this.reloadPage({ id: transaction.id }));
  }

  addNote(transactionResponse: TransactionDetails, note?: string): Observable<TransactionDetails> {
    if (!note) {
      return of(transactionResponse);
    }

    return this.noteService
      .addNote({
        entityId: transactionResponse.id,
        entityType: 'TRANSACTION',
        contentText: note,
      })
      .pipe(
        catchError(() => of(transactionResponse)),
        map(() => transactionResponse),
      );
  }

  reloadPage({ id, transactionPath }: { id?: TransactionDetails['id']; transactionPath?: string }): void {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(
      () => {
        if (transactionPath) {
          this.router.navigateByUrl(transactionPath);
        } else {
          this.router.navigate(['app', 'transactions', 'transactions', id ?? this.transaction.id]);
        }
      },
      () => null,
    );
  }

  onEditBtnClick(): void {
    this.store.dispatch(TransactionFormActions.formUpdateSltInitialize());
    this.onEditStage();
  }

  onEditStage(): void {
    const editModalRef = this.modalService.open(MultiLegTransactionEditModalComponent, {
      className: 'multi-leg-transaction-edit-modal',
    });

    editModalRef.result.then(
      (action?: 'REVIEW') => {
        if (action === 'REVIEW') {
          this.onReviewStage();
        }
      },
      () => false,
    );
  }

  onReviewStage(): void {
    const reviewModalRef = this.modalService.open(CreateTransactionReviewModalComponent, { className: 'create-transaction-review-modal' });

    reviewModalRef.componentInstance.modalInitData({ isUpdate: true });

    reviewModalRef.result.then(
      ({ action, isTransactionCreated, error, transactionPath } = {}) => {
        if (action === 'EDIT') {
          this.onEditStage();
        } else if (error) {
          this.onSumbitError(error);
        } else if (isTransactionCreated) {
          this.reloadPage({ transactionPath });
        }
      },
      () => false,
    );
  }

  onSumbitError(error: string | number | HttpErrorResponse) {
    if (error === HttpStatusCode.InternalServerError) {
      return;
    }

    const errorModalRef = this.modalService.open(ErrorModalComponent, { className: 'confirm-modal create-transaction-error-modal' });

    errorModalRef.componentInstance.messageText = ErrorUtils.parseExtendedError(error);
  }
}
