import { AsyncPipe, NgFor, NgIf, NgTemplateOutlet } from '@angular/common';
import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { Store } from '@ngrx/store';
import { find, some } from 'lodash-es';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { RktIconComponent } from '@rocketfinancialcorp/rocket-ui/icon';
import { ModalService } from '@rocketfinancialcorp/rocket-ui/modal';
import { RktTableSearchComponent } from '@rocketfinancialcorp/rocket-ui/table';

import { BusinessAccountListComponent } from '@shared/components';
import { NavigationMenuItem } from '@shared/models';
import { fromAuth } from '@shared/store';

@Component({
  selector: 'app-sidebar',
  templateUrl: 'sidebar.component.html',
  styleUrls: ['sidebar.component.scss'],
  standalone: true,
  imports: [NgIf, RouterLink, NgFor, NgTemplateOutlet, AsyncPipe, RktIconComponent, RktTableSearchComponent],
})
export class SidebarComponent implements AfterViewChecked, AfterViewInit, OnDestroy {
  @Input() menuItems: NavigationMenuItem[] = [];

  businessAccountName$ = this.store.select(fromAuth.selectBusinessAccountName);

  isSponsorTenant$ = this.store.select(fromAuth.selectSponsorTenant).pipe(take(1));

  private destroy$ = new Subject<void>();

  constructor(
    public ref: ChangeDetectorRef,
    private router: Router,
    private store: Store,
    private modalService: ModalService,
  ) {}

  ngAfterViewInit(): void {
    const menuItem = find(this.menuItems, (item) => {
      return (item.href && this.isActive(item.href)) || this.hasActiveSubitem(item);
    });
    if (!menuItem) return;
    menuItem.isCollapsed = false;
  }

  ngAfterViewChecked(): void {
    this.ref.detectChanges();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  isActive(menuHref: string): boolean {
    return this.router.isActive(menuHref, {
      paths: 'subset',
      queryParams: 'subset',
      fragment: 'ignored',
      matrixParams: 'ignored',
    });
  }

  hasActiveSubitem(menuItem: NavigationMenuItem): boolean {
    const isActiveChildren = some(menuItem.subItems, (item) => this.isActive(item.href || ''));
    if (isActiveChildren) {
      menuItem.isCollapsed = false;
    }
    return isActiveChildren;
  }

  toggleCollapsableItem(menuItem: NavigationMenuItem): void {
    menuItem.isCollapsed = !menuItem.isCollapsed;
  }

  openBusinessAccountListModal() {
    this.modalService.open(BusinessAccountListComponent, {
      className: 'business-account-list-modal',
      size: 'full-size',
    });
  }
}
