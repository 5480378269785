import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { endOfMonth, endOfWeek, endOfYear, format, startOfMonth, startOfWeek, startOfYear, sub } from 'date-fns';

import { FilterValues } from '@shared/models';

export const activeFilters = (filterValues: FilterValues) => {
  return Object.fromEntries(Object.entries(filterValues).filter(([, value]) => value !== undefined && value !== null));
};

export const filterCreatedAtDateRange = (
  createdAt?: string,
  defaultRange?: { from: string; to: string },
): { from: string; to: string } | undefined => {
  if (!createdAt) {
    return;
  }

  let from, to;
  const createdAtParsed = createdAt.split('::');
  const type = createdAtParsed[0];

  switch (type) {
    case 'TODAY':
      from = to = format(new Date(), 'yyyy-MM-dd');
      break;

    case 'YESTERDAY':
      from = to = format(sub(new Date(), { days: 1 }), 'yyyy-MM-dd');
      break;

    case 'THIS_WEEK':
      from = format(startOfWeek(new Date(), { weekStartsOn: 1 }), 'yyyy-MM-dd');
      to = format(endOfWeek(new Date(), { weekStartsOn: 1 }), 'yyyy-MM-dd');
      break;

    case 'THIS_MONTH':
      from = format(startOfMonth(new Date()), 'yyyy-MM-dd');
      to = format(endOfMonth(new Date()), 'yyyy-MM-dd');
      break;

    case 'THIS_YEAR':
      from = format(startOfYear(new Date()), 'yyyy-MM-dd');
      to = format(endOfYear(new Date()), 'yyyy-MM-dd');
      break;

    case 'DEFAULT':
      if (defaultRange) {
        from = defaultRange.from;
        to = defaultRange.to;
      } else {
        from = format(sub(new Date(), { days: 7 }), 'yyyy-MM-dd');
        to = format(new Date(), 'yyyy-MM-dd');
      }
      break;

    default:
      from = createdAtParsed[1];
      to = createdAtParsed[2];
      break;
  }

  return {
    from,
    to,
  };
};

export const filterCustomDate = (customDate?: string, defaultDate?: string): string | undefined => {
  if (!customDate) {
    return;
  }

  let date;
  const customDateParsed = customDate.split('::');
  const type = customDateParsed[0];

  switch (type) {
    case 'TODAY':
      date = format(new Date(), 'yyyy-MM-dd');
      break;

    case 'YESTERDAY':
      date = format(sub(new Date(), { days: 1 }), 'yyyy-MM-dd');
      break;

    case 'DEFAULT':
      if (defaultDate) {
        date = defaultDate;
      } else {
        date = format(sub(new Date(), { days: 7 }), 'yyyy-MM-dd');
      }
      break;

    default:
      date = customDateParsed[1];
      break;
  }

  return date;
};

export const filterAmountMinMax = (amount?: string): { min?: string; max?: string } => {
  if (!amount) {
    return {};
  }

  const [min, max] = amount.split('::') || [];

  if (!min || !max) {
    return {};
  }

  return {
    min,
    max,
  };
};

export const filterFormatDate = (date: number | Date) => {
  const dateFormatted = format(date, 'MM dd yyyy').split(' ');

  return NgbDate.from({
    month: parseInt(dateFormatted[0], 10),
    day: parseInt(dateFormatted[1], 10),
    year: parseInt(dateFormatted[2], 10),
  });
};

export const filterDateToObject = (value: string, delimiter: string) => {
  if (!value) {
    return null;
  }

  const date = value.split(delimiter);
  return {
    month: parseInt(date[0], 10),
    day: parseInt(date[1], 10),
    year: parseInt(date[2], 10),
  };
};
