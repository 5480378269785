import { ChangeDetectionStrategy, Component, OnInit, computed, input, output } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RktButtonDirective } from '@rocketfinancialcorp/rocket-ui/button';
import { RktFormComponent } from '@rocketfinancialcorp/rocket-ui/form';

import { LinxAccountType, LinxEnhanceAccountType, LinxEntityType } from '@shared/constants';
import { FormModel, LinxEnhanceAccountDetails, LinxEnhanceProfileDetails } from '@shared/models';

import { idDocumentBusinessFields, idDocumentIndividualFields, linxRequestProfileFields } from './form-fields';

interface EnhanceProfileFormModel {
  businessInfo: Record<string, boolean>;
  personalInfo: Record<string, boolean>;
  addresses: Record<string, boolean>;
  phoneNumber: string;
  documentTypes: Record<string, boolean>;
}

@Component({
  selector: 'app-enhance-profile-edit',
  templateUrl: './enhance-profile-edit.component.html',
  standalone: true,
  imports: [RktButtonDirective, FormsModule, ReactiveFormsModule, RktFormComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnhanceProfileEditComponent implements OnInit {
  entityType = input<LinxEntityType>();

  accountType = input<LinxAccountType | undefined>(LinxAccountType.INDIVIDUAL);

  selectedEnhanceProfileDetails = input<LinxEnhanceAccountDetails>();

  continue = output<LinxEnhanceAccountDetails>();

  profileForm = new FormGroup({});

  formModel: FormModel<EnhanceProfileFormModel> = {};

  profileFields = computed(() => {
    return linxRequestProfileFields(this.accountType(), this.entityType());
  });

  documentFields = computed(() => {
    return this.accountType() === LinxAccountType.BUSINESS ? idDocumentBusinessFields : idDocumentIndividualFields;
  });

  ngOnInit(): void {
    this.setEnhanceProfile();
  }

  private setEnhanceProfile(): void {
    if (!this.selectedEnhanceProfileDetails) return;

    this.formModel = {};
    const { phoneNumber, personalInfo, businessInfo, addresses } = this.selectedEnhanceProfileDetails()?.enhanceProfileDetails ?? {};
    if (phoneNumber) {
      this.formModel.phoneNumber = phoneNumber[0];
    }
    const profileFields: string[] = personalInfo ?? businessInfo ?? [];
    if (profileFields.length) {
      const profileInfo = {} as Record<string, boolean>;
      profileFields.forEach((info: string) => {
        profileInfo[info] = true;
      });
      if (personalInfo) {
        this.formModel.personalInfo = profileInfo;
      } else {
        this.formModel.businessInfo = profileInfo;
      }
    }

    if (addresses) {
      const formAddresses = {} as Record<string, boolean>;
      addresses.forEach((address: string) => {
        formAddresses[address] = true;
      });
      this.formModel.addresses = formAddresses;
    }
  }

  onClickContinue(): void {
    const { documentTypes = {}, personalInfo, businessInfo, addresses, phoneNumber } = this.formModel;
    const profileDetails: LinxEnhanceProfileDetails = {};

    if (phoneNumber) {
      profileDetails.phoneNumber = [phoneNumber];
    }

    const personalInfoDetails = personalInfo ? Object.keys(personalInfo).filter((key) => personalInfo[key]) : [];
    if (personalInfoDetails.length) {
      const individualInfo: string[] = [];
      personalInfoDetails.forEach((item: string) => {
        if (item === 'email') {
          profileDetails.email = ['PRIMARY'];
        } else {
          individualInfo.push(item);
        }
      });
      if (individualInfo.length) {
        profileDetails.personalInfo = individualInfo;
      }
    }

    const businessDetails = businessInfo ? Object.keys(businessInfo).filter((key) => businessInfo[key]) : [];
    if (businessDetails.length) {
      const businessProfileInfo: string[] = [];
      businessDetails.forEach((item: string) => {
        if (item === 'contacts') {
          profileDetails.contacts = ['PRIMARY'];
        } else if (item === 'phoneNumber') {
          profileDetails.phoneNumber = ['WORK'];
        } else {
          businessProfileInfo.push(item);
        }
      });
      if (businessProfileInfo.length) {
        profileDetails.businessInfo = businessProfileInfo;
      }
    }

    const addressesDetails = addresses ? Object.keys(addresses).filter((key) => addresses[key]) : [];
    if (addressesDetails.length) {
      profileDetails.addresses = addressesDetails;
    }

    const enhanceDetails: LinxEnhanceAccountDetails = {
      accountType: this.getEnhanceAccountType(),
      enhanceProfileDetails: Object.keys(profileDetails).length ? profileDetails : undefined,
      documentTypes: Object.keys(documentTypes).filter((key) => documentTypes[key]),
    };

    this.continue.emit(enhanceDetails);
  }

  getEnhanceAccountType(): LinxEnhanceAccountType {
    if (this.entityType() === LinxEntityType.RECIPIENT) {
      return LinxEnhanceAccountType.Recipient;
    }
    return this.accountType() === LinxAccountType.BUSINESS ? LinxEnhanceAccountType.Business : LinxEnhanceAccountType.Individual;
  }

  onClearBtnClick(): void {
    this.profileForm.reset();
  }
}
