import { AsyncPipe, NgFor, NgIf, NgTemplateOutlet } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { isObject } from 'lodash-es';
import { NgScrollbar, NgScrollbarModule } from 'ngx-scrollbar';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { catchError, finalize, shareReplay, switchMap, takeUntil } from 'rxjs/operators';
import { RktIconComponent } from '@rocketfinancialcorp/rocket-ui/icon';
import { ActiveModal } from '@rocketfinancialcorp/rocket-ui/modal';

import { MESSAGE } from '@shared/constants';
import { AddNoteParams, Note } from '@shared/models';
import { NoteService } from '@shared/services';
import { ErrorUtils } from '@shared/utils';

import { AccessControlPipe } from '../../../pipes/access-control.pipe';
import { TextWithPlaceholderComponent } from '../../text-with-placeholder/text-with-placeholder.component';
import { AddNoteComponent } from '../add-note/add-note.component';
import { NoteItemComponent } from '../note-item/note-item.component';

@Component({
  selector: 'app-notes-modal',
  templateUrl: './notes-modal.component.html',
  styleUrls: ['./notes-modal.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    NgScrollbarModule,
    NgTemplateOutlet,
    NgFor,
    NoteItemComponent,
    AddNoteComponent,
    TextWithPlaceholderComponent,
    AsyncPipe,
    AccessControlPipe,
    RktIconComponent,
  ],
})
export class NotesModalComponent implements AfterViewInit {
  @ViewChild(NgScrollbar) scrollRef!: NgScrollbar;

  refetch$ = new BehaviorSubject('INITIAL');

  notes$!: Observable<Note[]>;

  entityId!: Note['entityId'];

  entityType!: Note['entityType'];

  loading = true;

  newNoteLoading = false;

  noteAddError = '';

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    public activeModal: ActiveModal,
    private noteService: NoteService,
  ) {}

  ngAfterViewInit(): void {
    this.notes$ = this.refetch$.pipe(
      switchMap(() =>
        this.noteService.getNotes({ entityType: this.entityType, entityId: this.entityId }).pipe(
          finalize(() => {
            this.loading = false;
            this.newNoteLoading = false;
          }),
          catchError((errorRes) => {
            ErrorUtils.catchError('noteService.getNotes', errorRes);
            return [];
          }),
          takeUntil(this.destroy$),
        ),
      ),
      shareReplay(),
    );
  }

  onNoteAdd({ contentText = '', attachments }: Partial<AddNoteParams>): void {
    this.noteAddError = '';
    this.newNoteLoading = true;
    this.noteService
      .addNote({
        entityId: this.entityId,
        entityType: this.entityType,
        contentText,
        attachments,
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.scrollRef?.scrollTo({ top: 0 });
          this.refetchNotes();
        },
        error: (errorRes) => {
          this.loading = false;
          this.newNoteLoading = false;
          this.handleAddNoteError(errorRes);
        },
      });
  }

  refetchNotes(): void {
    this.refetch$.next('REFETCH');
  }

  private handleAddNoteError(error?: string | number | HttpErrorResponse): void {
    this.noteAddError = (isObject(error) && error.status === 403) || error === 403 ? MESSAGE.PERMISSION_DENIED : MESSAGE.GENERIC_ERROR;
    ErrorUtils.catchError('noteService.addNote', this.noteAddError);
  }
}
