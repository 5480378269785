import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { EarnedWageProgram, ErrorMessage } from '@shared/models';

export const EwaProgramActions = createActionGroup({
  source: 'Ewa Program',
  events: {
    'Load Ewa Program': emptyProps,
    'Load Ewa Program Success': props<{ ewaProgram: EarnedWageProgram }>(),
    'Load Ewa Program Failure': props<{ error: ErrorMessage }>(),
  },
});
