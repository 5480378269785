import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';

import { DocumentService } from '@shared/services';
import { MessagesActions, selectRouteParams, selectUrl } from '@shared/store';
import { ErrorUtils } from '@shared/utils';

import { DocumentActions } from './document.actions';

@Injectable()
export class DocumentEffects {
  constructor(
    private router: Router,
    private actions$: Actions,
    private documentService: DocumentService,
    private store: Store,
  ) {}

  getDocument$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DocumentActions.loadDocument),
      concatLatestFrom(() => this.store.select(selectRouteParams)),
      mergeMap(([, routeParams]) => {
        return this.documentService.getDocumentById({ documentId: routeParams.id }).pipe(
          map((response) => DocumentActions.loadDocumentSuccess({ document: response })),
          catchError((error) => {
            return of(DocumentActions.loadDocumentError({ error }));
          }),
        );
      }),
    );
  });

  loadDocumentError$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DocumentActions.loadDocumentError),
      tap({
        next: (loadDocumentError) => {
          ErrorUtils.catchError('documentService.getDocumentById error', loadDocumentError.error);
        },
      }),
      switchMap(() => of(MessagesActions.displayError({ message: 'Unable to fetch Document.', title: 'Error' }))),
    );
  });

  loadDocumentErrorRedirect$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(DocumentActions.loadDocumentError),
        concatLatestFrom(() => this.store.select(selectUrl)),
        tap({
          next: ([, currentUrl]) => {
            const prevUrl = currentUrl.split('/').slice(0, -1).join('/');
            this.router.navigateByUrl(prevUrl);
          },
        }),
      );
    },
    { dispatch: false },
  );
}
