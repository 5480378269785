import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subject, tap } from 'rxjs';

import { TransactionFormActions, selectAmountOptionItems, transactionFormFeature } from '@shared/store';

import { TooltipComponent } from '../../tooltip/tooltip.component';

export interface AmountOption {
  value: 'EXACT_AMOUNT' | 'PERCENTAGE' | 'EXACT_AMOUNT_LEFT';
  label: string;
  tooltip?: string;
  isDisabled?: boolean;
}

@Component({
  selector: 'app-create-transaction-amount-options',
  templateUrl: './create-transaction-amount-options.component.html',
  standalone: true,
  imports: [NgFor, FormsModule, ReactiveFormsModule, NgIf, TooltipComponent, AsyncPipe],
})
export class CreateTransactionAmountOptionsComponent implements AfterViewChecked, OnDestroy {
  amountOptionField = new FormControl<AmountOption['value']>('EXACT_AMOUNT');

  amountOptionItems$ = this.store.select(selectAmountOptionItems).pipe(
    tap({
      next: (items) => {
        if (this.amountOptionField?.value === 'PERCENTAGE' && items.find((item) => item.value === 'PERCENTAGE')?.isDisabled) {
          this.amountOptionField.setValue('EXACT_AMOUNT');
          this.onAmountOptionSelect('EXACT_AMOUNT');
        }
      },
    }),
  );

  isDebitLegsDisabled$ = this.store.select(transactionFormFeature.selectIsDebitLegsDisabled);

  private destroy$ = new Subject<void>();

  constructor(
    private ref: ChangeDetectorRef,
    private store: Store,
  ) {}

  ngAfterViewChecked() {
    this.ref.detectChanges();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onAmountOptionSelect(amountOption: AmountOption['value']): void {
    this.store.dispatch(TransactionFormActions.setAmountOption({ amountOption }));
  }
}
