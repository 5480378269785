import { DecimalPipe, NgFor, NgIf } from '@angular/common';
import { Component, OnDestroy } from '@angular/core';
import { compact } from 'lodash-es';
import { NgxMaskPipe } from 'ngx-mask';
import { Subject, finalize, takeUntil } from 'rxjs';
import { RktButtonDirective } from '@rocketfinancialcorp/rocket-ui/button';
import { RktIconComponent } from '@rocketfinancialcorp/rocket-ui/icon';
import { ActiveModal } from '@rocketfinancialcorp/rocket-ui/modal';

import { LimitUpdateRequest, TransactionLimitItem } from '@shared/models';
import { NotificationService, TransactionLimitsService } from '@shared/services';
import { ErrorUtils } from '@shared/utils';

import { PrefixPipe } from '../../../pipes/prefix.pipe';
import { LimitEditItemFieldComponent } from '../../limits/limit-edit-item-field/limit-edit-item-field.component';

@Component({
  templateUrl: './transaction-limits-edit-form.component.html',
  styleUrls: ['./transaction-limits-edit-form.component.scss'],
  standalone: true,
  imports: [RktButtonDirective, NgFor, LimitEditItemFieldComponent, NgIf, DecimalPipe, PrefixPipe, RktIconComponent, NgxMaskPipe],
})
export class TransactionLimitsEditFormComponent implements OnDestroy {
  public financialAccountId!: string;

  public transactionLimits: TransactionLimitItem[] = [];

  public hasLimits = false;

  public submitError = '';

  public isLoading = false;

  public values: Record<string, number> = {
    single: 0,
    daily: 0,
    weekly: 0,
    monthly: 0,
  };

  public maxLimits: Record<string, number> = {
    single: 0,
    daily: 0,
    weekly: 0,
    monthly: 999999999,
  };

  public valueErrors: Record<string, boolean> = {
    single: false,
    daily: false,
    weekly: false,
    monthly: false,
  };

  private destroy$ = new Subject<void>();

  get isLimitsHasError(): boolean {
    return compact(this.transactionLimits.map((item) => this.valueErrors[item.type])).length > 0;
  }

  get isLimitsModified(): boolean {
    return compact(this.transactionLimits.map((item) => this.values[item.type] !== item.amount)).length > 0;
  }

  constructor(
    public activeModal: ActiveModal,
    private limitsService: TransactionLimitsService,
    private notificationService: NotificationService,
  ) {}

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  modalInitData({
    financialAccountId,
    transactionLimits,
  }: {
    financialAccountId: string;
    transactionLimits: TransactionLimitItem[];
  }): void {
    this.financialAccountId = financialAccountId;
    this.transactionLimits = transactionLimits;
    this.hasLimits = !!transactionLimits.length;

    this.transactionLimits.forEach((item) => {
      this.values[item.type] = item.amount;
    });

    this.mapMaxLimits();
    this.valueErrors = {
      single: false,
      daily: false,
      weekly: false,
      monthly: false,
    };
  }

  onSave(): void {
    const params: LimitUpdateRequest[] = [];
    this.transactionLimits.forEach((item) => {
      if (this.values[item.type] && item.amount !== this.values[item.type]) {
        params.push({
          id: item.id,
          amount: this.values[item.type],
        });
      }
    });
    if (this.financialAccountId && params.length) {
      this.isLoading = true;
      this.limitsService
        .updateTransactionLimits(params)
        .pipe(
          takeUntil(this.destroy$),
          finalize(() => {
            this.isLoading = false;
          }),
        )
        .subscribe({
          next: () => {
            this.activeModal.close();
            this.notificationService.displaySuccess('Transaction Limits updated successfully.');
          },
          error: (error) => {
            if (typeof error === 'string') {
              this.submitError = error;
            } else {
              this.submitError = 'Unable to Update Financial Account Transaction Limits.';
            }
            ErrorUtils.catchError('limitsService.updateTransactionLimits', error);
          },
        });
    }
  }

  setLimitAmountValue(value: string, type: string): void {
    if (this.submitError) {
      this.submitError = '';
    }

    this.values[type] = parseFloat(value);
    this.mapMaxLimits();
  }

  onLimitAmountValueError(isError: boolean, type: string): void {
    this.valueErrors[type] = isError;
  }

  mapMaxLimits(): void {
    Object.keys(this.values).forEach((type) => {
      switch (type) {
        case 'daily':
          this.maxLimits['single'] = this.values[type];
          break;
        case 'weekly':
          this.maxLimits['daily'] = this.values[type];
          break;
        case 'monthly':
          this.maxLimits['weekly'] = this.values[type];
          break;
        default:
          break;
      }
    });
  }
}
