import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject, combineLatest } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

import { AccountBalance } from '@shared/models';
import { fromAggregatedAccountBalance } from '@shared/store';

import { FinancialAccountBalancesComponent } from '../../financial-account/financial-account-balances/financial-account-balances.component';

export const CountryCodes: Record<string, string> = {
  USA: 'USD',
  AZ: 'AZN',
};

@Component({
  selector: 'app-business-account-balance',
  templateUrl: './business-account-balance.component.html',
  standalone: true,
  imports: [FinancialAccountBalancesComponent],
})
export class BusinessAccountBalanceComponent implements OnInit, OnDestroy {
  @Input() isFullWidth = false;

  @Input() noBottomOffset = false;

  isLoading = false;

  accountBalanceValues!: AccountBalance | undefined;

  selectedCurrency!: string | undefined;

  private destroy$ = new Subject<void>();

  constructor(private store: Store) {}

  ngOnInit() {
    combineLatest([
      this.store.select(fromAggregatedAccountBalance.selectAggregatedBalanceForSelectedCurrency),
      this.store.select(fromAggregatedAccountBalance.selectActiveAggregatedAccountBalanceCurrency),
    ])
      .pipe(
        finalize(() => (this.isLoading = false)),
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: ([aggregateBalances, selectedActiveCurrency]) => {
          this.accountBalanceValues = aggregateBalances;
          this.selectedCurrency = selectedActiveCurrency;
        },
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
