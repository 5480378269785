import { NgFor, NgIf } from '@angular/common';
import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { RktButtonDirective } from '@rocketfinancialcorp/rocket-ui/button';
import { RktIconComponent } from '@rocketfinancialcorp/rocket-ui/icon';
import { ModalService } from '@rocketfinancialcorp/rocket-ui/modal';

import { AttachmentCreateFormComponent } from '@shared/components/attachments';
import { AddNoteParams, AttachmentCreateModel } from '@shared/models';
import { emptySpacesValidator } from '@shared/validators';

import { DragDropAttachmentDirective } from '../../../directives/drag-drop-attachment.directive';

@Component({
  selector: 'app-add-note',
  templateUrl: './add-note.component.html',
  styleUrls: ['./add-note.component.scss'],
  standalone: true,
  imports: [
    RktButtonDirective,
    DragDropAttachmentDirective,
    FormsModule,
    ReactiveFormsModule,
    NgIf,
    NgScrollbarModule,
    NgFor,
    RktIconComponent,
  ],
})
export class AddNoteComponent implements OnInit, OnChanges, AfterViewChecked {
  @ViewChild('noteField') noteField!: ElementRef;

  @Input() isDisabled?: boolean;

  @Input() notesLoading?: boolean;

  @Input() error?: string;

  @Output() addBtnClick = new EventEmitter<Partial<AddNoteParams>>();

  displayError = false;

  loading = false;

  newNote = new UntypedFormControl('', [emptySpacesValidator]);

  attachmentsData: Partial<AttachmentCreateModel>[] = [];

  get isShowSpinner() {
    return this.notesLoading ?? this.loading;
  }

  constructor(
    public ref: ChangeDetectorRef,
    private modalService: ModalService,
  ) {}

  ngOnInit(): void {
    this.newNote.valueChanges.subscribe({
      next: () => {
        if (this.displayError) {
          this.displayError = false;
        }
      },
    });
    this.newNote.disable();
  }

  ngAfterViewChecked(): void {
    this.ref.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.notesLoading = changes.notesLoading?.currentValue;
    if (this.newNote.disabled && !changes.isDisabled?.currentValue) {
      this.newNote.enable();
      setTimeout(() => this.noteField.nativeElement.focus(), 100);
    }

    if (this.loading && !changes.notesLoading?.currentValue) {
      this.newNote.enable();
      this.loading = false;
      if (!changes.error) {
        this.newNote.setValue('');
        this.attachmentsData = [];
      }
    }

    if (changes.error?.currentValue) {
      this.displayError = true;
    }
  }

  onAddNoteCancel(): void {
    this.newNote.setValue('');
  }

  onAddNoteSave(): void {
    this.loading = true;
    this.newNote.disable();

    this.addBtnClick.emit({
      contentText: this.newNote.value,
      attachments: this.attachmentsData.length ? this.attachmentsData : undefined,
    });
  }

  onNoteFieldBlur(): void {
    this.newNote.setValue(this.newNote.value.trim());
  }

  onAddAttachments(): void {
    const attachmentCreateFormModalRef = this.modalService.open(AttachmentCreateFormComponent, {
      className: 'entity-form-modal',
    });

    attachmentCreateFormModalRef.result.then(
      (result) => {
        if (result) {
          this.attachmentsData.push(result);
        }
      },
      () => false,
    );
  }

  onDeleteFileBtnClick(i: number): void {
    this.attachmentsData.splice(i, 1);
  }

  onEditFileBtnClick(i: number): void {
    const attachmentEditFormModalRef = this.modalService.open(AttachmentCreateFormComponent, {
      className: 'entity-form-modal',
    });
    attachmentEditFormModalRef.componentInstance.isEditMode = true;
    attachmentEditFormModalRef.componentInstance.formModel = {
      name: this.attachmentsData[i].name,
      description: this.attachmentsData[i].description,
    };

    attachmentEditFormModalRef.result.then(
      (result) => {
        if (result) {
          this.attachmentsData[i].description = result.description;
          this.attachmentsData[i].name = result.name;
        }
      },
      () => false,
    );
  }

  onAttachmentsDropped(attachments: Partial<AttachmentCreateModel>[]): void {
    this.attachmentsData.push(...attachments);
  }
}
