import { createAction, props } from '@ngrx/store';

import { AccountBalance, ErrorMessage, FinancialAccountBalances } from '@shared/models';

export namespace AggregatedAccountBalanceActions {
  export const loadAggregatedAccountBalance = createAction('[FA API] Load Business Account Aggregated Balances');

  export const loadAggregatedAccountBalanceSuccess = createAction(
    '[FA API] Load Business Account Aggregated Balance Success',
    props<{ financialAccountsBalances: FinancialAccountBalances }>(),
  );

  export const loadAggregatedAccountBalanceFailure = createAction(
    '[FA API] Load Business Account Aggregated Balance Failure',
    props<{ error: ErrorMessage }>(),
  );

  export const initialize = createAction('[Aggregated Currency Widget] Initialize');

  export const setCurrency = createAction('[Active Aggregated Account Currency] Set', props<{ currency: string }>());

  export const setAggregateAccountBalance = createAction(
    '[Active Aggregated Account Balance] Set',
    props<{ aggregateAccountBalance: AccountBalance }>(),
  );
}
