<app-collapsible-row
  iconName="move-money"
  class="move-how-row"
  label="Move Money"
  [isRowExpanded]="isRowExpanded()"
  [isRowHasValue]="isRowHasValue()"
>
  @if (isRowHasValue() && !isRowExpanded()) {
    <div class="registry-form-preview">
      <div class="preview-content">
        <div class="preview-content-item">
          [{{ moveMoneyDetails()?.selectedMoveItem === 'push' ? 'SEND' : 'REQUEST' }}] via [{{
            moveMoneyDetails()?.selectedMoveHowType | solutionName
          }}]
        </div>
        <div class="preview-content-item">
          @if (!isTemplate()) {
            [{{ moveMoneyDetails()?.moveAmountValue }}] of
          }
          [{{ moveMoneyDetails()?.paymentReasonName }}]
        </div>
      </div>

      <button class="preview-edit" [disabled]="isEditDisabled()" (click)="expandRow.emit()">
        <rkt-icon name="edit"></rkt-icon>
      </button>
    </div>
  }
  <app-linx-move-money-edit
    [hidden]="!isRowExpanded()"
    [isTemplate]="isTemplate()"
    [financialAccountType]="financialAccountType()"
    (cleared)="cleared.emit()"
    (continue)="continue.emit($event)"
    [customerId]="customerId()"
  />
</app-collapsible-row>
