import { AsyncPipe, NgIf } from '@angular/common';
import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, OnInit, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';
import { RktButtonDirective } from '@rocketfinancialcorp/rocket-ui/button';
import { ActiveModal } from '@rocketfinancialcorp/rocket-ui/modal';

import { FinancialAccountSelectionListItem } from '@shared/models';
import {
  TransactionFormAction,
  selectIsCreateTransactionDisabled,
  selectToAccountForWireWithoutAddress,
  transactionFormFeature,
} from '@shared/store';

import { CreateTransactionAmountComponent } from '../create-transaction-amount/create-transaction-amount.component';
import { CreateTransactionAmountOptionsComponent } from '../create-transaction-amount-options/create-transaction-amount-options.component';
import { CreateTransactionDeliverySpeedComponent } from '../create-transaction-delivery-speed/create-transaction-delivery-speed.component';
import { CreateTransactionFinancialAccountComponent } from '../create-transaction-financial-account/create-transaction-financial-account.component';
import { CreateTransactionNoteComponent } from '../create-transaction-note/create-transaction-note.component';
import { EditTransactionSchedulerComponent } from '../edit-transaction-scheduler/edit-transaction-scheduler.component';

@Component({
  selector: 'app-multi-leg-transaction-edit-modal',
  templateUrl: './multi-leg-transaction-edit-modal.component.html',
  standalone: true,
  imports: [
    RktButtonDirective,
    NgIf,
    CreateTransactionAmountComponent,
    CreateTransactionAmountOptionsComponent,
    CreateTransactionFinancialAccountComponent,
    CreateTransactionDeliverySpeedComponent,
    EditTransactionSchedulerComponent,
    CreateTransactionNoteComponent,
    AsyncPipe,
  ],
})
export class MultiLegTransactionEditModalComponent implements OnInit, AfterViewChecked, OnDestroy {
  public readonly activeModal = inject(ActiveModal);

  private readonly store = inject(Store);

  private readonly ref = inject(ChangeDetectorRef);

  private destroy$ = new Subject<void>();

  isFormLoading$ = this.store.select(transactionFormFeature.selectIsFormLoading);

  isUpdateMultiLegTransactionDisabled$ = this.store.select(selectIsCreateTransactionDisabled);

  deliverySpeedOptions$ = this.store.select(transactionFormFeature.selectDeliverySpeedOptions);

  formAction$ = this.store.select(transactionFormFeature.selectFormAction);

  financialAccountForWireWithoutAddress?: FinancialAccountSelectionListItem | null;

  schedulerEditData$ = this.store.select(transactionFormFeature.selectScheduler);

  ngOnInit() {
    this.store
      .select(selectToAccountForWireWithoutAddress)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (financialAccount) => (this.financialAccountForWireWithoutAddress = financialAccount),
      });
  }

  ngAfterViewChecked() {
    this.ref.detectChanges();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onContinueBtnClick({ formAction }: { formAction: TransactionFormAction }): void {
    if (formAction === 'EDIT_SCHEDULED_SLT') {
      this.activeModal.close('EDIT_SCHEDULED_SLT_CONFIRM');
    } else if (formAction === 'EDIT_SCHEDULED_MLT') {
      this.activeModal.close('EDIT_SCHEDULED_MLT_CONFIRM');
    } else {
      this.activeModal.close('REVIEW');
    }
  }
}
