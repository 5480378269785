import { NgFor } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RktButtonDirective } from '@rocketfinancialcorp/rocket-ui/button';
import { RktIconComponent } from '@rocketfinancialcorp/rocket-ui/icon';
import { ActiveModal } from '@rocketfinancialcorp/rocket-ui/modal';

import { Address } from '@shared/models';

import { DetailsItemComponent } from '../../details-item/details-item.component';

@Component({
  selector: 'app-address-select-modal',
  templateUrl: 'address-select-modal.component.html',
  styleUrls: ['address-select-modal.component.scss'],
  standalone: true,
  imports: [RktButtonDirective, NgFor, FormsModule, ReactiveFormsModule, DetailsItemComponent, RktIconComponent],
})
export class AddressSelectModalComponent {
  @Input() addresses: Address[] = [];

  selectedAddress = new FormControl<Address | null>(null);

  constructor(public activeModal: ActiveModal) {}

  public modalInitData(addresses: Address[]) {
    this.addresses = addresses.map((address, i) => {
      address.id = `${address.id ?? i}`;
      return address;
    });
    this.selectedAddress.setValue(this.addresses[0]);
  }

  public onAddressSelect(address: Address) {
    if (address) {
      this.selectedAddress.setValue(address);
    }
  }

  public getAddressLabel(index: number) {
    return `Address ${index + 2}`;
  }
}
