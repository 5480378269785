<h1 class="title">Enter Address For Selected Account</h1>

<button class="close-button" (click)="activeModal.close()"><rkt-icon name="times"></rkt-icon></button>

<div class="description">Wire transfer requires an address to proceed. Please enter the address below.</div>

<div class="account-preview">
  <div class="preview-content" *ngIf="financialAccount">
    <div class="preview-content-item">
      <span class="preview-label"> {{ financialAccount.accountHolderInfo | rktSplit: '::' : 1 | notAvailable: '--' }}:&nbsp; </span>

      {{ financialAccount.accountHolderInfo | rktSplit: '::' : 0 | notAvailable: '--' }}
    </div>

    <div class="preview-content-item">
      <rkt-icon name="bank"></rkt-icon>
      {{ financialAccount.accountInfo.replace('::', ' ') }}
    </div>

    <div class="preview-content preview-content-account-info">
      <div class="preview-content-item">{{ financialAccount.email }}</div>

      <div class="preview-content-item">{{ financialAccount.phone }}</div>
    </div>
  </div>
</div>

<form [formGroup]="addressForm">
  <rkt-form [form]="addressForm" [fields]="addressFormFields" [model]="addressFormModel" (modelChange)="onAddressChange()"></rkt-form>

  <div *ngIf="submitError" class="details-page-edit-form-error">{{ submitError }}</div>

  <div class="buttons">
    <button rktButton type="button" btnType="tertiary" (click)="activeModal.close()">Cancel</button>
    <button rktButton type="submit" (click)="onSave()" [disabled]="isLoading || addressForm.invalid">Save</button>
  </div>
</form>
