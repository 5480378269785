import { ChangeDetectionStrategy, Component, computed, inject, input, output, signal } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { RktFormComponent } from '@rocketfinancialcorp/rocket-ui/form';
import { ModalService } from '@rocketfinancialcorp/rocket-ui/modal';

import { MoveHowSelection, MoveMoneyConfirmChangesModalComponent } from '@shared/components';
import { FinancialAccountListItem, FormModel, TransactionDirection, TransactionSolution } from '@shared/models';

import { moveHowDirectionFields, moveHowTypeFields } from './form-fields';

interface MoveHowChange {
  moveItem?: TransactionDirection;
  moveHowType?: TransactionSolution;
}

@Component({
  selector: 'app-linx-move-how-selection',
  templateUrl: './linx-move-how-selection.component.html',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, RktFormComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinxMoveHowSelectionComponent {
  modalService = inject(ModalService);

  customerFinancialAccountType = input<FinancialAccountListItem['type'] | undefined>();

  selected = output<MoveHowSelection>();

  cleared = output<void>();

  selectedMoveItem = signal<TransactionDirection | undefined>(undefined);

  selectedMoveHowType = signal<TransactionSolution | undefined>(undefined);

  moveHowDirectionForm = new FormGroup({});

  moveHowTypeForm = new FormGroup({});

  formModel: FormModel<MoveHowSelection> = {};

  directionTypes: TransactionDirection[] = ['push', 'pull'];

  directionDisabledTypes = computed((): TransactionDirection[] => {
    if (!this.customerFinancialAccountType()) {
      return this.directionTypes;
    }

    const disabledTypes: TransactionDirection[] = [];

    if (this.customerFinancialAccountType() === 'CARD') {
      disabledTypes.push('pull');
    }

    return disabledTypes;
  });

  solutionTypes: TransactionSolution[] = ['ach', 'push-to-card', 'wire'];

  solutionDisabledTypes = computed((): TransactionSolution[] => {
    if (!this.customerFinancialAccountType()) {
      return this.solutionTypes;
    }

    const disabledTypes = this.solutionTypes.filter((type) => {
      const isCardAccount = this.customerFinancialAccountType() === 'CARD';

      if (isCardAccount && type === 'push-to-card') {
        return false;
      }

      const isPushOnlyType = ['ach', 'wire'].includes(type);
      const isPush = this.selectedMoveItem() === 'push' && isPushOnlyType;
      const isPull = this.selectedMoveItem() === 'pull' && type === 'ach';

      if (!isCardAccount && (isPush || isPull)) {
        return false;
      }

      return true;
    });

    return disabledTypes;
  });

  moveHowDirections = computed(() => {
    const directions = [
      { label: 'Send', value: 'push', disabled: false },
      { label: 'Request', value: 'pull', disabled: false },
    ];
    directions.forEach((direction) => {
      direction.disabled = this.directionDisabledTypes().includes(direction.value as TransactionDirection);
    });
    return directions;
  });

  moveHowTypes = computed(() => {
    const types = [
      { label: 'ACH', value: 'ach', disabled: false },
      { label: 'Push To Card', value: 'push-to-card', disabled: false },
      { label: 'Wire', value: 'wire', disabled: false },
    ];
    types.forEach((solution) => {
      solution.disabled = this.solutionDisabledTypes().includes(solution.value as TransactionSolution);
    });
    return types;
  });

  moveHowDirectionFields = computed(() => {
    return moveHowDirectionFields({
      moveHowFieldsChangeHook: (field: FormlyFieldConfig) => this.moveHowDirectionFieldsChangeHook(field),
      options: this.moveHowDirections(),
    });
  });

  moveHowTypeFields = computed(() => {
    return moveHowTypeFields({
      moveHowFieldsChangeHook: (field: FormlyFieldConfig) => this.moveHowSolutionFieldsChangeHook(field),
      options: this.moveHowTypes(),
    });
  });

  moveHowDirectionFieldsChangeHook(field: FormlyFieldConfig): void {
    const moveItem = field.formControl?.value;
    this.selectMove(moveItem, this.selectedMoveHowType(), { moveItem });
  }

  moveHowSolutionFieldsChangeHook(field: FormlyFieldConfig): void {
    const moveHowType = field.formControl?.value;
    this.selectMove(this.selectedMoveItem(), moveHowType, { moveHowType });
  }

  selectMove(moveItem: TransactionDirection | undefined, moveHowType: TransactionSolution | undefined, params: MoveHowChange): void {
    if (!this.selectedMoveItem() || !this.selectedMoveHowType()) {
      this.selectedMoveItem.set(params.moveItem ?? moveItem);
      this.selectedMoveHowType.set(params.moveHowType ?? moveHowType);

      this.onMoveHowSelect();
      return;
    }

    const confirmModalRef = this.modalService.open(MoveMoneyConfirmChangesModalComponent, { className: 'confirm-modal' });

    confirmModalRef.result.then(
      (result) => {
        if (result) {
          this.selectedMoveItem.set(moveItem);
          this.selectedMoveHowType.set(moveHowType);
          this.onMoveHowChange(params);
        }
      },
      () => false,
    );
  }

  onMoveHowChange({ moveItem, moveHowType }: MoveHowChange) {
    if (!moveItem && !moveHowType) {
      return;
    }

    this.cleared.emit();

    if (!moveHowType) {
      this.selectedMoveHowType.set(undefined);
      this.moveHowTypeForm.reset();
    }
    this.onMoveHowSelect();
  }

  onMoveHowSelect() {
    this.selected.emit({
      moveDirection: this.selectedMoveItem(),
      moveHowType: this.selectedMoveHowType(),
    });
  }

  resetValue(): void {
    this.selectedMoveItem.set(undefined);
    this.selectedMoveHowType.set(undefined);

    this.moveHowDirectionForm.reset();

    this.moveHowTypeForm.reset();
  }
}
