import { CurrencyPipe, NgIf, TitleCasePipe } from '@angular/common';
import { Component } from '@angular/core';
import { RktButtonDirective } from '@rocketfinancialcorp/rocket-ui/button';
import { RktIconComponent } from '@rocketfinancialcorp/rocket-ui/icon';
import { ActiveModal } from '@rocketfinancialcorp/rocket-ui/modal';

import { ModalReviewData } from '@shared/models';

import { DateFormatPipe } from '../../../../shared/pipes/date-format.pipe';

@Component({
  templateUrl: './move-money-review.component.html',
  styleUrls: ['./move-money-review.component.scss'],
  standalone: true,
  imports: [RktButtonDirective, NgIf, RktIconComponent, TitleCasePipe, CurrencyPipe, DateFormatPipe],
})
export class MoveMoneyReviewComponent {
  reviewData?: ModalReviewData;

  get isNextDay(): boolean {
    const { moveHowAchDetails, moveHowWireDetails } = this.reviewData ?? {};
    return moveHowAchDetails?.moveAchType === 'NEXT_DAY' || moveHowWireDetails?.moveWireType === 'NEXT_DAY';
  }

  constructor(public activeModal: ActiveModal) {}

  modalInitData(data: ModalReviewData) {
    this.reviewData = data;
  }
}
