import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { RktButtonDirective } from '@rocketfinancialcorp/rocket-ui/button';
import { RktFormComponent } from '@rocketfinancialcorp/rocket-ui/form';

import { identityProfileOptions } from '@shared/components/identity-verification';
import { LinxAccountType } from '@shared/constants';
import { FormModel, IdentityVerificationProfile, SubmitIdentityVerificationApplicationParams } from '@shared/models';
import { IdentityVerificationService } from '@shared/services';
import { ErrorUtils } from '@shared/utils';

@Component({
  selector: 'app-linx-identity-verification',
  templateUrl: './linx-identity-verification.component.html',
  standalone: true,
  imports: [RktButtonDirective, FormsModule, ReactiveFormsModule, RktFormComponent],
})
export class LinxIdentityVerificationComponent implements OnInit, OnDestroy {
  @Input() accountType?: LinxAccountType;
  @Input() selectedIdentityVerificationDetails?: IdentityVerificationProfile;

  @Output() continue = new EventEmitter<IdentityVerificationProfile>();

  formFields = identityProfileOptions;

  formModel: FormModel<SubmitIdentityVerificationApplicationParams> = {
    identityVerificationProfileId: undefined,
  };

  identityVerificationForm = new FormGroup({});

  idvProfileOptions: IdentityVerificationProfile[] = [];

  get idvCustomerType(): string {
    return this.accountType === LinxAccountType.BUSINESS ? 'BUSINESS_CUSTOMER' : 'INDIVIDUAL_CUSTOMER';
  }

  private destroy$ = new Subject<void>();

  constructor(private identityVerificationService: IdentityVerificationService) {}

  ngOnInit(): void {
    this.initFormData();
    if (this.selectedIdentityVerificationDetails) {
      this.formModel = {
        identityVerificationProfileId: this.selectedIdentityVerificationDetails.id,
      };
    }
  }

  public ngOnDestroy(): void {
    this.identityVerificationForm.markAsUntouched();
    this.destroy$.next();
    this.destroy$.complete();
  }

  private initFormData(): void {
    this.identityVerificationService
      .getIdentityVerificationProfiles()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (profileOptions) => {
          this.idvProfileOptions = this.idvCustomerType
            ? profileOptions.filter((profile) => profile.allowedAccountTypes.includes(this.idvCustomerType))
            : profileOptions;

          if (this.formFields[0]?.fieldGroup[0]?.props) {
            this.formFields[0].fieldGroup[0].props.options = this.idvProfileOptions;
          }
          if (this.selectedIdentityVerificationDetails) {
            this.onSubmit();
          }
        },
        error: (error) => {
          ErrorUtils.catchError('identityVerificationService.getIdentityVerificationProfiles error', error);
        },
      });
  }

  onSubmit() {
    const idvProfile = this.idvProfileOptions.find((idv) => idv.id === this.formModel.identityVerificationProfileId);
    this.continue.emit(idvProfile);
  }

  onClearBtnClick(): void {
    this.identityVerificationForm.reset();
  }
}
