<div class="registry-form-preview">
  <div class="preview-content">
    <div class="preview-content-item enhance-profile">
      @if (enhanceProfileDisplayData().length) {
        <rkt-icon name="user"></rkt-icon>
        @for (item of enhanceProfileDisplayData(); track $index) {
          <div class="enhance-profile-details-item">
            <div class="profile-dot"></div>
            <div>{{ item }}</div>
          </div>
        }
      }
      @if (enhanceAccountDetails()?.documentTypes?.length) {
        @for (item of enhanceAccountDetails()?.documentTypes; track $index) {
          <div class="enhance-profile-details-item">
            @if ($index === 0) {
              <rkt-icon name="document"></rkt-icon>
            }
            <div class="profile-dot"></div>
            <div>{{ item.replace('-', ' ') | titlecase }}</div>
          </div>
        }
      }
    </div>
  </div>

  <button class="preview-edit" [disabled]="isEditDisabled()" (click)="expandRow.emit()">
    <rkt-icon name="edit"></rkt-icon>
  </button>
</div>
