import { AsyncPipe, NgClass, TitleCasePipe } from '@angular/common';
import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router, RouterLink, RouterOutlet } from '@angular/router';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { capitalize, compact, keys } from 'lodash-es';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { RktIconComponent } from '@rocketfinancialcorp/rocket-ui/icon';

import { BreadcrumbsComponent, TabsComponent } from '@shared/components';
import { AccountBalance, Breadcrumb, FinancialAccountDetails, FinancialAccountHolderType } from '@shared/models';
import {
  FinancialAccountDetailsActions,
  selectFinancialAccountDetails,
  selectFinancialAccountDetailsTitle,
  selectIsFinancialAccountExternal,
  selectIsFinancialAccountIntegratedCard,
  selectRouteParams,
  selectUrl,
} from '@shared/store';

import { TransactionLimitsComponent } from '../../limits/transaction-limits/transaction-limits.component';
import { DetailsPageHeaderPlaceholderComponent } from '../../loading-placeholders/details-page-header-placeholder/details-page-header-placeholder.component';
import { FinancialAccountActionsComponent } from '../financial-account-actions/financial-account-actions.component';
import { FinancialAccountBalancesComponent } from '../financial-account-balances/financial-account-balances.component';

@Component({
  selector: 'app-financial-account-page',
  templateUrl: 'financial-account-page.component.html',
  styleUrls: ['financial-account-page.component.scss'],
  standalone: true,
  imports: [
    RouterLink,
    NgClass,
    BreadcrumbsComponent,
    FinancialAccountActionsComponent,
    DetailsPageHeaderPlaceholderComponent,
    FinancialAccountBalancesComponent,
    TransactionLimitsComponent,
    TabsComponent,
    RouterOutlet,
    TitleCasePipe,
    RktIconComponent,
    AsyncPipe,
  ],
})
export class FinancialAccountPageComponent implements OnInit, AfterViewChecked, OnDestroy {
  store = inject(Store);

  financialAccount: FinancialAccountDetails | null = null;

  financialAccountDetails$ = this.store.select(selectFinancialAccountDetails).pipe(
    tap({
      next: (financialAccount) => {
        if (!financialAccount) {
          return;
        }
        const { category, embeddedAccountBalance, currency } = financialAccount;
        keys(embeddedAccountBalance).forEach((key) => {
          if (!this.currencyList.includes(key)) {
            this.financialAccountCurrencyList.push({ label: key, value: key });
            this.currencyList = [...this.currencyList, key];
          }
        });

        this.accountBalanceList = embeddedAccountBalance ?? {};
        this.selectedCurrency = currency ?? 'USD';
        if (embeddedAccountBalance) {
          this.accountBalanceValues = embeddedAccountBalance[this.selectedCurrency];
        }

        if (this.entityType === 'business-account' && !this.isEscrowAccount) {
          this.tabItems = compact([
            { label: 'Account Information', href: 'account-details' },
            ['INTERNAL', 'INTEGRATED'].includes(category) ? { label: 'Transaction Activity', href: 'account-activity' } : undefined,
            category === 'EXTERNAL' ? { label: 'Transaction Activity', href: 'external-account-activity' } : undefined,
            category === 'INTERNAL' ? { label: 'Child Accounts', href: 'child-accounts' } : undefined,
            category === 'INTEGRATED' ? { label: 'Statements', href: 'account-statements' } : undefined,
          ]);
        } else if (this.entityType === 'business-account' && this.isEscrowAccount) {
          this.tabItems = [{ label: 'Account Information', href: 'escrow-account-details' }];
        } else if (['INTERNAL', 'INTEGRATED'].includes(category)) {
          this.tabItems = compact([
            { label: 'Account Information', href: 'account-details' },
            { label: 'Transaction Activity', href: 'account-activity' },
            category === 'INTEGRATED' ? { label: 'Statements', href: 'account-statements' } : undefined,
          ]);
        } else if (category === 'EXTERNAL') {
          this.tabItems = compact([
            { label: 'Account Information', href: 'account-details' },
            { label: 'Transaction Activity', href: 'external-account-activity' },
          ]);
        }
      },
    }),
  );

  selectFinancialAccountTitle$ = this.store.select(selectFinancialAccountDetailsTitle);

  selectIsFinancialAccountExternal$ = this.store.select(selectIsFinancialAccountExternal);

  selectIsFinancialAccountIntegratedCard$ = this.store.select(selectIsFinancialAccountIntegratedCard);

  entityFinancialAccountId!: string;

  entityId?: string | null;

  isFinancialAccountLoading = false;

  entityBreadcrumb?: string;

  entityBreadcrumbURL?: string;

  entitySubBreadcrumb?: string;

  entitySubBreadcrumbURL?: string;

  parentEntityBreadcrumb?: string;

  parentEntityBreadcrumbURL?: string;

  parentEntitySubBreadcrumb?: string;

  parentEntitySubBreadcrumbURL?: string;

  pageReturnURL!: string;

  entityType?: FinancialAccountHolderType;

  financialAccountCurrencyList: { label: string; value: string }[] = [];

  currencyList: string[] = [];

  selectedCurrency!: string;

  accountBalanceValues!: AccountBalance;

  accountBalanceList!: Record<string, AccountBalance>;

  transactionId?: string | null;

  customerId?: string | null;

  customerType?: 'business' | 'individual';

  tabItems: { label: string; href: string }[] = [{ label: 'Account Information', href: 'account-details' }];

  private destroy$ = new Subject<void>();

  get isFromTransaction() {
    const snapshot = this.activatedRoute.snapshot;
    return !!snapshot.paramMap.get('transactionId');
  }

  get breadcrumbList(): Breadcrumb[] {
    if (this.isFromTransaction) {
      return [
        {
          label: 'Transaction Activity',
          url: '/app/transactions/transactions',
        },
        {
          label: 'Transaction Details',
          url: `/app/transactions/transactions/${this.transactionId}`,
        },
        { label: 'Account Details' },
      ];
    }
    return compact([
      this.parentEntityBreadcrumb ? { label: this.parentEntityBreadcrumb, url: this.parentEntityBreadcrumbURL } : null,
      this.parentEntitySubBreadcrumb ? { label: this.parentEntitySubBreadcrumb, url: this.parentEntitySubBreadcrumbURL } : null,
      this.entityBreadcrumb ? { label: this.entityBreadcrumb, url: this.entityBreadcrumbURL } : null,
      this.entitySubBreadcrumb ? { label: this.entitySubBreadcrumb, url: this.entitySubBreadcrumbURL } : null,
      { label: 'Financial Accounts', url: this.pageReturnURL },
      { label: 'Account Details' },
    ]);
  }

  get isEscrowAccount(): boolean {
    return this.router.url.split('/').includes('escrow-account-details');
  }

  constructor(
    public ref: ChangeDetectorRef,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    this.getRouteParams();
    this.store
      .select(selectRouteParams)
      .pipe(concatLatestFrom(() => this.store.select(selectUrl)))
      .subscribe({
        next: ([, currentUrl]) => {
          if (!this.financialAccount && currentUrl?.split('/').slice(-1)[0] === 'account-activity') {
            this.tabItems = [
              { label: 'Account Information', href: 'account-details' },
              { label: 'Transaction Activity', href: 'account-activity' },
            ];
          } else if (!this.financialAccount && currentUrl?.split('/').slice(-1)[0] === 'child-accounts') {
            this.tabItems = [
              { label: 'Account Information', href: 'account-details' },
              { label: 'Transaction Activity', href: 'account-activity' },
              { label: 'Child Accounts', href: 'child-accounts' },
            ];
          } else if (!this.financialAccount && currentUrl?.split('/').slice(-1)[0] === 'external-account-activity') {
            this.tabItems = [
              { label: 'Account Information', href: 'account-details' },
              { label: 'Transaction Activity', href: 'external-account-activity' },
            ];
          } else if (!this.financialAccount && currentUrl?.split('/').slice(-1)[0] === 'escrow-account-details') {
            this.tabItems = [{ label: 'Account Information', href: 'escrow-account-details' }];
          }
        },
      });
  }

  ngOnInit(): void {
    this.store.dispatch(FinancialAccountDetailsActions.loadFinancialAccountDetails());
  }

  ngAfterViewChecked(): void {
    this.ref.detectChanges();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getRouteParams(): void {
    const { snapshot } = this.activatedRoute;
    this.transactionId = snapshot.paramMap.get('transactionId');
    this.customerId = snapshot.paramMap.get('customerId');
    this.customerType = snapshot.data['customerType'];
    this.entityFinancialAccountId = snapshot.paramMap.get('id')!;
    this.entityId = snapshot.paramMap.get('entityId')!;
    this.entityType = snapshot.data['accountHolderType'];

    this.mapEntityAccountDetails();
  }

  mapEntityAccountDetails(): void {
    switch (this.entityType) {
      case 'individuals':
        this.entityBreadcrumb = 'Individuals';
        this.entitySubBreadcrumb = 'Individual details';
        this.entityBreadcrumbURL = '/app/customers/individuals';
        this.entitySubBreadcrumbURL = `/app/customers/individuals/${this.entityId}`;
        this.pageReturnURL = `/app/customers/individuals/${this.entityId}/financial-accounts`;
        break;

      case 'businesses':
        this.entityBreadcrumb = 'Businesses';
        this.entitySubBreadcrumb = 'Business details';
        this.entityBreadcrumbURL = '/app/customers/businesses';
        this.entitySubBreadcrumbURL = `/app/customers/businesses/${this.entityId}`;
        this.pageReturnURL = `/app/customers/businesses/${this.entityId}/financial-accounts`;
        break;

      case 'recipients':
        if (this.customerId) {
          const customerUrl = `/app/customers/${this.customerType === 'individual' ? 'individuals' : 'businesses'}`;
          this.parentEntityBreadcrumb = this.customerType === 'individual' ? 'Individuals' : 'Businesses';
          this.parentEntitySubBreadcrumb = `${capitalize(this.customerType)} details`;
          this.parentEntityBreadcrumbURL = customerUrl;
          this.parentEntitySubBreadcrumbURL = `${customerUrl}/${this.customerId}`;
          this.entityBreadcrumb = 'Recipients';
          this.entitySubBreadcrumb = 'Recipient details';
          this.entityBreadcrumbURL = `${customerUrl}/${this.customerId}/recipients`;
          this.entitySubBreadcrumbURL = `${customerUrl}/${this.customerId}/recipients/${this.entityId}`;
          this.pageReturnURL = `${customerUrl}/${this.customerId}/recipients/${this.entityId}/financial-accounts`;
        } else {
          this.entityBreadcrumb = 'Recipients';
          this.entitySubBreadcrumb = 'Recipient details';
          this.entityBreadcrumbURL = '/app/recipients';
          this.entitySubBreadcrumbURL = `/app/recipients/${this.entityId}`;
          this.pageReturnURL = `/app/recipients/${this.entityId}/financial-accounts`;
        }
        break;

      case 'business-account':
      default:
        this.pageReturnURL = '/app/settings/financial-accounts';
        break;
    }
  }

  setAccountCurrency(currency: string) {
    this.accountBalanceValues = this.accountBalanceList[currency];
  }
}
