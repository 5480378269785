import { createReducer, on } from '@ngrx/store';

import { EnabledSolutionConfig, EnabledSolutionPermissions } from '@shared/models';

import { TransactionSolutionActions } from './transaction-solution.actions';

export const transactionFeatureKey = 'transactionSolution';

export interface TransactionSolutionState {
  enabledSolutions: EnabledSolutionPermissions | null;
  enabledSolutionConfigs: EnabledSolutionConfig[] | null;
  error: string | null;
}

export const initialState: TransactionSolutionState = {
  enabledSolutions: null,
  enabledSolutionConfigs: [],
  error: null,
};

export const reducer = createReducer(
  initialState,
  on(TransactionSolutionActions.loadEnabledSolutions, (state): TransactionSolutionState => {
    return {
      ...state,
      enabledSolutions: null,
      error: null,
    };
  }),
  on(TransactionSolutionActions.loadEnabledSolutionsSuccess, (state, { enabledSolutions }): TransactionSolutionState => {
    return {
      ...state,
      enabledSolutions,
    };
  }),
  on(TransactionSolutionActions.loadEnabledSolutionsFailure, (state, { error }): TransactionSolutionState => {
    return {
      ...state,
      error,
    };
  }),

  on(TransactionSolutionActions.loadEnabledSolutionConfigs, (state): TransactionSolutionState => {
    return {
      ...state,
      enabledSolutionConfigs: [],
      error: null,
    };
  }),
  on(TransactionSolutionActions.loadEnabledSolutionConfigsSuccess, (state, { enabledSolutionConfigs }): TransactionSolutionState => {
    return {
      ...state,
      enabledSolutionConfigs,
    };
  }),
  on(TransactionSolutionActions.loadEnabledSolutionConfigsFailure, (state, { error }): TransactionSolutionState => {
    return {
      ...state,
      error,
    };
  }),
);

export const getEnabledSolutions = (state: TransactionSolutionState) => state.enabledSolutions;
export const getSolutionsConfigs = (state: TransactionSolutionState) => state.enabledSolutionConfigs;
