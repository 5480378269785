import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, ViewChild } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Store } from '@ngrx/store';
import { SelectionType } from '@swimlane/ngx-datatable';
import { tap } from 'rxjs';
import { RktButtonDirective } from '@rocketfinancialcorp/rocket-ui/button';
import { RktIconComponent } from '@rocketfinancialcorp/rocket-ui/icon';
import { ActiveModal } from '@rocketfinancialcorp/rocket-ui/modal';
import {
  DatatableColumn,
  DatatableSorting,
  RktTableComponent,
  RktTableSearchComponent,
  SplitPipe,
} from '@rocketfinancialcorp/rocket-ui/table';

import { FinancialAccountSelectionListItem, RequestPageParams } from '@shared/models';
import { TransactionFormActions, selectCancelReviewData, transactionFeature, transactionFormFeature } from '@shared/store';
import { CurrencyCode } from '@shared/utils';

import { AmountPipe } from '../../../pipes/amount.pipe';
import { CreateTransactionNoteComponent } from '../create-transaction-note/create-transaction-note.component';

@Component({
  selector: 'app-cancel-recovery-financial-account-modal',
  templateUrl: './cancel-recovery-financial-account-modal.component.html',
  standalone: true,
  imports: [
    RktButtonDirective,
    NgIf,
    NgFor,
    FormsModule,
    ReactiveFormsModule,
    RktIconComponent,
    RktTableSearchComponent,
    RktTableComponent,
    CreateTransactionNoteComponent,
    AsyncPipe,
    AmountPipe,
    SplitPipe,
  ],
})
export class CancelRecoveryFinancialAccountModalComponent implements AfterViewChecked, OnDestroy {
  @ViewChild(RktTableComponent) financialAccountsTableRef!: RktTableComponent<FinancialAccountSelectionListItem>;

  @ViewChild(RktTableSearchComponent) tableSearchRef!: RktTableSearchComponent;

  financialAccounts$ = this.store.select(transactionFormFeature.selectFinancialAccountItems);

  defaultBusinessAccountFinancialAccount$ = this.store.select(transactionFeature.selectDefaultBusinessAccountFinancialAccount).pipe(
    tap({
      next: (financialAccount) => {
        this.toAccountTypeField.setValue(financialAccount ? 'DEFAULT' : 'SELECT');
      },
    }),
  );

  cancelReviewData$ = this.store.select(selectCancelReviewData);

  currency$ = this.store.select(transactionFormFeature.selectCurrency);

  totalElements$ = this.store.select(transactionFormFeature.selectFinancialAccountItemsTotalElements);

  isLoading$ = this.store.select(transactionFormFeature.selectFinancialAccountItemsLoading);

  activePage = 0;

  selectedFinancialAccounts: FinancialAccountSelectionListItem[] = [];

  selectionType = SelectionType.single;

  sortParams: DatatableSorting = { key: 'createdAt', sortProp: 'createdAt', sortDir: 'desc' };

  searchString?: string;

  toAccountTypeField = new FormControl('DEFAULT');

  toAccountTypeOptions = [
    { label: 'Default account', value: 'DEFAULT' },
    { label: 'Select another account', value: 'SELECT' },
  ];

  columns: DatatableColumn[] = [
    { name: 'Account Holder', prop: 'accountHolderInfo', colType: 'two-line-col', flexSize: 1 },
    { name: 'Account Info', prop: 'accountInfo', colType: 'two-line-col', flexSize: 1 },
    { name: 'Account Category', prop: 'accountCategory', fixedSize: 160 },
    { name: 'Account Balance', prop: 'accountBalance', fixedSize: 160, colType: 'amount', colAlign: 'right' },
    { name: 'Available Balance', prop: 'availableBalance', fixedSize: 160, colType: 'amount', colAlign: 'right' },
    { name: 'Status', prop: 'state', fixedSize: 140, colType: 'status-tag', colAlign: 'right' },
  ];

  constructor(
    public activeModal: ActiveModal,
    private ref: ChangeDetectorRef,
    private store: Store,
  ) {}

  ngAfterViewChecked(): void {
    this.ref.detectChanges();
  }

  ngOnDestroy(): void {
    this.store.dispatch(TransactionFormActions.resetFinancialAccountSelection());
  }

  onSearch(searchString?: string): void {
    if (!searchString && !this.searchString) {
      return;
    }

    this.searchString = searchString;
    this.financialAccountsTableRef?.setPage({ offset: 1 });
  }

  fetchFinancialAccounts({ page, size, sortParams }: RequestPageParams, currency: CurrencyCode | null): void {
    if (sortParams) {
      this.sortParams = sortParams;
    }

    this.store.dispatch(
      TransactionFormActions.loadFinancialAccounts({
        params: {
          page,
          size,
          categories: ['INTERNAL'],
          searchString: this.searchString,
          sortParams: this.sortParams,
          type: 'to',
          currency,
        },
      }),
    );
  }

  onFinancialAccountSelect(data: unknown): void {
    const { selected = [] } = data as { selected?: FinancialAccountSelectionListItem[] };

    this.selectedFinancialAccounts = [...selected];
  }

  onContinueBtnClick(financialAccount: FinancialAccountSelectionListItem): void {
    this.store.dispatch(TransactionFormActions.setMoveTo({ financialAccount }));
    this.activeModal.close({ action: 'CANCEL_REVIEW' });
  }
}
