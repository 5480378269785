import { AsyncPipe, NgIf } from '@angular/common';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Store } from '@ngrx/store';
import { upperCase } from 'lodash-es';
import { Observable, Subject, catchError, map, of, switchMap, takeUntil, tap } from 'rxjs';
import { RktIconComponent } from '@rocketfinancialcorp/rocket-ui/icon';

import { AdaRegistrationDropdownItem, AdaRegistrationList, TransactionDebitAuthorization } from '@shared/models';
import { AdaRegistrationService } from '@shared/services';
import { TransactionFormActions, transactionFormFeature } from '@shared/store';

@Component({
  selector: 'app-debit-authorization-select',
  templateUrl: './debit-authorization-select.component.html',
  styleUrls: ['./debit-authorization-select.component.scss'],
  standalone: true,
  imports: [NgSelectComponent, FormsModule, NgIf, AsyncPipe, RktIconComponent],
})
export class DebitAuthorizationSelectComponent implements OnInit {
  @ViewChild('select') selectRef!: NgSelectComponent;

  @Output() changed = new EventEmitter<TransactionDebitAuthorization>();

  loading = false;

  items$!: Observable<AdaRegistrationDropdownItem[]>;

  selectedItem?: string;

  selectedDebitAuthorization!: AdaRegistrationDropdownItem | undefined;

  bypassDebitAuthorizationStatus = false;

  private destroy$ = new Subject<void>();

  constructor(
    private store: Store,
    private adaRegistrationService: AdaRegistrationService,
  ) {}

  ngOnInit() {
    this.getRegistrationsForAccountHolder();
  }

  onChange(item?: AdaRegistrationDropdownItem): void {
    this.selectedItem = item?.value;
    this.selectedDebitAuthorization = item;
    this.updateDebitAuthorizationState();
  }

  bypassDebitAuthorizationToggle() {
    this.bypassDebitAuthorizationStatus = !this.bypassDebitAuthorizationStatus;
    this.updateDebitAuthorizationState();
  }

  updateDebitAuthorizationState() {
    const debitAuthStateData = {
      authorizationLabel: this.bypassDebitAuthorizationStatus ? 'Offline Debit Authorization' : this.selectedDebitAuthorization?.label,
      debitAuthorizationId: this.selectedDebitAuthorization?.value ?? undefined,
      bypassDebitAuthorizationStatus: this.bypassDebitAuthorizationStatus,
    };

    this.changed.emit(debitAuthStateData);
    this.store.dispatch(TransactionFormActions.setDebitAuthorization({ debitAuthorization: debitAuthStateData }));
  }

  onOpen() {
    this.selectedItem = this.selectedDebitAuthorization?.value;
  }

  onClose() {
    this.selectRef?.blur();
  }

  getRegistrationsForAccountHolder(): void {
    this.loading = true;
    this.items$ = this.store.select(transactionFormFeature.selectFromAccount).pipe(
      takeUntil(this.destroy$),
      switchMap((fromAccountDetails) => {
        if (fromAccountDetails?.accountHolderId) {
          return this.adaRegistrationService
            .getAdaRegistrations({
              page: 0,
              size: 100,
              accountHolderIds: [fromAccountDetails?.accountHolderId ?? ''],
            })
            .pipe(
              tap({
                next: () => (this.loading = false),
              }),
              map((debitRegistrations) => {
                return this.mapADARegistrationDropdown(debitRegistrations);
              }),
              catchError((error) => {
                this.loading = false;
                console.error('adaRegistrationService getAdaRegistrations', error);
                return of([] as AdaRegistrationDropdownItem[]);
              }),
            );
        } else {
          this.loading = false;
          return of([]);
        }
      }),
    );
  }

  mapADARegistrationDropdown(debitAuthorizations: AdaRegistrationList): AdaRegistrationDropdownItem[] {
    const { items } = debitAuthorizations;

    return items?.length
      ? [
          ...items.map((adaRegistration) => {
            return {
              label: `${upperCase(adaRegistration.transactionOccurrenceType)}: ${
                adaRegistration.amount ? '$' + adaRegistration.amount : 'AMT UNSPECIFIED'
              }`,
              value: adaRegistration.id,
            };
          }),
        ]
      : [];
  }
}
