import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'notAvailable',
  standalone: true,
})
export class NotAvailablePipe implements PipeTransform {
  transform(value?: string | null, placeholder = 'N/A'): string {
    if (!value) {
      return placeholder;
    }

    return value;
  }
}
