import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RktIconComponent } from '@rocketfinancialcorp/rocket-ui/icon';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
  standalone: true,
  imports: [NgIf, RktIconComponent],
})
export class TooltipComponent {
  @Input() tooltip?: string | null;

  isTooltipDisplayed = false;
}
