import { NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { RktButtonDirective } from '@rocketfinancialcorp/rocket-ui/button';
import { RktIconComponent } from '@rocketfinancialcorp/rocket-ui/icon';
import { ActiveModal } from '@rocketfinancialcorp/rocket-ui/modal';

import { FinancialAccountReviewData } from '@shared/models';

import { DetailsItemComponent } from '../../details-item/details-item.component';

@Component({
  selector: 'app-add-financial-account-review',
  templateUrl: './add-financial-account-review.component.html',
  styleUrls: ['./add-financial-account-review.component.scss'],
  standalone: true,
  imports: [RktButtonDirective, NgIf, DetailsItemComponent, RktIconComponent],
})
export class AddFinancialAccountReviewComponent {
  pageClassPrefix = 'financial-account-review-';

  reviewData!: FinancialAccountReviewData | null;

  constructor(public activeModal: ActiveModal) {}

  modalInitData(reviewData: FinancialAccountReviewData) {
    this.reviewData = reviewData;
  }
}
