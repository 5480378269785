import { Action, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromRoot from '@shared/store';

import * as fromDocuments from './document.reducer';

export const documentFeatureKey = 'document';

export interface DocumentState {
  [fromDocuments.documentPageFeatureKey]: fromDocuments.DocumentsState;
}

export interface State extends fromRoot.RootState {
  [documentFeatureKey]: DocumentState;
}

export const reducers = (state: DocumentState | undefined, action: Action) => {
  return combineReducers({
    [fromDocuments.documentPageFeatureKey]: fromDocuments.reducer,
  })(state, action);
};

export const selectDocumentState = createFeatureSelector<DocumentState>(documentFeatureKey);

export const selectDocumentDetailsState = createSelector(selectDocumentState, (state) => state.detailsPage);

export const selectDocumentDetails = createSelector(selectDocumentDetailsState, fromDocuments.getDocument);

export const selectDocumentDetailsLoading = createSelector(selectDocumentDetailsState, fromDocuments.getLoading);

export const selectDocumentDetailsError = createSelector(selectDocumentDetailsState, fromDocuments.getError);
