import { NgIf, NgTemplateOutlet } from '@angular/common';
import { Component, ElementRef, HostBinding, Input, TemplateRef } from '@angular/core';
import { RktIconComponent } from '@rocketfinancialcorp/rocket-ui/icon';

import { NotAvailablePipe } from '../../pipes/not-available.pipe';
import { DetailsItemPlaceholderComponent } from '../loading-placeholders/details-item-placeholder/details-item-placeholder.component';

@Component({
  selector: 'app-details-item',
  templateUrl: './details-item.component.html',
  styleUrls: ['./details-item.component.scss'],
  standalone: true,
  imports: [NgIf, NgTemplateOutlet, DetailsItemPlaceholderComponent, NotAvailablePipe, RktIconComponent],
})
export class DetailsItemComponent {
  @HostBinding('class.details-item-container') commonClass = true;

  @Input() label = '';

  @Input() value?: string | null;

  @Input() noBreak = false;

  @Input() wordWrap = false;

  @Input() showPlaceholder = true;

  @Input() title?: string | null;

  @Input() isPrimary?: boolean;

  @Input() isValueMuted?: boolean;

  @Input() isDarkBg?: boolean;

  @Input() showPlaceholderLabel?: boolean;

  @Input() multiline = false;

  @Input() contentValue?: boolean;

  @Input() iconName?: string;

  @Input() labelBtnTemplate!: TemplateRef<ElementRef>;
}
