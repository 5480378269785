import { NgClass, TitleCasePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';
import { RktIconComponent } from '@rocketfinancialcorp/rocket-ui/icon';

import { LinxEntityType, LinxProfileType } from '@shared/constants';

@Component({
  selector: 'app-linx-request-profile-type-selection',
  standalone: true,
  imports: [RktIconComponent, TitleCasePipe, NgClass],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './linx-request-profile-type-selection.component.html',
})
export class LinxRequestProfileTypeSelectionComponent {
  entityType = input<LinxEntityType>(LinxEntityType.CUSTOMER);

  profileType = input<LinxProfileType | undefined>();

  profileTypeChange = output<LinxProfileType>();

  LinxProfileType = LinxProfileType;

  onItemClick(profileType: LinxProfileType): void {
    this.profileTypeChange.emit(profileType);
  }
}
