import { createAction, props } from '@ngrx/store';

import { FeatureFlag } from '@shared/models';

export namespace FeatureManagementActions {
  export const resetFeatureFlags = createAction('[Feature Management] Reset Feature Flags');

  export const resetFeatureFlagsComplete = createAction(
    '[Feature Management] Reset Feature Flags Complete',
    props<{ featureFlags: FeatureFlag[] }>(),
  );

  export const setFeatureFlags = createAction(
    '[Feature Management] Set Feature Flags',
    props<{ featureFlagsValues: Record<string, boolean | string> }>(),
  );
}
