import { Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { RktButtonDirective } from '@rocketfinancialcorp/rocket-ui/button';
import { RktIconComponent } from '@rocketfinancialcorp/rocket-ui/icon';

import { Breadcrumb } from '@shared/models';
import { emptySpacesValidator } from '@shared/validators';

import { BreadcrumbsComponent } from '../../../../shared/components/breadcrumbs/breadcrumbs.component';
import { FileUploadComponent } from '../../../../shared/components/file-upload/file-upload.component';
import { NoteFormItemComponent } from '../../../../shared/components/notes/note-form-item/note-form-item.component';

@Component({
  selector: 'app-transaction-batch-create',
  templateUrl: 'transaction-batch-create.component.html',
  styleUrls: ['transaction-batch-create.component.scss'],
  standalone: true,
  imports: [
    RktButtonDirective,
    RouterLink,
    RktIconComponent,
    BreadcrumbsComponent,
    FileUploadComponent,
    NoteFormItemComponent,
    FormsModule,
    ReactiveFormsModule,
  ],
})
export class TransactionBatchCreateComponent {
  pageReturnURL = '/app/transactions/transaction-batches';

  batchUploadData = new FormData();

  note = new UntypedFormControl('', [emptySpacesValidator]);

  breadcrumbList: Breadcrumb[] = [{ label: 'Transaction Batch', url: this.pageReturnURL }, { label: 'Create Batch' }];

  get isSubmitDisabled() {
    return !this.batchUploadData.get('file');
  }

  constructor(private router: Router) {}

  onFileUploaded(file: File | null): void {
    if (!file) {
      this.batchUploadData.delete('file');
    } else {
      this.batchUploadData.set('file', file);
    }
  }

  onNoteFieldBlur(): void {
    this.note.setValue(this.note.value?.trim());
  }

  onDiscardBtnClick() {
    this.router.navigate(['app/transactions/transaction-batches']);
  }

  onSubmitBtnClick() {
    console.warn('create batch');
  }
}
