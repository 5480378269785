import { Directive, Input } from '@angular/core';

@Directive({
  selector: '[appTemplateContext]',
  standalone: true,
})
export class TemplateContextDirective<T> {
  @Input()
  appTemplateContext?: T;

  static ngTemplateContextGuard<T>(_dir: TemplateContextDirective<T>, _ctx: unknown): _ctx is T {
    return true;
  }
}
