import { AsyncPipe, NgFor } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { Store } from '@ngrx/store';
import { RktButtonDirective } from '@rocketfinancialcorp/rocket-ui/button';
import { RktIconComponent } from '@rocketfinancialcorp/rocket-ui/icon';
import { ModalService } from '@rocketfinancialcorp/rocket-ui/modal';

import { ReportMenuOption } from '@shared/models';
import { InsightDocumentActions, fromInsightDocument } from '@shared/store';
import { SupersetDashboardComponent } from '../../../../shared/components/insight/superset-dashboard/superset-dashboard.component';
import { NaturalLanguageSearchModalComponent } from '../../../../shared/components/natural-language-search-modal/natural-language-search-modal.component';

@Component({
  selector: 'app-reports-page',
  templateUrl: './reports-page.component.html',
  styleUrls: ['./reports-page.component.scss'],
  standalone: true,
  imports: [RktButtonDirective, NgFor, SupersetDashboardComponent, RouterOutlet, AsyncPipe, RktIconComponent],
})
export class ReportsPageComponent implements OnInit {
  insightUnreadDocumentCount$ = this.store.select(fromInsightDocument.selectInsightUnreadDocuments);

  menuOptions: ReportMenuOption[] = [
    {
      icon: 'brainstorm',
      title: 'Ask me anything!',
      subtitle: 'What do you want to know today?',
      customClick: (e) => {
        e?.preventDefault();
        this.openSearchModal();
      },
    },
    {
      icon: 'graph-report',
      title: 'Schedule a Custom Report',
      subtitle: 'Stay up-to-date with the performance',
      customClick: (e) => {
        e?.preventDefault();
        this.viewScheduledReports();
      },
    },
  ];

  constructor(
    private router: Router,
    private modalService: ModalService,
    private store: Store,
  ) {}

  ngOnInit() {
    this.store.dispatch(InsightDocumentActions.loadInsightDocumentHistory());
  }

  viewReports() {
    this.router.navigate(['app/insights/documents']);
  }

  viewScheduledReports() {
    this.router.navigate(['app/insights/documents/scheduled-report/list']);
  }

  openSearchModal() {
    this.modalService.open(NaturalLanguageSearchModalComponent, {
      className: 'natural-language-search-modal',
    });
  }
}
