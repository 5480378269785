import { FormlyFieldConfig } from '@ngx-formly/core';
import { formInput, formInputPlace, formRepeatSection, formRow } from '@rocketfinancialcorp/rocket-ui/form';

import { Patterns } from '@shared/components';

export const updateAddressFields = ({
  addressType,
  onMakePrimaryClick,
  onSectionRemove,
  isRequired,
}: {
  addressType: string;
  onSectionRemove: (i: number, remove: (index: number) => void) => void;
  onMakePrimaryClick: (i: number) => void;
  isRequired?: boolean;
}) => {
  const expressions = {
    'props.required': (field: FormlyFieldConfig) => {
      if (isRequired) return true;
      return (
        !!field.model?.addressLine1 ||
        !!field.model?.addressLine2 ||
        !!field.model?.city ||
        !!field.model?.state ||
        !!field.model?.postalCode ||
        !!field.model?.country
      );
    },
    'props.disabled': (field: FormlyFieldConfig) => {
      return field.model?.verified;
    },
  };
  return [
    formRepeatSection({
      key: 'addresses',
      unstyled: true,
      addText: `Add ${addressType} Address`,
      removeText: 'Remove',
      header: `${addressType} Address`,
      subheader: addressType === 'Physical' ? 'Must not be a PO box' : undefined,
      extraButtons: [
        {
          label: 'Set as Primary Address',
          type: 'radio',
          skipFirstSection: true,
          onClick: onMakePrimaryClick,
        },
      ],
      onRemove: onSectionRemove,
      fieldGroup: [
        {
          key: 'addressType',
          defaultValue: `${addressType.toUpperCase()}`,
        },
        formRow(
          [
            formInput({
              key: 'firstName',
              label: 'First Name',
              props: {
                pattern: Patterns.GENERIC_NAME,
                minLength: 1,
                maxLength: 40,
              },
              validation: { messages: { pattern: 'Enter a valid first name' } },
            }),
            formInput({
              key: 'middleName',
              label: 'Middle Name',
              props: {
                pattern: Patterns.GENERIC_NAME,
                minLength: 1,
                maxLength: 40,
              },
              validation: { messages: { pattern: 'Enter a valid middle name' } },
            }),
            formInput({
              key: 'lastName',
              label: 'Last Name',
              props: {
                pattern: Patterns.GENERIC_NAME,
                minLength: 1,
                maxLength: 40,
              },
              validation: { messages: { pattern: 'Enter a valid last name' } },
            }),
          ],
          { isHidden: addressType !== 'Shipping' },
        ),
        formRow([
          formInputPlace({
            key: 'addressLine1',
            label: `${addressType} Address Line 1`,
            props: {
              countryRestrictions: ['US'],
            },
            expressions,
          }),
        ]),
        formRow([
          formInput({
            key: 'addressLine2',
            label: 'Address Line 2',
            expressions: {
              'props.disabled': (field: FormlyFieldConfig) => field.model?.verified,
            },
          }),
        ]),
        formRow([
          formInput({
            key: 'city',
            label: 'City',
            className: 'city-field',
            props: { minLength: 1, maxLength: 25 },
            expressions,
          }),
          formInput({
            key: 'state',
            label: 'State',
            className: 'state-field',
            props: { minLength: 2, maxLength: 3 },
            expressions,
            validators: { validation: [{ name: 'isValidStateCode' }] },
          }),
          formInput({
            key: 'postalCode',
            label: 'Zip Code',
            className: 'postal-code-field',
            props: { minLength: 1, maxLength: 10 },
            expressions,
          }),
        ]),
        formRow([
          formInput({
            key: 'country',
            label: 'Country',
            parsers: [(value?: string) => value?.toUpperCase()],
            props: { minLength: 3, maxLength: 3 },
            expressions,
            validators: { validation: [{ name: 'isValidCountryCode' }] },
            validation: {
              messages: {
                minLength: 'Must be 3 characters',
              },
            },
          }),
        ]),
      ],
    }),
  ];
};
