import { createReducer, on } from '@ngrx/store';

import { ErrorMessage, RecipientList } from '@shared/models';

import { RecipientActions } from './recipient.actions';

export const recipientListFeatureKey = 'list';

export interface RecipientsState {
  recipients: RecipientList['items'] | null;
  totalElements: number;
  isLoading: boolean;
  error: ErrorMessage;
}

export const initialRecipientsState: RecipientsState = {
  recipients: null,
  totalElements: 0,
  isLoading: false,
  error: null,
};

export const reducer = createReducer<RecipientsState>(
  initialRecipientsState,
  on(RecipientActions.loadRecipients, (state): RecipientsState => {
    return {
      ...state,
      isLoading: true,
      error: null,
    };
  }),
  on(RecipientActions.loadRecipientsSuccess, (state, action): RecipientsState => {
    return {
      ...state,
      recipients: action.recipients,
      totalElements: action.totalElements,
      isLoading: false,
    };
  }),
  on(RecipientActions.loadRecipientsError, (state, action): RecipientsState => {
    return {
      ...state,
      isLoading: false,
      error: action.error,
    };
  }),
);

export const getRecipients = (state: RecipientsState) => state.recipients;
export const getTotalElements = (state: RecipientsState) => state.totalElements;
export const getLoading = (state: RecipientsState) => state.isLoading;
