import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { FinancialAccountService } from '@shared/services';
import { FinancialAccountsActions, selectRouteParams } from '@shared/store';

@Injectable()
export class FinancialAccountsEffects {
  constructor(
    private actions$: Actions,
    private financialAccountService: FinancialAccountService,
    private store: Store,
  ) {}

  getFinancialAccountsIds$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FinancialAccountsActions.loadFinancialAccountsIds),
      concatLatestFrom(() => this.store.select(selectRouteParams)),
      mergeMap(([, routeParams]) => {
        return this.financialAccountService.getFinancialAccountsIds(routeParams.id).pipe(
          map((response) => {
            return FinancialAccountsActions.loadFinancialAccountsIdsSuccess({ financialAccountsIds: response });
          }),
          catchError((error) => {
            return of(FinancialAccountsActions.loadFinancialAccountsIdsError({ error }));
          }),
        );
      }),
    );
  });
}
