import { AsyncPipe, NgIf } from '@angular/common';
import { AfterViewChecked, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { isEmpty, isUndefined } from 'lodash-es';
import { BehaviorSubject, Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { RktButtonDirective } from '@rocketfinancialcorp/rocket-ui/button';
import { RktIconComponent } from '@rocketfinancialcorp/rocket-ui/icon';
import {
  DatatableColumn,
  DatatableSorting,
  FetchData,
  RktTableComponent,
  RktTableSearchComponent,
} from '@rocketfinancialcorp/rocket-ui/table';

import { FilterMenuComponent } from '@shared/components';
import { FilterField, FilterValues, RequestPageParams, TransactionBatchList, TransactionBatchListItem } from '@shared/models';
import { AccessControlPipe } from '@shared/pipes';
import { TransactionBatchesService } from '@shared/services';
import { selectFeatureFlagEnabledByKey } from '@shared/store';
import { ErrorUtils, activeFilters } from '@shared/utils';

@Component({
  templateUrl: './transaction-batch-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    RktButtonDirective,
    RktTableSearchComponent,
    RktIconComponent,
    NgIf,
    RktTableComponent,
    AsyncPipe,
    AccessControlPipe,
    FilterMenuComponent,
  ],
})
export class TransactionBatchListComponent implements OnInit, AfterViewChecked, OnDestroy {
  loading = true;

  listItems$ = new BehaviorSubject<TransactionBatchListItem[]>([]);

  totalElements$ = new BehaviorSubject<number>(0);

  transactionBatchListRaw: TransactionBatchList = { items: [], totalElements: 0 };

  activeFilters: FilterValues = {};

  filters: FilterField[] = [
    {
      name: 'createdDate',
      displayName: 'Date',
      type: 'DATE',
      options: [
        { label: 'All Time', value: undefined },
        { label: 'Today', value: 'TODAY' },
        { label: 'Yesterday', value: 'YESTERDAY' },
        { label: 'This week', value: 'THIS_WEEK' },
        { label: 'This month', value: 'THIS_MONTH' },
        { label: 'Custom range', value: 'CUSTOM' },
      ],
      props: {
        maxMonths: '15',
      },
    },
    {
      name: 'status',
      displayName: 'Status',
      type: 'RADIO',
      options: [
        { label: 'All', value: undefined },
        { label: 'Cancelled', value: 'CANCELLED' },
        { label: 'Completed', value: 'COMPLETED' },
        { label: 'Error', value: 'ERROR' },
        { label: 'Pending', value: 'PENDING' },
        { label: 'Processing', value: 'PROCESSING' },
      ],
    },
    {
      name: 'solution',
      displayName: 'Solution',
      type: 'RADIO',
      options: [
        { label: 'All', value: undefined },
        { label: 'ACH', value: 'ach' },
        { label: 'Push To Card', value: 'push-to-card' },
        { label: 'Transfer', value: 'transfer' },
        { label: 'Wire', value: 'wire' },
      ],
    },
  ];

  columns: DatatableColumn[] = [
    { name: 'Created At', prop: 'createdAt', fixedSize: 150, isSortable: true, colType: 'date-time' },
    { name: 'Batch ID', prop: 'id', colType: 'short-id', flexSize: 180 },
    { name: 'Solution', prop: 'solutionName', flexSize: 180 },
    { name: 'Type', prop: 'transactionType', flexSize: 140 },
    { name: 'Total Volume', prop: 'totalVolume', colAlign: 'right', sortProp: 'transactionCount', fixedSize: 150, isSortable: true },
    {
      name: 'Total Value',
      prop: 'totalAmount',
      colType: 'amount',
      colAlign: 'right',
      sortProp: 'totalAmount',
      fixedSize: 200,
      isSortable: true,
    },
    { name: 'Status', prop: 'status', fixedSize: 140, isSortable: true, colType: 'status-tag', colAlign: 'right' },
  ];

  sortParams: DatatableSorting = { key: 'createdAt', sortProp: 'createdAt', sortDir: 'desc' };

  emptyListMessage = 'No Batches found.';

  get activePage(): number {
    const routePage = this.route.snapshot.queryParamMap.get('page');
    return routePage ? parseInt(routePage, 10) : 0;
  }

  get activeFiltersCount(): number {
    return Object.keys(this.activeFilters).length;
  }

  createBatchFlag$ = this.store.select(selectFeatureFlagEnabledByKey('add-batch-feature'));

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    public ref: ChangeDetectorRef,
    private router: Router,
    private route: ActivatedRoute,
    private transactionBatchesService: TransactionBatchesService,
    private store: Store,
  ) {}

  ngOnInit(): void {
    this.transactionBatchesService.activeFilters.pipe(takeUntil(this.destroy$)).subscribe({
      next: (filters: FilterValues) => {
        if (isEmpty(filters)) {
          this.transactionBatchesService.getFilterParams();
        } else {
          this.activeFilters = filters;
        }
      },
    });
  }

  ngAfterViewChecked(): void {
    this.ref.markForCheck();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getTransactionBatches({ page, size, sortParams }: FetchData): void {
    this.loading = true;

    this.updateQueryParams(page);

    if (sortParams) {
      this.sortParams = sortParams;
    }

    this.transactionBatchesService
      .getTransactionBatches({ page, size, sortParams: sortParams || this.sortParams, activeFilters: { ...this.activeFilters } })
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => {
          this.loading = false;
          this.ref.markForCheck();
        }),
      )
      .subscribe({
        next: (response) => {
          this.emptyListMessage = 'No Batches found.';
          this.onFetchComplete(response);
        },
        error: (error) => {
          this.emptyListMessage = 'Unable to fetch Batches.';
          ErrorUtils.catchError('transactionBatchesService.getTransactionBatches', error);
        },
      });
  }

  onSearch(term?: string): void {
    const { items, totalElements } = this.transactionBatchListRaw;
    const listItems = !term ? items : items.filter((item) => item.shortId.match(new RegExp(term, 'gi')));
    this.listItems$.next(listItems);
    this.totalElements$.next(term ? listItems.length : totalElements);
  }

  onCreateBatchBtnClick() {
    this.router.navigate(['/app/transactions/transaction-batches/add-batch']);
  }

  private onFetchComplete(response: TransactionBatchList) {
    const { items = [], totalElements = 0 } = response || {};
    this.transactionBatchListRaw = { ...response };
    this.listItems$.next(items);
    this.totalElements$.next(totalElements);
  }

  private updateQueryParams(page?: RequestPageParams['page']): void {
    if (!isUndefined(page) && this.activePage !== page) {
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: { page: page > 0 ? page : undefined },
        queryParamsHandling: 'merge',
      });
    }
  }

  public onRowClick({ id }: TransactionBatchListItem) {
    this.router.navigateByUrl(`/app/transactions/transaction-batches/${id}/batch-details`);
  }

  applyFilters(filterValues: FilterValues): void {
    this.activeFilters = activeFilters(filterValues);
    this.transactionBatchesService.setFilterParams(this.activeFilters);
    this.getTransactionBatches({ page: 0 });
  }
}
