import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { LoaderService } from '@shared/services';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  private excludedUrlsRegex: RegExp[] = [];

  private excludedUrls = ['.svg', '/token', '/roles/standard', '/event-types', '/acceptance-documents'];

  private totalRequests = 0;

  constructor(private loaderService: LoaderService) {
    this.excludedUrlsRegex = this.excludedUrls.map((urlPattern) => new RegExp(urlPattern, 'i'));
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const passThrough = !!this.excludedUrlsRegex.find((regex) => regex.test(request.url));

    if (passThrough) {
      return next.handle(request);
    }

    this.totalRequests++;
    this.loaderService.isLoading$.next(true);
    return next.handle(request).pipe(
      finalize(() => {
        this.totalRequests--;
        if (this.totalRequests === 0) {
          this.loaderService.isLoading$.next(false);
        }
      }),
    );
  }
}
