import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';

import { CustomerService } from '@shared/services';
import { MessagesActions, selectRouteParams, selectUrl } from '@shared/store';
import { ErrorUtils } from '@shared/utils';

import { CustomerBeneficiaryActions } from './customer-beneficiary.actions';

@Injectable()
export class CustomerBeneficiaryEffects {
  constructor(
    private router: Router,
    private actions$: Actions,
    private customerService: CustomerService,
    private store: Store,
  ) {}

  getCustomerBeneficiary$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CustomerBeneficiaryActions.loadCustomerBeneficiary),
      concatLatestFrom(() => this.store.select(selectRouteParams)),
      mergeMap(([, routeParams]) => {
        return this.customerService
          .getBusinessCustomerBeneficiaryById({
            customerId: routeParams.entityId!,
            beneficiaryId: routeParams.id!,
          })
          .pipe(
            map((response) => CustomerBeneficiaryActions.loadCustomerBeneficiarySuccess({ beneficiary: response })),
            catchError((error) => {
              return of(CustomerBeneficiaryActions.loadCustomerBeneficiaryError({ error }));
            }),
          );
      }),
    );
  });

  loadCustomerBeneficiaryError$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CustomerBeneficiaryActions.loadCustomerBeneficiaryError),
      tap({
        next: (loadCustomerBeneficiaryError) => {
          ErrorUtils.catchError('customerService.getBusinessCustomerBeneficiaryById error', loadCustomerBeneficiaryError.error);
        },
      }),
      switchMap(() => of(MessagesActions.displayError({ message: 'Unable to fetch Beneficial Owner.', title: 'Error' }))),
    );
  });

  loadCustomerBeneficiaryErrorRedirect$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(CustomerBeneficiaryActions.loadCustomerBeneficiaryError),
        concatLatestFrom(() => this.store.select(selectUrl)),
        tap({
          next: ([, currentUrl]) => {
            const prevUrl = currentUrl.split('/').slice(0, -2).join('/');
            this.router.navigateByUrl(prevUrl);
          },
        }),
      );
    },
    { dispatch: false },
  );
}
