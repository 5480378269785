import { createFeature, createReducer, createSelector, on } from '@ngrx/store';

import { ExceptionDetails, ExceptionHistoryItem, ExceptionItem, FilterValues } from '@shared/models';

import { ExceptionManagementActions } from './exception-management.actions';

export interface ExceptionManagementState {
  exceptions: ExceptionItem[];
  exceptionsTotalElements: number;
  exceptionsActiveFilters: FilterValues | null;
  exceptionsAssigneeFilter: { label: string; value: string }[];
  isLoading: boolean;
  exceptionDetails: ExceptionDetails | null;
  exceptionHistoryItems: ExceptionHistoryItem[];
  exceptionHistoryTotalElements: number;
}

export const exceptionManagementInitialState: ExceptionManagementState = {
  exceptions: [],
  exceptionsTotalElements: 0,
  exceptionsActiveFilters: { date: 'DEFAULT', status: 'NEW,IN_PROGRESS,REOPENED' },
  exceptionsAssigneeFilter: [],
  isLoading: false,
  exceptionDetails: null,
  exceptionHistoryItems: [],
  exceptionHistoryTotalElements: 0,
};

export const exceptionManagementFeature = createFeature({
  name: 'exceptionManagementFeature',
  reducer: createReducer(
    exceptionManagementInitialState,
    on(
      ExceptionManagementActions.loadExceptions,
      (state): ExceptionManagementState => ({ ...state, exceptions: [], exceptionsTotalElements: 0, isLoading: true }),
    ),
    on(ExceptionManagementActions.loadExceptionsSuccess, (state, { exceptionListItems, totalElements }): ExceptionManagementState => {
      return { ...state, exceptions: exceptionListItems, exceptionsTotalElements: totalElements, isLoading: false };
    }),
    on(ExceptionManagementActions.loadExceptionsFailure, (state): ExceptionManagementState => {
      return { ...state, exceptions: [], exceptionsTotalElements: 0, isLoading: false };
    }),
    on(ExceptionManagementActions.setExceptionsActiveFilters, (state, { filters }): ExceptionManagementState => {
      return { ...state, exceptionsActiveFilters: filters };
    }),
    on(ExceptionManagementActions.setExceptionsAssigneeFilter, (state, { assigneeFilter }): ExceptionManagementState => {
      return { ...state, exceptionsAssigneeFilter: assigneeFilter };
    }),
    on(ExceptionManagementActions.loadExceptionDetails, (state): ExceptionManagementState => ({ ...state, exceptionDetails: null })),
    on(ExceptionManagementActions.loadExceptionDetailsSuccess, (state, { exceptionDetails }): ExceptionManagementState => {
      return { ...state, exceptionDetails };
    }),
    on(ExceptionManagementActions.updateExceptionSuccess, (state, { exceptionDetails }): ExceptionManagementState => {
      return { ...state, exceptionDetails };
    }),
    on(
      ExceptionManagementActions.loadExceptionHistory,
      (state): ExceptionManagementState => ({ ...state, exceptionHistoryItems: [], exceptionHistoryTotalElements: 0 }),
    ),
    on(
      ExceptionManagementActions.loadExceptionHistorySuccess,
      (state, { exceptionHistoryItems, totalElements }): ExceptionManagementState => {
        return { ...state, exceptionHistoryItems, exceptionHistoryTotalElements: totalElements };
      },
    ),
    on(ExceptionManagementActions.loadExceptionHistoryFailure, (state): ExceptionManagementState => {
      return { ...state, exceptionHistoryItems: [], exceptionHistoryTotalElements: 0 };
    }),
  ),
});

export const selectExceptionsActiveFiltersRequest = createSelector(
  exceptionManagementFeature.selectExceptionsActiveFilters,
  (activeFilters) => {
    if (!activeFilters) {
      return;
    }

    const filters: Record<string, string> = {};
    Object.keys(activeFilters).map((filterKey) => {
      const value = activeFilters[filterKey];
      if (value && value !== 'DEFAULT' && value !== 'all') {
        filters[filterKey] = value;
      }
    });

    return filters;
  },
);

export const selectExceptionsActiveFiltersCount = createSelector(
  exceptionManagementFeature.selectExceptionsActiveFilters,
  (activeFilters) => {
    if (!activeFilters) {
      return 0;
    }

    return Object.values(activeFilters).filter(
      (value) => !!value && value !== 'DEFAULT' && value !== 'all' && value !== 'NEW,IN_PROGRESS,REOPENED',
    ).length;
  },
);
