import { formInput, formRow } from '@rocketfinancialcorp/rocket-ui/form';

import { Patterns } from '../patterns';

export const internalAccountFields = [
  formRow([
    formInput({
      key: 'name',
      label: 'Financial Account Nam\u0435',
      props: {
        pattern: Patterns.GENERIC_NAME,
        required: true,
      },
      validation: { messages: { pattern: 'Enter a valid Financial Account Nam\u0435' } },
      expressions: { 'props.disabled': 'model.category !== "INTERNAL"' },
    }),
  ]),
];
