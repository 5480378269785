import { financialAccountFeature } from './financial-account.state';

export * from './financial-account.actions';
export * from './financial-account.state';
export * as financialAccountFeatureEffects from './financial-account.effects';

export const {
  selectFinancialAccountDetails,
  selectFinancialAccountDetailsTitle,
  selectIsFinancialAccountExternal,
  selectIsFinancialAccountIntegratedCard,
} = financialAccountFeature;
