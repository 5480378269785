import { NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-linx-request-day-or-month-input-control',
  templateUrl: './linx-request-day-or-month-input-control.component.html',
  styleUrls: ['linx-request-day-or-month-input-control.component.scss'],
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, NgIf],
})
export class LinxRequestDayOrMonthInputControlComponent implements OnInit {
  @Input() initialValue?: number;

  @Input() maxValue?: number;

  @Input() label?: string;

  @Input() suffixLabel?: 'days' | 'months';

  @Input() showHours = false;

  @Output() changed = new EventEmitter<number>();

  @Output() hours = new EventEmitter<number>();

  showMaxValueErrorMessage = false;

  dayOrMonthFormControl = new UntypedFormControl();
  hoursFormControl = new UntypedFormControl();

  ngOnInit(): void {
    if (this.initialValue) {
      this.dayOrMonthFormControl.setValue(this.initialValue);
      this.changed.emit(this.initialValue);
    }
  }

  onChange(value: number): void {
    this.showMaxValueErrorMessage = false;
    if (this.dayOrMonthFormControl.invalid) return;
    let hours = this.dayOrMonthFormControl.value * 24;

    if (this.hoursFormControl.value) {
      hours = hours + this.hoursFormControl.value;
    }
    if (this.isMaximumValue(hours)) {
      this.showMaxValueErrorMessage = true;
      return;
    }

    this.changed.emit(value);
  }

  onHoursChange(value: number): void {
    this.showMaxValueErrorMessage = false;
    if (this.hoursFormControl.invalid) return;

    let hours = this.hoursFormControl.value;
    if (this.dayOrMonthFormControl.value) {
      hours = hours + this.dayOrMonthFormControl.value * 24;
    }
    if (this.isMaximumValue(hours)) {
      this.showMaxValueErrorMessage = true;
      return;
    }
    this.hours.emit(value);
  }

  private isMaximumValue(hours: number): boolean {
    return this.maxValue ? hours > this.maxValue * 24 : false;
  }

  resetValue(): void {
    this.dayOrMonthFormControl.reset();
    this.hoursFormControl.reset();
  }
}
