import { createReducer, on } from '@ngrx/store';

import { AuthTokenResponse, BusinessAccount, ErrorMessage, LinkedBusinessAccount, User } from '@shared/models';

import { AuthActions } from './auth.actions';
import { LoginPageActions } from './login-page.actions';

export const authFeatureKey = 'status';

export interface AuthState {
  user: User | null;
  businessAccount: LinkedBusinessAccount | null;
  businessAccountDetails: BusinessAccount | null;
  isLoading: boolean;
  error: ErrorMessage;
  tokenInfo: AuthTokenResponse | null;
  tenant: string | null;
  isSponsorTenant: boolean | undefined;
  showSplashScreen: boolean;
}

export const initialAuthState: AuthState = {
  user: null,
  businessAccount: null,
  businessAccountDetails: null,
  isLoading: false,
  error: null,
  tokenInfo: null,
  tenant: null,
  isSponsorTenant: undefined,
  showSplashScreen: false,
};

export const reducer = createReducer(
  initialAuthState,
  on(
    AuthActions.loginSuccess,
    (state, { user, businessAccount, tenant }): AuthState => ({
      ...state,
      user,
      businessAccount,
      tenant,
    }),
  ),
  on(AuthActions.logout, (): AuthState => initialAuthState),
  on(AuthActions.refreshTokenSuccess, (state, { tokenInfo }): AuthState => ({ ...state, tokenInfo })),
  on(AuthActions.businessAccountDetails, (state, { businessAccountDetails }): AuthState => ({ ...state, businessAccountDetails })),
  on(AuthActions.setSponsorAuthTenant, (state, { isSponsorTenant }): AuthState => ({ ...state, isSponsorTenant })),
  on(AuthActions.showSplashScreen, (state): AuthState => ({ ...state, showSplashScreen: true })),
  on(LoginPageActions.splashScreenAnimationEnd, (state): AuthState => ({ ...state, showSplashScreen: false })),
);

export const getToken = (state: AuthState) => state.tokenInfo;

export const getUser = (state: AuthState) => state.user;

export const getBusinessAccount = (state: AuthState) => state.businessAccount;

export const getBusinessAccountDetails = (state: AuthState) => state.businessAccountDetails;

export const getTenant = (state: AuthState) => state.tenant;

export const getSponsorTenant = (state: AuthState) => state.isSponsorTenant;

export const getShowSplashScreen = (state: AuthState) => state.showSplashScreen;
