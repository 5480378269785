import { AsyncPipe } from '@angular/common';
import { AfterViewChecked, ChangeDetectorRef, Component, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { isUndefined } from 'lodash-es';
import { DatatableColumn, DatatableSorting, FetchData, RktTableComponent } from '@rocketfinancialcorp/rocket-ui/table';

import { CardMerchant, RequestPageParams } from '@shared/models';
import { CardAccountActions, fromFinancialAccount } from '@shared/store';

@Component({
  selector: 'app-merchant-list',
  templateUrl: './merchant-list.component.html',
  standalone: true,
  imports: [AsyncPipe, RktTableComponent],
})
export class MerchantListComponent implements AfterViewChecked {
  store = inject(Store);

  merchants$ = this.store.select(fromFinancialAccount.selectCardProductMerchantList);

  totalElements$ = this.store.select(fromFinancialAccount.selectCardProductMerchantListTotalElements);

  loading$ = this.store.select(fromFinancialAccount.selectCardProductMerchantListLoading);

  columns: DatatableColumn[] = [
    { name: 'MCC', prop: 'merchantCategoryCode', flexSize: 140 },
    { name: 'Legal Name', prop: 'legalName', flexSize: 240 },
    { name: 'Brand Name', prop: 'brandName', flexSize: 180 },
    { name: 'Doing Business As', prop: 'dbaName', flexSize: 200 },
    { name: 'City', prop: 'city', flexSize: 160 },
    { name: 'State', prop: 'state', flexSize: 100 },
    { name: 'Country', prop: 'countryCode', flexSize: 100 },
  ];

  sortParams: DatatableSorting = { key: 'createdAt', sortProp: 'createdAt', sortDir: 'desc' };

  get activePage(): number {
    const routePage = this.activatedRoute?.snapshot?.queryParams?.page;
    return routePage ? parseInt(routePage, 10) : 0;
  }

  constructor(
    public ref: ChangeDetectorRef,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {}

  ngAfterViewChecked(): void {
    this.ref.detectChanges();
  }

  updateQueryParams(page: RequestPageParams['page']): void {
    if (!isUndefined(page) && this.activePage !== page) {
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: { page: page > 0 ? page : undefined },
        queryParamsHandling: 'merge',
      });
    }
  }

  getMerchants({ page, sortParams }: FetchData): void {
    this.updateQueryParams(page);

    if (sortParams) {
      this.sortParams = sortParams;
    }

    this.store.dispatch(CardAccountActions.loadMerchants());
  }

  onRowClick({ merchantId }: CardMerchant): void {
    this.router.navigate([merchantId], { relativeTo: this.activatedRoute });
  }
}
