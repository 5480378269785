import { AsyncPipe, NgIf, NgTemplateOutlet } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { RktButtonDirective } from '@rocketfinancialcorp/rocket-ui/button';
import { ModalService } from '@rocketfinancialcorp/rocket-ui/modal';
import { ShortIdPipe } from '@rocketfinancialcorp/rocket-ui/table';

import { RestrictionCreateModalComponent } from '@shared/components';
import { CardRestriction, TemplateContext } from '@shared/models';
import { NavigationService } from '@shared/services';
import { CardAccountActions, fromFinancialAccount } from '@shared/store';

import { TemplateContextDirective } from '../../../directives/template-context.directive';
import { AccessControlPipe } from '../../../pipes/access-control.pipe';
import { DateFormatPipe } from '../../../pipes/date-format.pipe';
import { DetailsItemComponent } from '../../details-item/details-item.component';
import { TextWithPlaceholderComponent } from '../../text-with-placeholder/text-with-placeholder.component';
import { CardPagesBreadcrumbsComponent } from '../card-pages-breadcrumbs/card-pages-breadcrumbs.component';

@Component({
  templateUrl: './restriction-details.component.html',
  standalone: true,
  imports: [
    RktButtonDirective,
    NgIf,
    NgTemplateOutlet,
    TemplateContextDirective,
    CardPagesBreadcrumbsComponent,
    TextWithPlaceholderComponent,
    DetailsItemComponent,
    AsyncPipe,
    DateFormatPipe,
    AccessControlPipe,
    ShortIdPipe,
  ],
})
export class RestrictionDetailsComponent implements OnInit, OnDestroy {
  pageClassPrefix = 'restriction-details-';

  restrictionDetailsContext?: TemplateContext<CardRestriction>;

  cardAccountId$ = this.store.select(fromFinancialAccount.selectCardAccountDetailsId);

  restrictionDetails$ = this.store.select(fromFinancialAccount.selectCardAccountRestrictionDetails);

  constructor(
    private navigationService: NavigationService,
    private store: Store,
    private modalService: ModalService,
  ) {
    this.navigationService.dispatchOnNavigationEnd(CardAccountActions.loadRestrictionDetails());
  }

  ngOnInit(): void {
    this.store.dispatch(CardAccountActions.loadCardAccountDetails({}));
  }

  ngOnDestroy(): void {
    this.modalService.dismissAll();
    this.navigationService.ngOnDestroy();
  }

  onEditBtnClick(restriction?: CardRestriction) {
    if (!restriction) {
      return;
    }

    const restrictionModalRef = this.modalService.open(RestrictionCreateModalComponent, {
      className: 'entity-form-modal restriction-form-modal',
    });

    restrictionModalRef.componentInstance.modalInitData({ ...restriction });

    restrictionModalRef.result.finally(() => {
      this.store.dispatch(CardAccountActions.loadRestrictionDetails());
    });
  }
}
