import { formInput, formInputPhone, formRadioGroup, formRow } from '@rocketfinancialcorp/rocket-ui/form';

import { Patterns } from '../patterns';

const createRecipientNameOptions = {
  expressions: {
    'props.disabled': 'model.accountHolderType === "CUSTOMER" && model.customerType === "BUSINESS"',
  },
};

const createRecipientOptions = {
  expressions: {
    'props.disabled': 'model.accountHolderType !== "RECIPIENT"',
  },
};
const createBusinessRecipientOptions = {
  expressions: {
    'props.required': 'model.recipientType === "BUSINESS"',
    hide: "model.recipientType === 'INDIVIDUAL'",
    'props.disabled': 'model.accountHolderType !== "RECIPIENT" ',
  },
};

export const recipientCreateFields = [
  formRow([
    formRadioGroup({
      key: 'recipientType',
      label: 'Recipient Type',
      props: {
        options: [
          { value: 'INDIVIDUAL', label: 'Individual' },
          { value: 'BUSINESS', label: 'Business' },
        ],
      },
    }),
  ]),
  formRow([
    formInput({
      key: 'businessName',
      label: 'Business Name on Account',
      props: { pattern: Patterns.GENERIC_NAME, minLength: 1, maxLength: 100 },
      validation: { messages: { pattern: 'Enter a valid Business Name on Account' } },
      ...createBusinessRecipientOptions,
    }),
  ]),
  formRow([
    formInput({
      key: 'firstName',
      label: 'First name',
      props: { required: true, minLength: 1, maxLength: 40 },
      expressions: {
        'props.disabled': 'model.accountHolderType === "CUSTOMER" && model.customerType === "BUSINESS"',
        'props.required': 'model.recipientType === "INDIVIDUAL"',
      },
    }),
    formInput({ key: 'middleName', label: 'Middle name', props: { minLength: 1, maxLength: 40 }, ...createRecipientNameOptions }),
    formInput({
      key: 'lastName',
      label: 'Last name',
      props: { minLength: 1, maxLength: 40 },
      ...createRecipientNameOptions,
    }),
  ]),
  formRow([
    formInput({
      key: 'email',
      label: 'Email address',
      props: { pattern: Patterns.EMAIL, type: 'email', required: false, minLength: 1, maxLength: 100 },
      validation: {
        messages: {
          pattern: 'Enter a valid email',
        },
      },
      ...createRecipientOptions,
    }),
    formInputPhone({
      key: 'phoneNumber',
      label: 'Mobile Number',
      props: { required: false, minLength: 10, maxLength: 40, countryRestrictions: [], shouldKeepPhoneNumber: true },
      validators: { validation: [{ name: 'isValidPhoneNumber' }] },
      ...createRecipientOptions,
    }),
  ]),
];
