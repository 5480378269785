import { Component } from '@angular/core';
import { ActiveModal } from '@rocketfinancialcorp/rocket-ui/modal';
import { RktIconComponent } from '@rocketfinancialcorp/rocket-ui/icon';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { formRadioGroup, RktFormComponent } from '@rocketfinancialcorp/rocket-ui/form';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { NgIf } from '@angular/common';
import { RktButtonDirective } from '@rocketfinancialcorp/rocket-ui/button';

@Component({
  selector: 'app-financial-account-verification',
  standalone: true,
  imports: [RktIconComponent, FormsModule, ReactiveFormsModule, RktFormComponent, NgIf, RktButtonDirective],
  templateUrl: './financial-account-verification.component.html',
  styleUrl: './financial-account-verification.component.scss',
})
export class FinancialAccountVerificationComponent {
  verificationForm = new FormGroup({});

  formFields: FormlyFieldConfig[] = [
    formRadioGroup({
      key: 'accountVerificationType',
      label: '',
      className: 'single-row-radio-group',
      props: {
        options: [
          { label: 'ACH', value: 'ACH' },
          { label: 'Aggregation with Flinks', value: 'AGGREGATION' },
        ],
        required: true,
      },
    }),
  ];

  formModel = {
    accountVerificationType: undefined,
  };

  submitError = '';

  constructor(public activeModal: ActiveModal) {}

  onSubmit() {
    const { accountVerificationType } = this.formModel;
    this.activeModal.close(accountVerificationType);
  }
}
