import { AsyncPipe, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RktIconComponent } from '@rocketfinancialcorp/rocket-ui/icon';

import { Customer } from '@shared/models';

import { AccessControlPipe } from '../../../pipes/access-control.pipe';

export type CustomerDetailsAction = 'SUSPEND' | 'UNSUSPEND' | 'REMOVE';

@Component({
  selector: 'app-customer-details-header',
  templateUrl: './customer-details-header.component.html',
  standalone: true,
  imports: [NgIf, AsyncPipe, AccessControlPipe, RktIconComponent],
})
export class CustomerDetailsHeaderComponent {
  @Input() customer!: Customer | null;

  @Input() isControlsDisabled = true;

  @Output() btnClicked = new EventEmitter<CustomerDetailsAction>();
}
