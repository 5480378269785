import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';

import { RequestPageParams } from '@shared/models';
import { RecipientService } from '@shared/services';
import { MessagesActions, fromAuth, selectRouteParams } from '@shared/store';
import { ErrorUtils } from '@shared/utils';

import { RecipientActions } from './recipient.actions';

@Injectable()
export class RecipientEffects {
  constructor(
    private actions$: Actions,
    private recipientService: RecipientService,
    private store: Store,
  ) {}

  getRecipients$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RecipientActions.loadRecipients),
      concatLatestFrom(() => [this.store.select(fromAuth.selectBusinessAccountId), this.store.select(selectRouteParams)]),
      mergeMap(
        ([payload, activeBusinessAccountId, routeParams]: [
          payload: { params: RequestPageParams },
          activeBusinessAccountId: string | null,
          routeParams: Params,
        ]) => {
          return this.recipientService.getRecipients(activeBusinessAccountId!, payload.params, routeParams.id).pipe(
            map((response) =>
              RecipientActions.loadRecipientsSuccess({ recipients: response.items, totalElements: response.totalElements }),
            ),
            catchError((error) => {
              return of(RecipientActions.loadRecipientsError({ error }));
            }),
          );
        },
      ),
    );
  });

  loadRecipientsError$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RecipientActions.loadRecipientsError),
      tap({
        next: (loadRecipientsError) => {
          ErrorUtils.catchError('recipientService.getRecipients error', loadRecipientsError.error);
        },
      }),
      switchMap(() => of(MessagesActions.displayError({ message: 'Unable to fetch Recipients.', title: 'Error' }))),
    );
  });
}
