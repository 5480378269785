import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';

import { BusinessAccountService } from '@shared/services';
import { MessagesActions, selectRouteParams, selectUrl } from '@shared/store';
import { ErrorUtils } from '@shared/utils';

import { BusinessAccountBeneficiaryActions } from './business-account-beneficiary.actions';

@Injectable()
export class BusinessAccountBeneficiaryEffects {
  constructor(
    private router: Router,
    private actions$: Actions,
    private businessAccountService: BusinessAccountService,
    private store: Store,
  ) {}

  getBusinessAccountBeneficiary$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BusinessAccountBeneficiaryActions.loadBusinessAccountBeneficiary),
      concatLatestFrom(() => this.store.select(selectRouteParams)),
      mergeMap(([, routeParams]) => {
        return this.businessAccountService.getBusinessAccountBeneficiaryById(routeParams.id!).pipe(
          map((response) => BusinessAccountBeneficiaryActions.loadBusinessAccountBeneficiarySuccess({ beneficiary: response })),
          catchError((error) => {
            return of(BusinessAccountBeneficiaryActions.loadBusinessAccountBeneficiaryError({ error }));
          }),
        );
      }),
    );
  });

  loadBusinessAccountBeneficiaryError$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BusinessAccountBeneficiaryActions.loadBusinessAccountBeneficiaryError),
      tap({
        next: (loadBusinessAccountBeneficiaryError) => {
          ErrorUtils.catchError(
            'businessAccountService.getBusinessAccountBeneficiaryById error',
            loadBusinessAccountBeneficiaryError.error,
          );
        },
      }),
      switchMap(() => of(MessagesActions.displayError({ message: 'Unable to fetch Beneficial Owner.', title: 'Error' }))),
    );
  });

  loadBusinessAccountBeneficiaryErrorRedirect$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(BusinessAccountBeneficiaryActions.loadBusinessAccountBeneficiaryError),
        concatLatestFrom(() => this.store.select(selectUrl)),
        tap({
          next: ([, currentUrl]) => {
            const prevUrl = currentUrl.split('/').slice(0, -2).join('/');
            this.router.navigateByUrl(prevUrl);
          },
        }),
      );
    },
    { dispatch: false },
  );
}
