import { Action, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromRoot from '@shared/store';

import * as fromAggregatedAccountBalance from './aggregated-account-balance.reducer';

export const aggregatedAccountBalanceFeatureKey = 'aggregated-account-balance-store';

export interface AggregatedAccountState {
  [fromAggregatedAccountBalance.aggregatedAccountBalancePageFeatureKey]: fromAggregatedAccountBalance.AggregatedAccountBalanceState;
}

export interface State extends fromRoot.RootState {
  [aggregatedAccountBalanceFeatureKey]: AggregatedAccountState;
}

export const reducers = (state: AggregatedAccountState | undefined, action: Action) => {
  return combineReducers({
    [fromAggregatedAccountBalance.aggregatedAccountBalancePageFeatureKey]: fromAggregatedAccountBalance.reducer,
  })(state, action);
};

export const selectAggregatedAccountState = createFeatureSelector<AggregatedAccountState>(aggregatedAccountBalanceFeatureKey);

export const selectAggregatedAccountBalanceState = createSelector(
  selectAggregatedAccountState,
  (state) => state?.aggregatedAccountBalancePage,
);

export const selectAggregatedAccountBalanceDetails = createSelector(
  selectAggregatedAccountBalanceState,
  fromAggregatedAccountBalance.getAggregatedAccountBalance,
);

export const selectActiveAggregatedAccountBalanceCurrency = createSelector(
  selectAggregatedAccountBalanceState,
  fromAggregatedAccountBalance.getAggregatedAccountBalanceCurrency,
);

export const selectAggregatedBalanceForSelectedCurrency = createSelector(
  selectAggregatedAccountBalanceState,
  fromAggregatedAccountBalance.getAggregatedAccountBalanceForSelectedCurrency,
);

export const selectAggregatedAccountBalanceDetailsLoading = createSelector(
  selectAggregatedAccountBalanceState,
  fromAggregatedAccountBalance.getLoading,
);

export const selectAggregatedAccountBalanceDetailsError = createSelector(
  selectAggregatedAccountBalanceState,
  fromAggregatedAccountBalance.getError,
);
