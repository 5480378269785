import { AsyncPipe, LowerCasePipe, NgIf, TitleCasePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { RktIconComponent } from '@rocketfinancialcorp/rocket-ui/icon';
import { ModalService } from '@rocketfinancialcorp/rocket-ui/modal';

import { AccountVerificationHistoryComponent } from '@shared/components';
import { VerificationStatus } from '@shared/models';

import { FocusRemoveDirective } from '../../directives/focus-remove.directive';
import { DateFormatPipe } from '../../pipes/date-format.pipe';
import { TeamMemberNamePipe } from '../../pipes/team-member-name.pipe';
import { DetailsItemComponent } from '../details-item/details-item.component';
import { TextWithPlaceholderComponent } from '../text-with-placeholder/text-with-placeholder.component';

@Component({
  selector: 'app-details-account-verification',
  templateUrl: './details-account-verification.component.html',
  styleUrls: ['./details-account-verification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TextWithPlaceholderComponent,
    NgIf,
    FocusRemoveDirective,
    DetailsItemComponent,
    AsyncPipe,
    LowerCasePipe,
    TitleCasePipe,
    DateFormatPipe,
    TeamMemberNamePipe,
    RktIconComponent,
  ],
})
export class DetailsAccountVerificationComponent {
  modalService = inject(ModalService);

  @Input() verificationStatus?: VerificationStatus;

  @Input() verificationStatusHistory?: VerificationStatus[];

  showAccountVerificationHistoryModal() {
    const verificationHistoryModalRef = this.modalService.open(AccountVerificationHistoryComponent, {
      className: 'account-verification-history-modal',
    });

    verificationHistoryModalRef.componentInstance.historyItems = this.verificationStatusHistory;
  }
}
