import { AsyncPipe, NgIf } from '@angular/common';
import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { RktButtonDirective } from '@rocketfinancialcorp/rocket-ui/button';
import { RktIconComponent } from '@rocketfinancialcorp/rocket-ui/icon';
import { ModalService } from '@rocketfinancialcorp/rocket-ui/modal';
import { DatatableColumn, RktTableComponent } from '@rocketfinancialcorp/rocket-ui/table';

import { DisputeCreateModalComponent } from '@shared/components';
import { CardDispute } from '@shared/models';
import { NavigationService } from '@shared/services';
import { CardAccountActions, fromFinancialAccount } from '@shared/store';

import { FocusRemoveDirective } from '../../../directives/focus-remove.directive';
import { AccessControlPipe } from '../../../pipes/access-control.pipe';

@Component({
  templateUrl: './dispute-list.component.html',
  standalone: true,
  imports: [RktButtonDirective, NgIf, FocusRemoveDirective, AsyncPipe, AccessControlPipe, RktIconComponent, RktTableComponent],
})
export class DisputeListComponent implements OnDestroy {
  cardAccountId$ = this.store.select(fromFinancialAccount.selectCardAccountDetailsId);

  disputes$ = this.store.select(fromFinancialAccount.selectCardAccountDisputeList);

  totalElements$ = this.store.select(fromFinancialAccount.selectDisputeListTotalElements);

  loading$ = this.store.select(fromFinancialAccount.selectDisputeListLoading);

  columns: DatatableColumn[] = [
    { name: 'Created At', prop: 'logDateTime', fixedSize: 150, isSortable: true, colType: 'date-time' },
    { name: 'Dispute ID', prop: 'disputeCaseNumber', fixedSize: 140 },
    { name: 'Dispute Transaction ID', prop: 'disputedTransactionId', colType: 'short-id', flexSize: 160 },
    { name: 'Dispute Type', prop: 'disputeTypeLabel', flexSize: 160 },
    { name: 'Dispute Reason', prop: 'disputeReasonLabel', flexSize: 200 },
    { name: 'Dispute Status', prop: 'disputeStatusLabel', fixedSize: 140 },
    { name: 'Dispute Amount', prop: 'disputedTransactionAmount', colType: 'amount', flexSize: 140, colAlign: 'right' },
  ];

  constructor(
    private navigationService: NavigationService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private store: Store,
    private modalService: ModalService,
  ) {
    this.navigationService.dispatchOnNavigationEnd(CardAccountActions.loadDisputes());
  }

  ngOnDestroy() {
    this.navigationService.ngOnDestroy();
  }

  onRowClick({ disputeCaseNumber }: CardDispute): void {
    this.router.navigate([disputeCaseNumber], { relativeTo: this.activatedRoute });
  }

  onCreateDisputeBtnClick({ financialAccountId }: { financialAccountId?: string }) {
    if (!financialAccountId) {
      return;
    }

    const disputeModalRef = this.modalService.open(DisputeCreateModalComponent, { className: 'entity-form-modal' });

    disputeModalRef.componentInstance.modalInitData({ financialAccountId });

    disputeModalRef.result.finally(() => {
      this.store.dispatch(CardAccountActions.loadDisputes());
    });
  }
}
