<div class="details-page-header-wrapper" *ngIf="!loading; else loadingPlaceholder">
  <div class="page-header">
    <div class="details-page-back-title">
      <a [routerLink]="[pageReturnURL]" class="details-page-back-btn">
        <rkt-icon name="arrow-left" class="details-page-back-btn-icon"></rkt-icon>
      </a>
      <h1 class="details-page-title">
        {{ statement?.statementStartDate | dateFormat: 'MMM dd, yyyy' }} -
        {{ statement?.statementEndDate | dateFormat: 'MMM dd, yyyy' }}
      </h1>
    </div>
    <app-breadcrumbs [itemList]="breadcrumbList"></app-breadcrumbs>
  </div>
</div>

<ng-template #loadingPlaceholder>
  <app-details-page-header-placeholder [pageReturnURL]="pageReturnURL" [withStatus]="false"></app-details-page-header-placeholder>
</ng-template>

<div class="statement-details">
  <div class="statement-info-wrapper">
    <div class="statement-info">
      <div class="statement-info-title">
        <app-text-with-placeholder [showPlaceholder]="loading" [darkBg]="true"
          >{{ statement?.accountType | titlecase }} Account Statement</app-text-with-placeholder
        >
      </div>

      <div class="statement-number-period-section">
        <div class="statement-info-item">
          <div class="statement-info-label">
            <app-text-with-placeholder [showPlaceholder]="loading" [darkBg]="true">Account Number</app-text-with-placeholder>
          </div>
          <div class="statement-info-value">
            <app-text-with-placeholder [showPlaceholder]="loading" [darkBg]="true">{{
              statement?.accountNumber
            }}</app-text-with-placeholder>
          </div>
        </div>

        <div class="statement-info-item">
          <div class="statement-info-label">
            <app-text-with-placeholder [showPlaceholder]="loading" [darkBg]="true">Statement Period</app-text-with-placeholder>
          </div>
          <div class="statement-info-value">
            <app-text-with-placeholder [showPlaceholder]="loading" [darkBg]="true"
              >{{ statement?.statementStartDate | dateFormat: 'MMM dd, yyyy' }} -
              {{ statement?.statementEndDate | dateFormat: 'MMM dd, yyyy' }}</app-text-with-placeholder
            >
          </div>
        </div>
      </div>

      <div class="statement-balances-section">
        <div class="statement-info-item">
          <div class="statement-info-label">
            <app-text-with-placeholder [showPlaceholder]="loading" [darkBg]="true"
              >Opening balance on {{ statement?.statementStartDate | dateFormat: 'MMM dd, yyyy' }}</app-text-with-placeholder
            >
          </div>
          <div class="statement-info-value">
            <app-text-with-placeholder [showPlaceholder]="loading" [darkBg]="true">{{
              (statement?.openingBalance | number: '1.2-2') || '' | mask: 'separator' : { thousandSeparator: ',' } | prefix: '$ '
            }}</app-text-with-placeholder>
          </div>
        </div>
        <div class="statement-info-item">
          <div class="statement-info-label">
            <app-text-with-placeholder [showPlaceholder]="loading" [darkBg]="true">Total Credit</app-text-with-placeholder>
          </div>
          <div class="statement-info-value">
            <app-text-with-placeholder [showPlaceholder]="loading" [darkBg]="true">{{
              (statement?.totalCredits | number: '1.2-2') || '' | mask: 'separator' : { thousandSeparator: ',' } | prefix: '$ '
            }}</app-text-with-placeholder>
          </div>
        </div>
        <div class="statement-info-item">
          <div class="statement-info-label">
            <app-text-with-placeholder [showPlaceholder]="loading" [darkBg]="true">Total Debit (includes Fees)</app-text-with-placeholder>
          </div>
          <div class="statement-info-value">
            <app-text-with-placeholder [showPlaceholder]="loading" [darkBg]="true">{{
              (statement?.totalDebits | number: '1.2-2') || '' | mask: 'separator' : { thousandSeparator: ',' } | prefix: '$ '
            }}</app-text-with-placeholder>
          </div>
        </div>
      </div>

      <div class="statement-closing-balance-section">
        <div class="statement-info-item">
          <div class="statement-info-label">
            <app-text-with-placeholder [showPlaceholder]="loading" [darkBg]="true"
              >Closing balance on {{ statement?.statementEndDate | dateFormat: 'MMM dd, yyyy' }}</app-text-with-placeholder
            >
          </div>
          <div class="statement-info-value">
            <app-text-with-placeholder [showPlaceholder]="loading" [darkBg]="true">{{
              (statement?.closingBalance | number: '1.2-2') || '' | mask: 'separator' : { thousandSeparator: ',' } | prefix: '$ '
            }}</app-text-with-placeholder>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="account-holder-info-wrapper">
    <div class="account-holder-info">
      <div class="account-holder-title">
        <app-text-with-placeholder [showPlaceholder]="loading">Account Holder Details</app-text-with-placeholder>
      </div>
      <div class="account-holder-info-item">
        <div class="account-holder-info-label">
          <app-text-with-placeholder [showPlaceholder]="loading">Account Holder Name</app-text-with-placeholder>
        </div>
        <div class="account-holder-info-value">
          <app-text-with-placeholder [showPlaceholder]="loading">{{ statement?.accountHolderDetails?.name }}</app-text-with-placeholder>
        </div>
      </div>
      <div class="account-holder-info-item">
        <div class="account-holder-info-label">
          <app-text-with-placeholder [showPlaceholder]="loading">Physical Address</app-text-with-placeholder>
        </div>
        <div class="account-holder-info-value no-value" *ngIf="!statement?.accountHolderDetails?.addressLine1">
          <app-text-with-placeholder [showPlaceholder]="loading">N/A</app-text-with-placeholder>
        </div>

        <div class="account-holder-info-value" *ngIf="statement?.accountHolderDetails?.addressLine1">
          <div>{{ statement?.accountHolderDetails?.addressLine1 }}</div>
          <div>{{ statement?.accountHolderDetails?.addressLine2 }}</div>
          <div>{{ statement?.accountHolderDetails?.city }}, {{ statement?.accountHolderDetails?.state }}</div>
          <div>{{ statement?.accountHolderDetails?.postalCode }} {{ statement?.accountHolderDetails?.country }}</div>
        </div>
      </div>
    </div>

    <div class="statement-created-at">
      <app-text-with-placeholder [showPlaceholder]="loading" [darkBg]="true"
        >Statement Created: {{ statement?.statementCreatedAt | dateFormat: 'MM/dd/yy' }}</app-text-with-placeholder
      >
    </div>
  </div>
</div>

<rkt-table
  [rows]="statement?.accountActivities || []"
  [columns]="columns"
  [count]="totalElements"
  [limit]="10"
  [rowHeight]="64"
  [loadingIndicator]="loading"
  [isItemsAlignCenter]="true"
  [isClickable]="false"
  [externalPaging]="false"
  [placeholderItemsCount]="6"
  [messages]="{ emptyMessage: 'No Account Activities found.' }"
  emptyValuePlaceholder="--"
  dateFormat="MM/dd/yy"
></rkt-table>
