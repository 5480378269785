import { AsyncPipe, NgClass, NgFor, NgIf, TitleCasePipe } from '@angular/common';
import { AfterViewChecked, ChangeDetectorRef, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { isUndefined } from 'lodash-es';
import { RktIconComponent } from '@rocketfinancialcorp/rocket-ui/icon';
import { DatatableColumn, DatatableSorting, RktTableComponent, SplitPipe } from '@rocketfinancialcorp/rocket-ui/table';

import { FilterMenuComponent } from '@shared/components';
import { TemplateContextDirective } from '@shared/directives';
import { FilterField, FilterValues, MultiLegTransactionListItem, RequestPageParams, TemplateContext } from '@shared/models';
import { AmountPipe, NotAvailablePipe } from '@shared/pipes';
import { MultiLegTransactionActions, selectMultiLegTransactionsActiveFiltersCount, transactionFeature } from '@shared/store';

interface MultiLegTransactionDetailsRowTemplateContext extends TemplateContext<unknown> {
  row: MultiLegTransactionListItem;
}

@Component({
  templateUrl: './multi-leg-transaction-list.component.html',
  standalone: true,
  imports: [
    NgIf,
    TemplateContextDirective,
    NgFor,
    NgClass,
    AsyncPipe,
    TitleCasePipe,
    NotAvailablePipe,
    AmountPipe,
    RktIconComponent,
    RktTableComponent,
    FilterMenuComponent,
    SplitPipe,
  ],
})
export class MultiLegTransactionListComponent implements AfterViewChecked {
  detailsRowTemplateContext?: MultiLegTransactionDetailsRowTemplateContext;

  filters: FilterField[] = [
    {
      name: 'date',
      displayName: 'Date',
      type: 'DATE',
      options: [
        { label: 'All (15 month max)', value: undefined },
        { label: 'Default: 30 days', value: 'DEFAULT' },
        { label: 'Today', value: 'TODAY' },
        { label: 'Yesterday', value: 'YESTERDAY' },
        { label: 'This week', value: 'THIS_WEEK' },
        { label: 'This month', value: 'THIS_MONTH' },
        { label: 'Custom range', value: 'CUSTOM' },
      ],
      defaultValue: 'DEFAULT',
      props: {
        maxMonths: '15',
      },
    },
    {
      name: 'maskedDebitAccountNumber',
      displayName: 'From Account',
      type: 'INPUT',
      props: {
        label: 'Account No.',
        maxLength: '20',
        validationPattern: '[0-9*]*',
        validationPatternMessage: 'Account Number only allow numbers (digits) and asterisk',
      },
    },
    {
      name: 'maskedCreditAccountNumber',
      displayName: 'To Account',
      type: 'INPUT',
      props: {
        label: 'Account No.',
        maxLength: '20',
        validationPattern: '[0-9*]*',
        validationPatternMessage: 'Account Number only allow numbers (digits) and asterisk',
      },
    },
    {
      name: 'name',
      displayName: 'Name',
      type: 'INPUT',
    },
    {
      name: 'amount',
      displayName: 'Amount',
      type: 'AMOUNT',
    },
    {
      name: 'status',
      displayName: 'Status',
      type: 'RADIO',
      options: [
        { label: 'All', value: undefined },
        { label: 'Cancelled', value: 'CANCELLED' },
        { label: 'Complete', value: 'COMPLETE' },
        { label: 'Failed', value: 'FAILED' },
        { label: 'Pending', value: 'PENDING' },
        { label: 'Processing', value: 'PROCESSING' },
      ],
    },
    {
      name: 'stage',
      displayName: 'Stage',
      type: 'RADIO',
      options: [
        { label: 'All', value: undefined },
        { label: 'Cancelling', value: 'CANCELLING' },
        { label: 'Disbursing', value: 'DISBURSING' },
        { label: 'Funding', value: 'FUNDING' },
      ],
    },
  ];

  activeFilters$ = this.store.select(transactionFeature.selectMultiLegTransactionsActiveFilters);

  activeFiltersCount$ = this.store.select(selectMultiLegTransactionsActiveFiltersCount);

  multiLegTransactions$ = this.store.select(transactionFeature.selectMultiLegTransactions);

  totalElements$ = this.store.select(transactionFeature.selectMultiLegTransactionsTotalElements);

  isLoading$ = this.store.select(transactionFeature.selectMultiLegTransactionsLoading);

  columns: DatatableColumn[] = [
    { name: 'Created At', prop: 'createdAt', fixedSize: 130, isSortable: true, colType: 'date-time' },
    { name: 'MLT ID', prop: 'id', colType: 'short-id', fixedSize: 142 },
    { name: 'Move From', prop: 'moveFrom', colType: 'two-line-col', flexSize: 300 },
    { name: '', prop: 'debitLegsCount', fixedSize: 50, isSortable: false, colAlign: 'center' },
    { name: 'Move To', prop: 'moveTo', colType: 'two-line-col', flexSize: 300 },
    { name: '', prop: 'creditLegsCount', fixedSize: 50, isSortable: false, colAlign: 'center' },
    { name: 'Amount', prop: 'totalAmount', flexSize: 151, isSortable: true, colType: 'amount', colAlign: 'right' },
    {
      name: 'Stage',
      prop: 'stage',
      fixedSize: 118,
      isSortable: true,
      colType: 'status-tag',
      colAlign: 'right',
    },
    {
      name: 'Status',
      prop: 'status',
      sortProp: 'status',
      fixedSize: 140,
      isSortable: true,
      colType: 'status-tag',
      colAlign: 'right',
    },
  ];

  sortParams: DatatableSorting = { key: 'createdAt', sortProp: 'createdAt', sortDir: 'desc' };

  searchString?: string;

  activePage = 0;

  constructor(
    public ref: ChangeDetectorRef,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private store: Store,
  ) {
    this.activePage = parseInt(this.activatedRoute?.snapshot?.queryParams?.page ?? '0', 10);
  }

  ngAfterViewChecked(): void {
    this.ref.detectChanges();
  }

  updateQueryParams(page: RequestPageParams['page']): void {
    if (!isUndefined(page) && this.activePage !== page) {
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: { page: page > 0 ? page : undefined },
        queryParamsHandling: 'merge',
      });

      if (this.activePage !== page) {
        this.activePage = page;
      }
    }
  }

  onSearch(searchString?: string): void {
    if (!searchString) {
      this.onSearchReset();
      return;
    }
    this.searchString = searchString;
    this.getMultiLegTransactions({ page: 0 });
  }

  onSearchReset(): void {
    if (!this.searchString) {
      return;
    }
    this.searchString = undefined;
    this.getMultiLegTransactions({ page: 0 });
  }

  getMultiLegTransactions({ page, sortParams }: RequestPageParams): void {
    this.updateQueryParams(page);

    if (sortParams) {
      this.sortParams = sortParams;
    }

    this.store.dispatch(
      MultiLegTransactionActions.load({
        params: {
          page: page ?? 0,
          searchString: this.searchString,
          sortParams: sortParams ?? this.sortParams,
        },
      }),
    );
  }

  onRowClick({ id }: MultiLegTransactionListItem): void {
    this.router.navigateByUrl(`/app/transactions/multi-leg-transactions/${id}`);
  }

  applyFilters(filterValues: FilterValues): void {
    this.store.dispatch(MultiLegTransactionActions.setFilters({ filters: { ...filterValues } }));
    this.getMultiLegTransactions({ page: 0 });
  }
}
