import { NgIf } from '@angular/common';
import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { Subject, finalize, takeUntil } from 'rxjs';
import { RktButtonDirective } from '@rocketfinancialcorp/rocket-ui/button';
import { RktFormComponent, formInput, formInputPlace, formRow } from '@rocketfinancialcorp/rocket-ui/form';
import { RktIconComponent } from '@rocketfinancialcorp/rocket-ui/icon';
import { ActiveModal } from '@rocketfinancialcorp/rocket-ui/modal';
import { SplitPipe } from '@rocketfinancialcorp/rocket-ui/table';

import { MESSAGE } from '@shared/constants';
import { Address, FinancialAccountSelectionListItem, FormModel } from '@shared/models';
import { FinancialAccountService } from '@shared/services';
import { ErrorUtils } from '@shared/utils';

import { NotAvailablePipe } from '../../../pipes/not-available.pipe';

@Component({
  selector: 'app-financial-account-address-form-modal',
  templateUrl: './financial-account-address-form-modal.component.html',
  standalone: true,
  imports: [RktButtonDirective, RktIconComponent, NgIf, FormsModule, ReactiveFormsModule, RktFormComponent, NotAvailablePipe, SplitPipe],
})
export class FinancialAccountAddressFormModalComponent implements AfterViewChecked, OnDestroy {
  financialAccount!: FinancialAccountSelectionListItem;

  submitError = '';

  isLoading = false;

  addressForm = new FormGroup({});

  addressFormModel: FormModel<Address> = {};

  addressFormFields: FormlyFieldConfig[] = [
    formRow([
      formInputPlace({
        key: 'addressLine1',
        label: 'Address 1',
        props: {
          minLength: 1,
          maxLength: 50,
          required: true,
        },
      }),
    ]),
    formRow([formInput({ key: 'addressLine2', label: 'Address 2', props: { minLength: 1, maxLength: 50 } })]),
    formRow([
      formInput({ key: 'city', label: 'City', className: 'city-field', props: { minLength: 1, maxLength: 25, required: true } }),
      formInput({
        key: 'state',
        label: 'State',
        className: 'state-field',
        props: { minLength: 2, maxLength: 3, required: true },
        validators: { validation: [{ name: 'isValidStateCode' }] },
      }),
      formInput({
        key: 'postalCode',
        label: 'Zip Code',
        className: 'postal-code-field',
        props: { minLength: 1, maxLength: 10, required: true },
      }),
    ]),
    formRow([
      formInput({
        key: 'country',
        label: 'Country',
        parsers: [(value?: string) => value?.toUpperCase()],
        props: { minLength: 3, maxLength: 3, required: true },
        validators: { validation: [{ name: 'isValidCountryCode' }] },
        validation: {
          messages: {
            minLength: 'Must be 3 characters',
          },
        },
      }),
    ]),
  ];

  private destroy$ = new Subject<void>();

  constructor(
    public activeModal: ActiveModal,
    private ref: ChangeDetectorRef,
    private financialAccountService: FinancialAccountService,
  ) {}

  ngAfterViewChecked(): void {
    this.ref.detectChanges();
  }

  public ngOnDestroy(): void {
    this.addressForm.markAsUntouched();
    this.destroy$.next();
    this.destroy$.complete();
  }

  modalInitData({ financialAccount }: { financialAccount: FinancialAccountSelectionListItem }): void {
    this.financialAccount = financialAccount;
  }

  onAddressChange(): void {
    this.submitError = '';
    this.addressForm.get('addressLine1')?.markAsTouched();
    this.ref.markForCheck();
  }

  onSave(): void {
    const { addressLine1, addressLine2, city, state, country, postalCode } = this.addressFormModel;

    if (!addressLine1 || !postalCode || !country || !city || !state) {
      return;
    }

    this.submitError = '';
    this.isLoading = true;

    this.financialAccountService
      .updateFinancialAccountBillingAddress({
        financialAccountId: this.financialAccount.id,
        address: {
          addressLine1,
          addressLine2,
          city,
          state,
          country,
          postalCode,
        },
      })
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => (this.isLoading = false)),
      )
      .subscribe({
        next: () => {
          this.activeModal.close({
            ...this.financialAccount,
            bankAccount: {
              ...this.financialAccount.bankAccount,
              billingAddress: { addressLine1, addressLine2, city, state, country, postalCode },
            },
          });
        },

        error: (error) => {
          this.submitError = MESSAGE.UNKNOWN_ERROR;
          ErrorUtils.catchError('financialAccountService.updateFinancialAccountBillingAddress error', error);
        },
      });
  }
}
