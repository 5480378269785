import { NgFor, NgIf } from '@angular/common';
import { AfterViewChecked, ChangeDetectorRef, Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { endOfMonth, isBefore, startOfMonth } from 'date-fns';
import { isUndefined } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RktButtonDirective } from '@rocketfinancialcorp/rocket-ui/button';
import { RktFormComponent } from '@rocketfinancialcorp/rocket-ui/form';
import { RktIconComponent } from '@rocketfinancialcorp/rocket-ui/icon';
import { FetchData } from '@rocketfinancialcorp/rocket-ui/table';

import { FindReportDateFormFields, getFindReportTypeFields } from '@modules/insights/components/find-report-page/form-field';
import { FilterValues, FormModel, InsightDocumentList, InsightDocumentListItem, RequestPageParams } from '@shared/models';
import { ReportService } from '@shared/services';
import { ErrorUtils, groupReportByDate } from '@shared/utils';

import { ReportListItemComponent } from '../../../../shared/components/insight/report-list-item/report-list-item.component';
import { PaginationComponent } from '../../../../shared/components/pagination/pagination.component';

@Component({
  selector: 'app-find-report-page',
  templateUrl: './find-report-page.component.html',
  styleUrls: ['./find-report-page.component.scss'],
  standalone: true,
  imports: [RktButtonDirective, RktFormComponent, NgIf, NgFor, ReportListItemComponent, PaginationComponent, RktIconComponent],
})
export class FindReportPageComponent implements AfterViewChecked {
  loading!: boolean;
  totalPages = 0;
  totalElements = 0;
  createdAtFrom!: Date;
  createdAtTo!: Date;
  filteredReports!: InsightDocumentListItem[];
  activeFilters: FilterValues = {};

  formFields: FormlyFieldConfig[] = getFindReportTypeFields(this.reportService);
  dateFormFields = FindReportDateFormFields;
  findReportForm = new FormGroup({});
  formModel: FormModel<{
    reportType: string;
    yearFrom: string;
    monthFrom: string;
    yearTo: string;
    monthTo: string;
  }> = {};

  get activePage(): number {
    const routePage = this.activatedRoute?.snapshot?.queryParams?.page;
    return routePage ? parseInt(routePage, 10) : 0;
  }

  private destroy$ = new Subject<void>();

  constructor(
    public ref: ChangeDetectorRef,
    private reportService: ReportService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {}

  ngAfterViewChecked(): void {
    this.ref.detectChanges();
  }

  updateQueryParams(page?: RequestPageParams['page']): void {
    if (!isUndefined(page) && this.activePage !== page) {
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: { page: page > 0 ? page : undefined },
        queryParamsHandling: 'merge',
      });
    }
  }

  getInsightReports({ page }: FetchData) {
    this.loading = true;

    this.updateQueryParams(page);

    this.reportService
      .getInsightReports({
        page: page ?? 0,
        size: 10,
        activeFilters: this.activeFilters,
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (reportList) => {
          this.onFetchComplete(reportList);
        },
        error: (error) => {
          this.onFetchComplete();
          ErrorUtils.catchError('reportService.getInsightReports error', error);
        },
      });
  }

  onFindReport() {
    this.findReportForm.markAllAsTouched();

    if (this.findReportForm.invalid) {
      return;
    }

    this.activeFilters = {
      reportType: this.formModel.reportType,
    };

    if (this.isValidDateRange()) {
      this.activeFilters = {
        ...this.activeFilters,
        createdAtFrom: this.createdAtFrom.toISOString(),
        createdAtTo: this.createdAtTo.toISOString(),
      };
    }

    this.getInsightReports({ page: 0 });
  }

  private isValidDateRange(): boolean {
    const { yearFrom, monthFrom, yearTo, monthTo } = this.formModel;

    if (yearFrom && !isUndefined(monthFrom) && yearTo && !isUndefined(monthTo)) {
      this.createdAtFrom = startOfMonth(new Date(parseInt(yearFrom, 10), parseInt(monthFrom, 10)));
      this.createdAtTo = endOfMonth(new Date(parseInt(yearTo, 10), parseInt(monthTo, 10)));
    }

    return isBefore(this.createdAtFrom, this.createdAtTo);
  }

  private onFetchComplete(reportList?: InsightDocumentList) {
    const { items = [], totalElements = 0, totalPages = 0 } = reportList ?? {};
    this.filteredReports = groupReportByDate(items);
    this.totalElements = totalElements;
    this.totalPages = totalPages;
    this.loading = false;
  }

  viewReports() {
    this.router.navigate(['app/insights/documents']);
  }

  closeModal() {
    this.router.navigate(['app/insights']);
  }
}
