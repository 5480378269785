import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { isNil } from 'lodash-es';
import { Subject, takeUntil } from 'rxjs';
import { ModalService } from '@rocketfinancialcorp/rocket-ui/modal';
import { DatatableColumn, DatatableSorting, RktTableComponent } from '@rocketfinancialcorp/rocket-ui/table';

import { FileDownloadSelectComponent, ViewPdfDocumentComponent } from '@shared/components';
import { FilterValues, InsightDocument, InsightDocumentList, RequestPageParams } from '@shared/models';
import { NotificationService, ReportService } from '@shared/services';
import { ErrorUtils } from '@shared/utils';

@Component({
  selector: 'app-financial-account-statements',
  templateUrl: './financial-account-statements.component.html',
  standalone: true,
  imports: [RktTableComponent],
})
export class FinancialAccountStatementsComponent implements AfterViewChecked, OnDestroy {
  accountStatements: InsightDocument[] = [];

  loading = false;

  totalElements = 0;

  totalPages = 0;

  activeFilters: FilterValues = {};

  columns: DatatableColumn[] = [
    { name: 'Created At', prop: 'createdAt', colType: 'date-time', fixedSize: 150 },
    { name: 'Statement ID', prop: 'id', fixedSize: 150, colType: 'short-id' },
    { name: 'Name', prop: 'name', flexSize: 1 },
    {
      name: '',
      prop: 'downloadAvailable',
      fixedSize: 150,
      colType: 'button',
      iconName: 'download',
      colAlign: 'center',
      colTitle: '',
    },
  ];

  page = 0;

  sortParams: DatatableSorting = { key: 'createdAt', sortProp: 'createdAt', sortDir: 'desc' };

  emptyListMessage = 'No Financial Account Statements found.';

  get activePage(): number {
    return this.page;
  }

  private destroy$ = new Subject<void>();

  constructor(
    public ref: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    private notificationService: NotificationService,
    private modalService: ModalService,
    private reportService: ReportService,
  ) {}

  ngAfterViewChecked(): void {
    this.ref.detectChanges();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onReportStatementAction(statementDocument: InsightDocument, documentType: string, downloadDocument: boolean): void {
    const { id, pdfStatementPath, name } = statementDocument;
    if (id && pdfStatementPath) {
      this.reportService.getInsightReportPreSignedURL(id, documentType).subscribe({
        next: (response) => {
          const { signedJsonUrl } = response;

          if (downloadDocument) {
            window.open(signedJsonUrl, '_blank');
          } else {
            this.previewDocument(name, signedJsonUrl);
          }
        },
        error: (error) => {
          this.notificationService.displayError('Unable to preview document.');
          ErrorUtils.catchError('reportService.getPreSignedURL error', error);
        },
      });
    } else {
      this.notificationService.displayError('Unable to preview document.');
    }
  }

  previewDocument(documentName: string | undefined, documentURL: string) {
    const previewDocumentRef = this.modalService.open(ViewPdfDocumentComponent, {
      className: 'entity-form-modal preview-document-modal',
      size: 'lg',
    });

    previewDocumentRef.componentInstance.modalInitData({
      documentName,
      documentURL,
      containerHeight: '40rem',
    });
  }

  onColButtonClick({ colButton, row }: { colButton: string; row: InsightDocument }): void {
    const fileDownloadModalRef = this.modalService.open(FileDownloadSelectComponent, {
      className: 'entity-form-modal',
      size: 'sm',
    });

    fileDownloadModalRef.componentInstance.modalInitData(row);

    fileDownloadModalRef.result.then(
      (response: string[]) => {
        if (response && colButton) {
          response.forEach((fileType: string) => {
            setTimeout(() => {
              this.onReportStatementAction(row, fileType.toUpperCase(), true);
            }, 100);
          });
        }
      },
      () => false,
    );
  }

  getFinancialAccountStatements({ page, size }: RequestPageParams): void {
    this.loading = true;

    if (!isNil(page) && this.activePage !== page) {
      this.page = page;
    }

    const { parent } = this.activatedRoute;

    this.reportService
      .getInsightStatements({
        page,
        size,
        financialAccountId: parent!.snapshot.paramMap.get('id')!,
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response) => {
          this.emptyListMessage = 'No Financial Account Statements found.';
          this.onFetchComplete(response);
        },
        error: (error) => {
          this.emptyListMessage = 'Unable to fetch Financial Account Statements.';
          this.onFetchComplete();
          ErrorUtils.catchError('financialAccountService.getFinancialAccountStatements error', error);
        },
      });
  }

  onFetchComplete(response?: InsightDocumentList): void {
    const { items = [], totalElements = 0 } = response ?? {};
    this.accountStatements = items;
    this.totalElements = totalElements;
    this.loading = false;
  }
}
