import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { isUndefined } from 'lodash-es';
import { Subject, takeUntil } from 'rxjs';
import { formRow, formSelect, RktFormComponent } from '@rocketfinancialcorp/rocket-ui/form';
import { RktIconComponent } from '@rocketfinancialcorp/rocket-ui/icon';
import { FetchData, RktTableSearchComponent } from '@rocketfinancialcorp/rocket-ui/table';

import { ReportListItemComponent } from '../../../../shared/components/insight/report-list-item/report-list-item.component';
import { PaginationComponent } from '../../../../shared/components/pagination/pagination.component';
import { FilterValues, FormModel, InsightDocumentList, InsightDocumentListItem, RequestPageParams } from '@shared/models';
import { AccessControlPipe } from '@shared/pipes';
import { NotificationService, ReportService } from '@shared/services';
import { InsightDocumentActions, fromInsightDocument } from '@shared/store';
import { ErrorUtils, activeFilters, groupReportByDate } from '@shared/utils';
import { FormlyFieldConfig } from '@ngx-formly/core';

@Component({
  selector: 'app-insight-report-page',
  templateUrl: './insight-report-page.component.html',
  styleUrls: ['./insight-report-page.component.scss'],
  standalone: true,
  imports: [
    RktFormComponent,
    NgIf,
    NgFor,
    ReportListItemComponent,
    PaginationComponent,
    AsyncPipe,
    AccessControlPipe,
    RktTableSearchComponent,
    RktIconComponent,
  ],
})
export class InsightReportPageComponent implements OnInit {
  insightUnreadDocument$ = this.store.select(fromInsightDocument.selectInsightUnreadDocuments);

  filteredReports!: InsightDocumentListItem[];

  loading!: boolean;

  totalElements = 0;

  searchString?: string;

  totalPages = 0;

  activeFilters: FilterValues = {};

  document?: ArrayBuffer;

  reportFilterForm = new FormGroup({});

  formModel: FormModel<{
    reportType: string;
    dateRange: string;
  }> = {};

  formFields: FormlyFieldConfig[] = [
    formRow([
      formSelect({
        key: 'reportType',
        label: 'Type',
        className: 'report-dropdown',
        defaultValue: 'ALL',
        props: {
          options: [],
          placeholder: 'Select report type',
          required: false,
        },
      }),
      formSelect({
        key: 'dateRange',
        label: 'Date Range',
        defaultValue: 'THIS_WEEK',
        props: {
          options: [
            { label: 'Today', value: 'TODAY' },
            { label: 'Yesterday', value: 'YESTERDAY' },
            { label: 'This Week', value: 'THIS_WEEK' },
            { label: 'This Month', value: 'THIS_MONTH' },
            { label: 'This Year', value: 'THIS_YEAR' },
          ],
          placeholder: 'Select date',
          required: false,
        },
      }),
    ]),
  ];

  get activePage(): number {
    const routePage = this.activatedRoute?.snapshot?.queryParams?.page;
    return routePage ? parseInt(routePage, 10) : 0;
  }

  private destroy$ = new Subject<void>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private reportService: ReportService,
    private router: Router,
    private store: Store,
    private notificationService: NotificationService,
  ) {}

  ngOnInit(): void {
    this.initializeReportTypeList();
    this.activeFilters = activeFilters({ reportType: 'ALL', dateRange: 'THIS_WEEK' });
    this.getInsightReports({ page: 0 });
    this.updateDocumentNotificationStatus();
  }

  updateQueryParams(page?: RequestPageParams['page']): void {
    if (!isUndefined(page) && this.activePage !== page) {
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: { page: page > 0 ? page : undefined },
        queryParamsHandling: 'merge',
      });
    }
  }

  getInsightReports({ page }: FetchData) {
    this.loading = true;

    this.updateQueryParams(page);

    this.reportService
      .getInsightReports({
        page: page ?? 0,
        size: 10,
        searchString: this.searchString,
        activeFilters: this.activeFilters,
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (reportList) => {
          this.onFetchComplete(reportList);
        },
        error: (error) => {
          this.onFetchComplete();
          ErrorUtils.catchError('reportService.getInsightReports error', error);
        },
      });
  }

  onFetchComplete(reportList?: InsightDocumentList) {
    const { items = [], totalElements = 0, totalPages = 0 } = reportList ?? {};
    this.filteredReports = groupReportByDate(items);
    this.totalElements = totalElements;
    this.totalPages = totalPages;
    this.loading = false;
  }

  onSearch(event: string): void {
    if (!event) {
      this.onSearchReset();
      return;
    }
    this.searchString = event;
    this.getInsightReports({ page: 0 });
  }

  onSearchReset(): void {
    if (!this.searchString) {
      return;
    }

    this.searchString = undefined;
    this.getInsightReports({ page: 0 });
  }

  onModelChange() {
    this.activeFilters = { ...this.formModel };
    this.getInsightReports({ page: 0 });
  }

  navigateToScheduledReport() {
    this.router.navigateByUrl('app/insights/documents/scheduled-report');
  }

  updateDocumentNotificationStatus() {
    this.insightUnreadDocument$.subscribe({
      next: (documentList) => {
        const unreadDocs = documentList.map((document) => document.section);
        if (unreadDocs.includes('REPORTS')) {
          this.reportService.updateInsightReportStatus('REPORTS').subscribe({
            next: () => {
              this.store.dispatch(InsightDocumentActions.loadInsightDocumentHistory());
            },
          });
        }
      },
    });
  }

  private initializeReportTypeList() {
    this.reportService
      .getInsightReportTypes()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (reportTypes) => {
          const reportOptions = [
            {
              label: 'All',
              value: 'ALL',
            },
            ...reportTypes,
          ];

          if (this.formFields && this.formFields[0]?.fieldGroup) {
            if (this.formFields[0]?.fieldGroup[0]?.props) {
              this.formFields[0].fieldGroup[0].props.options = reportOptions;
            }
          }
        },
        error: (err) => {
          this.notificationService.displayError('Unable to fetch report types.');
          ErrorUtils.catchError('reportService.getReportTypes error', err);
        },
      });
  }
}
