import { createActionGroup, emptyProps, props } from '@ngrx/store';

import {
  Address,
  AttachmentCreateModel,
  DeliverySpeedOption,
  ErrorMessage,
  FilterValues,
  FinancialAccountSelectionListItem,
  FinancialAccountSelectionListRequest,
  MultiLegEnabledSolutionPermissions,
  MultiLegPaymentReason,
  MultiLegSolutionConfig,
  MultiLegSolutionConfigSolutionName,
  MultiLegTransactionAchDetailsModel,
  MultiLegTransactionProcessingPriority,
  MultiLegTransactionScheduler,
  MultiLegTransactionWireDetailsModel,
  TransactionDebitAuthorization,
} from '@shared/models';
import { CurrencyCode } from '@shared/utils';

export const TransactionFormActions = createActionGroup({
  source: 'Create/Edit Transaction Form',
  events: {
    'Set Idempotency Time Constraint': props<{ timestamp: string | null }>(),
    'Set Amount': props<{ amount: string | null }>(),
    'Set Amount Option': props<{ amountOption: 'EXACT_AMOUNT' | 'PERCENTAGE' | 'EXACT_AMOUNT_LEFT' }>(),
    'Set Move From': props<{ financialAccount: FinancialAccountSelectionListItem | null }>(),
    'Set Move To': props<{ financialAccount: FinancialAccountSelectionListItem | null }>(),
    'Update Move From': props<{ financialAccount: FinancialAccountSelectionListItem | null }>(),
    'Update Move To': props<{ financialAccount: FinancialAccountSelectionListItem | null }>(),
    'Set Move From Method': props<{ method: MultiLegSolutionConfigSolutionName | null }>(),
    'Set Move To Method': props<{ method: MultiLegSolutionConfigSolutionName | null }>(),
    'Set Move From Payment Reason': props<{ paymentReason: string | null }>(),
    'Set Move To Payment Reason': props<{ paymentReason: string | null }>(),
    'Set Move From ACH Details': props<{ achDetails: MultiLegTransactionAchDetailsModel | null }>(),
    'Set Move To ACH Details': props<{ achDetails: MultiLegTransactionAchDetailsModel | null }>(),
    'Set Move From Wire Details': props<{ wireDetails: MultiLegTransactionWireDetailsModel | null }>(),
    'Set Move To Wire Details': props<{ wireDetails: MultiLegTransactionWireDetailsModel | null }>(),
    'Set Move From Processing Priority': props<{ processingPriority: MultiLegTransactionProcessingPriority | null }>(),
    'Set Move To Processing Priority': props<{ processingPriority: MultiLegTransactionProcessingPriority | null }>(),
    'Set Scheduler': props<{ schedulerDetails: MultiLegTransactionScheduler | null }>(),
    'Set Debit Authorization': props<{ debitAuthorization: TransactionDebitAuthorization | null }>(),
    'Set Note': props<{ note: string | null }>(),
    'Set Financial Accounts Filters': props<{ filters: FilterValues }>(),
    'Reset Financial Account Selection': emptyProps(),
    'Load Financial Accounts': props<{ params: FinancialAccountSelectionListRequest }>(),
    'Load Financial Accounts Success': props<{ financialAccountItems: FinancialAccountSelectionListItem[]; totalElements: number }>(),
    'Load Financial Accounts Failure': props<{ error: ErrorMessage }>(),
    'Financial Accounts Reset': emptyProps(),
    'Set Payment Reasons': props<{ paymentReasons: MultiLegPaymentReason[] }>(),
    'Form Initialize': emptyProps(),
    'Load Enabled Solutions Success': props<{ enabledSolutions: MultiLegEnabledSolutionPermissions }>(),
    'Load Enabled Solutions Failure': props<{ error: ErrorMessage }>(),
    'Load Enabled Solution Configs Success': props<{ enabledSolutionConfigs: MultiLegSolutionConfig[] }>(),
    'Load Enabled Solution Configs Failure': props<{ error: ErrorMessage }>(),
    'Set Transaction Type': props<{ transactionType: 'SLT' | 'MLT' | null }>(),
    'Create Transaction Page Reset': emptyProps(),
    'Create Transaction Page Clear': emptyProps(),
    'Set Unavailable Processing Priorities': props<{
      achSameDay: boolean;
      wireSameDay: boolean;
      wireNextDay: boolean;
    }>(),
    'Update To Account Address': props<{ billingAddress: Address }>(),
    'Form Update Mlt Initialize': emptyProps(),
    'Set Multi Leg Transaction Details Form Data': props<{
      formAction: 'EDIT_MLT';
      paymentReasons: MultiLegPaymentReason[];
      amount: string;
      fromAccount: FinancialAccountSelectionListItem | null;
      toAccount: FinancialAccountSelectionListItem | null;
      fromAccountMethod: MultiLegSolutionConfigSolutionName | null;
      toAccountMethod: MultiLegSolutionConfigSolutionName | null;
      transactionType: 'SLT' | 'MLT' | null;
      fromAccountPaymentReason: string | null;
      toAccountPaymentReason: string | null;
      isDebitLegsDisabled: boolean;
      fromAccountProcessingPriority: MultiLegTransactionProcessingPriority | null;
      toAccountProcessingPriority: MultiLegTransactionProcessingPriority | null;
      isWireMltTransaction: boolean;
      isMltDisbursementStage: boolean;
      deliverySpeedOptions: DeliverySpeedOption[];
    }>(),
    'Form Cancel Mlt Initialize': emptyProps(),
    'Set Cancel Multi Leg Transaction Form Data': props<{ formAction: 'CANCEL_MLT'; amount: string; currency: CurrencyCode | null }>(),
    'Form Update Slt Initialize': emptyProps(),
    'Set Single Leg Transaction Details Form Data': props<{
      formAction: 'EDIT_SLT';
      paymentReasons: MultiLegPaymentReason[];
      amount: string;
      fromAccount: FinancialAccountSelectionListItem | null;
      toAccount: FinancialAccountSelectionListItem | null;
      fromAccountMethod: MultiLegSolutionConfigSolutionName | null;
      toAccountMethod: MultiLegSolutionConfigSolutionName | null;
      transactionType: 'SLT' | 'MLT' | null;
      fromAccountPaymentReason: string | null;
      toAccountPaymentReason: string | null;
      isDebitLegsDisabled: boolean;
      fromAccountProcessingPriority: MultiLegTransactionProcessingPriority | null;
      toAccountProcessingPriority: MultiLegTransactionProcessingPriority | null;
      fromAccountAchDetails: MultiLegTransactionAchDetailsModel | null;
      deliverySpeedOptions: DeliverySpeedOption[];
    }>(),
    'Form Update Scheduled Slt Initialize': emptyProps(),
    'Set Scheduled Single Leg Transaction Details Form Data': props<{
      formAction: 'EDIT_SCHEDULED_SLT';
      paymentReasons: MultiLegPaymentReason[];
      amount: string;
      fromAccount: FinancialAccountSelectionListItem | null;
      toAccount: FinancialAccountSelectionListItem | null;
      fromAccountMethod: MultiLegSolutionConfigSolutionName | null;
      toAccountMethod: MultiLegSolutionConfigSolutionName | null;
      transactionType: 'SLT' | 'MLT' | null;
      fromAccountPaymentReason: string | null;
      toAccountPaymentReason: string | null;
      isDebitLegsDisabled: boolean;
      fromAccountProcessingPriority: MultiLegTransactionProcessingPriority | null;
      toAccountProcessingPriority: MultiLegTransactionProcessingPriority | null;
      fromAccountAchDetails: MultiLegTransactionAchDetailsModel | null;
      scheduler: MultiLegTransactionScheduler | null;
    }>(),
    'Form Update Scheduled Mlt Initialize': emptyProps(),
    'Set Scheduled Multi Leg Transaction Details Form Data': props<{
      formAction: 'EDIT_SCHEDULED_MLT';
      paymentReasons: MultiLegPaymentReason[];
      amount: string;
      fromAccount: FinancialAccountSelectionListItem | null;
      toAccount: FinancialAccountSelectionListItem | null;
      fromAccountMethod: MultiLegSolutionConfigSolutionName | null;
      toAccountMethod: MultiLegSolutionConfigSolutionName | null;
      transactionType: 'SLT' | 'MLT' | null;
      fromAccountPaymentReason: string | null;
      toAccountPaymentReason: string | null;
      isDebitLegsDisabled: boolean;
      fromAccountProcessingPriority: MultiLegTransactionProcessingPriority | null;
      toAccountProcessingPriority: MultiLegTransactionProcessingPriority | null;
      fromAccountAchDetails: MultiLegTransactionAchDetailsModel | null;
      scheduler: MultiLegTransactionScheduler | null;
    }>(),
    'Set Selected Delivery Option': props<{
      fromAccountMethod: MultiLegSolutionConfigSolutionName | null;
      toAccountMethod: MultiLegSolutionConfigSolutionName | null;
      deliveryOption: DeliverySpeedOption;
      fromAccountPaymentReason?: string;
      fromAccountProcessingPriority: MultiLegTransactionProcessingPriority | null;
      toAccountProcessingPriority: MultiLegTransactionProcessingPriority | null;
      solutionTransactionType?: string;
    }>(),
    'Set Transaction attachments': props<{ attachments: Partial<AttachmentCreateModel>[] | null }>(),
    'Set Delivery Speed Options': props<{ deliverySpeedOptions: DeliverySpeedOption[] }>(),
  },
});
