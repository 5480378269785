import { AsyncPipe, NgIf, NgTemplateOutlet } from '@angular/common';
import { AfterViewChecked, ChangeDetectorRef, Component, OnInit, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { Store } from '@ngrx/store';

import { FinancialAccountBalancesComponent, TabItem, TabsComponent } from '@shared/components';
import { TemplateContextDirective } from '@shared/directives';
import { FinancialAccountDetails, TemplateContext } from '@shared/models';
import { CardAccountActions, fromFinancialAccount } from '@shared/store';

import { CardPagesBreadcrumbsComponent } from '../card-pages-breadcrumbs/card-pages-breadcrumbs.component';

@Component({
  selector: 'app-integrated-card-account-page',
  templateUrl: './integrated-card-account-page.component.html',
  standalone: true,
  imports: [
    NgIf,
    NgTemplateOutlet,
    TemplateContextDirective,
    CardPagesBreadcrumbsComponent,
    FinancialAccountBalancesComponent,
    TabsComponent,
    RouterOutlet,
    AsyncPipe,
  ],
})
export class IntegratedCardAccountPageComponent implements OnInit, AfterViewChecked {
  store = inject(Store);

  ref = inject(ChangeDetectorRef);

  cardAccount$ = this.store.select(fromFinancialAccount.selectCardAccountDetails);

  cardAccountPageContext?: TemplateContext<FinancialAccountDetails>;

  tabItems: TabItem[] = [
    { label: 'Account Information', href: 'account-details' },
    { label: 'Card Transactions', href: 'card-transactions' },
    { label: 'Alerts', href: 'alerts' },
    { label: 'Disputes', href: 'disputes' },
    { label: 'Statements', href: 'statements' },
  ];

  ngOnInit(): void {
    this.store.dispatch(CardAccountActions.loadCardAccountDetails({ requestParams: { size: 100 } }));
  }

  public ngAfterViewChecked(): void {
    this.ref.detectChanges();
  }
}
