import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RktIconComponent } from '@rocketfinancialcorp/rocket-ui/icon';

@Component({
  selector: 'app-expandable-row',
  templateUrl: 'expandable-row.component.html',
  styleUrls: ['expandable-row.component.scss'],
  standalone: true,
  imports: [NgIf, RktIconComponent],
})
export class ExpandableRowComponent {
  @Input() title?: string;

  @Input() titleIconName?: string;

  @Input() isCollapsed = true;

  toggleRow() {
    this.isCollapsed = !this.isCollapsed;
  }
}
