import { AsyncPipe, NgIf, NgTemplateOutlet } from '@angular/common';
import { AfterViewChecked, ChangeDetectorRef, Component, Input, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { isNil } from 'lodash-es';
import { Subject, takeUntil } from 'rxjs';
import { RktButtonDirective } from '@rocketfinancialcorp/rocket-ui/button';
import { RktIconComponent } from '@rocketfinancialcorp/rocket-ui/icon';
import { ModalService } from '@rocketfinancialcorp/rocket-ui/modal';
import { DatatableColumn, DatatableSorting, FetchData, RktTableComponent } from '@rocketfinancialcorp/rocket-ui/table';

import {
  DocumentCreateFormComponent,
  DocumentTypeSelectionComponent,
  ErrorModalComponent,
  SuccessModalComponent,
} from '@shared/components';
import { MESSAGE } from '@shared/constants';
import { Beneficiary, DocumentCreateModel, DocumentList, DocumentListItem } from '@shared/models';
import { AccessControlPipe } from '@shared/pipes';
import { DocumentService, SubscriberService } from '@shared/services';
import { ErrorUtils } from '@shared/utils';

@Component({
  selector: 'app-document-list',
  templateUrl: 'document-list.component.html',
  styleUrls: ['document-list.component.scss'],
  standalone: true,
  imports: [RktButtonDirective, NgIf, NgTemplateOutlet, AsyncPipe, AccessControlPipe, RktIconComponent, RktTableComponent],
})
export class DocumentListComponent implements AfterViewChecked, OnDestroy {
  @Input() holderId?: string | null;
  @Input() accountHolderType?: string | null;
  @Input() holderType!: 'CUSTOMER' | 'BENEFICIARY' | 'BUSINESS_ACCOUNT';

  documents: DocumentList['items'] = [];

  loading = true;

  columns: DatatableColumn[] = [
    { name: 'Document Type', prop: 'documentTypeDisplayName', flexSize: 150 },
    { name: 'Document Name', prop: 'name', flexSize: 200 },
    { name: 'Document Description', prop: 'description', flexSize: 600 },
    { name: 'Document ID', prop: 'id', fixedSize: 160, colType: 'short-id' },
    { name: 'Created At', prop: 'createdAt', fixedSize: 150, colType: 'date-time', colAlign: 'right' },
  ];

  totalElements = 0;

  page = 0;

  sortParams: DatatableSorting = { key: 'createdAt', sortProp: 'createdAt', sortDir: 'asc' };

  emptyListMessage = 'No Documents found.';

  get activePage(): number {
    return this.page;
  }

  private destroy$ = new Subject<void>();

  constructor(
    public ref: ChangeDetectorRef,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private documentService: DocumentService,
    private modalService: ModalService,
    protected layoutOptions: SubscriberService<Beneficiary>,
  ) {}

  ngAfterViewChecked(): void {
    this.ref.detectChanges();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getDocuments({ page, size, sortParams }: FetchData): void {
    if (!this.holderId) {
      return;
    }

    this.loading = true;

    if (!isNil(page) && this.activePage !== page) {
      this.page = page;
    }

    if (sortParams) {
      this.sortParams = sortParams;
    }

    this.documentService
      .getDocuments({ holderId: this.holderId, page, size, sortParams: sortParams ?? this.sortParams })
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response) => {
          this.emptyListMessage = 'No Documents found.';
          this.onFetchComplete(response);
        },
        error: (error) => {
          this.emptyListMessage = 'Unable to fetch Documents.';
          this.onFetchComplete();
          ErrorUtils.catchError('documentService.getDocuments', error);
        },
      });
  }

  onFetchComplete(response?: DocumentList): void {
    const { items = [], totalElements = 0 } = response || {};
    this.documents = items;
    this.totalElements = totalElements;
    this.loading = false;
  }

  onRowClick({ id }: DocumentListItem): void {
    this.router.navigate([id], { relativeTo: this.activatedRoute });
  }

  onAddDocumentBtnClick(): void {
    this.onAddDocumentAction({ action: 'ADD' });
  }

  addDocument(editData?: DocumentCreateModel): void {
    const documentModalRef = this.modalService.open(DocumentTypeSelectionComponent, {
      className: 'entity-form-modal',
    });

    documentModalRef.componentInstance.modalInitData({ ...editData, accountHolderType: this.accountHolderType });

    documentModalRef.result.then(
      (createData: DocumentCreateModel) => {
        if (createData?.documentType) {
          this.addDocumentDetails(createData);
        }
      },
      () => false,
    );
  }

  addDocumentDetails(createData: DocumentCreateModel) {
    const documentCreateFormModalRef = this.modalService.open(DocumentCreateFormComponent, {
      className: 'entity-form-modal',
    });

    documentCreateFormModalRef.componentInstance.modalInitData({ ...createData, accountHolderType: this.accountHolderType });

    documentCreateFormModalRef.result.then(
      (result) => {
        if (result) this.onAddDocumentAction({ action: result.action, createData: { ...result, ...createData } });
      },
      () => false,
    );
  }

  saveDocument(createData: DocumentCreateModel) {
    this.documentService
      .createDocument({ ...createData, holderId: this.holderId!, holderType: this.holderType })
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          const addDocumentSuccessModalRef = this.modalService.open(SuccessModalComponent, {
            className: 'success-modal',
          });

          addDocumentSuccessModalRef.componentInstance.actionName = 'uploaded';
          addDocumentSuccessModalRef.componentInstance.type = 'Document';
          addDocumentSuccessModalRef.result.finally(() => {
            this.getDocuments({ page: 0 });
          });
        },
        error: (error) => {
          ErrorUtils.catchError('documentService.createDocument error', error);
          const errorModalRef = this.modalService.open(ErrorModalComponent, { className: 'confirm-modal' });

          if (error !== 'Bad request') {
            errorModalRef.componentInstance.messageText = error === 403 ? MESSAGE.PERMISSION_DENIED : MESSAGE.GENERIC_ERROR;
          }

          errorModalRef.result.finally(() => {
            this.getDocuments({ page: 0 });
          });
        },
      });
  }

  onAddDocumentAction({ action, createData }: { action: 'ADD' | 'EDIT' | 'CONTINUE' | 'SAVE'; createData?: DocumentCreateModel }): void {
    switch (action) {
      case 'ADD':
      case 'EDIT':
        this.addDocument(createData);
        break;

      case 'CONTINUE':
        this.addDocumentDetails(createData!);
        break;

      case 'SAVE':
        this.saveDocument(createData!);
        break;

      default:
        break;
    }
  }
}
