import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { EMPTY, Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

import { NotificationService } from '@shared/services';
import { fromAuth } from '@shared/store';

const allowedNonGetPaths: string[] = [
  'exchange-token',
  'token',
  'logout',
  '/accountflow/api/auth/realms/.*/validate', // user activation
];

@Injectable()
export class WatcherInterceptor implements HttpInterceptor {
  private watcherSponsorTenant$ = this.store.select(fromAuth.selectSponsorTenant).pipe(take(1));

  private allowedNonGetUrlsRegex: RegExp[] = [];

  constructor(
    private store: Store,
    private notificationService: NotificationService,
  ) {
    this.allowedNonGetUrlsRegex = allowedNonGetPaths.map((urlPattern) => new RegExp(urlPattern, 'i'));
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return this.watcherSponsorTenant$.pipe(
      switchMap((isSponsorTenant) => {
        if (isSponsorTenant && request.method !== 'GET') {
          const allowRequest = !!this.allowedNonGetUrlsRegex.find((regex) => regex.exec(request.url));
          if (!allowRequest) {
            this.notificationService.displayError('Operation not allowed in IRIS mode');
            return EMPTY;
          }
        }
        return next.handle(request);
      }),
    );
  }
}
