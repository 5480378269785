import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { uniqueId } from 'lodash-es';

interface Item {
  label: string;
  value: string;
}

@Component({
  selector: 'app-form-select-field',
  templateUrl: 'form-select-field.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, NgSelectComponent, FormsModule],
})
export class FormSelectFieldComponent {
  @Input() label!: string;

  @Input() required = false;

  @Input() selectedItem?: string;

  @Input() items: Item[] = [];

  @Input() loading = false;

  @Input() disabled = false;

  @Input() placeholder = '';

  @Output() changed = new EventEmitter<string>();

  @Output() scrolledToEnd = new EventEmitter<WheelEvent>();

  selectId = uniqueId();

  onChange(): void {
    this.changed.emit(this.selectedItem);
  }

  onScrollToEnd(): void {
    this.scrolledToEnd.emit();
  }
}
