import { Directive, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appFieldDisable]',
  standalone: true,
})
export class FieldDisableDirective {
  @Input() set appFieldDisable(condition: boolean) {
    const action = condition ? 'disable' : 'enable';
    if (this.ngControl.control) {
      this.ngControl.control[action]();
    }
  }

  constructor(private ngControl: NgControl) {}
}
