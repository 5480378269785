import { NgClass, NgIf, NgTemplateOutlet } from '@angular/common';
import { Component, ElementRef, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-attention-modal',
  templateUrl: 'attention-modal.component.html',
  standalone: true,
  imports: [NgClass, NgIf, NgTemplateOutlet],
})
export class AttentionModalComponent {
  @Input() type!: 'warning' | 'error' | 'success' | 'scheduled' | 'suspend' | 'resume';

  @Input() title!: string;

  @Input() subtitle!: string | undefined;

  @Input() messageTemplate!: TemplateRef<ElementRef>;

  @Input() buttonsTemplate!: TemplateRef<ElementRef>;
}
