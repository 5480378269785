import { Action, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';

import { QRAILS_CARD_SUPPORTED_LANGUAGES } from '@shared/enums';
import { CardProduct } from '@shared/models';
import * as fromRoot from '@shared/store';

import * as fromCardAccount from './card-account.reducer';
import * as fromFinancialAccounts from './financial-accounts.reducer';
export * from './card-account.actions';

export const financialAccountFeatureKey = 'financial-account';

export interface FinancialAccountState {
  [fromCardAccount.cardAccountFeatureKey]: fromCardAccount.CardAccountState;
  [fromFinancialAccounts.financialAccountsFeatureKey]: fromFinancialAccounts.FinancialAccountIdsState;
}

export interface State extends fromRoot.RootState {
  [financialAccountFeatureKey]: FinancialAccountState;
}

export const reducers = (state: FinancialAccountState | undefined, action: Action) => {
  return combineReducers({
    [fromCardAccount.cardAccountFeatureKey]: fromCardAccount.reducer,
    [fromFinancialAccounts.financialAccountsFeatureKey]: fromFinancialAccounts.reducer,
  })(state, action);
};

export const selectFinancialAccountState = createFeatureSelector<FinancialAccountState>(financialAccountFeatureKey);

// Card Account selectors
export const selectCardAccountState = createSelector(selectFinancialAccountState, (state) => state.cardAccount);

// Restriction selectors
export const selectCardAccountRestrictionList = createSelector(selectCardAccountState, (state) => state.restrictionList);
export const selectRestrictionListTotalElements = createSelector(selectCardAccountState, (state) => state.restrictionList?.length ?? 0);
export const selectRestrictionListLoading = createSelector(selectCardAccountState, (state) => state.restrictionList === null);
export const selectCardAccountRestrictionDetails = createSelector(selectCardAccountState, (state) => state.restrictionDetails);

// Alert selectors
export const selectCardAccountAlertList = createSelector(selectCardAccountState, (state) => state.alertList);
export const selectAlertListTotalElements = createSelector(selectCardAccountState, (state) => state.alertList?.length ?? 0);
export const selectAlertListLoading = createSelector(selectCardAccountState, (state) => state.alertList === null);
export const selectCardAccountAlertDetails = createSelector(selectCardAccountState, (state) => state.alertDetails);

// Dispute selectors
export const selectCardAccountDisputeList = createSelector(selectCardAccountState, (state) => state.disputeList);
export const selectDisputeListTotalElements = createSelector(selectCardAccountState, (state) => state.disputeList?.length ?? 0);
export const selectDisputeListLoading = createSelector(selectCardAccountState, (state) => state.disputeList === null);
export const selectCardAccountDisputeDetails = createSelector(selectCardAccountState, (state) => state.disputeDetails);

// Statement selectors
export const selectCardAccountStatementList = createSelector(selectCardAccountState, (state) => state.statementList);
export const selectStatementListTotalElements = createSelector(selectCardAccountState, (state) => state.statementListTotalElements);
export const selectStatementListLoading = createSelector(selectCardAccountState, (state) => state.statementList === null);

// Merchant selectors
export const selectCardAccountMerchantDetails = createSelector(selectCardAccountState, (state) => state.merchantDetails);
export const selectCardProductMerchantList = createSelector(selectCardAccountState, (state) => state.cardProductMerchants);
export const selectCardProductMerchantListTotalElements = createSelector(
  selectCardAccountState,
  (state) => state.cardProductMerchants?.length ?? 0,
);
export const selectCardProductMerchantListLoading = createSelector(selectCardAccountState, (state) => state.cardProductMerchants === null);

// Card Programs selectors
export const selectCardProgramList = createSelector(selectCardAccountState, (state) => state.cardProgramList ?? []);
export const selectCardProgramTotalElements = createSelector(selectCardAccountState, (state) => state.cardProgramList?.length ?? 0);
export const selectCardProgramLoading = createSelector(selectCardAccountState, (state) => state.cardProgramList === null);

// Card Product selectors
export const selectCardProductList = createSelector(selectCardAccountState, ({ cardProducts, cardProductsSortParam }) => {
  if (!cardProducts) {
    return null;
  }

  if (!cardProductsSortParam) {
    return cardProducts;
  }

  const { sortProp, sortDir } = cardProductsSortParam;
  const sortDirection = sortDir === 'asc' ? 1 : -1;

  if (!(sortProp in (cardProducts?.[0] ?? {}))) {
    return cardProducts;
  }

  return [...cardProducts].sort((a, b) => {
    const aValue = a[sortProp as keyof CardProduct];
    const bValue = b[sortProp as keyof CardProduct];

    if (aValue == null || bValue == null) {
      return 0;
    }

    return aValue > bValue ? sortDirection : -sortDirection;
  });
});

export const selectCardProductListTotalElements = createSelector(selectCardAccountState, (state) => state.cardProductsTotalElements ?? 0);
export const selectCardProductListLoading = createSelector(selectCardAccountState, (state) => state.cardProducts === null);
export const selectCardProductDetails = createSelector(selectCardAccountState, (state) => state.cardProductDetails);

export const selectCardProductRestrictionsLoading = createSelector(
  selectCardAccountState,
  (state) => state.cardProductRestrictions === null,
);
export const selectCardProductVelocityRestrictions = createSelector(
  selectCardAccountState,
  (state) => state.cardProductRestrictions?.velocityRestrictions,
);
export const selectCardProductGeneralRestrictions = createSelector(
  selectCardAccountState,
  (state) => state.cardProductRestrictions?.generalRestrictions,
);

// MCC selectors
export const selectMccItems = createSelector(selectCardAccountState, (state) => state.mccItems ?? []);

// Card Details
export const selectCardAccountDetails = createSelector(selectCardAccountState, selectCardProductList, (state, cardProducts) => {
  if (!state.cardAccountDetails) {
    return null;
  }

  return {
    ...state.cardAccountDetails,
    cardProviderDetails: state.cardAccountDetails?.cardProviderDetails
      ? {
          ...state.cardAccountDetails.cardProviderDetails,
          productName: cardProducts?.find((product) => product.id === state.cardAccountDetails!.cardProviderDetails!.productId)?.name,
          issuedLanguageName: QRAILS_CARD_SUPPORTED_LANGUAGES[state.cardAccountDetails?.cardProviderDetails?.issuedLanguageCode],
        }
      : undefined,
  };
});
export const selectCardAccountDetailsId = createSelector(selectCardAccountDetails, (financialAccount) => financialAccount?.id);

// Financial Accounts selectors
export const selectCustomerFinancialAccounts = createSelector(selectFinancialAccountState, (state) => state.financialAccounts);

export const selectCustomerFinancialAccountIds = createSelector(selectCustomerFinancialAccounts, (state) => state.financialaccountsIds);
