import { NgIf, TitleCasePipe } from '@angular/common';
import { Component } from '@angular/core';
import { RktButtonDirective } from '@rocketfinancialcorp/rocket-ui/button';
import { ActiveModal } from '@rocketfinancialcorp/rocket-ui/modal';

import { AttentionModalComponent } from '../attention-modal/attention-modal.component';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['confirm-modal.component.scss'],
  standalone: true,
  imports: [RktButtonDirective, AttentionModalComponent, NgIf, TitleCasePipe],
})
export class ConfirmModalComponent {
  confirmType: 'warning' | 'error' | 'success' | 'suspend' | 'resume' = 'error';

  title = 'Attention';

  actionName?: string;

  confirmBtnText?: string;

  displayName?: string;

  customText?: string;

  showCancelButton = true;

  type!: 'Recipient' | 'TeamMember' | 'FinancialAccount' | 'Transaction' | 'Customer';

  get typeText() {
    switch (this.type) {
      case 'TeamMember':
        return 'Team Member';
      case 'Recipient':
        return 'Recipient Account';
      case 'FinancialAccount':
        return 'Financial Account';
      case 'Customer':
        return 'Customer Account';
      default:
        return this.type;
    }
  }

  constructor(public activeModal: ActiveModal) {}
}
