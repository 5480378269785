import { AsyncPipe, NgFor } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RktIconComponent } from '@rocketfinancialcorp/rocket-ui/icon';
import { ModalService } from '@rocketfinancialcorp/rocket-ui/modal';

import { TransactionLimitsEditFormComponent } from '@shared/components';
import { TransactionLimitItem } from '@shared/models';
import { AccessControlPipe } from '@shared/pipes';
import { TransactionLimitsService } from '@shared/services';
import { ErrorUtils } from '@shared/utils';

import { TransactionLimitItemComponent } from '../transaction-limit-item/transaction-limit-item.component';

@Component({
  selector: 'app-transaction-limits',
  templateUrl: './transaction-limits.component.html',
  styleUrls: ['./transaction-limits.component.scss'],
  standalone: true,
  imports: [NgFor, TransactionLimitItemComponent, AsyncPipe, AccessControlPipe, RktIconComponent],
})
export class TransactionLimitsComponent implements OnInit, OnDestroy {
  @Input() isEditAllowed = true;

  @Input() scopeId!: string | undefined;

  @Input() scopeType!: 'FA_KFA' | 'CUSTOMER';

  public transactionLimits: TransactionLimitItem[] = [];

  public hasLimits = false;

  private destroy$ = new Subject<void>();

  constructor(
    private modalService: ModalService,
    public limitsService: TransactionLimitsService,
  ) {}

  ngOnInit(): void {
    this.getTransactionLimits();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getTransactionLimits() {
    if (!this.scopeId) {
      return;
    }

    this.limitsService
      .getTransactionLimits(this.scopeId, this.scopeType)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response) => {
          this.transactionLimits = response || [];
        },
        error: (error) => {
          ErrorUtils.catchError('limitsService.getTransactionLimits', error);
        },
      });
  }

  onEditClick(): void {
    const editModalRef = this.modalService.open(TransactionLimitsEditFormComponent, {
      className: 'entity-form-modal transaction-limits-edit-modal',
    });

    editModalRef.componentInstance.modalInitData({
      financialAccountId: this.scopeId,
      transactionLimits: this.transactionLimits,
    });

    editModalRef.result.finally(() => this.getTransactionLimits());
  }
}
