import { NgFor, NgIf } from '@angular/common';
import { AfterViewChecked, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { Subject, finalize } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RktButtonDirective } from '@rocketfinancialcorp/rocket-ui/button';
import { RktFormComponent, formRow, formSelect } from '@rocketfinancialcorp/rocket-ui/form';
import { RktIconComponent } from '@rocketfinancialcorp/rocket-ui/icon';
import { ActiveModal } from '@rocketfinancialcorp/rocket-ui/modal';

import { MESSAGE } from '@shared/constants';
import { AddScheduledReportParams, FormModel, ReportTemplate } from '@shared/models';
import { ReportService } from '@shared/services';
import { ErrorUtils } from '@shared/utils';

import { ViewPdfDocumentComponent } from '../../../../../../shared/components/documents/view-pdf-document/view-pdf-document.component';

@Component({
  selector: 'app-create-scheduled-report',
  templateUrl: './create-scheduled-report.component.html',
  styleUrls: ['./create-scheduled-report.component.scss'],
  standalone: true,
  imports: [
    RktButtonDirective,
    NgIf,
    NgFor,
    FormsModule,
    ReactiveFormsModule,
    RktFormComponent,
    ViewPdfDocumentComponent,
    RktIconComponent,
  ],
})
export class CreateScheduledReportComponent implements OnInit, AfterViewChecked {
  reportTemplates!: ReportTemplate[];

  selectedTemplate!: ReportTemplate;

  isLoading = false;

  submitError!: string;

  createScheduleReportForm = new FormGroup({});

  addReportModel: FormModel<AddScheduledReportParams> = {
    schedulerType: undefined,
  };

  reportFormFields: FormlyFieldConfig[] = [
    formRow([
      formSelect({
        key: 'schedulerType',
        label: 'Schedule Frequency',
        props: {
          options: [
            { value: 'DAILY', label: 'Daily' },
            { value: 'WEEKLY', label: 'Weekly' },
            { value: 'MONTHLY', label: 'Monthly' },
          ],
          required: true,
        },
      }),
    ]),
  ];

  private destroy$ = new Subject<void>();

  constructor(
    private reportService: ReportService,
    private ref: ChangeDetectorRef,
    public activeModal: ActiveModal,
  ) {}

  ngOnInit(): void {
    this.fetchReportTemplates();
  }

  ngAfterViewChecked() {
    this.ref.detectChanges();
  }

  fetchReportTemplates(): void {
    this.isLoading = true;
    this.reportService.getScheduledReportTemplates().subscribe({
      next: (templates) => {
        this.reportTemplates = templates;
        this.isLoading = false;
      },
      error: (err) => {
        this.isLoading = false;
        ErrorUtils.catchError('reportService getReportTemplates', err);
      },
    });
  }

  onSave(): void {
    this.createScheduleReportForm.markAllAsTouched();

    if (this.createScheduleReportForm.invalid || !this.selectedTemplate) {
      return;
    }

    this.isLoading = true;

    this.submitError = '';

    const { schedulerType } = this.addReportModel;

    const scheduledReportCreateData: AddScheduledReportParams = {
      includeCsvValue: true,
      graphicalReportTypeId: this.selectedTemplate.id,
      schedulerType,
      name: this.selectedTemplate.name,
      isInternal: false,
      isByFinancialAccount: false,
    };

    this.reportService
      .createScheduledReport(scheduledReportCreateData)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: () => {
          this.activeModal.close(true);
        },
        error: (error) => {
          this.handleError(error);
        },
      });
  }

  private handleError(error?: string | number): void {
    if (typeof error === 'string') {
      this.submitError = `Error: ${error}`;
    } else {
      this.submitError = error === 403 ? MESSAGE.PERMISSION_DENIED : MESSAGE.GENERIC_ERROR;
    }
    ErrorUtils.catchError('reportService.createScheduledReport', error);
  }

  setSelectedTemplate(reportTemplate: ReportTemplate) {
    this.selectedTemplate = reportTemplate;
  }
}
