import { createReducer, on } from '@ngrx/store';

import { ErrorMessage, Industry, IndustryList } from '@shared/models';

import { IndustriesActions } from './industry.actions';

export const industryListFeatureKey = 'industries';

export interface IndustriesState {
  industries: IndustryList['items'] | null;
  industry: Industry | null;
  totalElements: number;
  isLoading: boolean;
  error: ErrorMessage;
}

export const initialIndustriesState: IndustriesState = {
  industries: null,
  industry: null,
  totalElements: 0,
  isLoading: false,
  error: null,
};

export const reducer = createReducer<IndustriesState>(
  initialIndustriesState,
  on(IndustriesActions.loadIndustries, (state): IndustriesState => {
    return {
      ...state,
      isLoading: true,
      error: null,
    };
  }),
  on(IndustriesActions.loadIndustriesSuccess, (state, action): IndustriesState => {
    return {
      ...state,
      industries: action.industries,
      totalElements: action.totalElements,
      isLoading: false,
    };
  }),
  on(IndustriesActions.loadIndustriesError, (state, action): IndustriesState => {
    return {
      ...state,
      isLoading: false,
      error: action.error,
    };
  }),
  on(IndustriesActions.loadIndustryDetails, (state): IndustriesState => {
    return {
      ...state,
      isLoading: true,
      error: null,
    };
  }),
  on(IndustriesActions.loadIndustryDetailsSuccess, (state, action): IndustriesState => {
    return {
      ...state,
      industry: action.industry,
      isLoading: false,
    };
  }),
  on(IndustriesActions.loadIndustryDetailsError, (state, action): IndustriesState => {
    return {
      ...state,
      isLoading: false,
      error: action.error,
    };
  }),
);

export const getIndustries = (state: IndustriesState) => state.industries;
export const getIndustry = (state: IndustriesState) => state.industry;
export const getTotalElements = (state: IndustriesState) => state.totalElements;
export const getLoading = (state: IndustriesState) => state.isLoading;
