import { AsyncPipe, NgIf } from '@angular/common';
import { Component, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { RktButtonDirective } from '@rocketfinancialcorp/rocket-ui/button';
import { ActiveModal } from '@rocketfinancialcorp/rocket-ui/modal';
import { SplitPipe } from '@rocketfinancialcorp/rocket-ui/table';

import { selectCancelReviewData } from '@shared/store';

import { AmountPipe } from '../../../pipes/amount.pipe';
import { NotAvailablePipe } from '../../../pipes/not-available.pipe';

@Component({
  selector: 'app-cancel-recovery-financial-account-review-modal',
  templateUrl: './cancel-recovery-financial-account-review-modal.component.html',
  standalone: true,
  imports: [RktButtonDirective, NgIf, AsyncPipe, NotAvailablePipe, AmountPipe, SplitPipe],
})
export class CancelRecoveryFinancialAccountReviewModalComponent {
  private readonly store = inject(Store);

  public readonly activeModal = inject(ActiveModal);

  cancelReviewData$ = this.store.select(selectCancelReviewData);
}
