import { NgIf } from '@angular/common';
import { AfterViewChecked, ChangeDetectorRef, Component } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { add, format, getWeekOfMonth, parse } from 'date-fns';
import { fromZonedTime, toZonedTime } from 'date-fns-tz';
import { compact } from 'lodash-es';
import { BehaviorSubject } from 'rxjs';
import { RktButtonDirective } from '@rocketfinancialcorp/rocket-ui/button';
import { RktFormComponent, formDatepicker, formInput, formRadioGroup, formRow, formSelect } from '@rocketfinancialcorp/rocket-ui/form';
import { RktIconComponent } from '@rocketfinancialcorp/rocket-ui/icon';
import { ActiveModal } from '@rocketfinancialcorp/rocket-ui/modal';

import { Patterns } from '@shared/components';
import { FormModel } from '@shared/models';
import { formatDate, systemTimeZone } from '@shared/utils';

interface ScheduleRecurringModel {
  interval: number;
  frequency: string;
  monthlyInterval: string;
  ends: string;
  endsDate: string;
  endsCount: number;
  scheduleDate: string;
}

@Component({
  selector: 'app-move-money-scheduler-recurrence-modal',
  templateUrl: './move-money-scheduler-recurrence-modal.component.html',
  standalone: true,
  imports: [RktButtonDirective, FormsModule, ReactiveFormsModule, RktFormComponent, NgIf, RktIconComponent],
})
export class MoveMoneySchedulerRecurrenceModalComponent implements AfterViewChecked {
  scheduleRecurringOptions$ = new BehaviorSubject<{ label: string; value: string }[]>([]);

  recurrenceFormModel: FormModel<ScheduleRecurringModel> = {
    interval: 1,
    frequency: 'DAILY',
    monthlyInterval: undefined,
    ends: undefined,
    endsDate: undefined,
    endsCount: 1,
  };

  recurrenceFormFields = [
    formRow([
      formInput({
        key: 'interval',
        label: 'Repeat every',
        className: 'interval-field',
        props: {
          pattern: Patterns.POSITIVE_INTEGER,
          minLength: 1,
          maxLength: 4,
          required: true,
        },
        validation: {
          messages: {
            pattern: 'Enter a valid number',
          },
        },
      }),
      formSelect({
        key: 'frequency',
        label: '',
        className: 'empty-label-field',
        props: {
          required: true,
          options: [
            { value: 'DAILY', label: 'Day' },
            { value: 'WEEKLY', label: 'Week' },
            { value: 'MONTHLY', label: 'Month' },
            { value: 'YEARLY', label: 'Year' },
          ],
        },
      }),
    ]),
    formRow([
      formSelect({
        key: 'monthlyInterval',
        label: '',
        className: 'no-label-field',
        props: {
          options: this.scheduleRecurringOptions$.asObservable(),
        },
        expressions: {
          'props.required': "model?.frequency === 'MONTHLY'",
          hide: "model?.frequency !== 'MONTHLY'",
        },
      }),
    ]),
    formRow(
      [
        formRadioGroup({
          key: 'ends',
          label: 'Ends',
          className: 'full-height-row-radio-group recurrence-ends-field',
          props: {
            required: false,
            options: [
              {
                label: 'Never',
                value: undefined,
              },
            ],
          },
        }),
      ],
      { className: 'recurrence-ends-row' },
    ),
    formRow([
      formRadioGroup({
        key: 'ends',
        label: '',
        className: 'full-height-row-radio-group no-label-field recurrence-ends-field',
        props: {
          required: false,
          options: [
            {
              label: 'On',
              value: 'UNTIL',
            },
          ],
        },
      }),
      formDatepicker({
        key: 'endsDate',
        label: '',
        className: 'no-label-field',
        props: {
          placeholder: 'MM/DD/YY',
          maxDate: NgbDate.from({ day: 1, month: 1, year: new Date().getFullYear() + 120 }),
        },
        expressions: {
          'props.required': 'model?.ends === "UNTIL"',
          'props.disabled': 'model?.ends !== "UNTIL"',
          'props.minDate': (field: FormlyFieldConfig) => {
            return field.model?.scheduleDate ? formatDate(toZonedTime(new Date(field.model.scheduleDate), systemTimeZone)) : null;
          },
        },
      }),
    ]),
    formRow([
      formRadioGroup({
        key: 'ends',
        label: '',
        className: 'full-height-row-radio-group no-label-field recurrence-ends-field',
        props: {
          required: false,
          options: [
            {
              label: 'After',
              value: 'AFTER',
            },
          ],
        },
      }),
      formInput({
        key: 'endsCount',
        label: '',
        className: 'no-label-field occurrence-counter-field',
        props: { description: 'occurrences', pattern: Patterns.POSITIVE_INTEGER, minLength: 1 },
        expressions: {
          'props.required': 'model?.ends === "AFTER"',
          'props.disabled': 'model?.ends !== "AFTER"',
        },
        validation: {
          messages: {
            pattern: 'Enter a valid number',
          },
        },
      }),
    ]),
  ];

  recurrenceForm = new FormGroup({});

  formError = '';

  get isDisabled() {
    return this.recurrenceForm.invalid;
  }

  constructor(
    public activeModal: ActiveModal,
    private ref: ChangeDetectorRef,
  ) {}

  ngAfterViewChecked(): void {
    this.ref.detectChanges();
  }

  modalInitData({ scheduleDate }: { scheduleDate: string }) {
    const updatedOptions = this.recurringOptions(scheduleDate);
    this.scheduleRecurringOptions$.next(updatedOptions);

    this.recurrenceFormModel = {
      ...this.recurrenceFormModel,
      scheduleDate,
      monthlyInterval: updatedOptions[0].value,
      endsDate: format(add(parse(scheduleDate, 'MM/dd/yyyy', new Date()), { months: 1 }), 'yyyy-MM-dd'),
    };
  }

  onSave() {
    const scheduleDateParsed = parse(this.recurrenceFormModel.scheduleDate!, 'MM/dd/yyyy', new Date());

    const dayValue = format(scheduleDateParsed, 'd');
    const monthValue = format(scheduleDateParsed, 'M');
    const { interval, frequency, monthlyInterval, ends, endsDate, endsCount } = this.recurrenceFormModel;
    const endsDateParsed = endsDate ? format(parse(endsDate, 'MM/dd/yyyy', new Date()), 'yyyyMMdd') + 'T235959Z' : undefined;
    const monthlyValue = frequency === 'MONTHLY' ? monthlyInterval : '';
    const yearlyValue = frequency === 'YEARLY' ? `BYMONTH=${monthValue};BYMONTHDAY=${dayValue};` : '';
    const endsAfterValue = ends === 'AFTER' ? `COUNT=${endsCount};` : '';
    const endsDateValue = ends === 'UNTIL' ? `UNTIL=${endsDateParsed};` : '';
    const value = `FREQ=${frequency};INTERVAL=${interval};${monthlyValue}${yearlyValue}${endsDateValue}${endsAfterValue}`.slice(0, -1);

    this.activeModal.close({
      value,
      label: value,
    });
  }

  recurringOptions(scheduleDate: string) {
    const scheduleDateParsed = scheduleDate
      ? fromZonedTime(`${format(parse(scheduleDate, 'MM/dd/yyyy', new Date()), 'yyyy-MM-dd')} 00:00`, systemTimeZone)
      : undefined;

    if (!scheduleDateParsed) {
      return [];
    }
    const day = format(scheduleDateParsed, 'do');
    const dayValue = format(scheduleDateParsed, 'd');
    const week = getWeekOfMonth(scheduleDateParsed);
    const dayOfWeek = format(scheduleDateParsed, 'EEEE');
    const dayOfWeekValue = format(scheduleDateParsed, 'EEEEEE').toUpperCase();
    const isLastWeek = format(scheduleDateParsed, 'MMMM') !== format(add(scheduleDateParsed, { weeks: 1 }), 'MMMM');
    const weekLabels = ['first', 'second', 'third', 'fourth'];
    const weekLabel = isLastWeek ? 'last' : weekLabels[week - 1];

    return compact([
      day ? { value: `BYMONTHDAY=${dayValue};`, label: `Monthly on the ${day}` } : undefined,
      week && dayOfWeek
        ? { value: `BYSETPOS=${isLastWeek ? '-1' : week};BYDAY=${dayOfWeekValue};`, label: `Monthly on the ${weekLabel} ${dayOfWeek}` }
        : undefined,
      week === 4 && dayOfWeek ? { value: `BYSETPOS=-1;BYDAY=${dayOfWeekValue};`, label: `Monthly on the last ${dayOfWeek}` } : undefined,
    ]);
  }
}
