import { AsyncPipe, NgFor, NgIf, TitleCasePipe } from '@angular/common';
import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { SelectionType } from '@swimlane/ngx-datatable';
import { RktButtonDirective } from '@rocketfinancialcorp/rocket-ui/button';
import { RktIconComponent } from '@rocketfinancialcorp/rocket-ui/icon';
import { ActiveModal } from '@rocketfinancialcorp/rocket-ui/modal';
import { DatatableColumn, DatatableSorting, RktTableComponent, RktTableSearchComponent } from '@rocketfinancialcorp/rocket-ui/table';

import { FilterMenuComponent } from '@shared/components';
import { FilterField, FilterValues, FinancialAccountCategory, FinancialAccountSelectionListItem, RequestPageParams } from '@shared/models';
import { TransactionFormActions, selectTransactionFormFinancialAccountsActiveFiltersCount, transactionFormFeature } from '@shared/store';
import { activeFilters } from '@shared/utils';

type Category = 'INTERNAL,INTEGRATED' | 'EXTERNAL';

@Component({
  selector: 'app-financial-account-selection-modal',
  templateUrl: './financial-account-selection-modal.component.html',
  standalone: true,
  imports: [
    RktButtonDirective,
    NgFor,
    NgIf,
    AsyncPipe,
    TitleCasePipe,
    RktIconComponent,
    RktTableSearchComponent,
    RktTableComponent,
    FilterMenuComponent,
  ],
})
export class FinancialAccountSelectionModalComponent implements AfterViewChecked, OnDestroy {
  @ViewChild(RktTableComponent) financialAccountsTableRef!: RktTableComponent<FinancialAccountSelectionListItem>;

  @ViewChild(RktTableSearchComponent) tableSearchRef!: RktTableSearchComponent;

  @ViewChild(FilterMenuComponent) filterMenuRef!: FilterMenuComponent;

  financialAccounts$ = this.store.select(transactionFormFeature.selectFinancialAccountItems);

  totalElements$ = this.store.select(transactionFormFeature.selectFinancialAccountItemsTotalElements);

  isLoading$ = this.store.select(transactionFormFeature.selectFinancialAccountItemsLoading);

  activeFilters$ = this.store.select(transactionFormFeature.selectFinancialAccountsActiveFilters);

  activeFiltersCount$ = this.store.select(selectTransactionFormFinancialAccountsActiveFiltersCount);

  type?: 'from' | 'to';

  activePage = 0;

  selectedFinancialAccounts: FinancialAccountSelectionListItem[] = [];

  filters: FilterField[] = [
    {
      name: 'accountHolderTypes',
      displayName: 'Account Holder Type',
      type: 'RADIO',
      options: [
        { label: 'All Types', value: 'BUSINESS_ACCOUNT,CUSTOMER,RECIPIENT' },
        { label: 'Business Account', value: 'BUSINESS_ACCOUNT' },
        { label: 'Customer', value: 'CUSTOMER' },
        { label: 'Recipient', value: 'RECIPIENT' },
      ],
      defaultValue: 'BUSINESS_ACCOUNT,CUSTOMER,RECIPIENT',
    },
    {
      name: 'accountType',
      displayName: 'Account Type',
      type: 'RADIO',
      options: [
        { label: 'All Types', value: undefined },
        { label: 'Bank Account', value: 'BANK' },
        { label: 'Card Account', value: 'CARD' },
      ],
    },
    {
      name: 'maskedAccountNumber',
      displayName: 'Account Masked Number',
      type: 'INPUT',
      props: {
        maxLength: '20',
        validationPattern: '[0-9*]*',
        validationPatternMessage: 'Account Number only allow numbers (digits) and asterisk',
      },
    },
    {
      name: 'state',
      displayName: 'Account Status',
      type: 'RADIO',
      options: [
        { label: 'All', value: undefined },
        { label: 'Active', value: 'ACTIVE' },
        { label: 'Pending', value: 'PENDING' },
        { label: 'Suspended', value: 'SUSPENDED' },
      ],
    },
    {
      name: 'accountHolderName',
      displayName: 'Account Holder Name',
      type: 'INPUT',
    },
    {
      name: 'accountHolderPhone',
      displayName: 'Account Holder Phone',
      type: 'INPUT',
      props: {
        maxLength: '20',
        validationPattern: '[0-9]*',
        validationPatternMessage: 'Phone only allow numbers (digits)',
      },
    },
    {
      name: 'accountHolderEmail',
      displayName: 'Account Holder Email',
      type: 'INPUT',
    },
  ];

  selectionType = SelectionType.single;

  sortParams: DatatableSorting = { key: 'createdAt', sortProp: 'createdAt', sortDir: 'desc' };

  searchString?: string;

  categories: { label: string; value: Category }[] = [
    { label: 'INTERNAL & INTEGRATED', value: 'INTERNAL,INTEGRATED' },
    { label: 'EXTERNAL', value: 'EXTERNAL' },
  ];

  selectedCategory: Category = 'INTERNAL,INTEGRATED';

  columns: DatatableColumn[] = [
    { name: 'Account Holder', prop: 'accountHolderInfo', sortProp: 'accountHolderName', colType: 'two-line-col', flexSize: 5 },
    { name: 'Account Info', prop: 'accountInfo', sortProp: 'name', colType: 'two-line-col', flexSize: 7, isSortable: true },
    { name: 'Category', prop: 'accountCategory', sortProp: 'category', fixedSize: 120, isSortable: true },
    { name: 'Account Balance', prop: 'accountBalance', fixedSize: 160, colType: 'amount', colAlign: 'right' },
    { name: 'Available Balance', prop: 'availableBalance', fixedSize: 160, colType: 'amount', colAlign: 'right' },
    { name: 'Status', prop: 'state', fixedSize: 140, colType: 'status-tag', colAlign: 'right', isSortable: true },
  ];

  externalTypeColumns: DatatableColumn[] = [
    { name: 'Account Holder', prop: 'accountHolderInfo', sortProp: 'accountHolderName', colType: 'two-line-col', flexSize: 5 },
    { name: 'Account Info', prop: 'accountInfo', sortProp: 'name', colType: 'two-line-col', flexSize: 7, isSortable: true },
    { name: 'Phone', prop: 'phone', sortProp: 'accountHolderPhone', fixedSize: 180 },
    { name: 'Email', prop: 'email', sortProp: 'accountHolderEmail', fixedSize: 260 },
    { name: 'Status', prop: 'state', fixedSize: 140, colType: 'status-tag', colAlign: 'right', isSortable: true },
  ];

  isEditMLT = false;

  isEditSLT = false;

  isEditScheduledSLT = false;

  recipientAccountHolderId?: string;

  constructor(
    public activeModal: ActiveModal,
    private ref: ChangeDetectorRef,
    private store: Store,
  ) {}

  ngAfterViewChecked(): void {
    this.ref.detectChanges();
  }

  ngOnDestroy(): void {
    this.store.dispatch(TransactionFormActions.resetFinancialAccountSelection());
  }

  modalInitData({
    type,
    selectedFinancialAccount,
    fromAccount,
    isEditMLT,
    isEditSLT,
    isEditScheduledSLT,
  }: {
    type: 'from' | 'to';
    selectedFinancialAccount?: FinancialAccountSelectionListItem;
    fromAccount?: FinancialAccountSelectionListItem;
    isEditMLT: boolean;
    isEditSLT: boolean;
    isEditScheduledSLT: boolean;
  }) {
    this.type = type;
    this.isEditMLT = isEditMLT;
    this.isEditSLT = isEditSLT;
    this.isEditScheduledSLT = isEditScheduledSLT;

    if (isEditScheduledSLT && type === 'to') {
      if (fromAccount?.category === 'EXTERNAL') {
        this.categories = [];
        this.selectedCategory = 'INTERNAL,INTEGRATED';
      } else {
        this.categories = [];
        this.selectedCategory = 'EXTERNAL';
      }
    } else if (isEditSLT && type === 'from') {
      if (fromAccount?.category === 'EXTERNAL') {
        this.categories = [];
        this.selectedCategory = 'EXTERNAL';
      } else {
        this.categories = [];
        this.selectedCategory = 'INTERNAL,INTEGRATED';
      }
    } else if (isEditSLT && type === 'to') {
      if (fromAccount?.category === 'EXTERNAL') {
        this.categories = [];
        this.selectedCategory = 'INTERNAL,INTEGRATED';
      } else {
        this.categories = [];

        this.selectedCategory = 'EXTERNAL';
      }
    } else if (isEditMLT) {
      this.categories = [];
      this.selectedCategory = 'EXTERNAL';
    }

    if (fromAccount?.accountHolderType === 'CUSTOMER' && fromAccount?.accountHolderId) {
      this.recipientAccountHolderId = fromAccount.accountHolderId;
    }

    if (fromAccount?.accountHolderType === 'RECIPIENT' && fromAccount?.recipientAccountHolderId) {
      this.recipientAccountHolderId = fromAccount.recipientAccountHolderId;
    }

    if (fromAccount?.accountHolderType === 'BUSINESS_ACCOUNT') {
      this.recipientAccountHolderId = fromAccount?.businessAccountId;
    }

    if (selectedFinancialAccount) {
      this.selectedFinancialAccounts = [{ ...selectedFinancialAccount }];
    }
  }

  onSelectBtnClick() {
    const financialAccount = this.selectedFinancialAccounts[0];
    if (this.type === 'from' && this.isEditSLT) {
      this.store.dispatch(TransactionFormActions.updateMoveFrom({ financialAccount }));
    } else if (this.type === 'from') {
      this.store.dispatch(TransactionFormActions.setMoveFrom({ financialAccount }));
    } else if (this.type === 'to' && (this.isEditMLT || this.isEditSLT)) {
      this.store.dispatch(TransactionFormActions.updateMoveTo({ financialAccount }));
    } else if (this.type === 'to') {
      this.store.dispatch(TransactionFormActions.setMoveTo({ financialAccount }));
    }
    this.activeModal.close();
  }

  onSearch(searchString?: string): void {
    if (!searchString && !this.searchString) {
      return;
    }

    this.searchString = searchString;
    this.financialAccountsTableRef?.setPage({ offset: 1 });
  }

  applyFilters(filterValues: FilterValues) {
    this.store.dispatch(TransactionFormActions.setFinancialAccountsFilters({ filters: activeFilters(filterValues) }));
    this.financialAccountsTableRef?.setPage({ offset: 1 });
  }

  fetchFinancialAccounts({ page, size, sortParams }: RequestPageParams): void {
    if (sortParams) {
      this.sortParams = sortParams;
    }

    this.closeMenu();

    const categories = this.selectedCategory.split(',') as FinancialAccountCategory[];

    this.store.dispatch(
      TransactionFormActions.loadFinancialAccounts({
        params: {
          page,
          size,
          categories,
          searchString: this.searchString,
          sortParams: this.sortParams,
          type: this.type,
          financialAccountType: this.type === 'to' && this.isEditSLT ? 'BANK' : undefined,
          recipientAccountHolderId: this.recipientAccountHolderId,
        },
      }),
    );
  }

  onFinancialAccountSelect(data: unknown): void {
    const { selected = [] } = data as { selected?: FinancialAccountSelectionListItem[] };

    this.selectedFinancialAccounts = [...selected];
  }

  onCategoryClick(category: Category) {
    if (category === this.selectedCategory) {
      return;
    }

    this.store.dispatch(TransactionFormActions.financialAccountsReset());
    this.selectedCategory = category;
    this.searchString = undefined;
    this.financialAccountsTableRef?.setPage({ offset: 1 });

    if (this.tableSearchRef) {
      this.tableSearchRef.searchText.nativeElement.value = '';
    }
  }

  closeMenu() {
    if (this.filterMenuRef?.isVisible) {
      this.filterMenuRef.toggle({} as MouseEvent);
    }
  }
}
