import { DecimalPipe, NgIf, NgStyle } from '@angular/common';
import { Component, Input } from '@angular/core';
import { NgxMaskPipe } from 'ngx-mask';

import { TransactionLimitItem } from '@shared/models';

import { PrefixPipe } from '../../../pipes/prefix.pipe';

@Component({
  selector: 'app-transaction-limit-item',
  templateUrl: './transaction-limit-item.component.html',
  styleUrls: ['./transaction-limit-item.component.scss'],
  standalone: true,
  imports: [NgIf, NgStyle, DecimalPipe, PrefixPipe, NgxMaskPipe],
})
export class TransactionLimitItemComponent {
  @Input() limitItem!: TransactionLimitItem;

  get isSingle(): boolean {
    return this.limitItem.type === 'single';
  }
}
