import { Action, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';

import { fromRoot } from '@shared/store';

import * as fromInsightDocument from './insight-document.reducer';

export const insightDocumentFeatureKey = 'insight-document-store';

export interface InsightDocumentState {
  [fromInsightDocument.insightDocumentHistoryListFeatureKey]: fromInsightDocument.InsightDocumentHistoryState;
}

export interface State extends fromRoot.RootState {
  [insightDocumentFeatureKey]: InsightDocumentState;
}

export const reducers = (state: InsightDocumentState | undefined, action: Action) => {
  return combineReducers({
    [fromInsightDocument.insightDocumentHistoryListFeatureKey]: fromInsightDocument.reducer,
  })(state, action);
};

export const selectInsightDocumentHistoryState = createFeatureSelector<InsightDocumentState>(insightDocumentFeatureKey);

export const selectInsightDocumentHistoryListState = createSelector(
  selectInsightDocumentHistoryState,
  (state) => state.insightDocumentHistoryList,
);

export const selectInsightUnreadDocuments = createSelector(
  selectInsightDocumentHistoryListState,
  fromInsightDocument.getInsightUnreadDocuments,
);
