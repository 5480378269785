import { CurrencyPipe, NgClass, NgFor, NgIf, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { RktIconComponent } from '@rocketfinancialcorp/rocket-ui/icon';

import { AccountBalance } from '@shared/models';

import { AbsNumberPipe } from '../../../pipes/abs-number.pipe';

interface AccountBalanceItem {
  label: string;
  type: keyof AccountBalance;
  tooltip: string;
  isPrimary?: boolean;
  icon?: 'deposit' | 'withdraw';
}

@Component({
  selector: 'app-financial-account-balances',
  templateUrl: 'financial-account-balances.component.html',
  styleUrls: ['financial-account-balances.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgFor, NgTemplateOutlet, NgIf, NgClass, CurrencyPipe, AbsNumberPipe, RktIconComponent],
})
export class FinancialAccountBalancesComponent {
  @Input() accountBalanceValues?: AccountBalance;

  @Input() isLoading = true;

  @Input() isFullWidth = false;

  @Input() noBottomOffset = false;

  @Input() isVerticalOrientation = false;

  @Input() available = true;

  @Input() selectedCurrency?: string;

  @Input() label = 'Aggregated Account Balances';

  @Input() currencyList!: { label: string; value: string }[];

  @Output() setSelectedCurrency = new EventEmitter<string>();

  accountBalances: AccountBalanceItem[] = [
    {
      label: 'Available Balance',
      type: 'availableBalance',
      tooltip: 'Funds that are available to transact with.',
      isPrimary: true,
    },
    {
      label: 'Account Balance',
      type: 'accountBalance',
      tooltip: 'Actual balance of the account.  May not reflect the value of available funds.',
    },
  ];

  pendingBalances: AccountBalanceItem[] = [
    {
      label: 'Pending Deposits',
      type: 'pendingDeposit',
      tooltip: 'Total value of inbound funds submitted for processing that have not yet cleared.',
      icon: 'deposit',
    },
    {
      label: 'Pending Withdrawals',
      type: 'pendingWithdraw',
      tooltip: 'Total value of outbound funds submitted for processing that have not yet settled.',
      icon: 'withdraw',
    },
  ];
}
