import { NgFor, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-details-item-placeholder',
  templateUrl: './details-item-placeholder.component.html',
  styleUrls: ['./details-item-placeholder.component.scss'],
  standalone: true,
  imports: [NgIf, NgFor],
})
export class DetailsItemPlaceholderComponent {
  @Input() isPrimary?: boolean;

  @Input() showLabel?: boolean;

  @Input() label?: string;

  @Input() isDarkBg?: boolean;

  @Input() multiline?: boolean;
}
