import { Component } from '@angular/core';
import { RktButtonDirective } from '@rocketfinancialcorp/rocket-ui/button';
import { ActiveModal } from '@rocketfinancialcorp/rocket-ui/modal';

import { AttentionModalComponent } from '../../attention-modal/attention-modal.component';

@Component({
  selector: 'app-move-money-confirm-changes-modal',
  templateUrl: 'move-money-confirm-changes-modal.component.html',
  standalone: true,
  imports: [RktButtonDirective, AttentionModalComponent],
})
export class MoveMoneyConfirmChangesModalComponent {
  constructor(public activeModal: ActiveModal) {}
}
