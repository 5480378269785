import { AsyncPipe, NgFor, NgIf, TitleCasePipe } from '@angular/common';
import { Component, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { RktButtonDirective } from '@rocketfinancialcorp/rocket-ui/button';
import { ActiveModal } from '@rocketfinancialcorp/rocket-ui/modal';

import { exceptionManagementFeature } from '@shared/store';

import { DateFormatPipe } from '../../../pipes/date-format.pipe';
import { TeamMemberNamePipe } from '../../../pipes/team-member-name.pipe';

@Component({
  templateUrl: './exception-history-modal.component.html',
  standalone: true,
  imports: [RktButtonDirective, NgFor, NgIf, AsyncPipe, TitleCasePipe, DateFormatPipe, TeamMemberNamePipe],
})
export class ExceptionHistoryModalComponent {
  activeModal = inject(ActiveModal);

  store = inject(Store);

  historyItems$ = this.store.select(exceptionManagementFeature.selectExceptionHistoryItems);
}
