<rkt-table
  [rows]="(statements$ | async)!"
  [columns]="columns"
  [limit]="10"
  [count]="(totalElements$ | async)!"
  (fetchData)="getFinancialAccountStatements($event)"
  [loadingIndicator]="(loading$ | async)!"
  [offset]="page"
  [sortParam]="sortParams"
  dateTimeFormat="MM/dd/yy HH:mm"
  [messages]="{ emptyMessage: emptyListMessage }"
  (colButtonClicked)="onColButtonClick($event)"
  (rowActivated)="onReportStatementAction($event, false)"
  emptyValuePlaceholder="--"
></rkt-table>
