import { NgIf } from '@angular/common';
import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { isEmpty, isUndefined } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RktIconComponent } from '@rocketfinancialcorp/rocket-ui/icon';
import { DatatableColumn, FetchData, RktTableComponent, RktTableSearchComponent } from '@rocketfinancialcorp/rocket-ui/table';

import { FilterMenuComponent } from '@shared/components';
import {
  FilterField,
  FilterValues,
  FinancialAccountActivities,
  FinancialAccountActivityItem,
  FinancialAccountDetails,
  RequestPageParams,
} from '@shared/models';
import { FinancialAccountService } from '@shared/services';
import { ErrorUtils, activeFilters } from '@shared/utils';

@Component({
  selector: 'app-financial-account-activity-table',
  templateUrl: 'financial-account-activity-table.component.html',
  standalone: true,
  imports: [NgIf, RktIconComponent, RktTableSearchComponent, RktTableComponent, FilterMenuComponent],
})
export class FinancialAccountActivityTableComponent implements AfterViewChecked, OnDestroy, OnInit {
  public financialAccountId?: FinancialAccountDetails['id'];

  public accountActivities: FinancialAccountActivityItem[] = [];

  public accountActivityItems: FinancialAccountActivityItem[] = [];

  public columns: DatatableColumn[] = [
    { name: 'Txn ID & Created Time', prop: 'idWithCreatedAt', fixedSize: 200, colType: 'id-date-time-combined' },
    { name: 'Description', prop: 'description', colType: 'two-line-col', flexSize: 600 },
    { name: 'Amount', prop: 'amount', flexSize: 200, colType: 'amount', colAlign: 'right' },
    { name: 'Account Balance', prop: 'accountBalance', flexSize: 200, colType: 'amount', colAlign: 'right' },
    { name: 'Status', prop: 'status', fixedSize: 140, colType: 'status-tag', colAlign: 'right' },
  ];

  public totalElements = 0;

  searchString?: string;

  activeFilters: FilterValues = { date: 'DEFAULT' };

  filters: FilterField[] = [
    {
      name: 'date',
      displayName: 'Date',
      type: 'DATE',
      options: [
        { label: 'Today', value: 'TODAY' },
        { label: 'Yesterday', value: 'YESTERDAY' },
        { label: 'This week', value: 'THIS_WEEK' },
        { label: 'This month', value: 'THIS_MONTH' },
        { label: '90 days', value: 'DEFAULT' },
        { label: 'Custom range', value: 'CUSTOM' },
      ],
      defaultValue: 'DEFAULT',
      props: {
        maxMonths: '15',
      },
    },
    {
      name: 'entryType',
      displayName: 'Amount Type',
      type: 'RADIO',
      options: [
        { label: 'Credits', value: 'CREDIT' },
        { label: 'Debits', value: 'DEBIT' },
      ],
    },
    {
      name: 'amount',
      displayName: 'Amount',
      type: 'AMOUNT',
    },
    {
      name: 'transactionStatus',
      displayName: 'Status',
      type: 'RADIO',
      options: [
        { label: 'All', value: undefined },
        { label: 'Approved', value: 'APPROVED' },
        { label: 'Cancelled', value: 'CANCELLED' },
        { label: 'Cleared', value: 'CLEARED' },
        { label: 'Declined', value: 'DECLINED' },
        { label: 'Error', value: 'ERROR' },
        { label: 'New', value: 'NEW' },
        { label: 'Pending', value: 'PENDING' },
        { label: 'Processing', value: 'PROCESSING' },
        { label: 'Settled', value: 'SETTLED' },
        { label: 'Reversed', value: 'REVERSED' },
      ],
    },
  ];

  isLoading = false;

  emptyListMessage = 'No matching Financial Account Activity found.';

  get activePage(): number {
    const routePage = this.activatedRoute?.snapshot?.queryParamMap?.get('page');
    return routePage ? parseInt(routePage, 10) : 0;
  }

  get activeFiltersCount(): number {
    return Object.keys(this.activeFilters).length;
  }

  private destroy$ = new Subject<void>();

  constructor(
    public ref: ChangeDetectorRef,
    private financialAccountService: FinancialAccountService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    this.financialAccountId = this.activatedRoute.snapshot?.parent?.paramMap.get('id') || undefined;

    this.financialAccountService.activeActivityFilters.pipe(takeUntil(this.destroy$)).subscribe({
      next: (filters: FilterValues) => {
        if (isEmpty(filters)) {
          this.financialAccountService.getFilterParams();
        } else {
          this.activeFilters = filters;
        }
      },
    });
  }

  ngOnInit(): void {
    this.financialAccountService.setFilterParams(this.activeFilters);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngAfterViewChecked(): void {
    this.ref.detectChanges();
  }

  getFinancialAccountActivities({ page, size }: FetchData): void {
    this.isLoading = true;
    this.updateQueryParams(page);

    if (!this.financialAccountId) {
      return;
    }

    this.financialAccountService
      .getFinancialAccountActivities({
        page,
        size: size || 10,
        financialAccountId: this.financialAccountId,
        activeFilters: { ...this.activeFilters },
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response) => {
          this.emptyListMessage = 'No matching Financial Account Activity found.';
          this.onFetchComplete(response);
        },
        error: (error) => {
          this.emptyListMessage = 'Unable to fetch Account Activities.';
          this.onFetchComplete();
          ErrorUtils.catchError('financialAccountService.getFinancialAccountActivities error', error);
        },
      });
  }

  onFetchComplete(financialAccountActivities?: FinancialAccountActivities): void {
    const { items = [], totalElements = 0 } = financialAccountActivities || {};
    this.accountActivities = items;
    this.accountActivityItems = items;
    this.totalElements = totalElements;
    this.isLoading = false;
  }

  updateQueryParams(page?: RequestPageParams['page']): void {
    if (!isUndefined(page) && this.activePage !== page) {
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: { page: page > 0 ? page : undefined },
        queryParamsHandling: 'merge',
      });
    }
  }

  onSearch(event?: string) {
    if (!event) {
      this.onSearchReset();
      return;
    }
    this.searchString = event.toLowerCase();
    const filteredRows = this.accountActivityItems.filter(
      (item) => (item.transactionId && item.transactionId.toLowerCase().indexOf(this.searchString!) !== -1) || !this.searchString,
    );
    this.accountActivities = filteredRows;
    this.totalElements = filteredRows.length;
    this.updateQueryParams(0);
  }

  onSearchReset(): void {
    if (!this.searchString) {
      return;
    }

    this.accountActivities = this.accountActivityItems;
    this.totalElements = this.accountActivityItems.length;
    this.searchString = undefined;
    this.getFinancialAccountActivities({ page: this.activePage });
  }

  onTransactionIdClick({ transactionId }: FinancialAccountActivityItem): void {
    if (!transactionId) {
      return;
    }

    this.router.navigate([transactionId], {
      relativeTo: this.activatedRoute,
    });
  }

  applyFilters(filterValues: FilterValues): void {
    this.activeFilters = activeFilters(filterValues);
    this.financialAccountService.setFilterParams(this.activeFilters);
    this.getFinancialAccountActivities({ page: 0 });
  }
}
