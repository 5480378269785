import { AsyncPipe } from '@angular/common';
import { Component, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { RktIconComponent } from '@rocketfinancialcorp/rocket-ui/icon';
import { ModalService } from '@rocketfinancialcorp/rocket-ui/modal';

import { fromAuth } from '@shared/store';

import { NaturalLanguageSearchModalComponent } from '../natural-language-search-modal/natural-language-search-modal.component';
import { UserHeaderInfoComponent } from '../user-header-info/user-header-info.component';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: true,
  imports: [UserHeaderInfoComponent, AsyncPipe, RktIconComponent],
})
export class HeaderComponent {
  store = inject(Store);

  modalService = inject(ModalService);

  activeUser$ = this.store.select(fromAuth.selectUser);

  businessAccountId$ = this.store.select(fromAuth.selectBusinessAccountId);

  searchString?: string;

  openSearchModal() {
    this.modalService.open(NaturalLanguageSearchModalComponent, {
      className: 'natural-language-search-modal',
    });
  }
}
