import { HttpParams } from '@angular/common/http';
import { format, sub } from 'date-fns';
import { capitalize, compact, flatMap, isArray, orderBy, reduce } from 'lodash-es';

import { constants } from '@shared/constants';
import { CustomHttpParamEncoder } from '@shared/encoder';
import {
  AchEntryType,
  CustomerDetailRaw,
  EnabledSolutionPermissions,
  EnabledSolutionVerifiedPermissionRaw,
  EnabledSolutionsRaw,
  MCSendTransactionDetailsRaw,
  PermissionType,
  RecipientRaw,
  RequestPageParams,
  SingleLegTransactionFinancialAccount,
  TeamMember,
  TransactionBatch,
  TransactionBatchList,
  TransactionBatchListItem,
  TransactionBatchListRaw,
  TransactionBatchRaw,
  TransactionDetails,
  TransactionDetailsRaw,
  TransactionHistoryList,
  TransactionHistoryListResponse,
  TransactionLimitItem,
  TransactionLimitRawItem,
  TransactionList,
  TransactionListItem,
  TransactionListRaw,
  TransactionListRequest,
  TransactionSolution,
} from '@shared/models';
import {
  filterAmountMinMax,
  filterCreatedAtDateRange,
  getMaskedAccountNumber,
  getProcessingPriorityIconName,
  getProcessingPriorityName,
  getSolutionDisplayName,
  getSubtypeDisplayName,
  systemTimeZone,
  toTitleCase,
} from '@shared/utils';

export const mapTransactionHistoryData = (
  response: TransactionHistoryListResponse,
  teamMembersNames: Record<string, TeamMember['name'] | undefined>,
): TransactionHistoryList => {
  const descriptionMessages: Record<string, string> = {
    'Transaction status changed to ‘CANCELLED’': 'red',
    'Transaction status changed to ‘CANCELED’': 'red',
    'Transaction status changed to ‘ERROR’': 'red',
    'Transaction status changed to ‘DECLINED’': 'red',
    'Request declined due to': 'red',
    'New transaction created': 'orange',
    'Transaction status changed to ‘PROCESSING’': 'orange',
    'Transaction status changed to ‘NEW’': 'orange',
    'Transaction status changed to ‘PENDING’': 'orange',
    'Transaction status changed to ‘SETTLED’': 'green',
    'Transaction status changed to ‘CLEARED’': 'green',
    'Transaction status changed to ‘APPROVED’': 'green',
  };

  const { content = [], totalElements = 0, hasNext = false, number = 0 } = response || {};
  const items = orderBy(
    content.map((item) => {
      const employeeName = item.createdBy && teamMembersNames[item.createdBy.id] ? teamMembersNames[item.createdBy.id] : undefined;
      const splittedMessage = item.description.split(':');
      return {
        ...item,
        employeeName: employeeName ?? 'System',
        status: descriptionMessages[item.description] || descriptionMessages[splittedMessage[0]] || 'orange',
      };
    }),
    'createdAt',
    'desc',
  );

  return {
    items,
    totalElements,
    hasNext,
    number,
  };
};

export const achEntryTypeCodes: AchEntryType[] = [
  { value: 'ARC', label: 'Accounts Receivable Entries (ARC)', types: ['pull'], amountLimit: 25000 },
  { value: 'BOC', label: 'Back Office Conversion (BOC)', types: ['pull'], amountLimit: 25000 },
  { value: 'CCD', label: 'Corporate Credit or Debit (CCD)', types: ['pull', 'push'] },
  // { value: 'CIE', label: 'Customer Initiated Entries (CIE)', types: ['push'] },
  // { value: 'CTX', label: 'Corporate Trade Exchange (CTX)', types: ['pull', 'push'] },
  { value: 'POP', label: 'Point of Purchase (POP)', types: ['pull'] },
  { value: 'PPD', label: 'Pre-arranged Payment or Deposit (PPD)', types: ['pull', 'push'] },
  { value: 'RCK', label: 'Re-presented Check Entries (RCK)', types: ['pull'] },
  { value: 'TEL', label: 'Telephone Initiated Entries (TEL)', types: ['pull'] },
  { value: 'WEB', label: 'Internet Initiated/Mobile Entries (WEB)', types: ['pull', 'push'] },
];

export const achPullEntryTypeCodes = [...achEntryTypeCodes.filter((item) => item.types.includes('pull'))];

export const achPushEntryTypeCodes = [...achEntryTypeCodes.filter((item) => item.types.includes('push'))];

/**
 *  Enabled Solutions List Mapping
 */
export const mapEnabledSolutionsData = (response: EnabledSolutionsRaw): EnabledSolutionPermissions => {
  const { enabledSolutions } = response || {};
  const verifiedPermissions: EnabledSolutionVerifiedPermissionRaw[] = flatMap(enabledSolutions, 'verifiedPermissions');

  const permissions = reduce(
    verifiedPermissions,
    (prev: EnabledSolutionPermissions, item) => {
      const itemSplitted = item.actionName.split('.');
      const solution = itemSplitted[1] as TransactionSolution;
      const permissionType = itemSplitted[2] as PermissionType;

      return { ...prev, [permissionType]: { ...(prev[permissionType] ?? {}), [solution]: item.accessGranted } };
    },
    {},
  );
  return permissions;
};

/**
 * Returns the HttpParams object for making transactions API request with provided request parameters.
 * @param {TransactionListRequest} requestParams - The request parameters object containing pagination, sorting, filtering and batchId.
 * @returns {HttpParams} - The HttpParams object containing the query parameters to be sent in the request.
 */
export const transactionsRequestParams = (requestParams: TransactionListRequest): HttpParams => {
  const {
    page,
    size,
    searchString,
    sortParams,
    activeFilters,
    batchId,
    participatingFinancialAccountId,
    debitFinancialAccountId,
    creditFinancialAccountId,
    scheduledTransactionId,
    debitAuthorizationId,
    solution,
    name,
  } = requestParams;
  let params = new HttpParams({ encoder: new CustomHttpParamEncoder() });

  params = params.append('size', size ?? constants.TABLE_ROWS);

  if (page) {
    params = params.append('page', page);
  }

  if (searchString) {
    params = params.append('keyword', searchString);
  }

  if (sortParams) {
    params = params.append('sort', `${sortParams.key},${sortParams.sortDir}`);
  }

  if (batchId) {
    params = params.append('batchId', batchId);
  }

  if (participatingFinancialAccountId) {
    params = params.append(
      'participatingFinancialAccountId',
      isArray(participatingFinancialAccountId) ? participatingFinancialAccountId.join(',') : participatingFinancialAccountId,
    );
  }

  if (debitFinancialAccountId) {
    params = params.append(
      'debitFinancialAccountId',
      isArray(debitFinancialAccountId) ? debitFinancialAccountId.join(',') : debitFinancialAccountId,
    );
  }

  if (creditFinancialAccountId) {
    params = params.append(
      'creditFinancialAccountId',
      isArray(creditFinancialAccountId) ? creditFinancialAccountId.join(',') : creditFinancialAccountId,
    );
  }

  if (debitAuthorizationId) {
    params = params.append('debitAuthorizationIds', isArray(debitAuthorizationId) ? debitAuthorizationId.join(',') : debitAuthorizationId);
  }

  if (scheduledTransactionId) {
    params = params.append('scheduledTransactionId', scheduledTransactionId);
  }

  if (solution) {
    params = params.append('solution', solution);
  }

  if (name) {
    params = params.append('name', name);
  }

  if (activeFilters) {
    Object.keys(activeFilters).forEach((key) => {
      if (key === 'date') {
        const defaultRange = {
          from: format(sub(new Date(), { days: 30 }), 'yyyy-MM-dd'),
          to: format(new Date(), 'yyyy-MM-dd'),
        };
        const { from, to } = filterCreatedAtDateRange(activeFilters[key], defaultRange) ?? {};

        if (from && to) {
          params = params.append('createdDateFrom', from);
          params = params.append('createdDateTo', to);
          params = params.append('timeZone', systemTimeZone);
        }
      } else if (key === 'amount') {
        const { min, max } = filterAmountMinMax(activeFilters[key]);

        if (min && max) {
          params = params.append('amountFrom', min);
          params = params.append('amountTo', max);
        }
      } else {
        params = params.append(key, activeFilters[key]!);
      }
    });
  }

  return params;
};

export const mapLimits = (limits: TransactionLimitRawItem[]): TransactionLimitItem[] => {
  const result: TransactionLimitRawItem[] = [];
  const limitTypes = ['SINGLE', 'DAILY', 'WEEKLY', 'MONTHLY'];
  limitTypes.forEach((val) => {
    const limit = limits.find((item) => item.limitType === val);
    if (limit) {
      result.push(limit);
    }
  });

  const limitDisplayNames: Record<string, string> = {
    DAILY: '1 Day',
    WEEKLY: '7 Days',
    MONTHLY: '30 Days',
  };

  return result.map((item) => {
    const progress = item.limitAmount ? (item.deductionsTotal / item.limitAmount) * 100 : 0;
    return {
      type: item.limitType.toLowerCase(),
      label: limitDisplayNames[item.limitType] || capitalize(item.limitType),
      amount: item.limitAmount,
      usedAmount: item.deductionsTotal,
      progress: progress,
      id: item.limitId,
    };
  });
};

export const mapTransactionBatches = (transactionBatchesData: TransactionBatchListRaw): TransactionBatchList => {
  const { content = [], totalElements = 0, hasNext = false } = transactionBatchesData || {};

  const items: TransactionBatchListItem[] = content.map((item) => {
    return {
      id: item.id,
      shortId: item.id.split('-')[0],
      solutionName: getSolutionDisplayName(item.solution),
      totalVolume: item.transactionCount,
      totalAmount: item.totalAmount,
      updatedAt: item.updatedAt,
      failedTransactionCount: item.failedTransactionCount,
      failedTransactionAmount: item.failedTransactionAmount,
      processedTransactionCount: item.processedTransactionCount,
      processedTransactionAmount: item.processedTransactionAmount,
      createdAt: item.createdAt,
      status: item.status,
      transactionType: toTitleCase(item.transactionType),
      currency: item.currency,
    };
  });

  return {
    items,
    totalElements,
    hasNext,
  };
};

export const mapTransactionBatch = (transactionBatch: TransactionBatchRaw): TransactionBatch => {
  return {
    ...transactionBatch,
    shortId: transactionBatch.id?.split('-')[0],
    solutionName: getSolutionDisplayName(transactionBatch.solution),
  };
};

export const mapTransactionListData = (transactionsData?: TransactionListRaw): TransactionList => {
  const { content = [], totalElements = 0, hasNext = false } = transactionsData ?? {};

  const items: TransactionListItem[] = content.map((item) => {
    const bankNameMoveFrom = item.debitFinancialAccount?.bankAccount?.bankName;
    const bankNameMoveTo = item.creditFinancialAccount?.bankAccount?.bankName;
    const nameOnCardMoveTo = item.creditFinancialAccount?.card
      ? [item.creditFinancialAccount.card.firstName, item.creditFinancialAccount.card.lastName].join(' ')
      : '';

    const moveFromAccount = compact([
      getMaskedAccountNumber(item.maskedDebitAccountNumber),
      getSubtypeDisplayName(item.debitFinancialAccount?.subtype),
    ]).join(' | ');

    const moveToAccount = compact([
      getMaskedAccountNumber(item.maskedCreditAccountNumber),
      getSubtypeDisplayName(item.creditFinancialAccount?.subtype),
    ]).join(' | ');

    return {
      id: item.id,
      currency: item.currency,
      moveFrom: `${item.debitFinancialAccount?.name ?? ''}::${compact([bankNameMoveFrom, moveFromAccount]).join(' ')}`,
      moveTo: `${item.creditFinancialAccount?.name ?? ''}::${compact([bankNameMoveTo ?? nameOnCardMoveTo, moveToAccount]).join(' ')}`,
      solutionName: getSolutionDisplayName(item.solution),
      solution: item.solution,
      status: item.latestStatus?.status,
      amount: item.amount,
      createdAt: item.createdAt,
      transactionType: toTitleCase(item.transactionType),
      paymentReason: item.paymentReason,
    };
  });

  return {
    items,
    totalElements,
    hasNext,
  };
};

export const mapTransactionDetails = (response: TransactionDetailsRaw): TransactionDetails => {
  const { metadata = {} } = response;
  const { estLabelDate, estLabelDay, estLabelTime, estLabel } = getTransactionEstimatedDate(response) ?? {};

  return {
    ...response,
    updatedAt: response.latestStatus?.createdAt,
    processingPriority: getProcessingPriorityName(response.settlementPriority),
    processingPriorityIcon: getProcessingPriorityIconName(response.settlementPriority),
    metadataItems: Object.keys(metadata).map((key) => ({ key, value: metadata[key as keyof typeof metadata] })),
    isPushToCard: response.solution === 'push-to-card',
    isMillikartSolution: response.solution === 'millikart-transaction',
    isAch: response.solution === 'ach',
    isWire: response.solution === 'wire',
    initiatorAccountHolder: response?.transactionInitiatorDetails,
    estLabelDate,
    estLabelDay,
    estLabelTime,
    estLabel,
  };
};

const getTransactionEstimatedDate = (transaction: TransactionDetailsRaw) => {
  const deliveryOption = transaction.deliverySpeedOptions?.find(
    (item) => item.fromAccountMethod === transaction.solution && item.fromAccountProcessingPriority === transaction.settlementPriority,
  );

  if (!deliveryOption) {
    return;
  }
  return {
    estLabelDate: deliveryOption.estLabelDate,
    estLabelDay: deliveryOption.estLabelDay,
    estLabelTime: deliveryOption.estLabelTime,
    estLabel: deliveryOption.label,
  };
};

export const mapMCSendTransactionDetails = (
  transaction: TransactionDetails,
  MCSendTransaction: MCSendTransactionDetailsRaw,
): TransactionDetails => {
  return {
    ...transaction,
    ...MCSendTransaction.mcSendDetail,
    fundsAvailability: toTitleCase(MCSendTransaction.mcSendDetail?.fundsAvailability?.replace('-', ' ')),
  };
};

export const transactionBathesRequestParams = (requestParams: RequestPageParams): HttpParams => {
  const { page, size, sortParams, activeFilters } = requestParams;
  const pageSize = size ?? constants.TABLE_ROWS;

  let params = new HttpParams({ encoder: new CustomHttpParamEncoder() }).set('page', `${page ?? 0}`).set('size', `${pageSize}`);

  if (sortParams) {
    params = params.append('sort', `${sortParams.key},${sortParams.sortDir}`);
  }

  if (activeFilters) {
    Object.keys(activeFilters).forEach((key) => {
      if (key === 'createdDate') {
        const { from, to } = filterCreatedAtDateRange(activeFilters[key]) ?? {};

        if (!from || !to) {
          return;
        }

        params = params.append('createdDateFrom', from);
        params = params.append('createdDateTo', to);
        params = params.append('timeZone', systemTimeZone);
      } else {
        params = params.append(key, activeFilters[key]!);
      }
    });
  }
  return params;
};

export const mapFinancialAccountHolderInfo = (
  financialAccount: SingleLegTransactionFinancialAccount,
): SingleLegTransactionFinancialAccount => {
  const accountHolder: RecipientRaw | CustomerDetailRaw | undefined = financialAccount.customer ?? financialAccount.recipient;
  const accountHolderIndividualOrBusiness = financialAccount.customer?.type ?? financialAccount.recipient?.recipientType;
  const accountHolderBusinessName =
    (accountHolder as CustomerDetailRaw)?.doingBusinessAsName ?? (accountHolder as RecipientRaw)?.businessName;
  const accountHolderName =
    accountHolderBusinessName ??
    [accountHolder?.firstName, accountHolder?.lastName].join(' ') ??
    [financialAccount.card?.firstName, financialAccount.card?.lastName].join(' ') ??
    financialAccount.bankAccount?.nameOnAccount;
  const accountHolderType = toTitleCase(
    compact([accountHolderIndividualOrBusiness, financialAccount.accountHolderType ?? 'Business Account']).join(' '),
  );

  return { ...financialAccount, accountHolderInfo: `${accountHolderName ?? ''}::${accountHolderType ?? ''}` };
};

export const mapFinancialAccountInfo = (financialAccount: SingleLegTransactionFinancialAccount): SingleLegTransactionFinancialAccount => {
  const { maskedAccountNumber, bankAccount, name, card, subtype } = financialAccount;
  const bankName = bankAccount?.bankName;
  const nameOnCard = compact([card?.firstName, card?.lastName]).join(' ');
  const accNo = compact([getMaskedAccountNumber(maskedAccountNumber), getSubtypeDisplayName(subtype)]).join(' | ');
  const accountInfo = `${name ?? ''}::${compact([bankName ?? nameOnCard, accNo]).join(' ')}`;
  const address = card?.address ?? bankAccount?.address;

  return {
    ...financialAccount,
    accountInfo,
    address,
  };
};
