import { AsyncPipe } from '@angular/common';
import { Component } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { LoaderService } from '@shared/services';

import { LoadingBarComponent } from '../loading-bar/loading-bar.component';

@Component({
  selector: 'app-global-loader',
  templateUrl: './global-loader.component.html',
  standalone: true,
  imports: [LoadingBarComponent, AsyncPipe],
})
export class GlobalLoaderComponent {
  loading$: BehaviorSubject<boolean>;

  constructor(private loaderService: LoaderService) {
    this.loading$ = this.loaderService.isLoading$;
  }
}
