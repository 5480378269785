import { KeyValuePipe, NgFor, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { orderBy } from 'lodash-es';
import { RktIconComponent } from '@rocketfinancialcorp/rocket-ui/icon';

import { BusinessAccountAddress, CustomerAddress } from '@shared/models';
import { toTitleCase } from '@shared/utils';

import { DetailsItemComponent } from '../../details-item/details-item.component';

@Component({
  selector: 'app-address-view',
  templateUrl: 'address-view.component.html',
  styleUrls: ['address-view.component.scss'],
  standalone: true,
  imports: [NgIf, NgFor, DetailsItemComponent, KeyValuePipe, RktIconComponent],
})
export class AddressViewComponent {
  @Input() addresses?: (CustomerAddress | BusinessAccountAddress)[] = [];
  @Input() isLoading = true;

  get isEmptyAddress() {
    return this.addresses?.length === 0;
  }

  get addressesByType() {
    return {
      physicalAddresses: orderBy(this.addresses?.filter(({ type }) => type === 'PHYSICAL') ?? [], ['primary'], ['desc']),
      mailingAddresses: orderBy(this.addresses?.filter(({ type }) => type === 'MAILING') ?? [], ['primary'], ['desc']),
      shippingAddresses: orderBy(this.addresses?.filter(({ type }) => type === 'SHIPPING') ?? [], ['primary'], ['desc']),
    };
  }

  public keepOriginalOrder(): number {
    return 1;
  }

  public getAddressIconName(key: string) {
    switch (key) {
      case 'physicalAddresses':
        return 'location';
      case 'mailingAddresses':
        return 'mailing';
      case 'shippingAddresses':
        return 'shipping';
      default:
        return 'location';
    }
  }

  public getAddressTitle(key: string) {
    const type = toTitleCase(key.split('Addresses')[0]);
    return `${type} Address`;
  }

  public getAddressLabel(address: CustomerAddress | BusinessAccountAddress, index: number) {
    return address.primary ? 'Primary Address' : `Address ${index + 1}`;
  }

  public getContactFullName(address: CustomerAddress | BusinessAccountAddress) {
    const { firstName, middleName, lastName } = address.name ?? {};
    if (!firstName && !middleName && !lastName) return '';
    return `${firstName}${middleName ? ' ' + middleName : ''} ${lastName}`;
  }
}
