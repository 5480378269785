<div class="processing-summary-section" *ngFor="let idvApplicationSummaryItem of idvApplicationSummaryItems">
  <div class="header-items" (click)="toggleCollapsableItem(idvApplicationSummaryItem)">
    <div class="content">
      <rkt-icon class="menu-icon profile" [name]="getProfileIcon(idvApplicationSummaryItem?.accountTypeInfo)"></rkt-icon>
      <span class="display-text">{{ idvApplicationSummaryItem?.displayName }}</span>
    </div>

    <div class="content">
      <span class="status-tag" [ngClass]="'status-tag-' + idvApplicationSummaryItem?.entityVerificationStatus">
        {{ idvApplicationSummaryItem?.latestStatus }}
      </span>
      <rkt-icon
        class="menu-icon toggle"
        [name]="idvApplicationSummaryItem.collapseSectionExpanded ? 'chevron-up' : 'chevron-down'"
      ></rkt-icon>
    </div>
  </div>

  <div class="summary-description" *ngIf="idvApplicationSummaryItem.collapseSectionExpanded">
    <ng-container *ngIf="idvApplicationSummaryItem?.entityVerificationOutcome">
      <div class="idv-outcome-row">
        <div class="identity-application-details-outcome">
          <div
            class="identity-outcome-tag-item status-tag"
            [ngClass]="'status-tag-' + idvApplicationSummaryItem?.entityVerificationOutcome?.toLowerCase()"
          >
            {{ idvApplicationSummaryItem?.entityVerificationOutcome | titlecase }}
          </div>
        </div>

        <div class="idv-outcome-row-tags">
          <div class="identity-application-details-outcome" *ngFor="let outcomeDetailsTag of idvApplicationSummaryItem?.outcomeDetailsTags">
            <div class="identity-outcome-tag-item">
              <div class="status-icon" [ngClass]="'status-icon-' + getTagColor(outcomeDetailsTag)"></div>
              {{ outcomeDetailsTag }}
            </div>
          </div>
        </div>
      </div>

      <div class="separator-line"></div>
    </ng-container>

    <div class="details-row">
      <app-details-item
        [class]="pageClassPrefix + 'processing-summary-display-name'"
        [showPlaceholder]="loading"
        [label]="isBusinessCustomer(idvApplicationSummaryItem) ? 'Business Legal Name' : 'Name'"
        [value]="idvApplicationSummaryItem?.displayName"
      ></app-details-item>

      <app-details-item
        *ngIf="!isBusinessCustomer(idvApplicationSummaryItem)"
        [class]="pageClassPrefix + 'processing-summary-date-of-birth'"
        [showPlaceholder]="loading"
        label="DOB"
        [value]="idvApplicationSummaryItem?.dateOfBirth | dateFormat: 'MM/dd/yy' : 'ISO'"
      ></app-details-item>

      <app-details-item
        *ngIf="!isBusinessCustomer(idvApplicationSummaryItem)"
        [class]="pageClassPrefix + 'processing-summary-email'"
        [showPlaceholder]="loading"
        label="Email"
        [value]="idvApplicationSummaryItem?.emailAddress"
      ></app-details-item>

      <app-details-item
        [class]="pageClassPrefix + 'processing-summary-phone'"
        [showPlaceholder]="loading"
        label="Phone"
        [value]="idvApplicationSummaryItem?.phoneNumber"
      ></app-details-item>

      <app-details-item
        [class]="pageClassPrefix + 'processing-summary-address'"
        [showPlaceholder]="loading"
        label="Address"
        [value]="idvApplicationSummaryItem?.address?.addressLine1"
      >
        <ng-container *ngIf="idvApplicationSummaryItem?.address?.addressLine1">
          <div>{{ idvApplicationSummaryItem?.address?.addressLine2 }}</div>
          <div>{{ idvApplicationSummaryItem?.address?.city }}, {{ idvApplicationSummaryItem?.address?.state }}</div>
          <div>{{ idvApplicationSummaryItem?.address?.postalCode }} {{ idvApplicationSummaryItem?.address?.country }}</div>
        </ng-container>
      </app-details-item>

      <app-details-item
        [class]="pageClassPrefix + 'processing-summary-documents'"
        [showPlaceholder]="loading"
        label="Documents"
        [contentValue]="true"
        *ngIf="!isBusinessCustomer(idvApplicationSummaryItem)"
      >
        <ng-container *ngIf="applicationHasHostedDocumentLink(idvApplicationSummaryItem); else nonHostedApplication">
          <div class="document-item idv-upload" (click)="uploadDocumentModal(idvApplicationSummaryItem)">
            <rkt-icon class="menu-icon link-icon" [name]="'link'"></rkt-icon>
            <span class="document-link">Upload document link</span>
          </div>
        </ng-container>

        <ng-template #nonHostedApplication>
          <ng-container *ngIf="idvApplicationSummaryItem?.documentList?.length; else emptyDocList">
            <div class="document-item" [title]="document" *ngFor="let document of idvApplicationSummaryItem?.documentList">
              <rkt-icon class="menu-icon" [name]="getProfileIcon(document)"></rkt-icon>
              <span class="display-text">{{ document | titlecase }}</span>
            </div>
          </ng-container>

          <ng-template #emptyDocList>
            <span class="display-text">N/A</span>
          </ng-template>
        </ng-template>
      </app-details-item>
    </div>
  </div>
</div>
