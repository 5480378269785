import { NgIf } from '@angular/common';
import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subject, finalize, takeUntil } from 'rxjs';
import { RktButtonDirective } from '@rocketfinancialcorp/rocket-ui/button';
import {
  RktFormComponent,
  formInput,
  formInputPhone,
  formRadioGroup,
  formRow,
  formSelect,
  formSelectMulti,
} from '@rocketfinancialcorp/rocket-ui/form';
import { RktIconComponent } from '@rocketfinancialcorp/rocket-ui/icon';
import { ActiveModal } from '@rocketfinancialcorp/rocket-ui/modal';

import { Patterns } from '@shared/components';
import { ALERT_TYPES, THRESHOLD_TYPES } from '@shared/enums';
import { CardAlert, CreateAlertData, FormModel } from '@shared/models';
import { CardAccountService, NotificationService } from '@shared/services';
import { MccActions, fromFinancialAccount } from '@shared/store';
import { ErrorUtils } from '@shared/utils';

interface AlertCreateModel {
  id?: string;
  financialAccountId: string;
  useEmail: boolean;
  email: string;
  useContactNumber: boolean;
  mobileContactNumber: string;
  type: string;
  active: boolean;
  merchantCategoryCodes: string[];
  thresholdType: string;
  thresholdAmount: string;
}

@Component({
  selector: 'app-alert-create-modal',
  templateUrl: './alert-create-modal.component.html',
  standalone: true,
  imports: [RktButtonDirective, FormsModule, ReactiveFormsModule, RktFormComponent, NgIf, RktIconComponent],
})
export class AlertCreateModalComponent implements AfterViewChecked, OnDestroy {
  loading = false;

  isEdit = false;

  submitError = '';

  validationError = '';

  formModel: FormModel<AlertCreateModel> = { active: true };

  alertForm = new FormGroup({});

  mccCodes$ = this.store.select(fromFinancialAccount.selectMccItems);

  alertFields = [
    formRow([
      formRadioGroup({
        key: 'useEmail',
        label: 'Use Email',
        className: 'yes-no-field single-row-radio-group',
        props: {
          options: [
            { label: 'Yes', value: true },
            { label: 'No', value: false },
          ],
          required: true,
        },
      }),
      formInput({
        key: 'email',
        label: 'Email Address',
        props: { type: 'email', required: false, pattern: Patterns.EMAIL, minLength: 1, maxLength: 100 },
        validation: { messages: { required: 'Email is required', pattern: 'Enter a valid email' } },
        expressions: { 'props.required': 'model.useEmail' },
      }),
    ]),
    formRow([
      formRadioGroup({
        key: 'useContactNumber',
        label: 'Use Mobile Number',
        className: 'yes-no-field single-row-radio-group',
        props: {
          options: [
            { label: 'Yes', value: true },
            { label: 'No', value: false },
          ],
          required: true,
        },
      }),
      formInputPhone({
        key: 'mobileContactNumber',
        label: 'Mobile Number',
        props: { required: false, minLength: 10, maxLength: 40, countryRestrictions: ['US'] },
        validators: { validation: [{ name: 'isValidPhoneNumber' }] },
        expressions: { 'props.required': 'model.useContactNumber' },
      }),
    ]),
    formRow([
      formSelect({
        key: 'type',
        label: 'Alert Type',
        props: { options: Object.keys(ALERT_TYPES).map((key) => ({ label: ALERT_TYPES[key], value: key })), required: true },
      }),
    ]),
    formRow(
      [
        formSelectMulti({
          key: 'merchantCategoryCodes',
          label: 'Merchant Category Codes',
          props: { options: this.mccCodes$, required: true },
          expressions: {
            'props.required': 'model?.type === "MCC"',
            hide: 'model?.type !== "MCC"',
          },
        }),
      ],
      { className: 'row-with-separator' },
    ),
    formRow([
      formSelect({
        key: 'thresholdType',
        label: 'Threshold Type',
        props: { options: Object.keys(THRESHOLD_TYPES).map((key) => ({ label: THRESHOLD_TYPES[key], value: key })), required: true },
        expressions: {
          'props.required': 'model?.type === "BALANCE_THRESHOLD"',
          hide: 'model?.type !== "BALANCE_THRESHOLD"',
        },
      }),
    ]),
    formRow([
      formInput({
        key: 'thresholdAmount',
        label: 'Threshold Amount',
        className: 'alert-create-amount',
        props: {
          required: true,
          pattern: /^\d+$|(?=^.+$)^\d+\.\d{0,2}$/,
        },
        validation: {
          messages: {
            pattern: 'Please enter a valid amount.',
          },
        },
        expressions: {
          'props.required': 'model?.type === "BALANCE_THRESHOLD"',
          hide: 'model?.type !== "BALANCE_THRESHOLD"',
        },
      }),
    ]),
    formRadioGroup({
      key: 'active',
      label: 'Active',
      className: 'single-row-radio-group',
      props: {
        options: [
          { label: 'Yes', value: true },
          { label: 'No', value: false },
        ],
        required: true,
      },
    }),
  ];

  private destroy$ = new Subject<void>();

  constructor(
    public activeModal: ActiveModal,
    private ref: ChangeDetectorRef,
    private cardAccountService: CardAccountService,
    private notificationService: NotificationService,
    private store: Store,
  ) {}

  modalInitData(alert: Partial<CardAlert>) {
    this.store.dispatch(MccActions.load());

    const {
      id,
      financialAccountId,
      active,
      useEmail,
      useContactNumber,
      email,
      mobileContactNumber,
      type,
      thresholdDto,
      merchantCategoryCodes,
    } = alert;

    if (id) {
      this.isEdit = true;

      this.formModel = {
        ...this.formModel,
        financialAccountId,
        id,
        active,
        useEmail,
        useContactNumber,
        email,
        mobileContactNumber,
        type,
        thresholdAmount: thresholdDto?.amount,
        thresholdType: thresholdDto?.type,
        merchantCategoryCodes,
      };
    } else {
      this.formModel = { ...this.formModel, financialAccountId };
    }
  }

  ngAfterViewChecked() {
    this.ref.detectChanges();
  }

  ngOnDestroy(): void {
    this.alertForm.markAsUntouched();
    this.destroy$.next();
    this.destroy$.complete();
  }

  onModelChange() {
    const { useEmail, useContactNumber } = this.formModel;

    if (useEmail === false && useContactNumber === false) {
      this.validationError = 'Please choose at least one alert option: Email or Mobile Number.';
      return;
    }

    this.validationError = '';
  }

  onSubmit(): void {
    const {
      id,
      financialAccountId,
      useEmail,
      email,
      useContactNumber,
      mobileContactNumber,
      type,
      active,
      merchantCategoryCodes,
      thresholdType,
      thresholdAmount,
    } = this.formModel;

    if (!financialAccountId || !type) {
      return;
    }

    const createAlertData: CreateAlertData = {
      financialAccountId,
      useEmail: useEmail!,
      email,
      useContactNumber: useContactNumber!,
      mobileContactNumber: mobileContactNumber?.replace(/[ +]/gu, ''),
      type,
      active: active!,
      merchantCategoryCodes: merchantCategoryCodes?.length ? merchantCategoryCodes : undefined,
      thresholdDto:
        thresholdAmount && thresholdType
          ? {
              type: thresholdType,
              amount: thresholdAmount,
            }
          : undefined,
      notifyOption: 'PARENT',
    };

    this.loading = true;

    if (this.isEdit) {
      this.cardAccountService
        .editAlert(id!, createAlertData)
        .pipe(
          takeUntil(this.destroy$),
          finalize(() => {
            this.loading = false;
          }),
        )
        .subscribe({
          next: () => {
            this.activeModal.close();
            this.notificationService.displaySuccess('Alert Details has been successfully updated');
          },
          error: (error) => {
            this.submitError = ErrorUtils.parseExtendedError(error);
            ErrorUtils.catchError('cardAccountService.editAlert error', this.submitError);
          },
        });
    } else {
      this.cardAccountService
        .createAlert(createAlertData)
        .pipe(
          takeUntil(this.destroy$),
          finalize(() => {
            this.loading = false;
          }),
        )
        .subscribe({
          next: () => {
            this.activeModal.close();
            this.notificationService.displaySuccess('Alert successfully added');
          },
          error: (error) => {
            this.submitError = ErrorUtils.parseExtendedError(error);
            ErrorUtils.catchError('cardAccountService.createAlert error', this.submitError);
          },
        });
    }
  }
}
