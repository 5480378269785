<div class="attachments-container">
  <div class="attachments-header">
    <div class="attachments-title">
      Attachments ({{ totalElements }})
      @if (canUpdateAttachment()) {
        <button
          rktButton
          btnType="link"
          class="attachments-btn"
          (click)="onAddAttachment()"
          [disabled]="!!(loading | accessControl | async)"
        >
          <rkt-icon name="plus"></rkt-icon>
          Add
        </button>
      }
    </div>
    <div class="attachment-toggle-buttons">
      <button
        rktButton
        btnType="link"
        class="attachment-toggle-btn"
        [class.active]="attachmentsView === 'tiles'"
        (click)="onToggleAttachmentsView('tiles')"
      >
        <rkt-icon name="tiles"></rkt-icon>
      </button>
      <button
        rktButton
        btnType="link"
        class="attachment-toggle-btn"
        [class.active]="attachmentsView === 'list'"
        (click)="onToggleAttachmentsView('list')"
      >
        <rkt-icon name="list"></rkt-icon>
      </button>
    </div>
  </div>
  @if (showSearch()) {
    <rkt-table-search (changed)="onSearch($event)" placeholder="Search by Name / ID"></rkt-table-search>
  }
</div>

@if (!loading && attachments.length === 0) {
  <div class="empty-container">
    <div class="empty-container-icon">
      <div class="empty-list-placeholder"></div>
    </div>
    <div class="empty-container-description">No attachments yet</div>
  </div>
} @else {
  <ng-container *ngTemplateOutlet="attachmentsView === 'list' ? listView : carouselView"></ng-container>
}

<ng-template #listView>
  <rkt-table
    [rows]="attachments"
    [columns]="attachmentsColumns()"
    [limit]="10"
    (fetchData)="getAttachments($event)"
    (rowActivated)="onRowClick($event)"
    (colButtonClicked)="onColButtonClick($event)"
    [count]="totalElements"
    [loadingIndicator]="loading"
    [offset]="activePage"
    [sortParam]="sortParams"
    dateTimeFormat="MM/dd/yy HH:mm"
    [messages]="{ emptyMessage: 'No Attachments found.' }"
    emptyValuePlaceholder="--"
  >
  </rkt-table>
</ng-template>

<ng-template #carouselView>
  <rkt-carousel [items]="attachments" [responsiveBreakpoints]="responsiveBreakpoints">
    <ng-template let-item rktCarouselItem="item">
      <div class="file-item" (click)="onRowClick(item)">
        <div class="file-name">
          <rkt-icon [name]="'attachment'"></rkt-icon>
          <span class="file-name-text">{{ item.name }}</span>
        </div>
        <div class="file-image">
          <img [src]="item.thumbnailUrl?.value" *ngIf="item.thumbnailUrl?.value" />

          <div class="banner-text">{{ getAttachmentScanLabel(item) }}</div>
          @if (canUpdateAttachment()) {
            <div class="buttons-overlay">
              <div class="action-buttons">
                <button
                  class="action-button"
                  [disabled]="false | accessControl | async"
                  (click)="onActionButtonClick('DELETE', item.id, $event)"
                >
                  <rkt-icon class="trash-button" name="trash"></rkt-icon>
                </button>

                <button
                  class="action-button"
                  [disabled]="false | accessControl | async"
                  (click)="onActionButtonClick('EDIT', item.id, $event)"
                >
                  <rkt-icon class="edit-button" name="edit"></rkt-icon>
                </button>

                <button
                  class="action-button"
                  [disabled]="false | accessControl | async"
                  (click)="onActionButtonClick('DOWNLOAD', item.id, $event)"
                >
                  <rkt-icon class="download-button" name="download"></rkt-icon>
                </button>
              </div>
            </div>
          }
        </div>
      </div>
    </ng-template>
  </rkt-carousel>
</ng-template>
