import { createAction, props } from '@ngrx/store';

import { EnabledSolutionConfig, EnabledSolutionPermissions } from '@shared/models';

export namespace TransactionSolutionActions {
  export const loadEnabledSolutions = createAction('[Transaction Solutions API] Load Enabled Solutions');

  export const loadEnabledSolutionsSuccess = createAction(
    '[Transaction Solutions API] Load Enabled Solutions Success',
    props<{ enabledSolutions: EnabledSolutionPermissions }>(),
  );

  export const loadEnabledSolutionsFailure = createAction(
    '[Transaction Solutions API] Load Enabled Solutions Failure',
    props<{ error: string }>(),
  );

  export const loadEnabledSolutionConfigs = createAction('[Transaction Solutions API] Load Enabled Solution Configs');

  export const loadEnabledSolutionConfigsSuccess = createAction(
    '[Transaction Solutions API] Load Enabled Solution Configs Success',
    props<{ enabledSolutionConfigs: EnabledSolutionConfig[] }>(),
  );

  export const loadEnabledSolutionConfigsFailure = createAction(
    '[Transaction Solutions API] Load Enabled Solution Configs Failure',
    props<{ error: string }>(),
  );
}
