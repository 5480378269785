import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { ErrorMessage, FinancialAccountDetails } from '@shared/models';

export const FinancialAccountDetailsActions = createActionGroup({
  source: 'Financial Account Details',
  events: {
    'Load Financial Account Details': emptyProps,
    'Load Financial Account Details Success': props<{ financialAccountDetails: FinancialAccountDetails }>(),
    'Load Financial Account Details Failure': props<{ error: ErrorMessage }>(),
  },
});
