import { DecimalPipe, NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { NgxMaskPipe } from 'ngx-mask';
import { RktButtonDirective } from '@rocketfinancialcorp/rocket-ui/button';
import { ActiveModal } from '@rocketfinancialcorp/rocket-ui/modal';
import { ShortIdPipe } from '@rocketfinancialcorp/rocket-ui/table';

import { Address } from '@shared/models';

import { AttentionModalComponent } from '../../../../shared/components/attention-modal/attention-modal.component';
import { AmountPipe } from '../../../../shared/pipes/amount.pipe';
import { DateFormatPipe } from '../../../../shared/pipes/date-format.pipe';
import { PrefixPipe } from '../../../../shared/pipes/prefix.pipe';

interface MoveMoneySuccess {
  id?: string;
  solution?: string;
  settlementPriority?: string;
  fromAccountName?: string;
  fromAccountNumber?: string;
  fromAccountBalance?: string;
  toAccountName?: string;
  toAccountNumber?: string;
  toAccountBalance?: string;
  toAccountAddress?: Address;
  reason?: string;
  amount?: string;
  title?: string;
  name?: string;
  scheduleDateTimeDisplayValue?: string;
  scheduleRecurringDisplayValue?: string;
  transactionCurrency?: string;
}

@Component({
  selector: 'app-move-money-modal',
  templateUrl: './move-money-modal.component.html',
  standalone: true,
  imports: [
    RktButtonDirective,
    AttentionModalComponent,
    NgIf,
    DecimalPipe,
    PrefixPipe,
    DateFormatPipe,
    AmountPipe,
    ShortIdPipe,
    NgxMaskPipe,
  ],
})
export class MoveMoneyModalComponent {
  id?: string;

  solution?: string;

  settlementPriority?: string;

  fromAccountName?: string;

  fromAccountNumber?: string;

  fromAccountBalance?: string;

  toAccountName?: string;

  toAccountNumber?: string;

  toAccountBalance?: string;

  toAccountAddress?: Address;

  reason?: string;

  amount?: string;

  name?: string;

  scheduleDateTimeDisplayValue?: string;

  scheduleRecurringDisplayValue?: string;

  transactionCurrency?: string;

  title = 'Transaction Successful!';

  constructor(public activeModal: ActiveModal) {}

  modalInitData(data: MoveMoneySuccess) {
    const {
      id,
      solution,
      settlementPriority,
      fromAccountName,
      fromAccountNumber,
      fromAccountBalance,
      toAccountName,
      toAccountNumber,
      toAccountBalance,
      toAccountAddress,
      reason,
      amount,
      title,
      name,
      scheduleDateTimeDisplayValue,
      scheduleRecurringDisplayValue,
      transactionCurrency,
    } = data ?? {};

    this.id = id;
    this.solution = solution;
    this.settlementPriority = settlementPriority;
    this.fromAccountName = fromAccountName;
    this.fromAccountNumber = fromAccountNumber;
    this.fromAccountBalance = fromAccountBalance;
    this.toAccountName = toAccountName;
    this.toAccountNumber = toAccountNumber;
    this.toAccountBalance = toAccountBalance;
    this.toAccountAddress = toAccountAddress;
    this.reason = reason;
    this.amount = amount;
    this.name = name;
    this.scheduleDateTimeDisplayValue = scheduleDateTimeDisplayValue;
    this.scheduleRecurringDisplayValue = scheduleRecurringDisplayValue;
    this.transactionCurrency = transactionCurrency;

    if (title) {
      this.title = title;
    }
  }
}
