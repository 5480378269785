import { Pipe, PipeTransform } from '@angular/core';

import { Currency, CurrencyCode } from '@shared/utils';

@Pipe({
  name: 'currencySign',
  standalone: true,
})
export class CurrencySignPipe implements PipeTransform {
  transform(value?: string): string | undefined {
    if (!value) {
      return value;
    }

    const currencyKey = value as CurrencyCode;

    return (Currency[currencyKey] && Currency[currencyKey].symbol) || value;
  }
}
