import { NgFor, NgIf } from '@angular/common';
import { AfterViewChecked, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { Subject, finalize, takeUntil } from 'rxjs';
import { RktButtonDirective } from '@rocketfinancialcorp/rocket-ui/button';
import { RktCarouselComponent, RktCarouselItemDirective } from '@rocketfinancialcorp/rocket-ui/carousel';
import { RktIconComponent } from '@rocketfinancialcorp/rocket-ui/icon';
import { ModalService } from '@rocketfinancialcorp/rocket-ui/modal';

import { AttachmentCreateFormComponent, LinxConfirmModalComponent, ViewAttachmentModalComponent } from '@shared/components';
import {
  AttachmentCreateModel,
  AttachmentDetails,
  AttachmentEntityType,
  AttachmentList,
  AttachmentListItem,
  AttchmentUpdateModel,
} from '@shared/models';
import { AttachmentService, NotificationService } from '@shared/services';
import { ErrorUtils } from '@shared/utils';

@Component({
  selector: 'app-note-item-attachments',
  templateUrl: './note-item-attachments.component.html',
  styleUrls: ['./note-item-attachments.component.scss'],
  standalone: true,
  imports: [RktButtonDirective, NgIf, NgFor, RktIconComponent, RktCarouselComponent, RktCarouselItemDirective],
})
export class NoteItemAttachmentsComponent implements AfterViewChecked, OnDestroy {
  @Input() entityId?: string | null;
  @Input() attachments: AttachmentList['items'] = [];
  @Input() isEdit = false;
  @Output() addAttachment = new EventEmitter<Partial<AttachmentCreateModel>>();

  entityType: AttachmentEntityType = 'NOTE';
  loading = false;

  attachmentsView = 'tiles';

  private destroy$ = new Subject<void>();

  constructor(
    public ref: ChangeDetectorRef,
    private attachmentService: AttachmentService,
    private notificationService: NotificationService,
    private modalService: ModalService,
  ) {}

  ngAfterViewChecked(): void {
    this.ref.detectChanges();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getAttachments(): void {
    if (!this.entityId || !this.entityType) {
      return;
    }
    this.loading = true;
    this.attachmentService
      .getAttachments({
        entityType: this.entityType,
        entityId: this.entityId,
      })
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => (this.loading = false)),
      )
      .subscribe({
        next: (response) => {
          const { items = [] } = response ?? {};
          this.attachments = items;
        },
        error: (error) => {
          this.notificationService.displayError('Unable to fetch Attachments.', 'Error');
          ErrorUtils.catchError('attachmentService.getAttachments', error);
        },
      });
  }

  onAddAttachment(): void {
    const documentCreateFormModalRef = this.modalService.open(AttachmentCreateFormComponent, {
      className: 'entity-form-modal',
    });

    documentCreateFormModalRef.result.then(
      (result) => {
        if (result) this.addAttachment.emit(result);
      },
      () => false,
    );
  }

  createAttachment(attachmentData: AttachmentCreateModel): void {
    if (!this.entityId || !this.entityType) {
      return;
    }

    this.loading = true;

    this.attachmentService
      .createAttachment({
        ...attachmentData,
        entityType: this.entityType,
        entityId: this.entityId,
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.getAttachments();
          this.showConfirmationModal('Attachment Uploaded Successfully', 'Upload Successful');
        },
        error: (error) => {
          this.showConfirmationModal('Unable to create Attachment. Try Again', 'Upload Failed', 'error');
          ErrorUtils.catchError('attachmentService.createAttachment', error);
        },
      });
  }

  onViewAttachmentCLick({ id }: AttachmentListItem): void {
    this.attachmentService
      .getAttachmentById(id)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response) => {
          this.viewAttachment(response);
        },
        error: (error) => {
          this.notificationService.displayError('Unable to fetch Attachment.', 'Error');
          ErrorUtils.catchError('attachmentService.getAttachmentById', error);
        },
      });
  }

  viewAttachment(attachment: AttachmentDetails): void {
    const viewAttachmentModalRef = this.modalService.open(ViewAttachmentModalComponent, {
      className: 'view-attachment-modal',
      size: 'lg',
    });
    viewAttachmentModalRef.componentInstance.modalInitData(attachment);

    viewAttachmentModalRef.result.then(
      (result) => {
        if (result) {
          this.onActionButtonClick(result, attachment.id);
        }
      },
      () => false,
    );
  }

  onActionButtonClick(action: string, attachmentId: AttachmentDetails['id'], event?: Event): void {
    event?.stopPropagation();
    switch (action) {
      case 'DELETE':
        this.showConfirmationModal(
          'Are you sure you want to delete this Attachment? This cannot be undone.',
          'Delete Attachment',
          'error',
          action,
          attachmentId,
        );
        break;
      case 'DOWNLOAD':
        this.attachmentService.downloadAttachment(attachmentId);
        break;
      case 'EDIT':
        this.onUpdateAttachment(attachmentId);
        break;
    }
  }

  deleteAttachment(attachmentId: AttachmentDetails['id']): void {
    this.attachmentService
      .deleteAttachment(attachmentId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.getAttachments();
          this.showConfirmationModal('Attachment Deleted Successfully', 'Delete Successful');
        },
        error: (error) => {
          this.showConfirmationModal('Unable to Delete Attachment. Try Again', 'Delete Failed', 'error');
          ErrorUtils.catchError('attachmentService.deleteAttachment', error);
        },
      });
  }

  onUpdateAttachment(attachmentId: AttachmentDetails['id']): void {
    const selectedAttachment = this.attachments.find((attachment) => attachment.id === attachmentId);
    const documentCreateFormModalRef = this.modalService.open(AttachmentCreateFormComponent, {
      className: 'entity-form-modal',
    });
    documentCreateFormModalRef.componentInstance.isEditMode = true;
    documentCreateFormModalRef.componentInstance.formModel = {
      name: selectedAttachment?.name,
      description: selectedAttachment?.description,
    };

    documentCreateFormModalRef.result.then(
      (result) => {
        if (result) this.updateAttachment(attachmentId, result);
      },
      () => false,
    );
  }

  updateAttachment(attachmentId: AttachmentDetails['id'], formData: AttchmentUpdateModel): void {
    this.attachmentService
      .updateAttachment(attachmentId, formData)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.getAttachments();
          this.showConfirmationModal('Attachment Updated Successfully', 'Update Successful');
        },
        error: (error) => {
          this.showConfirmationModal('Unable to Update Attachment. Try Again', 'Update Failed', 'error');
          ErrorUtils.catchError('attachmentService.updateAttachment', error);
        },
      });
  }

  showConfirmationModal(message: string, title: string, confirmType = 'success', action?: string, attachmentId?: string) {
    const errorModalRef = this.modalService.open(LinxConfirmModalComponent, {
      className: 'confirm-modal',
    });
    errorModalRef.componentInstance.title = title;
    errorModalRef.componentInstance.customText = message;
    errorModalRef.componentInstance.confirmType = confirmType;
    errorModalRef.componentInstance.confirmBtnText = action ? 'Confirm' : 'Close';
    errorModalRef.componentInstance.showCancelButton = !!action;

    errorModalRef.result.then(
      (result) => {
        if (result && action === 'DELETE' && attachmentId) {
          this.deleteAttachment(attachmentId);
        }
      },
      () => false,
    );
  }

  onToggleAttachmentsView(view: string): void {
    this.attachmentsView = view;
  }

  getAttachmentScanLabel(attachment: AttachmentDetails): string {
    switch (attachment.status) {
      case 'NEW':
        return 'No File Available';

      case 'UPLOADED':
        return 'Scanning for Viruses';

      case 'MALICIOUS':
      case 'INCOMPLETE':
        return 'File cannot be Displayed.';

      case 'SCANNED':
      default:
        return '';
    }
  }
}
