import { HttpInterceptorFn } from '@angular/common/http';

export const JwtInterceptor: HttpInterceptorFn = (req, next) => {
  const authReq = req.clone({
    setHeaders: {
      'Access-Control-Allow-Credentials': 'true',
    },
    withCredentials: true,
  });

  return next(authReq);
};
