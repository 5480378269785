import { AsyncPipe, LowerCasePipe, NgIf, TitleCasePipe, UpperCasePipe } from '@angular/common';
import { Component, OnDestroy, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject, takeUntil, tap } from 'rxjs';
import { RktButtonDirective } from '@rocketfinancialcorp/rocket-ui/button';
import { RktIconComponent } from '@rocketfinancialcorp/rocket-ui/icon';
import { ModalService } from '@rocketfinancialcorp/rocket-ui/modal';

import {
  ErrorModalComponent,
  FinancialAccountVerificationComponent,
  FinancialAccountVerificationIframeComponent,
} from '@shared/components';
import { ConfirmModalComponent } from '@shared/components/confirm-modal/confirm-modal.component';
import { FinancialAccountDetails, FinancialAccountHolderType, FlinksConnectionRequest } from '@shared/models';
import { AccessControlPipe, AmountPipe, DateFormatPipe, TeamMemberNamePipe } from '@shared/pipes';
import { FinancialAccountService, NotificationService } from '@shared/services';
import { fromAuth, selectFinancialAccountDetails } from '@shared/store';
import { ErrorUtils, getCountryCodeFromCurrency } from '@shared/utils';

import { DetailsItemComponent } from '../../details-item/details-item.component';
import { DetailsNotesComponent } from '../../details-notes/details-notes.component';
import { TextWithPlaceholderComponent } from '../../text-with-placeholder/text-with-placeholder.component';
import { FinancialAccountAcceptedDocumentsComponent } from '../financial-account-accepted-documents/financial-account-accepted-documents.component';

@Component({
  selector: 'app-financial-account-details',
  templateUrl: 'financial-account-details.component.html',
  styleUrls: ['financial-account-details.component.scss'],
  standalone: true,
  imports: [
    RktButtonDirective,
    NgIf,
    DetailsItemComponent,
    TextWithPlaceholderComponent,
    DetailsNotesComponent,
    FinancialAccountAcceptedDocumentsComponent,
    AsyncPipe,
    UpperCasePipe,
    LowerCasePipe,
    TitleCasePipe,
    DateFormatPipe,
    AmountPipe,
    TeamMemberNamePipe,
    AccessControlPipe,
    RktIconComponent,
  ],
})
export class FinancialAccountDetailsComponent implements OnDestroy {
  store = inject(Store);

  public financialAccountId?: FinancialAccountDetails['id'];

  public financialAccount: FinancialAccountDetails | null = null;

  activeUser$ = this.store.select(fromAuth.selectUser);

  financialAccountDetails$ = this.store.select(selectFinancialAccountDetails).pipe(
    tap({
      next: (financialAccount) => {
        this.financialAccountId = financialAccount?.id;
        this.verificationStatus = financialAccount?.verificationStatus;
        this.financialAccount = financialAccount;
      },
    }),
  );

  public entityType?: FinancialAccountHolderType;

  public unmaskedAccountNumber?: string;

  public timeoutId?: ReturnType<typeof setTimeout>;

  public isEscrowAccount = this.router.url.split('/').includes('escrow-account-details');

  get accountNumber(): string {
    return this.unmaskedAccountNumber ?? '**** ' + this.financialAccount?.bankAccount?.accountNumberTail;
  }

  get isIntegrated(): boolean {
    return this.financialAccount?.category === 'INTEGRATED';
  }

  get isBankAccount(): boolean {
    return this.financialAccount?.cardOrBankAccount === 'bankAccount';
  }

  get isVerifiedByFlinks(): boolean {
    return this.financialAccount?.verificationStatus === 'APPROVED' && this.financialAccount?.verificationMethod === 'AGGREGATION';
  }

  get isEligibleForVerification(): boolean {
    const statusForVerification = ['UNVERIFIED', 'APPROVED', 'DECLINED'];
    return (
      this.financialAccount?.state === 'ACTIVE' &&
      statusForVerification.includes(this.verificationStatus ?? 'UNVERIFIED') &&
      this.financialAccount?.category === 'EXTERNAL' &&
      this.isBankAccount &&
      this.financialAccount?.subtype === 'CHECKING'
    );
  }

  verificationStatus?: string;

  private destroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private financialAccountService: FinancialAccountService,
    private notificationService: NotificationService,
    private activatedRoute: ActivatedRoute,
    private modalService: ModalService,
  ) {
    const { snapshot } = this.activatedRoute.parent ?? {};
    this.entityType = snapshot?.data['accountHolderType'];
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  loadFinancialAccountDetails() {
    if (!this.financialAccountId) return;
    this.financialAccountService
      .getFinancialAccountDetailsById(this.financialAccountId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (financialAccountDetails) => {
          this.unmaskedAccountNumber = financialAccountDetails?.bankAccount?.accountNumber;
          this.timeoutId = setTimeout(() => this.hideFinancialAccountDetails(), 30000);
        },
        error: (error) => {
          this.notificationService.displayWarning('Error Message: System is unable to retrieve details of the Financial Account');
          ErrorUtils.catchError('financialAccountService.getFinancialAccountDetailsById error', error);
        },
      });
  }

  hideFinancialAccountDetails() {
    if (this.timeoutId) clearTimeout(this.timeoutId);
    this.unmaskedAccountNumber = undefined;
  }

  onRequestAccountVerification(): void {
    const verificationModal = this.modalService.open(FinancialAccountVerificationComponent, {
      className: 'entity-form-modal',
    });

    verificationModal.result.then(
      (verificationType: string) => {
        if (verificationType) {
          this.confirmAccountVerification(verificationType);
        }
      },
      () => false,
    );
  }

  confirmAccountVerification(verificationType: string): void {
    const confirmModalRef = this.modalService.open(ConfirmModalComponent, { className: 'confirm-modal' });
    confirmModalRef.componentInstance.customText = 'Are you sure you want to submit a Bank Account Verification?';

    confirmModalRef.result.then(
      (result) => {
        if (result) {
          switch (verificationType) {
            case 'ACH':
              this.verifyAccountWithACH();
              break;
            case 'AGGREGATION':
              this.verifyAccountWithAggregation();
              break;
            default:
              break;
          }
        }
      },
      () => false,
    );
  }

  verifyAccountWithACH(): void {
    this.financialAccountService
      .verifyFinancialAccount(this.financialAccount?.id ?? '')
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response) => {
          if (response?.verificationResult[0].errorMessage) {
            this.notificationService.displayError(response?.verificationResult[0].errorMessage);
            ErrorUtils.catchError('financialAccountService.verifyFinancialAccount error', response?.verificationResult[0].errorMessage);
          } else {
            this.verificationStatus = response.verificationResult[0].status;
            this.notificationService.displaySuccess('Bank Account Verification requested.', 'Success');
          }
        },
        error: (error) => {
          this.notificationService.displayError('Bank Account Verification request failed', 'Error');
          ErrorUtils.catchError('financialAccountService.verifyFinancialAccount error', error);
        },
      });
  }

  verifyAccountWithAggregation(): void {
    this.financialAccountService.getEnabledSolutions().subscribe({
      next: (response) => {
        if (response?.enabledSolutions.find((solution) => solution.solutionName === 'financial-account-aggregation')) {
          this.externalAccountVerification();
        } else {
          const errorModalRef = this.modalService.open(ErrorModalComponent, { className: 'confirm-modal' });
          errorModalRef.componentInstance.modalInitData({
            modalTitle: 'No Access to Aggregation',
            messageText: `You don't have access to this verification.`,
            btnText: 'Back to Method',
          });
        }
      },
    });
  }

  externalAccountVerification() {
    if (this.financialAccount) {
      const { id, bankAccount, currency, businessAccountId } = this.financialAccount;

      const configData: FlinksConnectionRequest = {
        financialAccountId: id,
        countryCode: getCountryCodeFromCurrency(currency),
        routingNumber: bankAccount?.routingNo ?? '',
        businessAccountId,
      };

      this.activeUser$.subscribe((activeUser) => {
        this.financialAccountService
          .getFlinksConnectionURL({ ...configData, employeeId: activeUser?.id })
          .pipe(takeUntil(this.destroy$))
          .subscribe({
            next: (connectionURL) => {
              const iframeModal = this.modalService.open(FinancialAccountVerificationIframeComponent, {
                className: 'entity-form-modal financial-account-iframe-modal',
              });

              iframeModal.componentInstance.modelInitData(connectionURL);

              iframeModal.result.then(
                () => {
                  this.loadFinancialAccountDetails();
                },
                () => false,
              );
            },
            error: (error) => {
              const errorModalRef = this.modalService.open(ErrorModalComponent, { className: 'confirm-modal' });
              errorModalRef.componentInstance.modalInitData({
                modalTitle: 'Error',
                messageText: `Unable to load financial account verification.`,
                btnText: 'Close',
              });
              console.error('getFlinksConnectionURL error', error);
            },
          });
      });
    }
  }
}
