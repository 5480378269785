import { AsyncPipe, NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { RktButtonDirective } from '@rocketfinancialcorp/rocket-ui/button';
import { RktIconComponent } from '@rocketfinancialcorp/rocket-ui/icon';
import { ActiveModal } from '@rocketfinancialcorp/rocket-ui/modal';

import { AttachmentDetails } from '@shared/models';
import { AttachmentService } from '@shared/services';

import { AccessControlPipe } from '../../../pipes/access-control.pipe';

@Component({
  selector: 'app-view-attachment-modal',
  templateUrl: './view-attachment-modal.component.html',
  styleUrls: ['./view-attachment-modal.component.scss'],
  standalone: true,
  imports: [RktButtonDirective, NgIf, NgxExtendedPdfViewerModule, AsyncPipe, AccessControlPipe, RktIconComponent],
})
export class ViewAttachmentModalComponent {
  type = '';

  url = '';

  name = '';

  description = '';

  fileFormat = '';

  isDescriptionExpanded = false;

  attachmentId = '';

  readOnly = false;

  canUpdate = true;

  get isImageFormat(): boolean {
    return ['PNG', 'JPG', 'JPEG', 'SVG'].includes(this.fileFormat);
  }

  constructor(
    public activeModal: ActiveModal,
    private attachmentService: AttachmentService,
  ) {}

  modalInitData({ url, name, extension, id, description, readOnly }: AttachmentDetails, canUpdate = true) {
    this.url = url ? url.value : '';

    this.name = name;

    this.description = description;

    this.fileFormat = extension;

    this.attachmentId = id;
    this.readOnly = readOnly ?? false;
    this.canUpdate = canUpdate;
  }

  onExpandCollapse(): void {
    this.isDescriptionExpanded = !this.isDescriptionExpanded;
  }

  onDownloadClick(): void {
    this.attachmentService.downloadAttachment(this.attachmentId);
  }
}
