import { AsyncPipe } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RktIconComponent } from '@rocketfinancialcorp/rocket-ui/icon';

import { AccessControlPipe } from '../../../pipes/access-control.pipe';

export type DocumentDetailsAction = 'DELETE';

@Component({
  selector: 'app-document-details-header',
  templateUrl: './document-details-header.component.html',
  standalone: true,
  imports: [AsyncPipe, AccessControlPipe, RktIconComponent],
})
export class DocumentDetailsHeaderComponent {
  @Input() isControlsDisabled = true;
  @Output() btnClicked = new EventEmitter<DocumentDetailsAction>();
}
