import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';

import { FinancialAccountService } from '@shared/services';
import { AggregatedAccountBalanceActions, MessagesActions, fromAuth } from '@shared/store';
import { ErrorUtils } from '@shared/utils';

@Injectable()
export class AggregatedAccountBalanceEffects {
  constructor(
    private actions$: Actions,
    private financialAccountService: FinancialAccountService,
    private store: Store,
  ) {}

  getBusinessAccountAggregatedBalances$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AggregatedAccountBalanceActions.initialize, AggregatedAccountBalanceActions.loadAggregatedAccountBalance),
      concatLatestFrom(() => this.store.select(fromAuth.selectBusinessAccountId)),
      mergeMap(([, activeBusinessAccountId]) => {
        return this.financialAccountService.businessAccountBalances(activeBusinessAccountId).pipe(
          map((response) => AggregatedAccountBalanceActions.loadAggregatedAccountBalanceSuccess({ financialAccountsBalances: response })),
          catchError((error) => {
            return of(AggregatedAccountBalanceActions.loadAggregatedAccountBalanceFailure({ error }));
          }),
        );
      }),
    );
  });

  loadBusinessAccountBalanceFailure$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AggregatedAccountBalanceActions.loadAggregatedAccountBalanceFailure),
      tap({
        next: (loadBusinessAggregatedBalanceError) => {
          ErrorUtils.catchError('financialAccountService.getBusinessAccountBalance error', loadBusinessAggregatedBalanceError.error);
        },
      }),
      switchMap(() => of(MessagesActions.displayError({ message: 'Unable to fetch aggregated account balances.' }))),
    );
  });
}
