import { AsyncPipe, NgClass, NgIf, NgTemplateOutlet, TitleCasePipe } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs';
import { RktButtonDirective } from '@rocketfinancialcorp/rocket-ui/button';
import { RktIconComponent } from '@rocketfinancialcorp/rocket-ui/icon';
import { ModalService } from '@rocketfinancialcorp/rocket-ui/modal';
import { ShortIdPipe } from '@rocketfinancialcorp/rocket-ui/table';

import { ExceptionHistoryModalComponent } from '@shared/components';
import { Breadcrumb, DropdownItem, ExceptionDetails, TemplateContext } from '@shared/models';
import { NavigationService } from '@shared/services';
import { ExceptionManagementActions, exceptionManagementFeature } from '@shared/store';

import { TemplateContextDirective } from '../../../directives/template-context.directive';
import { TooltipDirective } from '../../../directives/tooltip/tooltip.directive';
import { AccessControlPipe } from '../../../pipes/access-control.pipe';
import { DateFormatPipe } from '../../../pipes/date-format.pipe';
import { TeamMemberNamePipe } from '../../../pipes/team-member-name.pipe';
import { BreadcrumbsComponent } from '../../breadcrumbs/breadcrumbs.component';
import { DetailsItemComponent } from '../../details-item/details-item.component';
import { AssigneeSelectComponent } from '../../dropdowns/assignee-select/assignee-select.component';
import { StatusSelectComponent } from '../../dropdowns/status-select/status-select.component';
import { DetailsPageHeaderPlaceholderComponent } from '../../loading-placeholders/details-page-header-placeholder/details-page-header-placeholder.component';
import { NotesFeedComponent } from '../../notes/notes-feed/notes-feed.component';
import { TextWithPlaceholderComponent } from '../../text-with-placeholder/text-with-placeholder.component';

@Component({
  selector: 'app-exception-management-details',
  templateUrl: './exception-management-details.component.html',
  standalone: true,
  imports: [
    RktButtonDirective,
    NgIf,
    NgTemplateOutlet,
    TemplateContextDirective,
    RouterLink,
    NgClass,
    BreadcrumbsComponent,
    DetailsPageHeaderPlaceholderComponent,
    TextWithPlaceholderComponent,
    AssigneeSelectComponent,
    StatusSelectComponent,
    DetailsItemComponent,
    TooltipDirective,
    NotesFeedComponent,
    AsyncPipe,
    TitleCasePipe,
    DateFormatPipe,
    TeamMemberNamePipe,
    AccessControlPipe,
    RktIconComponent,
    ShortIdPipe,
  ],
})
export class ExceptionManagementDetailsComponent implements OnInit {
  navigationService = inject(NavigationService);

  modalService = inject(ModalService);

  activatedRoute = inject(ActivatedRoute);

  store = inject(Store);

  exceptionDetailsContext?: TemplateContext<ExceptionDetails>;

  exceptionDetails$ = this.store.select(exceptionManagementFeature.selectExceptionDetails).pipe(
    tap({
      next: (exception) => {
        this.selectedAssignee = exception?.assignedTo?.id ? { label: exception.assignee!, value: exception.assignedTo.id } : undefined;
        this.selectedStatus = exception?.status;
        this.setStatusOptions();
      },
    }),
  );

  exceptionHistory$ = this.store.select(exceptionManagementFeature.selectExceptionHistoryItems);

  pageReturnURL = '/app/exception-management/exceptions';

  breadcrumbs: Breadcrumb[] = [{ label: 'Exceptions', url: this.pageReturnURL }, { label: 'Exception Details' }];

  selectedAssignee?: DropdownItem;

  selectedStatus?: string;

  statusOptions: DropdownItem[] = [];

  ngOnInit() {
    this.store.dispatch(ExceptionManagementActions.loadExceptionDetails());
  }

  showHistoryModal() {
    this.modalService.open(ExceptionHistoryModalComponent, { className: 'exception-history-modal' });
  }

  setSelectedAssignee(item?: DropdownItem) {
    this.selectedAssignee = item;
  }

  setSelectedStatus(item?: DropdownItem) {
    this.selectedStatus = item?.value;
  }

  updateException(exception: ExceptionDetails) {
    if (exception.assignedTo?.id !== this.selectedAssignee?.value) {
      this.store.dispatch(
        ExceptionManagementActions.updateException({
          data: { assignedTo: this.selectedAssignee?.value },
        }),
      );
    } else if (exception.status !== this.selectedStatus) {
      this.store.dispatch(
        ExceptionManagementActions.updateException({
          data: { status: this.selectedStatus },
        }),
      );
    }
  }

  setStatusOptions() {
    let options: DropdownItem[] = [];

    switch (this.selectedStatus) {
      case 'NEW':
        options = [
          { label: 'New', value: 'NEW' },
          { label: 'In Progress', value: 'IN_PROGRESS' },
          { label: 'Resolved', value: 'RESOLVED' },
        ];
        break;

      case 'IN_PROGRESS':
        options = [
          { label: 'In Progress', value: 'IN_PROGRESS' },
          { label: 'Resolved', value: 'RESOLVED' },
        ];
        break;

      case 'RESOLVED':
        options = [
          { label: 'Reopened', value: 'REOPENED' },
          { label: 'Resolved', value: 'RESOLVED' },
        ];
        break;

      case 'REOPENED':
        options = [
          { label: 'In Progress', value: 'IN_PROGRESS' },
          { label: 'Reopened', value: 'REOPENED' },
        ];
        break;

      default:
        break;
    }

    this.statusOptions = options;
    return;
  }
}
