import { HttpContext, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { differenceInSeconds } from 'date-fns';
import { Observable, from, of, throwError } from 'rxjs';
import { catchError, exhaustMap, map, mergeMap, switchMap, tap } from 'rxjs/operators';

import { environment } from '@env';
import { CustomHttpParamEncoder } from '@shared/encoder';
import { CUSTOM_ERROR_MESSAGE } from '@shared/interceptors';
import { AddNoteParams, BusinessAccount, Note, NoteRaw, NotesRaw, RequestNoteParams, TeamMember, UpdateNoteParams } from '@shared/models';
import { AttachmentService, BusinessAccountService } from '@shared/services';
import { BackendService } from '@shared/services/backend.service';
import { AuditActions } from '@shared/store';
import { ErrorUtils } from '@shared/utils';

@Injectable({
  providedIn: 'root',
})
export class NoteService {
  private notesBasePath = `${environment.noteFlowEndpoint}/notes`;

  teamMembersName: Record<string, TeamMember['name'] | undefined> = {};

  constructor(
    private backendService: BackendService,
    private businessAccountService: BusinessAccountService,
    private attachmentService: AttachmentService,
    private readonly store: Store,
  ) {}

  public addNote(noteData: AddNoteParams, customErrorNotification?: string): Observable<boolean> {
    return this.getSelectedBusinessAccountId().pipe(
      switchMap((businessAccountId) =>
        this.backendService
          .post<NoteRaw>(
            `${this.notesBasePath}`,
            { ...noteData, businessAccountId },
            { context: new HttpContext().set(CUSTOM_ERROR_MESSAGE, customErrorNotification) },
          )
          .pipe(
            exhaustMap((notesResponse) => {
              if (!noteData.attachments) {
                return of(notesResponse);
              }
              return from(noteData.attachments).pipe(
                mergeMap((attachment) =>
                  from(
                    this.attachmentService.createAttachment({
                      entityId: notesResponse.id,
                      entityType: 'NOTE',
                      file: attachment.file!,
                      extension: attachment.extension!,
                      name: attachment.name,
                      description: attachment.description,
                    }),
                  ),
                ),
                catchError((error) => {
                  ErrorUtils.catchError('attachmentService.createAttachment', error);
                  return of(notesResponse);
                }),
                map(() => notesResponse),
              );
            }),
            map(() => true),
            catchError((errorRes) => throwError(() => errorRes)),
          ),
      ),
    );
  }

  public updateNote(noteId: Note['id'], noteData: UpdateNoteParams): Observable<boolean> {
    return this.backendService.patch(`${this.notesBasePath}/${noteId}`, noteData).pipe(
      exhaustMap((notesResponse) => {
        if (!noteData.attachments) {
          return of(notesResponse);
        }
        return from(noteData.attachments).pipe(
          mergeMap((attachment) =>
            from(
              this.attachmentService.createAttachment({
                entityId: noteId,
                entityType: 'NOTE',
                file: attachment.file!,
                extension: attachment.extension!,
              }),
            ),
          ),
          catchError((error) => {
            ErrorUtils.catchError('attachmentService.createAttachment', error);
            return of(notesResponse);
          }),
          map(() => notesResponse),
        );
      }),
      map(() => true),
      catchError((errorRes) => throwError(() => errorRes)),
    );
  }

  public deleteNote(noteId: Note['id']): Observable<boolean> {
    return this.backendService.delete<void>(`${this.notesBasePath}/${noteId}`).pipe(
      map(() => true),
      catchError((errorRes) => throwError(() => errorRes)),
    );
  }

  public getNotes({ entityType, entityId, page, size }: RequestNoteParams): Observable<Note[]> {
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() });

    if (page) {
      params = params.append('page', page);
    }

    if (size) {
      params = params.append('size', size);
    }

    return this.backendService
      .get<NotesRaw>(`${this.notesBasePath}?entityType=${entityType}&entityId=${entityId}&embed=attachments`, { params })
      .pipe(
        tap({
          next: (response) => {
            const { content = [] } = response || {};
            this.store.dispatch(AuditActions.loadTeamMembers({ ids: content.map((item) => item.createdBy) }));
          },
        }),
        map((notesResponse) => this.mapNotes(notesResponse)),
        catchError((errorRes) => throwError(() => errorRes)),
      );
  }

  private mapNotes(response?: NotesRaw): Note[] {
    const { content = [] } = response ?? {};
    return content.map((note) => ({
      ...note,
      shortText: note.contentText?.length > 500 ? note.contentText.slice(0, 495) + '...' : undefined,
      isUpdated: differenceInSeconds(new Date(note.updatedAt), new Date(note.createdAt)) > 0,
      attachments:
        note._embedded?.attachments?.map((item) => {
          return {
            ...item,
            downloadBtn: 'DOWNLOAD',
            rowClassName: `attachment-extension attachment-extension-${item.extension.toLowerCase()}`,
          };
        }) ?? [],
    }));
  }

  private getSelectedBusinessAccountId(): Observable<BusinessAccount['id']> {
    return this.businessAccountService.getSelectedBusinessAccountId();
  }
}
