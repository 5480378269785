import { AsyncPipe, NgIf } from '@angular/common';
import { AfterViewChecked, ChangeDetectorRef, Component, Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { isEmpty, isUndefined } from 'lodash-es';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RktButtonDirective } from '@rocketfinancialcorp/rocket-ui/button';
import { RktIconComponent } from '@rocketfinancialcorp/rocket-ui/icon';
import { ModalService } from '@rocketfinancialcorp/rocket-ui/modal';
import { DatatableColumn, RktTableComponent } from '@rocketfinancialcorp/rocket-ui/table';

import {
  AddFinancialAccountMethodSelectComponent,
  AddFinancialAccountReviewComponent,
  AddIntegratedFinancialAccountAgreementsComponent,
  AddIntegratedFinancialAccountComponent,
  ErrorModalComponent,
  FilterMenuComponent,
  FinancialAccountVerificationIframeComponent,
  SuccessModalComponent,
} from '@shared/components';
import {
  FilterField,
  FilterValues,
  FinancialAccountList,
  FinancialAccountListItem,
  FinancialAccountReviewData,
  FlinksConnectionRequest,
  IntegratedFinancialAccountAcceptance,
  IntegratedFinancialAccountCreateData,
  RequestPageParams,
} from '@shared/models';
import { AccessControlPipe } from '@shared/pipes';
import { FinancialAccountService } from '@shared/services';
import { fromAuth, selectFeatureFlagEnabledByKey } from '@shared/store';
import { ErrorUtils, activeFilters } from '@shared/utils';

@Component({
  selector: 'app-business-account-financial-account-list',
  templateUrl: 'business-account-financial-account-list.component.html',
  standalone: true,
  imports: [
    NgIf,
    AsyncPipe,
    AccessControlPipe,
    RktButtonDirective,
    RktIconComponent,
    FilterMenuComponent,
    RktTableComponent,
    AddFinancialAccountMethodSelectComponent,
  ],
})
export class BusinessAccountFinancialAccountListComponent implements OnDestroy, AfterViewChecked {
  @Input() isShowControls = false;

  activeUser$ = this.store.select(fromAuth.selectUser);

  activeBusinessAccount$ = this.store.select(fromAuth.selectBusinessAccountId);

  financialAccounts: FinancialAccountListItem[] = [];

  loading = true;

  columns: DatatableColumn[] = [
    { name: 'Account Name', prop: 'name', flexSize: 300 },
    { name: 'Account Number', prop: 'displayName', flexSize: 350 },
    { name: 'Account Category', prop: 'category', flexSize: 200 },
    { name: 'Currency', prop: 'currency', flexSize: 120 },
    { name: 'Account Balance', prop: 'accountBalance', flexSize: 305, colType: 'amount', colAlign: 'right' },
    { name: 'Available Balance', prop: 'availableBalance', flexSize: 305, colType: 'amount', colAlign: 'right' },
    { name: 'Status', prop: 'state', fixedSize: 140, colType: 'status-tag', colAlign: 'right' },
  ];

  totalElements = 0;

  page = 0;

  activeFilters: FilterValues = {};

  filters: FilterField[] = [
    {
      name: 'categories',
      displayName: 'Account Category',
      type: 'RADIO',
      options: [
        { label: 'All Categories', value: undefined },
        { label: 'INTERNAL', value: 'INTERNAL' },
        { label: 'EXTERNAL', value: 'EXTERNAL' },
        { label: 'INTEGRATED', value: 'INTEGRATED' },
      ],
    },
  ];

  emptyListMessage = 'No Financial Accounts found.';

  get activePage() {
    return this.page;
  }

  get activeFiltersCount(): number {
    return Object.keys(this.activeFilters).length;
  }

  createIntegratedFaFlag$ = this.store.select(selectFeatureFlagEnabledByKey('create-integrated-fa-feature'));

  private destroy$ = new Subject<void>();

  constructor(
    public ref: ChangeDetectorRef,
    private router: Router,
    private financialAccountService: FinancialAccountService,
    private modalService: ModalService,
    private store: Store,
  ) {
    this.financialAccountService.financialAccountListFilters.subscribe({
      next: (filters: FilterValues) => {
        if (isEmpty(filters)) {
          this.financialAccountService.getListFilterParams();
        } else {
          this.activeFilters = filters;
        }
      },
    });
  }

  ngAfterViewChecked(): void {
    this.ref.detectChanges();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.modalService.dismissAll();
  }

  viewAccount({ id }: FinancialAccountListItem): void {
    this.router.navigate(['app', 'settings', 'financial-accounts', id, 'account-details']);
  }

  getFinancialAccounts({ page, size }: RequestPageParams): void {
    this.loading = true;

    if (!isUndefined(page) && this.activePage !== page) {
      this.page = page;
    }

    this.financialAccountService
      .getFinancialAccounts({
        page,
        size,
        state: ['ACTIVE', 'SUSPENDED', 'PENDING'],
        accountHolderTypes: ['BUSINESS_ACCOUNT'],
        activeFilters: { ...this.activeFilters },
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response) => {
          this.emptyListMessage = 'No Financial Accounts found.';
          this.onFetchComplete(response);
        },
        error: (error) => {
          this.emptyListMessage = "We couldn't fetch your Financial Accounts.";
          this.onFetchComplete();
          ErrorUtils.catchError('financialAccountService.getFinancialAccountsWithBalances', error);
        },
      });
  }

  onFetchComplete(response?: FinancialAccountList): void {
    const { items = [], totalElements = 0 } = response ?? {};
    this.financialAccounts = items;
    this.totalElements = totalElements;
    this.loading = false;
  }

  addAccountStep(editData?: FinancialAccountReviewData) {
    const accountCreateModalRef = this.modalService.open(AddIntegratedFinancialAccountComponent, {
      className: 'entity-form-modal',
    });

    if (editData) {
      accountCreateModalRef.componentInstance.modalInitData(editData);
    }

    accountCreateModalRef.result.then(
      (createData: FinancialAccountReviewData) => {
        if (createData && createData.category === 'INTEGRATED') {
          this.addIntegratedAccountAgreementStep(createData);
        }
      },
      () => false,
    );
  }

  addIntegratedAccountAgreementStep(createData: FinancialAccountReviewData) {
    const accountCreateAgreementModalRef = this.modalService.open(AddIntegratedFinancialAccountAgreementsComponent, {
      className: 'entity-form-modal documents-modal',
    });

    accountCreateAgreementModalRef.result.then(
      (result) => {
        this.onAddAccountAction({ action: result?.action, createData, acceptances: result?.acceptances });
      },
      () => false,
    );
  }

  addAccountReviewStep(createData: FinancialAccountReviewData, acceptances?: IntegratedFinancialAccountAcceptance[]) {
    const accountCreateReviewModalRef = this.modalService.open(AddFinancialAccountReviewComponent, {
      className: 'entity-form-modal entity-review-modal',
    });

    accountCreateReviewModalRef.componentInstance.modalInitData(createData);

    accountCreateReviewModalRef.result.then(
      (result) => {
        this.onAddAccountAction({ action: result?.action, createData, acceptances });
      },
      () => false,
    );
  }

  onAddAccountAction({
    action,
    createData,
    acceptances,
  }: {
    action?: 'EDIT' | 'REVIEW' | 'SAVE';
    createData: FinancialAccountReviewData;
    acceptances?: IntegratedFinancialAccountAcceptance[];
  }): void {
    switch (action) {
      case 'EDIT':
        this.addAccountStep(createData);
        break;

      case 'REVIEW':
        this.addAccountReviewStep(createData, acceptances);
        break;

      case 'SAVE':
        this.createIntegratedFinancialAccount(createData, acceptances!);
        break;

      default:
        break;
    }
  }

  createIntegratedFinancialAccount(createData: IntegratedFinancialAccountCreateData, acceptances: IntegratedFinancialAccountAcceptance[]) {
    const { subtype, name } = createData;
    this.financialAccountService
      .createIntegratedBankAccount({
        subtype,
        name,
        acceptanceAgreements: { acceptances: acceptances! },
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          const addAccountSuccessModalRef = this.modalService.open(SuccessModalComponent, {
            className: 'success-modal',
          });

          addAccountSuccessModalRef.componentInstance.actionName = 'added';
          addAccountSuccessModalRef.componentInstance.type = 'Financial Account';
          addAccountSuccessModalRef.result.finally(() => {
            this.getFinancialAccounts({ page: 0 });
          });
        },
        error: (error) => {
          ErrorUtils.catchError('financialAccountService.addEntityFinancialAccount error', error);

          const errorModalRef = this.modalService.open(ErrorModalComponent, { className: 'confirm-modal' });
          errorModalRef.componentInstance.messageText = error;

          errorModalRef.result.finally(() => {
            this.getFinancialAccounts({ page: 0 });
          });
        },
      });
  }

  applyFilters(filterValues: FilterValues): void {
    this.activeFilters = activeFilters(filterValues);
    this.financialAccountService.setListFilterParams(this.activeFilters);
    this.getFinancialAccounts({ page: 0 });
  }

  onboardAccountMethod(onboardingMethod: string) {
    switch (onboardingMethod) {
      case 'EXTERNAL':
        this.onOnboardExternalAccount();
        break;
      case 'MANUAL':
      default:
        this.addAccountStep();
    }
  }

  onOnboardExternalAccount(): void {
    this.financialAccountService.getEnabledSolutions().subscribe({
      next: (response) => {
        if (response?.enabledSolutions.find((solution) => solution.solutionName === 'financial-account-aggregation')) {
          this.onboardExternalAccount();
        } else {
          const errorModalRef = this.modalService.open(ErrorModalComponent, { className: 'confirm-modal' });
          errorModalRef.componentInstance.modalInitData({
            modalTitle: 'No Access to Connect Bank Account',
            messageText: `You don't have access to this solution.`,
            btnText: 'Close',
          });
        }
      },
    });
  }

  onboardExternalAccount() {
    combineLatest(this.activeUser$, this.activeBusinessAccount$).subscribe(([activeUser, activeBusinessAccount]) => {
      const configData: FlinksConnectionRequest = {
        countryCode: 'USA',
        businessAccountId: activeBusinessAccount,
        employeeId: activeUser?.id,
      };

      this.loadFlinksConnectionWidget(configData);
    });
  }

  loadFlinksConnectionWidget(configData: FlinksConnectionRequest) {
    this.financialAccountService
      .getFlinksConnectionURL(configData)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (connectionURL) => {
          const iframeModal = this.modalService.open(FinancialAccountVerificationIframeComponent, {
            className: 'entity-form-modal financial-account-iframe-modal',
          });

          iframeModal.componentInstance.modelInitData(connectionURL);

          iframeModal.result.then(
            () => {
              setTimeout(() => {
                this.getFinancialAccounts({ page: 0 });
              }, 1500);
            },
            () => false,
          );
        },
        error: (error) => {
          const errorModalRef = this.modalService.open(ErrorModalComponent, { className: 'confirm-modal' });
          errorModalRef.componentInstance.modalInitData({
            modalTitle: 'Error',
            messageText: `Unable to load financial account verification.`,
            btnText: 'Close',
          });
          console.error('getFlinksConnectionURL loadExternalBusinessAccountFinancialAccountModal', error);
        },
      });
  }
}
