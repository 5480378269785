<app-attention-modal
  [type]="name ? 'scheduled' : 'success'"
  [title]="title"
  [messageTemplate]="message"
  [buttonsTemplate]="buttons"
></app-attention-modal>

<ng-template #message>
  <div class="transaction-id">Transaction # {{ id || '' | rktShortId }}</div>

  <div class="transaction-info">
    <div class="row" *ngIf="name">
      <div class="label">Name</div>
      <div class="value">
        <div class="value-item">{{ name }}</div>
      </div>
    </div>

    <div class="row row-no-border" *ngIf="scheduleDateTimeDisplayValue">
      <div class="label">Scheduled for</div>
      <div class="value">
        <div class="value-item">{{ scheduleDateTimeDisplayValue | dateFormat }}</div>
      </div>
    </div>

    <div class="row" *ngIf="scheduleRecurringDisplayValue">
      <div class="label">Recurrence</div>
      <div class="value">
        <div class="value-item">{{ scheduleRecurringDisplayValue }}</div>
      </div>
    </div>

    <div class="row row-no-border">
      <div class="label">Payment Method</div>
      <div class="value">
        <div class="value-item">{{ solution }}</div>
        <div class="value-item">{{ settlementPriority }}</div>
      </div>
    </div>

    <div class="row">
      <div class="label">Payment Reason</div>
      <div class="value">{{ reason }}</div>
    </div>

    <div class="row">
      <div class="label">From Account</div>
      <div class="value">
        <div class="value-item" *ngIf="fromAccountName">{{ fromAccountName }}</div>
        <div class="value-item">{{ fromAccountNumber }}</div>
        <div class="value-item" *ngIf="fromAccountBalance">
          <span class="label-value">Avail. Balance:</span>
          {{ (fromAccountBalance | number: '1.2-2') || '' | mask: 'separator' : { thousandSeparator: ',' } | prefix: '$ ' }}
        </div>
      </div>
    </div>

    <div class="row">
      <div class="label">To Account</div>
      <div class="value">
        <div class="value-item" *ngIf="toAccountName">{{ toAccountName }}</div>
        <div class="value-item">{{ toAccountNumber }}</div>
        <div class="value-item" *ngIf="toAccountBalance">
          <span class="label-value">Avail. Balance:</span>
          {{ (toAccountBalance | number: '1.2-2') || '' | mask: 'separator' : { thousandSeparator: ',' } | prefix: '$ ' }}
        </div>
        <div class="value-item" *ngIf="toAccountAddress?.addressLine1">
          <span class="label-value">
            {{ toAccountAddress?.addressLine1 }},<br />
            <ng-container *ngIf="toAccountAddress?.addressLine2">{{ toAccountAddress?.addressLine2 }}<br /></ng-container>
            {{ toAccountAddress?.city }}, {{ toAccountAddress?.state }}<br />
            {{ toAccountAddress?.postalCode }}
            {{ toAccountAddress?.country }}
          </span>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="label">Amount</div>
      <div class="value">{{ amount | amount: transactionCurrency ?? 'USD' }}</div>
    </div>
  </div>
</ng-template>

<ng-template #buttons>
  <button rktButton btnType="secondary" type="button" (click)="activeModal.close()" title="Create Transaction">Create Transaction</button>
  <button rktButton type="button" (click)="activeModal.close(true)" title="Done">Done</button>
</ng-template>
