import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { RequestPageParams } from '@shared/models';
import { CustomerService } from '@shared/services';

import { IndustriesActions } from './industry.actions';

@Injectable()
export class IndustryEffects {
  constructor(
    private actions$: Actions,
    private customerService: CustomerService,
  ) {}

  getIndustries$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(IndustriesActions.loadIndustries),
      switchMap((payload: { params: RequestPageParams }) => {
        return this.customerService.getIndustries(payload.params).pipe(
          map((response) => {
            return IndustriesActions.loadIndustriesSuccess({ industries: response.items, totalElements: response.totalElements });
          }),
          catchError((error) => {
            return of(IndustriesActions.loadIndustriesError({ error }));
          }),
        );
      }),
    );
  });

  getIndustryById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(IndustriesActions.loadIndustryDetails),
      switchMap((payload) => {
        return this.customerService.getIndustryById(payload.industryCode).pipe(
          map((response) => IndustriesActions.loadIndustryDetailsSuccess({ industry: response })),
          catchError((error) => {
            return of(IndustriesActions.loadIndustryDetailsError({ error }));
          }),
        );
      }),
    );
  });
}
