import { differenceInBusinessDays, format } from 'date-fns';
import { compact, find, some } from 'lodash-es';

import {
  CustomerDetailRaw,
  DeliverySpeedOption,
  DeliverySpeedOptionsResponse,
  FinancialAccountSelectionList,
  FinancialAccountSelectionListRaw,
  MultiLegSolutionConfigSolutionName,
  MultiLegTransactionDetails,
  MultiLegTransactionDetailsRaw,
  MultiLegTransactionFinancialAccount,
  MultiLegTransactionList,
  MultiLegTransactionListItem,
  MultiLegTransactionListItemLegDetailsRaw,
  MultiLegTransactionListRaw,
  MultiLegTransactionProcessingPriority,
  RecipientRaw,
  SettlementPriority,
  SupportedSettlementPriorities,
  SupportedSettlementPriorityDetails,
} from '@shared/models';
import {
  getMaskedAccountNumber,
  getSolutionDisplayName,
  getSolutionIconName,
  getSubtypeDisplayName,
  objectEntries,
  toTitleCase,
} from '@shared/utils';

export const mapMultiLegTransactionListData = (transactionsData: MultiLegTransactionListRaw): MultiLegTransactionList => {
  const { content = [], totalElements = 0, hasNext = false } = transactionsData ?? {};

  const items: MultiLegTransactionListItem[] = content.map((item) => {
    const debitLegs = item.debitLegs.map((debitLeg) => mapMultiLegTransactionListLegInfo(debitLeg));
    const creditLegs = item.creditLegs.map((creditLeg) => mapMultiLegTransactionListLegInfo(creditLeg));
    return {
      ...item,
      debitLegs,
      creditLegs,
      debitLegsCount: debitLegs.length > 1 ? `+${debitLegs.length - 1}` : '',
      creditLegsCount: creditLegs.length > 1 ? `+${creditLegs.length - 1}` : '',
      debitLegsProcessed: debitLegs.filter((debitLeg) => debitLeg.isProcessed).length,
      creditLegsProcessed: creditLegs.filter((creditLeg) => creditLeg.isProcessed).length,
      moveFrom: debitLegs[0].accountInfo,
      moveTo: creditLegs[0].accountInfo,
    };
  });

  return {
    items,
    totalElements,
    hasNext,
  };
};

export const mapMultiLegTransactionDeatilsData = (response: MultiLegTransactionDetailsRaw): MultiLegTransactionDetails => {
  const { metadata = {} } = response;
  const { estLabel, estLabelDate, estLabelDay, estLabelTime } = getTransactionEstimatedDate(response) ?? {};
  return {
    ...response,
    processedTransactions: [...response.debits, ...response.credits].filter(
      (leg) =>
        leg.latestStatus?.status && ['CANCELLED', 'APPROVED', 'ERROR', 'DECLINED', 'CLEARED', 'SETTLED'].includes(leg.latestStatus.status),
    ).length,
    totalTransactions: response.debits.length + response.credits.length,
    metadataItems: Object.keys(metadata).map((key) => ({
      key,
      value: metadata[key as keyof typeof metadata],
    })),
    estLabel,
    estLabelDate,
    estLabelDay,
    estLabelTime,
  };
};

const getTransactionEstimatedDate = (transaction: MultiLegTransactionDetailsRaw) => {
  const debitLeg = transaction.debits[0];
  const creditLeg = transaction.credits[0];

  if (!debitLeg || !creditLeg) {
    return;
  }

  const deliveryOption = transaction.deliverySpeedOptions?.find(
    (item) =>
      item.fromAccountMethod === debitLeg.solution &&
      item.fromAccountProcessingPriority === debitLeg.settlementPriority &&
      item.toAccountMethod === creditLeg.solution &&
      item.toAccountProcessingPriority === creditLeg.settlementPriority,
  );

  if (!deliveryOption) {
    return;
  }
  return {
    estLabelDate: deliveryOption.estLabelDate,
    estLabelDay: deliveryOption.estLabelDay,
    estLabelTime: deliveryOption.estLabelTime,
    estLabel: deliveryOption.label,
  };
};

export const mapFinancialAccountSelectionList = (response: FinancialAccountSelectionListRaw): FinancialAccountSelectionList => {
  const { content = [], totalElements = 0, hasNext = false } = response ?? {};

  const items: FinancialAccountSelectionList['items'] = [];

  content.forEach((item) => {
    const accountInfo = compact([
      compact([item.financialInstitutionName, getMaskedAccountNumber(item.maskedAccountNumber)]).join(' '),
      getSubtypeDisplayName(item.subtype),
    ]).join(' | ');

    const { accountBalance, availableBalance } = item.financialAccountDetails?._embedded?.balances?.[item.currency] ?? {};

    const accountHolder: RecipientRaw | CustomerDetailRaw | undefined = item.customer ?? item.recipient;
    const accountHolderIndividualOrBusiness = item.customer?.type ?? item.recipient?.recipientType;
    const accountHolderBusinessName =
      (accountHolder as CustomerDetailRaw)?.doingBusinessAsName ?? (accountHolder as RecipientRaw)?.businessName;
    const accountHolderName =
      accountHolderBusinessName ??
      compact([accountHolder?.firstName, accountHolder?.lastName, item.businessAccount?.operatingName]).join(' ');
    const accountHolderType = toTitleCase(
      compact([accountHolderIndividualOrBusiness, item.accountHolderType ?? 'Business Account']).join(' '),
    );
    const customerPhone =
      item.customer?.primaryPhoneNumber?.number ?? find(item.customer?.contacts, ['primary', true])?.primaryPhoneNumber?.number;
    const customerEmail = item.customer?.primaryEmail?.value ?? find(item.customer?.contacts, ['primary', true])?.primaryEmail?.value;

    items.push({
      id: item.id,
      accountHolderInfo: `${accountHolderName ?? ''}::${accountHolderType ?? ''}`,
      accountInfo: `${item.name ?? ''}::${accountInfo ?? ''}`,
      accountHolderId: item.accountHolderId,
      accountHolderType: item.accountHolderType ?? 'BUSINESS_ACCOUNT',
      accountBalance,
      availableBalance,
      accountCategory: item.category,
      businessAccountId: item.businessAccountId,
      recipientAccountHolderId: item.recipient?.accountHolderId,
      state: item.state,
      phone: customerPhone ?? item.recipient?.phoneNumber,
      email: customerEmail ?? item.recipient?.email ?? item.businessAccount?.primaryEmail,
      isDisabled: some([item.isSelectionDisabled, !!item.itemError, item.state.toLowerCase() !== 'active']),
      currency: item.currency,
      subtype: item.subtype,
      category: item.category,
      parentId: item.parentId,
      bankAccount: item.bankAccount,
    });
  });

  return { items, totalElements, hasNext };
};

export const mapFinancialAccountHolderInfo = (
  financialAccount: MultiLegTransactionFinancialAccount,
): MultiLegTransactionFinancialAccount => {
  const accountHolder: RecipientRaw | CustomerDetailRaw | undefined = financialAccount.customer ?? financialAccount.recipient;
  const accountHolderIndividualOrBusiness = financialAccount.customer?.type ?? financialAccount.recipient?.recipientType;
  const accountHolderBusinessName =
    (accountHolder as CustomerDetailRaw)?.doingBusinessAsName ?? (accountHolder as RecipientRaw)?.businessName;
  const accountHolderName =
    accountHolderBusinessName ??
    [accountHolder?.firstName, accountHolder?.lastName].join(' ') ??
    [financialAccount.card?.firstName, financialAccount.card?.lastName].join(' ') ??
    financialAccount.bankAccount?.nameOnAccount;
  const accountHolderType = toTitleCase(
    compact([accountHolderIndividualOrBusiness, financialAccount.accountHolderType ?? 'Business Account']).join(' '),
  );

  return { ...financialAccount, accountHolderInfo: `${accountHolderName ?? ''}::${accountHolderType ?? ''}` };
};

export const mapFinancialAccountInfo = (financialAccount: MultiLegTransactionFinancialAccount): MultiLegTransactionFinancialAccount => {
  const { maskedAccountNumber, bankAccount, name, card, subtype } = financialAccount;
  const bankName = bankAccount?.bankName;
  const nameOnCard = compact([card?.firstName, card?.lastName]).join(' ');
  const accNo = compact([getMaskedAccountNumber(maskedAccountNumber), getSubtypeDisplayName(subtype)]).join(' | ');
  const accountInfo = `${name ?? ''}::${compact([bankName ?? nameOnCard, accNo]).join(' ')}`;
  const address = card?.address ?? bankAccount?.address;

  return {
    ...financialAccount,
    accountInfo,
    address,
  };
};

export const mapMultiLegTransactionListLegInfo = (
  legDetails: MultiLegTransactionListItemLegDetailsRaw,
): MultiLegTransactionListItemLegDetailsRaw => {
  const { maskedAccountNumber } = legDetails;
  const { bankAccount, name, card, subtype } = legDetails.financialAccount || legDetails._embedded?.financialAccount || {};
  const bankName = bankAccount?.bankName;
  const nameOnCard = compact([card?.firstName, card?.lastName]).join(' ');
  const accNo = compact([getMaskedAccountNumber(maskedAccountNumber), getSubtypeDisplayName(subtype)]).join(' | ');
  const solutionIcon = getSolutionIconName(legDetails.solution);
  const solutionName = getSolutionDisplayName(legDetails.solution);
  const isProcessed = legDetails.latestStatus
    ? ['CANCELLED', 'APPROVED', 'ERROR', 'DECLINED', 'CLEARED', 'SETTLED'].includes(legDetails.latestStatus.status)
    : false;
  return {
    ...legDetails,
    solutionIcon,
    solutionName,
    accountInfo: `${name ?? ''}::${compact([bankName ?? nameOnCard, accNo]).join(' ')}`,
    isProcessed,
  };
};

const isValidDate = (dateString: string): boolean => {
  const date = new Date(dateString);
  return !isNaN(date.getTime());
};

const formatDateTime = (dateTimeString: string, startDateTime?: string) => {
  if (!isValidDate(dateTimeString)) {
    console.error(`Invalid date value: ${dateTimeString}`);
    return null;
  }

  const date = new Date(dateTimeString);
  const hours = date.getHours();
  const estLabelTime = hours < 12 ? 'morning' : 'afternoon';

  const scheduledDate = startDateTime ? new Date(startDateTime) : new Date();

  return {
    estDate: date.toISOString(),
    estLabelTime,
    estLabelDate: format(date, 'LLL dd, yyyy'),
    estLabelDay: format(date, 'iiii'),
    estDaysLabel: getPriorityLabel(differenceInBusinessDays(date, scheduledDate)),
  };
};

const getPriorityLabel = (daysDifference: number): string => {
  const numberWords = ['Zero', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine', 'Ten'];

  if (daysDifference <= 0) {
    return 'Same business day';
  } else if (daysDifference === 1) {
    return 'Next business day';
  } else if (daysDifference === 2) {
    return 'Two business days';
  } else if (daysDifference < numberWords.length) {
    return `${numberWords[daysDifference]} business days`;
  } else {
    return `${daysDifference} business days`;
  }
};

const getPriorityValue = (fromPriority: string, toPriority: string): string => {
  if (fromPriority === 'NEXT_DAY' && toPriority === 'NEXT_DAY') {
    return 'NEXT_DAY_TO_NEXT_DAY';
  }
  if (fromPriority === 'SAME_DAY' && toPriority === 'IMMEDIATE') {
    return 'SAME_DAY_TO_IMMEDIATE';
  }
  if (fromPriority === 'NEXT_DAY' && toPriority === 'IMMEDIATE') {
    return 'NEXT_DAY_TO_IMMEDIATE';
  }
  return toPriority;
};

const addMltOptions = (
  options: DeliverySpeedOption[],
  fromMethod: MultiLegSolutionConfigSolutionName,
  fromPriorities: { [key in MultiLegTransactionProcessingPriority]: SettlementPriority },
  toMethod: MultiLegSolutionConfigSolutionName,
  toPriorities: { [key in MultiLegTransactionProcessingPriority]: SupportedSettlementPriorityDetails },
  startDateTime?: string,
) => {
  objectEntries(fromPriorities).forEach(([fromPriority]) => {
    objectEntries(toPriorities).forEach(([toPriority, toDetails]) => {
      if (toDetails && toDetails.fundingSolutionPriorityUsed) {
        const toDates = toDetails.fundingSolutionPriorityUsed[fromMethod]?.[fromPriority];
        if (toDates && toDates.estimatedFundsDeliveryDateTime) {
          const formattedDateTime = formatDateTime(toDates.estimatedFundsDeliveryDateTime, startDateTime);

          if (formattedDateTime) {
            const { estDate, estLabelTime, estLabelDate, estLabelDay, estDaysLabel } = formattedDateTime;
            const valuePriority = getPriorityValue(fromPriority, toPriority);

            options.push({
              label: `${estDaysLabel} – ${getSolutionDisplayName(toMethod)}`,
              value: `MLT_${toMethod.toUpperCase()}_${valuePriority}`,
              estDate,
              estLabelTime,
              estLabelDate,
              estLabelDay,
              fromAccountMethod: fromMethod,
              fromAccountProcessingPriority: fromPriority,
              toAccountMethod: toMethod,
              toAccountProcessingPriority: toPriority,
            });
          }
        }
      }
    });
  });
};

const addSltOptions = (
  options: DeliverySpeedOption[],
  method: MultiLegSolutionConfigSolutionName,
  priorities: { [key in MultiLegTransactionProcessingPriority]: SettlementPriority },
  startDateTime?: string,
) => {
  objectEntries(priorities).forEach(([fromPriority, fromDetails]) => {
    const formattedDateTime = formatDateTime(fromDetails.estimatedFundsDeliveryDateTime, startDateTime);
    if (formattedDateTime) {
      const { estDate, estLabelTime, estLabelDate, estLabelDay, estDaysLabel } = formattedDateTime;
      options.push({
        label: `${estDaysLabel} – ${getSolutionDisplayName(method)}`,
        value: `SLT_${method.toUpperCase()}_${fromPriority}`,
        estDate,
        estLabelTime,
        estLabelDate,
        estLabelDay,
        fromAccountMethod: method,
        fromAccountProcessingPriority: fromPriority,
        toAccountMethod: null,
        toAccountProcessingPriority: null,
      });
    }
  });
};

export const normalizeDeliveryOptions = (data: DeliverySpeedOptionsResponse, startDateTime?: string): DeliverySpeedOption[] => {
  const options: DeliverySpeedOption[] = [];

  if (data.disbursingFromEscrow) {
    objectEntries(data.disbursingFromEscrow).forEach(([method, details]) => {
      if (details.supportedSettlementPriorities) {
        objectEntries(details.supportedSettlementPriorities).forEach(([toPriority, toDetails]) => {
          if (toDetails.fundingSolutionPriorityUsed) {
            objectEntries(toDetails.fundingSolutionPriorityUsed).forEach(([fromMethod, fromPriorities]) => {
              addMltOptions(
                options,
                fromMethod,
                fromPriorities,
                method,
                {
                  [toPriority]: toDetails,
                } as SupportedSettlementPriorities,
                startDateTime,
              );
            });
          }
        });
      }
    });
  }

  if (data.solutions) {
    objectEntries(data.solutions).forEach(([method, details]) => {
      addSltOptions(options, method, details.supportedSettlementPriorities, startDateTime);
    });
  }

  options.map((option) => (option.solutionTransactionType = data.transactionType));

  const uniqueOptions = options.reduce((acc, option) => {
    const existingOption = acc.find((o) => o.value === option.value);
    if (existingOption) {
      if (option.fromAccountProcessingPriority === 'SAME_DAY') {
        acc = acc.filter((o) => o.value !== option.value);
        acc.push(option);
      }
    } else {
      acc.push(option);
    }
    return acc;
  }, [] as DeliverySpeedOption[]);

  uniqueOptions.sort((a, b) => {
    const getPriorityOrder = (label: string) => {
      if (label.includes('Same business day')) return 1;
      if (label.includes('Next business day')) return 2;

      const match = label.match(/^(?<days>\w+)\s+business days/);

      if (match) {
        const numberWords: Record<string, number> = {
          Two: 3,
          Three: 4,
          Four: 5,
          Five: 6,
          Six: 7,
          Seven: 8,
          Eight: 9,
          Nine: 10,
          Ten: 11,
        };
        const dayWord = match[1];

        if (numberWords[dayWord]) {
          return numberWords[dayWord];
        }
      }

      return 12;
    };

    return getPriorityOrder(a.label) - getPriorityOrder(b.label);
  });

  return uniqueOptions;
};
