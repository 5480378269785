import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { compact } from 'lodash-es';
import { catchError, exhaustMap, map, mergeMap, of, switchMap } from 'rxjs';

import { CustomerService, FinancialAccountService, RecipientService } from '@shared/services';

import { FinancialAccountDetailsActions } from './financial-account.actions';
import { selectRouteData, selectRouteParams } from '../core';
import { MessagesActions } from '../core/messages';

export const loadFinancialAccountDetails$ = createEffect(
  (
    actions$ = inject(Actions),
    financialAccountService = inject(FinancialAccountService),
    recipientService = inject(RecipientService),
    customerService = inject(CustomerService),
    store = inject(Store),
  ) => {
    return actions$.pipe(
      ofType(FinancialAccountDetailsActions.loadFinancialAccountDetails),
      concatLatestFrom(() => [store.select(selectRouteParams), store.select(selectRouteData)]),
      mergeMap(([, routeParams, routeData]) => {
        if (routeData.isEscrowAccount) {
          return financialAccountService.getEscrowFinancialAccountById(routeParams.id);
        } else {
          return financialAccountService.getFinancialAccountDetailsWithBalances(routeParams.id);
        }
      }),
      exhaustMap((financialAccount) => {
        if (financialAccount.accountHolderId && financialAccount.accountHolderType === 'RECIPIENT') {
          return recipientService.getRecipientById(financialAccount.accountHolderId).pipe(
            map((recipient) => {
              financialAccount.displayName = compact([recipient.firstName, recipient.lastName]).join(' ');
              return financialAccount;
            }),
            catchError(() => of(financialAccount)),
          );
        } else if (financialAccount.accountHolderId && financialAccount.accountHolderType === 'CUSTOMER') {
          return customerService.getCustomerById(financialAccount.accountHolderId).pipe(
            map((customer) => {
              financialAccount.displayName = compact([customer.firstName, customer.lastName]).join(' ');
              return financialAccount;
            }),
            catchError(() => of(financialAccount)),
          );
        } else {
          return of(financialAccount);
        }
      }),
      map((financialAccountDetails) => {
        return FinancialAccountDetailsActions.loadFinancialAccountDetailsSuccess({ financialAccountDetails });
      }),
      catchError((error) => {
        return of(FinancialAccountDetailsActions.loadFinancialAccountDetailsFailure({ error }));
      }),
    );
  },
  { functional: true },
);

export const loadFinancialAccountDetailsFailure$ = createEffect(
  (actions$ = inject(Actions)) => {
    return actions$.pipe(
      ofType(FinancialAccountDetailsActions.loadFinancialAccountDetailsFailure),
      switchMap(() => of(MessagesActions.displayError({ message: 'Unable to fetch Financial Account Details.' }))),
    );
  },
  { functional: true },
);
