import { AsyncPipe, NgIf } from '@angular/common';
import { AfterViewChecked, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';

import { TransactionFormActions, transactionFormFeature } from '@shared/store';
import { getFormattedAmount } from '@shared/utils';

import { PercentageFieldDirective } from '../../../directives/percentage-field.directive';

@Component({
  selector: 'app-create-transaction-amount',
  templateUrl: './create-transaction-amount.component.html',
  standalone: true,
  imports: [NgIf, FormsModule, ReactiveFormsModule, PercentageFieldDirective, AsyncPipe],
})
export class CreateTransactionAmountComponent implements OnInit, AfterViewChecked, OnDestroy {
  @ViewChild('textInputWrapperRef') textInputWrapperRef!: ElementRef<HTMLElement>;

  @ViewChild('textInputRef') textInputRef!: ElementRef<HTMLInputElement>;

  private destroy$ = new Subject<void>();

  amountOption$ = this.store.select(transactionFormFeature.selectAmountOption);

  isDebitLegsDisabled$ = this.store.select(transactionFormFeature.selectIsDebitLegsDisabled);

  moveAmount = new FormControl<string | null>('');

  get amountValue(): number | undefined {
    if (!this.moveAmount.value) {
      return undefined;
    }

    return parseFloat(this.moveAmount.value);
  }

  constructor(
    private ref: ChangeDetectorRef,
    private store: Store,
  ) {}

  ngOnInit() {
    this.store
      .select(transactionFormFeature.selectAmount)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (amount) => {
          if (!this.moveAmount?.value && amount) {
            this.moveAmount.setValue(getFormattedAmount(Number(amount)).formattedValue ?? '');
          } else if (this.moveAmount?.value && !amount) {
            this.moveAmount.setValue(null);
          }
        },
      });
  }

  ngAfterViewChecked() {
    this.ref.detectChanges();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onBlur(): void {
    if (!this.amountValue) {
      this.moveAmount.reset();
    } else {
      this.moveAmount.setValue(getFormattedAmount(this.amountValue).formattedValue ?? '');
    }
    this.updateDatasetValue();
  }

  onChange(value: string | null): void {
    this.store.dispatch(TransactionFormActions.setAmount({ amount: value }));
    this.updateDatasetValue();
  }

  onClearBtnClick() {
    this.moveAmount.reset();
  }

  updateDatasetValue() {
    if (this.textInputWrapperRef?.nativeElement?.dataset) {
      this.textInputWrapperRef.nativeElement.dataset.value = this.textInputRef.nativeElement.value ?? '';
    }
  }
}
