import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { RktIconComponent } from '@rocketfinancialcorp/rocket-ui/icon';
import { ActiveModal } from '@rocketfinancialcorp/rocket-ui/modal';

@Component({
  selector: 'app-view-pdf-document',
  templateUrl: './view-pdf-document.component.html',
  standalone: true,
  imports: [NgIf, NgxExtendedPdfViewerModule, RktIconComponent],
})
export class ViewPdfDocumentComponent {
  @Input() documentName!: string;
  @Input() documentURL!: string;
  @Input() containerHeight!: string;
  @Input() showPrintButton!: boolean;
  @Input() showDownloadButton!: boolean;
  @Input() showZoomButton!: boolean;
  @Input() showModalHeader = true;

  constructor(public activeModal: ActiveModal) {}

  modalInitData({
    documentURL,
    containerHeight,
    showPrintButton,
    showDownloadButton,
    showZoomButton,
    documentName,
  }: {
    documentURL: string;
    containerHeight: string;
    showPrintButton: boolean;
    showDownloadButton: boolean;
    showZoomButton: boolean;
    documentName: string; // Add this new input variable
  }) {
    this.documentURL = documentURL;
    this.containerHeight = containerHeight;
    this.showPrintButton = showPrintButton;
    this.showDownloadButton = showDownloadButton;
    this.showZoomButton = showZoomButton;
    this.documentName = documentName;
  }
}
