<div class="registry-form-preview">
  <div class="preview-content">
    <div class="preview-content-item enhance-profile">
      @if (enhanceBenificiaryDetails()?.beneficiaryType === 'NEW') {
        @for (item of createBeneficiaryFields; track $index) {
          <div class="enhance-profile-details-item">
            <div class="profile-dot"></div>
            <div>{{ item }}</div>
          </div>
        }
      }
      @if (enhanceBenificiaryDetails()?.enhanceProfileDetails) {
        @for (item of enhanceBenificiaryDisplayData(); track $index) {
          <div class="enhance-profile-details-item">
            @if ($index === 0) {
              <rkt-icon name="beneficiary"></rkt-icon>
            }
            <div class="profile-dot"></div>
            <div>{{ item }}</div>
          </div>
        }
      }
      @if (enhanceBenificiaryDetails()?.documentTypes?.length) {
        @for (item of enhanceBenificiaryDetails()?.documentTypes; track $index) {
          <div class="enhance-profile-details-item">
            @if ($index === 0) {
              <rkt-icon name="document"></rkt-icon>
            }
            <div class="profile-dot"></div>
            <div>{{ item.replace('-', ' ') | titlecase }}</div>
          </div>
        }
      }
    </div>
  </div>

  <button class="preview-edit" [disabled]="isEditDisabled()" (click)="expandRow.emit()">
    <rkt-icon name="edit"></rkt-icon>
  </button>
</div>
