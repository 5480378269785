import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { catchError, map, mergeMap, of, switchMap, tap } from 'rxjs';

import { TransactionBatchesService } from '@shared/services';

import { selectRouteParams } from '../core';
import { TransactionBatchDetailsActions } from './transcation-batch.actions';
import { MessagesActions } from '../core/messages';

export const loadTransactionBatchDetails$ = createEffect(
  (actions$ = inject(Actions), transactionBatchesService = inject(TransactionBatchesService), store = inject(Store)) => {
    return actions$.pipe(
      ofType(TransactionBatchDetailsActions.loadTransactionBatchDetails),
      concatLatestFrom(() => [store.select(selectRouteParams)]),
      mergeMap(([, routeParams]) => {
        return transactionBatchesService.getTransactionBatchById(routeParams.id).pipe(
          map((transactionBatchDetails) => {
            return TransactionBatchDetailsActions.loadTransactionBatchDetailsSuccess({ transactionBatchDetails });
          }),
          catchError((error) => {
            return of(TransactionBatchDetailsActions.loadTransactionBatchDetailsFailure({ error }));
          }),
        );
      }),
    );
  },
  { functional: true },
);

export const loadTransactionBatchDetailsFailure$ = createEffect(
  (actions$ = inject(Actions)) => {
    return actions$.pipe(
      ofType(TransactionBatchDetailsActions.loadTransactionBatchDetailsFailure),
      switchMap(() => of(MessagesActions.displayError({ message: 'Unable to fetch Transaction Batch Details.' }))),
    );
  },
  { functional: true },
);

export const loadTransactionBatchDetailsFailureRedirect$ = createEffect(
  (actions$ = inject(Actions), router = inject(Router)) => {
    return actions$.pipe(
      ofType(TransactionBatchDetailsActions.loadTransactionBatchDetailsFailure),
      tap({
        next: () => router.navigateByUrl('/app/exception-management/exceptions'),
      }),
    );
  },
  { functional: true, dispatch: false },
);
