import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from '@env';
import { CustomHttpParamEncoder } from '@shared/encoder';
import {
  AcceptanceDocument,
  AcceptanceDocumentResponse,
  AcceptanceDocumentsRequestParams,
  AcceptedDocumentList,
  AcceptedDocumentListRaw,
} from '@shared/models';
import { BackendService } from '@shared/services/backend.service';
import { toTitleCase } from '@shared/utils';

@Injectable({
  providedIn: 'root',
})
export class FinancialAccountDocumentService {
  http = inject(HttpClient);

  constructor(private backendService: BackendService) {}

  public getFinancialAccountAcceptanceDocuments({
    accountHolderType,
    accountHolderSubtype,
  }: AcceptanceDocumentsRequestParams): Observable<AcceptanceDocument[]> {
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() }).set('accountHolderType', accountHolderType);

    if (accountHolderSubtype) {
      params = params.append('accountHolderSubtype', accountHolderSubtype);
    }

    return this.backendService
      .get<AcceptanceDocument[]>(`${environment.financialAccountFlow}/integrated-bank-accounts/acceptance-documents`, { params })
      .pipe(
        map((response) => response),
        catchError((errorRes) => throwError(() => errorRes)),
      );
  }

  public getAcceptedDocuments({ financialAccountId }: { financialAccountId: string }): Observable<AcceptedDocumentList> {
    const params = new HttpParams({ encoder: new CustomHttpParamEncoder() }).set('financialAccountId', financialAccountId);

    return this.backendService.get<AcceptedDocumentListRaw>(`${environment.documentAcceptanceService}/acceptances`, { params }).pipe(
      map((response) => {
        const { content = [], totalElements = 0 } = response;

        const items = content.map((item) => ({
          documentName: toTitleCase(item.documentId.replace(/(?:ebt|rkor)-/iu, '').replace(/-/g, ' ')),
          documentId: item.documentId,
          documentVersion: item.documentVersion,
          isAccepted: item.accepted ? 'Yes' : 'No',
          acceptedAt: item.respondedAt,
          downloadUrl: item.downloadUrl,
        }));

        return {
          items,
          totalElements,
        };
      }),
      catchError((errorRes) => throwError(() => errorRes)),
    );
  }

  public getAcceptedDocumentsByAcceptorId(acceptorId: string): Observable<AcceptedDocumentList> {
    const params = new HttpParams({ encoder: new CustomHttpParamEncoder() }).set('acceptorId', acceptorId);

    return this.backendService.get<AcceptedDocumentListRaw>(`${environment.documentAcceptanceService}/acceptances`, { params }).pipe(
      map((response) => {
        const { content = [], totalElements = 0 } = response;

        const items = content.map((item) => ({
          documentName: toTitleCase(item.documentId.replace(/(?:ebt|rkor)-/iu, '').replace(/-/g, ' ')),
          documentId: item.documentId,
          documentVersion: item.documentVersion,
          isAccepted: item.accepted ? 'Yes' : 'No',
          acceptedAt: item.respondedAt,
          downloadUrl: item.downloadUrl,
          id: item.id,
          financialAccountId: item?.scopes?.FINANCIAL_ACCOUNT_ID,
        }));

        return {
          items,
          totalElements,
        };
      }),
      catchError((errorRes) => throwError(() => errorRes)),
    );
  }

  public getAcceptanceDocument(documentUrl: string): Observable<AcceptanceDocumentResponse> {
    return this.http
      .get(documentUrl, {
        responseType: 'arraybuffer',
        observe: 'response',
      })
      .pipe(
        map((response) => response as AcceptanceDocumentResponse),
        catchError((errorRes) => throwError(() => errorRes)),
      );
  }
}
