import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, OnInit, inject, input, output } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { Subject, takeUntil } from 'rxjs';
import { RktButtonDirective } from '@rocketfinancialcorp/rocket-ui/button';
import { RktFormComponent, formRadioGroup, formSelect } from '@rocketfinancialcorp/rocket-ui/form';

import { LinxProfileType } from '@shared/constants';
import { FinancialAccountList, FinancialAccountListItem, FormModel, LinxRequestFinancialAccount } from '@shared/models';
import { FinancialAccountService } from '@shared/services';
import { ErrorUtils } from '@shared/utils';

@Component({
  selector: 'app-linx-request-financial-account',
  templateUrl: './linx-request-financial-account.component.html',
  standalone: true,
  imports: [RktButtonDirective, FormsModule, ReactiveFormsModule, RktFormComponent],
})
export class LinxRequestFinancialAccountComponent implements OnInit, AfterViewChecked, OnDestroy {
  readonly financialAccountService = inject(FinancialAccountService);
  readonly ref = inject(ChangeDetectorRef);

  profileType = input<LinxProfileType>();

  accountHolderId = input<string>('');

  continue = output<LinxRequestFinancialAccount>();

  financialAccountForm = new UntypedFormGroup({});

  formModel: FormModel<{
    financialAccountType?: 'NEW' | 'EXISTING';
    financialAccountSubType?: string;
    existingFinancialAccountId?: string;
  }> = {};

  financialAccountFields: FormlyFieldConfig[] = [
    formRadioGroup({
      key: 'financialAccountType',
      label: '',
      props: {
        options: [
          { label: 'Use Existing Financial Account', value: 'EXISTING' },
          { label: 'Create New Financial Account', value: 'NEW' },
        ],
        required: true,
      },
      expressions: {
        hide: () => this.isNewAccount,
      },
    }),
    formRadioGroup({
      key: 'financialAccountSubType',
      label: 'Account Type',
      props: {
        options: [
          { label: 'Checking Account', value: 'CHECKING' },
          { label: 'Saving Account', value: 'SAVING' },
          { label: 'Card Account', value: 'CARD' },
        ],
        required: true,
      },
      expressions: {
        hide: (field: FormlyFieldConfig) => field.model.financialAccountType !== 'NEW' && !this.isNewAccount,
      },
    }),
    formSelect({
      key: 'existingFinancialAccountId',
      label: 'Financial Account',
      props: {
        options: [],
        placeholder: 'Select a Financial Account',
        required: true,
      },
      className: 'linx-form-field-dropdown',
      expressions: {
        hide: (field: FormlyFieldConfig) => this.isNewAccount || field.model.financialAccountType !== 'EXISTING',
      },
    }),
  ];

  financialAccountsLoading = false;

  financialAccounts!: FinancialAccountListItem[];

  get isNewAccount(): boolean {
    return this.profileType() === LinxProfileType.New;
  }

  private destroy$ = new Subject<void>();

  ngOnInit(): void {
    if (!this.isNewAccount) {
      this.getFinancialAccounts();
    }
  }

  ngAfterViewChecked(): void {
    this.ref.detectChanges();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onClickContinue(): void {
    const { financialAccountType = 'NEW', financialAccountSubType, existingFinancialAccountId } = this.formModel;

    const formData = { financialAccountType } as LinxRequestFinancialAccount;

    if (financialAccountType === 'NEW') {
      formData.financialAccountSubType = financialAccountSubType;
    } else {
      const existingFinancialAccount = this.financialAccounts.find((item) => item.id === existingFinancialAccountId);
      formData.existingFinancialAccount = existingFinancialAccount;
      formData.financialAccountSubType = existingFinancialAccount?.subtype;
    }

    this.continue.emit(formData);
  }

  onClearBtnClick(): void {
    this.financialAccountForm.reset();
  }

  getFinancialAccounts(): void {
    if (!this.accountHolderId()) {
      return;
    }
    this.financialAccountsLoading = true;

    this.financialAccountService
      .getCustomerFinancialAccounts({ customerId: this.accountHolderId() })
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response) => {
          this.onFetchComplete(response);
        },
        error: (error) => {
          this.onFetchComplete();
          ErrorUtils.catchError('customerService.getCustomerFinancialAccounts', error);
        },
      });
  }

  onFetchComplete(response?: FinancialAccountList): void {
    const { items = [] } = response || {};

    this.financialAccounts = items;

    if (items.length) {
      const financialAccountOptions: Record<string, string | boolean>[] = [];

      items.forEach((item) => {
        const availableBalance = item.availableBalance ? item.availableBalance : '0.00';
        const account = {
          value: item.id,
          label: `${item.name ?? item.displayName} | Available Balance $ ${availableBalance}`,
        };
        financialAccountOptions.push(account);
      });
      if (this.financialAccountFields[2]?.props) {
        this.financialAccountFields[2].props.options = financialAccountOptions;
      }
    }

    this.financialAccountsLoading = false;
  }
}
