import { Injectable } from '@angular/core';

import {
  IdentityVerificationProfile,
  LinxEnhanceAccountDetails,
  LinxRequestBenificiaryDetails,
  LinxRequestDocumentDetails,
  LinxRequestFinancialAccount,
  LinxRequestMoveMoneyDetails,
  LinxRequestOptionType,
  LinxRequestRowType,
} from '@shared/models';

export abstract class AbstractLinxRequestBase {
  abstract expandedRow?: LinxRequestRowType;
  abstract financialAccountDetails?: LinxRequestFinancialAccount;
  abstract enhanceAccountDetails?: LinxEnhanceAccountDetails;
  abstract enhanceBeneficiaryDetails?: LinxRequestBenificiaryDetails;
  abstract documentAcceptanceDetails?: LinxRequestDocumentDetails[];
  abstract moveMoneyDetails?: LinxRequestMoveMoneyDetails;
  abstract identityVerificationDetails?: IdentityVerificationProfile;
  abstract selectedRequestOptions: LinxRequestOptionType[];
  abstract isRequestOptionsAvailable(): boolean;
  abstract isRowVisible(type: LinxRequestRowType): boolean;
  abstract isRowExpanded(type: LinxRequestRowType): boolean;
  abstract isRowHasValue(type: LinxRequestRowType): boolean;
  abstract isEditDisabled(): boolean;
  abstract onReset(): void;
  abstract resetRequestRowDetails(type: LinxRequestRowType): void;
  abstract handleRowExpand(type?: LinxRequestRowType): void;
}

@Injectable()
export class LinxRequestWrapperService implements AbstractLinxRequestBase {
  public expandedRow?: LinxRequestRowType;

  financialAccountDetails?: LinxRequestFinancialAccount;

  enhanceAccountDetails?: LinxEnhanceAccountDetails;

  enhanceBeneficiaryDetails?: LinxRequestBenificiaryDetails;

  documentAcceptanceDetails?: LinxRequestDocumentDetails[];

  moveMoneyDetails?: LinxRequestMoveMoneyDetails;

  identityVerificationDetails?: IdentityVerificationProfile;

  selectedRequestOptions: LinxRequestOptionType[] = [];

  get isEnhanceProfileDetailsRowHasValue(): boolean {
    return !!this.enhanceAccountDetails;
  }

  get isEnhanceBeneficiaryDetailsRowHasValue(): boolean {
    return !!this.enhanceBeneficiaryDetails;
  }

  get isFinancialAccountDetailsHasValue(): boolean {
    return this.financialAccountDetails ? true : false;
  }

  get isFinancialAccountDetailsRowHasValue(): boolean {
    return this.isFinancialAccountDetailsHasValue;
  }

  get isDocumentAcceptanceDetailsRowHasValue(): boolean {
    return this.documentAcceptanceDetails?.length ? true : false;
  }

  get isMoveMoneyDetailsRowHasValue(): boolean {
    return this.moveMoneyDetails ? true : false;
  }

  get isIdentityVerificationHasValue(): boolean {
    return this.identityVerificationDetails ? true : false;
  }

  isRequestOptionsAvailable(): boolean {
    return this.selectedRequestOptions.length > 0;
  }

  handleRowExpand(type?: LinxRequestRowType): void {
    if (type) {
      this.expandedRow = type;
      return;
    }
    this.expandedRow = this.selectedRequestOptions.find((option) => option.isChecked && !option.hasValue)?.type;
  }

  isRowVisible(type: LinxRequestRowType): boolean {
    return this.selectedRequestOptions.find((option) => option.type === type)?.isChecked || false;
  }

  isRowExpanded(type: LinxRequestRowType): boolean {
    return this.expandedRow === type;
  }

  isEditDisabled(): boolean {
    return this.expandedRow ? true : false;
  }

  isRowHasValue(type: string): boolean {
    switch (type) {
      case 'enhance-profile':
        return this.isEnhanceProfileDetailsRowHasValue;
      case 'financial-account':
        return this.isFinancialAccountDetailsRowHasValue;
      case 'document-acceptance':
        return this.isDocumentAcceptanceDetailsRowHasValue;
      case 'move-money':
        return this.isMoveMoneyDetailsRowHasValue;
      case 'enhance-beneficiary':
        return this.isEnhanceBeneficiaryDetailsRowHasValue;
      case 'identity-verification':
        return this.isIdentityVerificationHasValue;
      default:
        return false;
    }
  }

  resetRequestRowDetails(type: LinxRequestRowType): void {
    switch (type) {
      case 'enhance-profile':
        this.enhanceAccountDetails = undefined;
        break;
      case 'financial-account':
        this.financialAccountDetails = undefined;
        break;
      case 'document-acceptance':
        this.documentAcceptanceDetails = undefined;
        break;
      case 'move-money':
        this.moveMoneyDetails = undefined;
        break;
      case 'enhance-beneficiary':
        this.enhanceBeneficiaryDetails = undefined;
        break;
      case 'identity-verification':
        this.identityVerificationDetails = undefined;
        break;
      default:
        break;
    }
  }

  onReset(): void {
    this.financialAccountDetails = undefined;
    this.enhanceAccountDetails = undefined;
    this.documentAcceptanceDetails = undefined;
    this.moveMoneyDetails = undefined;
    this.expandedRow = undefined;
    this.selectedRequestOptions = [];
    this.enhanceBeneficiaryDetails = undefined;
    this.identityVerificationDetails = undefined;
  }
}
