import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { isNil } from 'lodash-es';
import { Subject, takeUntil } from 'rxjs';
import { DatatableColumn, RktTableComponent } from '@rocketfinancialcorp/rocket-ui/table';

import { FinancialAccountList, FinancialAccountListItem, RequestPageParams } from '@shared/models';
import { FinancialAccountService } from '@shared/services';
import { ErrorUtils } from '@shared/utils';

@Component({
  selector: 'app-child-accounts-page',
  templateUrl: './child-accounts-page.component.html',
  standalone: true,
  imports: [RktTableComponent],
})
export class ChildAccountsPageComponent implements AfterViewChecked, OnDestroy {
  financialAccounts: FinancialAccountListItem[] = [];

  loading = false;

  public columns: DatatableColumn[] = [
    { name: 'Customer Name', prop: 'customer.displayName', flexSize: 300 },
    { name: 'Account Name', prop: 'name', flexSize: 300 },
    { name: 'Account Category', prop: 'category', flexSize: 333 },
    { name: 'Account Number', prop: 'displayName', flexSize: 333 },
    { name: 'Account Balance', prop: 'accountBalance', flexSize: 305, colType: 'amount', colAlign: 'right' },
    { name: 'Available Balance', prop: 'availableBalance', flexSize: 305, colType: 'amount', colAlign: 'right' },
    { name: 'Status', prop: 'state', fixedSize: 140, colType: 'status-tag', colAlign: 'right' },
  ];

  totalElements = 0;

  page = 0;

  emptyListMessage = 'No Child Financial Account Activity found.';

  get activePage(): number {
    return this.page;
  }

  private destroy$ = new Subject<void>();

  constructor(
    private financialAccountService: FinancialAccountService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public ref: ChangeDetectorRef,
  ) {}

  ngAfterViewChecked(): void {
    this.ref.detectChanges();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onRowClick(data: FinancialAccountListItem): void {
    const type = data.customer!.type === 'BUSINESS' ? 'businesses' : 'individuals';
    this.router.navigateByUrl(`app/customers/${type}/${data.customer!.id}/financial-accounts/${data.id}/account-activity`);
  }

  getFinancialAccounts({ page, size }: RequestPageParams): void {
    this.loading = true;

    if (!isNil(page) && this.activePage !== page) {
      this.page = page;
    }

    const { parent } = this.activatedRoute;

    this.financialAccountService
      .getFinancialAccountsWithBalances({
        page,
        size,
        state: ['ACTIVE', 'SUSPENDED', 'PENDING'],
        accountHolderTypes: ['CUSTOMER'],
        parentId: parent!.snapshot.paramMap.get('id') || undefined,
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response) => {
          this.emptyListMessage = 'No Child Financial Account Activity found.';
          this.onFetchComplete(response);
        },
        error: (error) => {
          this.emptyListMessage = 'Unable to fetch Child Financial Accounts.';
          this.onFetchComplete();
          ErrorUtils.catchError('financialAccountService.getFinancialAccountsWithBalances', error);
        },
      });
  }

  onFetchComplete(response?: FinancialAccountList): void {
    const { items = [], totalElements = 0 } = response || {};
    this.financialAccounts = items;
    this.totalElements = totalElements;
    this.loading = false;
  }
}
