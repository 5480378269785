import { createAction, props } from '@ngrx/store';

import { ErrorMessage, InsightReportHistoryStatus } from '@shared/models';

export namespace InsightDocumentActions {
  export const loadInsightDocumentHistory = createAction('[Insight API] GET Insight Document History Status');

  export const getInsightDocumentHistorySuccess = createAction(
    '[Insight API] Load Insight Document History Success',
    props<{ insightUnreadDocuments: InsightReportHistoryStatus[] }>(),
  );

  export const getInsightDocumentHistoryError = createAction(
    '[Insight API] Load Insight Document History Error',
    props<{ error: ErrorMessage }>(),
  );
}
