import { AsyncPipe, NgIf } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';
import { RktButtonDirective } from '@rocketfinancialcorp/rocket-ui/button';
import { ModalService } from '@rocketfinancialcorp/rocket-ui/modal';

import {
  ConfirmModalComponent,
  CreateTransactionReviewModalComponent,
  ErrorModalComponent,
  FinancialAccountAddressFormModalComponent,
} from '@shared/components';
import { AttachmentCreateModel, FinancialAccountSelectionListItem } from '@shared/models';
import {
  TransactionFormActions,
  selectIsCreateTransactionDisabled,
  selectIsCreateTransactionUnchanged,
  selectToAccountForWireWithoutAddress,
  transactionFormFeature,
} from '@shared/store';
import { ErrorUtils } from '@shared/utils';

import { CreateEntityAttachmentComponent } from '../../attachments/create-entity-attachment/create-entity-attachment.component';
import { CreateTransactionAmountComponent } from '../create-transaction-amount/create-transaction-amount.component';
import { CreateTransactionAmountOptionsComponent } from '../create-transaction-amount-options/create-transaction-amount-options.component';
import { CreateTransactionDeliverySpeedComponent } from '../create-transaction-delivery-speed/create-transaction-delivery-speed.component';
import { CreateTransactionFinancialAccountComponent } from '../create-transaction-financial-account/create-transaction-financial-account.component';
import { CreateTransactionNoteComponent } from '../create-transaction-note/create-transaction-note.component';
import { CreateTransactionSchedulerComponent } from '../create-transaction-scheduler/create-transaction-scheduler.component';

@Component({
  templateUrl: './create-transaction-screen.component.html',
  standalone: true,
  imports: [
    RktButtonDirective,
    CreateTransactionAmountComponent,
    CreateTransactionAmountOptionsComponent,
    CreateTransactionFinancialAccountComponent,
    NgIf,
    CreateTransactionDeliverySpeedComponent,
    CreateTransactionSchedulerComponent,
    CreateEntityAttachmentComponent,
    CreateTransactionNoteComponent,
    AsyncPipe,
  ],
})
export class CreateTransactionScreenComponent implements OnInit, OnDestroy {
  private readonly store = inject(Store);

  private readonly modalService = inject(ModalService);

  private readonly router = inject(Router);

  deliverySpeedOptions$ = this.store.select(transactionFormFeature.selectDeliverySpeedOptions);

  isCreateTransactionDisabled$ = this.store.select(selectIsCreateTransactionDisabled);

  financialAccountForWireWithoutAddress?: FinancialAccountSelectionListItem | null;

  isCreateTransactionUnchanged = true;

  private destroy$ = new Subject<void>();

  ngOnInit() {
    this.store.dispatch(TransactionFormActions.formInitialize());

    this.store
      .select(selectIsCreateTransactionUnchanged)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (result) => {
          this.isCreateTransactionUnchanged = result;
        },
      });

    this.store
      .select(selectToAccountForWireWithoutAddress)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (financialAccount) => (this.financialAccountForWireWithoutAddress = financialAccount),
      });
  }

  ngOnDestroy() {
    this.store.dispatch(TransactionFormActions.createTransactionPageReset());

    this.destroy$.next();
    this.destroy$.complete();
  }

  canDeactivate() {
    return this.isCreateTransactionUnchanged;
  }

  onClearBtnClick() {
    const confirmModalRef = this.modalService.open(ConfirmModalComponent, { className: 'confirm-modal' });

    confirmModalRef.componentInstance.customText =
      'The data you have captured on this page will be lost. Are you sure you want to clear all information and selections?';

    confirmModalRef.result.then(
      (result) => {
        if (result) {
          this.store.dispatch(TransactionFormActions.createTransactionPageClear());
        }
      },
      () => false,
    );
  }

  onContinueBtnClick() {
    if (!this.financialAccountForWireWithoutAddress) {
      this.openReviewModal();
      return;
    }

    const addressModalRef = this.modalService.open(FinancialAccountAddressFormModalComponent, {
      className: 'entity-form-modal financial-account-address-form-modal',
    });

    addressModalRef.componentInstance.modalInitData({ financialAccount: this.financialAccountForWireWithoutAddress });

    addressModalRef.result.then(
      (result) => {
        if (result) {
          this.store.dispatch(TransactionFormActions.updateToAccountAddress({ billingAddress: result }));
          this.openReviewModal();
        }
      },
      () => false,
    );
  }

  openReviewModal() {
    const reviewModalRef = this.modalService.open(CreateTransactionReviewModalComponent, { className: 'create-transaction-review-modal' });

    reviewModalRef.result.then(
      ({ isTransactionCreated, transactionPath, isSkipRedirect, error } = {}) => {
        if (error) {
          this.onSumbitError(error);
        }

        if (!isTransactionCreated) {
          return;
        }

        this.store.dispatch(TransactionFormActions.createTransactionPageReset());

        if (isSkipRedirect) {
          this.onCreateTranasctionScreenReset();
        }

        if (transactionPath) {
          this.router.navigateByUrl(transactionPath);
          return;
        }
      },
      () => false,
    );
  }

  onSumbitError(error: string | number | HttpErrorResponse) {
    const errorModalRef = this.modalService.open(ErrorModalComponent, { className: 'confirm-modal create-transaction-error-modal' });

    errorModalRef.componentInstance.messageText = ErrorUtils.parseExtendedError(error);
  }

  onCreateTranasctionScreenReset(): void {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(
      () => {
        this.router.navigate(['app', 'transactions', 'create-transaction']);
      },
      () => null,
    );
  }

  onFilesUpload(attachmentData: Partial<AttachmentCreateModel>[] | null): void {
    this.store.dispatch(TransactionFormActions.setTransactionAttachments({ attachments: attachmentData }));
  }
}
