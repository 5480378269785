import { NgIf, TitleCasePipe } from '@angular/common';
import { Component } from '@angular/core';
import { RktButtonDirective } from '@rocketfinancialcorp/rocket-ui/button';
import { ActiveModal } from '@rocketfinancialcorp/rocket-ui/modal';

import { AttentionModalComponent } from '../../attention-modal/attention-modal.component';

@Component({
  selector: 'app-linx-confirm-modal',
  templateUrl: './linx-confirm-modal.component.html',
  styleUrls: ['linx-confirm-modal.component.scss'],
  standalone: true,
  imports: [RktButtonDirective, AttentionModalComponent, NgIf, TitleCasePipe],
})
export class LinxConfirmModalComponent {
  confirmType: 'warning' | 'error' | 'success' = 'success';

  title = 'Attention';

  actionName?: string;

  confirmBtnText?: string;

  customText?: string;

  showCancelButton?: boolean;

  displayName?: string;

  type?: string;

  constructor(public activeModal: ActiveModal) {}
}
