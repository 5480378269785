import { AsyncPipe, LowerCasePipe, NgFor, NgIf, TitleCasePipe } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RouterLink } from '@angular/router';
import { RktButtonDirective } from '@rocketfinancialcorp/rocket-ui/button';
import { RktIconComponent } from '@rocketfinancialcorp/rocket-ui/icon';

import { Beneficiaries } from '@shared/models';

import { AccessControlPipe } from '../../../pipes/access-control.pipe';
import { NotAvailablePipe } from '../../../pipes/not-available.pipe';
import { PhonePipe } from '../../../pipes/phone.pipe';

@Component({
  selector: 'app-beneficiary-list',
  templateUrl: './beneficiary-list.component.html',
  styleUrls: ['./beneficiary-list.component.scss'],
  standalone: true,
  imports: [
    RktButtonDirective,
    NgIf,
    NgFor,
    RouterLink,
    AsyncPipe,
    LowerCasePipe,
    TitleCasePipe,
    PhonePipe,
    NotAvailablePipe,
    AccessControlPipe,
    RktIconComponent,
  ],
})
export class BeneficiaryListComponent {
  @Input() beneficiaries!: Beneficiaries[] | null;
  @Input() loading!: boolean;
  @Output() addBeneficiaryEmitter = new EventEmitter();

  onAddBeneficiaryBtnClick() {
    this.addBeneficiaryEmitter.emit();
  }
}
