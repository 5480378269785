@let financialAccount = financialAccountDetails$ | async;
@let isLoading = !financialAccount;

<div class="entity-details-container">
  <div class="entity-details-header">
    <div class="entity-details-title">Financial Account Details</div>
    <button
      rktButton
      *ngIf="isIntegrated && !unmaskedAccountNumber"
      btnType="link"
      (click)="loadFinancialAccountDetails()"
      title="Reveal Financial Account Number"
      [disabled]="!!(isLoading | accessControl | async)"
      class="reveal-btn"
    >
      Reveal
    </button>
    <button
      rktButton
      *ngIf="isIntegrated && unmaskedAccountNumber"
      btnType="link"
      (click)="hideFinancialAccountDetails()"
      title="Hide Financial Account Number"
      [disabled]="!!(isLoading | accessControl | async)"
      class="reveal-btn"
    >
      Hide
    </button>
  </div>

  <div class="details-row details-row-with-offset" *ngIf="isEscrowAccount">
    <app-details-item
      class="fa-details-balance"
      [showPlaceholder]="isLoading"
      label="Account Balance"
      [value]="
        financialAccount?.embeddedAccountBalance?.[financialAccount?.currency ?? 'USD']?.availableBalance ?? '0'
          | amount: financialAccount?.currency ?? 'USD'
      "
    ></app-details-item>

    <app-details-item
      class="fa-details-available-balance"
      [showPlaceholder]="isLoading"
      label="Available Balance"
      [value]="
        financialAccount?.embeddedAccountBalance?.[financialAccount?.currency ?? 'USD']?.accountBalance ?? '0'
          | amount: financialAccount?.currency ?? 'USD'
      "
    ></app-details-item>
  </div>
  <div class="details-row details-row-with-offset" *ngIf="isEscrowAccount">
    <app-details-item
      class="fa-details-pending-deposits"
      [showPlaceholder]="isLoading"
      label="Pending Deposits"
      [value]="
        financialAccount?.embeddedAccountBalance?.[financialAccount?.currency ?? 'USD']?.pendingDeposit ?? '0'
          | amount: financialAccount?.currency ?? 'USD'
      "
    ></app-details-item>

    <app-details-item
      *ngIf="financialAccount?.bankAccount"
      class="fa-details-pending-withdrawals"
      [showPlaceholder]="isLoading"
      label="Pending Withdrawals"
      [value]="
        financialAccount?.embeddedAccountBalance?.[financialAccount?.currency ?? 'USD']?.pendingWithdraw ?? '0'
          | amount: financialAccount?.currency ?? 'USD'
      "
    ></app-details-item>
  </div>

  <div *ngIf="isEscrowAccount" class="details-separator"></div>

  <div class="details-row details-row-with-offset">
    <app-details-item
      *ngIf="entityType !== 'business-account'"
      class="fa-details-name"
      [showPlaceholder]="isLoading"
      label="Financial Account Name"
      [title]="financialAccount?.name"
      [value]="financialAccount?.name"
    ></app-details-item>

    <app-details-item
      class="fa-details-id"
      [showPlaceholder]="isLoading"
      label="Financial Account ID"
      [title]="financialAccount?.id | uppercase"
      [value]="financialAccount?.financialAccountShortId"
    ></app-details-item>

    <app-details-item
      class="fa-details-account-category"
      [showPlaceholder]="isLoading"
      label="Account Category"
      [value]="financialAccount?.category"
    ></app-details-item>

    <app-details-item
      *ngIf="financialAccount?.currency"
      class="fa-details-currency"
      [showPlaceholder]="isLoading"
      label="Currency"
      [value]="financialAccount?.currency | uppercase"
    ></app-details-item>

    <app-details-item
      *ngIf="financialAccount?.verificationStatus"
      class="fa-details-verification-status"
      [showPlaceholder]="isLoading"
      label="Verification Status"
      [contentValue]="true"
    >
      <div class="verification-status">
        <div *ngIf="isVerifiedByFlinks" class="flinks-verification-btn">
          <div class="verified-text">Verified by</div>
          <rkt-icon class="rkt-icon" name="flinks-icon"></rkt-icon>
        </div>

        <div *ngIf="!isVerifiedByFlinks" [class]="'inline-status inline-status-' + verificationStatus | lowercase">
          {{ verificationStatus | titlecase }}
        </div>

        <button
          *ngIf="isEligibleForVerification"
          rktButton
          title="Request Account Verification"
          [disabled]="!!(isLoading | accessControl | async)"
          class="add-entity-btn rkt-button-link"
          (click)="onRequestAccountVerification()"
        >
          <div class="rkt-icon-link-text">
            <rkt-icon class="rkt-icon" name="bank"></rkt-icon>
            <div class="rkt-icon-text">Request Account Verification</div>
          </div>
        </button>
      </div>
    </app-details-item>

    <app-details-item
      *ngIf="financialAccount?.verificationMethod"
      class="fa-details-verification-method"
      [showPlaceholder]="isLoading"
      label="Verification Method"
      [value]="financialAccount?.verificationMethod | titlecase"
    ></app-details-item>

    <app-details-item
      *ngIf="financialAccount?.token"
      class="fa-token"
      [showPlaceholder]="isLoading"
      label="Token"
      [value]="financialAccount?.token | titlecase"
    ></app-details-item>

    <app-details-item
      *ngIf="financialAccount?.provider"
      class="fa-provider"
      [showPlaceholder]="isLoading"
      label="Provider"
      [value]="financialAccount?.provider | titlecase"
    ></app-details-item>
  </div>

  <div *ngIf="isEscrowAccount" class="details-separator"></div>

  <div class="financial-account-fields" *ngIf="isLoading">
    <div class="financial-account-fields-type">
      <app-text-with-placeholder [showPlaceholder]="true" [darkBg]="!isEscrowAccount"></app-text-with-placeholder>
    </div>

    <div class="details-row">
      <app-details-item
        class="fa-details-bank-name"
        [showPlaceholder]="true"
        label=""
        [value]=""
        [isDarkBg]="!isEscrowAccount"
      ></app-details-item>

      <app-details-item
        class="fa-details-name-on-account"
        [showPlaceholder]="true"
        label=""
        [value]=""
        [isDarkBg]="!isEscrowAccount"
      ></app-details-item>

      <app-details-item
        class="fa-details-account-number"
        [showPlaceholder]="true"
        label=""
        [value]=""
        [isDarkBg]="!isEscrowAccount"
      ></app-details-item>
    </div>
  </div>

  <div [class.financial-account-fields]="!isEscrowAccount" *ngIf="financialAccount?.bankAccount">
    <div class="financial-account-fields-type">
      <rkt-icon name="bank"></rkt-icon>
      {{ financialAccount?.type }}
    </div>

    <div class="details-row">
      <app-details-item
        class="fa-details-name-on-account"
        [showPlaceholder]="isLoading"
        label="Name on Account"
        [value]="financialAccount?.bankAccount?.nameOnAccount"
      ></app-details-item>

      <app-details-item
        *ngIf="financialAccount?.bankAccount"
        class="fa-details-routing-no"
        [showPlaceholder]="isLoading"
        label="Routing No."
        [value]="financialAccount?.bankAccount?.routingNo"
      ></app-details-item>

      <app-details-item
        class="fa-details-account-number"
        [showPlaceholder]="isLoading"
        label="Account Number"
        [value]="accountNumber"
      ></app-details-item>

      <app-details-item
        class="fa-details-bank-name"
        [showPlaceholder]="isLoading"
        label="Bank Name"
        [value]="financialAccount?.bankAccount?.bankName"
      ></app-details-item>

      <app-details-item
        class="fa-details-account-number"
        [showPlaceholder]="isLoading"
        label="Address"
        [value]="financialAccount?.bankAccount?.billingAddress?.addressLine1"
      >
        <ng-container *ngIf="financialAccount?.bankAccount?.billingAddress?.addressLine1">
          <div>{{ financialAccount?.bankAccount?.billingAddress?.addressLine2 }}</div>
          <div>{{ financialAccount?.bankAccount?.billingAddress?.city }}, {{ financialAccount?.bankAccount?.billingAddress?.state }}</div>
          <div>
            {{ financialAccount?.bankAccount?.billingAddress?.postalCode }} {{ financialAccount?.bankAccount?.billingAddress?.country }}
          </div>
        </ng-container>
      </app-details-item>
    </div>
  </div>

  <div class="financial-account-fields" *ngIf="financialAccount?.card">
    <div class="financial-account-fields-type">
      <rkt-icon name="card"></rkt-icon>
      {{ financialAccount?.type }}
    </div>

    <div class="details-row details-row-with-offset">
      <app-details-item
        class="fa-details-name-on-account"
        [showPlaceholder]="isLoading"
        label="Name on Card"
        [value]="financialAccount?.card?.fullNameOnCard"
      ></app-details-item>

      <app-details-item
        class="fa-details-account-number"
        [showPlaceholder]="isLoading"
        label="Card Number"
        [value]="'**** ' + financialAccount?.card?.cardNumberTail"
      ></app-details-item>

      <app-details-item
        class="fa-details-account-number"
        [showPlaceholder]="isLoading"
        label="Card Exp. Date"
        [value]="financialAccount?.card?.expiry"
      ></app-details-item>
    </div>

    <div class="details-row">
      <app-details-item
        class="fa-details-account-number"
        [showPlaceholder]="isLoading"
        label="Address"
        [value]="financialAccount?.card?.billingAddress?.addressLine1"
      >
        <ng-container *ngIf="financialAccount?.card?.billingAddress?.addressLine1">
          <div>{{ financialAccount?.card?.billingAddress?.addressLine2 }}</div>
          <div>{{ financialAccount?.card?.billingAddress?.city }}, {{ financialAccount?.card?.billingAddress?.state }}</div>
          <div>{{ financialAccount?.card?.billingAddress?.postalCode }} {{ financialAccount?.card?.billingAddress?.country }}</div>
        </ng-container>
      </app-details-item>
    </div>
  </div>

  <div *ngIf="isEscrowAccount" class="details-separator"></div>

  <div class="details-row details-row-with-offset">
    <app-details-item
      class="fa-details-created-at"
      [showPlaceholder]="isLoading"
      label="Created At"
      [value]="financialAccount?.createdAt | dateFormat"
    ></app-details-item>

    <app-details-item
      class="fa-details-created-by"
      [showPlaceholder]="isLoading"
      label="Created By"
      [value]="financialAccount?.createdBy?.id | teamMemberName | async"
    >
    </app-details-item>
  </div>

  <div class="details-row">
    <app-details-item
      class="fa-details-updated-at"
      [showPlaceholder]="isLoading"
      label="Updated At"
      [value]="financialAccount?.updatedAt | dateFormat"
    ></app-details-item>

    <app-details-item
      class="fa-details-updated-by"
      [showPlaceholder]="isLoading"
      label="Updated By"
      [value]="financialAccount?.updatedBy?.id | teamMemberName | async"
    ></app-details-item>
  </div>

  <app-details-notes *ngIf="financialAccountId" entityType="FINANCIAL_ACCOUNT" [entityId]="financialAccountId"></app-details-notes>
</div>

<app-financial-account-accepted-documents
  *ngIf="isIntegrated && isBankAccount && financialAccountId"
  [financialAccountId]="financialAccountId"
></app-financial-account-accepted-documents>
