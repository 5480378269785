<ng-container *ngIf="document$ | async as document; else documentLoading">
  <ng-container *ngTemplateOutlet="documentDetails; context: { $implicit: document, loading: false }"></ng-container>
</ng-container>
<ng-template #documentLoading>
  <ng-container *ngTemplateOutlet="documentDetails; context: { $implicit: null, loading: true }"></ng-container>
</ng-template>

<ng-template #documentDetails [appTemplateContext]="documentDetailsContext" let-document let-loading="loading">
  <div class="details-page-header-wrapper" *ngIf="!loading; else loadingPlaceholder">
    <div class="page-header">
      <div class="details-page-back-title">
        <a [routerLink]="[pageReturnURL]" class="details-page-back-btn">
          <rkt-icon name="arrow-left" class="details-page-back-btn-icon"></rkt-icon>
        </a>
        <h1 class="details-page-title">{{ document?.documentTypeDisplayName }}</h1>

        <rkt-icon name="verified" class="verified-icon" *ngIf="isVerifiedDocument(document)" title="Verified Document"></rkt-icon>
      </div>
      <app-breadcrumbs [itemList]="breadcrumbList"></app-breadcrumbs>
    </div>

    <div class="page-header-controls">
      <app-document-details-header
        [isControlsDisabled]="loading || !isUnverifiedDocument(document)"
        (btnClicked)="onHeaderBtnClick($event, document)"
      ></app-document-details-header>
    </div>
  </div>

  <ng-template #loadingPlaceholder>
    <app-details-page-header-placeholder [pageReturnURL]="pageReturnURL" [withStatus]="false"></app-details-page-header-placeholder>
  </ng-template>

  <div class="entity-details-container">
    <div class="details-section-title">
      <div class="title">
        <div class="icon">
          <div class="loading-placeholder" *ngIf="loading"></div>
          <rkt-icon *ngIf="!loading && document?.documentTypeIcon" [name]="document.documentTypeIcon!"></rkt-icon>
        </div>
        <app-text-with-placeholder [showPlaceholder]="loading">{{ document?.documentTypeDisplayName }}</app-text-with-placeholder>
      </div>

      <div class="controls">
        <button
          *ngIf="isUnverifiedDocument(document)"
          rktButton
          btnType="link"
          class="control-btn"
          [disabled]="!!(loading | accessControl | async)"
          (click)="onEditBtnClick(document)"
        >
          Edit
        </button>
      </div>
    </div>

    <div class="details-section basic-info-section">
      <div class="id-row">
        <app-text-with-placeholder [showPlaceholder]="loading">ID: {{ document?.id || '' | rktShortId }}</app-text-with-placeholder>
      </div>

      <div class="details-row details-row-with-offset">
        <app-details-item
          [class]="pageClassPrefix + 'document-name'"
          [showPlaceholder]="loading"
          label="Document Name"
          [value]="document?.name"
        ></app-details-item>

        <app-details-item
          [class]="pageClassPrefix + 'document-number'"
          [showPlaceholder]="loading"
          label="Document number"
          [value]="document?.number"
        ></app-details-item>
      </div>

      <div class="details-row details-row-with-offset">
        <app-details-item
          [class]="pageClassPrefix + 'issuing-date'"
          [showPlaceholder]="loading"
          label="Issuing Date"
          [value]="document?.issuingDate | dateFormat: 'MM/dd/yy' : 'ISO'"
        ></app-details-item>

        <app-details-item
          [class]="pageClassPrefix + 'expiry-date'"
          [showPlaceholder]="loading"
          label="Expiry Date"
          [value]="document?.expiryDate | dateFormat: 'MM/dd/yy' : 'ISO'"
        ></app-details-item>
        <app-details-item
          *ngIf="loading || document?.documentType === 'passport'"
          [class]="pageClassPrefix + 'issuing-country'"
          [showPlaceholder]="loading"
          label="Issuing Country"
          [noBreak]="true"
          [value]="document?.issuingCountryCode"
        ></app-details-item>
      </div>

      <div class="details-row">
        <app-details-item
          *ngIf="loading || document?.documentType !== 'passport'"
          [class]="pageClassPrefix + 'issuing-country'"
          [showPlaceholder]="loading"
          label="Issuing Country"
          [noBreak]="true"
          [value]="document?.issuingCountryCode"
        ></app-details-item>

        <app-details-item
          *ngIf="loading || document?.documentType !== 'passport'"
          [class]="pageClassPrefix + 'issuing-state'"
          [showPlaceholder]="loading"
          label="Issuing State"
          [value]="document?.issuingStateCode"
        ></app-details-item>
      </div>

      <div class="details-separator"></div>

      <div class="details-row description-row">
        <app-details-item
          [class]="pageClassPrefix + 'description'"
          [showPlaceholder]="loading"
          [isValueMuted]="true"
          label="Description"
          [value]="document?.description"
        ></app-details-item>
      </div>

      <ng-container *ngIf="!loading">
        <div class="details-separator"></div>

        <div class="details-section-title document-file-title">
          <app-text-with-placeholder [showPlaceholder]="loading">Document File</app-text-with-placeholder>
        </div>

        <div class="file-list" *ngIf="!loading">
          <div class="file-item" (click)="onFilePreviewClick(document)">
            <div class="file-name">
              <rkt-icon [name]="document.documentTypeIcon!"></rkt-icon>
              {{ document?.documentTypeDisplayName }}
            </div>
            <div class="file-image">
              <img [src]="'./assets/images/' + document?.documentTypeIcon + '-placeholder.jpg'" [alt]="document?.documentTypeDisplayName" />
              <div class="banner-text">{{ getDocumentScanLabel(document) }}</div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>

    <div class="details-separator"></div>

    <div class="create-update-section">
      <div class="details-row">
        <app-details-item
          [class]="pageClassPrefix + 'created-at'"
          [showPlaceholder]="loading"
          label="Created At"
          [value]="document?.createdAt | dateFormat"
        ></app-details-item>

        <app-details-item
          [class]="pageClassPrefix + 'created-by'"
          [showPlaceholder]="loading"
          label="Created By"
          [value]="document?.createdBy?.id | teamMemberName | async"
        ></app-details-item>
      </div>

      <div class="details-row">
        <app-details-item
          [class]="pageClassPrefix + 'updated-at'"
          [showPlaceholder]="loading"
          label="Updated At"
          [value]="document?.updatedAt | dateFormat"
        ></app-details-item>

        <app-details-item
          [class]="pageClassPrefix + 'updated-by'"
          [showPlaceholder]="loading"
          label="Updated By"
          [value]="document?.updatedBy?.id | teamMemberName | async"
        ></app-details-item>
      </div>
    </div>
  </div>
</ng-template>
